import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PureFnModule } from '../../pipes/pure-fn/pure-fn.module';
import { ValidatorsMessageComponent } from './validators-message.component';

@NgModule({
  declarations: [ValidatorsMessageComponent],
  imports: [CommonModule, TranslateModule.forChild(), PureFnModule],
  exports: [ValidatorsMessageComponent]
})
export class ValidatorsMessageModule {}
