export enum ClientIdTypeEnum {
  POS_APPL = 'POS_APPL',
  STORE_PORTAL = 'STORE_PORTAL',
  TD_EYES_APPL = 'TD_EYES_APPL',
  ADMIN_PORTAL = 'ADMIN_PORTAL',
  TD_HAND_APPL = 'TD_HAND_APPL',
  CJX_POS_APPL = 'CJX_POS_APPL',
  CJX_HAND_APPL = 'CJX_HAND_APPL',
  CJX_POS_MANAGER_APPL = 'CJX_POS_MANAGER_APPL'
}
