<div *ngIf="fileList && fileList.length > 0; else noFiles">
  <div class="position-relative image-container" *ngFor="let file of fileList">
    <input class="form-control cursor-default" [disabled]="disabled" readonly />
    <span class="input-upload-display d-block link-file">{{ file.name }}</span>
    <em *ngIf="file.name && !disabled" class="icon-close-mini" (click)="onClickDelete()"></em>
  </div>
</div>

<ng-template #noFiles>
  <div class="upload-container text-center form-control">
    <input id="uploadFile" class="d-none" #uploadInput type="file" />
    <div class="input-group position-relative">
      <input
        placeholder="No File Chosen"
        class="form-control upload-input cursor-default"
        (click)="uploadInput.click()"
        [disabled]="disabledInput"
        readonly
      />
      <div class="input-group-append">
        <button type="button" class="btn upload-btn" (click)="uploadInput.click()" [disabled]="disabledInput">
          <em class="icon-upload"></em>
        </button>
      </div>
    </div>
  </div>
</ng-template>
