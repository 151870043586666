import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-no-search-result',
  templateUrl: './no-search-result.component.html',
  styleUrls: ['./no-search-result.component.scss']
})
export class NoSearchResultComponent {
  env = environment;

  @Input() resultList$: Observable<any>;
  @Input() hasSearchCriteria = true;

  constructor() {
    // intentionally empty
  }
}
