import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { isZeroValidator } from '@shared/custom-validators/is-zero-validator';
import { FileModuleEnum } from '@shared/enum/file-url.enum';
import { MasterDataEnum } from '@shared/enum/master-data.enum';
import { RequestSectionEnum } from '@shared/enum/request-section.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '@shared/enum/request-step.enum';
import { TDStorePage } from '@shared/enum/td-store-page.enum';
import { NewMasterData } from '@shared/gql/common.gql';
import { MerchantRequestViewResponse } from '@shared/models';
import { deliveryBy4Wheels } from '@shared/models/list-value/list-key-value.model';
import { MasterService } from '@shared/services/master.service';
import { AppStates } from '@shared/store/state/app.states';
import { getFileUploadObj } from '@shared/utils/get-file-name-from-ref-id-util';
import { getSelectByPage } from '@shared/utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '@shared/utils/td-store-workflow-util';

@Component({
  selector: 'app-store-location',
  templateUrl: './store-location.component.html',
  styleUrls: ['./store-location.component.scss']
})
export class StoreLocationComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() mode: RequestPageModesEnum;
  @Input() page: TDStorePage;
  @Input() listOfChange: any;

  type: RequestTypeEnum;
  private step: RequestStepEnum;

  public buildingSelectValue: NgOption[];
  public buildingSizeSelectValue: NgOption[];
  public stateSelectValue: NgOption[];
  public regionSelectValue: NgOption[];
  public timeZoneSelectValue: NgOption[];
  public propertyOwnerhipSelectValue: NgOption[];
  public countrySelectValue: NgOption[];
  public parkingSelectValue: NgOption[];
  public competitorSelectValue: NgOption[];
  public poiSelectValue: NgOption[];
  public poiDistanceSelectValue: NgOption[];
  public restrictDeliveryTimeSelectValue: Array<NewMasterData> = [];
  deliveryBy4WheelsList = deliveryBy4Wheels;

  public merchantRequestView$: Observable<MerchantRequestViewResponse>;
  private localStore: Observable<any>;
  public fileModule = FileModuleEnum;

  get storeLocation() {
    return this.parentForm.get('storeLocation') as UntypedFormGroup;
  }

  constructor(
    private readonly masterService: MasterService,
    private fb: FormBuilder,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    private readonly store: Store<AppStates>
  ) {
    super();
  }

  ngOnInit(): void {
    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.parentForm.addControl('storeLocation', this.createStoreLocationForm());

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.mode)) {
      this.setStoreLocationValue();
    }

    this.masterService
      .getMasterDataByNames([
        MasterDataEnum.BUILDING,
        MasterDataEnum.BUILDING_SIZE,
        MasterDataEnum.STATE,
        MasterDataEnum.REGION,
        MasterDataEnum.TIMEZONE,
        MasterDataEnum.PROPERTY_OWNERSHIP,
        MasterDataEnum.COUNTRY,
        MasterDataEnum.PARKING_CONDITION,
        MasterDataEnum.COMPETITOR,
        MasterDataEnum.POI,
        MasterDataEnum.POI_DISTANCE,
        MasterDataEnum.RESTRICTED_DELIVERY_TIME
      ])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.buildingSelectValue = result.building;
        this.buildingSizeSelectValue = result.buildingSizes;
        this.stateSelectValue = result.states;
        this.regionSelectValue = result.regions;
        this.timeZoneSelectValue = result.timezones;
        this.propertyOwnerhipSelectValue = result.propertyOwnership;
        this.countrySelectValue = result.countries;
        this.parkingSelectValue = result.parkingConditions;
        this.competitorSelectValue = result.competitors;
        this.poiSelectValue = result.poi;
        this.poiDistanceSelectValue = result.poiDistances;
        this.restrictDeliveryTimeSelectValue = result.restrictedDeliveryTime;
      });
  }

  isShowSection(): boolean {
    return this.tdStoreWorkflowUtil.isShowSection(this.type, this.page, this.step, RequestSectionEnum.STORE_PROFILE);
  }

  setStoreLocationValue() {
    this.storeLocation.disable();
    this.merchantRequestView$ = this.localStore.pipe(
      select(getSelectByPage(this.page)),
      filter(data => data !== null)
    );

    this.merchantRequestView$
      .pipe(
        map(response => response),
        filter(value => value !== null),
        take(1)
      )
      .subscribe(value => {
        this.type = value.type || RequestTypeEnum.EDIT;
        this.step = value.step || RequestStepEnum.EDIT_PROFILE;
        // this.status = value.status || RequestStatusEnum.DRAFT;

        if (
          value.merchantInfo &&
          this.tdStoreWorkflowUtil.isShowSection(
            this.type,
            this.page,
            this.step,
            RequestSectionEnum.STORE_LOCATION
          ) &&
          value.merchantInfo.storeProfile.length > 0
        ) {
          const storeProfile = value.merchantInfo.storeProfile[0];

          this.storeLocation.patchValue({
            address: storeProfile.address,
            state: storeProfile.state,
            region: storeProfile.region,
            postCode: storeProfile.postCode,
            country: storeProfile.country,
            timezone: storeProfile.timezone,
            buildingType: storeProfile.buildingType,
            unitSize: storeProfile.unitSize,
            unitWLH: storeProfile.unitWLH,
            propertyOwnership: storeProfile.propertyOwnership,
            rentalFee: (storeProfile.rentalFee && storeProfile.rentalFee.amount) || null,
            saleSpace: storeProfile.saleSpace,
            stockSpace: storeProfile.stockSpace,
            latitude: storeProfile.latitude,
            longitude: storeProfile.longitude,
            parking: storeProfile.parking,
            deliveryByFourWheelsTruck: storeProfile.deliveryByFourWheelsTruck,
            titleDeed: getFileUploadObj(storeProfile.titleDeed),
            houseRegistrationPicture: getFileUploadObj(storeProfile.houseRegistrationPicture),
            idCardPicture: getFileUploadObj(storeProfile.idCardPicture),
            consentLetterPicture: getFileUploadObj(storeProfile.consentLetterPicture),
            storeVDOFront: getFileUploadObj(storeProfile.storeFrontVideo),
            storeFrontPicture: this.getStoreMultipleImage(storeProfile.storeFrontPicture, 'storeFrontPicture'),
            attachmentPicture: this.getStoreMultipleImage(storeProfile.attachmentPicture, 'attachmentPicture')
          });

          this.setStoreLocationCtrl(this.type, this.page, this.step, this.mode);
        }
      });
  }

  getStoreMultipleImage(storeImages, fields) {
    if (!storeImages || storeImages.length === 0) {
      return [{ image: [] }];
    }

    const images = [];
    const storeProfileImages = this.storeLocation.get(fields) as UntypedFormArray;

    storeImages.forEach((image, i) => {
      if (!storeProfileImages.at(i)) {
        storeProfileImages.push(this.fb.group({ image: [{ value: null, disabled: false }] }));
      }

      const imageArray = { image: [] };
      imageArray.image = getFileUploadObj(image);
      images.push(imageArray);
    });
    return images;
  }

  createStoreLocationForm() {
    const regExpLatitude = /^([+\-])?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    const regExpLongitude = /^([+\-])?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    const regExpPostCode = /^\d{5}$/;
    const initialNull = [{ value: null, disabled: false }];
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    const initialNullRequiredNotZero = [{ value: null, disabled: false }, [Validators.required, isZeroValidator()]];
    const timezone = 'Asia/Bangkok';
    const country = 'TH';

    return this.fb.group({
      address: initialNullRequired,
      state: initialNullRequired,
      region: initialNullRequired,
      postCode: [{ value: null, disabled: false }, [Validators.pattern(regExpPostCode), Validators.required]],
      country: [{ value: country, disabled: false }, Validators.required],
      timezone: [{ value: timezone, disabled: false }, Validators.required],
      buildingType: initialNullRequired,
      unitSize: initialNullRequired,
      unitWLH: initialNull,
      propertyOwnership: [{ value: null, disabled: false }, Validators.required],
      rentalFee: [{ value: null, disabled: false }, [Validators.required]],
      saleSpace: initialNullRequiredNotZero,
      stockSpace: initialNullRequiredNotZero,
      latitude: [{ value: null, disabled: false }, [Validators.required, Validators.pattern(regExpLatitude)]],
      longitude: [{ value: null, disabled: false }, [Validators.required, Validators.pattern(regExpLongitude)]],
      parking: initialNullRequired,
      deliveryByFourWheelsTruck: initialNullRequired,
      titleDeed: initialNull,
      houseRegistrationPicture: initialNull,
      idCardPicture: initialNull,
      consentLetterPicture: initialNull,
      storeVDOFront: initialNull,
      storeFrontPicture: this.fb.array([this.fb.group({ image: initialNull })]),
      attachmentPicture: this.fb.array([this.fb.group({ image: initialNull })])
    });
  }

  disableRentalField(store: UntypedFormGroup) {
    if (store.controls['propertyOwnership'].value === 'Own') {
      store.controls['rentalFee'].disable();
      store.controls['rentalFee'].patchValue(null);
    } else if (store.controls['propertyOwnership'].value === 'Rent') {
      store.controls['rentalFee'].clearValidators();
      store.controls['rentalFee'].enable();
    } else {
      store.controls['rentalFee'].setValidators(Validators.required);
      store.controls['rentalFee'].disable();
    }

    store.updateValueAndValidity();
  }

  toggleEditStoreLocation() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;
    this.setStoreLocationCtrl(this.type, this.page, this.step, this.mode);
  }

  setStoreLocationCtrl(
    localType: RequestTypeEnum,
    localPage: TDStorePage,
    localStep: RequestStepEnum,
    localMode: RequestPageModesEnum
  ) {
    const editSection = RequestSectionEnum.STORE_LOCATION;
    const canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(localType, localPage, localStep, editSection);
    if (localMode === RequestPageModesEnum.REQUEST_EDIT && canEditByWorkflow) {
      this.storeLocation.enable();
      this.disableRentalField(this.storeLocation);
      this.disableFields();
    } else {
      this.storeLocation.disable();
    }
  }

  disableFields() {
    if (this.step === RequestStepEnum.EDIT_PROFILE) {
      this.storeLocation.get('state').disable();
      this.storeLocation.get('region').disable();

      this.storeLocation.updateValueAndValidity();
    }
  }

  isRequestTypeEdit(requestType: RequestTypeEnum) {
    return requestType === RequestTypeEnum.EDIT;
  }
}
