import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { StoresPagingListResponse } from '../models/stores.model';
import { BaseService } from './base.service';

@Injectable()
export class StoresService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.stores;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchStores(term): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STORE,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const criteria = { ...term, size: 100 };
    const params = this.getParams(criteria);
    return this.http.get<any>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public storePaging(criteria): Observable<StoresPagingListResponse> {
    const header = new HttpHeaders({
      'x-host': 'store.api.tdshop.io',
      'x-tenant-id': '*:*:*:*'
    });

    const url = this.getFullUrl(this.envService.storesPaging);
    const params = this.getParams(criteria, true);

    return this.http
      .get<StoresPagingListResponse>(url, {
        headers: header,
        observe: 'body',
        params
      })
      .pipe(
        map(res => {
          if (res && res.content) {
            res.content.map(val => {
              if (val.storeCode && val.storeName) {
                val.storeNameToDisplay = `${val.storeCode}-${val.storeName}`;
              }
            });
          }

          return res;
        })
      );
  }
}
