import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BillPaymentReportCriteria } from '../models/report.model';
import { metricsReporting } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable()
export class StoreReportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.storeReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportBillPayment(exportCriteria: BillPaymentReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportBillPayment);
    delete exportCriteria.exportBy;
    const metrics = metricsReporting([this.envService.url, this.envService.exportBillPayment]);

    return this.http.post<any>(url, exportCriteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
