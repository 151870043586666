import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { orderBy } from 'lodash';

import {
  AuditLog,
  CJBarcode,
  CJProductLinkArticleDetail,
  ErrorResponse,
  RequestAssortment,
  RequestAssortmentSearchCriteria
} from '../../models';
import { RequestAssortmentActionTypes, RequestAssortmentActions } from '../actions/request-assortment.actions';

export interface RequestAssortmentState extends EntityState<RequestAssortment> {
  isLoading: boolean;
  totalElements: number;
  isDeleteSuccess: boolean;
  isApproveRejectSuccess: boolean;
  saveSuccess: boolean;
  isShowSupplierModal: boolean;
  requestAssortment: any;
  requestAssortmentEdit: any;
  CJBarcodeDuplicateList: { cjBarcodeList: CJBarcode[] };
  duplicateBarcode: string;
  criteriaObject: RequestAssortmentSearchCriteria;
  cjProductLinkArticleDetail: CJProductLinkArticleDetail;
  submitError: ErrorResponse;
  auditLogs: AuditLog[];
  supplierPriceError: any;
}

export const adapter: EntityAdapter<RequestAssortment> = createEntityAdapter<RequestAssortment>();

export const initialRequestAssortmentState: RequestAssortmentState = adapter.getInitialState({
  isLoading: false,
  totalElements: 0,
  isDeleteSuccess: false,
  isApproveRejectSuccess: false,
  saveSuccess: false,
  isShowSupplierModal: false,
  requestAssortment: {},
  requestAssortmentEdit: null,
  CJBarcodeDuplicateList: null,
  duplicateBarcode: null,
  submitError: null,
  supplierPriceError: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  cjProductLinkArticleDetail: null,
  auditLogs: null
});

export function requestAssortmentReducers(
  state = initialRequestAssortmentState,
  action: RequestAssortmentActions
): RequestAssortmentState {
  switch (action.type) {
    case RequestAssortmentActionTypes.RequestAssortmentSearchLoaded:
      return adapter.setAll(action.payload.requestAssortment.content, {
        ...state,
        page: action.payload.requestAssortment.page,
        size: action.payload.requestAssortment.size,
        totalElements: action.payload.requestAssortment.totalElements,
        totalPages: action.payload.requestAssortment.totalPages
      });
    case RequestAssortmentActionTypes.RequestAssortmentDeleteSuccess:
      return {
        ...state,
        isDeleteSuccess: true
      };
    case RequestAssortmentActionTypes.RequestAssortmentResetDeleteSuccess:
      return {
        ...state,
        isDeleteSuccess: false
      };
    case RequestAssortmentActionTypes.RequestAssortmentSet:
    case RequestAssortmentActionTypes.RequestAssortmentLoaded:
    case RequestAssortmentActionTypes.ProductAssortmentLoaded: {
      const barcodes = orderBy(
        action.payload.requestAssortment.productDetail.barcodes,
        ['tdBarcode.barSize', 'tdBarcode.barcode'],
        ['desc', 'asc']
      );
      return {
        ...state,
        requestAssortmentEdit: {
          ...action.payload.requestAssortment,
          productDetail: {
            ...action.payload.requestAssortment.productDetail,
            barcodes
          }
        }
      };
    }
    case RequestAssortmentActionTypes.ProductAssortmentHistoryLoaded: {
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    }
    case RequestAssortmentActionTypes.SupplierPriceAdded: {
      return {
        ...state,
        requestAssortmentEdit: {
          ...state.requestAssortmentEdit,
          document: {
            ...state.requestAssortmentEdit.document,
            supplierPriceSnapshots: state.requestAssortmentEdit.document.supplierPriceSnapshots
              ? state.requestAssortmentEdit.document.supplierPriceSnapshots.concat(action.payload)
              : [].concat(action.payload)
          }
        }
      };
    }
    case RequestAssortmentActionTypes.SupplierPriceUpdated: {
      const temp = state.requestAssortmentEdit.document.supplierPriceSnapshots.map((supplierPrice, i) =>
        i === action.payload.index
          ? {
              ...action.payload
            }
          : supplierPrice
      );
      return {
        ...state,
        requestAssortmentEdit: {
          ...state.requestAssortmentEdit,
          document: {
            ...state.requestAssortmentEdit.document,
            supplierPriceSnapshots: [...temp]
          }
        }
      };
    }
    case RequestAssortmentActionTypes.SupplierPriceDeleted: {
      const suppliers = state.requestAssortmentEdit.document.supplierPriceSnapshots.filter(
        (_item, index) => index !== action.payload
      );
      return {
        ...state,
        requestAssortmentEdit: {
          ...state.requestAssortmentEdit,
          document: {
            ...state.requestAssortmentEdit.document,
            supplierPriceSnapshots: [...suppliers]
          }
        }
      };
    }
    case RequestAssortmentActionTypes.RequestBarcodeUpdated: {
      const temp = state.requestAssortmentEdit.document.barcodeSnapshots.map((bar, i) =>
        i === action.payload.barcode.index
          ? {
              ...bar,
              tdBarcodeSnapshot: {
                ...action.payload.barcode
              },
              cjBarcodeSnapshot: bar.cjBarcodeSnapshot
            }
          : bar
      );
      return {
        ...state,
        requestAssortmentEdit: {
          ...state.requestAssortmentEdit,
          document: {
            ...state.requestAssortmentEdit.document,
            barcodeSnapshots: [...temp]
          }
        }
      };
    }
    case RequestAssortmentActionTypes.RequestBarcodeCouponDiscountUpdated: {
      const temp = state.requestAssortmentEdit.document.barcodeSnapshots.map(bar => {
        return {
          ...bar,
          tdBarcodeSnapshot: {
            ...bar.tdBarcodeSnapshot,
            allowVoucher: action.payload,
            allowPromotion: action.payload
          }
        };
      });
      return {
        ...state,
        requestAssortmentEdit: {
          ...state.requestAssortmentEdit,
          document: {
            ...state.requestAssortmentEdit.document,
            barcodeSnapshots: [...temp]
          }
        }
      };
    }
    case RequestAssortmentActionTypes.RequestBarcodeAdded: {
      const temp = {
        tdBarcodeSnapshot: {
          ...action.payload.barcode
        }
      };
      const newBarcodeSnapshots = state.requestAssortmentEdit.document.barcodeSnapshots.concat(temp);
      const barcodeSnapshots = orderBy(
        newBarcodeSnapshots,
        ['tdBarcodeSnapshot.barSize', 'tdBarcodeSnapshot.barcode'],
        ['desc', 'asc']
      );
      return {
        ...state,
        requestAssortmentEdit: {
          ...state.requestAssortmentEdit,
          document: {
            ...state.requestAssortmentEdit.document,
            barcodeSnapshots
          }
        }
      };
    }
    case RequestAssortmentActionTypes.RequestBarcodeDeleted: {
      const barcode = state.requestAssortmentEdit.document.barcodeSnapshots.filter(
        (_item, index) => index !== action.payload
      );
      return {
        ...state,
        requestAssortmentEdit: {
          ...state.requestAssortmentEdit,
          document: {
            ...state.requestAssortmentEdit.document,
            barcodeSnapshots: [...barcode]
          }
        }
      };
    }
    case RequestAssortmentActionTypes.AllowToOrderBarcodeUpdated: {
      const barcodes = state.requestAssortmentEdit.document.barcodeSnapshots.map(barcodeSnapshot => {
        if (action.payload.includes(barcodeSnapshot.tdBarcodeSnapshot.unit)) {
          return {
            ...barcodeSnapshot,
            tdBarcodeSnapshot: {
              ...barcodeSnapshot.tdBarcodeSnapshot,
              allowToOrder: true
            },
            cjBarcodeSnapshot: barcodeSnapshot.cjBarcodeSnapshot
          };
        } else {
          return {
            ...barcodeSnapshot,
            tdBarcodeSnapshot: {
              ...barcodeSnapshot.tdBarcodeSnapshot,
              allowToOrder: false
            },
            cjBarcodeSnapshot: barcodeSnapshot.cjBarcodeSnapshot
          };
        }
      });
      return {
        ...state,
        requestAssortmentEdit: {
          ...state.requestAssortmentEdit,
          document: {
            ...state.requestAssortmentEdit.document,
            barcodeSnapshots: [...barcodes]
          }
        }
      };
    }
    case RequestAssortmentActionTypes.AllowToBuyBarcodeUpdated: {
      const barcodes = state.requestAssortmentEdit.document.barcodeSnapshots.map(barcodeSnapshot => {
        if (action.payload === barcodeSnapshot.tdBarcodeSnapshot.barcode) {
          return {
            ...barcodeSnapshot,
            tdBarcodeSnapshot: {
              ...barcodeSnapshot.tdBarcodeSnapshot,
              allowToBuy: true
            },
            cjBarcodeSnapshot: barcodeSnapshot.cjBarcodeSnapshot
          };
        } else {
          return {
            ...barcodeSnapshot,
            tdBarcodeSnapshot: {
              ...barcodeSnapshot.tdBarcodeSnapshot,
              allowToBuy: false
            },
            cjBarcodeSnapshot: barcodeSnapshot.cjBarcodeSnapshot
          };
        }
      });
      return {
        ...state,
        requestAssortmentEdit: {
          ...state.requestAssortmentEdit,
          document: {
            ...state.requestAssortmentEdit.document,
            barcodeSnapshots: [...barcodes]
          }
        }
      };
    }
    case RequestAssortmentActionTypes.RequestAssortmentUpdateSuccess:
      return {
        ...state,
        saveSuccess: action.payload.status
      };
    case RequestAssortmentActionTypes.RequestAssortmentCriteriaObjectUpdated:
      return {
        ...state,
        criteriaObject: action.payload.criteriaObject
      };
    case RequestAssortmentActionTypes.RequestAssortmentReset:
      return {
        ...state,
        requestAssortmentEdit: null
      };
    case RequestAssortmentActionTypes.RequestAssortmentApproveRejectSuccess:
      return {
        ...state,
        isApproveRejectSuccess: action.payload.isSuccess
      };
    case RequestAssortmentActionTypes.RequestAssortmentCopySuccess:
      return {
        ...state,
        requestAssortment: {
          ...action.payload
        }
      };
    case RequestAssortmentActionTypes.RequestAssortmentClearCopySuccess:
      return {
        ...state,
        requestAssortment: {}
      };
    case RequestAssortmentActionTypes.RequestAssortmentCheckCJDuplicateBarcodeResponse: {
      return {
        ...state,
        CJBarcodeDuplicateList: action.payload
      };
    }
    case RequestAssortmentActionTypes.RequestAssortmentCheckCJDuplicateBarcodeReset: {
      return {
        ...state,
        CJBarcodeDuplicateList: null
      };
    }
    case RequestAssortmentActionTypes.RequestAssortmentDuplicateBarcode: {
      return {
        ...state,
        duplicateBarcode: action.payload
      };
    }
    case RequestAssortmentActionTypes.RequestAssortmentDuplicateBarcodeReset: {
      return {
        ...state,
        duplicateBarcode: null
      };
    }
    case RequestAssortmentActionTypes.RequestAssortmentSubmitError: {
      return {
        ...state,
        submitError: action.payload
      };
    }
    case RequestAssortmentActionTypes.RequestAssortmentSupplierPriceError: {
      return {
        ...state,
        supplierPriceError: action.payload
      };
    }
    case RequestAssortmentActionTypes.RequestAssortmentSupplierPriceErrorReset: {
      return {
        ...state,
        supplierPriceError: null
      };
    }
    case RequestAssortmentActionTypes.RequestAssortmentSubmitErrorReset: {
      return {
        ...state,
        submitError: null
      };
    }
    case RequestAssortmentActionTypes.LinkArticleDetailResponse: {
      return {
        ...state,
        cjProductLinkArticleDetail: action.payload
      };
    }
    case RequestAssortmentActionTypes.LinkArticleDetailReset: {
      return {
        ...state,
        cjProductLinkArticleDetail: null
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
