import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RequestSectionEnum } from '../../../../../shared/enum/request-section.enum';
import { RequestStatusEnum } from '../../../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../../../shared/enum/request-step.enum';
import { TDStorePage } from '../../../../../shared/enum/td-store-page.enum';
import { MerchantRequestViewResponse, StoreRequestViewResponse } from '../../../../../shared/models';
import { AppStates } from '../../../../../shared/store/state/app.states';
import { getSelectByPage } from '../../../../../shared/utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../../../shared/utils/td-store-workflow-util';

@Component({
  selector: 'app-near-by-competitor',
  templateUrl: './near-by-competitor.component.html',
  styleUrls: ['./near-by-competitor.component.scss']
})
export class NearByCompetitorComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public merchantRequestView$: Observable<MerchantRequestViewResponse>;
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() page: TDStorePage;
  @Input() index: number;
  @Input() mode: RequestPageModesEnum;
  @Input() buildingSizeSelectValue: NgOption;
  @Input() competitorSelectValue: NgOption;
  @Input() poiDistanceSelectValue: NgOption;

  private localStore: Observable<any>;
  private type: RequestTypeEnum;
  private step: RequestStepEnum;
  private status: RequestStatusEnum;

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppStates>,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    private readonly element: ElementRef
  ) {
    super();
  }

  ngOnInit() {
    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;

    this.parentForm.addControl('nearByCompetitors', this.fb.array([]));
    this.addForm();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.mode)) {
      this.setNearbyCompetitor();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  get nearByCompetitors() {
    return this.parentForm.get('nearByCompetitors') as UntypedFormArray;
  }

  addForm() {
    this.nearByCompetitors.push(this.createForm());
  }

  createForm() {
    const initialNull = [{ value: null, disabled: false }];
    return this.fb.group({
      competitorName: initialNull,
      unitSize: initialNull,
      distance: initialNull
    });
  }

  onSelectedCompetitor(rowIndex) {
    const row = this.nearByCompetitors.at(rowIndex);
    if (row.value.competitorName || row.value.unitSize || row.value.distance) {
      this.updateValidator(rowIndex, true);
    } else {
      this.updateValidator(rowIndex, false);
    }
  }

  updateValidator(rowIndex, isRequired) {
    const fieldUpdates = ['competitorName', 'distance'];
    for (const field of fieldUpdates) {
      this.nearByCompetitors
        .at(rowIndex)
        .get(field)
        .setValidators(isRequired ? Validators.required : null);
      this.nearByCompetitors
        .at(rowIndex)
        .get(field)
        .updateValueAndValidity();
    }
  }

  deleteNearByCompetitor(index) {
    this.nearByCompetitors.removeAt(index);

    if (this.nearByCompetitors.length === 0) {
      this.addForm();
    }
  }

  checkEditPermission() {
    return this.nearByCompetitors.disabled;
  }

  setNearbyCompetitor() {
    this.nearByCompetitors.disable();

    this.merchantRequestView$ = this.localStore.pipe(
      select(getSelectByPage(this.page)),
      filter(data => data !== null)
    );
    this.merchantRequestView$.subscribe((value: MerchantRequestViewResponse | StoreRequestViewResponse) => {
      this.type = value.type || RequestTypeEnum.EDIT;
      this.step = value.step || RequestStepEnum.EDIT_PROFILE;
      this.status = value.status || RequestStatusEnum.DRAFT;

      const currentData = value.merchantInfo?.storeProfile?.[this.index];

      if (currentData && currentData.nearByCompetitors && currentData.nearByCompetitors.length > 0) {
        currentData.nearByCompetitors.forEach((field, i) => {
          if (!this.nearByCompetitors.at(i)) {
            this.addForm();
          }

          this.nearByCompetitors.at(i).patchValue({
            competitorName: field.competitorName,
            unitSize: field.unitSize,
            distance: field.distance
          });

          this.onSelectedCompetitor(i);
        });
      }

      this.setNearbyCompetitorCtrl(this.type, this.page, this.step, this.mode);
    });
  }

  setNearbyCompetitorCtrl(
    localType: RequestTypeEnum,
    localPage: TDStorePage,
    localStep: RequestStepEnum,
    localMode: RequestPageModesEnum
  ) {
    const editSection = [TDStorePage.MERCHANT_EDIT, TDStorePage.MERCHANT_REQUEST].includes(localPage)
      ? RequestSectionEnum.PROFILE
      : RequestSectionEnum.STORE_PROFILE;

    let canEditByWorkflow = false;

    canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(localType, localPage, localStep, editSection);

    if (localMode === RequestPageModesEnum.REQUEST_EDIT && canEditByWorkflow) {
      this.nearByCompetitors.enable();
    } else {
      this.nearByCompetitors.disable();
    }
  }

  disableTableScroll(toDisable: boolean) {
    const el: HTMLInputElement = this.element.nativeElement.querySelector('.table-scroll');
    if (toDisable) {
      el.setAttribute('style', 'overflow-y: hidden');
    } else {
      el.removeAttribute('style');
    }
  }
}
