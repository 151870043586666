<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">Confirm</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hide()" id="confirm-modal-closeBtn">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-body admin-form">
    <div class="container-fluid">
      <div class="form-group">
        <div class="mb-3">
          Are you sure you want to terminate
          <span class="modal-store-info-header">"{{ storeCode }}-{{ storeName }}"</span>?
        </div>
        <label for="terminateDateModal"> Terminate Date<span class="text-danger">*</span></label>
        <input
          id="terminateDateModal"
          data-id="terminateDateModal"
          name="terminateDateModal"
          type="text"
          placeholder="{{ dateFormat }}"
          class="form-control calendar"
          autocomplete="off"
          [ngClass]="{
            'is-invalid': submitted && terminateDate.invalid
          }"
          bsDatepicker
          [bsConfig]="bsConfig"
          [formControl]="terminateDate"
          [minDate]="storeOpenDate"
          [maxDate]="currentDate"
          readonly
        />
        <label for="terminateDateModal" class="icon-calendar btn-calendar"></label>
        <div *ngIf="submitted && terminateDate.errors" class="invalid-feedback">
          <div *ngIf="terminateDate.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="decline()" id="confirm-modal-cancelBtn">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      id="confirm-modal-okBtn"
      appDebounceClick
      (debounceClick)="confirm()"
    >
      Yes, confirm
    </button>
  </div>
</div>
