import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  StoreGroup,
  StoreGroupDto,
  StoreGroupListResponse,
  StoreGroupSearchCriteria,
  StoreGroupUser
} from '@shared/models';
import { MetricAction, MetricActionType, MetricModule, MetricType, Metrics } from '@shared/models/metrics';
import { BaseService } from '@shared/services/base.service';

@Injectable()
export class StoreGroupService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.storeGroup;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteriaRequest(criteria: StoreGroupSearchCriteria): Observable<StoreGroupListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STORE_GROUP,
          route: this.envService.groupList,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getFullUrl(this.envService.groupList);
    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  getGroup(merchantNo: string, groupNo: string): Observable<StoreGroup> {
    const url = this.getFullUrl(this.envService.get, {
      merchantId: merchantNo,
      groupId: groupNo
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public submit(data: StoreGroupDto): Observable<any> {
    const url = this.getFullUrl(this.envService.submit, {
      merchantId: data.merchant
    });
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.STORE_GROUP,
          path: window.location.pathname
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.STORE_GROUP,
          action: MetricAction.STORE_GROUP_CREATE,
          type: 'CREATE',
          items: 1
        }
      ]
    };

    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(3000, metricsReq)
    });
  }

  public update(merchantNo: string, groupNo: string, data: StoreGroupDto): Observable<any> {
    const url = this.getFullUrl(this.envService.get, {
      merchantId: merchantNo,
      groupId: groupNo
    });
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.STORE_GROUP,
          path: window.location.pathname
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.STORE_GROUP,
          action: MetricAction.STORE_GROUP_EDIT,
          type: 'EDIT',
          items: 1
        }
      ]
    };

    return this.http.put<any>(url, data, {
      headers: this.loaderHeaders(3000, metricsReq),
      observe: 'body'
    });
  }

  public getStoreGroupsByMerchant(merchantNo: string): Observable<StoreGroupUser[]> {
    const url = this.getFullUrl(this.envService.submit, {
      merchantId: merchantNo
    });
    return this.http.get<StoreGroupUser[]>(url, { headers: this.headers, observe: 'body' });
  }
}
