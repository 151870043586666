export enum RequestSectionEnum {
  PROFILE = 'PROFILE',
  LEGAL_DOCUMENT = 'LEGAL_DOCUMENT',
  WALLET = 'WALLET',
  ORDER_POLICY = 'ORDER_POLICY',
  FIRST_LOT_ORDER = 'FIRST_LOT_ORDER',
  STORE_PROFILE = 'STORE_PROFILE',
  STORE_CONDITION = 'STORE_CONDITION',
  STORE_CONTACT = 'STORE_CONTACT',
  STORE_LOCATION = 'STORE_LOCATION'
}
