import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import { RoleContent, RoleSearchCriteria } from '@shared/models';

import { RoleActionTypes, RoleActions } from './role.actions';
import { AppRolesListState } from './role.state';

export const rolesListModuleReducers: ActionReducerMap<Partial<AppRolesListState>> = {
  rolesList: RoleListReducers
};

export interface RoleListState extends EntityState<RoleContent> {
  isLoading: boolean;
  criteriaObject: RoleSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<RoleContent> = createEntityAdapter<RoleContent>();

export const initialRoleListState: RoleListState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  auditLogs: null
});

export function RoleListReducers(state = initialRoleListState, action: RoleActions): RoleListState {
  switch (action.type) {
    case RoleActionTypes.ROLE_LIST_REQUEST_ACTION:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case RoleActionTypes.ROLE_LIST_RESPONSE_ACTION:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
