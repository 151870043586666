import { Action } from '@ngrx/store';

import { ClientIdTypeEnum } from '../../enum/client-id.enum';
import {
  AuthUserInfo,
  ChangeStatusUserRequest,
  ErrorResponse,
  ResetPasswordPinRequest,
  TenantInformation,
  UserListResponse,
  UserSearchCriteria,
  UserViewResponse
} from '../../models';
import { BaseApiResponse } from '../../models/base-api-response';
import { ChangePasswordRequest, ChangePasswordResponse } from '../../models/force-change-password.model';
import { UserDto } from './../../models/user.model';

export enum UserActionTypes {
  USER_INFO = '[User] User Info',
  USER_TENANT = '[User] Tenant Info',
  USER_CLIENT_ID = '[User] Client ID',
  USER_INFO_RESET = '[User] User Info Reset',
  USER_PASSWORD_RESET = '[User] User Password Reset',

  USER_LIST_REQUEST = '[User] List Request',
  USER_LIST_RESPONSE = '[User] List Response',
  USER_LIST_ERROR = '[User] List Error',

  USER_CREATE_REQUEST = '[User] User create request',
  USER_CREATE_RESPONSE = '[User] User create response',
  USER_CREATE_RESPONSE_ERROR = '[User] User create response error',
  USER_CREATE_RESPONSE_RESET = '[User] User create response reset',

  USER_UPDATE_REQUEST = '[User] User update request',
  USER_UPDATE_RESPONSE = '[User] User update response',
  USER_UPDATE_RESPONSE_ERROR = '[User] User update response error',
  USER_UPDATE_RESPONSE_RESET = '[User] User update response reset',

  USER_GET_BY_NO_REQUEST = '[User] Get User by UserNo Request',
  USER_GET_BY_NO_RESPONSE = '[User] Get User by UserNo Response',
  USER_GET_BY_NO_ERROR = '[User] Get User by UserNo Error',
  USER_GET_BY_NO_RESET = '[User] Get User by UserNo Reset',

  USER_PASSWORD_RESET_REQUEST = '[User] User Password Reset Request',
  USER_PASSWORD_RESET_RESPONSE = '[User] User Password Reset Response',
  USER_PASSWORD_RESET_RESPONSE_RESET = '[User] User Password Reset Response Reset',

  USERS_RESET_PIN_CODE_REQUEST = '[Users] Reset Pin Code Request',
  USERS_RESET_PIN_CODE_RESPONSE = '[Users] Reset Pin Code Response',
  USERS_RESET_PIN_CODE_ERROR = '[Users] Reset Pin Code Error',
  USERS_RESET_PASSWORD_PIN_CODE_RESET = '[User] Reset Password Pin Code Reset',

  CHANGE_PASSWORD_REQUEST = '[Force Change Password] ChangePassword Request',
  CHANGE_PASSWORD_SUCCESS = '[Force Change Password] ChangePassword Success',
  CHANGE_PASSWORD_FAILED = '[Force Change Password] ChangePassword Failed',
  CHANGE_PASSWORD_RESET = '[Force Change Password] ChangePassword Reset',

  USER_ACTIVATE_BY_USER_NAME_REQUEST = '[User] Activate User by UserNo Request',
  USER_ACTIVATE_BY_USER_NAME_RESPONSE = '[User] Activate User by UserNo Response',
  USER_ACTIVATE_BY_USER_NAME_RESET = '[User] Activate User by UserNo  Reset'
}

export class UserInformationAction implements Action {
  readonly type = UserActionTypes.USER_INFO;

  constructor(public payload: AuthUserInfo) {}
}

export class TenantInformationAction implements Action {
  readonly type = UserActionTypes.USER_TENANT;

  constructor(public payload: TenantInformation) {}
}

export class ClientIdAction implements Action {
  readonly type = UserActionTypes.USER_CLIENT_ID;

  constructor(public payload: ClientIdTypeEnum | null) {}
}

export class UserInformationResetAction implements Action {
  readonly type = UserActionTypes.USER_INFO_RESET;

  constructor() {}
}

export class UserPasswordResetAction implements Action {
  readonly type = UserActionTypes.USER_PASSWORD_RESET;

  constructor(public payload: { userName: string; merchant: string }) {}
}

export class UserCreateRequestAction implements Action {
  readonly type = UserActionTypes.USER_CREATE_REQUEST;

  constructor(public payload: UserDto) {}
}

export class UserCreateResponseAction implements Action {
  readonly type = UserActionTypes.USER_CREATE_RESPONSE;

  constructor(public payload: ErrorResponse) {}
}
export class UserCreateResponseErrorAction implements Action {
  readonly type = UserActionTypes.USER_CREATE_RESPONSE_ERROR;
  constructor(public payload: any) {}
}
export class UserCreateResponseResetAction implements Action {
  readonly type = UserActionTypes.USER_CREATE_RESPONSE_RESET;
}

export class UserUpdateRequestAction implements Action {
  readonly type = UserActionTypes.USER_UPDATE_REQUEST;

  constructor(public payload: UserDto) {}
}
export class UserUpdateResponseAction implements Action {
  readonly type = UserActionTypes.USER_UPDATE_RESPONSE;

  constructor(public payload: ErrorResponse) {}
}
export class UserUpdateResponseErrorAction implements Action {
  readonly type = UserActionTypes.USER_UPDATE_RESPONSE_ERROR;
  constructor(public payload: any) {}
}
export class UserUpdateResponseResetAction implements Action {
  readonly type = UserActionTypes.USER_UPDATE_RESPONSE_RESET;
}

export class UserListRequestAction implements Action {
  readonly type = UserActionTypes.USER_LIST_REQUEST;

  constructor(public payload: UserSearchCriteria) {}
}

export class UserListResponseAction implements Action {
  readonly type = UserActionTypes.USER_LIST_RESPONSE;
  constructor(public payload: UserListResponse) {}
}

export class UserListErrorAction implements Action {
  readonly type = UserActionTypes.USER_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class UserByUserNoRequestAction implements Action {
  readonly type = UserActionTypes.USER_GET_BY_NO_REQUEST;

  constructor(public payload: string) {}
}

export class UserByUserNoResponseAction implements Action {
  readonly type = UserActionTypes.USER_GET_BY_NO_RESPONSE;

  constructor(public payload: UserViewResponse) {}
}

export class UserByUserNoErrorAction implements Action {
  readonly type = UserActionTypes.USER_GET_BY_NO_ERROR;

  constructor(public payload: any) {}
}

export class UserByUserNoResetAction implements Action {
  readonly type = UserActionTypes.USER_GET_BY_NO_RESET;
}

export class UserResetPasswordRequestAction implements Action {
  readonly type = UserActionTypes.USER_PASSWORD_RESET_REQUEST;

  constructor(public payload: string) {}
}

export class UserResetPasswordResponseAction implements Action {
  readonly type = UserActionTypes.USER_PASSWORD_RESET_RESPONSE;
  constructor(public payload: any) {}
}

export class UserResetPasswordResponseResetAction implements Action {
  readonly type = UserActionTypes.USERS_RESET_PASSWORD_PIN_CODE_RESET;
}

export class ForceChangePasswordRequestAction implements Action {
  readonly type = UserActionTypes.CHANGE_PASSWORD_REQUEST;

  constructor(public payload: ChangePasswordRequest) {}
}

export class ForceChangePasswordSuccess implements Action {
  readonly type = UserActionTypes.CHANGE_PASSWORD_SUCCESS;

  constructor(public payload: ChangePasswordResponse) {}
}

export class ForceChangePasswordFailed implements Action {
  readonly type = UserActionTypes.CHANGE_PASSWORD_FAILED;

  constructor(public payload: ErrorResponse) {}
}

export class ForceChangePasswordReset implements Action {
  readonly type = UserActionTypes.CHANGE_PASSWORD_RESET;
  constructor() {}
}

export class UsersResetPinCodeRequestAction implements Action {
  readonly type = UserActionTypes.USERS_RESET_PIN_CODE_REQUEST;
  constructor(public payload: ResetPasswordPinRequest) {}
}

export class UsersResetPinCodeResponseAction implements Action {
  readonly type = UserActionTypes.USERS_RESET_PIN_CODE_RESPONSE;
  constructor(public payload?: BaseApiResponse) {}
}

export class UsersResetPinCodeErrorAction implements Action {
  readonly type = UserActionTypes.USERS_RESET_PIN_CODE_ERROR;
  constructor(public payload?: ErrorResponse) {}
}

export class ActivateUserRequestAction implements Action {
  readonly type = UserActionTypes.USER_ACTIVATE_BY_USER_NAME_REQUEST;
  constructor(public payload: ChangeStatusUserRequest) {}
}

export class ActivateUserResponseAction implements Action {
  readonly type = UserActionTypes.USER_ACTIVATE_BY_USER_NAME_RESPONSE;
  constructor(public payload: BaseApiResponse) {}
}

export class ActivateUserResetAction implements Action {
  readonly type = UserActionTypes.USER_ACTIVATE_BY_USER_NAME_RESET;
}

export type UserInfoActions =
  | ActivateUserRequestAction
  | ActivateUserResponseAction
  | ActivateUserResetAction
  | ForceChangePasswordRequestAction
  | ForceChangePasswordSuccess
  | ForceChangePasswordFailed
  | ForceChangePasswordReset
  | UserResetPasswordRequestAction
  | UserResetPasswordResponseAction
  | UserResetPasswordResponseResetAction
  | UsersResetPinCodeRequestAction
  | UsersResetPinCodeResponseAction
  | UsersResetPinCodeErrorAction
  | UserInformationAction
  | TenantInformationAction
  | ClientIdAction
  | UserInformationResetAction
  | UserPasswordResetAction
  | UserListRequestAction
  | UserListResponseAction
  | UserListErrorAction
  | UserCreateRequestAction
  | UserCreateResponseAction
  | UserCreateResponseErrorAction
  | UserCreateResponseResetAction
  | UserByUserNoRequestAction
  | UserByUserNoResponseAction
  | UserByUserNoErrorAction
  | UserByUserNoResetAction
  | UserUpdateRequestAction
  | UserUpdateResponseAction
  | UserUpdateResponseErrorAction
  | UserUpdateResponseResetAction;
