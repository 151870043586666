export enum MemberPointSettingPageModes {
  CREATE_REQUEST = 'CREATE',
  VIEW_REQUEST = 'VIEW_REQUEST',
  EDIT_REQUEST = 'EDIT_REQUEST',
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}

export enum MemberPointSettingStatusEnum {
  DRAFT = 'DRAFT',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED'
}

export enum MemberPointSettingPageTitleMode {
  VIEW = 'View',
  EDIT = 'Edit',
  VIEW_REQUEST = 'View'
}

export enum MemberPointScheduleSettingMode {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE'
}

export enum MemberPointScheduleSettingModeTitle {
  ADD = 'Add',
  EDIT = 'Edit',
  DELETE = 'Delete'
}

export enum PointSettingType {
  PURCHASE_AMOUNT = 'Purchase Amount',
  NEW_MEMBER = 'New Member Enrollment',
  LINE_CONNECT = 'Line Connect',
  SPECIAL_POINT = 'Special Point'
}

export enum PurchaseConditionTitleMode {
  CREATE = 'Create Purchase Condition Request',
  VIEW_REQUEST = 'View Purchase Condition Request',
  EDIT_REQUEST = 'Edit Purchase Condition  Request',
  VIEW = 'View Purchase Condition',
  EDIT = 'Edit Purchase Condition'
}

export enum PointSettingTypeEnum {
  PURCHASE_AMOUNT = 'PURCHASE_AMOUNT',
  NEW_MEMBER = 'NEW_MEMBER',
  LINE_CONNECT = 'LINE_CONNECT',
  SPECIAL_POINT = 'SPECIAL_POINT'
}

export enum SpecialPointEnum {
  ACTIVE = 'ACTIVE',
  AWAITING_SCHEDULE = 'AWAITING_SCHEDULE',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED'
}
