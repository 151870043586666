import { Pipe, PipeTransform } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MasterDataEnum } from '../enum/master-data.enum';
import { MasterService } from '../services/master.service';

@Pipe({
  name: 'masterDataDisplay'
})
export class MasterDataDisplayPipe implements PipeTransform {
  constructor(private masterService: MasterService, private readonly logger: NGXLogger) {}

  transform(state: string, type: MasterDataEnum): Observable<string> {
    return this.masterService.getMasterDataByNames([type]).pipe(
      map(data => data.data),
      map(data => {
        try {
          const result = data[type].find(masterData => masterData.code === state);
          return result ? result.nameTh : '-';
        } catch (e) {
          this.logger.error('MasterDataDisplayPipe', e);
          return '-';
        }
      })
    );
  }
}
