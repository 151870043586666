import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment as env } from '../../../../../../environments/environment';
import { beforeTodayValidator } from '../../../../../shared/custom-validators/before-today-validator';
import { OrderSubTypeEnum } from '../../../../../shared/enum/ordering-method.enum';
import { RequestPageModesEnum } from '../../../../../shared/enum/request-step.enum';
import { DeliveryDetails } from '../../../../../shared/models/order-request.model';
import { selectFixAssetDeliveryDetails } from '../../../../../shared/store/selectors/order-fix-asset-request.selector';
import { selectInventoryDeliveryDetails } from '../../../../../shared/store/selectors/order-inventory-request.selector';
import { selectDeliveryDetails } from '../../../../../shared/store/selectors/order-request.selector';
import { AppStates } from '../../../../../shared/store/state/app.states';

@Component({
  selector: 'app-delivery-schedule',
  templateUrl: './delivery-schedule.component.html',
  styleUrls: ['./delivery-schedule.component.scss']
})
export class DeliveryScheduleComponent extends OnDestroyMixin implements OnInit, OnDestroy, OnChanges {
  @Input() mode: RequestPageModesEnum;
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() openDate: Date;
  @Input() segment: string;
  @Input() noOfDateBefore: number;
  @Input() orderSubType: OrderSubTypeEnum;
  @Output() applyToAll: EventEmitter<string> = new EventEmitter<string>();

  private localStore: Observable<any>;
  private deliveryDetails$: Observable<DeliveryDetails>;

  public bsConfig: BsDatepickerConfig;
  public title: string;
  public dateFormat = env.dateFormat;
  public orderSubTypeList = OrderSubTypeEnum;

  constructor(public fb: UntypedFormBuilder, public store: Store<AppStates>) {
    super();
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.bsConfig = {
      dateInputFormat: env.dateFormat,
      maxDate: this.getMaxDate(),
      minDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue'
    } as BsDatepickerConfig;

    this.parentForm.setControl(this.segment, this.createForm());

    let deliveryDetailsSelector;
    if (this.orderSubType === OrderSubTypeEnum.FIX_ASSET_AND_STORE_USE) {
      deliveryDetailsSelector = selectFixAssetDeliveryDetails;
    } else if (this.orderSubType === OrderSubTypeEnum.INVENTORY) {
      deliveryDetailsSelector = selectInventoryDeliveryDetails;
    } else {
      deliveryDetailsSelector = selectDeliveryDetails;
    }

    this.deliveryDetails$ = this.localStore.pipe(select(deliveryDetailsSelector));

    this.deliveryDetails$.pipe(filter(data => Boolean(data && data.deliveryDetailsByType))).subscribe(value => {
      if (value.storeOpenDate && value.deliveryDetailsByType[this.segment]) {
        this.form.patchValue({
          ...value.deliveryDetailsByType[this.segment],
          deliveryDate: moment(value.deliveryDetailsByType[this.segment].deliveryDate, [
            env.dateFormat,
            moment.ISO_8601
          ]).toDate()
        });
      }
    });

    this.setTitle();
    this.setDeliveryScheduleCtrl();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openDate && changes.openDate.currentValue && !changes.openDate.firstChange) {
      this.setDefaultDate();
    }

    if (changes.mode && !changes.mode.firstChange) {
      this.setDeliveryScheduleCtrl();
    }
  }

  createForm() {
    return this.fb.group({
      deliveryDate: [{ value: null, disabled: false }, [Validators.required, beforeTodayValidator()]],
      deliveryNote: [{ value: null, disabled: false }]
    });
  }

  setDefaultDate() {
    const defaultDate = new Date(this.openDate.getTime());
    defaultDate.setDate(defaultDate.getDate() - this.noOfDateBefore);
    this.form.controls.deliveryDate.setValue(defaultDate);

    this.bsConfig.maxDate = this.getMaxDate();
  }

  getMaxDate() {
    if (this.openDate) {
      const maxDate = new Date(this.openDate.getTime());
      maxDate.setDate(maxDate.getDate() - 1);
      return maxDate;
    }
    return null;
  }

  setTitle() {
    const regex = /[^_]+$/g;
    const deliveryBy = regex.exec(this.segment)[0];
    this.title = deliveryBy === 'SUPPLIER' ? 'Supplier' : 'TD Warehouse';
  }

  applyToAllItems(value) {
    this.applyToAll.emit(value);
  }

  setDeliveryScheduleCtrl() {
    if (this.mode === RequestPageModesEnum.REQUEST_VIEW) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  get form() {
    return this.parentForm.controls[this.segment] as UntypedFormGroup;
  }

  get pageMode() {
    return RequestPageModesEnum;
  }
}
