import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { MonthlyPartnerProfitSharingUploadStatusEnum } from '../../../shared/enum/monthly-partner-profit-sharing.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { MonthlyPartnerProfitSharingService } from '../../../shared/services/monthly-partner-profit-sharing.service';
import { LayoutActionVersionError } from '../../../shared/store/actions/layout.action';
import { MonthlyPartnerProfitSharingResetUploadAction } from '../store/monthly-partner-profit-sharing/monthly-partner-profit-sharing.actions';
import { AppProfitSharingState } from '../store/profit-sharing.state';

@Component({
  selector: 'app-monthly-partner-profit-sharing-download-modal',
  templateUrl: './monthly-partner-profit-sharing-download-modal.component.html',
  styleUrls: ['./monthly-partner-profit-sharing-download-modal.component.scss']
})
export class MonthlyPartnerProfitSharingDownloadModalComponent extends OnDestroyMixin implements OnInit {
  public id: string;
  public refId: string;
  public runDate: string;
  public form: UntypedFormGroup;
  public monthlyPartnerProfitSharingUploadStatusEnum = MonthlyPartnerProfitSharingUploadStatusEnum;

  private monthlyPartnerProfitSharingObject;

  @Output() submitUpload: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    protected readonly store: Store<AppProfitSharingState>,
    protected readonly modalService: BsModalService,
    protected readonly monthlyPartnerProfitSharingService: MonthlyPartnerProfitSharingService
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.form.controls.uploadProfitSharing.valueChanges
      .pipe(filter(Boolean), distinctUntilChanged())
      .subscribe(value => {
        const response = value[0];
        this.monthlyPartnerProfitSharingObject = response;
      });
  }

  createForm() {
    this.form = this.fb.group({
      uploadProfitSharing: [null]
    });
  }

  downloadMonthlyProfitSharing() {
    if (!this.refId) {
      return;
    }

    this.monthlyPartnerProfitSharingService.downloadMonthlyProfitSharingReport(this.refId, this.id).subscribe({
      next: result => {
        window.open(result.signedUrl, '_parent');
      },
      error: error => {
        this.store.dispatch(new LayoutActionVersionError(error));
      }
    });
  }

  alertErrorModal(errorResponse) {
    if (errorResponse && errorResponse.error) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.error.message
      };

      const alertModal = this.modalService.show(AlertModalComponent, {
        backdrop: 'static',
        initialState
      });

      alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new MonthlyPartnerProfitSharingResetUploadAction());
          alertModal.hide();
          this.bsModalRef.hide();
        }
      });
    }
  }

  onSubmit() {
    if (this.monthlyPartnerProfitSharingObject.status !== MonthlyPartnerProfitSharingUploadStatusEnum.COMPLETED) {
      return;
    }
    const submitObject = {
      monthlyPartnerProfitSharingFile: this.monthlyPartnerProfitSharingObject,
      runDate: this.runDate
    };
    this.submitUpload.emit(submitObject);
  }
}
