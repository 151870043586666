<div class="full-popup-head">
  <div class="full-popup-title flex"><em class="modal-title-icon icon-td-marketing"></em>{{ data.title }}</div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="showCancelButton((promotionView$ | async)?.status) && hasEditPromotionPermission"
    (click)="goToCancel()"
  >
    <em class="icon-forbidden"></em>Cancel Promotion
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div>
  <div class="admin-form" [formGroup]="form">
    <div class="info-header-container">
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Promotion Code:</span>
        </div>
        <div class="info-header-value">
          <span>{{ (promotionView$ | async)?.promotionCode }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Promotion Status:</span>
        </div>
        <div class="info-header-value">
          <span [ngClass]="getColorStatus(status)" class="request-status">
            {{ 'PROMOTION.STATUS.' + status | translate | titlecase }}</span
          >
        </div>
      </div>
    </div>

    <div>
      <div class="row section-header">
        <span>Promotion Details</span>
      </div>
      <div>
        <app-promotion-details
          #promotionDetails
          [mode]="data.mode"
          [submitted]="submitted"
          [parentForm]="promotionForm"
          [saveDraft]="saveDraft"
        >
        </app-promotion-details>
      </div>

      <hr class="section-divider" />

      <div class="row section-header">
        <span>Promotion Condition</span>
      </div>
      <div>
        <app-promotion-condition
          #promotionCondition
          [mode]="data.mode"
          [submitted]="submitted"
          [parentForm]="promotionForm"
          [saveDraft]="saveDraft"
        >
        </app-promotion-condition>
      </div>

      <hr class="section-divider" />

      <div class="row section-header">
        <span>Promotion Item</span>
      </div>
      <div class="mb-5">
        <app-promotion-item
          class="mb-5"
          #promotionItem
          [mode]="data.mode"
          [submitted]="submitted"
          [promotionItemType]="'promotionItem'"
          [parentForm]="promotionForm"
          [saveDraft]="saveDraft"
        >
        </app-promotion-item>
      </div>

      <hr class="section-divider" />

      <div class="row section-header" [hidden]="!isCoPromotion()">
        <span>Co-Promotion Item</span>
      </div>
      <div class="mb-5" [hidden]="!isCoPromotion()">
        <app-promotion-item
          class="mb-5"
          #coPromotionItem
          [submitted]="submitted"
          [promotionItemType]="'coPromotionItem'"
          [mode]="data.mode"
          [parentForm]="promotionForm"
          [saveDraft]="saveDraft"
        >
        </app-promotion-item>
      </div>

      <div class="row section-header">
        <span>Select Store</span>
      </div>
      <div class="mb-5">
        <app-promotion-store
          id="promotionStore"
          class="mb-5"
          [submitted]="submitted"
          [mode]="data.mode"
          [status]="status"
          [promotion]="promotion"
          [parentForm]="promotionForm"
          [saveDraft]="saveDraft"
          #promotionStore
        >
        </app-promotion-store>
      </div>
    </div>

    <!--footer-->
    <div class="fixed-row-bottom form-row"></div>
    <!--End footer-->
  </div>
</div>
