import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import { BaseReport } from '../models';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class MemberRewardsService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, protected readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.memberRewards;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportMemberRewardAccounting<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.memberRewardAccountingReport);

    return this.http.post<any>(url, exportCriteria, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
