<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()" id="confirm-modal-closeBtn">
      <span aria-hidden="true"><em class="icon-close"></em></span>
    </button>
  </div>
  <div class="modal-body" [ngClass]="bodyClass">
    <div *ngIf="message" [innerHTML]="message" [ngClass]="messageClass"></div>
    <ng-container *ngTemplateOutlet="template; context: { $implicit: details }"></ng-container>
  </div>
  <div class="modal-footer" *ngIf="hasSubmit || hasCancel">
    <button
      type="button"
      id="btn-cancel"
      data-id="btn-cancel"
      class="btn btn-standard"
      *ngIf="hasCancel"
      (click)="cancel()"
    >
      Cancel
    </button>
    <button
      *ngIf="hasSubmit"
      type="button"
      id="btn-confirm"
      data-id="btn-confirm"
      class="btn btn-primary"
      (click)="submit()"
    >
      {{ submitText ? submitText : 'OK' }}
    </button>
  </div>
</div>
