import { BaseReport } from '@shared/models/base-report.model';

import { GoodsReceiveStatusEnum } from '../enum/goods-receive.enum';
import { ProductTypeEnum } from '../enum/product-type.enum';
import { ShipToType } from '../enum/purchase-order.enum';
import { PurchaseRequestTypeEnum } from '../enum/purchase-request.enum';
import { AuditLog } from './audit-log.model';
import { ProductChange, PurchaseRequestProductError } from './purchase-request.model';

export class GoodsReceiveSearchCriteria {
  searchCriteria?: string;
  status?: GoodsReceiveStatusEnum;
  purchaseType?: string[];
  createdDateFrom?: string;
  createdDateTo?: string;
  page: number;
  size: number;
}

export class GoodsReceiveContent {
  id: string;
  docNo: string;
  poNo: string;
  doNo: string;
  type: PurchaseRequestTypeEnum;
  shipToCodeName: string;
  totalAmount: number;
  supplierName: string;
  receiver: string;
  createdDate: string;
  status: GoodsReceiveStatusEnum;
  advance?: boolean;
  orderSchedule?: string;
  auditLogs: AuditLog[];
  productType: ProductTypeEnum;
  shipToType?: ShipToType;
  shipToCode?: string;
}

export class GoodsReceivePagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: GoodsReceiveContent[];
}

export class GoodsReceive {
  id: string;
  docNo: string;
  poNo: string;
  supplierCode: string;
  supplierName: string;
  receiver: string;
  createdDate: Date;
  status: GoodsReceiveStatusEnum;
  doNo: string;
  docRefNo: string;
  items: GoodsReceiveItem[];
  freeItems?: GoodsReceiveFreeItem[];
  subTotalAmount: number;
  vatAmount: number;
  totalAmount: number;
  articleType: string;
  shipToType: ShipToType;
  shipToCodeName: string;
  roRefNo?: string;
  supplierVat?: boolean;
  shipToCode: string;
  productType: ProductTypeEnum;
  auditLogs?: AuditLog[];
}

export class GoodsReceiveItem {
  itemNo: string;
  productName: string;
  articleNo: string;
  barcode: string;
  orderUnit: string;
  unitPrice: number;
  orderQuantity: number;
  remainingQuantity: number;
  receiveQuantity: number;
  vatPct: number;
  vatAmount: number;
  receiveAmount: number;
  unitFactor: number;
  freeItems: GoodsReceiveFreeItem[];
  productVat?: boolean;
  unitPriceAfterDiscount?: number;
  unitPriceBeforeDiscount?: number;
  discountCondition?: GoodsReceiveDiscountCondition;
  discountAmountPerUnit?: number;
  orderUnitFactor?: number;
  subTotalAmountAfterDiscount?: number;
  totalAmountAfterDiscount?: number;
  vatAmountAfterDiscount?: number;
  vatTotalAmount?: number;
}

export class GoodsReceiveFreeItem {
  refArticleNo: string;
  refBarcode: string;
  productName: string;
  articleNo: string;
  barcode: string;
  orderUnit: string;
  orderQuantity: number;
  remainingQuantity: number;
  receiveQuantity: number;
  unitFactor: number;
  discountAmountPerUnit?: number;
  discountCondition?: GoodsReceiveDiscountCondition;
  itemNo?: number;
  orderUnitFactor?: number;
  productVat?: number;
  receiveAmount?: number;
  subTotalAmountAfterDiscount?: number;
  totalAmountAfterDiscount?: number;
  unitPrice?: number;
  unitPriceAfterDiscount?: number;
  unitPriceBeforeDiscount?: number;
  vatAmount?: number;
  vatAmountAfterDiscount?: number;
  vatPct?: number;
  vatTotalAmount?: number;
}

export interface GoodsReceiveDiscountCondition {
  articleNo: string;
  discountByPercent: number;
  effectiveDate: string;
  expireDate: string;
  productLevel: string;
  supplierCode: string;
  wmsCode: string;
  wmsCodeName: string;
}

export interface GoodsReceiveCancelRequestData {
  id: string;
  comment: string;
}

export class GoodsReceiveErrorResponse {
  code: string;
  message: string;
  items: GoodsReceiveItemDetails[];
}

export interface GoodsReceiveItemDetails {
  itemNo: number;
  changes?: ProductChange[];
  errors?: PurchaseRequestProductError[];
}

export class GoodsReceiveExportCriteria extends BaseReport {
  createdDateFrom: string;
  createdDateTo?: string;
  statuses: GoodsReceiveStatusEnum[];
  supplierCode: string;
  search: string;
  locations: string[];
  fileType: string;

  constructor(init?: Partial<GoodsReceiveExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
