import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { VatDetailsModalComponent } from '../../../shared/components/vat-details-modal/vat-details-modal.component';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { OrderColorStatusEnum } from '../../../shared/enum/order-color-status.enum';
import { StoreType } from '../../../shared/enum/store.enum';
import { GraphqlQueryObject } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ErrorResponse } from '../../../shared/models';
import { AuditLog } from '../../../shared/models/audit-log.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { OrderContent, OrderItem, OrderPrintCriteria, OrderTabs } from '../../../shared/models/order.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { OrderService } from '../../../shared/services/order.service';
import {
  OrderHistoryRequested,
  OrderReleaseOrderRequested,
  OrderReleaseOrderRequestedError,
  OrderViewLoaded,
  OrderViewRequested
} from '../../../shared/store/actions/order.actions';
import {
  selectOrderAuditLogs,
  selectOrderReleaseError,
  selectViewOrder
} from '../../../shared/store/selectors/order.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { StoreInfoComponent } from '../order-components/store-info/store-info.component';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { orderNo: string; title: string };

  private localStore: Observable<any>;

  public dateFormat = environment.dateFormat;
  public dateTimeDisplay = environment.dateTimeDisplay;
  public viewOrder$: Observable<OrderContent>;
  public productDetails: Array<OrderItem>;

  public orderTabs = OrderTabs;
  public selectedTab: OrderTabs;
  public vatPct$: Observable<number>;

  public hasReleasePreOrderPermission: boolean;
  public hasReleaseMemberRewardPermission: boolean;

  auditLogs$: Observable<AuditLog[]>;

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    protected orderService: OrderService,
    protected readonly modalService: BsModalService,
    protected masterService: MasterService,
    protected authGuardService: AuthGuardService
  ) {
    super(store, modalService);
  }

  ngOnInit() {
    const countryQuery = new GraphqlQueryObject();

    countryQuery.name = MasterDataEnum.COUNTRY;
    countryQuery.fields = ['id', 'code', 'nameEn', 'vatPct'];

    this.vatPct$ = this.masterService
      .getMasterDataByNames([countryQuery])
      .pipe(map(result => result.data.countries.find(data => data.nameEn === environment.defaultCountry).vatPct));

    this.selectedTab = OrderTabs.ORDER_DETAILS;
    this.store.dispatch(new OrderViewRequested(this.data.orderNo));
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.viewOrder$ = this.localStore.pipe(
      select(selectViewOrder),
      filter(data => Boolean(data && data.items))
    );
    this.viewOrder$.subscribe(data => {
      this.productDetails = data.items;
    });

    this.hasReleasePreOrderPermission = this.authGuardService.checkPermission(['pe_order_m']);
    this.hasReleaseMemberRewardPermission = this.authGuardService.checkPermission(['me_order_m']);

    this.initialOrderReleaseErrorEffect();

    this.auditLogs$ = this.localStore.pipe(select(selectOrderAuditLogs));
    this.store.dispatch(new OrderHistoryRequested(this.data.orderNo));
  }

  initialOrderReleaseErrorEffect() {
    this.localStore
      .pipe(
        select(selectOrderReleaseError),
        filter(data => Boolean(data)),
        switchMap(() => {
          const confirmModalRef = this.modalService.show(AlertModalComponent, {
            initialState: {
              title: 'Failed',
              message: `Not allow to release order. Stock allocation is under processing.<br/> Please try again later.`
            }
          });
          return confirmModalRef.content.action;
        })
      )
      .subscribe(() => {
        this.onClose();
        this.store.dispatch(new OrderReleaseOrderRequestedError(null));
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(new OrderViewLoaded(null));
    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }
  }

  getColorStatus(status: string) {
    return OrderColorStatusEnum[status];
  }

  onSelectTabs(tab: OrderTabs) {
    this.selectedTab = tab;
  }

  onPrintPdf(order: OrderContent) {
    if (!order) {
      return;
    }
    const param: OrderPrintCriteria = {
      id: order.id,
      format: 'pdf',
      locale: 'th'
    };

    this.orderService.printPdfOrder(param).subscribe({
      next: response => {
        const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, this.getGeneratedFileName(order.orderNo));
      },
      error: error => {
        this.alertErrorModal(error.error);
      }
    });
  }

  getGeneratedFileName(orderNo: string): string {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.downloadPdf.timeFormat);
    const formattedName = orderNo ? orderNo.replace('/', '_') : '';
    return `${formattedName}_${formattedDate}`;
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      if (errorResponse.code !== '00001') {
        initialState.message = this.translate.instant(errorResponse.translateKey);
        this.modalService.show(AlertModalComponent, {
          initialState
        });
      }
    }
  }

  showVatDetails() {
    const vatDetails = [];

    this.productDetails.forEach((item, i) => {
      const data = {
        itemNo: i + 1,
        productName: item.productName,
        vatAmount: item.vatAmount && item.vatAmount.amount,
        vat: 0,
        isInvalid: false
      };

      if (item.vat) {
        this.vatPct$.subscribe(vatPct => (data.vat = vatPct));
      }

      vatDetails.push(data);
    });

    this.modalService.show(VatDetailsModalComponent, {
      initialState: {
        title: 'VAT',
        vatDetails
      }
    });
  }

  isCanPrintPDF(status: string) {
    return ['NEW_ORDER', 'PROCESSING', 'PARTIAL_DELIVERED', 'CLOSED', 'DELIVERED'].includes(status);
  }

  onClose() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  isSelectedTab(tab: OrderTabs) {
    return this.selectedTab === tab;
  }

  getStoreIcon(storeType?: StoreType) {
    return storeType === StoreType.STORE_MODEL ? 'icon-storemodel' : 'icon-partnermodel';
  }

  showHistory() {
    const initialState = {
      title: 'History',
      historyHeader: `Order No.: ${this.data.orderNo}`,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  showStoreInfo(order: OrderContent) {
    const initialState = {
      title: 'Store Information',
      historyHeader: `Store: ${order.customerDetail.storeCode}-${order.customerDetail.storeName}`,
      customerDetail: order.customerDetail
    };
    this.modalService.show(StoreInfoComponent, {
      initialState
    });
  }

  canReleasePreOrder(orderContent: OrderContent): boolean {
    if (orderContent.orderType.toUpperCase() === 'PRE_ORDER') {
      return orderContent.status === 'AWAITING_ALLOCATION' && this.hasReleasePreOrderPermission;
    }

    if (orderContent.orderType.toUpperCase() === 'MEMBER_REWARD') {
      return orderContent.status === 'AWAITING_ALLOCATION' && this.hasReleaseMemberRewardPermission;
    }

    return false;
  }

  onReleaseOrder(order: OrderContent) {
    if (!order) {
      return;
    }

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to release Order No. <strong>&quot;${order.orderNo}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, release'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new OrderReleaseOrderRequested({
              orderNo: order.orderNo,
              version: order.version,
              reason: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  transformWareHouseName(warehouseName: string, deliveryByName: string) {
    if (deliveryByName === 'SUPPLIER') {
      return '(None)';
    }
    return warehouseName;
  }

  doAfterVersionAlertModal() {}

  doAfterSuccessModal() {
    // intentionally empty
  }

  alertForSaveSuccessModal() {}

  alertForVersionErrorModal() {}

  subscribeForSaveSuccess() {}

  subscribeForVersionError() {}
}
