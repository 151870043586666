export interface AuditLog {
  activity: string;
  description?: string;
  descriptions?: string[];
  editBy: string;
  editByName: string;
  editDate: string;
  activities?: Activity[];
  activityDetail?: string;
}

export interface Activity {
  activity: string;
  descriptions: string[];
}
export enum HistoryType {
  REQUEST = 'REQUEST',
  PRODUCT_ASSORTMENT = 'PRODUCT_ASSORTMENT',
  COMPENSATE = 'COMPENSATE',
  PRICE_SETTING_CLASS = 'PRICE_SETTING_CLASS',
  STACK_PRICING = 'STACK_PRICING',
  MERCHANT_REQUEST = 'MERCHANT_REQUEST',
  STORE = 'STORE',
  CLAIM_REQUEST = 'CLAIM_REQUEST',
  STORE_REQUEST = 'STORE_REQUEST',
  PROMOTION = 'PROMOTION',
  PRE_ORDER = 'PRE_ORDER',
  MERCHANT = 'MERCHANT',
  VOUCHER = 'VOUCHER',
  POS_ADVERTISEMENT = 'POS_ADVERTISEMENT',
  REWARD = 'REWARD',
  REWARD_CATALOG = 'REWARD_CATALOG',
  REWARD_CATALOG_REQUEST = 'REWARD_CATALOG_REQUEST',
  TASK_REQUEST = 'TASK_REQUEST',
  TASK_RESPONSE = 'TASK_RESPONSE',
  USER = 'USER',
  SUPPLIER = 'SUPPLIER',
  SHELF_REQUEST = 'SHELF_REQUEST',
  SHELF = 'SHELF',
  ORDER_REQUEST = 'ORDER_REQUEST',
  STORE_ASSORTMENT = 'STORE_ASSORTMENT',
  STORE_CONSULTANT = 'STORE_CONSULTANT',
  PROFIT_SHARING = 'PROFIT_SHARING',
  Z8_PARAMETER = 'Z8_PARAMETER',
  Z8_RESULT = 'Z8_RESULT',
  SSR_RESULT = 'SSR_RESULT',
  MEMBER = 'MEMBER',
  CUSTOMER_360 = 'CUSTOMER_360',
  APPROVAL_WORKFLOW = 'APPROVAL_WORKFLOW',
  TD_ASSORTMENT = 'TD_ASSORTMENT',
  STORE_ASSORTMENT_RECOMMENDATION = 'STORE_ASSORTMENT_RECOMMENDATION',
  TOTE_ADJUSTMENT = 'TOTE_ADJUSTMENT',
  HOLD_ORDER_REQUEST = 'HOLD_ORDER_REQUEST',
  STOCK_ADJUST = 'STOCK_ADJUST'
}
