import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ConfirmRefundModel,
  RefundList,
  RefundListSearchCriteria,
  RefundRequestContent
} from '../../pages/refund/model/refund.model';
import { RefundExportCriteria } from '../models/report.model';
import { BaseService } from './base.service';

@Injectable()
export class RefundService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.refund;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: RefundListSearchCriteria): Observable<RefundList> {
    criteria = { ...criteria };
    const params = this.getParams(criteria, true);
    return this.http.get<RefundList>(this.getUrl(), {
      headers: this.loaderHeaders(null),
      observe: 'body',
      params
    });
  }

  public getRefundById(id: string): Observable<RefundRequestContent> {
    const url = this.getFullUrl(this.envService.get, { id });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public exportRefund(reportCriteria: RefundExportCriteria) {
    const url = this.getFullUrl(this.envService.export);
    // const metrics = metricsReporting([this.envService.url, this.envService.export]);
    const header = new HttpHeaders({
      'x-host': 'report.api.tdshop.io',
      'x-tenant-id': '*:*:*:*',
      'x-frontend-spinner-loader': '0'
    });
    // reportCriteria
    return this.http.post<any>(url, reportCriteria, {
      headers: header,
      observe: 'body'
    });
  }

  public confirmRefund(refund: ConfirmRefundModel): Observable<any> {
    const url = this.getFullUrl(this.envService.confirm);

    return this.http.post<any>(url, refund, {
      headers: this.loaderHeaders(2000),
      observe: 'body'
    });
  }
}
