import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AssortmentDeliveryMethodEnum } from '../models';
import { FileResponse } from '../models/fileResponse';
import { MetricAction, MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { Progress } from '../models/progress.model';
import {
  SupplierPriceExportCriteria,
  SupplierPricePagination,
  SupplierPriceSearchCriteria
} from '../models/supplier-price.model';
import { BaseService } from './base.service';

type UploadResultType = Progress | FileResponse | null | any;

@Injectable()
export class SupplierPriceConfigService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.supplierPrice;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public uploadSupplierPriceFiles(
    files: Array<File>,
    deliveryMethod: AssortmentDeliveryMethodEnum
  ): Array<Observable<UploadResultType>> {
    return files.map(file => this.uploadSupplierPriceFile(file, deliveryMethod));
  }

  public uploadSupplierPriceFile(
    file: File,
    deliveryMethod: AssortmentDeliveryMethodEnum
  ): Observable<UploadResultType> {
    const url = this.getFullUrl(this.envService.import, {
      deliveryMethod
    });
    const formData = new FormData();

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.PRODUCT,
          path: 'supplier-price-import'
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.PRODUCT,
          action: 'supplier-price-import',
          type: 'SUPPLIER_PRICE'
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: 'supplier-price-import',
          action: MetricAction.IMPORT
        }
      ]
    };

    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.loaderHeaders(0, metricsReq)
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return { ...event.body, status: 'done' };
            default:
              const msg = `Unhandled event: ${HttpEventType[event.type]}`;
              this.logger.info(msg);
              return null;
          }
        }),
        catchError(err => {
          return of(err);
        })
      );
  }

  searchSupplierPrice(supplierPriceSearchCriteria: SupplierPriceSearchCriteria): Observable<SupplierPricePagination> {
    if (supplierPriceSearchCriteria.importId) {
      return this.searchSupplierPriceByImportId(supplierPriceSearchCriteria);
    } else {
      return this.searchSupplierPriceByArticleNo(supplierPriceSearchCriteria);
    }
  }

  searchSupplierPriceByArticleNo(
    supplierPriceSearchCriteria: SupplierPriceSearchCriteria
  ): Observable<SupplierPricePagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: this.envService.get,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(supplierPriceSearchCriteria, true);
    return this.http.get<any>(this.getFullUrl(this.envService.get), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  searchSupplierPriceByImportId(
    supplierPriceSearchCriteria: SupplierPriceSearchCriteria
  ): Observable<SupplierPricePagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.PRODUCT,
          action: 'supplier-price-import',
          type: 'SUPPLIER_PRICE'
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: this.envService,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(supplierPriceSearchCriteria, true);
    return this.http.get<any>(this.getUrl(), {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      params
    });
  }

  onExport(supplierPriceCriteria: SupplierPriceExportCriteria) {
    const params = this.getParams(supplierPriceCriteria, true);

    const url = this.getFullUrl(this.envService.export);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.SUPPLIER_PRICE,
          path: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.SUPPLIER_PRICE,
          route: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public downloadFileTemplate(deliveryMethod: { [key: string]: string }) {
    const params = this.getParams(deliveryMethod, true);

    const url = this.getFullUrl(this.envService.template);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
