import { createSelector } from '@ngrx/store';

import { AppStates } from '@shared/store/state/app.states';

import * as fromShelfRequestResponseState from './shelf-fix-asset-request.reducers';

const selectShelfFixAssetRequestState = (state: AppStates) => state.shelfFixAssetRequest;

export const selectAllShelfRequestList = createSelector(
  selectShelfFixAssetRequestState,
  fromShelfRequestResponseState.selectAll
);

export const selectShelfRequestList = createSelector(
  selectShelfFixAssetRequestState,
  (state: fromShelfRequestResponseState.ShelfFixAssetRequestState) => state
);

export const selectShelfRequestListCriteria = createSelector(selectShelfFixAssetRequestState, state => {
  return state.criteriaObject;
});

export const selectShelfFixAssetRequestById = createSelector(
  selectShelfFixAssetRequestState,
  (state: fromShelfRequestResponseState.ShelfFixAssetRequestState) => state.selected
);

export const selectShelfRequestHistory = createSelector(
  selectShelfFixAssetRequestState,
  (state: fromShelfRequestResponseState.ShelfFixAssetRequestState) => state.auditLogs
);

export const selectShelfDeleteStatus = createSelector(
  selectShelfFixAssetRequestState,
  (state: fromShelfRequestResponseState.ShelfFixAssetRequestState) => {
    return state.isDeleteSuccess;
  }
);
