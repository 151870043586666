import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EMPTY, Observable } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AlertModalTemplateComponent } from '../../../../shared/components/alert-modal-template/alert-modal-template.component';
import { ModalTemplateContainerComponent } from '../../../../shared/components/alert-modal-template/modal-template-container/modal-template-container.component';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { PosRegisterModes } from '../../../../shared/enum/store.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ConfirmWithMessageModalComponent } from '../../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { Device, ErrorResponse, POSStatus } from '../../../../shared/models';
import { AuditLog } from '../../../../shared/models/audit-log.model';
import { StoreService } from '../../../../shared/services/store.service';
import { AppStates } from '../../../../shared/store/state/app.states';
import { formatDate } from '../../../../shared/utils/date-util';
import { StoreByIdRequestAction } from '../../store/store.actions';
import { selectDevices } from '../../store/store.selectors';
import { STORE_DEVICE } from '../enum/store';
import { RegisterPOSComponent } from '../register-pos/register-pos.component';

@Component({
  selector: 'app-pos-terminal',
  templateUrl: './pos-terminal.component.html',
  styleUrls: ['./pos-terminal.component.scss']
})
export class POSTerminalComponent extends OnDestroyMixin implements OnInit {
  @ViewChild(ModalTemplateContainerComponent, { static: false })
  modalTemplateContainerComponent: ModalTemplateContainerComponent;
  @Input() data: { storeNo?: string; merchant?: string };
  @Input() showAddDevice = true;
  @Input() version: number;
  @Input() device: STORE_DEVICE;
  posStatus = POSStatus;
  devicePrefix: string;
  lableAddDevice: string;
  selector: any;

  private localStore: Observable<any>;
  public waitingDevice: Device;
  public activeDevice: Device;

  devices: Device[] = [new Device()];
  devicesAll: Device[] = [];

  constructor(
    private readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly storeService: StoreService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    if (this.device == STORE_DEVICE.HAND) {
      this.devicePrefix = 'H';
      this.lableAddDevice = 'Add HAND Device';
    } else if (this.device == STORE_DEVICE.POS) {
      this.devicePrefix = 'P';
      this.lableAddDevice = 'Add POS Device';
    }

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(
        untilComponentDestroyed(this),
        select(selectDevices),
        filter(devices => devices.length !== 0),
        tap(devices => {
          this.devicesAll = devices;
          const devicesList = devices.filter(device => device.type === this.device);
          if (devicesList && devicesList.length) {
            this.devices = this.filterDeviceByRunningNoAndStatus(devicesList);
          } else {
            this.devices = [new Device()];
          }
        })
      )
      .subscribe();
  }

  filterDeviceByRunningNoAndStatus(devices: Device[]) {
    const groupData = devices => {
      return devices.reduce((result, item) => {
        const key = item.runningNo;
        (result[key] || (result[key] = [])).push(item);
        return result;
      }, {});
    };

    const findStatusFromGroup = group => {
      const findStatus = group.find(val => [POSStatus.ACTIVATED, POSStatus.WAITING].includes(val.status));
      return findStatus ? findStatus : new Device();
    };

    // Main Function
    const groupedData = groupData(devices);

    return Object.keys(groupedData)
      .map(key => findStatusFromGroup(groupedData[key]))
      .sort((a, b) => a.runningNo - b.runningNo);
  }

  initialDefaultRegisDevice(index: number) {
    return {
      storeNo: this.data.storeNo,
      merchant: this.data.merchant,
      device: this.device,
      runningNo: index,
      version: this.version
    };
  }

  onRegister(index) {
    const initialState = {
      ...this.initialDefaultRegisDevice(index),
      mode: PosRegisterModes.CREATE,
      isRegenerate: false
    };

    this.modalService.show(RegisterPOSComponent, {
      initialState,
      backdrop: 'static'
    });
  }

  onUpdateRegisterPOS(device: Device) {
    const initialState = {
      ...this.initialDefaultRegisDevice(device.runningNo),
      mode: PosRegisterModes.EDIT,
      data: device
    };

    this.modalService.show(RegisterPOSComponent, {
      initialState,
      backdrop: 'static'
    });
  }

  getDeviceNo = (index: number) => {
    return this.devicePrefix + '' + String(index).padStart(2, '0');
  };

  onDeactivate(index: number) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to deactivate device "<b>${this.getDeviceNo(index)}</b>"?`,
        label: 'Reason',
        okText: 'Yes, deactivate',
        isRequiredConfirmMessage: true
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          return this.deactivateDeviceService(confirmModalRef, index);
        }),
        switchMap(_ => this.alertSuccessModal('deactivated')),
        catchError(error => {
          this.alertErrorModal(error);
          return EMPTY;
        })
      )
      .subscribe({
        next: () => {},
        error: _ => {},
        complete: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  deactivateDeviceService(confirmModalRef: BsModalRef<ConfirmWithMessageModalComponent>, runningNo: number) {
    return this.storeService
      .deactivatePOS(
        { storeNo: this.data.storeNo },
        { comment: confirmModalRef.content.confirmMessage, type: this.device, runningNo, version: this.version }
      )
      .pipe(untilComponentDestroyed(this));
  }

  alertSuccessModal(type: string) {
    const initialState = {
      title: 'Success',
      message: `Device has been ${type}.`
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    return alertModal.content.action.pipe(
      switchMap(result => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new StoreByIdRequestAction({
              merchant: { merchant: this.data.merchant },
              storeNo: { storeNo: this.data.storeNo }
            })
          );
        }

        return EMPTY;
      })
    );
  }

  alertErrorModal(errorResponse: any) {
    const error: ErrorResponse = errorResponse.error;
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(error.translateKey, { context: error.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  canUpdateRegisterPOS(status: string): boolean {
    return status === POSStatus.ACTIVATED;
  }

  dashDisplay = (data: string, status: POSStatus) => {
    return !data || status === POSStatus.DEACTIVATED ? '-' : data;
  };

  onAddDevice() {
    if (this.devices.length && !this.devices[this.devices.length - 1].deviceId) {
      const initialState = {
        title: 'Failed',
        message: 'There is available device.<br> Please register the existing device before add the new one.'
      };

      this.modalService.show(AlertModalComponent, {
        initialState
      });
      return;
    }

    this.devices = [...this.devices, new Device()];
  }

  regenerateCode(device: Device) {
    const initialState = {
      ...this.initialDefaultRegisDevice(device.runningNo),
      mode: PosRegisterModes.REGENERATE,
      data: device,
      isRegenerate: true
    };

    this.modalService.show(RegisterPOSComponent, {
      initialState,
      backdrop: 'static'
    });
  }

  showHistory = (runningNo: number) => {
    const auditLogs = this.prepareHistory(runningNo);

    const initialTemplate = {
      header: ['Deactivate Date', 'Device ID', 'Deactivate By'],
      content: auditLogs,
      config: {
        dateEmptyDisplay: '(None)',
        dateTimeDisplay: 'DD/MM/YYYY'
      }
    };
    const alertModal = this.modalService.show(AlertModalTemplateComponent, {
      initialState: {
        title: 'Previous Setting',
        template: this.modalTemplateContainerComponent?.multiDescAndHeader,
        details: initialTemplate,
        message: `Device: ${this.getDeviceNo(runningNo)}`,
        messageClass: 'text-center w-100 border-bottom pb-3 mb-2',
        bodyClass: 'py-0 px-1',
        submitText: 'Yes, confirm',
        hasSubmit: false,
        submitAction: () => {
          alertModal.hide();
        }
      }
    });
  };

  sortByRegisterDateDescending(runningNo: number) {
    const devices = this.devicesAll.filter(
      val => val.runningNo === runningNo && val.status === POSStatus.DEACTIVATED && val.type === this.device
    );
    devices.sort((a, b) => {
      const dateA: Date = new Date(a.lastModifiedDate);
      const dateB: Date = new Date(b.lastModifiedDate);

      return dateB.getTime() - dateA.getTime();
    });
    return devices;
  }

  convertHistory(device?: Device) {
    return {
      activities: [
        { activity: '', descriptions: [device?.deviceId || '(None)'] },
        { activity: 'RD Registered No.', descriptions: [device?.registeredNo || '(None)'] },
        { activity: 'Serial No.', descriptions: [device?.serialNo || '(None)'] },
        { activity: 'Effective Date', descriptions: [this.convertDateFormat(device?.effectiveDate)] },
        { activity: 'Last Updated', descriptions: [this.convertDateFormat(device?.registerDate)] }
      ],
      editBy: null,
      editDate: device?.lastModifiedDate || '(None)',
      editByName: device?.lastModifiedBy || '(None)'
    };
  }

  prepareHistory(runningNo: number): AuditLog[] {
    let history = [];
    let devices = this.sortByRegisterDateDescending(runningNo);
    if (devices.length) {
      history = devices.map(device => {
        return this.convertHistory(device);
      });
    } else {
      history = [this.convertHistory()];
    }
    return history as AuditLog[];
  }

  convertDateFormat(date: string) {
    return date ? formatDate(date, 'DD/MM/YYYY') : '(None)';
  }
}
