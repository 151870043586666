import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ClassMarkupService } from '../../services/class-markup.service';
import {
  ClassMarkupActionTypes,
  ClassMarkupLoaded,
  ClassMarkupRequested,
  ClassMarkupSearchLoaded,
  ClassMarkupSearchRequested,
  ClassMarkupUpdateRequested,
  ClassMarkupUpdateSuccess
} from '../actions/class-markup.actions';
import { LayoutActionLoadError } from '../actions/layout.action';

@Injectable()
export class ClassMarkupEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly classMarkupService: ClassMarkupService,
    private readonly logger: NGXLogger
  ) {}

  searchClassMarkup$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<ClassMarkupSearchRequested>(ClassMarkupActionTypes.ClassMarkupSearchRequested),
      tap(action => this.logger.debug('@Effect Class Markup Search: ' + this.stringify(action.payload))),
      switchMap(action => {
        return this.classMarkupService.searchByCriteria(action.payload).pipe(
          map(classMarkup => new ClassMarkupSearchLoaded({ classMarkup })),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  updateClassMarkup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ClassMarkupUpdateRequested>(ClassMarkupActionTypes.ClassMarkupUpdateRequested),
      tap(action =>
        this.logger.debug('@Effect Class Markup Update Request: ' + this.stringify(action.payload.classMarkup))
      ),
      switchMap(action => {
        return this.classMarkupService.updateById(action.payload.classMarkup).pipe(
          map(
            response =>
              new ClassMarkupUpdateSuccess({
                id: response.id,
                classMarkup: response
              })
          ),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  getClassMarkup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ClassMarkupRequested>(ClassMarkupActionTypes.ClassMarkupRequested),
      tap(action => this.logger.debug('@Effect Class Markup Request By ID: ' + this.stringify(action.payload))),
      switchMap(action => {
        return this.classMarkupService.getById(action.payload).pipe(
          map(response => new ClassMarkupLoaded({ classMarkup: response })),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
