<span
  class="reference-link"
  [title]="(isAllowToLink | pureFn: permissions:text:warehouseCode) ? 'Click to open reference document' : ''"
  (click)="linkToComponent($event)"
>
  <span
    [attr.id]="id ? id : text"
    [attr.data-id]="id ? id : text"
    [ngClass]="{ text: isAllowToLink | pureFn: permissions:text:warehouseCode }"
    >{{ text }}</span
  >
</span>

<!-- title="{{ isAllowToLink() ? 'Click to open reference document' : '' }}" -->
