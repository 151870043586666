import { Action } from '@ngrx/store';

import {
  SelectStoreDropdownList,
  SelectStoreDropdownPagingList,
  StoreDropdownSearchCriteria
} from '../../models/store-dropdown.model';

export enum StoreDropdownActionTypes {
  SELECT_STORE_DROPDOWNS_LIST_REQUEST = '[Select StoreDropdown List] Select StoreDropdown List Request',
  SELECT_STORE_DROPDOWNS_LIST_RESPONSE = '[Select StoreDropdown List] Select StoreDropdown List Response',
  SELECT_STORE_DROPDOWNS_PAGING_LIST_RESPONSE = '[Select StoreDropdown List] Select StoreDropdown Paging List Response',
  REMOVE_ALL_SELECT_STORE_DROPDOWN_LIST = '[Select StoreDropdown List] Remove All Select StoreDropdown List',
  RESET_STORE_DROPDOWN_LIST = '[Select StoreDropdown List] Reset All StoreDropdown List'
}

export class SelectStoreDropdownListRequestAction implements Action {
  readonly type = StoreDropdownActionTypes.SELECT_STORE_DROPDOWNS_LIST_REQUEST;

  constructor(public payload: StoreDropdownSearchCriteria) {}
}

export class SelectStoreDropdownListResponseAction implements Action {
  readonly type = StoreDropdownActionTypes.SELECT_STORE_DROPDOWNS_LIST_RESPONSE;
  constructor(public payload: SelectStoreDropdownList[]) {}
}

export class SelectStoreDropdownPagingListResponseAction implements Action {
  readonly type = StoreDropdownActionTypes.SELECT_STORE_DROPDOWNS_PAGING_LIST_RESPONSE;
  constructor(public payload: SelectStoreDropdownPagingList) {}
}

export class RemoveAllSelectStoreDropdownListAction implements Action {
  readonly type = StoreDropdownActionTypes.REMOVE_ALL_SELECT_STORE_DROPDOWN_LIST;
  constructor() {}
}

export class ResetStoreDropdownListAction implements Action {
  readonly type = StoreDropdownActionTypes.RESET_STORE_DROPDOWN_LIST;
  constructor() {}
}

export type StoreDropdownAction =
  | SelectStoreDropdownListRequestAction
  | SelectStoreDropdownListResponseAction
  | RemoveAllSelectStoreDropdownListAction
  | SelectStoreDropdownPagingListResponseAction
  | ResetStoreDropdownListAction;
