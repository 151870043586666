import { Action } from '@ngrx/store';

import {
  ProfitSharingData,
  ProfitSharingListPagination,
  ProfitSharingSearchCriteria
} from '../../../../shared/models/profit-sharing.model';

export enum ProfitSharingActionTypes {
  PROFIT_SHARING_LIST_REQUEST = '[Profit Sharing] Profit Sharing List request',
  PROFIT_SHARING_LIST_RESPONSE = '[Profit Sharing] Profit Sharing List response',
  PROFIT_SHARING_VIEW_REQUEST = '[Profit Sharing] Profit Sharing view request',
  PROFIT_SHARING_VIEW_RESPONSE = '[Profit Sharing] Profit Sharing view response',
  PROFIT_SHARING_SUBMIT_REQUEST = '[Profit Sharing] Profit Sharing submit request',
  PROFIT_SHARING_SUBMIT_ERROR = '[Profit Sharing] Profit Sharing submit error',
  PROFIT_SHARING_SUBMIT_ERROR_RESET = '[Profit Sharing] Profit Sharing submit error reset'
}

export class ProfitSharingListRequestAction implements Action {
  readonly type = ProfitSharingActionTypes.PROFIT_SHARING_LIST_REQUEST;
  constructor(public payload: ProfitSharingSearchCriteria) {}
}

export class ProfitSharingListResponseAction implements Action {
  readonly type = ProfitSharingActionTypes.PROFIT_SHARING_LIST_RESPONSE;
  constructor(public payload: ProfitSharingListPagination) {}
}

export class ProfitSharingViewRequestAction implements Action {
  readonly type = ProfitSharingActionTypes.PROFIT_SHARING_VIEW_REQUEST;
  constructor(public payload: string) {}
}

export class ProfitSharingViewResponseAction implements Action {
  readonly type = ProfitSharingActionTypes.PROFIT_SHARING_VIEW_RESPONSE;
  constructor(public payload: ProfitSharingData) {}
}

export class ProfitSharingSubmitRequestAction implements Action {
  readonly type = ProfitSharingActionTypes.PROFIT_SHARING_SUBMIT_REQUEST;
  constructor(public payload: ProfitSharingData) {}
}

export class ProfitSharingSubmitErrorAction implements Action {
  readonly type = ProfitSharingActionTypes.PROFIT_SHARING_SUBMIT_ERROR;
  constructor(public payload: string) {}
}

export class ProfitSharingSubmitErrorResetAction implements Action {
  readonly type = ProfitSharingActionTypes.PROFIT_SHARING_SUBMIT_ERROR_RESET;
  constructor() {}
}

export type ProfitSharingActions =
  | ProfitSharingListRequestAction
  | ProfitSharingListResponseAction
  | ProfitSharingViewRequestAction
  | ProfitSharingViewResponseAction
  | ProfitSharingSubmitRequestAction
  | ProfitSharingSubmitErrorAction
  | ProfitSharingSubmitErrorResetAction;
