import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BarcodeListSearchCriteria, BarcodeNameSearchCriteria, BarcodeResponse } from '../models/barcode.model';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';

@Injectable()
export class BarcodeService extends BaseService {
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.barcodes;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public headers: HttpHeaders;
  public env = environment;
  public envService: any;

  checkDuplicatedBarcode(barcodeNumber: string): Observable<any> {
    const url = this.getFullUrl(this.envService.verifyBarcode, { barcodeNumber });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  searchBarcodeByCriteria(
    barcode: string[],
    criteria: BarcodeListSearchCriteria = new BarcodeListSearchCriteria()
  ): Observable<BarcodeResponse[]> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: this.envService.getList,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getFullUrl(this.envService.getList);
    const params = this.getParams(criteria);

    return this.http.post<any>(url, barcode, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  searchBarcodeByName(barcodeNameSearchCriteria: BarcodeNameSearchCriteria): Observable<BarcodeResponse[]> {
    const params = this.getParams(barcodeNameSearchCriteria);
    return this.http.get<BarcodeResponse[]>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }
}
