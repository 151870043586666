import { ArticleTypeEnum } from '../enum/article-type.enum';
import { ProductGradingEnum } from '../enum/product-grading.enum';
import { ProductTypeEnum } from '../enum/product-type.enum';
import { AuditLog } from './audit-log.model';
import { Sort } from './pagination.model';
import { ProductMasterSelectStatusEnum } from './product-master.model';
import { SupplierPriceContent } from './supplier-price.model';

export class RequestAssortmentSearchCriteria {
  searchCriteria?: string;
  beginRequestDate?: string;
  endRequestDate?: string;
  requestType?: string[];
  status?: string;
  sortBy?: string;
  sortOrder?: string;
  page: number;
  size: number;
}

export class RequestAssortmentResponse {
  id: number;
  content: RequestAssortment[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface RequestAssortment {
  id: string;
  requestNo: string;
  articleNo: string;
  articleType: string;
  productName: string;
  type: string;
  requestedBy: string;
  requestedByName: string;
  requestedDate: string;
  status: AssortmentRequestStatusEnum;
  allowToCopy: boolean;
  auditLogs: AuditLog[];
  cjProduct: CJProductSnapshot;
  tdProduct: any;
}

export interface CJProductSnapshot {
  articleNo: string;
  barcodes: BarcodeSnapshot[];
  brand: string;
  classCode: string;
  className: string;
  createdBy: string;
  createdDate: string;
  familyCode: string;
  familyName: string;
  id: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  minimumShelfLife: number;
  pickingUnit: string;
  productName: string;
  restrictedItem: false;
  segmentCode: string;
  segmentName: string;
  selectStatus: string;
  shelfLife: 0;
  statusCJ: string;
  subClassCode: string;
  subClassName: string;
  supplierCode: string;
  supplierName: string;
  type: string;
  vat: boolean;
  version: number;
}

export interface ProductImage {
  directory: string;
  fileName: string;
  id: string;
  medium: string;
  original: string;
  small: string;
}

export class BarcodeSnapshot {
  index?: number;
  changeBarcodes: ChangeValue[];
  cjBarcodeSnapshot: CJBarcodeSnapshot;
  tdBarcodeSnapshot: TDBarcodeSnapshot;
}

export class CJBarcodeSnapshot {
  allowCoupon: boolean;
  allowDiscount: boolean;
  allowToBuy: boolean;
  allowToOrder: boolean;
  allowToSell: boolean;
  articleNo: string;
  barSize: string;
  barcode: string;
  id: string;
  pickingUnit: string;
  retailPrice: number;
  movingPrice: number;
  retailPriceEffectiveDate: string;
  unit: string;
  unitFactor: number;
  version: number;
}

export interface TDBarcodeSnapshot {
  allowVoucher: boolean;
  allowPromotion: boolean;
  allowToBuy: boolean;
  allowToOrder: boolean;
  articleNo: string;
  barSize: string;
  barcode: string;
  barcodeStatus: BarcodeStatusEnum;
  competitorRetPrice: number;
  createdBy: string;
  createdDate: string;
  height: number;
  id: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  length: number;
  retailPrice: number;
  retailPriceDate: string;
  scheduleRetailPrice: number;
  scheduleRetailPriceDate: string;
  suggestedRetPrice: number;
  unit: string;
  unitFactor: number;
  version: number;
  weight: number;
  wholesalePrice: number;
  width: number;
}

export interface WholesaleSettingSnapshot {
  wholesaleSetting: WholesaleSetting;
  changeWholesaleSettings: ChangeValue[];
}

export interface WholesaleSetting {
  classMarkupPct: number;
  classMarkupPrice: number;
  defaultWholesalePrice: boolean;
  gpPct: number;
  id: string;
  initialPrice: number;
  lowerBoundPrice: number;
  price: number;
  tdPrice?: number;
  suggestedPriceExcVat: number;
  suggestedPriceIncVat: number;
  tolerancePct: number;
  upperBoundPrice: number;
  vatPct: number;
  version: number;
}

// Use for edit page
export interface RequestAssortmentRequestResponse {
  allowToCopy: boolean;
  articleNo: string;
  articleType: string;
  auditLogs: AssortmentRequestAuditLog[];
  document: Document;
  id: string;
  productName: string;
  requestNo: string;
  requestedBy: string;
  requestedByName: string;
  requestedDate: string;
  status: AssortmentRequestStatusEnum;
  type: string;
  useCJProduct: boolean;
  version: number;
}

export interface AssortmentRequestAuditLog {
  activity: string;
  description: string;
  editBy: string;
  editByName: string;
  editDate: string;
}

export interface ApproveRejectAssortmentRequest {
  id: string;
  comment: string;
}

export interface Document {
  barcodeSnapshots: BarcodeSnapshot[];
  changeProducts?: ChangeValue[];
  cjProductSnapshot: CJProductSnapshot;
  tdProductSnapshot: any;
  wholesaleSettingSnapshots: WholesaleSettingSnapshot[];
  supplierPriceSnapshots?: SupplierPriceSnapshot[];
}

export interface RequestAssortmentEditResponse {
  id?: string;
  version?: number;
  requestNo?: string;
  articleNo?: string;
  productName?: string;
  type: string;
  createdBy?: string;
  createdDate?: string;
  status: string;
  document: Document;
}

export interface SupplierPriceSnapshot {
  branchNo?: string;
  changes?: any;
  effectiveDate: string;
  expireDate: string;
  id?: string;
  supplierCode: string;
  supplierName: string;
  supplierPrice: number;
  supplierUnit: string;
  supplierUnitFactor: number;
  tdOrderUnitFactor?: number;
  constSellingUnit: string;
  cost: number;
  error?: any;
  index?: number;
}

/* New Assortment Model */
export interface Assortment {
  id?: string;
  version: number;
  requestNo: string;
  articleNo: string;
  importId: string;
  isSubmitted: boolean;
  productName: string;
  isPriceUpdated: boolean;
  isForceSubmit?: boolean;
  type: AssortmentRequestTypeEnum;
  requestedBy: string;
  requestedByName: string;
  requestedDate: string;
  useCJProduct: boolean;
  articleType: ArticleTypeEnum;
  productType: ProductTypeEnum;
  status: AssortmentRequestStatusEnum;
  productDetail: AssortmentProductDetail;
  manualAddSupplierPrices: Array<SupplierPriceContent>;
  isUpdatedSupplierPrice: boolean;
  allowToCopy: boolean;
  reasonForChangeStatus: string;
  auditLogs: AuditLog[];
}

export interface AssortmentProductDetail {
  tdProduct: TDProduct;
  cjProduct: CJProduct;
  barcodes: Barcode[];
  supplierPrices: SupplierPrice[];
  normalPrice: StockPricing;
  specialPrice: StockPricing;
  maximumNormalPrice: number;
  maximumSpecialPrice: number;
  effectiveWholesalePrice: WholesalePrice;
  scheduleWholesalePrice: WholesalePrice;
  changeProducts: ChangeValue[];
}

export interface SupplierDetail {
  supplierPrices: SupplierPrice[];
  normalPrice: StockPricing;
  specialPrice: StockPricing;
  maximumNormalPrice: number;
  maximumSpecialPrice: number;
}

export interface StockPricing {
  id?: string;
  type: string;
  safetyStock: number;
  totalStock: number;
  movingAvgIncVat: number;
  vat: number;
  movingAvgExcVat: number;
  changes: ChangeValue[];
}

export interface Barcode {
  tdBarcode: TDBarcode;
  cjBarcode: CJBarcode;
  changeBarcodes: ChangeValue[];
}

export interface CJProduct {
  id: string;
  version: number;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  articleNo: string;
  productName: string;
  statusCJ: string;
  supplierCode: string;
  supplierName: string;
  brand: string;
  pickingUnit: string;
  shelfLife: number;
  minimumShelfLife: number;
  restrictedItem: boolean;
  vat: boolean;
  segmentCode: string;
  segmentName: string;
  familyCode: string;
  familyName: string;
  classCode: string;
  className: string;
  subClassCode: string;
  subClassName: string;
  type: string;
  selectStatus: string;
  barcodes: CJBarcode[];
  groupingBarcodes: GroupingBarcode[];
  cjSupplierPrices: SupplierPrice[];
}

export interface TDBarcode {
  id: string;
  version: number;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  articleNo: string;
  barcode: string;
  barcodeStatus: BarcodeStatusEnum;
  barSize: string;
  pickingUnit?: boolean;
  unit: string;
  unitFactor: number;
  weight: number;
  height: number;
  width: number;
  length: number;
  suggestedRetailPrice: number;
  // estimatedPartnerGpPct: number;
  // scheduleEstimatedPartnerGpPct: number;
  effectiveRetailPrice: RetailPrice;
  scheduleRetailPrice?: RetailPrice;
}

export interface SupplierPrice {
  id: string;
  supplierCode: string;
  supplierName: string;
  orderBarcode: string;
  vendorCode: string;
  branchNo: string;
  supplierPrice: number;
  supplierUnit: string;
  supplierUnitFactor: number;
  effectiveDate: string;
  sourceSupplierUnit: string;
  expireDate: string;
  warehouses: Warehouse[];
  isDelete: boolean;
  changes: ChangeValue[];
  avgSupplierPrice: number;
}

export interface GroupingBarcode {
  dcCode: string;
  dcName: string;
  barcodes: CJBarcode[];
}

export interface RetailPrice {
  id: string;
  version: number;
  barcode: string;
  articleNo: string;
  priceIncVat: number;
  effectiveDate: string;
  vat: number;
  vatPct: number;
  priceExcVat: number;
  estimatedPartnerGpPct: number;
}

export interface CJBarcode {
  id: string;
  version: number;
  barcode: string;
  articleNo: string;
  barSize: string;
  unit: string;
  unitFactor: number;
  retailPrice: number;
  movingPrice: number;
  wholesalePrice: number;
  retailPriceEffectiveDate: string;
  pickingUnit: boolean;
  allowToOrder: boolean;
  allowToBuy: boolean;
  allowToSell: boolean;
}

export interface TDProduct {
  id: string;
  version: number;
  importId: string;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  useCJProduct: boolean;
  articleNo: string;
  supplierRefNumber: string;
  pickingUnitBarcode: string;
  productStatus: ProductStatusEnum;
  previousProductStatus?: ProductStatusEnum;
  productName: string;
  productDisplayName: string;
  brand: string;
  vat: boolean;
  shelfLife: number;
  minimumShelfLife: number;
  standardGp: number;
  highMargin: boolean;
  productTier: string;
  productStandard: string;
  cbgProduct: string;
  exclusiveProduct: boolean;
  productGrading: ProductGradingEnum;
  productRecommend: boolean;
  estSalesQty: number;
  estSalesValue: number;
  sellingChannel: string;
  restrictedItem: boolean;
  restrictedAlcoholSaleTime: boolean;
  segment: string;
  family: string;
  classCode: string;
  subClass: string;
  catalogLV1: string;
  catalogLV2: string;
  size: number;
  uom: string;
  flavor: string;
  gradingGroup: string;
  productLocationGuideline: string;
  articleType: ArticleTypeEnum;
  productType: ProductTypeEnum;
  allowToRent: boolean;
  rentalPerMonth: number;
  orderingMethods: AssortmentOrderingMethodEnum[];
  freshLiteCategory: FreshLiteCategory;
  deliveryMethod: string;
  allowToEdit: boolean;
  supplierGroup: SupplierGroup;
  productImages: ProductImage[];
  maximumNormalPrice: number;
  maximumSpecialPrice: number;
  tdMovingAvg: number;
  supplierArticleNo: string;
  supplierProductName: string;
  warehouses: Warehouse[];
  allowToDestroy: boolean;
  tags: string[];
}

export interface WholesalePrice {
  id: string;
  articleNo: string;
  priceIncVat: number;
  vat: number;
  vatPct: number;
  priceExcVat: number;
  tdGp?: number;
  gpPct?: number;
  effectiveDate: string;
  version: number;
  changes: ChangeValue[];
}

export interface ChangeValue {
  field: string;
  before: any;
  after: any;
}

export interface SupplierGroup {
  supplierGroupId: string;
  supplierGroupNo: string;
  supplierGroupName: string;
}

export interface Warehouse {
  id?: string;
  code: string;
  nameTh?: string;
  name?: string;
  wmsCode?: string;
  locationDisplay?: string;
  warehouseNameDisplay?: string;
}

export interface FreshLiteCategory {
  id?: string;
  version?: number;
  code?: string;
  name: string;
  supplierGroup: SupplierGroup;
  type?: AssortmentFreshLiteTypeEnum;
  index?: string;
  isDuplicate?: boolean;
  createdDate: string;
  createdBy: string;
  createdByName: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  lastModifiedByName: string;
}

export interface FreshLiteUpdateResponse {
  duplicateName: string[];
}

export enum AssortmentRequestTypeEnum {
  NEW = 'NEW',
  EDIT = 'EDIT'
}

export enum AssortmentPageModes {
  REQUEST_VIEW = 'REQUEST_VIEW',
  REQUEST_EDIT = 'REQUEST_EDIT',
  REQUEST_CREATE = 'REQUEST_CREATE',
  ASSORTMENT_VIEW = 'ASSORTMENT_VIEW',
  ASSORTMENT_EDIT = 'ASSORTMENT_EDIT'
}

export enum AssortmentPageTabs {
  PRODUCT_TAB = 'REQUEST_VIEW',
  PRICE_TAB = 'REQUEST_EDIT'
}

export enum AssortmentPageTabsEnum {
  PRODUCT_TAB = 'PRODUCT_TAB',
  STORE_CONDITION_TAB = 'STORE_CONDITION_TAB',
  SUPPLIER_TAB = 'SUPPLIER_TAB',
  PRICE_TAB = 'PRICE_TAB',
  CJ_PRODUCT_DETAILS_TAB = 'CJ_PRODUCT_DETAILS_TAB'
}

export enum AssortmentRequestStatus {
  DRAFT = 'DRAFT',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL'
}

export enum ProductStatusEnum {
  SELECT = 'SELECT',
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  SEASONAL = 'SEASONAL',
  HOLD_BUY = 'HOLD_BUY',
  RECALL = 'RECALL',
  CLEAR = 'CLEAR',
  CLEAR_PROMOTION = 'CLEAR_PROMOTION',
  HOLD_BUY_SELL = 'HOLD_BUY_SELL',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  DELETE = 'DELETE'
}

export enum BarcodeStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum AssortmentRequestStatusEnum {
  DRAFT = 'DRAFT',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED'
}

export enum AssortmentOrderingMethodEnum {
  FIRST_LOT_ORDER = 'FIRST_LOT_ORDER',
  SPECIAL_REQUEST = 'SPECIAL_REQUEST',
  STORE_REPLENISHMENT = 'STORE_REPLENISHMENT',
  FRESH_LITE = 'FRESH_LITE'
}

export enum AssortmentFreshLiteTypeEnum {
  KEEP_STOCK = 'KEEP_STOCK',
  EXPENSE = 'EXPENSE'
}

export enum AssortmentDeliveryMethodEnum {
  SUPPLIER = 'SUPPLIER',
  TD = 'TD'
}

export class CJProductLinkArticleDetail {
  id: string;
  version: number;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  articleNo: string;
  productName: string;
  statusCJ: string;
  supplierCode: string;
  supplierName: string;
  brand: string;
  pickingUnit: string;
  shelfLife: number;
  minimumShelfLife: number;
  restrictedItem: boolean;
  vat: boolean;
  segmentCode: string;
  segmentName: string;
  familyCode: string;
  familyName: string;
  classCode: string;
  className: string;
  subClassCode: string;
  subClassName: string;
  type: string;
  selectStatus: ProductMasterSelectStatusEnum;
  barcodes: CJBarcode[];
  cjSupplierPrices: SupplierPrice[];
  groupingBarcodes: GroupingBarcode[];
}

export interface SupplierPriceErrorResponse {
  code: string;
  message: string;
  supplierPriceConfigValidationResult: SupplierPriceItemErrorDetails[];
}

export interface SupplierPriceItemErrorDetails {
  itemNo: number;
  changes?: SupplierPriceItemChange[];
}

export interface SupplierPriceItemChange {
  field: string;
  before: string;
  after: string;
}
