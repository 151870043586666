import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { OrderSelectItemSearchCriteria, ProductOrderItem } from '../../models/order-request.model';
import { OrderSelectItemActionTypes, OrderSelectItemActions } from '../actions/order-select-item.actions';

export interface OrderSelectItemState extends EntityState<ProductOrderItem> {
  isLoading: boolean;
  saveSuccess: boolean;
  criteriaObject: OrderSelectItemSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<ProductOrderItem> = createEntityAdapter<ProductOrderItem>({
  selectId: (productOrderItem: ProductOrderItem) => productOrderItem.articleNo
});

export const initialOrderSelectItemState: OrderSelectItemState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function OrderSelectItemReducers(
  state = initialOrderSelectItemState,
  action: OrderSelectItemActions
): OrderSelectItemState {
  switch (action.type) {
    case OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_REQUEST:
    case OrderSelectItemActionTypes.FIRST_LOT_ORDER_SELECT_ITEM_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_RESPONSE:
    case OrderSelectItemActionTypes.FIRST_LOT_ORDER_SELECT_ITEM_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_RESET:
    case OrderSelectItemActionTypes.FIRST_LOT_ORDER_SELECT_ITEM_LIST_RESET:
      return adapter.removeAll({
        ...state,
        totalElements: 0,
        totalPages: 0
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
