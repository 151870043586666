import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ErrorConnectorService {
  errorEvent: Subject<any> = new Subject<any>();
  showError(errorContent) {
    this.errorEvent.next(errorContent);
  }
}
