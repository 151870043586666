<div class="flex-item d-flex justify-content-center">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="right-section col-sm-10">
    <div class="flex mb-4 align-self-md-center">
      <div *ngIf="(forgotUsernameResult$ | async).result.isSuccess" class="form-group">
        <div class="alert-success alert-icon" role="alert">
          <span
            translate="FORGOT_USERNAME.FORGOT_USERNAME_SUCCESS"
            [translateParams]="{
              mobileNumber: (forgotUsernameResult$ | async).result.mobileNumber | mask: '000-000-0000'
            }"
          ></span>
        </div>
      </div>
      <div *ngIf="(forgotPasswordResult$ | async).result.isSuccess" class="form-group">
        <div class="alert-success alert-icon" role="alert">
          <span>
            {{ 'FORGOT_PASSWORD.RESET_PASSWORD_SUCCESSFULLY' | translate }}<br />
            {{ 'FORGOT_PASSWORD.PLEASE_LOGIN_WITH_YOUR_NEW_PASSWORD' | translate }}
          </span>
        </div>
      </div>
      <div *ngIf="forceChangePasswordSuccess" class="form-group">
        <div class="alert-success alert-icon" role="alert">
          <span>
            {{ 'FORGOT_PASSWORD.RESET_PASSWORD_SUCCESSFULLY' | translate }}<br />
            {{ 'FORGOT_PASSWORD.PLEASE_LOGIN_WITH_YOUR_NEW_PASSWORD' | translate }}
          </span>
        </div>
      </div>
      <div *ngIf="(result$ | async).result.errorResponse.translateKey" class="form-group">
        <div class="alert-danger alert-icon" role="alert">
          <span [innerHTML]="(result$ | async).result.errorResponse.translateKey | translate"></span>
        </div>
      </div>

      <div class="right-section-control">
        <div class="form-group">
          <label for="username"> {{ 'USERNAME' | translate }}<span class="text-danger">*</span> </label>
          <input
            type="text"
            formControlName="username"
            class="form-control"
            id="username"
            name="username"
            maxlength="50"
            placeholder="{{ 'ENTER_USERNAME' | translate }}"
            [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
          />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="password"> {{ 'PASSWORD' | translate }}<span class="text-danger">*</span> </label>
          <div class="inner-addon right-addon">
            <span (click)="toggleInputType()" [ngClass]="{ 'is-show': isShowPassword }">
              <em
                class="glyphicon"
                [ngClass]="{
                  'is-invalid': submitted && f.password.errors,
                  'icon-eye': !isShowPassword,
                  'icon-invisible': isShowPassword
                }"
              ></em>
            </span>
            <input
              type="{{ isShowPassword ? 'text' : 'password' }}"
              formControlName="password"
              class="form-control"
              id="password"
              name="password"
              maxlength="50"
              placeholder="{{ 'ENTER_PASSWORD' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group mt-4">
          <button type="submit" id="btn-signin" class="btn btn-primary btn-block">
            {{ 'LOGIN' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="forgot-section">
      <a href="#" routerLink="/forgot-password" class="mb-3 btn-text-link text-capitalize">{{
        'FORGOT_PASSWORD.FORGOT_PASSWORD' | translate
      }}</a>
    </div>
  </form>
</div>
