import { BsModalService } from 'ngx-bootstrap/modal';

export const hideAllModal = (modalService: BsModalService) => {
  const modalCount = modalService.getModalsCount();

  if (modalCount > 0) {
    const ids: number[] = modalService['loaders'].map(l => l.instance.id);

    for (const id of ids) {
      modalService.hide(id);
    }
  }
};
