export enum OrderStatusEnum {
  NEW_ORDER = 'NEW_ORDER',
  PROCESSING = 'PROCESSING',
  PARTIAL_DELIVERED = 'PARTIAL_DELIVERED',
  AWAITING_ALLOCATION = 'AWAITING_ALLOCATION',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
  CANCELED_BY_SUPPLIER = 'CANCELED_BY_SUPPLIER',
  REJECTED_BY_ERP = 'REJECTED_BY_ERP',
  CLOSED = 'CLOSED',
  GO_CREATED = 'GO_CREATED',
  AWAITING_CREATE_GO = 'AWAITING_CREATE_GO'
}
