import { Action } from '@ngrx/store';

import { StockTransferItem } from '../../models/stock-transfer-request.model';

export enum StockProductSelectedActionTypes {
  STOCK_PRODUCT_SELECTED_ADD_ITEM = '[Stock Product Selected] Add Item',
  STOCK_PRODUCT_SELECTED_UPDATE_ITEM = '[Stock Product Selected] Update Item',
  STOCK_PRODUCT_SELECTED_REMOVE_ITEM = '[Stock Product Selected] Remove Item',
  STOCK_PRODUCT_SELECTED_RESET = '[Stock Product Selected] Reset',
  PURCHASE_REQUEST_ITEM_SELECTED_LIST = '[Stock Product Selected] Purchase Request Item Selected List',
  STOCK_PRODUCT_SELECTED_ADD_ALL_ITEM = '[Stock Product Selected] Add All Item',
  STOCK_PRODUCT_SELECTED_INSERT_ALL_ITEM = '[Stock Product Selected] Insert All Item'
}

export class StockProductSelectedAddItem implements Action {
  readonly type = StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_ADD_ITEM;

  constructor(public payload: StockTransferItem) {}
}

export class StockProductSelectedUpdateItem implements Action {
  readonly type = StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_UPDATE_ITEM;

  constructor(public payload: StockTransferItem) {}
}

export class StockProductSelectedAddAllItem implements Action {
  readonly type = StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_ADD_ALL_ITEM;

  constructor(public payload: StockTransferItem[]) {}
}

export class StockProductSelectedInsertAllItem implements Action {
  readonly type = StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_INSERT_ALL_ITEM;

  constructor(public payload: StockTransferItem[]) {}
}

export class StockProductSelectedRemoveItem implements Action {
  readonly type = StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_REMOVE_ITEM;

  constructor(public payload: string) {}
}

export class StockProductSelectedReset implements Action {
  readonly type = StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_RESET;

  constructor() {}
}

export class PurchaseRequestItemSelectedList implements Action {
  readonly type = StockProductSelectedActionTypes.PURCHASE_REQUEST_ITEM_SELECTED_LIST;

  constructor(public payload: StockTransferItem[]) {}
}

export type StockProductSelectedActions =
  | StockProductSelectedAddItem
  | StockProductSelectedAddAllItem
  | StockProductSelectedReset
  | PurchaseRequestItemSelectedList
  | StockProductSelectedRemoveItem
  | StockProductSelectedInsertAllItem
  | StockProductSelectedUpdateItem;
