export class MyTaskSearchCriteria {
  searchCriteria?: string;
  module?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class MyTaskListResponse {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: MyTaskContent[];
}

export class MyTaskContent {
  id: string;
  requestId: string;
  requestNo: string;
  docType: string;
  module: string;
  requestStatus: string;
  subject: string;
  requester: string;
  requesterName: string;
  requestedDate: string;
  requestInfo: any;
  status: string;
  createdDate: string;
  lastModifiedDate: string;
}

export class MyTaskLinkContent {
  module: string;
  referenceID?: string;
  referenceNo?: string;
}

export enum PortalModule {
  PRODUCT = 'PRODUCT',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',
  CLAIM = 'CLAIM',
  MERCHANT = 'MERCHANT',
  STORE = 'STORE',
  PROMOTION = 'PROMOTION',
  VOUCHER = 'VOUCHER',
  REWARD = 'REWARD',
  REWARD_CATALOG = 'REWARD_CATALOG',
  SHELF_REQUEST = 'SHELF_REQUEST',
  SHELF_INVENTORY_REQUEST = 'SHELF_INVENTORY_REQUEST',
  SHELF_FIX_ASSET_REQUEST = 'SHELF_FIX_ASSET_REQUEST',
  ORDER = 'ORDER',
  TASK_ASSIGNMENT_RESPONSE = 'TASK_ASSIGNMENT_RESPONSE',
  RECEIVE_ORDER = 'RECEIVE_ORDER',
  STOCK_TRANSFER = 'STOCK_TRANSFER',
  STOCK_TRANSFER_REQUEST = 'STOCK_TRANSFER_REQUEST',
  PURCHASE_CONDITION = 'PURCHASE_CONDITION',
  ORDER_REQUEST = 'ORDER_REQUEST',
  TASK = 'TASK',
  TASK_REQUEST = 'TASK_ASSIGNMENT',
  POINT_SETTING = 'POINT_SETTING',
  DELIVERY_ORDER = 'DELIVERY_ORDER',
  REFUND = 'REFUND',
  TOTE = 'TOTE',
  GROUP_ORDER = 'GROUP_ORDER',
  HOLD_ASSORTMENT = 'HOLD_ASSORTMENT',
  RETURN_TO_WAREHOUSE_REQUEST_VIEW = 'RETURN_TO_WAREHOUSE_REQUEST_VIEW',
  RETURN_TO_WAREHOUSE_ORDER_VIEW = 'RETURN_TO_WAREHOUSE_ORDER_VIEW',
  PRE_ORDER = 'PRE_ORDER',
  COUPON_SETTING_REQUEST = 'COUPON_SETTING_REQUEST',
  STORE_ASSORTMENT = 'STORE_ASSORTMENT',
  RETURN_TO_WAREHOUSE = 'RETURN_TO_WAREHOUSE'
}

export enum TasksDocType {
  PRODUCT_MERCHANDISE = 'PRODUCT_MERCHANDISE',
  PRODUCT_NON_MERCHANDISE = 'PRODUCT_NON_MERCHANDISE',
  PURCHASE_REQUEST_MERCHANDISE = 'PURCHASE_REQUEST_MERCHANDISE',
  PURCHASE_REQUEST_NON_MERCHANDISE = 'PURCHASE_REQUEST_NON_MERCHANDISE',
  PROMOTION = 'PROMOTION',
  VOUCHER = 'VOUCHER'
}

export class TaskModuleUrl {
  public static readonly MY_TASKS = '/dashboard/my-task';
  public static readonly PROMOTION_REQUEST = '/campaign/promotion-request-list';
  public static readonly PURCHASE_REQUEST = '/purchase/purchase-request/purchase-request-list';
  public static readonly CLAIM_REQUEST = '/claim-request-list';
  public static readonly VOUCHER_REQUEST = '/voucher-request-list';
  public static readonly REWARD_REQUEST = '/reward-request-list';
  public static readonly REWARD_CATALOG_REQUEST = '/reward-catalog-request-list';
  public static readonly MERCHANT_REQUEST = '/td-store/merchant-request-list';
  public static readonly STORE_REQUEST = '/td-store/store-request-list';
  public static readonly SHELF_REQUEST = '/shelf-request-list';
  public static readonly SHELF_INVENTORY_REQUEST = '/shelf-inventory-request-list';
  public static readonly SHELF_FIX_ASSET_REQUEST = '/shelf-fix-asset-request-list';
  public static readonly ORDER_REQUEST = '/order-request-list';
  public static readonly RECEIVE_ORDER_REQUEST = '/receive-order-list';
  public static readonly STOCK_TRANSFER_REQUEST = '/stock-transfer-request-list';
  public static readonly PURCHASE_CONDITION_REQUEST = 'purchase/purchase-condition/purchase-condition-list';
  public static readonly TASK = '/task-assignment/task-request/task-list';
  public static readonly TASK_REQUEST = '/task-assignment/task-request/task-request-list';
  public static readonly TASK_ASSIGNMENT_RESPONSE = '/task-assignment/task-response/response-list';
  public static readonly POINT_SETTING = '/membership/member-point/member-point-setting-request-list';
  public static readonly REFUND_REQUEST = '/billing/refund/refund-request-list';
  public static readonly COUPON_SETTING_REQUEST = '/campaign/online-coupon/online-coupon-request-list';
}
