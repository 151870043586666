<ng-template #multiDescAndHeader let-auditLogs>
  <div class="scroll">
    <div
      class="history-modal-body mx-3 my-2"
      *ngFor="let auditLog of auditLogs.content; let i = index"
      [ngClass]="{ 'border-bottom': i !== auditLogs.content.length - 1 }"
    >
      <div class="row">
        <div class="col-4 history-list-header" *ngFor="let header of auditLogs.header">{{ header }}</div>
      </div>
      <div class="row">
        <div class="col-4 history-list-text" id="editDate" data-id="editDate">
          {{
            auditLog.editDate
              | amFromUtc
              | amLocal
              | dateDisplay: auditLogs?.config?.dateTimeDisplay || dateTimeDisplay:auditLogs.config.dateEmptyDisplay
          }}
        </div>

        <div class="col-4 history-list-text" id="activity" data-id="activity">
          <ng-container *ngFor="let activity of auditLog.activities; index as indexOfActivity">
            <div class="history-list-header mb-0">{{ activity.activity }}</div>
            <div class="mb-2" *ngFor="let description of activity?.descriptions; index as indexOfDescription">
              <ng-container *ngIf="description"
                ><span class="history-list-text"> {{ description }}</span></ng-container
              >
            </div></ng-container
          >
        </div>
        <div class="col-4 history-list-text">
          <span id="editByName" data-id="editByName">{{ auditLog?.editByName }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
