import { Component } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, switchMap } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';

import { ModalButtonResponseEnum } from '@shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '@shared/layouts';
import { StoreService } from '@shared/services/store.service';
import { formatDateStartOfDay } from '@shared/utils/date-util';

import { environment as env } from '../../../../environments/environment';
import { TerminateStoreModalComponent } from './terminate-store-modal/terminate-store-modal.component';

@Component({
  selector: 'app-terminate-store',
  templateUrl: './terminate-store.component.html',
  styleUrls: ['./terminate-store.component.scss']
})
export class TerminateStoreComponent {
  action = new Subject<ModalButtonResponseEnum>();
  bsConfig: BsDatepickerConfig;

  constructor(private storeService: StoreService, private bsModalService: BsModalService) {}

  openTerminateModal(storeCode: string, storeName: string, storeNo: string, version: number, openDate: string) {
    const modalRef = this.bsModalService.show(TerminateStoreModalComponent, {
      initialState: {
        storeName,
        storeCode,
        openDate
      }
    });

    modalRef.content.action
      .pipe(
        filter(result => result === ModalButtonResponseEnum.OK),
        take(1),
        switchMap(_ => {
          modalRef.hide();
          return this.storeService
            .terminateDateStore({
              storeNo: storeNo,
              version: version,
              terminateDate: formatDateStartOfDay(modalRef.content.terminateDate.value, env.dateISO8601)
            })
            .pipe(
              switchMap(_ => {
                return this.alertModal('Success', `Store has been terminated.`);
              }),
              catchError(err => {
                return this.alertModal('Failed', err.error.message);
              })
            );
        })
      )
      .subscribe({
        next: () => {
          this.action.next(ModalButtonResponseEnum.OK);
        }
      });
  }

  alertModal(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    const alertModalRef = this.bsModalService.show(AlertModalComponent, {
      initialState
    });

    return alertModalRef.content.action.pipe(
      filter(result => result === ModalButtonResponseEnum.OK),
      take(1)
    );
  }
}
