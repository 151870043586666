import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ClaimApproveRequest,
  ClaimRejectRequest,
  ClaimRequest,
  ClaimRequestPagination,
  ClaimRequestSearchCriteria,
  ClaimRequestSubmit
} from '../../pages/claim-request/model/claim-request.model';
import { SignedUrlObject } from '../models';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';

@Injectable()
export class ClaimService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.claim;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getClaimRequestByClaimNumber(claimRequestNo: string): Observable<ClaimRequest> {
    const url = this.getFullUrl(this.envService.get, {
      claimRequestNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getClaimDraftByReceiveOrderNumber(receiveOrderNo: string): Observable<ClaimRequest> {
    const url = this.getFullUrl(this.envService.draft, {
      receiveOrderNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  submit(data: ClaimRequestSubmit): Observable<any> {
    return this.http.post<any>(`${this.getUrl()}`, data, {
      headers: this.loaderHeaders(3000)
    });
  }

  searchByCriteria(criteria: ClaimRequestSearchCriteria): Observable<ClaimRequestPagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ORDER,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.list);
    return this.http.get<ClaimRequestPagination>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  approve(claimApproveRequest: ClaimApproveRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.approve);
    return this.http.put<any>(url, claimApproveRequest, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }

  reject(claimRejectRequest: ClaimRejectRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.reject, {
      claimRequestNo: claimRejectRequest.claimRequestNo
    });
    return this.http.put<any>(
      url,
      {
        version: claimRejectRequest.version,
        comment: claimRejectRequest.comment,
        toteItems: claimRejectRequest.toteItems
      },
      { headers: this.loaderHeaders(3000) }
    );
  }

  public getFileUrl(refId: string): Observable<SignedUrlObject> {
    const url = this.getFullUrl(this.envService.fileUrl);
    return this.http.post<SignedUrlObject>(url, { refId }, { headers: this.headers });
  }
}
