import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { MyTaskListResponse, MyTaskSearchCriteria } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class DashboardService extends BaseService {
  public headers: HttpHeaders;
  public nextCount: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.dashboard;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  /**
   * @description  get tasks by role , return MyTaskListResponse
   */
  tasksByRole(criteria: MyTaskSearchCriteria): Observable<MyTaskListResponse> {
    const url = this.getFullUrl(this.envService.tasksByRole);
    const params = this.getParams(criteria);
    this.nextCount.next(true);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  taskCount() {
    const url = this.getFullUrl(this.envService.coutTask);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  resetCount() {
    this.nextCount.next(false);
  }
}
