import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PureFnModule } from '../../pipes/pure-fn/pure-fn.module';
import { ReferenceDocumentLinkComponent } from './reference-document-link.component';

@NgModule({
  declarations: [ReferenceDocumentLinkComponent],
  imports: [CommonModule, PureFnModule],
  exports: [ReferenceDocumentLinkComponent]
})
export class ReferenceDocumentLinkModule {}
