import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { MerchantService } from '@shared/services/merchant.service';
import {
  StoreMerchantsActionTypes,
  StoreMerchantsListRequest,
  StoreMerchantsListResponse
} from '@shared/store/actions/store-merchants.actions';

import { LayoutActionLoadError } from '../actions/layout.action';

@Injectable()
export class StoreMerchantsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly merchantService: MerchantService,
    private readonly logger: NGXLogger
  ) {}

  searchStoreMerchants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreMerchantsListRequest>(StoreMerchantsActionTypes.STORE_MERCHANTS_PAGING_LIST_REQUEST),
      tap(action =>
        this.logger.debug(`@Effect [Store Merchants] Store Merchant List request:` + JSON.stringify(action.payload))
      ),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(action =>
        this.merchantService.searchStoreByCriteria(action.payload).pipe(
          map(result => new StoreMerchantsListResponse(result)),
          catchError(err => of(new LayoutActionLoadError(err)))
        )
      )
    );
  });
}
