import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DateDisplayPipe } from '@shared/pipes/date-display.pipe';

/***
 * This module is used to declare all pipes, It's make to use pipe in shared module
 */
@NgModule({
  declarations: [DateDisplayPipe],
  imports: [CommonModule],
  exports: [DateDisplayPipe]
})
export class PipesModule {}
