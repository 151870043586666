import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { R, WatchQueryOptions } from 'apollo-angular/types';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { GraphqlQueryObject } from '../gql/common.gql';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  public watchQuery<T = any, V = R>(options: WatchQueryOptions<V>): Observable<ApolloQueryResult<T>> {
    return this.apollo
      .watchQuery<T, V>(options)
      .valueChanges.pipe(tap(value => this.logger.debug('watchQuery', value)));
  }

  public getMasterDataByNames<T = any, V = R>(
    names: Array<string | GraphqlQueryObject>
  ): Observable<ApolloQueryResult<T>> {
    return names.length ? this.watchQuery<T, V>({ query: this.getGraphQuery(names) }) : of(null);
  }

  public clearStore(): void {
    this.apollo.getClient().clearStore();
  }

  constructor(private readonly apollo: Apollo, protected readonly logger: NGXLogger) {}

  /**
   *
   * Sample usage
   * const dayQuery = new GraphqlQueryObject();
   * dayQuery.name = 'days';
   * dayQuery.sort = { orderBy: 'ID'} as GraphqlQuerySortOptions;
   * dayQuery.fields = dayQuery.defaultFields;
   *
   * const countryQuery = new GraphqlQueryObject();
   * countryQuery.name = 'countries';
   * countryQuery.fields = ['id', 'vatPct', 'defaultCurrency'];
   *
   * const stateQuery = new GraphqlQueryObject();
   * stateQuery.name = 'states';
   * stateQuery.sort = { orderBy: 'NAME_EN'} as GraphqlQuerySortOptions;
   * stateQuery.fields = [
   * 'code',
   * 'nameTh',
   * 'nameEn',
   * `region {
   *      nameTh
   *      code
   *    }`
   * ];
   */
  public getGraphQuery(names: Array<string | GraphqlQueryObject>) {
    const nodes = names.map(name => {
      if (typeof name === 'string') {
        return `${name} {
          id
          nameTh
          nameEn
          code
        }`;
      } else if (name instanceof GraphqlQueryObject) {
        return this.getGraphqlQueryObject(name);
      }
    });
    const nodesString = nodes.join('\n');

    // query {
    //   days(sort: { orderBy: ID }) {
    //     id
    //     nameTh
    //     nameEn
    //     code
    //   }
    // }

    // query {
    //   days(sort: { orderBy: ID }, type: '') {
    //     id
    //     nameTh
    //     nameEn
    //     code,
    //     type
    //   }
    // }

    return gql`
      query {
        ${nodesString}
      }
    `;
  }

  public getGraphqlQueryObject(name: GraphqlQueryObject) {
    const queryObject = name;
    const sort = queryObject.sort
      ? `sort: { orderBy: ${queryObject.sort.orderBy} ${
          queryObject.sort.orderDirection ? ', orderDirection: ' + queryObject.sort.orderDirection : ''
        }}`
      : null;
    const rawType = queryObject.type instanceof Array ? JSON.stringify(queryObject.type) : `"${queryObject.type}"`;
    const type = queryObject.type ? `type: ${rawType}` : null;
    const rawLocationType =
      queryObject.type instanceof Array ? JSON.stringify(queryObject.locationType) : `"${queryObject.locationType}"`;
    const locationType = queryObject.locationType ? `locationType: ${rawLocationType}` : null;
    const active = queryObject.active !== undefined ? `active: ${queryObject.active}` : null;
    const allowManualAdjustment =
      queryObject.allowManualAdjustment !== undefined
        ? `allowManualAdjustment: ${queryObject.allowManualAdjustment}`
        : null;
    const params = [sort, type, locationType, active, allowManualAdjustment].filter(Boolean).join(',');
    const queryParams = params ? `(${params})` : '';
    return `${queryObject.name}${queryParams} {
      ${(queryObject.fields ? queryObject.fields : queryObject.defaultFields).join('\n')}
    }`;
  }
}
