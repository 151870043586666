import { createSelector } from '@ngrx/store';

import * as fromTdAssortmentOrderState from '../reducers/td-assortment-order.reducers';
import { AppStates } from '../state/app.states';

const selectTdAssortmentOrderState = (state: AppStates) => state.tdAssortmentOrder;

const selectTdAssortmentFreeItemOrderState = (state: AppStates) => state.tdAssortmentOrder.tdAssortmentFreeItemOrder;

export const getTdAssortmentOrderBySupplierCodeAndBarcode = (supplierCode, barcode) =>
  createSelector(selectTdAssortmentOrderState, orders => orders.entities[`${supplierCode}_${barcode}`]);

export const getCountTdAssortmentOrder = createSelector(
  selectTdAssortmentOrderState,
  fromTdAssortmentOrderState.selectTotal
);

export const selectAllTdAssortmentOrder = createSelector(
  selectTdAssortmentOrderState,
  fromTdAssortmentOrderState.selectAll
);

export const selectAllTdAssortmentFreeItemOrder = createSelector(
  selectTdAssortmentFreeItemOrderState,
  fromTdAssortmentOrderState.selectAllTdAssortmentFreeItemOrder
);

export const getTdAssortmentOrderFreeItemBySupplierCodeAndBarcode = (supplierCode, barcode) =>
  createSelector(selectTdAssortmentFreeItemOrderState, orders => orders.entities[`${supplierCode}_${barcode}`]);

export const getCountTdAssortmentFreeItemOrder = createSelector(
  selectTdAssortmentFreeItemOrderState,
  fromTdAssortmentOrderState.selectTotalAllTdAssortmentFreeItemOrder
);

export const selectFree = createSelector(
  selectTdAssortmentFreeItemOrderState,
  fromTdAssortmentOrderState.selectEntitiesFree
);
