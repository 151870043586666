import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { ItemExportCriteria } from '../models/purchase-request.model';
import { BaseService } from './base.service';

@Injectable()
export class PurchaseProductPricesTemplateService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.purchaseProductPricesTemplate;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportPrItem(criteria: ItemExportCriteria): Observable<any> {
    const { supplierCode, ...criteriaQueryString } = criteria;
    const params = this.getParams(criteriaQueryString, true);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.PR,
          path: `${this.envService.url}${this.envService.purchaseProductPricesTemplate}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PR,
          route: `${this.envService.url}${this.envService.purchaseProductPricesTemplate}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    const url = this.getFullUrl(this.envService.export);
    return this.http.post<any>(url, supplierCode, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      responseType: 'blob' as 'json',
      params
    });
  }
}
