import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RedeemTransactionReportCriteria } from '@shared/models/report.model';
import { BaseService } from '@shared/services/base.service';
import { metricsReporting } from '@shared/utils/metrics-util';

@Injectable()
export class RewardRedeemTransactionReportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.exportReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  reportRewardRedeemTransaction(reportCriteria: RedeemTransactionReportCriteria) {
    const url = this.getFullUrl(this.envService.rewardRedeemTransaction, null, true);
    const metrics = metricsReporting([this.envService.url, this.envService.report]);

    return this.http.post<any>(url, reportCriteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
