import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { StockProductService } from '../../services/stock-product.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  StockProductActionTypes,
  StockProductListRequestAction,
  StockProductListResponseAction
} from '../actions/stock-product.actions';
import { selectAllStockProductSelected } from '../selectors/stock-product-selected.selectors';
import { AppStates } from '../state/app.states';

@Injectable()
export class StockProductEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly stockProductService: StockProductService,
    private readonly store: Store<AppStates>,
    private readonly logger: NGXLogger
  ) {}

  searchStockProductList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StockProductListRequestAction>(StockProductActionTypes.STOCK_PRODUCT_LIST_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${StockProductActionTypes.STOCK_PRODUCT_LIST_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload =>
        this.stockProductService.searchByCriteria(payload).pipe(
          withLatestFrom(this.store.select(selectAllStockProductSelected)),
          map(([response, stockProductSelected]) => {
            stockProductSelected.forEach(selected => {
              const foundObjectIndex = response.content.findIndex(content => {
                return content.articleNo === selected.articleNo;
              });
              if (response.content[foundObjectIndex]) {
                response.content[foundObjectIndex].quantity = selected.quantity;
              }
            });
            return new StockProductListResponseAction(response);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
