import gql from 'graphql-tag';

import { MasterData } from '../models';
import { CatalogLv1, ProductGrading, Segments } from '../services';

export class ProductMasterData {
  segments: Segments;
  catalogLv1: CatalogLv1;
  productGradings: ProductGrading;
  units: MasterData;
  uoms: MasterData;
}

export const productsMasterQuery = gql`
  query masterData($articleType: String!, $active: Boolean) {
    segments(articleType: $articleType, active: $active) {
      code
      nameEn
      families {
        code
        nameEn
        classCodes {
          code
          nameEn
          gradingGroup {
            code
            nameEn
          }
          subClasses {
            code
            nameEn
          }
        }
      }
    }
    catalogLv1(articleType: $articleType) {
      code
      nameEn
      catalogLv2 {
        code
        nameEn
      }
    }
    productGradings {
      code
      value
    }
    units {
      code
      nameEn
    }
    uoms {
      code
      nameEn
    }
  }
`;
