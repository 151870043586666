import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import { StoresPagingList, StoresRequestSearchCriteria } from '../../models/stores.model';
import { StoresActionTypes, StoresActions } from '../actions/stores.action';
import { StoresStates } from '../state/stores.state';

export const storesModuleReducers: ActionReducerMap<Partial<StoresStates>> = {
  stores: storesReducers
};

export interface StoresRequestState extends EntityState<StoresPagingList> {
  criteriaObject: StoresRequestSearchCriteria;
  storesPagingList: SelectStoreState;
  storesResponseList: StoresPagingList[];
}

export interface SelectStoreState extends EntityState<StoresPagingList> {
  isLoading: boolean;
  totalElement: number;
}

export const adapter: EntityAdapter<StoresPagingList> = createEntityAdapter<StoresPagingList>({
  selectId: (billToBase: StoresPagingList) => billToBase.storeId
});

export const adapterSelectStore: EntityAdapter<StoresPagingList> = createEntityAdapter<StoresPagingList>({
  selectId: (billToBase: StoresPagingList) => billToBase.storeId
});

export const initialSelectStoreState: SelectStoreState = adapterSelectStore.getInitialState({
  isLoading: false,
  totalElement: 0
});

export const initialStoreResponseState: StoresRequestState = adapter.getInitialState({
  criteriaObject: {
    page: 0,
    size: 20
  },
  storesPagingList: initialSelectStoreState,
  storesResponseList: []
});
// export interface StoresRequestState extends EntityState<StoresPagingList[]>, BaseState {
//   criteriaObject: StoresRequestSearchCriteria;
//   //   storesPagingList: SelectStoreState;
//   storesResponseList: StoresPagingList[];
//   isLoading: boolean;
// }

// export interface SelectStoreState extends EntityState<StoresPagingListResponse> {
//   isLoading: boolean;
//   totalElement: number;
// }

// export const adapter: EntityAdapter<StoresPagingList[]> = createEntityAdapter<StoresPagingList[]>();

// export const initialStoreResponseState: StoresRequestState = adapter.getInitialState({
//   criteriaObject: {
//     page: 0,
//     size: 20
//   },
//   storesResponseList: [],
//   totalElements: 0,
//   //   storesPagingList: SelectStoreState;
//   isLoading: false
// });

export function storesReducers(state = initialStoreResponseState, action: StoresActions): StoresRequestState {
  switch (action.type) {
    case StoresActionTypes.STORES_PAGING_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        storesPagingList: {
          ...state.storesPagingList,
          isLoading: true
        }
      };
    case StoresActionTypes.STORES_PAGING_LIST_RESPONSE:
      return {
        ...state,
        storesPagingList: adapterSelectStore.upsertMany(action.payload.content, {
          ...state.storesPagingList,
          isLoading: false,
          totalElement: action.payload.totalElements
        })
      };
    case StoresActionTypes.REMOVE_ALL_SELECT_STORES_LIST:
      return {
        ...state,
        storesPagingList: adapterSelectStore.removeAll({ ...state.storesPagingList })
      };
    case StoresActionTypes.REMOVE_ALL_SELECT_STORE_LIST:
      return {
        ...state,
        storesPagingList: adapterSelectStore.removeAll({
          ...state.storesPagingList
        })
      };
    case StoresActionTypes.RESET_STORE_LIST:
      return {
        ...state,
        storesPagingList: initialSelectStoreState
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const { selectAll: selectAllStoresPagingList } = adapterSelectStore.getSelectors();
