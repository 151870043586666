import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuditLog } from '@shared/models';
import { BaseService } from '@shared/services/base.service';

import { MetricActionType, MetricModule, MetricType, Metrics } from '../../../shared/models/metrics';
import { ReturnToWarehouseOrderReportCriteria } from '../../../shared/models/report.model';
import { metricsReporting } from '../../../shared/utils/metrics-util';
import { ReturnToWarehouseOrder } from '../modals/return-to-warehouse-list';
import { ReturnToWarehouseOrderView } from '../modals/return-to-warehouse-order-view';

@Injectable({
  providedIn: 'root'
})
export class ReturnToWarehouseOrderService extends BaseService {
  envReportService;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.returnToWarehouseOrder;
    this.headers = new HttpHeaders(this.envService.headers);

    this.envReportService = this.env.services.exportReport;
  }

  getReturnToWarehouseOrderList(
    criteria: ReturnToWarehouseOrder.SearchCriteriaRequest
  ): Observable<ReturnToWarehouseOrder.Pagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.RETURN_TO_WAREHOUSE,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria);
    return this.http.get<ReturnToWarehouseOrder.Pagination>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  getReturnToWarehouseOrder(docNo: string) {
    const url = this.getFullUrl(this.envService.get, { docNo });

    return this.http.get<ReturnToWarehouseOrderView>(url, {
      headers: this.loaderHeaders(null),
      observe: 'body'
    });
  }

  getReturnToWarehouseOrderHistory(docNo: string) {
    const url = this.getFullUrl(this.envService.history, { docNo });

    return this.http.get<AuditLog[]>(url, {
      headers: this.loaderHeaders(null),
      observe: 'body'
    });
  }

  closeRT(id: string, comment: string) {
    const url = this.getFullUrl(this.envService.closed);

    return this.http.post(
      url,
      { id, comment },
      {
        headers: this.loaderHeaders(null),
        observe: 'body'
      }
    );
  }

  exportReport(criteria: ReturnToWarehouseOrderReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envReportService.returnToWarehouseOrder, null, true);
    const metrics = metricsReporting([this.envReportService.returnToWarehouseOrder]);

    let headers = this.loaderHeaders(0, metrics);
    headers = headers.set('x-host', this.envReportService.headers['x-host']);

    return this.http.post<any>(url, criteria, {
      headers,
      observe: 'body'
    });
  }
}
