import { Action } from '@ngrx/store';

import { StoreListSearchCriteria } from '@shared/models';
import { StoreMerchantsPagingListResponse } from '@shared/models/store-merchant.model';

export enum StoreMerchantsActionTypes {
  STORE_MERCHANTS_PAGING_LIST_REQUEST = '[Store Merchants] Store Merchants List request',
  STORE_MERCHANTS_PAGING_LIST_RESPONSE = '[Store Merchants] Store Merchants List response',
  REMOVE_ALL_SELECT_STORE_MERCHANTS_LIST = '[Store Merchants] Remove All Select Store Merchants List',
  REMOVE_ALL_SELECT_STORE_MERCHANT_LIST = '[Store Merchants] Remove All Select Merchant List',
  RESET_STORE_MERCHANTS_LIST = '[Store Merchants] Reset All Store Merchant List'
}

export class StoreMerchantsListRequest implements Action {
  readonly type = StoreMerchantsActionTypes.STORE_MERCHANTS_PAGING_LIST_REQUEST;

  constructor(public payload: StoreListSearchCriteria) {}
}

export class StoreMerchantsListResponse implements Action {
  readonly type = StoreMerchantsActionTypes.STORE_MERCHANTS_PAGING_LIST_RESPONSE;

  constructor(public payload: StoreMerchantsPagingListResponse) {}
}

export class RemoveAllSelectStoreMerchantsListAction implements Action {
  readonly type = StoreMerchantsActionTypes.REMOVE_ALL_SELECT_STORE_MERCHANTS_LIST;

  constructor() {}
}

export class RemoveAllSelectMerchantListAction implements Action {
  readonly type = StoreMerchantsActionTypes.REMOVE_ALL_SELECT_STORE_MERCHANT_LIST;

  constructor() {}
}

export class ResetStoreMerchantsListAction implements Action {
  readonly type = StoreMerchantsActionTypes.RESET_STORE_MERCHANTS_LIST;

  constructor() {}
}

export type StoreMerchantsActions =
  | StoreMerchantsListRequest
  | StoreMerchantsListResponse
  | RemoveAllSelectStoreMerchantsListAction
  | ResetStoreMerchantsListAction
  | RemoveAllSelectMerchantListAction;
