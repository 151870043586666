import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ControlContainer,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { ReplaySubject, forkJoin, fromEventPattern } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';

import { environment as env } from '../../../../environments/environment';
import { FileModuleEnum } from '../../enum/file-url.enum';
import { ModalButtonResponseEnum } from '../../enum/modal-button-response.enum';
import { AlertModalComponent } from '../../layouts';
import { ConfirmModalComponent } from '../../layouts/modals/confirm-modal/confirm-modal.component';
import { FileUrlService } from '../../services/file-url.service';
import { UsersService } from '../../services/users.service';
export class ICustomFile extends File {
  errors?: { [key: string]: any };
  imgSrc?: string;
  filePath?: string | ArrayBuffer | null;
  id?: number;
  imgHeight?: number;
  imgWidth?: number;
  isImg?: boolean;
  imgLoadReplay?: ReplaySubject<[Event, ProgressEvent]>;
  textContent?: string;
  textLoadReplay?: ReplaySubject<ProgressEvent>;
}

type AllowedExtType = RegExp | string | string[];

@Component({
  selector: 'app-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilesUploadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FilesUploadComponent),
      multi: true
    }
  ],
  exportAs: 'FilesUploadComponent'
})
export class FilesUploadComponent extends OnDestroyMixin implements OnChanges, OnInit, ControlValueAccessor {
  @HostBinding('class.is-invalid') get invalid() {
    return this.hasError;
  }

  constructor(
    @Optional() @SkipSelf() private readonly controlContainer: ControlContainer,
    protected readonly modalService: BsModalService,
    private readonly usersService: UsersService,
    private readonly logger: NGXLogger,
    private readonly fileUrlService: FileUrlService
  ) {
    super();
    this.disabled = false;
    this.hasError = false;
    this.hasChange = false;
    this.isHiddenFileName = false;
    this.ngChange = () => {};
    this.ngTouched = () => {};
  }

  @Input()
  set allowedExt(value: AllowedExtType) {
    if (typeof value === 'string') {
      value = value + '$';
    }
    if (value instanceof Array) {
      value = value.join('|') + '$';
    }
    this._allowedExt = value;
  }

  get allowedExt(): AllowedExtType {
    return this._allowedExt;
  }

  private _allowedExt: AllowedExtType;
  url = '';
  inputValue;
  isLoad = true;
  ngChange;
  ngTouched;
  value;
  validator: AsyncValidatorFn;
  fileList: ICustomFile[] = [];
  progress;
  fileService;

  @ViewChild('uploadInput') uploadInput: ElementRef;

  @Input() disabled: boolean;
  @Input() multiple: boolean;
  @Input() allowedTypes: AllowedExtType;
  @Input() size: number;
  @Input() hasError: boolean;
  @Input() hasChange: boolean;
  @Input() withMeta: boolean;
  @Input() maxHeight: number;
  @Input() maxWidth: number;
  @Input() controlName: string;
  @Input() initialFileList: ICustomFile[];
  @Input() isNew: boolean;
  @Input() fileTypeErrorTxt: string;
  @Input() fileSizeErrorTxt: string;
  @Input() descriptionTxt: string | null;
  @Input() isAddable: boolean;
  @Input() index: number;
  @Input() maxImages: number;
  @Input() lastIndex: number;
  @Input() isHiddenFileName: boolean;
  @Input() fileModule: FileModuleEnum;

  @Output() addImage = new EventEmitter<string>();
  @Output() showImage: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('change', ['$event.target.files']) onChange = (_value: any) => {};
  @HostListener('blur') onTouched = () => {};

  ngOnInit(): void {
    this.fileList = this.initialFileList || [];
    this.fileService = this.getFileService();
  }

  ngOnChanges(): void {
    this.ngChange(this.value);
  }

  getFileService() {
    switch (this.fileModule) {
      case FileModuleEnum.MERCHANT_REQUEST:
        return this.fileUrlService.merchantRequest;
      case FileModuleEnum.SHELF_TYPE:
      case FileModuleEnum.SHELF_INFORMATION:
        return this.fileUrlService.shelfType;
      default:
        this.logger.debug('Please add @Input fileModule to FilesUploadComponent');
        return null;
    }
  }

  propagateChange: any = () => {};

  writeValue(fileList): void {
    if (fileList && fileList.length) {
      fileList
        .filter(file => !(file.filePath instanceof ArrayBuffer))
        .forEach(file => {
          this.fileService(file.filePath as string)
            .pipe(filter(data => Boolean(data)))
            .subscribe(signedUrlObject => (file.filePath = signedUrlObject.signedUrl));
        });
    }

    this.value = this.fileList = this.inputValue = fileList;
  }

  registerOnChange(fn: any): void {
    this.onChange = this.onChangeGenerator(fn);
  }

  registerOnTouched(fn: any): void {
    this.ngTouched = fn;
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onChangeGenerator(fn: (_: any) => {}): (_: ICustomFile[]) => void {
    if (this.value) {
      this.value.forEach(imgObj => {
        const regexpImageType = new RegExp(env.regexp.imageType, 'ig');
        imgObj = {
          ...imgObj,
          isImg: regexpImageType.test(imgObj.name)
        };
      });
    }
    this.ngChange(this.value);
    this.ngTouched();

    return (files: ICustomFile[]) => {
      const fileArr: File[] = [];
      this.isLoad = false;

      for (const f of files) {
        if (this.withMeta && FileReader) {
          const fr = new FileReader();
          this.generateFileMeta(f, fr);
        }
        f.errors = { test: true };
        fileArr.push(f);
      }

      fn(fileArr);
    };
  }

  private generateFileMeta(f: ICustomFile, fr: FileReader) {
    if (f.type.match(/text.*/)) {
      f.textLoadReplay = this.setText(f, fr);
    } else if (f.type.match(/image.*/)) {
      f.imgLoadReplay = this.setImage(f, fr);
    }
  }

  private setText(f: ICustomFile, fr: FileReader): ReplaySubject<ProgressEvent> {
    const onloadReplay = new ReplaySubject<ProgressEvent>(1);
    const frLoadObs = fromEventPattern<ProgressEvent>(
      (handler: any) => fr.addEventListener('load', handler),
      (handler: any) => fr.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    frLoadObs.subscribe(onloadReplay);
    frLoadObs.pipe(take(1)).subscribe(() => {
      f.textContent = fr.result + '';
    });

    fr.readAsText(f);

    return onloadReplay;
  }

  private setImage(f: ICustomFile, fr: FileReader): ReplaySubject<[Event, ProgressEvent]> {
    f.isImg = true;

    const img = new Image();

    const imgLoadObs = fromEventPattern<Event>(
      (handler: any) => img.addEventListener('load', handler),
      (handler: any) => img.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    const frLoadObs = fromEventPattern<ProgressEvent>(
      (handler: any) => fr.addEventListener('load', handler),
      (handler: any) => fr.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    const onloadReplay = new ReplaySubject<[Event, ProgressEvent]>(1);
    const observables = [imgLoadObs, frLoadObs];

    forkJoin(observables)
      .pipe(take(1))
      .subscribe(onloadReplay);

    imgLoadObs.pipe(take(1)).subscribe(() => {
      f.imgHeight = img.height;
      f.imgWidth = img.width;
    });

    fr.readAsDataURL(f);

    return onloadReplay;
  }

  private generateRegExp(pattern: AllowedExtType): RegExp | null {
    if (!pattern) {
      return null;
    }

    if (pattern instanceof RegExp) {
      return new RegExp(pattern);
    } else if (typeof pattern === 'string') {
      return new RegExp(pattern, 'ig');
    } else if (pattern instanceof Array) {
      return new RegExp(`(${pattern.join('|')})`, 'ig');
    }
    return null;
  }

  validate(c: AbstractControl) {
    if (!c.value || !c.value.length || c.disabled) {
      return null;
    }

    const errors: ValidationErrors = {};
    const loaders: ReplaySubject<ProgressEvent>[] = [];

    for (const f of c.value) {
      if (this.size && this.size < f.size) {
        f.errors['fileSize'] = true;
        errors['fileSize'] = true;
      }

      if (f.isImg && (this.maxWidth || this.maxHeight)) {
        loaders.push(
          f.imgLoadReplay.pipe(
            take(1),
            map((e: ProgressEvent) => {
              if (this.maxWidth && f.imgWidth > this.maxWidth) {
                f.errors['imageWidth'] = true;
                errors['imageWidth'] = true;
              }
              if (this.maxHeight && f.imgHeight > this.maxHeight) {
                f.errors['imageHeight'] = true;
                errors['imageHeight'] = true;
              }
              return e;
            })
          )
        );
      }

      if (!this.allowedExt && !this.allowedTypes) {
        continue;
      }

      const extP = this.generateRegExp(this.allowedExt);
      const typeP = this.generateRegExp(this.allowedTypes);

      if (extP && !extP.test(f.name)) {
        f.errors['fileExt'] = true;
        errors['fileExt'] = true;
      }

      if (typeP && f.type && !typeP.test(f.type)) {
        f.errors['fileType'] = true;
        errors['fileType'] = true;
      }
    }

    if (!this.isLoad) {
      if (Object.keys(errors).length === 0) {
        this.fileList = c.value;

        this.usersService.uploadUserFiles(c.value).forEach((allRes, index: number) =>
          allRes.pipe(filter(Boolean)).subscribe({
            next: res => {
              if (res['status']) {
                if (!this.fileList[index].filePath) {
                  const reader = new FileReader();

                  reader.readAsDataURL(c.value[index]);
                  reader.onload = () => (this.fileList[index].filePath = reader.result);
                }

                if (res['status'] === 'progress') {
                  return JSON.stringify(res);
                } else {
                  return `File Index (${index}) Unhandled status: ${res['status']}`;
                }
              } else if (res['refId'] && res['signedUrl']) {
                c.value[index].id = res['refId'];
                c.value[index].filePath = res['signedUrl'];
                c.markAsTouched();
                this.isLoad = true;
              } else {
                this.logger.info(`FileIndex(${index})-Unhandled`, res);
              }
            },
            error: err => this.logger.error(`FileIndex(${index})-Error`, err)
          })
        );
      } else {
        this.controlContainer.control.get(this.controlName).setValue([]);
        this.uploadInput.nativeElement.value = '';
        const isFileTypeError = errors['fileType'] || errors['fileExt'];
        const isFileSizeError = errors['fileSize'];
        this.alertFailModal(isFileTypeError, isFileSizeError);

        return errors;
      }
    }
  }

  onClickDelete() {
    this.ngChange(this.value);

    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: 'Are you sure you want to delete this file?'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.fileList = [];
          this.controlContainer.control.get(this.controlName).setValue([]);
        }
      });
  }

  alertFailModal(isFileTypeError, isFileSizeError) {
    const initialState = {
      title: 'Failed',
      message: isFileTypeError
        ? this.fileTypeErrorTxt
        : `File size limit exceeded. <br/><br/> ${this.fileSizeErrorTxt}`
    };
    const confirmModalRef = this.modalService.show(AlertModalComponent, {
      initialState
    });

    this.logger.debug('alertFailModal->isFileSizeError', isFileSizeError);
    confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.fileList = [];
      this.controlContainer.control.get(this.controlName).setValue([]);
    });
  }

  onAddImage() {
    this.addImage.emit('');
  }

  onShowImage() {
    this.showImage.emit();
  }

  isShelfInformationUpload() {
    return this.fileModule === FileModuleEnum.SHELF_INFORMATION ? true : false;
  }
}
