import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';

import { ClassCodesProductMasterData, classCodesProductsMasterQuery } from '../gql/class-codes.gql';
import { ProductsSearchCriteria, ProductsSelectStatusRequest } from '../models';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class ProductService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly masterService: MasterService) {
    super();
    this.envService = this.env.services.products;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: ProductsSearchCriteria): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria, true);
    return this.http.get<any>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public updateSelectStatus(req: ProductsSelectStatusRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.select);
    return this.http.post<any>(url, req, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getClassCodeData(articleType: string): Observable<ApolloQueryResult<ClassCodesProductMasterData>> {
    return this.masterService.watchQuery<ClassCodesProductMasterData>({
      query: classCodesProductsMasterQuery,
      variables: {
        articleType
      }
    });
  }

  public getCJArticleData(articleNo: string): Observable<any> {
    const url = this.getFullUrl(this.envService.getCJArticleDetails, { articleNo });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
}
