export enum selectedWarehouseEnum {
  PRE_ORDER = 'PRE_ORDER',
  PRE_ORDER_HUB = 'PRE_ORDER_HUB'
}

export enum selectedWarehouseConditionEnum {
  CHECK_STOCK = 'CHECK_STOCK',
  CHECK_QUOTA = 'CHECK_QUOTA'
}

export enum warehouseOperator {
  TD = 'TD',
  TDSC = 'TDSC'
}

export enum crossDockSupplier {
  CJ = 'CJ'
}
