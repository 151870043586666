<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="confirm-modal-closeBtn">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-body history-modal-body overflow-auto">
    <table class="table table-borderless" aria-hidden="true">
      <thead>
        <tr>
          <th scope="col">No.</th>
          <th scope="col">Item Name</th>
          <th class="text-center" scope="col">VAT</th>
          <th class="text-right" scope="col">Amount(THB)</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let vatDetail of vatDetails; index as i">
          <tr [ngClass]="{ 'line-through': vatDetail.isReject }">
            <td>
              <span>{{ vatDetail.itemNo }}</span>
            </td>
            <td>
              <span>{{ vatDetail.productName }}</span>
            </td>
            <td class="text-center">
              <span>{{ vatDetail.vat || 0 }}%</span>
            </td>
            <td class="text-right align-top">
              <span *ngIf="!vatDetail.isInvalid"
                >{{
                  vatDetail.vatAmount | numberFormatDisplay: (vatAmountPrecision ? vatAmountPrecision : undefined)
                }}
              </span>
              <span *ngIf="vatDetail.isInvalid">(None)</span>
            </td>
            <td>
              <em
                *ngIf="
                  !vatDetail.supplierVat &&
                  vatDetail.supplierVat !== null &&
                  vatDetail.supplierVat !== undefined &&
                  vatDetail.productVat
                "
                class="icon-info-mini"
                title="No VAT Supplier"
                appTippy
                [tippyOptions]="{ arrow: true }"
              ></em>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
