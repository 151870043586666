import { createSelector } from '@ngrx/store';

import * as fromOrderSelectItemOrderState from '../reducers/order-select-item-order.reducers';
import { AppStates } from '../state/app.states';

const selectOrderSelectItemOrderState = (state: AppStates) => state.orderSelectItemOrder;

export const getOrderSelectItemOrderById = articleNo =>
  createSelector(selectOrderSelectItemOrderState, orders => orders.entities[articleNo]);

export const getCountOrderSelectItemOrder = createSelector(
  selectOrderSelectItemOrderState,
  fromOrderSelectItemOrderState.selectTotal
);

export const selectAllOrderSelectItemOrder = createSelector(
  selectOrderSelectItemOrderState,
  fromOrderSelectItemOrderState.selectAll
);

export const selectOrderUpdated = createSelector(selectOrderSelectItemOrderState, orders => orders.skipUpdated);
