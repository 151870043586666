import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DestroyDocumentListResponse, DestroyDocumentSearchCriteria } from '../models/destroy.model';
import { DestroyDocumentReportCriteria, DestroyTransactionReportCriteria } from '../models/report.model';
import { metricsReporting } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable()
export class DestroyService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.destroy;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportDestroyTransaction(exportCriteria: DestroyTransactionReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportDestroyTransaction);
    const metrics = metricsReporting([this.envService.url, this.envService.exportDestroyTransaction]);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  searchAllDestroyDocumentByCriteria(criteria: DestroyDocumentSearchCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.allDestroyDocument);
    const params = this.getParams(criteria);
    return this.http.get<DestroyDocumentListResponse>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  exportDestroyDocument(exportCriteria: DestroyDocumentReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportDestroyDocument, {
      docNo: exportCriteria.docNo
    });
    const metrics = metricsReporting([this.envService.url, this.envService.exportDestroyDocument]);

    delete exportCriteria.docNo;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
