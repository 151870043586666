<div class="admin-form mt-2 popover-container">
  <div class="page-title">
    <em class="page-title-icon icon-td-order"></em>
    <h4>{{ 'MENU.ORDER' | translate }}</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap">
        <button
          type="button"
          class="btn btn-primary mr-2"
          (click)="onSelectStore(orderTypeEnum.FIRST_LOT_ORDER)"
          *appHasPermission="['so_firstlot_m', 'or_fl_ast_m', 'or_fl_inv_m']"
        >
          <em class="icon-plus"></em>
          Create First Lot
        </button>
        <button
          type="button"
          class="btn btn-primary mr-2"
          (click)="onSelectStore(orderTypeEnum.SPECIAL_REQUEST)"
          *appHasPermission="['so_special_m']"
        >
          <em class="icon-plus"></em>
          Create Special Order
        </button>
        <app-import-export-button [buttons]="buttons" (import)="openImportModal()"> </app-import-export-button>
      </div>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="orderRequestStatusFilter"
            [searchable]="false"
            [clearable]="false"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
            (change)="onchangeStatus($event)"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Requested Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="requestedDateFrom"
                          formControlName="requestedDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="requestedDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="requestedDateTo"
                          formControlName="requestedDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="requestedDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Order Type
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="orderTypeFilter"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="orderType"
                          class="clear-cycle"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Order SubType
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="orderSubTypeAdvancedFilter"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="orderSubType"
                          class="clear-cycle"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Warehouse
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="warehouseList"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="warehouseNameDisplay"
                          bindValue="code"
                          formControlName="warehouseCode"
                          class="clear-cycle"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="mb-2">
                          Order Flow
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="orderFlowFilter"
                          [searchable]="false"
                          [multiple]="false"
                          [clearable]="false"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="orderFlow"
                        >
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="mb-2">
                          Cross Dock Supplier
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="crossDockSupplierFilter"
                          [searchable]="false"
                          [multiple]="false"
                          [clearable]="false"
                          bindLabel="name"
                          bindValue="code"
                          formControlName="crossDockSupplier"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="
      dateStringTag ||
      orderTypeStringTag ||
      orderSubTypeStringTag ||
      warehouseStringTag ||
      orderFlowTag ||
      crossDockSupplierTag
    "
  >
    <div class="search-criteria" *ngIf="dateStringTag">
      <a class="remove-criteria" (click)="clearFilterDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ dateStringTag }}: {{ dateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="orderTypeStringTag">
      <a class="remove-criteria" (click)="clearFilter('orderType')">
        <em class="icon-close"></em>
      </a>
      <span>{{ orderTypeStringTag }}: {{ orderTypeTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="orderSubTypeStringTag">
      <a class="remove-criteria" (click)="clearFilter('orderSubType')">
        <em class="icon-close"></em>
      </a>
      <span>{{ orderSubTypeStringTag }}: {{ orderSubTypeTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="warehouseStringTag">
      <a class="remove-criteria" (click)="clearFilter('warehouseCode')">
        <em class="icon-close"></em>
      </a>
      <span>{{ warehouseStringTag }}: {{ warehouseTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="orderFlowStringTag">
      <a class="remove-criteria" (click)="clearFilter('orderFlow')">
        <em class="icon-close"></em>
      </a>
      <span>{{ orderFlowStringTag }}: {{ orderFlowTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="crossDockSupplierStringTag">
      <a class="remove-criteria" (click)="clearFilter('crossDockSupplier')">
        <em class="icon-close"></em>
      </a>
      <span>{{ crossDockSupplierStringTag }}: {{ crossDockSupplierTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100" aria-label="data-table">
        <thead>
          <tr>
            <th class="text-center w-15" scope="col">Request No.</th>
            <th class="text-center w-20" scope="col">Store</th>
            <th class="text-center w-10" scope="col">Order Type</th>
            <th class="text-center w-10" scope="col">Order SubType</th>
            <th class="text-center w-15" scope="col">Warehouse</th>
            <th class="text-center w-10" scope="col">Order Flow</th>
            <th class="text-center w-10" scope="col">Requested By</th>
            <th class="text-center w-10" scope="col">Requested Date</th>
            <th class="text-center w-15" scope="col">Status</th>
            <th class="text-center w-5" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of resultList$ | async; index as i">
            <td class="text-left" (click)="goToView(result)">
              {{ result.requestNo | dashDisplay: '(None)' }}
            </td>
            <td class="text-left" (click)="goToView(result)">
              <app-click-to-copy [text]="result.storeCodeName | dashDisplay: '(None)'"></app-click-to-copy>
            </td>
            <td class="text-left" (click)="goToView(result)">
              {{ 'ORDER_TYPE.' + result.orderType | translate }}
            </td>
            <td class="text-left" (click)="goToView(result)">
              {{ result?.orderSubType ? ('ORDER_SUBTYPE.' + result?.orderSubType | translate) : '(None)' }}
            </td>
            <td class="text-left" (click)="goToView(result)">
              {{ result.warehouseDisplayName | numberFormatDisplay }}
            </td>
            <td class="text-left" (click)="goToView(result)">
              {{ result.orderFlowDisplayName | dashDisplay: '(None)' }}
            </td>
            <td class="text-left" (click)="goToView(result)">
              {{ result.requestBy | dashDisplay: '(None)' }}
            </td>
            <td class="text-center" (click)="goToView(result)">
              {{ result.requestedDate | amFromUtc | amLocal | dateDisplay }}
            </td>
            <td
              (click)="goToView(result)"
              class="text-left request-status"
              [ngClass]="getColorStatus(result.requestStatus)"
            >
              {{ 'ORDER_REQUEST.STATUS.' + result.requestStatus | translate }}
            </td>

            <td class="text-center">
              <a
                href="javascript:void(0)"
                class="btn-more mx-auto"
                [popover]="moreTemplate"
                placement="bottom right"
                [outsideClick]="true"
                [adaptivePosition]="false"
                *ngIf="
                  hasEditPermission(result.requestStatus) ||
                  hasDeletePermission(result.requestStatus) ||
                  hasCancelPermission(result.requestStatus) ||
                  hasViewHistoryPermission(result.requestStatus)
                "
              >
                <em class="icon-more"></em>
              </a>
              <ng-template #moreTemplate>
                <button
                  type="button"
                  class="btn btn-link"
                  *ngIf="hasEditPermission(result.requestStatus)"
                  (click)="goToEdit(result)"
                >
                  <em class="icon-edit"></em>Edit
                </button>
                <button
                  type="button"
                  class="btn btn-link"
                  *ngIf="hasDeletePermission(result.requestStatus)"
                  (click)="deleteOrderRequest(result.id)"
                >
                  <em class="icon-delete"></em>Delete
                </button>
                <button
                  type="button"
                  class="btn btn-link"
                  *ngIf="hasCancelPermission(result.requestStatus)"
                  (click)="cancelOrderRequest(result.requestNo, result.orderSubType)"
                >
                  <em class="icon-forbidden"></em>Cancel Request
                </button>
                <button
                  type="button"
                  class="btn btn-link"
                  *ngIf="result.requestStatus !== 'DRAFT' && hasViewHistoryPermission()"
                  (click)="showHistory(result.id, result.requestNo)"
                >
                  <em class="icon-history"></em>History
                </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <app-no-search-result [resultList$]="listState$" [hasSearchCriteria]="hasSearchCriteria"></app-no-search-result>

  <div
    class="modal fade"
    bsModal
    #modalCreateNewOrder="bs-modal"
    [config]="{ backdrop: 'static' }"
    [formGroup]="storeForm"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">{{ createModalTitle }}</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalCreateNewOrder()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid">
            <div class="form-group">
              <label for="orderSubType">Order SubType<span class="text-danger">*</span></label>
              <ng-select
                id="orderSubType"
                placeholder="Please select..."
                [items]="orderSubTypeFilter"
                [searchable]="false"
                [clearable]="false"
                bindLabel="label"
                bindValue="value"
                formControlName="orderSubType"
                (change)="onSelectOrderSubType()"
                [ngClass]="{
                  'is-invalid': submitted && storeForm.controls.orderSubType.errors
                }"
              >
              </ng-select>
              <div *ngIf="submitted && storeForm.controls.orderSubType.errors" class="invalid-feedback">
                <div *ngIf="storeForm.controls.orderSubType.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="storeList">
                Search by: Store Name, Store Code, Store ID<span class="text-danger">*</span>
              </label>
              <ng-select
                #storeSelect
                [items]="storeList | async"
                id="storeList"
                placeholder="Please select..."
                notFoundText="No Data"
                formControlName="store"
                bindLabel="storeCodeName"
                dropdownPosition="auto"
                labelForId="album"
                class="clear-cycle"
                [loading]="storeSearchLoading"
                [typeahead]="storeSearchInput$"
                [ngClass]="{
                  'is-invalid': submitted && storeForm.controls.store.errors
                }"
                (change)="onSelectWarehouse()"
                (clear)="(onSelectWarehouse)"
              >
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                  <div>
                    <strong>Store Name:</strong>
                    <span [ngOptionHighlight]="search" class="ml-1">
                      {{ item.name }}
                    </span>
                  </div>
                  <small>
                    <strong>Store Code:</strong><span [ngOptionHighlight]="search" class="ml-1">{{ item.code }}</span
                    >{{ ' | ' }} <strong>Store ID:</strong
                    ><span [ngOptionHighlight]="search" class="ml-1">{{ item.no }}</span>
                  </small>
                </ng-template>
              </ng-select>
              <div *ngIf="submitted && storeForm.controls.store.errors" class="invalid-feedback">
                <div *ngIf="storeForm.controls.store.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="warehouse">Warehouse<span class="text-danger">*</span></label>
              <ng-select
                id="warehouse"
                placeholder="Please select..."
                [items]="warehouseOptionList"
                [searchable]="false"
                [clearable]="false"
                bindLabel="warehouseNameDisplay"
                bindValue="code"
                formControlName="warehouseCode"
                (change)="setOrderFlow()"
                [ngClass]="{
                  'is-invalid': submitted && storeForm.controls.warehouseCode.errors
                }"
              >
              </ng-select>
              <div *ngIf="submitted && storeForm.controls.warehouseCode.errors" class="invalid-feedback">
                <div *ngIf="storeForm.controls.warehouseCode.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="orderFlow">Order Flow<span class="text-danger">*</span></label>
                  <ng-select
                    id="orderFlow"
                    placeholder="Please select..."
                    [items]="orderFlowList"
                    [searchable]="false"
                    [clearable]="false"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="orderFlow"
                    [ngClass]="{
                      'is-invalid': submitted && storeForm.controls.orderFlow.errors
                    }"
                    (change)="setCrossDockSupplier($event.value)"
                  >
                  </ng-select>
                  <div *ngIf="submitted && storeForm.controls.orderFlow.errors" class="invalid-feedback">
                    <div *ngIf="storeForm.controls.orderFlow.errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="crossDockSupplier">Cross Dock Supplier<span class="text-danger">*</span></label>
                  <ng-select
                    id="crossDockSupplier"
                    placeholder="Please select..."
                    [items]="crossDockSupplierList"
                    [searchable]="false"
                    [clearable]="false"
                    bindLabel="name"
                    bindValue="code"
                    formControlName="crossDockSupplier"
                    [ngClass]="{
                      'is-invalid': submitted && storeForm.controls.crossDockSupplier.errors
                    }"
                  >
                  </ng-select>
                  <div *ngIf="submitted && storeForm.controls.crossDockSupplier.errors" class="invalid-feedback">
                    <div *ngIf="storeForm.controls.crossDockSupplier.errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="goToCreate()" appPreventDoubleClick>
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
