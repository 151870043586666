import * as moment from 'moment';

import { ReportFileType } from './report.model';

export class BaseReport {
  exportBy?: string;
  timezone: string;
  constructor() {
    this.timezone = moment().format('Z');
  }
}

export class BaseReportAsFile extends BaseReport {
  fileType: ReportFileType;
}
