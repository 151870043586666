import { Action } from '@ngrx/store';

import {
  PurchaseRequestFreeItem,
  PurchaseRequestItem,
  TdAssortmentFreeSearchCriteria,
  TdAssortmentSearchCriteria
} from '../../models/purchase-request.model';

export enum TdAssortmentActionTypes {
  TD_ASSORTMENT_LIST_REQUEST = '[TD Assortment] TD Assortment List Request',
  TD_ASSORTMENT_FREE_ITEM_LIST_REQUEST = '[TD Assortment] TD Assortment Free ItemList Request',
  TD_ASSORTMENT_FREE_ITEM_UPDATE_ITEM = '[TD Assortment] TD Assortment Free Item Update Item',
  TD_ASSORTMENT_LIST_RESPONSE = '[TD Assortment] TD Assortment List Response',
  TD_ASSORTMENT_FREE_ITEM_LIST_RESPONSE = '[TD Assortment] TD Assortment Free Item List Response',
  TD_ASSORTMENT_LIST_RESET = '[TD Assortment] TD Assortment List Reset',
  TD_ASSORTMENT_UPDATE_ITEM = '[TD Assortment] TD Assortment Item Update Item'
}

export class TdAssortmentListRequest implements Action {
  readonly type = TdAssortmentActionTypes.TD_ASSORTMENT_LIST_REQUEST;

  constructor(public payload: TdAssortmentSearchCriteria) {}
}

export class TdAssortmentListResponse implements Action {
  readonly type = TdAssortmentActionTypes.TD_ASSORTMENT_LIST_RESPONSE;

  constructor(public payload: any) {}
}

export class TdAssortmentListReset implements Action {
  readonly type = TdAssortmentActionTypes.TD_ASSORTMENT_LIST_RESET;

  constructor() {}
}

export class TdAssortmentFreeItemListRequest implements Action {
  readonly type = TdAssortmentActionTypes.TD_ASSORTMENT_FREE_ITEM_LIST_REQUEST;

  constructor(public payload: TdAssortmentFreeSearchCriteria) {}
}

export class TdAssortmentFreeItemListResponse implements Action {
  readonly type = TdAssortmentActionTypes.TD_ASSORTMENT_FREE_ITEM_LIST_RESPONSE;

  constructor(public payload: any) {}
}

export class TdAssortmentUpdateItem implements Action {
  readonly type = TdAssortmentActionTypes.TD_ASSORTMENT_UPDATE_ITEM;

  constructor(public payload: PurchaseRequestItem) {}
}

export class TdAssortmentFreeItemUpdateItem implements Action {
  readonly type = TdAssortmentActionTypes.TD_ASSORTMENT_FREE_ITEM_UPDATE_ITEM;

  constructor(public payload: PurchaseRequestFreeItem) {}
}

export type TdAssortmentActions =
  | TdAssortmentListRequest
  | TdAssortmentListResponse
  | TdAssortmentListReset
  | TdAssortmentFreeItemListRequest
  | TdAssortmentFreeItemListResponse
  | TdAssortmentFreeItemUpdateItem
  | TdAssortmentUpdateItem;
