<div class="modal-content admin-form">
  <div class="modal-header">
    <h5 class="modal-title pull-left">Submit Data</h5>
    <button
      type="button"
      id="btnCloseModal"
      data-id="btnCloseModal"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label for="storeOrderScheduleTemplate">Store Order Schedule Template</label>
        <div class="d-flex">
          <input
            type="text"
            class="form-control mr-3"
            id="storeOrderScheduleTemplate"
            data-id="storeOrderScheduleTemplate"
            placeholder="Store Order Schedule Template"
            disabled
          />
          <button
            type="button"
            id="btnDownloadOrderScheduleTemplate"
            data-id="btnDownloadOrderScheduleTemplate"
            class="btn btn-primary"
            (click)="downloadTemplate()"
          >
            <em class="icon-download"></em>
            {{ 'REPORTS.DOWNLOAD' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md">
        <label for="importOrderSchedule">Store Order Schedule</label>
        <div class="d-flex">
          <app-get-excel-data
            id="importData"
            data-id="importData"
            formControlName="importData"
            [size]="50000000"
            [controlName]="'importData'"
            [disabled]="loading"
            [disabledInput]="!loadedMaster"
            (clearInput)="reset()"
            (showErrorMessage)="setErrorMessage($event)"
            (change)="onSelectFile()"
            class="flex-item"
          ></app-get-excel-data>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md">
        <div class="alert-danger form-group upload-error" *ngIf="errorMessage">
          <span [ngClass]="{ 'flex-70': errorData }">{{ errorMessage }}</span>
          <a
            *ngIf="errorData"
            id="btnDownloadError"
            data-id="btnDownloadError"
            class="btn btn-link upload-error-file"
            (click)="downloadErrorFile()"
            >download</a
          >
        </div>

        <div class="alert-success form-group" *ngIf="isValidated">
          <div>
            The data have been uploaded. Are you sure you want to submit?
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      id="btnCancelImportOrderSchedule"
      data-id="btnCancelImportOrderSchedule"
      class="btn btn-standard"
      (click)="bsModalRef.hide()"
    >
      Cancel
    </button>
    <button
      type="button"
      id="btnSubmitImportOrderSchedule"
      data-id="btnSubmitImportOrderSchedule"
      class="btn btn-primary btn btn-primary btn-loader"
      (click)="onSubmit()"
      [disabled]="!form.value.importData || !loadedMaster || loading || errorMessage"
    >
      Submit
    </button>
  </div>
</div>
