<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ titleModal }}</h4>
    <button
      id="closeModal"
      data-id="closeModal"
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-sub-header col-md-12 text-center">
    <em class="icon-report-real-time"></em>
    <span class="report-day">Real time</span>
  </div>

  <div class="modal-body admin-form" [formGroup]="form" (ngSubmit)="onExport()">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div *ngIf="!shelfType">
              <label>Shelf<span class="text-danger">*</span></label>
              <app-dropdown-multiple-select
                [parentForm]="form"
                controlName="no"
                [items]="shelfList"
                bindValue="no"
                bindLabel="shelfName"
              >
              </app-dropdown-multiple-select>
            </div>
            <div *ngIf="shelfType">
              <label>Shelf</label>
              <app-dropdown-multiple-select
                [parentForm]="form"
                controlName="no"
                [items]="listShelf"
                bindValue="no"
                bindLabel="shelfName"
              >
              </app-dropdown-multiple-select>
            </div>
          </div>
          <div class="form-group" *ngIf="shelfType">
            <label>Status</label>
            <ng-select
              id="exportStatusList"
              data-id="exportStatusList"
              [items]="exportStatusList"
              [searchable]="false"
              [clearable]="true"
              [multiple]="true"
              class="select-status w-100 clear-cycle"
              bindLabel="label"
              bindValue="value"
              formControlName="status"
              placeholder="Please select..."
            ></ng-select>
          </div>
        </div>
      </div>
      <div *ngIf="errorExport" class="alert-danger form-group mb-0 " role="alert">
        {{ errorExport }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      data-id="exportBtn"
      type="button"
      class="btn btn-primary"
      (click)="onExport()"
      [disabled]="!form.value.no.length && !shelfType"
      id="btnExport"
    >
      Export
    </button>
  </div>
</div>
