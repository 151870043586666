import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';

@Injectable()
export class OrderStoreUseExportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.orderRequestStoreUse;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportItemStoreUse(selected: Array<string>): Observable<any> {
    const url = this.getFullUrl(this.envService.export);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.STORE_USE,
          path: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STORE_USE,
          route: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.post<any>(
      url,
      { selected },
      {
        headers: this.loaderHeaders(0, metricsReq),
        observe: 'body',
        responseType: 'blob' as 'json'
      }
    );
  }
}
