import { GroupingBarcode, SupplierPrice } from './request-assortment.model';

export interface ProductMaster {
  id: string;
  version: number;
  articleNo: string;
  productName: string;
  classCode: string;
  className: string;
  familyCode: string;
  familyName: string;
  subClassCode: string;
  subClassName: string;
  segmentCode: string;
  segmentName: string;
  oem: string;
  source: string;
  supplier: string;
  shelfLife: number;
  statusTD: string;
  statusCJ: string;
  active: string;
  vat: boolean;
  restrictedItem: boolean;
  barcodes: ProductBarcode[];
  brand: string;
  supplierName: string;
  pickingUnit: string;
  minimumShelfLife: number;
  cjSupplierPrices: SupplierPrice[];
  groupingBarcodes: GroupingBarcode[];
}

export interface ProductBarcode {
  id: number;
  version: number;
  barcode: string;
  articleNo: string;
  unit: string;
  unitFactor: number;
  retailPrice: number;
  cost: number;
  wholesalePrice: number;
  barSize: string;
  pkgLength: number;
  pkgWidth: number;
  pkgHeight: number;
  pkgWeight: number;
  active: boolean;
  effectiveDate: string;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  new: boolean;
  allowToBuy: boolean;
  allowToSell: boolean;
  allowToOrder: boolean;
  allowToDiscount: boolean;
  allowCoupon: boolean;
  allowFree: boolean;
}

export enum ProductMasterSelectStatusEnum {
  NEW = 'NEW',
  SELECTED = 'SELECTED',
  UNUSED = 'UNUSED'
}
