import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreAssortmentPrintCriteria } from '../../pages/store-assortment/models/store-assortment.model';
import {
  BaseReport,
  ImportOrderScheduleData,
  POSRegisteredData,
  SignedUrlObject,
  StoreLockSaleRequest,
  StoreNo,
  StoreResponse,
  UpdateOrderScheduleTemplate
} from '../models';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import {
  MoneyInReportCriteria,
  RedeemTransactionReportCriteria,
  RewardTransactionReportCriteria
} from '../models/report.model';
import { metricsReporting } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable()
export class StoreService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.store;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getHistoryLogs(storeNo: StoreNo): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      storeNo: storeNo.storeNo
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getStoreById(storeNo: StoreNo): Observable<StoreResponse> {
    const url = this.getFullUrl(this.env.services.store.view, {
      storeNo: storeNo.storeNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public generatePOSRegistrationCode(storeNo: StoreNo, data: POSRegisteredData): Observable<any> {
    const url = this.getFullUrl(this.env.services.store.generatePOSRegisCode, {
      storeNo: storeNo.storeNo
    });

    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(0),
      observe: 'body',
      responseType: 'text' as 'json'
    });
  }

  public updatePOSRegistrationCode(storeNo: StoreNo, data: POSRegisteredData): Observable<any> {
    const url = this.getFullUrl(this.env.services.store.updatePOSRegisCode, {
      storeNo: storeNo.storeNo
    });

    return this.http.put<any>(url, data, {
      headers: this.headers,
      observe: 'body',
      responseType: 'text' as 'json'
    });
  }

  public deletePOS(storeNo: StoreNo): Observable<StoreResponse> {
    const url = this.getFullUrl(this.env.services.store.deletePOS, {
      storeNo: storeNo.storeNo
    });
    return this.http.post<any>(
      url,
      {},
      {
        headers: this.headers,
        observe: 'body'
      }
    );
  }

  public deactivatePOS(
    storeNo: any,
    body: { comment: string; type: string; runningNo: number; version: number }
  ): Observable<StoreResponse> {
    const url = this.getFullUrl(this.env.services.store.deactivatePOS, {
      storeNo: storeNo.storeNo,
      type: storeNo.type,
      runningNo: storeNo.runningNo
    });
    return this.http.post<any>(url, body, {
      headers: this.loaderHeaders(500),
      observe: 'body'
    });
  }

  public updateOrderSchedule(
    updateOrderScheduleTemplate: UpdateOrderScheduleTemplate
  ): Observable<UpdateOrderScheduleTemplate> {
    const url = this.getFullUrl(this.env.services.store.orderSchedule);
    return this.http.put<any>(url, updateOrderScheduleTemplate, { headers: this.headers });
  }

  public exportMoneyIn(exportCriteria: MoneyInReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportMoneyIn);
    const metrics = metricsReporting([this.envService.url, this.envService.exportMoneyIn]);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public printStoreAssortmentPdf(criteria: StoreAssortmentPrintCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.printStoreAssortmentPdf, { ...criteria });

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public activateStore(storeNo: StoreNo): Observable<any> {
    const url = this.getFullUrl(this.envService.activate, {
      storeNo: storeNo.storeNo
    });

    return this.http.put<any>(url, null, { headers: this.headers, observe: 'body' });
  }

  public deactivateStore(storeNo: StoreNo): Observable<any> {
    const url = this.getFullUrl(this.envService.deactivate, {
      storeNo: storeNo.storeNo
    });

    return this.http.put<any>(url, null, { headers: this.headers, observe: 'body' });
  }

  public exportAdjustmentRequestStore<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.exportAdjustment, { exportBy: exportCriteria.exportBy });
    const metrics = metricsReporting([this.envService.url, this.envService.exportAdjustment]);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportInventoryCountStore<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.inventoryCount, { exportBy: exportCriteria.exportBy });
    const metrics = metricsReporting([this.envService.url, this.envService.inventoryCount]);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportNoWSPStore<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.exportNoWSP, { exportBy: exportCriteria.exportBy });
    const metrics = metricsReporting([this.envService.url, this.envService.exportNoWSP]);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportInventoryVarianceReport<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.exportInventoryVarianceNestPDF);
    const params = this.getParams(exportCriteria, true);
    const metrics = metricsReporting([this.envService.url, this.envService.exportInventoryVarianceNestPDF]);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params
    });
  }

  public exportRewardTransaction(exportCriteria: RewardTransactionReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.rewardTransaction);
    const metrics = metricsReporting([this.envService.url, this.envService.rewardTransaction]);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportRedeemTransaction(exportCriteria: RedeemTransactionReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.redeemTransaction);
    const metrics = metricsReporting([this.envService.url, this.envService.redeemTransaction]);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public downloadStoreOrderScheduleTemplate() {
    const url = this.getFullUrl(this.envService.exportOrderScheduleTemplate);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public importStoreOrderSchedule(items: ImportOrderScheduleData[]) {
    const url = this.getFullUrl(this.envService.importStoreOrderSchedule);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STORE,
          route: this.envService.importStoreOrderSchedule,
          action: MetricActionType.IMPORT
        }
      ]
    };

    return this.http.put<any>(url, items, {
      headers: this.loaderHeaders(2000, metricsReq),
      observe: 'body'
    });
  }

  public getFileUrl(refId: string): Observable<SignedUrlObject> {
    const url = this.getFullUrl(this.envService.imageSignedUrl);
    return this.http.post<SignedUrlObject>(url, { refId }, { headers: this.headers });
  }

  public storeLockSale(request: StoreLockSaleRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.lockSale);
    return this.http.patch<any>(url, request, {
      headers: this.loaderHeaders(6000),
      observe: 'body'
    });
  }

  terminateDateStore(request: any): Observable<any> {
    const url = this.getFullUrl(this.envService.terminateDateStore, {
      storeNo: request.storeNo
    });

    return this.http.patch<any>(
      url,
      { version: request.version, terminateDate: request.terminateDate },
      {
        headers: this.loaderHeaders(2000),
        observe: 'body'
      }
    );
  }
}
