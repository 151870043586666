import { createReducer, on } from '@ngrx/store';

import { ExportFileTypeEnum } from '../../../enum/export-file-type.enum';
import { dynamicReportAction } from './dynamic-reports.actions';

export interface DynamicReportState {
  isSuccess: boolean;
  isSuccessFile: boolean;
  exportResponse: any;
  exportFileResponse: { fileName: string; file: Blob };
  extension?: ExportFileTypeEnum | null;
  error: any;
}

export const initialState: DynamicReportState = {
  isSuccess: null,
  isSuccessFile: null,
  exportResponse: null,
  exportFileResponse: null,
  extension: null,
  error: null
};

export const dynamicReportReducer = createReducer<DynamicReportState>(
  initialState,
  on(dynamicReportAction.clearAllStates, () => {
    return { ...initialState };
  }),
  on(dynamicReportAction.exportReportError, (state, { isSuccess, error }) => {
    return { ...state, isSuccess, error };
  }),
  on(dynamicReportAction.exportReportFileError, (state, { isSuccess, error }) => {
    return { ...state, isSuccessFile: isSuccess, error };
  }),
  on(dynamicReportAction.exportReportResponse, (state, { isSuccess, response }) => {
    return {
      ...state,
      isSuccess,
      exportResponse: { ...response }
    };
  }),
  on(dynamicReportAction.exportReportFileResponse, (state, { isSuccess, response, fileName, extension }) => {
    return {
      ...state,
      isSuccessFile: isSuccess,
      exportFileResponse: { fileName, file: response },
      extension: extension
    };
  })
);
