import { createSelector } from '@ngrx/store';

import { AppStates } from '@shared/store/state/app.states';

import * as fromShelfFixAssetListResponseState from './shelf-fix-asset.reducers';

const selectShelfFixAssetListState = (state: AppStates) => state.shelfFixAsset;

export const selectAllShelfFixAssetList = createSelector(
  selectShelfFixAssetListState,
  fromShelfFixAssetListResponseState.selectAll
);

export const selectShelfFixAssetList = createSelector(
  selectShelfFixAssetListState,
  (state: fromShelfFixAssetListResponseState.ShelfFixAssetState) => state
);

export const selectShelfFixAssetListCriteria = createSelector(selectShelfFixAssetListState, state => {
  return state.criteriaObject;
});

export const selectShelfFixAssetListHistory = createSelector(
  selectShelfFixAssetListState,
  (state: fromShelfFixAssetListResponseState.ShelfFixAssetState) => {
    return state.auditLogs;
  }
);

export const selectShelfFixAssetById = createSelector(
  selectShelfFixAssetListState,
  (state: fromShelfFixAssetListResponseState.ShelfFixAssetState) => state.selected
);
