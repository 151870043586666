import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MerchantRequestService } from '../../services/merchant-request.service';
import { ShelfTypesService } from '../../services/shelf-types.service';
import {
  AddShelfTypeErrorAction,
  AddShelfTypeRequestAction,
  AddShelfTypeResponseAction,
  FirstLotOrderActionType,
  ShelfDetailsRequestAction,
  ShelfDetailsResponseAction,
  ShelfItemsRequestAction,
  ShelfItemsResponseAction
} from '../actions/first-lot-order.action';
import { LayoutActionLoadError } from '../actions/layout.action';

@Injectable()
export class FirstLotOrderEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly shelfTypesService: ShelfTypesService,
    private readonly merchantRequestService: MerchantRequestService,
    private readonly logger: NGXLogger
  ) {}

  shelfListRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfItemsRequestAction>(FirstLotOrderActionType.SHELF_ITEMS_REQUEST),
      map(action => {
        this.logger.debug(`@Effect ${FirstLotOrderActionType.SHELF_ITEMS_REQUEST}: ` + this.stringify(action));
        return action;
      }),
      switchMap(() => {
        return this.shelfTypesService.getShelfList().pipe(
          map(shelfListResponse => {
            return new ShelfItemsResponseAction(shelfListResponse);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  addShelfTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AddShelfTypeRequestAction>(FirstLotOrderActionType.ADD_SHELF_TYPE_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${FirstLotOrderActionType.ADD_SHELF_TYPE_REQUEST}: ` + JSON.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload =>
        this.shelfTypesService.addShelfTypeList(payload).pipe(
          map(() => new AddShelfTypeResponseAction(payload)),
          catchError(error => of(new AddShelfTypeErrorAction(error.error)))
        )
      )
    );
  });

  shelfDetailsRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfDetailsRequestAction>(FirstLotOrderActionType.SHELF_DETAILS_REQUEST),
      map(action => {
        this.logger.debug(`@Effect ${FirstLotOrderActionType.SHELF_DETAILS_REQUEST}: ` + this.stringify(action));
        return action.payload;
      }),
      switchMap(payload => {
        return this.merchantRequestService.getRequestShelfDetails(payload).pipe(
          map(shelfDetailResponse => {
            return new ShelfDetailsResponseAction(shelfDetailResponse.shelfTypes);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
