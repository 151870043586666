import { Action } from '@ngrx/store';

import { ErrorResponse } from '../../../../shared/models';
import {
  ChangeStatusRewardCatalogRequest,
  RewardCatalogCreateRequest,
  RewardCatalogRequestId,
  RewardCatalogRequestListResponse,
  RewardCatalogRequestListSearchCriteria,
  RewardCatalogRequestResponse,
  RewardCatalogRequestViewResponse
} from '../../../../shared/models/reward-catalog.model';

export enum RewardCatalogRequestActionType {
  REWARD_CATALOG_REQUEST_CREATE_SAVE_REQUEST = '[RewardCatalog] Create RewardCatalog Save Request',
  REWARD_CATALOG_REQUEST_CREATE_SAVE_RESPONSE = '[RewardCatalog] Create RewardCatalog Save Response',
  REWARD_CATALOG_REQUEST_CREATE_SAVE_ERROR = '[RewardCatalog] Create RewardCatalog Save Error',
  REWARD_CATALOG_REQUEST_CREATE_SUBMIT_REQUEST = '[RewardCatalog] Create RewardCatalog Submit Request',
  REWARD_CATALOG_REQUEST_CREATE_SUBMIT_RESPONSE = '[RewardCatalog] Create RewardCatalog Submit Response',
  REWARD_CATALOG_REQUEST_CREATE_SUBMIT_ERROR = '[RewardCatalog] Create RewardCatalog Submit Error',
  REWARD_CATALOG_REQUEST_CREATE_RESET = '[RewardCatalog] Create RewardCatalog Reset',
  REWARD_CATALOG_REQUEST_APPROVE_REQUEST = '[RewardCatalog] Request Approve RewardCatalog request',
  REWARD_CATALOG_REQUEST_APPROVE_RESPONSE = '[RewardCatalog] Request Approve RewardCatalog Response',
  REWARD_CATALOG_REQUEST_APPROVE_ERROR = '[RewardCatalog] Request Approve RewardCatalog Error',
  REWARD_CATALOG_REQUEST_LIST_REQUEST = '[RewardCatalog] Request List Request',
  REWARD_CATALOG_REQUEST_LIST_RESPONSE = '[RewardCatalog] Request List Response',
  REWARD_CATALOG_REQUEST_LIST_ERROR = '[RewardCatalog] Request List Error',
  REWARD_CATALOG_REQUEST_GET_BY_ID_REQUEST = '[RewardCatalog] Get RewardCatalog Request by Id Request',
  REWARD_CATALOG_REQUEST_GET_BY_ID_RESPONSE = '[RewardCatalog] Get RewardCatalog Request by Id Response',
  REWARD_CATALOG_REQUEST_GET_BY_ID_ERROR = '[RewardCatalog] Get RewardCatalog Request by Id Error',
  REWARD_CATALOG_REQUEST_GET_BY_ID_RESET = '[RewardCatalog] Get RewardCatalog Request by Id Reset',
  REWARD_CATALOG_REQUEST_HISTORY_REQUEST = '[RewardCatalog] Request RewardCatalog Request',
  REWARD_CATALOG_REQUEST_HISTORY_RESPONSE = '[RewardCatalog] Request RewardCatalog Response',
  REWARD_CATALOG_REQUEST_DELETE_REQUEST = '[RewardCatalog] Request Delete RewardCatalog request',
  REWARD_CATALOG_REQUEST_DELETE_RESPONSE = '[RewardCatalog] Request Delete RewardCatalog Response',
  REWARD_CATALOG_REQUEST_DELETE_RESET = '[RewardCatalog] Request Delete RewardCatalog Reset',
  REWARD_CATALOG_REQUEST_RESET = '[RewardCatalog] Create RewardCatalog Request Reset'
}

export class RewardCatalogRequestCreateSaveRequestAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_CREATE_SAVE_REQUEST;

  constructor(public payload: RewardCatalogCreateRequest) {}
}

export class RewardCatalogRequestCreateSaveResponseAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_CREATE_SAVE_RESPONSE;

  constructor(public payload: RewardCatalogRequestResponse) {}
}

export class RewardCatalogRequestCreateSaveErrorAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_CREATE_SAVE_ERROR;

  constructor(public payload: ErrorResponse) {}
}
export class RewardCatalogRequestCreateSubmitRequestAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_CREATE_SUBMIT_REQUEST;

  constructor(public payload: RewardCatalogCreateRequest) {}
}

export class RewardCatalogRequestCreateSubmitResponseAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_CREATE_SUBMIT_RESPONSE;

  constructor(public payload: RewardCatalogRequestResponse) {}
}

export class RewardCatalogRequestCreateSubmitErrorAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_CREATE_SUBMIT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class RewardCatalogRequestCreateResetAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_CREATE_RESET;

  constructor() {}
}

export class RewardCatalogRequestApproveRequestAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_APPROVE_REQUEST;

  constructor(public payload: ChangeStatusRewardCatalogRequest) {}
}

export class RewardCatalogRequestApproveResponseAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_APPROVE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class RewardCatalogRequestApproveErrorAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_APPROVE_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class RewardCatalogRequestListRequestAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_LIST_REQUEST;

  constructor(public payload: RewardCatalogRequestListSearchCriteria) {}
}

export class RewardCatalogRequestListResponseAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_LIST_RESPONSE;

  constructor(public payload: RewardCatalogRequestListResponse) {}
}

export class RewardCatalogRequestListErrorAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class RewardCatalogRequestByIdRequestAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: RewardCatalogRequestId) {}
}

export class RewardCatalogRequestByIdResponseAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: RewardCatalogRequestViewResponse) {}
}

export class RewardCatalogRequestByIdErrorAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_GET_BY_ID_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class RewardCatalogRequestHistoryRequestAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_HISTORY_REQUEST;

  constructor(public payload: string) {}
}

export class RewardCatalogRequestHistoryResponseAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_HISTORY_RESPONSE;

  constructor(public payload: { auditLogs }) {}
}

export class ResetRewardCatalogRequestSelected implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_GET_BY_ID_RESET;
}

export class RewardCatalogDeleteRequestAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_DELETE_REQUEST;

  constructor(public payload: RewardCatalogRequestId) {}
}

export class RewardCatalogDeleteResponseAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_DELETE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class RewardCatalogDeleteResetAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_DELETE_RESET;

  constructor() {}
}

export class RewardCatalogRequestResetAction implements Action {
  readonly type = RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_RESET;

  constructor() {}
}

export type RewardCatalogRequestActions =
  | RewardCatalogRequestCreateSaveRequestAction
  | RewardCatalogRequestCreateSaveResponseAction
  | RewardCatalogRequestCreateSaveErrorAction
  | RewardCatalogRequestCreateSubmitRequestAction
  | RewardCatalogRequestCreateSubmitResponseAction
  | RewardCatalogRequestCreateSubmitErrorAction
  | RewardCatalogRequestCreateResetAction
  | RewardCatalogRequestApproveRequestAction
  | RewardCatalogRequestApproveResponseAction
  | RewardCatalogRequestApproveErrorAction
  | RewardCatalogRequestListRequestAction
  | RewardCatalogRequestListResponseAction
  | RewardCatalogRequestListErrorAction
  | RewardCatalogRequestByIdRequestAction
  | RewardCatalogRequestByIdResponseAction
  | RewardCatalogRequestByIdErrorAction
  | RewardCatalogRequestHistoryRequestAction
  | RewardCatalogRequestHistoryResponseAction
  | ResetRewardCatalogRequestSelected
  | RewardCatalogDeleteRequestAction
  | RewardCatalogDeleteResponseAction
  | RewardCatalogDeleteResetAction
  | RewardCatalogRequestResetAction;
