import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { ErrorResponse } from '../../models';
import {
  OrderFixAssetCreateRequest,
  OrderFixAssetRequestViewResponse
} from '../../models/order-request-fix-asset.model';
import {
  OrderFixAssetRequestActionType,
  OrderFixAssetRequestActions
} from '../actions/order-fix-asset-request.actions';
import { BaseState } from '../state/base.state';

export interface OrderFixAssetCreateResponseState extends EntityState<OrderFixAssetCreateRequest>, BaseState {
  isLoading: boolean;
  isSave?: boolean | null;
  isApproveRejectSuccess?: boolean | null;
  result?: {
    response: OrderFixAssetRequestViewResponse | null;
    errorResponse: ErrorResponse | null;
  };
}

export const adapter: EntityAdapter<OrderFixAssetCreateRequest> = createEntityAdapter<OrderFixAssetCreateRequest>();

export const initialOrderFixAssetCreateResponseState: OrderFixAssetCreateResponseState = adapter.getInitialState({
  isLoading: false,
  isSave: null,
  isApproveRejectSuccess: null
});

export function orderFixAssetCreateReducers(
  state = initialOrderFixAssetCreateResponseState,
  action: OrderFixAssetRequestActions
): OrderFixAssetCreateResponseState {
  switch (action.type) {
    case OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SAVE_RESPONSE:
    case OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SUBMIT_RESPONSE:
      return {
        ...state,
        isSave: OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SAVE_RESPONSE === action.type,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    case OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SUBMIT_ERROR:
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    case OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_APPROVE_RESPONSE:
      return {
        ...state,
        isApproveRejectSuccess: action.payload.isSuccess
      };
    case OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_RESET:
      return initialOrderFixAssetCreateResponseState;
    default: {
      return state;
    }
  }
}
