import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { isEmpty } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ClientIdTypeEnum } from '@shared/enum/client-id.enum';
import { ModalButtonResponseEnum } from '@shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '@shared/layouts';
import { ErrorResponse } from '@shared/models';
import { MasterService } from '@shared/services/master.service';
import { HideAllModalAction } from '@shared/store/actions/hide-modal.action';

import { environment as env } from '../../../environments/environment';
import { PasswordValidation } from '../../shared/custom-validators/password-validation';
import {
  ClientIdAction,
  ForceChangePasswordRequestAction,
  ForceChangePasswordReset,
  UserInformationResetAction
} from '../../shared/store/actions/user-info.action';
import { selectUserInfoResult } from '../../shared/store/selectors/auth-user-info.selector';
import { selectForceChangePasswordResult } from '../../shared/store/selectors/user-selector';
import { AppStates } from '../../shared/store/state/app.states';

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.scss']
})
export class ForceChangePasswordComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public changePasswordForm: UntypedFormGroup;
  public submitted;
  public forceChangePasswordResult$: Observable<any>;
  public userInfoResult$: Observable<any>;
  public userName: string;
  public isShowPassword: boolean;
  public isShowReEnterPassword: boolean;
  public alertDanger = false;
  private localStore: Observable<any>;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<AppStates>,
    private readonly router: Router,
    private readonly translate: TranslateService,
    protected readonly modalService: BsModalService,
    private readonly cookieService: CookieService,
    private readonly masterService: MasterService
  ) {
    super();
    this.changePasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, PasswordValidation, this.checkNewpassMismatch]],
      reEnterPassword: ['', [Validators.required, this.checkConfirmpassMismatch]]
    });
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.forceChangePasswordResult$ = this.localStore.pipe(select(selectForceChangePasswordResult));
    this.submitted = false;

    this.forceChangePasswordResult$.subscribe(data => {
      this.alertDanger = false;
      if (data.result.response && data.result.response.statusCode === '200') {
        this.backToHome();
      }
      if (!data.result.response && data.result.errorResponse) {
        if (['01008'].includes(data.result.errorResponse.code)) {
          this.alertAndBack(data.result.errorResponse);
        } else if (
          data.result.errorResponse &&
          ['ERROR_CODE.undefined', 'ERROR_CODE.02002'].includes(data.result.errorResponse.translateKey)
        ) {
          this.alertDanger = true;
        }
      }
    });
    this.userInfoResult$ = this.localStore.pipe(select(selectUserInfoResult));
    this.userInfoResult$.pipe(filter(value => Boolean(value))).subscribe(userInfo => {
      this.userName = userInfo.userName;
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onSubmit() {
    this.submitted = true;
    if (this.changePasswordForm.valid) {
      this.store.dispatch(
        new ForceChangePasswordRequestAction({
          userName: this.userName,
          newPassword: this.changePasswordFormGetter.newPassword.value
        })
      );
    }
  }

  get changePasswordFormGetter(): {
    [key: string]: AbstractControl;
  } {
    return this.changePasswordForm.controls;
  }

  get checkConfirmpassMismatch(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const pass = this.changePasswordForm?.controls.newPassword.value;
      const confirmPass = control.value;
      return pass === confirmPass ? null : { passwordNotEquivalent: true };
    };
  }

  get checkNewpassMismatch(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      if (this.changePasswordForm?.controls) {
        const pass = control.value;
        const confirmPass = this.changePasswordForm.controls.reEnterPassword.value;
        if (pass === confirmPass) {
          this.changePasswordForm?.controls.reEnterPassword.setErrors(null);
          if (this.submitted && isEmpty(confirmPass)) {
            this.changePasswordForm?.controls.reEnterPassword.setErrors({ required: true });
          }
        } else {
          this.changePasswordForm?.controls.reEnterPassword.setErrors({ passwordNotEquivalent: true });
        }
      }
      return null;
    };
  }

  toggleInputPasswordType() {
    this.isShowPassword = !this.isShowPassword;
  }

  toggleInputReEnterPasswordType() {
    this.isShowReEnterPassword = !this.isShowReEnterPassword;
  }

  alertAndBack(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant('ERROR_CODE.' + errorResponse.code)
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();

        this.clearAuth();
        this.backToHome();
      }
    });
  }

  backToHome() {
    this.router.navigateByUrl('/');
  }

  private clearAuth() {
    this.cookieService.deleteAll(env.cookies.path, env.cookies.domain);
    this.store.dispatch(new ClientIdAction(env.defaultClientId as ClientIdTypeEnum));
    this.store.dispatch(new UserInformationResetAction());
    this.store.dispatch(new ForceChangePasswordReset());
    this.store.dispatch(new HideAllModalAction(true));
    this.masterService.clearStore();
  }
}
