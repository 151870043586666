export class TaskPermission {
  private _hasManageTask: boolean;
  private _hasApproverTask: boolean;
  private _hasViewTaskRequest: boolean;
  private _hasViewTask: boolean;

  public get hasManageTask(): boolean {
    return this._hasManageTask;
  }

  public get hasApproverTask(): boolean {
    return this._hasApproverTask;
  }

  public get hasViewTaskRequest(): boolean {
    return this._hasViewTaskRequest;
  }

  public get hasViewTask(): boolean {
    return this._hasViewTask;
  }

  checkPermission(authGuardService) {
    this._hasManageTask = authGuardService.checkPermission(['task_m']);
    this._hasApproverTask = authGuardService.checkPermission(['task_req_app']);
    this._hasViewTaskRequest = authGuardService.checkPermission(['task_req_v']);
    this._hasViewTask = authGuardService.checkPermission(['task_v']);
  }
}
