import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BaseApiResponse } from '../../models/base-api-response';
import { AuthService } from '../../services';
import { UsersService } from '../../services/users.service';
import {
  ForgotUsernameActionTypes,
  ForgotUsernameFailed,
  ForgotUsernameRequestAction,
  ForgotUsernameSuccess
} from '../actions/forgot-username.action';

@Injectable()
export class ForgotUsernameEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly userService: UsersService,
    private readonly router: Router
  ) {}

  forgotUsername$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ForgotUsernameActionTypes.FORGOT_USERNAME_REQUEST),
      map((action: ForgotUsernameRequestAction) => action.payload),
      switchMap(payload => {
        return this.authService.bypassAuthenToken().pipe(
          map(result => result['access_token']),
          switchMap(accessToken => {
            return this.userService.forgotUsername(payload, accessToken);
          }),
          map((forgotUsername: HttpResponse<BaseApiResponse>) => {
            this.router.navigateByUrl('/');
            return new ForgotUsernameSuccess({ data: forgotUsername, mobileNumber: payload.mobileNumber });
          }),
          catchError(err => {
            return of(new ForgotUsernameFailed({ error: err.error, mobileNumber: payload.mobileNumber }));
          })
        );
      })
    );
  });
}
