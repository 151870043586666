<div id="forgot-password-form" class="flex-item d-flex justify-content-center">
  <div class="right-section col-sm-10 d-flex align-items-center">
    <div class="header">
      <div *ngIf="step === 'REQUEST_OTP'" class="mb-4">
        <div class="title-text">{{ 'FORGOT_PASSWORD.' + getPageTitle() | translate }}</div>
        <div class="sub-title-text">{{ 'FORGOT_PASSWORD.PLEASE_ENTER_YOUR_USERNAME' | translate }}</div>
      </div>

      <div *ngIf="step === 'ENTER_OTP'" class="mb-4">
        <div class="title-text">{{ 'FORGOT_PASSWORD.' + getPageTitle() | translate }}</div>
        <div
          class="sub-title-text"
          [innerHTML]="
            'FORGOT_PASSWORD.FORGOT_PASSWORD_OTP_SUCCESS'
              | translate
                : {
                    phoneNo: (forgotPasswordResult$ | async).result.phoneNo | mask: 'AAA-AAA-AAAA',
                    refNo: (forgotPasswordResult$ | async).result.refNo
                  }
          "
        ></div>
        <div class="mt-2">
          <span class="otp-resend"
            >{{ 'FORGOT_PASSWORD.REQUEST_NOT_RECEIVE_OTP' | translate }}&nbsp;<a
              [ngClass]="{ disabled: hasOtpDelay }"
              href="javascript:void(0);"
              (click)="hasOtpDelay || resendOtp()"
              >{{ 'FORGOT_PASSWORD.REQUEST_RESEND_OTP' | translate }}</a
            >
          </span>
        </div>
      </div>

      <div *ngIf="step === 'CHANGE_PASSWORD'" class="mb-4">
        <div class="title-text">New Password</div>
        <div class="sub-title-text">Create a new password for your account.</div>
      </div>

      <div class="right-section-control flex-item">
        <form [formGroup]="forgotPasswordForm">
          <!-- Request OTP -->
          <div
            *ngIf="
              (forgotPasswordResult$ | async).result.errorResponse.translateKey &&
              (forgotPasswordResult$ | async).result.errorResponse.code !== '01008'
            "
            class="form-group"
          >
            <div class="alert-danger alert-icon mb-4" role="alert">
              <span
                *ngIf="(forgotPasswordResult$ | async).result.errorResponse.code === '00000'; else defaultMessage"
                translate="FORGOT_PASSWORD.FORGOT_PASSWORD_OTP_FAILED"
              >
              </span>
              <ng-template #defaultMessage>
                <span
                  [innerHTML]="(forgotPasswordResult$ | async).result.errorResponse.translateKey | translate"
                ></span>
              </ng-template>
            </div>
          </div>

          <form formGroupName="requestOTP" *ngIf="step === 'REQUEST_OTP'" (submit)="onSubmit()">
            <div class="form-group">
              <label for="userName"
                >{{ 'FORGOT_PASSWORD.USERNAME' | translate }}<span class="text-danger">*</span></label
              >
              <input
                type="text"
                formControlName="userName"
                class="form-control"
                id="userName"
                name="userName"
                placeholder="{{ 'FORGOT_PASSWORD.ENTER_USERNAME' | translate }}"
                [ngClass]="{ 'is-invalid': submitted && formRequestOTP.controls.userName.errors }"
              />

              <div *ngIf="submitted && formRequestOTP.controls.userName.errors" class="invalid-feedback">
                <div *ngIf="formRequestOTP.controls.userName.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </form>
          <!-- Request OTP -->

          <div
            *ngIf="
              step === 'ENTER_OTP' &&
              isTimeout &&
              (forgotPasswordResult$ | async).result.isSentOTP &&
              (forgotPasswordResult$ | async).result.errorResponse.code !== '01006'
            "
            class="form-group"
          >
            <div class="alert-danger alert-icon mb-4" role="alert">
              <span [innerHTML]="'FORGOT_PASSWORD.FORGOT_PASSWORD_OTP_TIMEOUT' | translate"></span>
            </div>
          </div>

          <!-- Input OTP -->
          <form
            formGroupName="inputOTP"
            [ngClass]="{ 'd-none': step !== 'ENTER_OTP' }"
            (keydown)="onEnterKey($event)"
          >
            <div class="form-group">
              <div class="otp">
                <app-otp-input
                  [ngClass]="{ 'is-timeout': isTimeout }"
                  [otp]="otp"
                  (inputChange)="onOtpChange($event)"
                ></app-otp-input>
              </div>
            </div>
            <div>
              <div class="countdown">
                <span [innerHTML]="'FORGOT_PASSWORD.OTP_EXPIRY_IN' | translate"></span
                ><span class="minutes"></span>:<span class="seconds"></span
                ><span [innerHTML]="'FORGOT_PASSWORD.OTP_EXPIRY_MINUTE' | translate"></span>
              </div>
            </div>
          </form>
          <!-- Input OTP -->

          <!-- Change Password -->
          <form formGroupName="changePassword" *ngIf="step === 'CHANGE_PASSWORD'" (keydown)="onEnterKey($event)">
            <div class="form-group">
              <label for="new-password">{{ 'NEW_PASSWORD' | translate }}<span class="text-danger">*</span></label>
              <div class="inner-addon right-addon">
                <span (click)="toggleInputPasswordType()" [ngClass]="{ 'is-show': isShowPassword }">
                  <em
                    class="icon-eye glyphicon"
                    [ngClass]="{
                      'icon-eye': !isShowPassword,
                      'icon-invisible': isShowPassword
                    }"
                  ></em>
                </span>
                <input
                  type="{{ isShowPassword ? 'text' : 'password' }}"
                  class="form-control"
                  formControlName="newPassword"
                  id="new-password"
                  name="newPassword"
                  placeholder="{{ 'ENTER_NEW_PASSWORD' | translate }}"
                  maxlength="50"
                  [ngClass]="{ 'is-invalid': submitted && formChangePassword.controls.newPassword.errors }"
                  (keydown.space)="$event.preventDefault()"
                  (paste)="$event.preventDefault()"
                />
                <div
                  *ngIf="submitted && formChangePassword.controls.newPassword.errors"
                  class="invalid-feedback"
                  id="new-password-error"
                >
                  <div *ngIf="formChangePassword.controls.newPassword.errors.required; else invalidPassword">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                  <ng-template #invalidPassword>
                    <div *ngIf="formChangePassword.controls.newPassword.errors.invalidPassword">
                      {{ 'ERRORS.PASSWORD_RULE' | translate }}
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="re-enter-password"
                >{{ 'CONFIRM_NEW_PASSWORD' | translate }}<span class="text-danger">*</span></label
              >

              <div class="inner-addon right-addon">
                <span (click)="toggleInputReEnterPasswordType()" [ngClass]="{ 'is-show': isShowReEnterPassword }">
                  <em
                    class="icon-eye glyphicon"
                    [ngClass]="{
                      'icon-eye': !isShowReEnterPassword,
                      'icon-invisible': isShowReEnterPassword
                    }"
                  ></em>
                </span>
                <input
                  type="{{ isShowReEnterPassword ? 'text' : 'password' }}"
                  class="form-control"
                  formControlName="reEnterPassword"
                  id="re-enter-password"
                  name="reEnterPassword"
                  placeholder="{{ 'ENTER_NEW_PASSWORD' | translate }}"
                  maxlength="50"
                  [ngClass]="{ 'is-invalid': submitted && formChangePassword.controls.reEnterPassword.errors }"
                  (keydown.space)="$event.preventDefault()"
                  (paste)="$event.preventDefault()"
                />
                <div
                  *ngIf="submitted && formChangePassword.controls.reEnterPassword.errors"
                  class="invalid-feedback"
                  id="re-enter-password-error"
                >
                  <div *ngIf="formChangePassword.controls.reEnterPassword.errors.required">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                  <div *ngIf="formChangePassword.controls.reEnterPassword.errors.passwordNotEquivalent">
                    {{ 'ERRORS.PASSWORD_NOT_MATCH' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- Change Password -->
        </form>

        <div class="form-group mt-4 pb-2">
          <button
            type="submit"
            id="btnContinue"
            name="btnContinue"
            class="btn btn-primary btn-block"
            [ngClass]="{ disabled: isDisableButton() }"
            [disabled]="isDisableButton()"
            (click)="onSubmit()"
          >
            {{ buttonDisplay | translate }}
          </button>
        </div>

        <div class="form-group">
          <button type="button" class="btn btn-standard btn-block" (click)="onCancel()">
            {{ 'CANCEL' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
