import { Component, Input } from '@angular/core';

import { Box } from '../../models';

@Component({
  selector: 'app-boxs-display',
  templateUrl: './boxs-display.component.html',
  styleUrls: ['./boxs-display.component.scss']
})
export class BoxsDisplayComponent {
  @Input() boxs: Box[];
  constructor() {
    // intentionally empty
  }

  isNumber(val) {
    return typeof val === 'number';
  }
}
