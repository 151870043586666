import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SelectStoreDropdownPagingList, StoreDropdownSearchCriteria } from '../models/store-dropdown.model';
import { BaseService } from './base.service';

@Injectable()
export class StoreDropdownService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.masters;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public selectStoreDropdownList(criteria: StoreDropdownSearchCriteria): Observable<SelectStoreDropdownPagingList> {
    const url = this.getFullUrl(this.envService.store);
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }
}
