<div class="row section-header justify-content-between">
  <span>POS Device</span>
</div>
<app-pos-terminal
  #posTerminal
  [data]="data"
  [showAddDevice]="env.isShowAddPosDevice"
  [device]="device.POS"
  [version]="version"
></app-pos-terminal>
<ng-container *ngIf="env.isShowHandDevice">
  <hr class="section-divider" />

  <div class="row section-header justify-content-between">
    <span>HAND Device</span>
  </div>
  <app-pos-terminal #handTerminal [data]="data" [device]="device.HAND" [version]="version"></app-pos-terminal>
</ng-container>
