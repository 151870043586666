import { createSelector } from '@ngrx/store';

import { AppStates } from '../../../../shared/store/state/app.states';
import * as fromRewardCatalogListResponseState from './reward-catalog.reducers';

const selectRewardCatalogState = (state: AppStates) => state.rewardCatalog;

export const selectAllRewardCatalogList = createSelector(
  selectRewardCatalogState,
  fromRewardCatalogListResponseState.selectAll
);

export const selectRewardCatalogList = createSelector(
  selectRewardCatalogState,
  (state: fromRewardCatalogListResponseState.RewardCatalogState) => state
);

export const selectRewardCatalogItem = createSelector(
  selectRewardCatalogState,
  (state: fromRewardCatalogListResponseState.RewardCatalogState) => state.selected
);

export const selectRewardCatalogListCriteria = createSelector(selectRewardCatalogState, state => {
  return state.criteriaObject;
});

export const selectRewardCatalogById = createSelector(
  selectRewardCatalogState,
  (state: fromRewardCatalogListResponseState.RewardCatalogState) => state.selected
);

export const selectRewardCatalogHistory = createSelector(
  selectRewardCatalogState,
  (state: fromRewardCatalogListResponseState.RewardCatalogState) => state.auditLogs
);
