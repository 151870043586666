import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import { SearchLocationCriteria } from '../models';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';

@Injectable()
export class StockLocationsService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, protected readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.stocksLocation;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  location(searchCriteria: SearchLocationCriteria): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STOCK_LOCATION,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(searchCriteria, true);
    const url = this.getUrl();

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }
}
