import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BaseSearchComponent } from '../../../../base/base-search.component';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ShelfDetailRequest, ShelfInventoryItems } from '../../../../shared/models';
import { ConfirmModal } from '../../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import {
  ShelfSelectInventoryList,
  ShelfSelectInventorySearchCriteria
} from '../../../../shared/models/shelf-inventory.model';
import {
  OrderInventoryRequestGenerateShelfItemsRequestAction,
  OrderInventoryRequestShelfSelectionResetAction
} from '../../../../shared/store/actions/order-inventory-request.actions';
import { OrderInventorySelectItemOrderReset } from '../../../../shared/store/actions/order-select-item-inventory-order.actions';
import { ShelfInventoryDetailsResponseAction } from '../../../../shared/store/actions/shelf-details.actions';
import { ShelfSelectInventoryListRequest } from '../../../../shared/store/actions/shelf-select-inventory.actions';
import { ShelfSelectInventoryState } from '../../../../shared/store/reducers/shelf-select-inventory.reducers';
import { selectAllShelfDetails } from '../../../../shared/store/selectors/shelf-details.selectors';
import {
  selectAllShelfSelectInventoryList,
  selectShelfInventoryList
} from '../../../../shared/store/selectors/shelf-select-inventory.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-select-shelf-inventory-modal',
  templateUrl: './select-shelf-inventory-modal.component.html',
  styleUrls: ['./select-shelf-inventory-modal.component.scss']
})
export class SelectShelfInventoryModalComponent
  extends BaseSearchComponent<ShelfSelectInventorySearchCriteria, ShelfSelectInventoryList, ShelfSelectInventoryState>
  implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    storeNo: string;
  };

  public localStore: Observable<any>;
  public shelfListForm: UntypedFormGroup;
  public shelfSelected: ShelfDetailRequest[] = [];
  public currentSelected: ShelfDetailRequest[] = [];
  public submitted = false;

  public shelfList$: Observable<ShelfInventoryItems[]>;
  public shelfDetails$: Observable<ShelfDetailRequest[]>;

  constructor(
    private readonly fb: UntypedFormBuilder,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly element: ElementRef
  ) {
    super(store, modalService, selectAllShelfSelectInventoryList, selectShelfInventoryList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.createForm();
    this.initialShelfData();
  }

  doAfterVersionAlertModal() {}

  doDestroy() {
    this.store.dispatch(new OrderInventoryRequestShelfSelectionResetAction());
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });

    this.shelfListForm = this.fb.group({
      shelfItems: this.fb.array([])
    });
  }

  initialShelfData() {
    this.localStore
      .pipe(
        select(selectAllShelfSelectInventoryList),
        filter(response => Boolean(response))
      )
      .subscribe(shelfList => {
        if (shelfList) {
          this.formShelfItems.clear();

          shelfList.forEach(item => {
            if (item.options && item.options.length > 0) {
              this.formShelfItems.push(this.createShelfForm(item));
            }
          });
        }
      });

    this.localStore
      .pipe(
        select(selectAllShelfDetails),
        filter(response => Boolean(response))
      )
      .subscribe(shelfSelected => {
        this.shelfSelected = [...shelfSelected];
        this.currentSelected = [...shelfSelected];
      });
  }

  createShelfForm(shelfListItem: ShelfInventoryItems): UntypedFormGroup {
    const selected = this.shelfSelected.find(x => x.shelfTypeCode === shelfListItem.shelfType);
    const selectedShelfCode = selected && selected.shelfCode ? selected.shelfCode : null;
    const selectedShelfOption = selected && selected.shelfOption ? selected.shelfOption : null;
    const isRequired =
      !!selected && shelfListItem.options && shelfListItem.options.length > 1 ? Validators.required : null;

    if (shelfListItem) {
      return this.fb.group({
        shelfTypeCode: shelfListItem.shelfType,
        shelfType: shelfListItem.shelfName,
        shelfOptions: [shelfListItem.options],
        shelfCode: [{ value: selectedShelfCode, disabled: !!!selected }, isRequired],
        shelfOption: [{ value: selectedShelfOption, disabled: !!!selected }],
        selected: !!selected
      });
    }

    return null;
  }

  getShelfOption(index: number) {
    return this.formShelfItems.at(index) && this.formShelfItems.at(index).get('shelfOptions').value;
  }

  isSelectedShelf(item: AbstractControl) {
    return item.get('selected').value ? item.get('selected').value : false;
  }

  selectShelf(index: number) {
    const form = this.formShelfItems.at(index);
    const selected = form.get('selected').value || false;

    form.get('selected').setValue(!selected);
    form.get('selected').updateValueAndValidity();

    if (!selected) {
      if (this.getShelfOption(index) && this.getShelfOption(index).length === 1) {
        form.get('shelfCode').enable();
        form.get('shelfCode').setValue(this.getShelfOption(index)[0].shelfCode);
        form.get('shelfOption').setValue(this.getShelfOption(index)[0].shelfName);
      } else {
        form.get('shelfCode').enable();
        form.get('shelfCode').setValidators(Validators.required);
        form.get('shelfCode').updateValueAndValidity();
      }

      form.updateValueAndValidity();
      this.shelfSelected.push(this.prepareShelfSelectedData(form.value));
    } else {
      const selectedIndex = this.shelfSelected.findIndex(
        item => item.shelfTypeCode === form.get('shelfTypeCode').value
      );

      if (selectedIndex > -1) {
        this.shelfSelected.splice(selectedIndex, 1);
      }

      form.get('shelfOption').setValue(null);

      form.get('shelfCode').setValue(null);
      form.get('shelfCode').disable();
      form.get('shelfCode').clearValidators();
      form.get('shelfCode').updateValueAndValidity();

      form.updateValueAndValidity();
    }
  }

  onChangeSelectShelfOption(index: number) {
    const formValue = this.formShelfItems.at(index).value;

    const selectedIndex = this.shelfSelected.findIndex(item => item.shelfTypeCode === formValue.shelfTypeCode);
    if (selectedIndex > -1) {
      this.shelfSelected[selectedIndex] = this.prepareShelfSelectedData(formValue);
    }
  }

  onBack() {
    if (this.shelfSelected.length !== this.currentSelected.length) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onGenerateShelfInventory() {
    this.submitted = true;

    const isSelectShelfOptionError = this.shelfSelected.find(item => !item.shelfCode);
    if (isSelectShelfOptionError) {
      this.alertModal(`Please select shelf option of shelf type "${isSelectShelfOptionError.shelfType}".`);
      this.scrollToFirstInvalidControl();

      return;
    }

    if (this.shelfSelected.length > 0) {
      const selectedShelfNo = this.shelfSelected.map(item => item.shelfNo);
      const storeNo = this.data.storeNo;

      this.store.dispatch(new ShelfInventoryDetailsResponseAction(this.shelfSelected));

      const result = { response: true, errorResponse: null };
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: result });

      this.store.dispatch(new OrderInventorySelectItemOrderReset());
      this.store.dispatch(
        new OrderInventoryRequestGenerateShelfItemsRequestAction({
          shelfNo: selectedShelfNo.toString(),
          storeNo
        })
      );
    }
  }

  prepareShelfRequestData(formData): ShelfDetailRequest[] {
    return formData.shelfItems
      .filter(item => item.selected)
      .map(item => {
        return this.prepareShelfSelectedData(item);
      });
  }

  prepareShelfSelectedData(item): ShelfDetailRequest {
    const shelfOptionSelected = item.shelfCode ? item.shelfOptions.find(x => x.shelfCode === item.shelfCode) : '';

    return {
      shelfTypeCode: item.shelfTypeCode,
      shelfType: item.shelfType,
      shelfCode: item.shelfCode,
      shelfNo: shelfOptionSelected.shelfNo || null,
      shelfOption: shelfOptionSelected.shelfName || null
    } as ShelfDetailRequest;
  }

  alertModal(message: string) {
    const initialState = {
      title: 'Failed',
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  disableTableScroll(toDisable: boolean) {
    const el: HTMLInputElement = this.element.nativeElement.querySelector('.table-scroll');
    if (toDisable) {
      el.setAttribute('style', 'overflow-y: hidden');
    } else {
      el.removeAttribute('style');
    }
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.element.nativeElement.querySelector('.error-row');
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  goToView() {
    // intentionally empty
  }

  onAdvanceSubmit() {
    // intentionally empty
  }

  prepareSearchCriteriaTags() {
    // intentionally empty
  }

  search(criteriaObj) {
    this.store.dispatch(new ShelfSelectInventoryListRequest(criteriaObj));
  }

  clearSearchText() {
    this.clearSearch();
  }

  clearLastKeyUpSearchText() {
    const formValue = this.searchForm.value;
    if (formValue && formValue.searchCriteria.length > 0) {
      return;
    }

    this.clearSearch();
  }

  clearSearch() {
    this.setFirstPage();

    this.searchForm.get('searchCriteria').setValue(null);

    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: null,
      page: 0
    };

    this.search(this.criteriaObject);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      status: 'ACTIVE',
      page: 0,
      size: 20,
      sortBy: 'shelfName',
      sortOrder: 'asc'
    };
  }

  setRouteTab() {
    // intentionally empty
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  getItemIndex(index: number) {
    return index + (this.currentPage - 1) * this.pageSize;
  }

  getItemNo(index: number) {
    return this.getItemIndex(index) + 1;
  }

  get form(): UntypedFormGroup {
    return this.shelfListForm;
  }

  get formShelfItems(): UntypedFormArray {
    return this.shelfListForm.get('shelfItems') as UntypedFormArray;
  }

  get isSearch(): boolean {
    return !!this.criteriaObject.searchCriteria;
  }
}
