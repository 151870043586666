import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AwaitingAllocationForGroupOrderReportCriteria } from '@shared/models/report.model';
import { BaseService } from '@shared/services/base.service';
import { metricsReporting } from '@shared/utils/metrics-util';

@Injectable({
  providedIn: 'root'
})
export class AwaitingAllocationForGroupOrderService extends BaseService {
  constructor(private http: HttpClient) {
    super();
    this.envService = this.env.services.exportReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  exportAwaitingAllocation(reportCriteria: AwaitingAllocationForGroupOrderReportCriteria) {
    const url = this.getFullUrl(this.envService.awaitingAllocationExport, null, true);
    const metrics = metricsReporting([this.envService.awaitingAllocationExport]);

    return this.http.post<any>(url, reportCriteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
