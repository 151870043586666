export enum DataInsightFilterTypeEnum {
  SELECT = 'SELECT',
  SELECT_FILTER_VALUE = 'SELECT_FILTER_VALUE',
  SELECT_FILTER_LIST = 'SELECT_FILTER_LIST',
  SELECT_FILTER_SEARCH = 'SELECT_FILTER_SEARCH'
}

export enum DataInsightDataTypeEnum {
  TEXT = 'TEXT',
  INTEGER = 'INTEGER',
  FLOAT = 'FLOAT',
  DATE = 'DATE'
}

export enum DataInsightManageFolderTypeEnum {
  SELECT_FOLDER = 'SELECT_FOLDER',
  ADD_FOLDER = 'ADD_FOLDER'
}

export enum DataInsightSaveTypeEnum {
  SAVE = 'SAVE',
  SAVE_NEW = 'SAVE_NEW'
}

export enum DataInsightDashboardEnum {
  CUSTOMER = 'customer_dashboard'
}
