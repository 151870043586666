import { Action } from '@ngrx/store';

import {
  ClaimApproveRequest,
  ClaimRejectRequest,
  ClaimRequest,
  ClaimRequestPagination,
  ClaimRequestSearchCriteria,
  ClaimRequestSubmit
} from '../../model/claim-request.model';

export enum ClaimRequestActionTypes {
  CLAIM_REQUEST_VIEW_REQUESTED = '[Claim Request View Page] Claim Request View Requested',
  CLAIM_REQUEST_DRAFT_REQUESTED = '[Claim Request View Page] Claim Request DRAFT Requested',
  CLAIM_REQUEST_SUBMIT_REQUESTED = '[Claim Request View Page] Claim Request Submit Requested',
  CLAIM_REQUEST_VIEW_LOADED = '[Claim Request API] Claim Request View Loaded',
  CLAIM_REQUEST_APPROVE = '[Claim Request View Page] Claim Request Approve Requested',
  CLAIM_REQUEST_REJECT = '[Claim Request View Page] Claim Request Reject Requested',
  CLAIM_REQUEST_LIST_REQUEST = '[Claim Request List Page] Claim Request List Request',
  CLAIM_REQUEST_LIST_RESPONSE = '[Claim Request List API] Claim Request List Response',
  CLAIM_REQUEST_RESET = '[Claim Request View Page] Claim Request Reset',
  CLAIM_REQUEST_VERSION_ERROR = '[Claim Request View Page] Claim Request Version Error'
}

export class ClaimRequestViewRequested implements Action {
  readonly type = ClaimRequestActionTypes.CLAIM_REQUEST_VIEW_REQUESTED;

  constructor(public payload: string) {}
}

export class ClaimRequestDraftRequested implements Action {
  readonly type = ClaimRequestActionTypes.CLAIM_REQUEST_DRAFT_REQUESTED;

  constructor(public payload: string) {}
}

export class ClaimRequestSubmitRequested implements Action {
  readonly type = ClaimRequestActionTypes.CLAIM_REQUEST_SUBMIT_REQUESTED;

  constructor(public payload: ClaimRequestSubmit) {}
}

export class ClaimRequestViewLoaded implements Action {
  readonly type = ClaimRequestActionTypes.CLAIM_REQUEST_VIEW_LOADED;

  constructor(public payload: ClaimRequest) {}
}

export class ClaimRequestApprove implements Action {
  readonly type = ClaimRequestActionTypes.CLAIM_REQUEST_APPROVE;

  constructor(public payload: ClaimApproveRequest) {}
}

export class ClaimRequestReject implements Action {
  readonly type = ClaimRequestActionTypes.CLAIM_REQUEST_REJECT;

  constructor(public payload: ClaimRejectRequest) {}
}

export class ClaimRequestListRequest implements Action {
  readonly type = ClaimRequestActionTypes.CLAIM_REQUEST_LIST_REQUEST;

  constructor(public payload: ClaimRequestSearchCriteria) {}
}

export class ClaimRequestListResponse implements Action {
  readonly type = ClaimRequestActionTypes.CLAIM_REQUEST_LIST_RESPONSE;

  constructor(public payload: ClaimRequestPagination) {}
}

export class ClaimRequestReset implements Action {
  readonly type = ClaimRequestActionTypes.CLAIM_REQUEST_RESET;

  constructor() {}
}

export class ClaimRequestVersionError implements Action {
  readonly type = ClaimRequestActionTypes.CLAIM_REQUEST_VERSION_ERROR;

  constructor(public payload: boolean) {}
}

export type ClaimRequestActions =
  | ClaimRequestViewRequested
  | ClaimRequestDraftRequested
  | ClaimRequestViewLoaded
  | ClaimRequestApprove
  | ClaimRequestReject
  | ClaimRequestListRequest
  | ClaimRequestListResponse
  | ClaimRequestReset
  | ClaimRequestSubmitRequested
  | ClaimRequestVersionError;
