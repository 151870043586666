import { Action } from '@ngrx/store';

export enum HideModalActionTypes {
  HIDE_ALL_MODAL = '[Hide Modal] Hide all Modal'
}

export class HideAllModalAction implements Action {
  readonly type = HideModalActionTypes.HIDE_ALL_MODAL;

  constructor(public payload: boolean) {}
}

export type HideModalActions = HideAllModalAction;
