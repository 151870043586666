import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { PermissionsService } from '@shared/services/permissions.service';
import { RoleService } from '@shared/services/role.service';
import {
  LayoutActionLoadError,
  LayoutActionSaveSuccess,
  LayoutActionVersionError
} from '@shared/store/actions/layout.action';

import {
  RoleActionTypes,
  RoleAllRequestAction,
  RoleAllResponseAction,
  RoleByIdRequestAction,
  RoleByIdResponseAction,
  RoleCreatedRequestAction,
  RoleDeleteRequestAction,
  RoleEditRequestAction,
  RoleListRequestAction,
  RoleListResponseAction,
  RoleMasterListRequest,
  RoleMasterListResponse,
  RoleResponseErrorAction,
  UserByRoleRequestAction,
  UserByRoleResponseAction
} from './role.actions';

@Injectable()
export class RoleEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly roleService: RoleService,
    private readonly permissionsService: PermissionsService,
    private readonly logger: NGXLogger,
    protected spinner: NgxSpinnerService
  ) {}

  searchRoleList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<RoleListRequestAction>(RoleActionTypes.ROLE_LIST_REQUEST_ACTION),
      map(action => {
        this.logger.debug(`@Effect ${RoleActionTypes.ROLE_LIST_REQUEST_ACTION}: ` + this.stringify(action.payload));
        return action.payload;
      }),
      switchMap(payload => {
        return this.roleService.searchByCriteriaRequest(payload).pipe(
          map(requests => {
            let i = 1;
            requests.content.forEach(e => {
              e.id = i.toString();
              i++;
            });
            return new RoleListResponseAction(requests);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  getRoleMasterList$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<RoleMasterListRequest>(RoleActionTypes.ROLE_MASTER_LIST_REQUEST),
      tap(action =>
        this.logger.debug(`@Effect ${RoleActionTypes.ROLE_MASTER_LIST_REQUEST}: ` + JSON.stringify(action))
      ),
      switchMap(() =>
        this.permissionsService.getPermission().pipe(
          map(items => new RoleMasterListResponse({ permissions: items })),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  createRole$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<RoleCreatedRequestAction>(RoleActionTypes.ROLE_CREATED_REQUEST),
      tap(action => this.logger.debug(`@Effect ${RoleActionTypes.ROLE_CREATED_REQUEST}: ` + JSON.stringify(action))),
      switchMap(action => {
        return this.roleService.save(action.payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The role has been created.'
              })
          ),
          catchError(err => this.responseError(err, action))
        );
      })
    );
  });

  editRole$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<RoleEditRequestAction>(RoleActionTypes.ROLE_EDIT_REQUEST),
      tap(action => this.logger.debug(`@Effect ${RoleActionTypes.ROLE_EDIT_REQUEST}: ` + JSON.stringify(action))),
      switchMap(action => {
        return this.roleService.edit(action.payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The role has been updated.'
              })
          ),
          catchError(err => this.responseError(err, action))
        );
      })
    );
  });

  getRoleById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<RoleByIdRequestAction>(RoleActionTypes.ROLE_GET_BY_ID_REQUEST),
      tap(action => {
        this.logger.debug('@Effect Role By Id Load: ' + JSON.stringify(action.payload));
      }),
      mergeMap(action =>
        this.roleService.getRoleById(action.payload).pipe(
          map(roleView => {
            return new RoleByIdResponseAction(roleView);
          }),
          catchError(err => of(new LayoutActionLoadError(err)))
        )
      )
    );
  });

  getRoleAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<RoleAllRequestAction>(RoleActionTypes.ROLE_ALL_REQUEST_ACTION),
      tap(action => {
        this.logger.debug('@Effect Role All Load: ' + JSON.stringify(action.type));
      }),
      mergeMap(() =>
        this.roleService.getRoleAll().pipe(
          map(roleView => {
            return new RoleAllResponseAction(roleView);
          }),
          catchError(err => of(new LayoutActionLoadError(err)))
        )
      )
    );
  });

  getUserByRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserByRoleRequestAction>(RoleActionTypes.USER_BY_ROLE_REQUEST),
      map(action => {
        this.logger.debug(`@Effect ${RoleActionTypes.USER_BY_ROLE_REQUEST}: ` + this.stringify(action.payload));
        return action.payload;
      }),
      switchMap(payload => {
        return this.roleService.getUserByRole(payload.criteria).pipe(
          map(requests => {
            return new UserByRoleResponseAction(requests);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  deleteRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<RoleDeleteRequestAction>(RoleActionTypes.ROLE_DELETE_REQUEST),
      tap(action => this.logger.debug('@Effect Delete role: ' + this.stringify(action.payload))),
      switchMap(action => {
        return this.roleService.deleteRole(action.payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'Role has been deleted.'
              })
          ),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  private responseError(err, action) {
    return err && err.error && ['02011', '02012'].includes(err.error.code)
      ? of(
          new RoleResponseErrorAction({
            ...err.error,
            duplicateName: action.payload.name
          })
        )
      : err && err.error && err.error.code === '00001'
      ? of(new LayoutActionVersionError(true))
      : of(new LayoutActionLoadError(err));
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
