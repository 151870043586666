<form id="search-form-moment" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
  <div class="flex-item d-md-flex justify-content-between">
    <div class="d-md-flex flex-fill justify-content-end">
      <div class="filter-search-box flex-item form-group ml-md-2">
        <div class="search-box position-relative">
          <input
            type="text"
            formControlName="searchCriteria"
            class="form-control searchCriteria"
            placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
            required
            maxlength="50"
            (keyup.backspace)="clearLastKeyUpSearchText($event)"
          />
          <em
            class="icon-close-mini"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
          <button class="btn-search" type="submit">
            <em class="icon-search"></em>
          </button>
        </div>
      </div>
      <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
        <button
          class="btn advance-filter-button btn-advance-filter"
          [ngClass]="{ active: isShowAdvanceSearch }"
          type="button"
          (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
        >
          <em class="icon-filter"></em>
          {{ 'ADVANCED_FILTERS' | translate }}
        </button>
        <div
          class="box-dropdown"
          [ngClass]="{ open: isShowAdvanceSearch }"
          (appClickOutside)="onClickedOutside($event)"
          [exclude]="['.btn-advance-filter', '.is-highlighted']"
        >
          <div class="box-dropdown-list advance-filter-dropdown">
            <div class="box-dropdown-body px-4">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-2">
                      Movement Date
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ 'FROM' | translate }}</label>
                      <input
                        id="movementDateFrom"
                        formControlName="movementDateFrom"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        autocomplete="off"
                        class="form-control calendar"
                        bsDatepicker
                        [bsConfig]="bsDateConfig"
                        [maxDate]="movementMaxDate"
                        readonly
                        (bsValueChange)="onChangeMovementDateFrom($event)"
                      />
                      <label for="movementDateFrom" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ 'TO' | translate }}</label>
                      <input
                        id="movementDateTo"
                        formControlName="movementDateTo"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        autocomplete="off"
                        class="form-control calendar"
                        bsDatepicker
                        [bsConfig]="bsDateConfig"
                        [minDate]="movementMinDate"
                        readonly
                        (bsValueChange)="onChangeMovementDateTo($event)"
                      />
                      <label for="movementDateTo" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="mb-2">
                        Movement Type
                      </div>
                      <ng-select
                        [items]="stockMovementType"
                        placeholder="Please select..."
                        [clearable]="true"
                        [multiple]="true"
                        formControlName="movementType"
                        bindLabel="label"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="stockLocation === stockLocationEnum.ALL_STORES">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="mb-2">
                        Location
                      </div>
                      <ng-select
                        [items]="locationList$ | async"
                        placeholder="Please select..."
                        [loading]="locationSearchLoading"
                        [typeahead]="locationSearchInput$"
                        [searchable]="true"
                        [clearable]="true"
                        [multiple]="true"
                        bindLabel="label"
                        formControlName="location"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="advance-filter-footer text-center">
              <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                Cancel
              </button>
              <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                {{ 'APPLY' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div
  class="top-search-criteria space-search-criteria"
  *ngIf="movementDateTag || selectedlocationTag || movementTypeTag"
>
  <div class="search-criteria" *ngIf="selectedlocationTag">
    <a class="remove-criteria" (click)="clearLocationFilter()">
      <em class="icon-close"></em>
    </a>
    <span>{{ selectedlocationStringTag }}: {{ selectedlocationTag }}</span>
  </div>
  <div class="search-criteria" *ngIf="movementTypeTag">
    <a class="remove-criteria" (click)="clearMovementTypeFilter()">
      <em class="icon-close"></em>
    </a>
    <span>{{ movementTypeStringTag }}: {{ movementTypeTag }}</span>
  </div>
  <div class="search-criteria" *ngIf="movementDateTag">
    <a class="remove-criteria" (click)="clearMovementDateFilter()">
      <em class="icon-close"></em>
    </a>
    <span>{{ movementDateStringTag }}: {{ movementDateTag }}</span>
  </div>
  <div class="d-inline-block">
    <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
  </div>
</div>
<ng-container *ngIf="(listMovementState$ | async).totalElements === 0">
  <div class="no-result-container text-center">
    <div class="d-flex justify-content-center">
      <img width="240" height="240" src="/assets/images/not-found.svg" alt="not found" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="(listMovementState$ | async).totalElements > 0">
  <div class="mt-3 table-responsive">
    <table
      id="transactions"
      class="table table-striped table-bordered table-hover border-none"
      aria-label="transactions"
    >
      <thead>
        <tr>
          <th class="text-center w-10" scope="col">Movement Date/Time</th>
          <th class="text-center w-25" scope="col">Document No.</th>
          <th class="text-center  w-10" *ngIf="stockLocation === stockLocationEnum.ALL_STORES" scope="col">
            Location
          </th>
          <th class="text-center  w-10" scope="col">Movement Type</th>
          <th class="text-center  w-10" scope="col">Stock on Hand</th>
          <th class="text-center  w-10" scope="col">Adjust</th>
          <th class="text-center  w-10" scope="col">Total Stock</th>
          <th class="text-center w-15" scope="col">Created By</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of resultMovementList$ | async; index as i">
          <td class="text-center">{{ result.movementDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}</td>
          <td>
            <ng-container *ngIf="isShowQuantity(result.type)">
              {{ result.refNo }}
              <label class="detail" *ngIf="result.type !== 'INITIAL_STOCK'">
                Barcode: {{ result.barcode }} |
                {{ result.quantity | numberFormatDisplay: 0 }}
                {{ result.unit }} (Unit Factor = {{ result.unitFactor }})
              </label>
            </ng-container>

            <ng-container *ngIf="!isShowQuantity(result.type)">
              {{ result.refNo | dashDisplay: '-' }}
              <label class="detail">
                Type: {{ result.adjustType ? ('ADJUST_TYPE.' + result.adjustType | translate) : '-' }} | Ref No.:
                {{ result.docNo | dashDisplay: '-' }}
              </label>
            </ng-container>
          </td>
          <td *ngIf="stockLocation === stockLocationEnum.ALL_STORES">{{ result.location }}</td>
          <td>{{ 'STOCK.MOVEMENT_TYPE.' + result.type | translate }}</td>
          <td class="text-center">{{ result.originalOnHandStock | numberFormatDisplay: 0 }}</td>
          <td class="text-center">{{ result.adjust | stockQuantity: result.type | numberFormatDisplay: 0 }}</td>
          <td class="text-center">{{ result.currentOnHandStock | numberFormatDisplay: 0 }}</td>
          <td>{{ result.createdByName }}</td>
        </tr>
      </tbody>
    </table>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listMovementState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </div>
</ng-container>
