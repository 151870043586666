<div class="table-scroll">
  <div class="error-label" *ngIf="isRequestTypeEdit(type) && listOfChange?.storeConditions">
    <app-tag-updated updated="Store Condition"></app-tag-updated>
  </div>
  <table id="data-table" class="table table-striped table-hover w-100" aria-label="data-table">
    <thead>
      <tr>
        <th class="text-center w-90" scope="col">Condition</th>
        <th class="text-center w-10" scope="col">Not Allow</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let condition of storeConditionValuesWithFormControl; index as i">
        <td class="p-1 w-90">
          <div class="column">{{ condition.nameEn }}</div>
        </td>
        <td class="p-1 w-10 text-center align-middle">
          <input
            type="checkbox"
            class="form-check-input-label checkbox-modal"
            [id]="'storeNotAllow' + condition.nameEn"
            [attr.data-id]="'storeNotAllow' + condition.nameEn"
            [formControl]="condition.notAllow"
            (change)="onChangeStoreConditionFormControl$.next()"
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>
