import { Component } from '@angular/core';

@Component({
  selector: 'app-login-left',
  templateUrl: './login-left.component.html',
  styleUrls: ['./login-left.component.scss']
})
export class LoginLeftComponent {
  constructor() {
    // intentionally empty
  }
}
