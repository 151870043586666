export class WarehouseListResponse {
  content: WarehouseListContent[];
}

export class WarehouseListContent {
  id: string;
  code: string;
  name: string;
  type: string;
  wmsCode: string;
  warehouseNameDisplay: string;
  defaultFirstLot: boolean;
  operator: string;
  orderFlow: string[];
  crossDockSupplier: string[];

  constructor(data: WarehouseListContent) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.type = data.type;
    this.wmsCode = data.wmsCode;
    this.warehouseNameDisplay = data.warehouseNameDisplay;
    this.defaultFirstLot = data.defaultFirstLot;
    this.operator = data.operator;
    this.orderFlow = data.orderFlow;
    this.crossDockSupplier = data.crossDockSupplier;
  }

  // get warehouseNameDisplay(): string {
  //   return `${this.code}:${this.name}`;
  // }
}

export class SelectWarehouseList {
  id: string;
  code: string;
  wmsCode: string;
  name: string;
  locationDisplay: string;
}

export class SelectWarehousePagingList {
  masterDtoList: SelectWarehouseList[];
  totalElement: number;
}

export class WarehousesStoresSearchCriteria {
  isPaging?: boolean;
  searchCriteria?: string;
  isWarehouse?: boolean;
  module?: string;
  page?: number;
  size?: number;
}

export class BillToBase {
  code: string;
  nameEn: string;
  nameTh: string;
  contactName: string;
  contactNumber: string;
  address: string;
  fullNameTh: string;
  fullNameEn: string;
  wmsCode: string;
}

export class OfficeWarehouse extends BillToBase {
  billTo: BillToBase;
}

export class WarehouseMasterData {
  offices: OfficeWarehouse[];
  warehouses: OfficeWarehouse[];
}

export class BillingMasterData {
  billings: BillToBase[];
}
