import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { CriteriaUtil } from '@shared/utils/criteria-util';

import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { DefaultStatusEnum } from '../../../shared/enum/default-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  AuditLog,
  PortalModule,
  PromotionRequest,
  PromotionRequestPageModes,
  PromotionSearchCriteria,
  RouteLinkTab,
  TaskModuleUrl
} from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import {
  PromotionRequestCancelRequested,
  PromotionRequestDeleteRequest,
  PromotionRequestListRequestAction
} from '../../../shared/store/actions/promotion.action';
import { PromotionRequestState } from '../../../shared/store/reducers/promotion-request.reducers';
import {
  selectAllPromotionRequestList,
  selectPromotionRequestList,
  selectPromotionRequestListCriteria
} from '../../../shared/store/selectors/promotion-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ModuleUtil } from '../../../shared/utils/module-util';
import { PromotionRequestComponent } from '../promotion-request/promotion-request.component';

@Component({
  selector: 'app-promotion-request-list',
  templateUrl: './promotion-request-list.component.html',
  styleUrls: ['./promotion-request-list.component.scss']
})
export class PromotionRequestListComponent extends BaseSearchComponent<
  PromotionSearchCriteria,
  PromotionRequest,
  PromotionRequestState
> {
  public minDate: Date;
  public maxDate: Date;
  public expireMaxDate: Date;
  public expireMinDate: Date;
  public createdMaxDate: Date;
  public createdMinDate: Date;
  public validMaxDate: Date;
  public validMinDate: Date;

  public createdDateTag: string;
  public createdDateStringTag: string;
  public effectiveDateTag: string;
  public effectiveDateStringTag: string;
  public expireDateTag: string;
  public expireDateStringTag: string;
  public validDateTag: string;
  public validDateStringTag: string;

  public statusList = filterDropdown.promotionRequestStatusFilter;

  private bsModalRef: BsModalRef;
  private localStore: Observable<any>;

  listRoute: Array<RouteLinkTab>;
  dateFormat = environment.dateFormat;
  hasViewPromotionPermission: boolean;
  hasEditPromotionPermission: boolean;
  hasApprovePromotionPermission: boolean;
  notifyParent: any;
  hasSearchCriteria: boolean;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: UntypedFormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService
  ) {
    super(store, modalService, selectAllPromotionRequestList, selectPromotionRequestList);
    super.subscribeForSaveSuccess();
    this.hasViewPromotionPermission = this.authGuardService.checkPermission(['promotion_v']);
    this.hasEditPromotionPermission = this.authGuardService.checkPermission(['promotion_m']);
    this.hasApprovePromotionPermission = this.authGuardService.checkPermission(['promotion_app']);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectPromotionRequestListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.effectiveDateFrom &&
      !formValue.effectiveDateTo &&
      !formValue.expireDateFrom &&
      !formValue.expireDateTo &&
      !formValue.createdDateFrom &&
      !formValue.createdDateTo &&
      !formValue.validDateFrom &&
      !formValue.validDateTo
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      effectiveDateFrom: dateToStringCriteria(formValue.effectiveDateFrom),
      effectiveDateTo: dateToStringCriteria(formValue.effectiveDateTo, false),
      expireDateFrom: dateToStringCriteria(formValue.expireDateFrom),
      expireDateTo: dateToStringCriteria(formValue.expireDateTo, false),
      createdDateFrom: dateToStringCriteria(formValue.createdDateFrom),
      createdDateTo: dateToStringCriteria(formValue.createdDateTo, false),
      validDateFrom: dateToStringCriteria(formValue.validDateFrom),
      validDateTo: dateToStringCriteria(formValue.validDateTo, false),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.effectiveDateTag = null;
    this.effectiveDateStringTag = null;
    this.expireDateTag = null;
    this.expireDateStringTag = null;
    this.createdDateTag = null;
    this.createdDateStringTag = null;
    this.validDateStringTag = null;
    this.validDateTag = null;

    const effectiveDateFrom = dateStringToTagCriteria(this.criteriaObject.effectiveDateFrom);
    const effectiveDateTo = dateStringToTagCriteria(this.criteriaObject.effectiveDateTo);
    const effectiveDate = generateDateStringTag({
      dateName: 'Effective Date',
      dateFrom: effectiveDateFrom,
      dateTo: effectiveDateTo
    });

    this.effectiveDateStringTag = effectiveDate.dateStringTag;
    this.effectiveDateTag = effectiveDate.dateTag;

    const expireDateFrom = dateStringToTagCriteria(this.criteriaObject.expireDateFrom);
    const expireDateTo = dateStringToTagCriteria(this.criteriaObject.expireDateTo);
    const expireDate = generateDateStringTag({
      dateName: 'Expire Date',
      dateFrom: expireDateFrom,
      dateTo: expireDateTo
    });

    this.expireDateStringTag = expireDate.dateStringTag;
    this.expireDateTag = expireDate.dateTag;

    const validDateFrom = dateStringToTagCriteria(this.criteriaObject.validDateFrom);
    const validDateTo = dateStringToTagCriteria(this.criteriaObject.validDateTo);
    const validDate = generateDateStringTag({
      dateName: 'Valid Date',
      dateFrom: validDateFrom,
      dateTo: validDateTo
    });

    this.validDateStringTag = validDate.dateStringTag;
    this.validDateTag = validDate.dateTag;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'Created Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.createdDateStringTag = createdDate.dateStringTag;
    this.createdDateTag = createdDate.dateTag;
  }

  search(criteriaObj: PromotionSearchCriteria) {
    this.prepareSearchCriteriaTags();
    this.hasSearchCriteria = CriteriaUtil.hasCriteria(this.criteriaObject);
    this.store.dispatch(new PromotionRequestListRequestAction(criteriaObj));
  }

  goToView(promotionItem) {
    const initialState = ModuleUtil.InitialState(
      promotionItem,
      PromotionRequestComponent,
      PromotionRequestPageModes.REQUEST_VIEW,
      'View Promotion Request',
      TaskModuleUrl.PROMOTION_REQUEST,
      PortalModule.PROMOTION
    );
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  goToEdit(promotionItem) {
    if (!this.hasEditPromotionPermission) {
      return;
    }

    const initialState = ModuleUtil.InitialState(
      promotionItem,
      PromotionRequestComponent,
      PromotionRequestPageModes.REQUEST_EDIT,
      'Edit Promotion Request',
      TaskModuleUrl.PROMOTION_REQUEST,
      PortalModule.PROMOTION
    );

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  showHistory(requestNo: string, auditLogs: AuditLog[]) {
    const initialState = {
      title: 'History',
      action: HistoryType.REQUEST,
      historyHeader: `Request No. ${requestNo}`,
      historyType: HistoryType.PROMOTION,
      auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  showConfirmDeleteModal(requestId): void {
    if (!this.hasEditPromotionPermission) {
      return;
    }

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Delete',
        message: 'Are you sure you want to delete this request?',
        okText: 'Yes, delete'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PromotionRequestDeleteRequest({
              requestId
            })
          );
        }
      });
  }

  showConfirmCancelModal(item): void {
    if (!this.hasEditPromotionPermission) {
      return;
    }

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel promotion request number <strong>&quot;${item.requestNo}&quot;</strong>?`,
        okText: 'Yes, cancel',
        label: 'Comment',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PromotionRequestCancelRequested({
              id: item.requestNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  setInitialValue() {
    this.pageSize = 20;
    const todayDate = new Date();

    this.maxDate = todayDate;
    this.expireMaxDate = todayDate;
    this.createdMaxDate = todayDate;
    this.validMaxDate = todayDate;

    this.maxDate.setDate(todayDate.getDate() + 365);
    this.expireMaxDate.setDate(todayDate.getDate() + 365);
    this.createdMaxDate.setDate(todayDate.getDate() + 365);
    this.validMaxDate.setDate(todayDate.getDate() + 365);
  }

  doDestroy() {
    // intentionally empty
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      effectiveDateFrom: [null],
      effectiveDateTo: [null],
      expireDateFrom: [null],
      expireDateTo: [null],
      createdDateFrom: [null],
      createdDateTo: [null],
      validDateFrom: [null],
      validDateTo: [null]
    });
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.search(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  callCreatePromotionModal() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        PromotionRequestComponent,
        {
          title: 'Create Promotion Request',
          mode: PromotionRequestPageModes.REQUEST_CREATE
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  onChangeExpireDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.expireMinDate = new Date(value);
    } else {
      this.expireMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExpireDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.expireMaxDate = new Date(value);
    } else {
      this.expireMaxDate = new Date();
      this.expireMaxDate.setDate(this.expireMaxDate.getDate() + 365);
    }
  }

  onChangeCreatedDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.createdMinDate = new Date(value);
    } else {
      this.createdMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeValidDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.validMinDate = new Date(value);
    } else {
      this.validMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeValidDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.validMaxDate = new Date(value);
    } else {
      this.validMaxDate = new Date();
      this.validMaxDate.setDate(this.validMaxDate.getDate() + 365);
    }
  }

  onChangeCreatedDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.createdMaxDate = new Date(value);
    } else {
      this.createdMaxDate = new Date();
      this.createdMaxDate.setDate(this.createdMaxDate.getDate() + 365);
    }
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  clearFilterEffectiveDate() {
    this.setFirstPage();
    this.searchForm.controls['effectiveDateFrom'].reset();
    this.searchForm.controls['effectiveDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      effectiveDateFrom: null,
      effectiveDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterExpireDate() {
    this.setFirstPage();
    this.searchForm.controls['expireDateFrom'].reset();
    this.searchForm.controls['expireDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      expireDateFrom: null,
      expireDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterValidDate() {
    this.setFirstPage();
    this.searchForm.controls['validDateFrom'].reset();
    this.searchForm.controls['validDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      validDateFrom: null,
      validDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterCreateDate() {
    this.setFirstPage();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.setFirstPage();
    this.searchForm.controls['effectiveDateFrom'].reset();
    this.searchForm.controls['effectiveDateTo'].reset();
    this.searchForm.controls['expireDateFrom'].reset();
    this.searchForm.controls['expireDateTo'].reset();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.searchForm.controls['validDateFrom'].reset();
    this.searchForm.controls['validDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      effectiveDateFrom: null,
      effectiveDateTo: null,
      expireDateFrom: null,
      expireDateTo: null,
      createdDateFrom: null,
      createdDateTo: null,
      validDateFrom: null,
      validDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission(['promotion_m', 'promotion_v']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['promotion_m', 'promotion_app']);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Promotion List', url: '/campaign/promotion-list' });
    }
    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Promotion Request', url: '/campaign/promotion-request-list' });
    }
  }

  getColorStatus(status: string): string {
    return DefaultStatusEnum[status];
  }
}
