import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventDoubleClick]'
})
export class PreventDoubleClickDirective {
  constructor() {
    // intentionally empty
  }

  @HostListener('click', ['$event'])
  onClick($event) {
    if (!$event['detail'] || $event['detail'] === 1) {
      return true;
    } else {
      $event.stopPropagation();
      return false;
    }
  }
}
