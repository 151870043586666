import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, EMPTY, Observable, combineLatest, of } from 'rxjs';
import { filter, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { HistoryComponent } from '@shared/components/history/history.component';
import { StoreContactComponent } from '@shared/components/td-store/store-contact/store-contact.component';
import { StoreLocationComponent } from '@shared/components/td-store/store-location/store-location.component';
import { AuditLog, HistoryType } from '@shared/models/audit-log.model';
import { findObjectDifference } from '@shared/utils/findObjectDifference.util';
import { removeObjectArray } from '@shared/utils/remove-object-array.util';

import { environment as env } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { UpdateOrderPolicyComponent } from '../../../shared/components/td-store/order-policy/update-order-policy/update-order-policy.component';
import { StoreConditionComponent } from '../../../shared/components/td-store/store-condtion/store-condition.component';
import { StoreProfileComponent } from '../../../shared/components/td-store/store-profile/store-profile.component';
import { TDStoreValidatorTypeEnum } from '../../../shared/enum/merchant-validator-type.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { RequestSectionEnum } from '../../../shared/enum/request-section.enum';
import { NewRequestStatusEnum, RequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { TDStoreEnum, TDStorePage } from '../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import {
  ErrorResponse,
  SaleStatusEnum,
  StoreListSearchCriteria,
  StoreRequestTemplate,
  StoreTabs,
  StoreViewResponse
} from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import { StoreRequestService } from '../../../shared/services/store-request.service';
import { StoreService } from '../../../shared/services/store.service';
import { LayoutActionLoadError } from '../../../shared/store/actions/layout.action';
import {
  StoreCreateResetAction,
  StoreCreateSubmitRequestAction
} from '../../../shared/store/actions/store-request.actions';
import { StoreCreateResponseState } from '../../../shared/store/reducers/store-create.reducers';
import { selectStore } from '../../../shared/store/selectors/store-create.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { formatDateStartOfDay } from '../../../shared/utils/date-util';
import { getFileId, getMultipleFileId } from '../../../shared/utils/get-fileId-util';
import { getSelectByPage } from '../../../shared/utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../shared/utils/td-store-workflow-util';
import {
  ResetStoreSelected,
  StoreByIdRequestAction,
  StoreListHistoryRequestAction,
  StoreListHistoryResponseAction,
  StoreLockSaleRequestAction
} from '../store/store.actions';
import { selectStoreListCriteria, selectStoreListHistory } from '../store/store.selectors';
import { TerminateStoreComponent } from '../terminate-store/terminate-store.component';

@Component({
  selector: 'app-view-store',
  templateUrl: './view-store.component.html',
  styleUrls: ['./view-store.component.scss']
})
export class ViewStoreComponent extends BaseComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('storeProfile') storeProfile: StoreProfileComponent;
  @ViewChild('storeLocation') storeLocation: StoreLocationComponent;
  @ViewChild('storeContact') storeContact: StoreContactComponent;
  @ViewChild('storeCondition') storeCondition: StoreConditionComponent;
  @Input() data: { title: string; mode: RequestPageModesEnum; storeNo?: string; merchant?: string };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  @ViewChild('terminateStoreModal', { static: false }) terminateStoreModal: TerminateStoreComponent;

  private localStore: Observable<any>;
  private readonly type: RequestTypeEnum = RequestTypeEnum.EDIT;
  private readonly step: RequestStepEnum = RequestStepEnum.EDIT_PROFILE;
  private readonly page: TDStorePage = TDStorePage.STORE_EDIT;

  public storeForm: UntypedFormGroup;
  public storeView$: Observable<StoreViewResponse>;
  public storeInfo: StoreViewResponse;
  public status: RequestStatusEnum;
  public saleStatus = SaleStatusEnum;
  public submitted: boolean;
  public version: number;
  public requestStatus = NewRequestStatusEnum;
  protected selectedTab: StoreTabs = StoreTabs.STORE_PROFILE;
  protected storeTabList = [
    { label: 'Store Profile', value: StoreTabs.STORE_PROFILE },
    { label: 'Store Device', value: StoreTabs.STORE_DEVICE }
  ];
  protected readonly StoreTabs = StoreTabs;
  // @ts-ignore
  criteriaObject: StoreListSearchCriteria;

  auditLogs$: Observable<AuditLog[]>;

  onPatchValueFinish = new BehaviorSubject<boolean>(false);
  onTriggerEditEffect = new BehaviorSubject<boolean>(false);

  constructor(
    protected fb: UntypedFormBuilder,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    protected storeRequestService: StoreRequestService,
    protected readonly translate: TranslateService,
    protected bsModalService: BsModalService,
    protected cdRef: ChangeDetectorRef,
    protected storeService: StoreService,
    protected authGuardService: AuthGuardService
  ) {
    super(store, modalService, true);
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(new ResetStoreSelected());
    this.store.dispatch(new StoreCreateResetAction());
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.initialSubscription();
    this.store.dispatch(
      new StoreByIdRequestAction({
        merchant: { merchant: this.data.merchant },
        storeNo: { storeNo: this.data.storeNo }
      })
    );

    combineLatest([this.onPatchValueFinish, this.onTriggerEditEffect])
      .pipe(
        filter(([value1, value2]) => value1 && value2),
        take(1)
      )
      .subscribe(() => {
        this.onTriggerEdit();
      });

    this.auditLogs$ = this.localStore.pipe(
      select(selectStoreListHistory),
      switchMap(val => {
        if (val) {
          return of(
            val.map(history => {
              return { ...history, parameters: { deviceNo: history?.deviceNo || '' } };
            })
          );
        }

        return EMPTY;
      })
    );

    this.storeView$ = this.localStore.pipe(select(getSelectByPage(TDStorePage.STORE_EDIT)));
    this.storeView$
      .pipe(
        filter(value => value !== null),
        tap(value => {
          this.version = value.version;
          this.storeInfo = value;
          this.onPatchValueFinish.next(true);
        })
      )
      .subscribe();

    this.initControl();

    if (this.data.mode === RequestPageModesEnum.REQUEST_EDIT) {
      this.onTriggerEditEffect.next(true);
    }
  }

  initialSubscription() {
    this.localStore.pipe(select(selectStoreListCriteria)).subscribe(criteriaObject => {
      this.criteriaObject = criteriaObject;
    });
  }

  get merchantPage() {
    return TDStorePage.STORE_EDIT;
  }

  get requestPageModesEnum() {
    return RequestPageModesEnum;
  }

  get profile() {
    return this.storeForm.get('storeProfile')['controls'][0] as UntypedFormGroup;
  }

  getColorStatus(status: string): string {
    return status && status.toLowerCase();
  }

  initControl() {
    this.storeForm = this.fb.group({});
  }

  onCancel() {
    if (this.storeForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.storeForm.invalid) {
      return;
    }

    this.handleConfirm();
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const storeRequestTemplate = this.prepareStoreRequest();
          this.store.dispatch(new StoreCreateSubmitRequestAction(storeRequestTemplate));
        }
      });
  }

  get location() {
    return this.storeForm.get('storeLocation') as UntypedFormGroup;
  }

  prepareStoreRequest(): StoreRequestTemplate {
    const storeRequestTemplate = new StoreRequestTemplate();
    const formData = this.storeForm.getRawValue();
    const store = formData.storeProfile[0];
    const location = formData.storeLocation;
    const contact = formData.storeContact;

    const storePhoneData = index => contact?.at(index)?.phone;
    const storeContactData = index => contact?.at(index)?.contactName;

    storeRequestTemplate.version = this.storeInfo?.version;
    storeRequestTemplate.step = this.step;
    storeRequestTemplate.status = this.status;
    storeRequestTemplate.type = this.type;
    storeRequestTemplate.merchantNo = this.data.merchant;
    storeRequestTemplate.storeProfile = {
      ...store,
      ...location,
      deliveryTimes: removeObjectArray(location.deliveryTimes),
      nearByPois: removeObjectArray(location.nearByPois),
      nearByCompetitors: removeObjectArray(location.nearByCompetitors),
      openDate: store.openDate ? formatDateStartOfDay(store.openDate, env.dateISO8601) : null,
      storeNo: this.data.storeNo,
      contactName: storeContactData(0) ?? null,
      contactName2: storeContactData(1) ?? null,
      contactName3: storeContactData(2) ?? null,
      phone: storePhoneData(0)?.nationalNumber?.replace(/ /g, '') ?? null,
      phone2: storePhoneData(1)?.nationalNumber?.replace(/ /g, '') ?? null,
      phone3: storePhoneData(2)?.nationalNumber?.replace(/ /g, '') ?? null,
      countryCode: storePhoneData(0)?.internationalNumber.split(' ')[0] ?? null,
      countryCode2: storePhoneData(1)?.internationalNumber.split(' ')[0] ?? null,
      countryCode3: storePhoneData(2)?.internationalNumber.split(' ')[0] ?? null,
      rentalFee:
        !this.location.controls.rentalFee.disabled && location.rentalFee
          ? { amount: location.rentalFee, currency: 'THB' }
          : null,
      poiType: location.poiType && location.poiType.join(','),
      storeFrontPicture: location.storeFrontPicture && getMultipleFileId(location.storeFrontPicture),
      storeFrontVideo: getFileId(location.storeVDOFront) ?? null,
      titleDeed: getFileId(location.titleDeed) ?? null,
      houseRegistrationPicture: getFileId(location.houseRegistrationPicture) ?? null,
      idCardPicture: getFileId(location.idCardPicture) ?? null,
      consentLetterPicture: getFileId(location.consentLetterPicture) ?? null,
      attachmentPicture: location.attachmentPicture && getMultipleFileId(location.attachmentPicture),
      storeConditions: formData.storeConditions,
      terminateDate: store?.terminateDate ? formatDateStartOfDay(store.terminateDate, env.dateISO8601) : null
    };

    const storeProfileInfo = this.storeInfo?.merchantInfo?.storeProfile[0];
    const storeProfile = {
      ...storeProfileInfo,
      openDate: formatDateStartOfDay(storeProfileInfo?.openDate, env.dateISO8601),
      terminateDate: storeProfileInfo?.terminateDate
        ? formatDateStartOfDay(storeProfileInfo?.terminateDate, env.dateISO8601)
        : null
    };

    storeRequestTemplate.updatedFields = [
      ...findObjectDifference('storeProfile', storeProfile, storeRequestTemplate.storeProfile)
    ].filter(value => value !== null);

    storeRequestTemplate.orderSchedule = formData.orderSchedule.orderScheduleList[0];

    return storeRequestTemplate;
  }

  onUpdateOrderSchedule() {
    const initialState = {
      parentForm: this.storeForm,
      mode: RequestPageModesEnum.REQUEST_EDIT,
      page: TDStorePage.STORE_EDIT_ORDER_SCHEDULE,
      storeNo: this.data.storeNo,
      merchant: this.data.merchant,
      version: this.version.toString(),
      typeSection: TDStoreEnum.REPLENISH,
      ddlPostion: 'bottom'
    };

    this.modalService.show(UpdateOrderPolicyComponent, {
      class: 'update-order-schedule',
      initialState
    });
  }

  onUpdatePreOrderSchedule() {
    const initialState = {
      parentForm: this.storeForm,
      mode: RequestPageModesEnum.REQUEST_EDIT,
      page: TDStorePage.STORE_EDIT_ORDER_SCHEDULE,
      storeNo: this.data.storeNo,
      merchant: this.data.merchant,
      version: this.version.toString(),
      typeSection: TDStoreEnum.PRE_ORDER
    };

    this.modalService.show(UpdateOrderPolicyComponent, {
      class: 'update-order-schedule',
      initialState
    });
  }

  canEditStore() {
    this.status = RequestStatusEnum.DRAFT;

    this.localStore
      .pipe(
        select(selectStore),
        filter(value => Boolean(value.result))
      )
      .subscribe((value: StoreCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          this.alertSuccessModal('The request has been sent to approver.');
        } else {
          this.alertErrorModal(value.result.errorResponse);
        }
      });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.modalService.hide();
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  alertModal(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  onTriggerEdit() {
    this.storeRequestService
      .getStoreValidate(TDStoreValidatorTypeEnum.REQUESTED, this.data.storeNo)
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: res => {
          if (res.body.allowToEdit) {
            this.data.mode = RequestPageModesEnum.REQUEST_EDIT;

            if (
              this.tdStoreWorkflowUtil.canEditSection(
                this.type,
                this.page,
                this.step,
                RequestSectionEnum.STORE_PROFILE
              )
            ) {
              this.data.title = 'Edit Store';
              this.canEditStore();

              this.storeProfile.toggleEditStoreProfile();
              this.storeContact.toggleEditStoreContact();
              this.storeLocation.toggleEditStoreLocation();
              this.storeCondition.toggleEditStoreCondition();
            }
          } else {
            this.alertModal('Alert', 'Another request is awaiting approval.');
          }
        },
        error: error => {
          this.store.dispatch(new LayoutActionLoadError(error));
        }
      });
  }

  onLockSale() {
    const msg = this.storeInfo.saleStatus === SaleStatusEnum.DISABLE ? 'unlock' : 'lock';
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to ${msg} sale?`,
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          this.store.dispatch(
            new StoreLockSaleRequestAction({
              storeNo: this.storeInfo.no,
              saleStatus:
                this.storeInfo.saleStatus === SaleStatusEnum.DISABLE ? SaleStatusEnum.ENABLE : SaleStatusEnum.DISABLE,
              version: this.storeInfo.version
            })
          );
        }
      });
  }

  onActivateStore(storeNo) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to activate?',
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          return this.storeService.activateStore({ storeNo }).pipe(untilComponentDestroyed(this));
        })
      )
      .subscribe({
        next: () => {
          this.alertSuccessModal('The store has been activated.');
        },
        error: error => {
          this.alertErrorModal(error.error);
        },
        complete: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  onDeactivateStore(storeNo) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to deactivate?',
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          return this.storeRequestService.getStoreValidate(TDStoreValidatorTypeEnum.REQUESTED, storeNo).pipe(
            untilComponentDestroyed(this),
            filter(v => Boolean(v))
          );
        })
      )
      .subscribe({
        next: res => {
          if (res.body.allowToEdit) {
            this.deactivateStore(storeNo);
          } else {
            this.alertModal('Failed', 'The request is awaiting approval.');
          }
        },
        error: error => {
          this.store.dispatch(new LayoutActionLoadError(error));
        },
        complete: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  deactivateStore(storeNo) {
    this.storeService
      .deactivateStore({ storeNo })
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: () => {
          this.alertSuccessModal('The store has been deactivated.');
        },
        error: error => {
          this.alertErrorModal(error.error);
        }
      });
  }

  hasEditPermission(): boolean {
    return this.tdStoreWorkflowUtil.hasEditPermission(this.type, this.page, this.step, RequestStatusEnum.DRAFT);
  }

  hasSubmitPermission(): boolean {
    return this.tdStoreWorkflowUtil.hasSubmitPermission(this.type, this.page, this.step, this.status);
  }

  hasAtLeastOnePermission() {
    return this.hasEditPermission() || this.hasSubmitPermission();
  }

  hasLockSalePermission() {
    return this.authGuardService.checkPermission(['store_locksale']);
  }

  doAfterVersionAlertModal() {}

  onTerminateStore(data: StoreViewResponse) {
    const { storeCode, storeName, storeNo } = data.merchantInfo.storeProfile[0];
    this.terminateStoreModal.openTerminateModal(
      storeCode,
      storeName,
      storeNo,
      data.version,
      data.merchantInfo.storeProfile[0].openDate
    );
    this.terminateStoreModal.action.pipe(take(1)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.store.dispatch(
          new StoreByIdRequestAction({
            merchant: { merchant: this.data.merchant },
            storeNo: { storeNo: this.data.storeNo }
          })
        );
      }
    });
  }

  showHistory(data: StoreViewResponse) {
    const { storeCode, storeName, storeNo } = data.merchantInfo.storeProfile[0];
    this.store.dispatch(new StoreListHistoryRequestAction({ storeNo }));
    const initialState = {
      title: 'History',
      historyHeader: `Store: ${storeCode}-${storeName}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.STORE,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new StoreListHistoryResponseAction({ auditLogs: null }));
    });
  }

  isSelectedTab(tab: StoreTabs, selectedTab: StoreTabs) {
    return selectedTab === tab;
  }

  onSelectTabs(tab: StoreTabs) {
    this.selectedTab = tab;
  }
}
