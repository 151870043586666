import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import {
  RewardCatalogRequestList,
  RewardCatalogRequestListSearchCriteria,
  RewardCatalogRequestViewResponse
} from '../../../../shared/models/reward-catalog.model';
import { RewardCatalogRequestActionType, RewardCatalogRequestActions } from './reward-catalog-request.actions';
import { AppRewardCatalogRequestState } from './reward-catalog-request.state';

export const rewardCatalogRequestModuleReducers: ActionReducerMap<Partial<AppRewardCatalogRequestState>> = {
  rewardCatalogRequest: rewardCatalogRequestReducers
};

export interface RewardCatalogRequestState extends EntityState<RewardCatalogRequestList> {
  isLoading: boolean;
  selected: RewardCatalogRequestViewResponse;
  criteriaObject: RewardCatalogRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
  isDeleteSuccess: boolean;
  isApproveRejectSuccess?: boolean;
  auditLogs: any;
}

export const adapter: EntityAdapter<RewardCatalogRequestList> = createEntityAdapter<RewardCatalogRequestList>();

export const initialRewardCatalogRequestListState: RewardCatalogRequestState = adapter.getInitialState({
  isLoading: false,
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  isDeleteSuccess: false,
  auditLogs: null
});

export function rewardCatalogRequestReducers(
  state = initialRewardCatalogRequestListState,
  action: RewardCatalogRequestActions
): RewardCatalogRequestState {
  switch (action.type) {
    case RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: { ...action.payload }
      };
    case RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_DELETE_RESPONSE:
      return {
        ...state,
        isDeleteSuccess: action.payload.isSuccess
      };
    case RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_DELETE_RESET:
      return {
        ...state,
        isDeleteSuccess: false
      };
    case RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_RESET:
      return initialRewardCatalogRequestListState;
    case RewardCatalogRequestActionType.REWARD_CATALOG_REQUEST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
