import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BaseComponent } from '../../../base/base.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { PromotionModeEnum, PromotionRequestStatusEnum } from '../../../shared/enum/promotion.enum';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import {
  PromotionRequestPageModes,
  PromotionRequestResponse,
  PromotionSearchCriteria,
  PromotionTypeEnum
} from '../../../shared/models';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import {
  PromotionCancelRequest,
  PromotionGetByCodeRequestAction,
  PromotionListRequestAction,
  ResetPromotionRequestSelected
} from '../../../shared/store/actions/promotion.action';
import { selectRequestPromotionData } from '../../../shared/store/selectors/promotion-request.selectors';
import { selectPromotionListCriteria } from '../../../shared/store/selectors/promotion.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { PromotionConditionComponent } from '../promotion-request/promotion-condition/promotion-condition.component';
import { PromotionDetailsComponent } from '../promotion-request/promotion-details/promotion-details.component';
import { PromotionItemComponent } from '../promotion-request/promotion-item/promotion-item.component';
import { PromotionStoreComponent } from '../promotion-request/promotion-store/promotion-store.component';

@Component({
  selector: 'app-promotion-view',
  templateUrl: './promotion-view.component.html',
  styleUrls: ['./promotion-view.component.scss']
})
export class PromotionViewComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('promotionItem') promotionItem: PromotionItemComponent;
  @ViewChild('coPromotionItem') coPromotionItem: PromotionItemComponent;
  @ViewChild('promotionDetails') promotionDetails: PromotionDetailsComponent;
  @ViewChild('promotionCondition') promotionCondition: PromotionConditionComponent;
  @ViewChild('promotionStore') promotionStore: PromotionStoreComponent;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { title: string; mode: PromotionRequestPageModes; promotionCode?: string };
  @Output() notifyLeaveFormOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() titleOutput: EventEmitter<string> = new EventEmitter<string>();

  public localStore: Observable<any>;
  public saveDraft: boolean;
  public submitted: boolean;
  public promotionForm: UntypedFormGroup;
  public status: PromotionRequestStatusEnum;
  public listSearchCriteria: PromotionSearchCriteria;
  public pageMode: PromotionModeEnum;
  public promotionView$: Observable<PromotionRequestResponse>;
  public version: number;
  public isViewMode: boolean;
  public promotion: PromotionRequestResponse;
  hasViewPromotionPermission: boolean;
  hasEditPromotionPermission: boolean;
  hasApprovePromotionPermission: boolean;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly fb: UntypedFormBuilder,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    protected spinner: NgxSpinnerService
  ) {
    super(store, modalService, false);
    this.hasViewPromotionPermission = this.authGuardService.checkPermission(['promotion_v']);
    this.hasEditPromotionPermission = this.authGuardService.checkPermission(['promotion_m']);
    this.hasApprovePromotionPermission = this.authGuardService.checkPermission(['promotion_app']);
  }

  ngOnInit() {
    this.initControl();
    this.initData();
    this.initState();
  }

  initControl() {
    const controlsConfig = {
      promotionDetails: this.fb.group({}),
      promotionCondition: this.fb.group({}),
      promotionItem: this.fb.array([]),
      coPromotionItem: this.fb.array([]),
      promotionStores: this.fb.group({})
    };

    this.promotionForm = this.fb.group(controlsConfig);
  }

  initData() {
    this.version = 0;
  }

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectPromotionListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    if (this.data.promotionCode) {
      this.store.dispatch(new PromotionGetByCodeRequestAction({ promotionCode: this.data.promotionCode }));
    }

    this.promotionView$ = this.localStore.pipe(select(selectRequestPromotionData));
    this.promotionView$.pipe(filter(data => Boolean(data))).subscribe(promotionValue => {
      if (promotionValue) {
        this.version = promotionValue.version;
        this.status = promotionValue.status as PromotionRequestStatusEnum;
        this.promotion = promotionValue;
        this.form.disable();
      }
    });
  }

  goToCancel() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel promotion code <strong>&quot;${this.promotion.promotionCode}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PromotionCancelRequest({
              id: this.promotion.promotionCode,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  showCancelButton(status: string): boolean {
    if (
      status !== null &&
      (status.toLocaleLowerCase() === 'awaiting_schedule' || status.toLocaleLowerCase() === 'active')
    ) {
      return true;
    }
    return false;
  }

  onExit() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  get form(): UntypedFormGroup {
    return this.promotionForm;
  }

  get details(): UntypedFormGroup {
    return this.form.get('promotionDetails') as UntypedFormGroup;
  }

  get promotionRequestPageMode() {
    return PromotionRequestPageModes;
  }

  isCoPromotion(): boolean {
    const promotionType = this.details.controls['promotionType']
      ? this.details.controls['promotionType'].value
      : null;
    return promotionType === PromotionTypeEnum.BUY_TWO_SAVE_MORE;
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  refreshPromotionList() {
    this.store.dispatch(new PromotionListRequestAction(this.listSearchCriteria));
  }

  doAfterVersionAlertModal() {}

  doAfterSuccessModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.refreshPromotionList();
    if (this.titleOutput) {
      this.titleOutput.unsubscribe();
    }
    if (this.notifyLeaveFormOutput) {
      this.notifyLeaveFormOutput.unsubscribe();
    }
    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }
    this.store.dispatch(new ResetPromotionRequestSelected());
  }
}
