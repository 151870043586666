import { createSelector } from '@ngrx/store';

import { AppStates } from '@shared/store/state/app.states';

import * as fromShelfInventoryListResponseState from './shelf-inventory.reducers';

const selectShelfInventoryListState = (state: AppStates) => state.shelfInventory;

export const selectAllShelfInventoryList = createSelector(
  selectShelfInventoryListState,
  fromShelfInventoryListResponseState.selectAll
);

export const selectShelfInventoryList = createSelector(
  selectShelfInventoryListState,
  (state: fromShelfInventoryListResponseState.ShelfInventoryState) => state
);

export const selectShelfInventoryListCriteria = createSelector(selectShelfInventoryListState, state => {
  return state.criteriaObject;
});

export const selectShelfInventoryListHistory = createSelector(
  selectShelfInventoryListState,
  (state: fromShelfInventoryListResponseState.ShelfInventoryState) => {
    return state.auditLogs;
  }
);

export const selectShelfInventoryById = createSelector(
  selectShelfInventoryListState,
  (state: fromShelfInventoryListResponseState.ShelfInventoryState) => state.selected
);
