import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { RequestSectionEnum } from '../enum/request-section.enum';
import { RequestStatusEnum } from '../enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../enum/request-step.enum';
import { TDStorePage } from '../enum/td-store-page.enum';
import { AuthGuardService } from '../services';
import { selectUserInfoResult } from '../store/selectors/auth-user-info.selector';
import { AppStates } from '../store/state/app.states';
import { AuthUserInfoState } from '../store/state/auth-user-info.state';

@Injectable()
export class TDStoreWorkflowUtil {
  private userInfo: AuthUserInfoState;

  private readonly workflow = [
    // Merchant Request and Store Request
    {
      type: RequestTypeEnum.NEW,
      page: [TDStorePage.MERCHANT_REQUEST, TDStorePage.STORE_REQUEST],
      step: RequestStepEnum.PROFILE,
      showSection: [
        RequestSectionEnum.PROFILE,
        RequestSectionEnum.LEGAL_DOCUMENT,
        RequestSectionEnum.STORE_PROFILE,
        RequestSectionEnum.ORDER_POLICY,
        RequestSectionEnum.STORE_CONTACT,
        RequestSectionEnum.STORE_LOCATION
      ],
      editSection: [
        RequestSectionEnum.PROFILE,
        RequestSectionEnum.LEGAL_DOCUMENT,
        RequestSectionEnum.STORE_PROFILE,
        RequestSectionEnum.STORE_CONTACT,
        RequestSectionEnum.STORE_LOCATION
      ],
      editStatus: [RequestStatusEnum.DRAFT, RequestStatusEnum.AWAITING_APPROVAL],
      editPermission: ['merchant_new_m'],
      approvePermission: ['merchant_app']
    },
    {
      type: RequestTypeEnum.NEW,
      page: [TDStorePage.MERCHANT_REQUEST, TDStorePage.STORE_REQUEST],
      step: RequestStepEnum.ORDER_POLICY,
      showSection: [
        RequestSectionEnum.PROFILE,
        RequestSectionEnum.LEGAL_DOCUMENT,
        RequestSectionEnum.STORE_PROFILE,
        RequestSectionEnum.ORDER_POLICY,
        RequestSectionEnum.STORE_CONTACT,
        RequestSectionEnum.STORE_LOCATION
      ],
      editSection: [RequestSectionEnum.ORDER_POLICY],
      editStatus: [RequestStatusEnum.AWAITING_ORDER_SCHEDULE],
      editPermission: ['merchant_order_m']
    },
    {
      type: RequestTypeEnum.NEW,
      page: [TDStorePage.MERCHANT_REQUEST, TDStorePage.STORE_REQUEST],
      step: RequestStepEnum.APPROVED,
      showSection: [
        RequestSectionEnum.PROFILE,
        RequestSectionEnum.LEGAL_DOCUMENT,
        RequestSectionEnum.STORE_PROFILE,
        RequestSectionEnum.ORDER_POLICY,
        RequestSectionEnum.STORE_CONTACT,
        RequestSectionEnum.STORE_LOCATION
      ],
      editSection: []
    },
    {
      type: RequestTypeEnum.EDIT,
      page: [TDStorePage.MERCHANT_REQUEST],
      step: RequestStepEnum.APPROVED,
      showSection: [RequestSectionEnum.PROFILE, RequestSectionEnum.LEGAL_DOCUMENT],
      editSection: []
    },
    {
      type: RequestTypeEnum.EDIT,
      page: [TDStorePage.STORE_REQUEST],
      step: RequestStepEnum.APPROVED,
      showSection: [
        RequestSectionEnum.STORE_PROFILE,
        RequestSectionEnum.STORE_CONTACT,
        RequestSectionEnum.STORE_LOCATION,
        RequestSectionEnum.ORDER_POLICY
      ],
      editSection: []
    },

    // Merchant List and Store List
    {
      type: RequestTypeEnum.EDIT,
      page: [TDStorePage.MERCHANT_EDIT],
      step: RequestStepEnum.EDIT_PROFILE,
      showSection: [RequestSectionEnum.PROFILE, RequestSectionEnum.LEGAL_DOCUMENT],
      editSection: [RequestSectionEnum.PROFILE, RequestSectionEnum.LEGAL_DOCUMENT],
      editStatus: [RequestStatusEnum.DRAFT],
      editPermission: ['merchant_edit_m']
    },
    {
      type: RequestTypeEnum.EDIT,
      page: [TDStorePage.STORE_EDIT],
      step: RequestStepEnum.EDIT_PROFILE,
      showSection: [
        RequestSectionEnum.STORE_PROFILE,
        RequestSectionEnum.STORE_CONTACT,
        RequestSectionEnum.STORE_LOCATION,
        RequestSectionEnum.ORDER_POLICY
      ],
      editSection: [
        RequestSectionEnum.STORE_PROFILE,
        RequestSectionEnum.STORE_CONTACT,
        RequestSectionEnum.STORE_LOCATION
      ],
      editStatus: [RequestStatusEnum.DRAFT],
      editPermission: ['merchant_edit_m']
    },

    // Merchant Edit
    {
      type: RequestTypeEnum.EDIT,
      page: [TDStorePage.MERCHANT_REQUEST],
      step: RequestStepEnum.EDIT_PROFILE,
      showSection: [RequestSectionEnum.PROFILE, RequestSectionEnum.LEGAL_DOCUMENT],
      editSection: [RequestSectionEnum.PROFILE, RequestSectionEnum.LEGAL_DOCUMENT],
      editStatus: [RequestStatusEnum.DRAFT, RequestStatusEnum.AWAITING_APPROVAL],
      editPermission: ['merchant_edit_m'],
      approvePermission: ['merchant_app']
    },

    // Store Edit
    {
      type: RequestTypeEnum.EDIT,
      page: [TDStorePage.STORE_REQUEST],
      step: RequestStepEnum.EDIT_PROFILE,
      showSection: [
        RequestSectionEnum.STORE_PROFILE,
        RequestSectionEnum.STORE_CONTACT,
        RequestSectionEnum.STORE_LOCATION,
        RequestSectionEnum.ORDER_POLICY
      ],
      editSection: [
        RequestSectionEnum.STORE_PROFILE,
        RequestSectionEnum.STORE_CONTACT,
        RequestSectionEnum.STORE_LOCATION
      ],
      editStatus: [RequestStatusEnum.DRAFT, RequestStatusEnum.AWAITING_APPROVAL],
      editPermission: ['merchant_edit_m'],
      approvePermission: ['merchant_app']
    }
  ];

  constructor(private readonly authGuardService: AuthGuardService, private readonly store: Store<AppStates>) {
    this.store.select(selectUserInfoResult).subscribe(userInfo => {
      this.userInfo = userInfo;
    });
  }

  isShowSection(type: RequestTypeEnum, page: TDStorePage, step: RequestStepEnum, sectionName: RequestSectionEnum) {
    const currentWorkflow = this.getMerchantWorkflow(type, page, step);

    return currentWorkflow && currentWorkflow.showSection.indexOf(sectionName) > -1;
  }

  canEditSection(type: RequestTypeEnum, page: TDStorePage, step: RequestStepEnum, sectionName: RequestSectionEnum) {
    const currentWorkflow = this.getMerchantWorkflow(type, page, step);

    return currentWorkflow && currentWorkflow.editSection.indexOf(sectionName) > -1;
  }

  hasSubmitPermission(
    type: RequestTypeEnum,
    page: TDStorePage,
    step: RequestStepEnum,
    status: RequestStatusEnum
  ): boolean {
    const currentWorkflow = this.getMerchantWorkflow(type, page, step);

    if (
      currentWorkflow &&
      currentWorkflow.editStatus &&
      currentWorkflow.editStatus.includes(status) &&
      [RequestStepEnum.PROFILE, RequestStepEnum.ORDER_POLICY, RequestStepEnum.EDIT_PROFILE].includes(step)
    ) {
      return this.authGuardService.checkPermission(currentWorkflow.editPermission);
    }

    return false;
  }

  hasEditPermission(
    type: RequestTypeEnum,
    page: TDStorePage,
    step: RequestStepEnum,
    status: RequestStatusEnum
  ): boolean {
    const currentWorkflow = this.getMerchantWorkflow(type, page, step);

    if (currentWorkflow && currentWorkflow.editStatus && currentWorkflow.editStatus.indexOf(status) > -1) {
      return this.authGuardService.checkPermission(currentWorkflow.editPermission);
    }

    return false;
  }

  hasApprovePermission(
    type: RequestTypeEnum,
    page: TDStorePage,
    step: RequestStepEnum,
    status: RequestStatusEnum
  ): boolean {
    const currentWorkflow = this.getMerchantWorkflow(type, page, step);

    if (currentWorkflow && currentWorkflow.approvePermission && status === RequestStatusEnum.AWAITING_APPROVAL) {
      return this.authGuardService.checkPermission(currentWorkflow.approvePermission);
    }

    return false;
  }

  hasViewLegalDocument(status: RequestStatusEnum, requestedBy: string): boolean {
    return (
      status === RequestStatusEnum.DRAFT ||
      (status === RequestStatusEnum.AWAITING_APPROVAL &&
        (requestedBy === this.userInfo.userNo || this.authGuardService.checkPermission(['merchant_app'])))
    );
  }

  hasViewEditLegalDocument(status: RequestStatusEnum, mode: RequestPageModesEnum): boolean {
    return (
      status === RequestStatusEnum.AWAITING_APPROVAL &&
      ((mode === RequestPageModesEnum.REQUEST_EDIT &&
        this.authGuardService.checkPermission(['merchant_edit_m', 'merchant_edit_m'])) ||
        (mode === RequestPageModesEnum.REQUEST_VIEW && this.authGuardService.checkPermission(['merchant_app'])))
    );
  }

  hasSavePermission(
    type: RequestTypeEnum,
    page: TDStorePage,
    step: RequestStepEnum,
    status: RequestStatusEnum
  ): boolean | undefined {
    const currentWorkflow = this.getMerchantWorkflow(type, page, step);

    if (
      currentWorkflow &&
      currentWorkflow.editPermission &&
      step === RequestStepEnum.PROFILE &&
      status === RequestStatusEnum.DRAFT
    ) {
      return this.authGuardService.checkPermission(currentWorkflow.editPermission);
    }
  }

  hasDeletePermission(
    type: RequestTypeEnum,
    page: TDStorePage,
    step: RequestStepEnum,
    status: RequestStatusEnum
  ): boolean {
    const currentWorkflow = this.getMerchantWorkflow(type, page, step);

    return (
      step === RequestStepEnum.PROFILE &&
      status === RequestStatusEnum.DRAFT &&
      currentWorkflow &&
      currentWorkflow.editPermission &&
      this.authGuardService.checkPermission(currentWorkflow.editPermission)
    );
  }

  hasCancelPermission(status: RequestStatusEnum): boolean {
    return (
      this.authGuardService.checkPermission(['merchant_new_m', 'merchant_edit_m']) &&
      status === RequestStatusEnum.AWAITING_APPROVAL
    );
  }

  hasViewHistoryPermission(status: RequestStatusEnum): boolean {
    return status !== RequestStatusEnum.DRAFT;
  }

  private getMerchantWorkflow(type: RequestTypeEnum, page: TDStorePage, step: RequestStepEnum): any {
    return this.workflow.find(item => {
      return item.type === type && item.page.includes(page) && item.step === step;
    });
  }
}
