import { createSelector } from '@ngrx/store';

import * as fromOrderRequestResponseState from '../reducers/order-request.reducers';
import { AppStates } from '../state/app.states';

const selectOrderRequestState = (state: AppStates) => state.orderRequest;

export const selectAllOrderRequestList = createSelector(
  selectOrderRequestState,
  fromOrderRequestResponseState.selectAll
);

export const selectOrderRequestList = createSelector(
  selectOrderRequestState,
  (state: fromOrderRequestResponseState.OrderRequestState) => state
);

export const selectOrderRequestListCriteria = createSelector(selectOrderRequestState, state => {
  return state.criteriaObject;
});

export const selectOrderRequestById = createSelector(
  selectOrderRequestState,
  (state: fromOrderRequestResponseState.OrderRequestState) => state.selected
);

export const selectOrderRequestHistory = createSelector(
  selectOrderRequestState,
  (state: fromOrderRequestResponseState.OrderRequestState) => state.auditLogs
);

export const selectOrderRequestShelfList = createSelector(
  selectOrderRequestState,
  (state: fromOrderRequestResponseState.OrderRequestState) => state.shelfList
);

export const selectDeliveryDetails = createSelector(
  selectOrderRequestState,
  (state: fromOrderRequestResponseState.OrderRequestState) => {
    return state.selected && state.selected.deliveryDetails;
  }
);
