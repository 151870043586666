import { Action } from '@ngrx/store';

import {
  SupplierPriceContent,
  SupplierPricePagination,
  SupplierPriceSearchCriteria
} from '../../models/supplier-price.model';

export enum SupplierPriceActionTypes {
  SUPPLIER_PRICE_LIST_REQUEST = '[Supplier Price List Page] Supplier Price List Request',
  SUPPLIER_PRICE_LIST_RESPONSE = '[Supplier Price List API] Supplier Price List Response',
  SUPPLIER_PRICE_RESET = '[Supplier Price View Page] Supplier Price Reset',
  SUPPLIER_PRICE_SHOW_SUCCESS = '[Supplier Price View Page] Supplier Price Show Success',
  SUPPLIER_PRICE_IS_PRICE_UPDATED = '[Supplier Price View Page] Supplier Price is price updated',
  SUPPLIER_PRICE_UPDATED_SUPPLIER_NAME = '[Supplier Price View Page] Supplier Price update supplier name',
  SUPPLIER_PRICE_UPDATED_MULTIPLE_SUPPLIER_NAME = '[Supplier Price View Page] Supplier Price update multiple supplier name',
  SUPPLIER_PRICE_INSERT_SUPPLIER = '[Supplier Price View Page] Supplier Price insert supplier',
  SUPPLIER_PRICE_ADD_SUPPLIER = '[Supplier Price View Page] Supplier Price Add Many supplier',
  SUPPLIER_PRICE_REMOVE_ONE_SUPPLIER = '[Supplier Price View Page] Supplier Price Remove One supplier',
  SUPPLIER_PRICE_RE_INDEX_DATA = '[Supplier Price View Page] Supplier Price Re-index',
  SUPPLIER_PRICE_UPDATE_ONE = '[Supplier Price View Page] Supplier Price Update one'
}

export class SupplierPriceListRequest implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_LIST_REQUEST;
  constructor(public payload: SupplierPriceSearchCriteria) {}
}

export class SupplierPriceListResponse implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_LIST_RESPONSE;

  constructor(public payload: SupplierPricePagination) {}
}

export class SupplierPriceReset implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_RESET;
  constructor() {}
}

export class SupplierPriceShowSuccess implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_SHOW_SUCCESS;
  constructor(public payload: boolean) {}
}

export class SupplierPriceIsPriceUpdated implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_IS_PRICE_UPDATED;
  constructor(public payload: boolean) {}
}

export class SupplierPricesUpdate implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_UPDATED_SUPPLIER_NAME;
  constructor(public payload: any) {}
}

export class SupplierPricesMultipleUpdate implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_UPDATED_MULTIPLE_SUPPLIER_NAME;
  constructor(public payload: Array<SupplierPriceContent>) {}
}

export class SupplierPricesInsert implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_INSERT_SUPPLIER;
  constructor(public payload: any) {}
}

export class SupplierPricesAddMany implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_ADD_SUPPLIER;
  constructor(public payload: any) {}
}

export class SupplierPricesRemoveOne implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_REMOVE_ONE_SUPPLIER;
  constructor(public payload: string) {}
}
export class ReIndexSupplierPrice implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_RE_INDEX_DATA;
  constructor() {}
}

export class SupplierPricesUpdateOne implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_UPDATE_ONE;
  constructor(public payload: any) {}
}

export type SupplierPriceActions =
  | SupplierPriceListRequest
  | SupplierPriceListResponse
  | SupplierPriceReset
  | SupplierPriceShowSuccess
  | SupplierPricesUpdate
  | SupplierPricesInsert
  | SupplierPricesAddMany
  | SupplierPricesRemoveOne
  | SupplierPriceIsPriceUpdated
  | ReIndexSupplierPrice
  | SupplierPricesUpdateOne
  | SupplierPricesMultipleUpdate;
