import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { ErrorResponse } from '../../models';
import { OrderCreateRequest, OrderRequestViewResponse } from '../../models/order-request.model';
import { OrderRequestActionType, OrderRequestActions } from '../actions/order-request.actions';
import { BaseState } from '../state/base.state';

export interface OrderCreateResponseState extends EntityState<OrderCreateRequest>, BaseState {
  isLoading: boolean;
  isSave?: boolean | null;
  isApproveRejectSuccess?: boolean | null;
  result?: {
    response: OrderRequestViewResponse | null;
    errorResponse: ErrorResponse | null;
  };
}

export const adapter: EntityAdapter<OrderCreateRequest> = createEntityAdapter<OrderCreateRequest>();

export const initialOrderCreateResponseState: OrderCreateResponseState = adapter.getInitialState({
  isLoading: false,
  isSave: null,
  isApproveRejectSuccess: null
});

export function orderCreateReducers(
  state = initialOrderCreateResponseState,
  action: OrderRequestActions
): OrderCreateResponseState {
  switch (action.type) {
    case OrderRequestActionType.ORDER_CREATE_SAVE_RESPONSE:
    case OrderRequestActionType.ORDER_CREATE_SUBMIT_RESPONSE:
      return {
        ...state,
        isSave: OrderRequestActionType.ORDER_CREATE_SAVE_RESPONSE === action.type,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    case OrderRequestActionType.ORDER_CREATE_SUBMIT_ERROR:
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    case OrderRequestActionType.ORDER_REQUEST_APPROVE_RESPONSE:
      return {
        ...state,
        isApproveRejectSuccess: action.payload.isSuccess
      };
    case OrderRequestActionType.ORDER_CREATE_RESET:
      return initialOrderCreateResponseState;
    default: {
      return state;
    }
  }
}
