import { HideModalActionTypes, HideModalActions } from '@shared/store/actions/hide-modal.action';
import { HideModalState } from '@shared/store/state/hide-modal.state';

export function hideAllModalReducers(
  initialState = { isHideAllModal: false },
  action: HideModalActions
): HideModalState {
  if (action.type === HideModalActionTypes.HIDE_ALL_MODAL) {
    return {
      ...initialState,
      isHideAllModal: action.payload
    };
  } else {
    return initialState;
  }
}
