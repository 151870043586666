import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import { VoucherListContent, VoucherListSearchCriteria } from '../models/voucher.model';
import { VoucherAction, VoucherActionTypes } from './voucher.actions';
import { VoucherStates } from './voucher.states';

export const voucherModuleReducers: ActionReducerMap<Partial<VoucherStates>> = {
  voucher: VoucherReducers
};

export interface VoucherState extends EntityState<VoucherListContent> {
  isLoading: boolean;
  selected: any;
  criteriaObject: VoucherListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<VoucherListContent> = createEntityAdapter<VoucherListContent>();

export const initialVoucherListState: VoucherState = adapter.getInitialState({
  isLoading: false,
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  auditLogs: null
});

export function VoucherReducers(state = initialVoucherListState, action: VoucherAction): VoucherState {
  switch (action.type) {
    case VoucherActionTypes.VOUCHER_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case VoucherActionTypes.VOUCHER_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case VoucherActionTypes.VOUCHER_GET_BY_CODE_RESPONSE:
      return {
        ...state,
        selected: { ...action.payload.VoucherView }
      };
    case VoucherActionTypes.VOUCHER_GET_BY_CODE_RESET:
      return {
        ...state,
        selected: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
