import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { UserActionTypes, UserInfoActions } from '../actions/user-info.action';

export interface UserCreateState extends EntityState<any> {
  isLoading: boolean;
  isSubmitSuccess: boolean;
  userResponseError: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialUserState: UserCreateState = adapter.getInitialState({
  isLoading: false,
  isSubmitSuccess: false,
  userResponseError: null
});

export function userCreateReducers(state = initialUserState, action: UserInfoActions): UserCreateState {
  switch (action.type) {
    case UserActionTypes.USER_CREATE_RESPONSE:
    case UserActionTypes.USER_UPDATE_RESPONSE:
      return {
        ...state,
        isSubmitSuccess: true
      };
    case UserActionTypes.USER_CREATE_RESPONSE_ERROR:
    case UserActionTypes.USER_UPDATE_RESPONSE_ERROR: {
      return {
        ...state,
        userResponseError: action.payload
      };
    }
    case UserActionTypes.USER_CREATE_RESPONSE_RESET:
    case UserActionTypes.USER_UPDATE_RESPONSE_RESET: {
      return initialUserState;
    }
    default: {
      return state;
    }
  }
}
