import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import {
  ShelfInventoryRequestList,
  ShelfInventoryRequestListSearchCriteria,
  ShelfInventoryRequestViewResponse
} from '@shared/models/shelf-inventory-request.model';
import { BaseState } from '@shared/store/state/base.state';

import { ShelfInventoryRequestActionType, ShelfInventoryRequestActions } from './shelf-inventory-request.actions';
import { AppShelfInventoryRequestState } from './shelf-inventory-request.state';

export const shelfInventoryRequestModuleReducers: ActionReducerMap<Partial<AppShelfInventoryRequestState>> = {
  shelfInventoryRequest: shelfInventoryRequestReducers
};

export interface ShelfInventoryRequestState extends EntityState<ShelfInventoryRequestList>, BaseState {
  selected: ShelfInventoryRequestViewResponse;
  criteriaObject: ShelfInventoryRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  isDeleteSuccess: boolean;
}

export const adapter: EntityAdapter<ShelfInventoryRequestList> = createEntityAdapter<ShelfInventoryRequestList>();

export const initialShelfInventoryResponseState: ShelfInventoryRequestState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  isDeleteSuccess: null
});

export function shelfInventoryRequestReducers(
  state = initialShelfInventoryResponseState,
  action: ShelfInventoryRequestActions
): ShelfInventoryRequestState {
  switch (action.type) {
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_DELETE_RESPONSE:
      return {
        ...state,
        isDeleteSuccess: action.payload.isSuccess
      };
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_DELETE_RESET:
      return {
        ...state,
        isDeleteSuccess: false
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
