import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { MasterData } from '../../models';
import { DropdownMasterDataActionsType, MasterDataActions } from '../actions/master-data.actions';

export interface MasterDataState extends EntityState<MasterData> {
  isLoading: boolean;
  masterData: MasterData;
}

export const adapter: EntityAdapter<MasterData> = createEntityAdapter<MasterData>();

export const initialMasterDataState: MasterDataState = adapter.getInitialState({
  isLoading: false,
  masterData: new MasterData()
});

export function masterDataReducers(state = initialMasterDataState, action: MasterDataActions): MasterDataState {
  switch (action.type) {
    case DropdownMasterDataActionsType.DropdownMasterDataLoaded:
      return adapter.setAll(action.payload, {
        ...state
      });
    case DropdownMasterDataActionsType.DropdownMasterDataReset:
      return initialMasterDataState;

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
