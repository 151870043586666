import { createSelector } from '@ngrx/store';

import { AppStates } from '@shared/store/state/app.states';
import { HideModalState } from '@shared/store/state/hide-modal.state';

const selectHideModal = (state: AppStates) => state.hideAllModal;

export const selectHideAllModal = createSelector(selectHideModal, (state: HideModalState) => {
  return state?.isHideAllModal;
});
