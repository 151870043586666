import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store, select } from '@ngrx/store';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';

import { ProductDelistEnum, ProductRestrictEnum, ProductTypeEnum } from '../../enum/product-type.enum';
import { PromotionItemTypeEnum } from '../../enum/promotion.enum';
import { PromotionItem, PromotionTypeEnum } from '../../models';
import { BarcodeNameSearchCriteria, BarcodeResponse } from '../../models/barcode.model';
import { BarcodeService } from '../../services';
import { selectCoPromotionItems, selectPromotionItems } from '../../store/selectors/promotion-request.selectors';
import { AppStates } from '../../store/state/app.states';

@Component({
  selector: 'app-search-product-modal',
  templateUrl: './search-product-modal.component.html',
  styleUrls: ['./search-product-modal.component.scss']
})
export class SearchProductModalComponent implements OnInit {
  @ViewChild('searchProductModal') searchProductModal: ModalDirective;
  @ViewChild('productSelect') productSelect: NgSelectComponent;
  @Output() addItem = new EventEmitter<PromotionItem[] | BarcodeResponse[]>(); // TODO Fixing type of any to specified type.
  @Input() productType?: ProductTypeEnum = ProductTypeEnum.ALL;
  @Input() allowRestrictItem?: ProductRestrictEnum = ProductRestrictEnum.YES;
  @Input() allowDelistItem?: ProductDelistEnum = ProductDelistEnum.NO;
  @Input() promotion?: {
    promotionConRetailPrice: string;
    promotionType: PromotionTypeEnum;
    promotionItemType: PromotionItemTypeEnum;
  };

  searchForm: UntypedFormGroup;
  submittedSelectProduct: boolean;
  promotionItems$: Observable<PromotionItem[]>;
  productList: Observable<BarcodeResponse[]>;
  promotionItemList: PromotionItem[];
  productSearchLoading = false;
  productSearchInput$ = new Subject<string>();

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly formBuilder: UntypedFormBuilder,
    protected readonly modalService: BsModalService,
    private readonly barcodeService: BarcodeService
  ) {}

  ngOnInit() {
    this.loadProduct('');
    let _selectPromotionItems = selectPromotionItems;

    if (this.promotion && this.promotion.promotionItemType !== PromotionItemTypeEnum.PROMOTION) {
      _selectPromotionItems = selectCoPromotionItems;
    }

    this.promotionItems$ = this.store.pipe(select(_selectPromotionItems));
    this.promotionItems$.subscribe(item => {
      this.promotionItemList = item;
    });
    this.searchForm = this.formBuilder.group({
      ProductName: [null, [Validators.required]]
    });
  }

  openSelectProductModal() {
    this.loadProduct('');
    this.searchProductModal.show();
  }

  closeSelectProductModal() {
    this.searchForm.get('ProductName').reset();
    this.productSelect.itemsList.unmarkItem();
    this.submittedSelectProduct = false;
    this.searchProductModal.hide();
  }

  onChangeProductName(value) {
    this.searchForm.patchValue({
      ProductName: value ? value : null
    });
  }

  get form() {
    return this.searchForm.controls;
  }

  saveProduct() {
    this.submittedSelectProduct = true;
    if (this.searchForm.invalid) {
      return;
    }
    const selectedItem = this.searchForm.get('ProductName').value;
    this.addItem.emit([selectedItem]);
    this.closeSelectProductModal();
  }

  onBlurProductName() {
    if (!this.searchForm.controls.ProductName.value) {
      this.loadProduct('');
    }
  }

  // ======== Supplier Modal =======
  loadProduct(initialTerm: string) {
    this.productList = concat(
      of([]),
      this.productSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.productSearchLoading = true)),
        switchMap(term => {
          let criteria: BarcodeNameSearchCriteria;

          if (this.promotion) {
            criteria = new BarcodeNameSearchCriteria({ searchCriteria: term, productType: this.productType });
          } else {
            criteria = new BarcodeNameSearchCriteria({
              searchCriteria: term,
              allowRestrictItem: this.allowRestrictItem === ProductRestrictEnum.YES,
              allowDelistItem: this.allowDelistItem === ProductDelistEnum.YES,
              productType: this.productType
            });
          }

          return this.barcodeService.searchBarcodeByName(criteria).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.productSearchLoading = false;
            })
          );
        })
      )
    );
  }
}
