<div id="product-assortment" class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-order"></em>
    <h4>{{ 'MENU.ORDER' | translate }}</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="flex-item d-md-flex justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="orderStatusList"
            [searchable]="false"
            [clearable]="false"
            class="ng-select-width clear-cycle"
            bindLabel="label"
            bindValue="value"
            formControlName="orderStatus"
            (change)="onChangeOrderStatus($event)"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Created Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="orderDateFrom"
                          formControlName="orderDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="orderDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="orderDateTo"
                          formControlName="orderDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="{
                            containerClass: 'theme-dark-blue',
                            dateInputFormat: dateFormat,
                            showWeekNumbers: false
                          }"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="orderDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        <!--{{ 'CJ_PRODUCT.IMPORTED_DATE' | translate }}-->
                        Requested Delivery Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="deliveryDateFrom"
                          formControlName="deliveryDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDeliveryDate"
                          (bsValueChange)="onChangeDeliveryDateFrom($event)"
                          readonly
                        />
                        <label for="deliveryDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="deliveryDateTo"
                          formControlName="deliveryDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="{
                            containerClass: 'theme-dark-blue',
                            dateInputFormat: dateFormat,
                            showWeekNumbers: false
                          }"
                          [minDate]="minDeliveryDate"
                          (bsValueChange)="onChangeDeliveryDateTo($event)"
                          readonly
                        />
                        <label for="deliveryDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Order Type
                        </div>
                        <ng-select
                          class="clear-cycle"
                          placeholder="Please select..."
                          [items]="orderTypeList"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="orderType"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Delivery By
                        </div>
                        <ng-select
                          class="clear-cycle"
                          placeholder="Please select..."
                          [items]="orderDeliveryList"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="deliveryBy"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Warehouse
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="warehouseList"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="warehouseNameDisplay"
                          bindValue="code"
                          formControlName="warehouseCode"
                          class="clear-cycle"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="mb-2">
                          Order Flow
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="orderFlowList"
                          [searchable]="false"
                          [multiple]="false"
                          [clearable]="false"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="orderFlow"
                        >
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="mb-2">
                          Cross Dock Supplier
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="crossDockSupplierList"
                          [searchable]="false"
                          [multiple]="false"
                          [clearable]="false"
                          bindLabel="name"
                          bindValue="code"
                          formControlName="crossDockSupplier"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <app-advanced-search-tags [listTags]="listTags" (clearAll)="clearAdvanceFilter()" (clearByTag)="clearByTag($event)">
  </app-advanced-search-tags>

  <app-no-search-result [resultList$]="listState$" [hasSearchCriteria]="isSearch"></app-no-search-result>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="td-card" *ngFor="let result$ of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView(result$)">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">
                <app-click-to-copy [text]="result$.orderNo"></app-click-to-copy>
              </label>
              <span [ngClass]="getColorStatus(result$.status)" class="card-status order-status ml-auto">{{
                'ORDER.STATUS.' + result$.status | translate
              }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Order Request No.:</span>
              <span class="result-value">{{ result$.requestNo | dashDisplay }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Store:</span>
              <span class="result-value">
                <app-click-to-copy [text]="result$.storeCodeName | dashDisplay"></app-click-to-copy>
              </span>
            </div>
            <!--            <div class="col-lg-5 col-md-6 text-break">-->
            <!--              <span class="result-property">Store Type:</span>-->
            <!--              <span class="result-value">{{ 'STORE_TYPE.' + result$.storeType | translate }}</span>-->
            <!--            </div>-->
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Order Type:</span>
              <span class="result-value">{{ 'ORDER_TYPE.' + result$.orderType | translate }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Delivery By:</span>
              <span class="result-value">{{ 'ORDER.DELIVERY_BY.' + result$.deliveryBy | translate }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Warehouse:</span>
              <span class="result-value">
                {{ transformWareHouseName | pureFn: (result$.warehouseDisplayName | dashDisplay):result$.deliveryBy }}
              </span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Order Flow:</span>
              <span class="result-value">{{ result$.orderFlowDisplayName | dashDisplay: '(None)' }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Req. Delivery Date:</span>
              <span class="result-value">{{ result$.deliveryDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property groupOrderNoProperty">Group Order No.:</span>
              <span class="result-value groupOrderNoValue">{{ result$.goNo | dashDisplay: '(None)' }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Created By:</span>
              <span class="result-value">{{ result$.orderBy | dashDisplay: '(None)' }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property groupOrderNoProperty">Created Date:</span>
              <span class="result-value groupOrderNoValue">{{
                result$.createdDate | amFromUtc | amLocal | dateDisplay
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom right"
            [adaptivePosition]="false"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <button
              type="button"
              class="btn btn-link"
              *ngIf="canReleasePreOrder(result$)"
              (click)="onReleaseOrder(result$)"
            >
              <em class="icon-release-order"></em>Release Order
            </button>
            <button type="button" class="btn btn-link" (click)="showHistory(result$)">
              <em class="icon-history"></em>History
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>
