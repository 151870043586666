import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { StackPricing, StackPricingSearchCriteria, StackPricingView } from '../../models/stack-pricing.model';
import { StackPricingActionTypes, StackPricingActions } from '../actions/stack-pricing.actions';

export interface StackPricingState extends EntityState<StackPricing> {
  isLoading: boolean;
  criteriaObject: StackPricingSearchCriteria;
  selected: StackPricingView;
  auditLogs: any;
}

export const adapter: EntityAdapter<StackPricing> = createEntityAdapter<StackPricing>();

export const initialStackPricingState: StackPricingState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  selected: null,
  auditLogs: null
});

export function stackPricingReducers(
  state = initialStackPricingState,
  action: StackPricingActions
): StackPricingState {
  switch (action.type) {
    case StackPricingActionTypes.StackPricingSearchLoaded:
      return adapter.setAll(action.payload.stackPricings.content, {
        ...state,
        page: action.payload.stackPricings.page,
        size: action.payload.stackPricings.size,
        totalElements: action.payload.stackPricings.totalElements,
        totalPages: action.payload.stackPricings.totalPages
      });
    case StackPricingActionTypes.StackPricingViewLoaded: {
      return {
        ...state,
        selected: action.payload.stackPricingView
      };
    }
    case StackPricingActionTypes.StackPricingHistoryLoaded: {
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
