import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import { clone, isEqual } from 'lodash';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { finalize, map, mergeMap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ProfitSharingModeEnum, ProfitSharingStatusEnum } from '../../../shared/enum/profit-sharing.enum';
import { GraphqlQueryObject } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { HistoryType } from '../../../shared/models/audit-log.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  ProfitSharingData,
  ProfitSharingPrintCriteria,
  ProfitSharingSearchCriteria
} from '../../../shared/models/profit-sharing.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { ProfitSharingService } from '../../../shared/services/profit-sharing.service';
import { DecimalJsUtil } from '../../../shared/utils/decimal-js-util';
import { generatedFilenamePdf } from '../../../shared/utils/generate-filename-util';
import { ProfitSharingPermission } from '../profit-sharing-permission';
import { AppProfitSharingState } from '../store/profit-sharing.state';
import {
  ProfitSharingListRequestAction,
  ProfitSharingSubmitErrorResetAction,
  ProfitSharingSubmitRequestAction,
  ProfitSharingViewRequestAction
} from '../store/profit-sharing/profit-sharing.actions';
import {
  selectProfitSharingData,
  selectProfitSharingSearchCriteria,
  selectProfitSharingSubmitError
} from '../store/profit-sharing/profit-sharing.selectors';

@Component({
  selector: 'app-profit-sharing-view',
  templateUrl: './profit-sharing-view.component.html',
  styleUrls: ['./profit-sharing-view.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0, opacity: 0, display: 'none' })),
      state('down', style({ height: '*', opacity: 1 })),
      transition('up <=> down', animate(100))
    ])
  ]
})
export class ProfitSharingViewComponent extends BaseComponent implements OnInit {
  @Output() data: {
    title: string;
    id: string;
    mode: ProfitSharingModeEnum;
  };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  public profitSharing: ProfitSharingData;
  public profitSharingForm: UntypedFormGroup;
  public profitSharingModeEnum = ProfitSharingModeEnum;
  public isSubmitted = false;
  public hasPermissionManage: boolean;
  public vatPct: number;
  private criteriaObject: ProfitSharingSearchCriteria;
  public permission = new ProfitSharingPermission();
  public expandedTypeList: string[] = [];
  public langTH = false;

  switchLanguage(toggleStatus: boolean) {
    if (toggleStatus) {
      this.translate.use('th');
      this.langTH = true;
    } else {
      this.translate.use('en-US');
      this.langTH = false;
    }
  }

  constructor(
    protected readonly store: Store<AppProfitSharingState>,
    protected fb: UntypedFormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly translate: TranslateService,
    protected authGuardService: AuthGuardService,
    protected profitSharingService: ProfitSharingService,
    private masterService: MasterService
  ) {
    super(store, modalService, true);
  }
  private localStore: Observable<any>;

  get templateVersion() {
    return this.profitSharing.templateVersion;
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.permission.checkPermission(this.authGuardService);
    this.profitSharing = {} as ProfitSharingData;
    this.initialDataForViewEdit();
    this.initialSubscription();
  }

  initialDataForViewEdit() {
    const countryQuery = new GraphqlQueryObject();

    countryQuery.name = MasterDataEnum.COUNTRY;
    countryQuery.fields = ['id', 'code', 'nameEn', 'vatPct'];
    this.masterService
      .getMasterDataByNames([countryQuery])
      .pipe(map(result => result.data.countries.find(data => data.nameEn === environment.defaultCountry).vatPct))
      .subscribe(result => {
        this.vatPct = result;
      });
    this.profitSharing = {} as ProfitSharingData;
    this.store.dispatch(new ProfitSharingViewRequestAction(this.data.id));
    this.localStore.pipe(select(selectProfitSharingData), untilComponentDestroyed(this)).subscribe(profitSharing => {
      if (profitSharing) {
        this.profitSharing = clone(profitSharing) as ProfitSharingData;

        this.createForm();
      }
    });
  }

  initialSubscription() {
    this.localStore.pipe(select(selectProfitSharingSearchCriteria)).subscribe(criteriaObject => {
      this.criteriaObject = criteriaObject;
    });

    const submitErrorModal = this.localStore
      .pipe(
        select(selectProfitSharingSubmitError),
        mergeMap(submitError => {
          if (submitError) {
            const alertModalRef = this.modalService.show(AlertModalComponent, {
              initialState: {
                title: 'Failed',
                message: submitError
              }
            });
            alertModalRef.content.action
              .pipe(untilComponentDestroyed(this))
              .subscribe((result: ModalButtonResponseEnum) => {
                if (result === ModalButtonResponseEnum.OK) {
                  this.store.dispatch(new ProfitSharingSubmitErrorResetAction());
                  this.store.dispatch(new ProfitSharingListRequestAction(this.criteriaObject));
                  this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
                }
              });
          }
          return of(submitError);
        }),
        finalize(() => {
          if (submitErrorModal) {
            submitErrorModal.unsubscribe();
          }
        })
      )
      .subscribe();
  }

  doAfterVersionAlertModal() {
    this.store.dispatch(new ProfitSharingListRequestAction(this.criteriaObject));
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  doAfterSuccessModal() {
    this.store.dispatch(new ProfitSharingListRequestAction(this.criteriaObject));
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  subscribeForVersionError() {}

  onCloseFullModal() {
    if (this.profitSharingForm.touched || this.profitSharingForm.dirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
      return;
    }
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    this.switchLanguage(false);
  }

  getColorStatus(status): string {
    return status.toLowerCase();
  }

  createForm() {
    this.profitSharingForm = new UntypedFormGroup({
      compensateAmount: new UntypedFormControl(this.profitSharing.compensateAmount, Validators.required),
      withHoldingTaxAmount: new UntypedFormControl(this.profitSharing.withHoldingTaxAmount, Validators.required),
      storeUseAmount: new UntypedFormControl(this.profitSharing.storeUseAmount, Validators.required),
      destroyAmount: new UntypedFormControl(
        this.profitSharing.destroyAmount,
        this.templateVersion === 1 ? Validators.required : () => null
      ),
      advancePaymentAmount: new UntypedFormControl(
        this.profitSharing.advancePaymentAmount,
        this.templateVersion === 2 ? Validators.required : () => null
      ),
      cigaretteVatAdjustmentAmount: new UntypedFormControl(
        this.profitSharing.cigaretteVatAdjustmentAmount,
        Validators.required
      ),
      incentiveAmount: new UntypedFormControl(this.profitSharing.incentiveAmount, Validators.required),

      lessCashTransfer: new UntypedFormGroup({
        totalAmount: new UntypedFormControl(this.profitSharing.lessCashTransfer.totalAmount, Validators.required),
        lessCashTransferAmount: new UntypedFormControl(
          this.profitSharing.lessCashTransfer.lessCashTransferAmount,
          Validators.required
        ),
        stockLossInstallmentAmount: new UntypedFormControl(
          this.profitSharing.lessCashTransfer.stockLossInstallmentAmount,
          Validators.required
        ),
        billPaymentInstallmentAmount: new UntypedFormControl(
          this.profitSharing.lessCashTransfer.billPaymentInstallmentAmount,
          Validators.required
        ),
        negativeProfitSharingAmount: new UntypedFormControl(
          this.profitSharing.lessCashTransfer.negativeProfitSharingAmount,
          Validators.required
        ),
        advancePaymentAmount: new UntypedFormControl(
          this.profitSharing.lessCashTransfer.advancePaymentAmount,
          this.templateVersion === 1 ? Validators.required : () => null
        ),
        otherLessCashTransferAmount: new UntypedFormControl(
          this.profitSharing.lessCashTransfer.otherLessCashTransferAmount,
          Validators.required
        )
      }),

      stockLossAdjustment: new UntypedFormGroup({
        totalAmount: new UntypedFormControl(this.profitSharing.stockLossAdjustment.totalAmount, [
          Validators.required
        ]),
        icLostAmount: new UntypedFormControl(this.profitSharing.stockLossAdjustment.icLostAmount, [
          Validators.required
        ]),
        icLostPaidAmount: new UntypedFormControl(
          this.profitSharing.stockLossAdjustment.icLostPaidAmount,
          Validators.required
        ),
        icLostNextBillAmount: new UntypedFormControl(
          this.profitSharing.stockLossAdjustment.icLostNextBillAmount,
          Validators.required
        )
      }),

      netPenalty: new UntypedFormGroup({
        totalAmount: new UntypedFormControl(this.profitSharing.netPenalty.totalAmount, Validators.required),
        latePaymentAmount: new UntypedFormControl(
          this.profitSharing.netPenalty.latePaymentAmount,
          Validators.required
        ),
        toteFineAmount: new UntypedFormControl(this.profitSharing.netPenalty.toteFineAmount, Validators.required),
        otherPenaltyAmount: new UntypedFormControl(
          this.profitSharing.netPenalty.otherPenaltyAmount,
          Validators.required
        )
      }),

      otherExpense: new UntypedFormGroup({
        totalAmount: new UntypedFormControl(this.profitSharing.otherExpense.totalAmount, Validators.required),
        internetAmount: new UntypedFormControl(this.profitSharing.otherExpense.internetAmount, Validators.required),
        assetRepairAmount: new UntypedFormControl(
          this.profitSharing.otherExpense.assetRepairAmount,
          Validators.required
        ),
        otherExpenseAmount: new UntypedFormControl(
          this.profitSharing.otherExpense.otherExpenseAmount,
          Validators.required
        )
      }),

      refund: new UntypedFormGroup({
        overCashTransferAmount: new UntypedFormControl(
          this.profitSharing.refund.overCashTransferAmount,
          Validators.required
        ),
        refundAmount: new UntypedFormControl(this.profitSharing.refund.refundAmount, Validators.required),
        appealForFineAmount: new UntypedFormControl(
          this.profitSharing.refund.appealForFineAmount,
          Validators.required
        ),
        appealForStockLossAmount: new UntypedFormControl(
          this.profitSharing.refund.appealForStockLossAmount,
          Validators.required
        ),
        otherRefundAmount: new UntypedFormControl(this.profitSharing.refund.otherRefundAmount, Validators.required),
        totalAmount: new UntypedFormControl(this.profitSharing.refund.totalAmount, Validators.required)
      }),

      otherIncome: new UntypedFormGroup({
        marketingSupportAmount: new UntypedFormControl(
          this.profitSharing.otherIncome.marketingSupportAmount,
          Validators.required
        ),
        withHoldingTaxCorpAmount: new UntypedFormControl(
          this.profitSharing.otherIncome.withHoldingTaxCorpAmount,
          Validators.required
        ),
        otherIncomeAmount: new UntypedFormControl(
          this.profitSharing.otherIncome.otherIncomeAmount,
          Validators.required
        ),
        totalAmount: new UntypedFormControl(this.profitSharing.otherIncome.totalAmount, Validators.required)
      })
    });

    if (this.data.mode === ProfitSharingModeEnum.VIEW) {
      this.profitSharingForm.disable();
    } else if (this.data.mode === ProfitSharingModeEnum.EDIT) {
      this.editModeForm();
    }

    this.calculateAdministrativeExpenses();
    this.calculateOtherIncomeTotal();
    this.updateValueChangesEventForm();
    this.otherExpenseSubFormValueChange();
    this.refundSubFormValueChange();
  }

  updateValueChangesEventForm() {
    this.profitSharingForm.controls['cigaretteVatAdjustmentAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateGrossProfitAndProfitSharing();
      });
    this.profitSharingForm.controls['compensateAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateGrossProfitAndProfitSharing();
      });

    if (this.templateVersion === 2) {
      this.profitSharingForm.controls['advancePaymentAmount'].valueChanges
        .pipe(untilComponentDestroyed(this))
        .subscribe(() => {
          this.calculateAdministrativeExpenses();
        });
    }

    this.profitSharingForm.controls['withHoldingTaxAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm
      .get('lessCashTransfer')
      .get('totalAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm.controls['storeUseAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm
      .get('stockLossAdjustment')
      .get('totalAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm.controls['destroyAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm
      .get('netPenalty')
      .get('totalAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm
      .get('otherExpense')
      .get('totalAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm
      .get('refund')
      .get('totalAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherIncomeTotal();
      });
    this.profitSharingForm.controls['incentiveAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherIncomeTotal();
      });
    this.profitSharingForm
      .get('otherIncome')
      .get('totalAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherIncomeTotal();
      });

    this.lessCashTransferSubFormValueChange();
    this.stockLossAdjustSubFormValueChange();
    this.netPenaltySubFormValueChange();
    this.otherIncomeSubFormValueChange();
  }

  lessCashTransferSubFormValueChange() {
    this.profitSharingForm
      .get('lessCashTransfer')
      .get('lessCashTransferAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateCashTransferSubForm();
      });

    this.profitSharingForm
      .get('lessCashTransfer')
      .get('stockLossInstallmentAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateCashTransferSubForm();
      });

    this.profitSharingForm
      .get('lessCashTransfer')
      .get('billPaymentInstallmentAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateCashTransferSubForm();
      });

    this.profitSharingForm
      .get('lessCashTransfer')
      .get('negativeProfitSharingAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateCashTransferSubForm();
      });

    this.profitSharingForm
      .get('lessCashTransfer')
      .get('advancePaymentAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateCashTransferSubForm();
      });

    this.profitSharingForm
      .get('lessCashTransfer')
      .get('otherLessCashTransferAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateCashTransferSubForm();
      });
  }

  stockLossAdjustSubFormValueChange() {
    this.icLostAmount.valueChanges.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.calculateStockLossAdjustmentSubForm();
    });

    this.icLostPaidAmount.valueChanges.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.calculateStockLossAdjustmentSubForm();
    });

    this.icLostNextBillAmount.valueChanges.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.calculateStockLossAdjustmentSubForm();
    });
  }

  netPenaltySubFormValueChange() {
    this.profitSharingForm
      .get('netPenalty')
      .get('latePaymentAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateNetPenaltySubForm();
      });

    this.profitSharingForm
      .get('netPenalty')
      .get('toteFineAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateNetPenaltySubForm();
      });

    this.profitSharingForm
      .get('netPenalty')
      .get('otherPenaltyAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateNetPenaltySubForm();
      });
  }

  otherExpenseSubFormValueChange() {
    this.profitSharingForm
      .get('otherExpense')
      .get('internetAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherExpenseSubForm();
      });

    this.profitSharingForm
      .get('otherExpense')
      .get('assetRepairAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherExpenseSubForm();
      });

    this.profitSharingForm
      .get('otherExpense')
      .get('otherExpenseAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherExpenseSubForm();
      });
  }

  refundSubFormValueChange() {
    this.profitSharingForm
      .get('refund')
      .get('overCashTransferAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateRefundSubForm();
      });

    this.profitSharingForm
      .get('refund')
      .get('refundAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateRefundSubForm();
      });

    this.profitSharingForm
      .get('refund')
      .get('appealForFineAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateRefundSubForm();
      });

    this.profitSharingForm
      .get('refund')
      .get('appealForStockLossAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateRefundSubForm();
      });

    this.profitSharingForm
      .get('refund')
      .get('otherRefundAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateRefundSubForm();
      });
  }

  otherIncomeSubFormValueChange() {
    this.profitSharingForm
      .get('otherIncome')
      .get('marketingSupportAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherIncomeSubForm();
      });

    this.profitSharingForm
      .get('otherIncome')
      .get('withHoldingTaxCorpAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherIncomeSubForm();
      });

    this.profitSharingForm
      .get('otherIncome')
      .get('otherIncomeAmount')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherIncomeSubForm();
      });
  }

  calculateCashTransferSubForm() {
    const lessCashTransferAmount = this.profitSharingForm.get('lessCashTransfer').get('lessCashTransferAmount').value;
    const stockLossInstallmentAmount = this.profitSharingForm
      .get('lessCashTransfer')
      .get('stockLossInstallmentAmount').value;
    const billPaymentInstallmentAmount = this.profitSharingForm
      .get('lessCashTransfer')
      .get('billPaymentInstallmentAmount').value;
    const negativeProfitSharingAmount = this.profitSharingForm
      .get('lessCashTransfer')
      .get('negativeProfitSharingAmount').value;
    const advancePaymentAmount = this.profitSharingForm.get('lessCashTransfer').get('advancePaymentAmount').value;
    const otherLessCashTransferAmount = this.profitSharingForm
      .get('lessCashTransfer')
      .get('otherLessCashTransferAmount').value;

    const totalAmount = DecimalJsUtil.round(
      DecimalJsUtil.add(
        lessCashTransferAmount,
        stockLossInstallmentAmount,
        billPaymentInstallmentAmount,
        negativeProfitSharingAmount,
        advancePaymentAmount,
        otherLessCashTransferAmount
      )
    );
    this.profitSharingForm
      .get('lessCashTransfer')
      .get('totalAmount')
      .setValue(totalAmount);
  }

  calculateStockLossAdjustmentSubForm() {
    const stockLossAdjustmentTotal1 = DecimalJsUtil.minus(this.icLostAmount.value, this.icLostPaidAmount.value);
    const stockLossAdjustmentTotal2 = DecimalJsUtil.round(
      DecimalJsUtil.minus(stockLossAdjustmentTotal1, this.icLostNextBillAmount.value)
    );

    const paidAndNextBill = DecimalJsUtil.round(
      DecimalJsUtil.add(this.icLostPaidAmount.value, this.icLostNextBillAmount.value)
    );

    this.profitSharingForm
      .get('stockLossAdjustment')
      .get('totalAmount')
      .setValue(stockLossAdjustmentTotal2);

    if (paidAndNextBill > this.icLostAmount.value) {
      this.icLostAmount.setErrors({ invalid: true });
      this.icLostPaidAmount.setErrors({ invalid: true });
      this.icLostNextBillAmount.setErrors({ invalid: true });

      this.profitSharingForm
        .get('stockLossAdjustment')
        .get('totalAmount')
        .setErrors({ invalid: true });
    } else {
      this.icLostAmount.setErrors(null);
      this.icLostPaidAmount.setErrors(null);
      this.icLostNextBillAmount.setErrors(null);

      this.profitSharingForm
        .get('stockLossAdjustment')
        .get('totalAmount')
        .setErrors(null);
    }

    this.setStocklossIsRequired();
  }

  calculateNetPenaltySubForm() {
    const latePaymentAmount = this.profitSharingForm.get('netPenalty').get('latePaymentAmount').value;
    const toteFineAmount = this.profitSharingForm.get('netPenalty').get('toteFineAmount').value;
    const otherPenaltyAmount = this.profitSharingForm.get('netPenalty').get('otherPenaltyAmount').value;

    const totalAmount = DecimalJsUtil.round(DecimalJsUtil.add(latePaymentAmount, toteFineAmount, otherPenaltyAmount));
    this.profitSharingForm
      .get('netPenalty')
      .get('totalAmount')
      .setValue(totalAmount);
  }

  calculateOtherExpenseSubForm() {
    const internetAmount = this.profitSharingForm.get('otherExpense').get('internetAmount').value;
    const assetRepairAmount = this.profitSharingForm.get('otherExpense').get('assetRepairAmount').value;
    const otherExpenseAmount = this.profitSharingForm.get('otherExpense').get('otherExpenseAmount').value;

    const totalAmount = DecimalJsUtil.round(DecimalJsUtil.add(internetAmount, assetRepairAmount, otherExpenseAmount));
    this.profitSharingForm
      .get('otherExpense')
      .get('totalAmount')
      .setValue(totalAmount);
  }

  calculateRefundSubForm() {
    const overCashTransferAmount = this.profitSharingForm.get('refund').get('overCashTransferAmount').value;
    const refundAmount = this.profitSharingForm.get('refund').get('refundAmount').value;
    const appealForFineAmount = this.profitSharingForm.get('refund').get('appealForFineAmount').value;
    const appealForStockLossAmount = this.profitSharingForm.get('refund').get('appealForStockLossAmount').value;
    const otherRefundAmount = this.profitSharingForm.get('refund').get('otherRefundAmount').value;

    const totalAmount = DecimalJsUtil.round(
      DecimalJsUtil.add(
        overCashTransferAmount,
        refundAmount,
        appealForFineAmount,
        appealForStockLossAmount,
        otherRefundAmount
      )
    );
    this.profitSharingForm
      .get('refund')
      .get('totalAmount')
      .setValue(totalAmount);
  }

  calculateOtherIncomeSubForm() {
    const marketingSupportAmount = this.profitSharingForm.get('otherIncome').get('marketingSupportAmount').value;
    const withHoldingTaxCorpAmount = this.profitSharingForm.get('otherIncome').get('withHoldingTaxCorpAmount').value;
    const otherIncomeAmount = this.profitSharingForm.get('otherIncome').get('otherIncomeAmount').value;

    const totalAmount = DecimalJsUtil.round(
      DecimalJsUtil.add(marketingSupportAmount, withHoldingTaxCorpAmount, otherIncomeAmount)
    );
    this.profitSharingForm
      .get('otherIncome')
      .get('totalAmount')
      .setValue(totalAmount);
  }

  calculateAdministrativeExpenses() {
    // const administrativeExpensesAmount =
    //   this.profitSharingForm.controls.withHoldingTaxAmount.value +
    //   this.profitSharingForm.get('lessCashTransfer').get('totalAmount').value +
    //   this.profitSharingForm.controls.storeUseAmount.value +
    //   this.profitSharingForm.get('stockLossAdjustment').get('totalAmount').value +
    //   this.profitSharingForm.controls.destroyAmount.value +
    //   this.profitSharingForm.get('netPenalty').get('totalAmount').value +
    //   this.profitSharingForm.get('otherExpense').get('totalAmount').value;

    const administrativeExpensesAmount = DecimalJsUtil.add(
      this.profitSharingForm.controls.withHoldingTaxAmount.value,
      this.profitSharingForm.get('lessCashTransfer').get('totalAmount').value,
      this.profitSharingForm.controls.storeUseAmount.value,
      this.profitSharingForm.get('stockLossAdjustment').get('totalAmount').value,
      this.profitSharingForm.controls.destroyAmount.value,
      this.profitSharingForm.get('netPenalty').get('totalAmount').value,
      this.profitSharingForm.get('otherExpense').get('totalAmount').value,
      this.profitSharingForm.controls.advancePaymentAmount.value
    );

    this.profitSharing = clone(this.profitSharing);
    this.profitSharing.totalOtherExpense = DecimalJsUtil.round(administrativeExpensesAmount);
    this.calculateProfitSharing();
  }

  calculateOtherIncomeTotal() {
    const otherIncomeAmount = DecimalJsUtil.add(
      this.profitSharingForm.get('refund').get('totalAmount').value,
      this.profitSharingForm.controls.incentiveAmount.value,
      this.profitSharingForm.get('otherIncome').get('totalAmount').value
    );

    this.profitSharing.totalOtherIncome = DecimalJsUtil.round(otherIncomeAmount);
    this.calculateProfitSharing();
  }

  calculateGrossProfitAndProfitSharing() {
    const grossProfit1 = DecimalJsUtil.minus(
      this.profitSharing.salesIncVatAmount,
      this.profitSharing.cogsIncVatAmount
    );

    const grossProfit2 = DecimalJsUtil.add(grossProfit1, this.profitSharingForm.controls.compensateAmount.value);
    const grossProfit3 = DecimalJsUtil.minus(
      grossProfit2,
      this.profitSharingForm.controls.cigaretteVatAdjustmentAmount.value
    );

    const grossProfitIncVatAmount = DecimalJsUtil.round(
      DecimalJsUtil.minus(grossProfit3, this.profitSharing.refundGrossProfitIncVatAmount)
    );

    this.profitSharing.grossProfitIncVatAmount = grossProfitIncVatAmount;

    const grossProfitExcVatAmount = DecimalJsUtil.minus(
      DecimalJsUtil.add(
        DecimalJsUtil.minus(this.profitSharing.salesExcVatAmount, this.profitSharing.cogsExcVatAmount),
        this.profitSharingForm.controls.compensateAmount.value
      ),
      DecimalJsUtil.add(
        DecimalJsUtil.minus(this.profitSharing.refundExcVatAmount, this.profitSharing.refundCogsExcVatAmount),
        this.profitSharing.refundCompensateAmount
      )
    );
    //
    this.profitSharing.grossProfitExcVatAmount = grossProfitExcVatAmount;

    // share to TD
    const shareToTDExcVatAmount = Number(DecimalJsUtil.multiply(grossProfitExcVatAmount, 0.15));

    const shareToTDVatAmount = DecimalJsUtil.round(
      DecimalJsUtil.divide(DecimalJsUtil.multiply(DecimalJsUtil.round(shareToTDExcVatAmount), this.vatPct), 100)
    );

    this.profitSharing.shareToTDExcVatAmount = DecimalJsUtil.round(shareToTDExcVatAmount);
    this.profitSharing.shareToTDVatAmount = DecimalJsUtil.round(shareToTDVatAmount);
    const shareToTDIncVatAmount = DecimalJsUtil.add(
      this.profitSharing.shareToTDExcVatAmount,
      this.profitSharing.shareToTDVatAmount
    );
    this.profitSharing.shareToTDIncVatAmount = DecimalJsUtil.round(shareToTDIncVatAmount);

    // grossProfitAfterShareAmount
    const grossProfitAfterShareAmount = DecimalJsUtil.minus(
      this.profitSharing.grossProfitIncVatAmount,
      this.profitSharing.shareToTDIncVatAmount
    );
    this.profitSharing.grossProfitAfterShareAmount = DecimalJsUtil.round(grossProfitAfterShareAmount);

    this.calculateProfitSharing();
  }

  calculateProfitSharing() {
    const profitSharingAmount1 = DecimalJsUtil.minus(
      this.profitSharing.grossProfitAfterShareAmount,
      this.profitSharing.totalOtherExpense
    );
    const profitSharingAmount = DecimalJsUtil.round(
      DecimalJsUtil.add(profitSharingAmount1, this.profitSharing.totalOtherIncome)
    );

    this.profitSharing.profitSharingAmount = profitSharingAmount > 0 ? profitSharingAmount : 0;
    this.profitSharing.remainingBalanceAmount = profitSharingAmount < 0 ? profitSharingAmount * -1 : 0;
    this.profitSharing.profitStatus =
      profitSharingAmount >= 0 ? ProfitSharingStatusEnum.POSITIVE : ProfitSharingStatusEnum.NEGATIVE;
  }

  toggleToEditMode() {
    const isEndOfPeriod = moment(this.profitSharing.lastAllowEditedDate).isBefore(moment().utc());
    if (isEndOfPeriod) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'Not allow to edit. This period has been closed.'
        }
      });
      return;
    }

    this.data.mode = ProfitSharingModeEnum.EDIT;
    this.data.title = 'Edit Profit Sharing';
    this.editModeForm();
    this.switchLanguage(false);
  }

  editModeForm() {
    this.profitSharingForm.enable();
    this.profitSharingForm
      .get('lessCashTransfer')
      .get('totalAmount')
      .disable();
    this.profitSharingForm
      .get('stockLossAdjustment')
      .get('totalAmount')
      .disable();
    this.profitSharingForm
      .get('netPenalty')
      .get('totalAmount')
      .disable();
    this.profitSharingForm
      .get('otherExpense')
      .get('totalAmount')
      .disable();
    this.profitSharingForm
      .get('refund')
      .get('totalAmount')
      .disable();
    this.profitSharingForm
      .get('otherIncome')
      .get('totalAmount')
      .disable();
  }

  onCancel() {
    this.onCloseFullModal();
  }

  onSubmit() {
    this.isSubmitted = true;
    this.setStocklossIsRequired();

    if (this.profitSharingForm.invalid) {
      this.onSubmitSlide('lessCashTransfer');
      this.onSubmitSlide('stockLossAdjustment');
      this.onSubmitSlide('netPenalty');
      this.onSubmitSlide('otherExpense');
      this.onSubmitSlide('refund');
      this.onSubmitSlide('otherIncome');

      return;
    }

    if (this.isNoValueChanged()) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'No data have been edited.'
        }
      });
      return;
    }

    this.handleConfirm();
  }

  setStocklossIsRequired() {
    if (this.icLostAmount.value === null) {
      this.icLostAmount.setErrors({ required: true });
    }

    if (this.icLostPaidAmount.value === null) {
      this.icLostPaidAmount.setErrors({ required: true });
    }

    if (this.icLostNextBillAmount.value === null) {
      this.icLostNextBillAmount.setErrors({ required: true });
    }
  }

  onSubmitSlide(formGroupName: string) {
    if (this.profitSharingForm.get(formGroupName).invalid && !this.expandedTypeList.includes(formGroupName)) {
      this.expandedTypeList.push(formGroupName);
    }
  }

  isNoValueChanged() {
    const loadedValue = {
      cigaretteVatAdjustmentAmount: this.profitSharing.cigaretteVatAdjustmentAmount,
      destroyAmount: this.profitSharing.destroyAmount,
      incentiveAmount: this.profitSharing.incentiveAmount,
      lessCashTransferAmount: this.profitSharing.lessCashTransfer.totalAmount,
      netPenaltyAmount: this.profitSharing.netPenalty.totalAmount,
      otherExpenseAmount: this.profitSharing.otherExpense.totalAmount,
      otherIncomeAmount: this.profitSharing.otherIncome.totalAmount,
      overCashTransferAmount: this.profitSharing.refund.totalAmount,
      stockLossAdjustmentAmount: this.profitSharing.stockLossAdjustment.totalAmount,
      icLostAmount: this.profitSharing.stockLossAdjustment.icLostAmount,
      icLostNextBillAmount: this.profitSharing.stockLossAdjustment.icLostNextBillAmount,
      icLostPaidAmount: this.profitSharing.stockLossAdjustment.icLostPaidAmount,
      storeUseAmount: this.profitSharing.storeUseAmount,
      withHoldingTaxAmount: this.profitSharing.withHoldingTaxAmount,
      compensateAmount: this.profitSharing.compensateAmount,
      advancePaymentAmount: this.profitSharing.advancePaymentAmount
    };

    const currentValue = {
      cigaretteVatAdjustmentAmount: this.profitSharingForm.get('cigaretteVatAdjustmentAmount').value,
      destroyAmount: this.profitSharingForm.get('destroyAmount').value,
      incentiveAmount: this.profitSharingForm.get('incentiveAmount').value,
      lessCashTransferAmount: this.profitSharingForm.get('lessCashTransfer').get('totalAmount').value,
      netPenaltyAmount: this.profitSharingForm.get('netPenalty').get('totalAmount').value,
      otherExpenseAmount: this.profitSharingForm.get('otherExpense').get('totalAmount').value,
      otherIncomeAmount: this.profitSharingForm.get('otherIncome').get('totalAmount').value,
      overCashTransferAmount: this.profitSharingForm.get('refund').get('totalAmount').value,
      stockLossAdjustmentAmount: this.profitSharingForm.get('stockLossAdjustment').get('totalAmount').value,
      icLostAmount: this.profitSharingForm.get('stockLossAdjustment').get('icLostAmount').value,
      icLostNextBillAmount: this.profitSharingForm.get('stockLossAdjustment').get('icLostNextBillAmount').value,
      icLostPaidAmount: this.profitSharingForm.get('stockLossAdjustment').get('icLostPaidAmount').value,
      storeUseAmount: this.profitSharingForm.get('storeUseAmount').value,
      withHoldingTaxAmount: this.profitSharingForm.get('withHoldingTaxAmount').value,
      compensateAmount: this.profitSharingForm.get('compensateAmount').value,
      advancePaymentAmount: this.profitSharingForm.get('advancePaymentAmount').value
    };

    return isEqual(loadedValue, currentValue);
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const payload = this.prepareProfitSharingData();

          this.store.dispatch(new ProfitSharingSubmitRequestAction(payload));
        }
      });
  }

  prepareProfitSharingData() {
    return { ...this.profitSharing, ...this.profitSharingForm.getRawValue() };
  }

  get isViewMode(): boolean {
    return [ProfitSharingModeEnum.VIEW].includes(this.data.mode);
  }

  get icLostAmount() {
    return this.profitSharingForm.get('stockLossAdjustment').get('icLostAmount');
  }

  get icLostPaidAmount() {
    return this.profitSharingForm.get('stockLossAdjustment').get('icLostPaidAmount');
  }

  get icLostNextBillAmount() {
    return this.profitSharingForm.get('stockLossAdjustment').get('icLostNextBillAmount');
  }

  onPrintProfitSharing() {
    if (!this.profitSharing.docNo) {
      return;
    }
    const param: ProfitSharingPrintCriteria = {
      docNo: this.profitSharing.docNo,
      runDate: this.profitSharing.schedule
        .split('/')
        .reverse()
        .join('')
    };

    this.profitSharingService.printProfitSharingPdf(param).subscribe({
      next: response => {
        const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, generatedFilenamePdf(this.profitSharing.docNo));
      },
      error: error => {
        if (error.error.code === '00004') {
          this.modalService.show(AlertModalComponent, {
            initialState: {
              title: 'Failed',
              message: 'File not found.'
            }
          });
        }
      }
    });
  }

  toggleExpandableType(type) {
    if (this.expandedTypeList.includes(type)) {
      const index = this.expandedTypeList.indexOf(type);
      this.expandedTypeList.splice(index, 1);
    } else {
      this.expandedTypeList.push(type);
    }
  }

  isExpandedType(type) {
    return this.expandedTypeList.includes(type);
  }

  showHistory() {
    const initialState = {
      title: 'History',
      action: HistoryType.REQUEST,
      historyHeader: `Profit Sharing No.: ${this.profitSharing.docNo}`,
      historyType: HistoryType.PROFIT_SHARING,
      auditLogs: this.profitSharing.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }
}
