import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { Customer360Content, Customer360SearchCriteria } from '../../models/customer360.model';
import { DataInsightPreviewDataResponse, DataInsightViewResponse } from '../../models/data-insight.model';
import { ErrorResponse } from '../../models/error.model';
import { Customer360ActionTypes, Customer360Actions } from '../actions/customer360.action';

export interface Customer360State extends EntityState<Customer360Content> {
  isSave?: boolean | null;
  selected: DataInsightViewResponse;
  criteriaObject: Customer360SearchCriteria;
  totalElements: number;
  totalPages: number;
  previewResponse: DataInsightPreviewDataResponse;
  errorResponse: ErrorResponse;
}

export const adapter: EntityAdapter<Customer360Content> = createEntityAdapter<Customer360Content>({
  selectId: list => list.id
});

export const initialCustomer360State: Customer360State = adapter.getInitialState({
  isSave: null,
  selected: null,
  criteriaObject: {
    searchCriteria: null,
    viewType: null,
    createdDateFrom: null,
    createdDateTo: null,
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  previewResponse: {
    content: [],
    page: 1,
    size: 20,
    totalElements: 0,
    totalPages: 1
  },
  errorResponse: null
});

export function customer360Reducers(state = initialCustomer360State, action: Customer360Actions): Customer360State {
  switch (action.type) {
    case Customer360ActionTypes.CUSTOMER360_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case Customer360ActionTypes.CUSTOMER360_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case Customer360ActionTypes.CUSTOMER360_PREVIEW_RESPONSE:
      return {
        ...state,
        previewResponse: action.payload
      };
    case Customer360ActionTypes.CUSTOMER360_PREVIEW_RESET:
      return {
        ...state,
        previewResponse: initialCustomer360State.previewResponse
      };
    case Customer360ActionTypes.CUSTOMER360_SAVE_NEW_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSave: action.payload
      };
    case Customer360ActionTypes.CUSTOMER360_SAVE_NEW_TEMPLATE_ERROR:
      return {
        ...state,
        errorResponse: action.payload
      };
    case Customer360ActionTypes.CUSTOMER360_SAVE_NEW_TEMPLATE_RESET:
      return {
        ...state,
        errorResponse: null
      };
    case Customer360ActionTypes.CUSTOMER360_GET_TEMPLATE_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case Customer360ActionTypes.CUSTOMER360_GET_TEMPLATE_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll } = adapter.getSelectors();
