export enum ManageStockPageModes {
  ADJUST_STOCK = 'ADJUST_STOCK',
  ADJUST_STOCK_VIEW = 'ADJUST_STOCK_VIEW'
}

export enum MovementType {
  ADJUST_STOCK = 'ADJUST_STOCK'
}

export enum LocationTypeEnum {
  WAREHOUSE = 'WAREHOUSE',
  STORE = 'STORE'
}

export enum RequestAdjustItemErrorEnum {
  INVALID_ARTICLE = 'INVALID_ARTICLE',
  DUPLICATED_ARTICLE_FIELD = 'DUPLICATED_ARTICLE_FIELD',
  NOT_ALLOW_ZERO = 'NOT_ALLOW_ZERO',
  NOT_ALLOW_STORE_USE = 'NOT_ALLOW_STORE_USE',
  NOT_ALLOW_FIX_ASSET = 'NOT_ALLOW_FIX_ASSET',
  NOT_INSUFFICIENT_STOCK = 'NOT_INSUFFICIENT_STOCK',
  FRESH_LITE_ITEM = 'FRESH_LITE_ITEM',
  FRESH_LITE_EXPENSE_ITEM = 'FRESH_LITE_EXPENSE_ITEM',
  INVALID_DOCUMENT_TYPE = 'INVALID_DOCUMENT_TYPE',
  INVALID_STORE_CODE = 'INVALID_STORE_CODE',
  INVALID_REASON_REF_NO = 'INVALID_REASON_REF_NO',
  INVALID_DOCUMENT_NUMBER = 'INVALID_DOCUMENT_NUMBER',
  INVALID_ARTICLE_NO_REF_DOC = 'INVALID_ARTICLE_NO_REF_DOC'
}

export enum AdjustStockStatusEnum {
  NEW = 'New',
  COMPLETED = 'Completed',
  FAILED = 'Failed'
}

export enum AdjustTypeEnum {
  RECEIVE_ORDER = 'RECEIVE_ORDER',
  INVENTORY_COUNT = 'INVENTORY_COUNT',
  PURCHASE = 'PURCHASE',
  DESTROY = 'DESTROY',
  CLAIM = 'CLAIM',
  TRANSFER = 'TRANSFER',
  VOID_RECEIPT = 'VOID_RECEIPT',
  RETURN_TO_SUPPLIER = 'RETURN_TO_SUPPLIER',
  INTERNAL_USE = 'INTERNAL_USE',
  RETURN_TO_WAREHOUSE = 'RETURN_TO_WAREHOUSE',
  WAREHOUSE_SALE = 'WAREHOUSE_SALE'
}

export enum ImportAdjustUploadStatusEnum {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}
