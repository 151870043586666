import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NewRequestStatusEnum } from '../enum/request-status.enum';
import {
  Merchant,
  MerchantListResponse,
  MerchantListSearchCriteria,
  MerchantStore,
  MerchantViewResponse,
  SignedUrlObject,
  StoreList,
  StoreListSearchByName,
  StoreListSearchCriteria
} from '../models';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';

@Injectable()
export class MerchantService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.merchant;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchMerchantByCriteria(criteria: MerchantListSearchCriteria): Observable<MerchantListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.MERCHANT,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public getMerchantById(merchant: Merchant): Observable<MerchantViewResponse> {
    const url = this.getFullUrl(this.envService.viewMerchant, {
      merchant: merchant.merchant
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public generateUser(merchant: Merchant): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.generateUser, {
      merchant: merchant.merchant
    });
    return this.http.put<any>(url, null, { headers: this.headers });
  }

  public addResetOwnerLog(merchant: Merchant): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.addResetOwnerLog, {
      merchant: merchant.merchant
    });
    return this.http.put<any>(url, null, { headers: this.headers });
  }

  public searchStoreByCriteria(criteria: StoreListSearchCriteria): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.MERCHANT,
          route: this.envService.allStore,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getFullUrl(this.envService.allStore);
    const params = this.getParams(criteria);

    return this.http
      .get<any>(url, {
        headers: this.loaderHeaders(null, metricsReq),
        observe: 'body',
        params
      })
      .pipe(
        map(res => {
          if (res && res.content) {
            res.content.forEach(item => {
              item.storeCodeName = `${item.code}-${item.name}`;
            });
          }
          return res;
        })
      );
  }

  public searchStoreByName(name: string, warehouse?: string, isAllStatus = false) {
    const param = {
      searchCriteria: name,
      ...(!isAllStatus && { status: NewRequestStatusEnum.ACTIVE }),
      warehouse
    } as StoreListSearchByName;

    const url = this.getFullUrl(this.envService.allStore);
    const params = this.getParams(param);

    return this.http
      .get<any>(url, {
        headers: this.headers,
        observe: 'body',
        params
      })
      .pipe(
        map(res => {
          return this.getStoreResult(res);
        })
      );
  }

  public searchStoreByMerchant(merchant: string, criteria: StoreListSearchCriteria): Observable<StoreList[]> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.MERCHANT,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getFullUrl(this.envService.listStore, { merchant });
    const params = this.getParams(criteria);

    return this.http
      .get<any>(url, {
        headers: this.loaderHeaders(null, metricsReq),
        observe: 'body',
        params
      })
      .pipe(
        map(res => {
          return this.getStoreResult(res);
        })
      );
  }

  public getFileUrl(refId: string): Observable<SignedUrlObject> {
    const url = this.getFullUrl(this.envService.fileUrl);
    return this.http.post<SignedUrlObject>(url, { refId }, { headers: this.headers });
  }

  public getHistoryLogs(merchant: Merchant): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      merchant: merchant.merchant
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getStore(merchant: string, storeNo: string): Observable<MerchantStore> {
    const url = this.getFullUrl(this.envService.getStore, { merchant });

    const params = this.getParams({ storeNo });
    return this.http
      .get<any>(url, { headers: this.headers, observe: 'body', params })
      .pipe(map(data => data && data.content && data.content[0]));
  }

  private getStoreResult(res: any) {
    const items = [];
    if (res && res.content) {
      res.content.forEach(item => {
        items.push({ ...item, display: `${item.code}-${item.name}` });
      });
    }
    return items;
  }
}
