import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { PreOrderTransactionReportCriteria } from '../models/report.model';
import { metricsReporting } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable()
export class PreOrderRequestsService extends BaseService {
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.preOrderRequests;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public headers: HttpHeaders;
  public env = environment;
  public envService: any;

  public exportPreOrderAccounting(exportCriteria: PreOrderTransactionReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.accountingExport);
    const metrics = metricsReporting([this.envService.url, this.envService.accountingExport]);

    delete exportCriteria.exportBy;

    return this.http.post<any>(url, exportCriteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
