import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment as env } from '../../../environments/environment';
import { AuthRequest } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class AuthService extends BaseService {
  private readonly SPINNER_NAME: string = 'x-frontend-spinner-loader';
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = env.services.auth;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public logIn(payload: AuthRequest): Observable<any> {
    const body = new HttpParams()
      .set('username', payload.username)
      .set('password', payload.password)
      .set('grant_type', payload.grant_type);

    this.headers = this.headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post<any>(this.getUrl(), body, { headers: this.headers });
  }

  public refreshToken(refreshToken: string, request: HttpRequest<any> = null): Observable<any> {
    const body = new HttpParams().set('refresh_token', refreshToken).set('grant_type', 'refresh_token');

    this.headers = this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    if (request?.headers.has(this.SPINNER_NAME)) {
      this.headers = this.headers.set(this.SPINNER_NAME, request.headers.get(this.SPINNER_NAME));
    }

    return this.http.post<any>(this.getUrl(), body, { headers: this.headers });
  }

  public bypassAuthenToken<T>(): Observable<T> {
    const body = new HttpParams().set('grant_type', 'client_credentials');

    return this.http.post<T>(this.getUrl(), body, { headers: this.headers });
  }
}
