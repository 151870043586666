import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { TippyModule } from 'ng-tippy';
import { InputTrimModule } from 'ng2-trim-directive';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TagInputModule } from 'ngx-chips';
import { MomentModule } from 'ngx-moment';
import { QuillModule } from 'ngx-quill';

import { BoxsDisplayComponent } from '@shared/components/boxs-display/boxs-display.component';
import { FileSubmitWithTypeComponent } from '@shared/components/file-submit-with-type/file-submit-with-type.component';
import { FilesUploadComponent } from '@shared/components/files-upload/files-upload.component';
import { GetExcelDataComponent } from '@shared/components/get-excel-data/get-excel-data.component';
import { MultipleFilesUploadComponent } from '@shared/components/multiple-files-upload/multiple-files-upload.component';
import { SearchArticleModalComponent } from '@shared/components/search-article-modal/search-article-modal.component';
import { SearchBarcodeModalComponent } from '@shared/components/search-barcode-modal/search-barcode-modal.component';
import { SearchMultipleArticleModalComponent } from '@shared/components/search-multiple-article-modal/search-multiple-article-modal.component';
import { SearchProductModalComponent } from '@shared/components/search-product-modal/search-product-modal.component';
import { DeliveryTimesComponent } from '@shared/components/td-store/store-profile/delivery-times/delivery-times.component';
import { NearByCompetitorComponent } from '@shared/components/td-store/store-profile/near-by-competitor/near-by-competitor.component';
import { NearByPoiComponent } from '@shared/components/td-store/store-profile/near-by-poi/near-by-poi.component';
import { StoreProfileComponent } from '@shared/components/td-store/store-profile/store-profile.component';
import { UploadDocumentButtonComponent } from '@shared/components/upload-document-button/upload-document-button.component';
import { AppSecureSrcDirective } from '@shared/directives/app-secure-src.directive';
import { PipesModule } from '@shared/pipes/pipes.module';

import { AdvancedSearchTagsComponent } from './components/advanced-search-tags/advanced-search-tags.component';
import { AlertModalTemplateModule } from './components/alert-modal-template/alert-modal-template.module';
import { AssortmentCjProductDetailsComponent } from './components/assortment-cj-product-details/assortment-cj-product-details.component';
import { BaseModalExportComponent } from './components/base-modal-export/base-modal-export.component';
import { ClickToCopyComponent } from './components/click-to-copy/click-to-copy.component';
import { DashboardBoxesComponent } from './components/dashboard-boxes/dashboard-boxes.component';
import { DropdownCustomTemplateSelectComponent } from './components/dropdown-custom-template-select/dropdown-custom-template-select.component';
import { DropdownMultipleSelectComponent } from './components/dropdown-multiple-select/dropdown-multiple-select.component';
import { DropdownPagingMultipleSelectComponent } from './components/dropdown-paging-multiple-select/dropdown-paging-multiple-select.component';
import { DynamicFormModule } from './components/dynamic-form/dynamic-form.module';
import { DynamicReportModalModule } from './components/dynamic-report-modal/dynamic-report-modal.module';
import { FileSubmitBeforeUploadComponent } from './components/file-submit-before-upload/file-submit-before-upload.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FilesInputComponent } from './components/files-input/files-input.component';
import { FilesSubmitValidateComponent } from './components/files-submit-validate/files-submit-validate.component';
import { ImportExportButtonComponent } from './components/import-export-button/import-export-button.component';
import { InputPostfixComponent } from './components/input-postfix/input-postfix.component';
import { NumericTextboxComponent } from './components/money-textbox/numeric-textbox.component';
import { NgxIntlTelInputModule } from './components/ngx-intl-tel-input/ngx-intl-tel-input.module';
import { NoSearchResultComponent } from './components/no-search-result/no-search-result.component';
import { ProductViewHeaderComponent } from './components/product-view-header/product-view-header.component';
import { ReferenceDocumentLinkModule } from './components/reference-document-link/reference-document-link.module';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { TagUpdatedComponent } from './components/tag-updated/tag-updated.component';
import { LegalDocumentComponent } from './components/td-store/legal-document/legal-document.component';
import { MerchantProfileComponent } from './components/td-store/merchant-profile/merchant-profile.component';
import { OrderPolicyScheduleComponent } from './components/td-store/order-policy/order-policy-schedule/order-policy-schedule.component';
import { OrderPolicyComponent } from './components/td-store/order-policy/order-policy.component';
import { PreOrderPolicyComponent } from './components/td-store/order-policy/pre-order-policy/pre-order-policy.component';
import { PreOrderScheduleComponent } from './components/td-store/order-policy/pre-order-schedule/pre-order-schedule.component';
import { OwnerProfileComponent } from './components/td-store/owner-profile/owner-profile.component';
import { StoreConditionComponent } from './components/td-store/store-condtion/store-condition.component';
import { PhoneContactComponent } from './components/td-store/store-contact/phone-contact/phone-contact.component';
import { StoreContactComponent } from './components/td-store/store-contact/store-contact.component';
import { StoreLocationComponent } from './components/td-store/store-location/store-location.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { UploadAttachmentsComponent } from './components/upload-attachments/upload-attachments.component';
import { ValidatorsMessageModule } from './components/validators-message/validators-message.module';
import { VatDetailsModalComponent } from './components/vat-details-modal/vat-details-modal.component';
import { AlphabetOnlyDirective } from './directives/alphabet-only.directive';
import { AlphanumericOnlyDirective } from './directives/alphanumeric-only.directive';
import { AppAdsSrcDirective } from './directives/app-ads-src.directive';
import { AppSrcDirective } from './directives/app-src.directive';
import { AppStoreSecureSrcDirective } from './directives/app-store-secure-src.directive';
import { AppTaskAssignmentSecureSrcDirective } from './directives/app-task-assignment-secure-src.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ClickWithOutDragDirective } from './directives/click-with-out-darg.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import { DisableButtonDirective } from './directives/disable-button.directive';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { OptionHiglightModule } from './directives/option-higlight/option-higlight.module';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { RemoveUnicodeDirective } from './directives/remove-unicode.directive';
import { ScrollHorizontalDirective } from './directives/scroll-horizontal.directive';
import { ConfirmModalComponent } from './layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from './layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ConfirmWithRefNoModalComponent } from './layouts/modals/confirm-with-ref-no-modal/confirm-with-ref-no-modal.component';
import { ChildDirective } from './layouts/modals/full-modal/child-directive';
import { FullModalComponent } from './layouts/modals/full-modal/full-modal.component';
import { NoteModalComponent } from './layouts/modals/note-modal/note-modal.component';
import { SearchPaginationComponent } from './layouts/search-pagination/search-pagination.component';
import { TabComponent } from './layouts/tab/tab.component';
import { UiPaginationComponent } from './layouts/ui-pagination/ui-pagination.component';
import { ArraySortPipe } from './pipes/array-sort.pipe';
import { DashDisplayPipe } from './pipes/dash-display.pipe';
import { DateDisplayPipe } from './pipes/date-display.pipe';
import { DaysDisplayModule } from './pipes/days-display/days-display.module';
import { MasterDataDisplayPipe } from './pipes/master-data-display.pipe';
import { MonthsDisplayPipe } from './pipes/months-display/months-display.pipe';
import { NumberFormatDisplayPipe } from './pipes/number-display.pipe';
import { BooleanDisplayPipe, NameThDisplayPipe, StockQuantityPipe } from './pipes/Pipes';
import { PureFnModule } from './pipes/pure-fn/pure-fn.module';
import { SafePipe } from './pipes/safe.pipe';
import { SafeHtmlModule } from './pipes/safeHtml/safe-html.module';

@NgModule({
  declarations: [
    FileSubmitBeforeUploadComponent,
    TabComponent,
    NumericTextboxComponent,
    ImportExportButtonComponent,
    DropdownMultipleSelectComponent,
    NumberFormatDisplayPipe,
    NameThDisplayPipe,
    ChildDirective,
    FullModalComponent,
    ConfirmModalComponent,
    DashDisplayPipe,
    ClickOutsideDirective,
    DebounceClickDirective,
    SearchPaginationComponent,
    ImportExportButtonComponent,
    FileUploadComponent,
    FileSubmitBeforeUploadComponent,
    UiPaginationComponent,
    AppStoreSecureSrcDirective,
    AppTaskAssignmentSecureSrcDirective,
    AppSrcDirective,
    AppAdsSrcDirective,
    TextEditorComponent,
    InputPostfixComponent,
    ClickToCopyComponent,
    MonthsDisplayPipe,
    PreventDoubleClickDirective,
    ConfirmWithMessageModalComponent,
    DropdownPagingMultipleSelectComponent,
    NoteModalComponent,
    MasterDataDisplayPipe,
    FilesInputComponent,
    DigitOnlyDirective,
    ClickToCopyComponent,
    NoteModalComponent,
    DisableButtonDirective,
    ArraySortPipe,
    AssortmentCjProductDetailsComponent,
    ProductViewHeaderComponent,
    HasPermissionDirective,
    VatDetailsModalComponent,
    AlphabetOnlyDirective,
    AlphanumericOnlyDirective,
    HasPermissionDirective,
    BooleanDisplayPipe,
    StockQuantityPipe,
    AssortmentCjProductDetailsComponent,
    ProductViewHeaderComponent,
    StoreConditionComponent,
    SafePipe,
    RemoveUnicodeDirective,
    ScrollHorizontalDirective,
    ClickWithOutDragDirective,
    UploadAttachmentsComponent,
    ConfirmWithRefNoModalComponent,
    TagInputComponent,
    SearchProductModalComponent,
    FilesUploadComponent,
    SearchBarcodeModalComponent,
    UploadDocumentButtonComponent,
    BoxsDisplayComponent,
    SearchMultipleArticleModalComponent,
    SearchArticleModalComponent,
    AppSecureSrcDirective,
    PreOrderPolicyComponent,
    OrderPolicyComponent,
    StoreProfileComponent,
    GetExcelDataComponent,
    NearByPoiComponent,
    MultipleFilesUploadComponent,
    DeliveryTimesComponent,
    NearByCompetitorComponent,
    PreOrderScheduleComponent,
    OrderPolicyScheduleComponent,
    MerchantProfileComponent,
    OwnerProfileComponent,
    LegalDocumentComponent,
    BaseModalExportComponent,
    DashboardBoxesComponent,
    NoSearchResultComponent,
    AdvancedSearchTagsComponent,
    DropdownCustomTemplateSelectComponent,
    FileSubmitWithTypeComponent,
    StoreContactComponent,
    PhoneContactComponent,
    StoreLocationComponent,
    TagUpdatedComponent,
    FilesSubmitValidateComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    NgOptionHighlightModule,
    MomentModule.forRoot(),
    ButtonsModule.forRoot(),
    RouterModule,
    PureFnModule,
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    DaysDisplayModule,
    TranslateModule.forChild(),
    AlertModalTemplateModule,
    ValidatorsMessageModule,
    QuillModule.forRoot(),
    InputTrimModule,
    ReferenceDocumentLinkModule,
    NgxIntlTelInputModule,
    TippyModule,
    TagInputModule,
    DynamicFormModule,
    DynamicReportModalModule,
    PipesModule,
    NgOptimizedImage,
    OptionHiglightModule
  ],
  exports: [
    // modules
    CommonModule,
    ModalModule,
    AlertModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    NgSelectModule,
    NgOptionHighlightModule,
    MomentModule,
    ButtonsModule,
    RouterModule,
    PureFnModule,
    PopoverModule,
    PaginationModule,
    AlertModalTemplateModule,
    ValidatorsMessageModule,
    InputTrimModule,
    ReferenceDocumentLinkModule,
    TippyModule,
    NgxIntlTelInputModule,
    OptionHiglightModule,

    // component
    NumericTextboxComponent,
    FileSubmitBeforeUploadComponent,
    ImportExportButtonComponent,
    TabComponent,
    DropdownMultipleSelectComponent,
    DropdownPagingMultipleSelectComponent,
    DropdownCustomTemplateSelectComponent,
    FullModalComponent,
    ConfirmModalComponent,
    SearchPaginationComponent,
    ImportExportButtonComponent,
    FileSubmitBeforeUploadComponent,
    FileUploadComponent,
    UiPaginationComponent,
    PreventDoubleClickDirective,
    ClickToCopyComponent,
    TextEditorComponent,
    InputPostfixComponent,
    FilesInputComponent,
    VatDetailsModalComponent,
    AssortmentCjProductDetailsComponent,
    ProductViewHeaderComponent,
    SearchProductModalComponent,
    StoreConditionComponent,
    FilesUploadComponent,
    SearchBarcodeModalComponent,
    UploadDocumentButtonComponent,
    BoxsDisplayComponent,
    TagInputComponent,
    StoreConditionComponent,
    UploadAttachmentsComponent,
    PreOrderPolicyComponent,
    OrderPolicyComponent,
    StoreProfileComponent,
    GetExcelDataComponent,
    NearByPoiComponent,
    MultipleFilesUploadComponent,
    DeliveryTimesComponent,
    NearByCompetitorComponent,
    PreOrderScheduleComponent,
    OrderPolicyScheduleComponent,
    SearchMultipleArticleModalComponent,
    SearchArticleModalComponent,
    MerchantProfileComponent,
    OwnerProfileComponent,
    LegalDocumentComponent,
    BaseModalExportComponent,
    DashboardBoxesComponent,
    NoSearchResultComponent,
    AdvancedSearchTagsComponent,
    StoreContactComponent,
    PhoneContactComponent,
    FileSubmitWithTypeComponent,

    // PIPE
    DashDisplayPipe,
    DateDisplayPipe,
    NumberFormatDisplayPipe,
    NameThDisplayPipe,
    DaysDisplayModule,
    ArraySortPipe,
    MasterDataDisplayPipe,
    MonthsDisplayPipe,
    BooleanDisplayPipe,
    StockQuantityPipe,
    SafeHtmlModule,

    // DIRECTIVE
    ClickOutsideDirective,
    DebounceClickDirective,
    ChildDirective,
    AppStoreSecureSrcDirective,
    AppSrcDirective,
    AppAdsSrcDirective,
    AppTaskAssignmentSecureSrcDirective,
    DigitOnlyDirective,
    HasPermissionDirective,
    AlphabetOnlyDirective,
    AlphanumericOnlyDirective,
    StoreConditionComponent,
    UploadAttachmentsComponent,
    AppSecureSrcDirective,
    DisableButtonDirective,
    RemoveUnicodeDirective,
    StoreLocationComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return { ngModule: SharedModule };
  }
}
