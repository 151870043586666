export enum TDStorePage {
  MERCHANT_REQUEST = 'Merchant Request',
  MERCHANT_EDIT = 'Merchant List',
  STORE_REQUEST = 'Store Request',
  STORE_EDIT = 'Store List',
  STORE_EDIT_ORDER_SCHEDULE = 'Store Edit Order Schedule',
  STORE_EDIT_PRE_ORDER_HUB_SCHEDULE = 'Store Edit Pre Order Hub Schedule'
}

export enum TDStoreEnum {
  PRE_ORDER = 'PRE_ORDER',
  REPLENISH = 'REPLENISH',
  PRE_ORDER_HUB = 'PRE_ORDER_HUB'
}

export enum ShelfPages {
  SHELF_REQUEST = 'Shelf Request',
  SHELF_REQUEST_FIX_ASSET = 'Shelf Request FixAsset',
  SHELF_REQUEST_INVENTORY = 'Shelf Request Inventory',
  SHELF_EDIT = 'Shelf List',
  SHELF_FIX_ASSET_EDIT = 'Shelf FixAsset List',
  SHELF_INVENTORY_EDIT = 'Shelf Inventory List'
}
