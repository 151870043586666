import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { ShelfItems } from '../../models';
import { OrderInventoryRequestViewResponse } from '../../models/order-request-inventory.model';
import { OrderRequestList } from '../../models/order-request.model';
import {
  OrderInventoryRequestActionType,
  OrderInventoryRequestActions
} from '../actions/order-inventory-request.actions';
import { BaseState, initialCriteriaObject } from '../state/base.state';

export interface OrderInventoryRequestState extends EntityState<OrderRequestList>, BaseState {
  selected: OrderInventoryRequestViewResponse;
  shelfList: ShelfItems[];
}

export const adapter: EntityAdapter<OrderRequestList> = createEntityAdapter<OrderRequestList>();

export const initialOrderInventoryResponseState: OrderInventoryRequestState = adapter.getInitialState({
  selected: null,
  criteriaObject: initialCriteriaObject,
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  shelfList: null
});

export function OrderInventoryRequestReducers(
  state = initialOrderInventoryResponseState,
  action: OrderInventoryRequestActions
): OrderInventoryRequestState {
  switch (action.type) {
    case OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GET_BY_STORE_RESPONSE:
      return {
        ...state,
        selected: {
          ...state.selected,
          store: {
            merchantNo: action.payload.merchant,
            storeCode: action.payload.code,
            storeName: action.payload.name,
            storeCodeName: `${action.payload.code}-${action.payload.name}`,
            storeNo: action.payload.no,
            storeType: action.payload.merchantType,
            minOrder: action.payload.minOrder,
            openDate: action.payload.openDate
          }
        }
      };
    case OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_SHELF_SELECTION_RESPONSE:
      return {
        ...state,
        shelfList: action.payload
      };
    case OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_SHELF_SELECTION_RESET:
      return {
        ...state,
        shelfList: null
      };
    case OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_SAVE_DELIVERY_DETAILS:
      return {
        ...state,
        selected: {
          ...state.selected,
          deliveryDetails: action.payload,
          deliveryStatus: 'success'
        }
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
