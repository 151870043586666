import { createSelector } from '@ngrx/store';

import * as fromMerchantCreateResponseState from '../reducers/first-lot-order.reducers';
import { AppStates } from '../state/app.states';

const selectFirstLotOrderState = (state: AppStates) => state.firstLotOrder;

export const selectFirstLotOrder = createSelector(
  selectFirstLotOrderState,
  (state: fromMerchantCreateResponseState.FirstLotOrderResponseState | null) => state
);

export const selectOrderedAmount = (storeCode: string) =>
  createSelector(
    selectFirstLotOrderState,
    (state: fromMerchantCreateResponseState.FirstLotOrderResponseState | null) => {
      return state.data[storeCode] && (state.data[storeCode].orderedAmount ? state.data[storeCode].orderedAmount : 0);
    }
  );

export const selectShelfItems = createSelector(
  selectFirstLotOrderState,
  (state: fromMerchantCreateResponseState.FirstLotOrderResponseState) => state.shelfItems
);

export const selectShelfDetail = createSelector(
  selectFirstLotOrderState,
  (state: fromMerchantCreateResponseState.FirstLotOrderResponseState) =>
    state.shelfDetails ? state.shelfDetails : []
);
