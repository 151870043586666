import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { CriteriaUtil } from '@shared/utils/criteria-util';

import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { TDStoreValidatorTypeEnum } from '../../../shared/enum/merchant-validator-type.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { RequestPageModesEnum } from '../../../shared/enum/request-step.enum';
import { NewMasterData } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  ErrorResponse,
  RouteLinkTab,
  SaleStatusEnum,
  StoreList,
  StoreListSearchCriteria
} from '../../../shared/models';
import { AuditLog, HistoryType } from '../../../shared/models/audit-log.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { WarehouseListContent } from '../../../shared/models/warehouse.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { StoreRequestService } from '../../../shared/services/store-request.service';
import { StoreService } from '../../../shared/services/store.service';
import { LayoutActionLoadError } from '../../../shared/store/actions/layout.action';
import { WarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { selectAllWarehouse } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ImportOrderScheduleModalComponent } from '../store-components/import-order-schedule/import-order-schedule.component';
import {
  StoreByIdRequestAction,
  StoreListHistoryRequestAction,
  StoreListHistoryResponseAction,
  StoreListRequestAction,
  StoreLockSaleRequestAction,
  StoreLockSaleResetAction
} from '../store/store.actions';
import { StoreState } from '../store/store.reducers';
import {
  selectAllStoreList,
  selectLockSaleStatus,
  selectStoreList,
  selectStoreListCriteria,
  selectStoreListHistory
} from '../store/store.selectors';
import { TerminateStoreComponent } from '../terminate-store/terminate-store.component';
import { ViewStoreComponent } from '../view-store/view-store.component';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoreListComponent extends BaseSearchComponent<StoreListSearchCriteria, StoreList, StoreState> {
  private localStore: Observable<any>;

  public listRoute: Array<RouteLinkTab>;
  public searchTag: string;

  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public storeTypeTag: string;
  public storeTypeStringTag = 'Store Type';
  public regionTag: string;
  public regionStringTag = 'Region';
  public stateTag: string;
  public stateStringTag = 'Province';
  public onPage: string;
  public hasSearchCriteria: boolean;

  public storeStatusList = filterDropdown.listStatusFilter;
  public storeTypeList: Array<NewMasterData> | null;
  public regionList: Array<NewMasterData> | null;
  public stateList: Array<NewMasterData> | null;

  public auditLogs$: Observable<AuditLog[]>;
  public warehouseList$: Observable<WarehouseListContent[]>;
  dateFormat = environment.dateFormat;
  public masterDataEnum = MasterDataEnum;
  public saleStatusEnum = SaleStatusEnum;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.IMPORT,
      name: 'Import Order Schedule',
      hidden: !this.authGuardService.checkPermission(['merchant_order_m'])
    }
  ];

  @ViewChild('terminateStoreModal', { static: false }) terminateStoreModal: TerminateStoreComponent;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: UntypedFormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    protected storeRequestService: StoreRequestService,
    protected storeService: StoreService,
    protected masterService: MasterService,
    protected bsModalService: BsModalService
  ) {
    super(store, modalService, selectAllStoreList, selectStoreList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.initialSubscription();
    this.localStore
      .pipe(select(selectStoreListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT, MasterDataEnum.REGION, MasterDataEnum.STATE])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
        this.regionList = result[MasterDataEnum.REGION];
        this.stateList = result[MasterDataEnum.STATE];
      });

    this.warehouseList$ = this.localStore.pipe(select(selectAllWarehouse('Warehouse')));
    this.store.dispatch(new WarehouseListRequestAction('-'));
    this.auditLogs$ = this.localStore.pipe(
      select(selectStoreListHistory),
      switchMap(val => {
        if (val) {
          return of(
            val.map(history => {
              return { ...history, parameters: { deviceNo: history?.deviceNo || '' } };
            })
          );
        }

        return EMPTY;
      })
    );
  }

  initialSubscription() {
    this.localStore.pipe(select(selectStoreListCriteria)).subscribe(criteriaObject => {
      this.criteriaObject = criteriaObject;
    });

    this.localStore
      .pipe(
        select(selectLockSaleStatus),
        mergeMap(success => {
          if (success) {
            const merchantNo = success.merchantNo;
            const storeNo = success.storeNo;
            const msg = success.status === SaleStatusEnum.ENABLE ? 'unlocked' : 'locked';

            const alertModalRef = this.modalService.show(AlertModalComponent, {
              initialState: {
                title: 'Success',
                message: `Sale pages have been ${msg}.`
              }
            });

            alertModalRef.content.action
              .pipe(untilComponentDestroyed(this))
              .subscribe((result: ModalButtonResponseEnum) => {
                if (result === ModalButtonResponseEnum.OK) {
                  this.doSearch(this.criteriaObject);
                  if (!this.onPage || this.onPage !== 'LIST_PAGE') {
                    this.store.dispatch(
                      new StoreByIdRequestAction({
                        merchant: { merchant: merchantNo },
                        storeNo: { storeNo: storeNo }
                      })
                    );
                  }
                  this.store.dispatch(new StoreLockSaleResetAction());
                }
              });
          }
          return of(null);
        })
      )
      .subscribe();
  }

  doDestroy() {
    // intentionally empty
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.storeStatusList[0].value],
      startCreatedDate: [null],
      endCreatedDate: [null],
      storeType: [null],
      region: [null],
      state: [null]
    });
  }

  onchangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  clearFilterDate() {
    this.searchForm.controls['startCreatedDate'].reset();
    this.searchForm.controls['endCreatedDate'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      startCreatedDate: null,
      endCreatedDate: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterStoreType() {
    this.clearFilter('storeType');
  }

  clearFilterRegion() {
    this.clearFilter('region');
  }

  clearFilterState() {
    this.clearFilter('state');
  }

  clearFilter(controlName) {
    this.searchForm.controls[controlName].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      [controlName]: this.searchForm.controls[controlName].value
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['startCreatedDate'].reset();
    this.searchForm.controls['endCreatedDate'].reset();
    this.searchForm.controls['storeType'].reset();
    this.searchForm.controls['region'].reset();
    this.searchForm.controls['state'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      startCreatedDate: null,
      endCreatedDate: null,
      storeType: null,
      region: null,
      state: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = value;
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = value;
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (
      !(
        formValue.startCreatedDate ||
        formValue.endCreatedDate ||
        formValue.storeType ||
        formValue.region ||
        formValue.state
      )
    ) {
      return;
    }

    let dateFrom = formValue.startCreatedDate;
    let dateTo = formValue.endCreatedDate;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      startCreatedDate: dateFrom,
      endCreatedDate: dateTo,
      storeType: formValue.storeType && formValue.storeType.length ? formValue.storeType.toString() : null,
      region: formValue.region && formValue.region.length ? formValue.region.toString() : null,
      state: formValue.state && formValue.state.length ? formValue.state.toString() : null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.storeTypeTag = null;
    this.regionTag = null;
    this.stateTag = null;

    const startCreatedDate = dateStringToTagCriteria(this.criteriaObject.startCreatedDate);
    const endCreatedDate = dateStringToTagCriteria(this.criteriaObject.endCreatedDate);
    const createdDate = generateDateStringTag({
      dateName: 'Created Date',
      dateFrom: startCreatedDate,
      dateTo: endCreatedDate
    });

    this.dateStringTag = createdDate.dateStringTag;
    this.dateTag = createdDate.dateTag;

    if (this.criteriaObject.storeType && this.criteriaObject.storeType.length) {
      const storeTypes = this.storeTypeList
        .filter(data => this.criteriaObject.storeType.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.storeTypeTag = `"${storeTypes}"`;
    }

    if (this.criteriaObject.state && this.criteriaObject.state.length) {
      const states = this.stateList
        .filter(data => this.criteriaObject.state.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.stateTag = `"${states}"`;
    }

    if (this.criteriaObject.region && this.criteriaObject.region.length) {
      const regions = this.regionList
        .filter(data => this.criteriaObject.region.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.regionTag = `"${regions}"`;
    }

    // this.criteriaObject.searchCriteriaFieldName = 'no,storeCodeName,createdByName';
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteriaFieldName: 'no,storeCodeName,createdByName'
    };
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.hasSearchCriteria = CriteriaUtil.hasCriteria(this.criteriaObject, null, [
      'page',
      'size',
      'searchCriteriaFieldName'
    ]);
    this.store.dispatch(new StoreListRequestAction(criteriaObj));
  }

  handleEdit(result: StoreList) {
    this.storeRequestService
      .getStoreValidate(TDStoreValidatorTypeEnum.REQUESTED, result.no)
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: res => {
          if (res.body.allowToEdit) {
            this.goToView(RequestPageModesEnum.REQUEST_EDIT, { merchant: result.merchant, no: result.no });
          } else {
            this.alertModal('Alert', 'Another request is awaiting approval.');
          }
        },
        error: error => {
          this.store.dispatch(new LayoutActionLoadError(error));
        }
      });
  }

  onLockSale(store: StoreList) {
    const msg = store.saleStatus && store.saleStatus === SaleStatusEnum.DISABLE ? 'unlock' : 'lock';
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to ${msg} sale?`,
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          this.onPage = 'LIST_PAGE';
          this.store.dispatch(
            new StoreLockSaleRequestAction({
              storeNo: store.no,
              saleStatus:
                store.saleStatus === SaleStatusEnum.DISABLE ? SaleStatusEnum.ENABLE : SaleStatusEnum.DISABLE,
              version: store.version
            })
          );
        }
      });
  }

  goToView(mode: RequestPageModesEnum, data?: any) {
    this.onPage = null;
    const initialState = {
      title: null,
      childItem: new ChildItem(
        ViewStoreComponent,
        {
          title: mode === RequestPageModesEnum.REQUEST_EDIT ? 'Edit Store' : 'View Store',
          mode,
          storeNo: data.no,
          merchant: data.merchant
        },
        false
      )
    };

    this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  showHistory(storeList: StoreList) {
    this.store.dispatch(new StoreListHistoryRequestAction({ storeNo: storeList.no }));
    const initialState = {
      title: 'History',
      historyHeader: `Store: ${storeList.code}-${storeList.name}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.STORE,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new StoreListHistoryResponseAction({ auditLogs: null }));
    });
  }

  getColorStatus(status: string): string {
    return status.toLowerCase();
  }

  get pageMode() {
    return RequestPageModesEnum;
  }

  hasStoreEditPermission() {
    return this.authGuardService.checkPermission(['merchant_edit_m']);
  }

  hasLockSalePermission() {
    return this.authGuardService.checkPermission(['store_locksale']);
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission([
      'store_v',
      'store_eq',
      'store_locksale',
      'merchant_edit_m',
      'merchant_order_m',
      'store_terminate'
    ]);
    const hasRequestPagePermission = this.authGuardService.checkPermission([
      'merchant_new_m',
      'merchant_edit_m',
      'merchant_order_m',
      'merchant_app'
    ]);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Store List', url: '/merchant-store/store-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Store Request', url: '/merchant-store/store-request-list' });
    }
  }

  onOpenImportOrderSchedule() {
    this.modalService.show(ImportOrderScheduleModalComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {}
    });
  }

  convertArrayFilter(value) {
    return value && value.length > 0 ? value.toString() : null;
  }

  onActivateStore(storeNo) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to activate?',
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          return this.storeService.activateStore({ storeNo }).pipe(untilComponentDestroyed(this));
        })
      )
      .subscribe({
        next: () => {
          this.alertSuccessModal('The store has been activated.');
        },
        error: error => {
          this.alertErrorModal(error.error);
        },
        complete: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  onDeactivateStore(storeNo) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to deactivate?',
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          return this.storeRequestService.getStoreValidate(TDStoreValidatorTypeEnum.REQUESTED, storeNo).pipe(
            untilComponentDestroyed(this),
            filter(v => Boolean(v))
          );
        })
      )
      .subscribe({
        next: res => {
          if (res.body.allowToEdit) {
            this.deactivateStore(storeNo);
          } else {
            this.alertModal('Failed', 'The request is awaiting approval.');
          }
        },
        error: error => {
          this.store.dispatch(new LayoutActionLoadError(error));
        },
        complete: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  onTerminateStore(storeCode: string, storeName: string, storeNo: string, version: number, openDate: string) {
    this.terminateStoreModal.openTerminateModal(storeCode, storeName, storeNo, version, openDate);
    this.terminateStoreModal.action.pipe(take(1)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.setFirstPage();
        this.search({ ...this.criteriaObject, page: 0 });
      }
    });
  }

  deactivateStore(storeNo) {
    this.storeService
      .deactivateStore({ storeNo })
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: () => {
          this.alertSuccessModal('The store has been deactivated.');
        },
        error: error => {
          this.alertErrorModal(error.error);
        }
      });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.bsModalService.hide(1);
        this.setFirstPage();
        this.search({ ...this.criteriaObject, page: 0 });
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  alertModal(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }
}
