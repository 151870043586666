import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuditLog } from '@shared/models';
import { MetricActionType, MetricModule, MetricType, Metrics } from '@shared/models/metrics';
import { ReturnToWarehouseGRReportCriteria } from '@shared/models/report.model';
import { BaseService } from '@shared/services/base.service';
import { metricsReporting } from '@shared/utils/metrics-util';

import { ReturnToWarehouseGrView } from '../modals/return-to-warehouse-gr-view';
import { ReturnToWarehouseGR } from '../modals/return-to-warehouse-list';

@Injectable()
export class ReturnToWarehouseGRService extends BaseService {
  public headers: HttpHeaders;
  envReportService;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.returnToWarehouseGR;
    this.headers = new HttpHeaders(this.envService.headers);

    this.envReportService = this.env.services.exportReport;
  }

  getReturnToWarehouseGRList(
    criteria: ReturnToWarehouseGR.SearchCriteriaRequest
  ): Observable<ReturnToWarehouseGR.Pagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.RETURN_TO_WAREHOUSE,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria);
    return this.http.get<ReturnToWarehouseGR.Pagination>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  getReturnToWarehouseGR(docNo: string): Observable<ReturnToWarehouseGrView> {
    const url = this.getFullUrl(this.envService.get, { docNo });

    return this.http.get<ReturnToWarehouseGrView>(url, {
      headers: this.loaderHeaders(null),
      observe: 'body'
    });
  }

  getReturnToWarehouseHistory(docNo: string): Observable<AuditLog[]> {
    const url = this.getFullUrl(this.envService.history, { docNo });

    return this.http.get<AuditLog[]>(url, {
      headers: this.loaderHeaders(null),
      observe: 'body'
    });
  }

  exportReport(criteria: ReturnToWarehouseGRReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envReportService.returnToWarehouseGR, null, true);
    const metrics = metricsReporting([this.envReportService.returnToWarehouseGR]);

    let headers = this.loaderHeaders(0, metrics);
    headers = headers.set('x-host', this.envReportService.headers['x-host']);

    return this.http.post<any>(url, criteria, {
      headers,
      observe: 'body'
    });
  }
}
