export enum PromotionTypeEnum {
  SPECIAL_PRICE = 'SPECIAL_PRICE',
  BUY_X_GET_ONE_FREE = 'BUY_X_GET_ONE_FREE',
  BUY_X_CHEAPER = 'BUY_X_CHEAPER',
  PAIR_SAVER = 'PAIR_SAVER',
  BUY_TWO_PAY_ONE = 'BUY_TWO_PAY_ONE'
}

export enum PromotionPageModeEnum {
  REQUEST_CREATE = 'REQUEST_CREATE',
  REQUEST_VIEW = 'REQUEST_VIEW',
  REQUEST_EDIT = 'REQUEST_EDIT',
  VIEW = 'VIEW'
}

export enum DiscountTypeEnum {
  VALUE = 'VALUE',
  PERCENT = 'PERCENT'
}

export enum PromotionStatusEnum {
  A = 'APPROVED',
  C = 'CANCELLED',
  D = 'DRAFT',
  R = 'REJECTED',
  W = 'AWAITING_APPROVAL',
  AWAITING_APPROVAL = 'W',
  REJECTED = 'R',
  DRAFT = 'D',
  CANCELLED = 'C',
  APPROVED = 'A'
}

export const promotionConditionConfig = {
  SPECIAL_PRICE: { forceBuyQty: { start: 1, end: 1 }, freeQTY: null, discountType: [DiscountTypeEnum.VALUE] },
  BUY_X_GET_ONE_FREE: { forceBuyQty: { start: 1, end: 12 }, freeQTY: 1, discountType: [DiscountTypeEnum.VALUE] },
  BUY_X_CHEAPER: {
    forceBuyQty: { start: 2, end: 12 },
    freeQTY: null,
    discountType: [DiscountTypeEnum.VALUE, DiscountTypeEnum.PERCENT]
  },
  PAIR_SAVER: {
    forceBuyQty: { start: 2, end: 2 },
    freeQTY: null,
    discountType: [DiscountTypeEnum.VALUE, DiscountTypeEnum.PERCENT]
  },
  BUY_TWO_PAY_ONE: {
    forceBuyQty: { start: 2, end: 2 },
    freeQTY: 1,
    discountType: [DiscountTypeEnum.VALUE]
  }
};

export enum PromotionGroupNameEnum {
  A = 'A',
  B = 'B'
}
