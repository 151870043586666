import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SignedUrlObject } from '../models';
import { FileResponse } from '../models/fileResponse';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import {
  MonthlyPartnerProfitSharingListPagination,
  MonthlyPartnerProfitSharingSearchCriteria,
  MonthlyPartnerProfitSharingSubmitResponse
} from '../models/monthly-partner-profit-sharing.model';
import { Progress } from '../models/progress.model';
import { selectUserInfoResult } from '../store/selectors/auth-user-info.selector';
import { AppStates } from '../store/state/app.states';
import { BaseService } from './base.service';

type UploadResultType = Progress | FileResponse | null | any;

@Injectable()
export class MonthlyPartnerProfitSharingService extends BaseService {
  public headers: HttpHeaders;
  // @ts-ignore
  private userName: string;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: NGXLogger,
    private readonly store: Store<AppStates>
  ) {
    super();
    this.envService = this.env.services.monthlyPartnerProfitSharing;
    this.headers = new HttpHeaders(this.envService.headers);
    this.store.select(selectUserInfoResult).subscribe(val => {
      if (val) {
        this.userName = val.userName;
      }
    });
  }

  public downloadMonthlyProfitSharingReport(refId: string, id: string): Observable<SignedUrlObject> {
    const url = this.getFullUrl(this.envService.report, { id });
    return this.http.post<SignedUrlObject>(url, { refId }, { headers: this.headers });
  }

  public searchByCriteria(
    criteria: MonthlyPartnerProfitSharingSearchCriteria
  ): Observable<MonthlyPartnerProfitSharingListPagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.BILLING,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria, true);
    return this.http.get<MonthlyPartnerProfitSharingListPagination>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public uploadMonthlyPartnerProfitSharingFiles(
    files: Array<File>,
    runDate: string
  ): Array<Observable<UploadResultType>> {
    return files.map(file => this.uploadMonthlyPartnerProfitSharingFile(file, runDate));
  }

  public uploadMonthlyPartnerProfitSharingFile(file: File, runDate: string): Observable<UploadResultType> {
    const url = this.getFullUrl(this.envService.uploadMonthlyPartnerProfitSharingFileAppend, {
      runDate
    });
    const formData = new FormData();

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.loaderHeaders()
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return { ...event.body, status: 'done' };
            default:
              const msg = `Unhandled event: ${HttpEventType[event.type]}`;
              this.logger.info(msg);
              return null;
          }
        }),
        catchError(err => of(err))
      );
  }

  public submitMonthlyPartnerProfitSharing(
    file: File,
    runDate: string
  ): Observable<MonthlyPartnerProfitSharingSubmitResponse> {
    const url = this.getFullUrl(this.envService.submit, {
      runDate
    });

    const formData = new FormData();

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<MonthlyPartnerProfitSharingSubmitResponse>(url, formData, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });

    // mock error
    // return throwError({ code: "11007", message: "Invalid import data." });
  }
}
