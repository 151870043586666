import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { round } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ProductOrderItem } from '../../models/order-request.model';
import { OrderRequestService } from '../../services/order-request.service';
import { ShelfTypesService } from '../../services/shelf-types.service';
import { ShelfService } from '../../services/shelf.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  OrderApproveRequestAction,
  OrderApproveResponseAction,
  OrderCreateSaveRequestAction,
  OrderCreateSaveResponseAction,
  OrderCreateSubmitRequestAction,
  OrderCreateSubmitResponseAction,
  OrderCreateSubmitResponseErrorAction,
  OrderRequestActionType,
  OrderRequestByIdRequestAction,
  OrderRequestByIdResponseAction,
  OrderRequestByRefNoRequestAction,
  OrderRequestByRefNoResponseAction,
  OrderRequestGenerateShelfItemsRequestAction,
  OrderRequestHistoryRequestAction,
  OrderRequestHistoryResponseAction,
  OrderRequestListRequestAction,
  OrderRequestListResponseAction,
  OrderRequestShelfSelectionRequestAction,
  OrderRequestShelfSelectionResponseAction
} from '../actions/order-request.actions';
import { OrderSelectItemOrderAddAllItem } from '../actions/order-select-item-order.actions';
import { selectAllOrderSelectItemOrder } from '../selectors/order-select-item-order.selector';
import { AppStates } from '../state/app.states';

@Injectable()
export class OrderRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly orderRequestService: OrderRequestService,
    private readonly shelfService: ShelfService,
    private readonly shelfTypesService: ShelfTypesService,
    private readonly logger: NGXLogger,
    private readonly store: Store<AppStates>
  ) {}

  searchOrderRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderRequestListRequestAction>(OrderRequestActionType.ORDER_REQUEST_LIST_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderRequestActionType.ORDER_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.orderRequestService.searchByCriteria(payload).pipe(
          map(response => {
            return new OrderRequestListResponseAction(response);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  orderCreateSave$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderCreateSaveRequestAction>(OrderRequestActionType.ORDER_CREATE_SAVE_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderRequestActionType.ORDER_CREATE_SAVE_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.orderRequestService.saveRequest(payload).pipe(
          map(res => new OrderCreateSaveResponseAction(res)),
          catchError(error => of(new OrderCreateSubmitResponseErrorAction(error.error)))
        );
      })
    );
  });

  OrderCreateSubmit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderCreateSubmitRequestAction>(OrderRequestActionType.ORDER_CREATE_SUBMIT_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderRequestActionType.ORDER_CREATE_SUBMIT_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.orderRequestService.submitRequest(payload).pipe(
          map(res => new OrderCreateSubmitResponseAction(res)),
          catchError(error => of(new OrderCreateSubmitResponseErrorAction(error.error)))
        );
      })
    );
  });

  getOrderRequestById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderRequestByIdRequestAction>(OrderRequestActionType.ORDER_REQUEST_GET_BY_ID_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderRequestActionType.ORDER_REQUEST_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.orderRequestService.getOrderById(payload).pipe(
          map(res => {
            return new OrderRequestByIdResponseAction(res);
          }),

          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  getOrderRequestByRefNo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderRequestByRefNoRequestAction>(OrderRequestActionType.ORDER_REQUEST_GET_BY_REF_NO_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderRequestActionType.ORDER_REQUEST_GET_BY_REF_NO_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.orderRequestService.getOrderByRefNo(payload).pipe(
          map(res => {
            return new OrderRequestByRefNoResponseAction(res);
          }),

          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  orderApprove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderApproveRequestAction>(OrderRequestActionType.ORDER_REQUEST_APPROVE_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderRequestActionType.ORDER_REQUEST_APPROVE_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload =>
        this.orderRequestService.approveRequest(payload).pipe(
          map(() => new OrderApproveResponseAction({ isSuccess: true })),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  OrderRequestHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderRequestHistoryRequestAction>(OrderRequestActionType.ORDER_REQUEST_HISTORY_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderRequestActionType.ORDER_REQUEST_HISTORY_REQUEST}: ` + JSON.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.orderRequestService.getHistoryLogs(payload).pipe(
          map(response => new OrderRequestHistoryResponseAction({ auditLogs: response.auditLogs })),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  orderRequestShelfListRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderRequestShelfSelectionRequestAction>(OrderRequestActionType.ORDER_REQUEST_SHELF_SELECTION_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderRequestActionType.ORDER_REQUEST_SHELF_SELECTION_REQUEST}: ` + this.stringify(action)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfTypesService.getShelfTypeList(payload).pipe(
          map(shelfListResponse => {
            return new OrderRequestShelfSelectionResponseAction(shelfListResponse.content);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  orderRequestGenerateShelfItemsRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderRequestGenerateShelfItemsRequestAction>(
        OrderRequestActionType.ORDER_REQUEST_GENERATE_SHELF_ITEM_REQUEST
      ),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderRequestActionType.ORDER_REQUEST_GENERATE_SHELF_ITEM_REQUEST}: ` + this.stringify(action)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfService.generateShelfItems(payload.shelfNo, payload.storeNo).pipe(
          withLatestFrom(this.store.select(selectAllOrderSelectItemOrder)),
          map(([response, tdAssortmentOrder]) => {
            tdAssortmentOrder.forEach(order => {
              const item = response.find(value => value.articleNo === order.articleNo);

              if (!item) {
                return;
              }

              item.qty += order.qty;
              item.isAddItem = true;
              item.allowToDelete = item.allowToDelete || false;
            });

            return new OrderSelectItemOrderAddAllItem({ itemList: this.mappingShelfItemOrder(response) });
          }),
          catchError(err => of(new LayoutActionLoadError(err)))
        );
      }),
      catchError(error => of(new LayoutActionLoadError(error)))
    );
  });

  mappingShelfItemOrder(shelfItems: ProductOrderItem[]): ProductOrderItem[] {
    shelfItems.forEach(item => {
      item.totalVat = {
        amount: (item.vatAmount && round(item.vatAmount.amount * item.qty, 2)) || 0,
        currency: (item.vatAmount && item.vatAmount.currency) || 'THB'
      };
      item.amountExcVat = {
        amount: (item.wholesalePriceExcVat && round(item.wholesalePriceExcVat.amount * item.qty, 2)) || 0,
        currency: (item.wholesalePriceExcVat && item.wholesalePriceExcVat.currency) || 'THB'
      };
      item.amount = {
        amount: round(item.amountExcVat.amount + item.totalVat.amount, 2) || 0,
        currency: (item.amountExcVat && item.amountExcVat.currency) || 'THB'
      };
      item.isAddItem = true;
    });

    return shelfItems;
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
