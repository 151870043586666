import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MerchantRequestService } from '@shared/services/merchant-request.service';

import { environment as env } from '../../../../environments/environment';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import {
  GraphqlQueryObject,
  GraphqlQuerySortOptions,
  OrderByEnum,
  OrderDirectionEnum
} from '../../../shared/gql/common.gql';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { UserDto } from '../../../shared/models';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { RoleService } from '../../../shared/services/role.service';
import { StoreRequestService } from '../../../shared/services/store-request.service';
import { UserCreateRequestAction } from '../../../shared/store/actions/user-info.action';
import { selectUsersListCriteria } from '../../../shared/store/selectors/user-selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { RoleAllRequestAction } from '../../roles/stores/role/role.actions';
import { selectRoleAllList } from '../../roles/stores/role/role.selectors';
import { StoreGroupService } from '../../store-group/services/store-group.service';
import { UserEditComponent } from '../user-edit/user-edit.component';

@Component({
  selector: 'app-user-create',
  templateUrl: '../user-edit/user-edit.component.html',
  styleUrls: ['../user-edit/user-edit.component.scss']
})
export class UserCreateComponent extends UserEditComponent implements OnInit, OnDestroy {
  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService,
    protected readonly fb: UntypedFormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected storeRequestService: StoreRequestService,
    protected storeGroupService: StoreGroupService,
    protected masterService: MasterService,
    protected roleService: RoleService,
    protected authGuardService: AuthGuardService,
    protected merchantRequestService: MerchantRequestService
  ) {
    super(
      store,
      translate,
      fb,
      logger,
      modalService,
      storeRequestService,
      storeGroupService,
      masterService,
      roleService,
      authGuardService,
      merchantRequestService
    );
    this.hasStoreAuthority = false;
    this.storeGroupList = [];
    this.selectedAllStoreInMerchant = false;
    this.selectedStoreGroup = [];
    this.selectedStore = [];
  }

  ngOnInit() {
    this.initControl();
    this.initData();
    this.initState();
    this.loadMerchant('');
  }

  initData() {
    const departmentQuery = new GraphqlQueryObject();
    departmentQuery.name = MasterDataEnum.DEPARTMENTS;
    departmentQuery.fields = ['code', 'id', 'nameEn', 'nameTh'];
    departmentQuery.active = true;
    departmentQuery.sort = {
      orderBy: OrderByEnum.NAME_EN,
      orderDirection: OrderDirectionEnum.ASC
    } as GraphqlQuerySortOptions;
    this.masterService.getMasterDataByNames([departmentQuery]).subscribe(result => {
      if (result.data) {
        this.departmentList = result.data.departments;
      }
    });
    this.store.dispatch(new RoleAllRequestAction());
    this.localStore.pipe(select(selectRoleAllList)).subscribe(roles => {
      if (roles && roles.length > 0) {
        this.roleList = roles.filter(v => this.roleStoreOwner.indexOf(v.code) === -1);
      }
    });
    this.localStore
      .pipe(select(selectUsersListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));
  }

  loadStoreGroup(merchantNo: string) {
    if (merchantNo == null) {
      return;
    }
    this.selectedAllStoreInMerchant = false;
    this.selectedStoreGroup = [];
    this.selectedStore = [];

    this.listStore$ = this.storeGroupService.getStoreGroupsByMerchant(merchantNo).pipe(catchError(() => of([])));
  }

  sendToSubmit(data: UserDto) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.isSendToSubmit = true;
          this.store.dispatch(new UserCreateRequestAction(data));
        }
      });
  }

  prepareData(): UserDto {
    const rawData = this.userForm.getRawValue();

    const dataRequest = {
      ...rawData,
      birthDate: rawData.birthDate ? moment(rawData.birthDate, env.dateFormat).format(env.dateFormat) : null,
      countryCode: rawData.mobileNo && rawData.mobileNo.internationalNumber.split(' ')[0],
      mobileNo: rawData.mobileNo && rawData.mobileNo.nationalNumber.replace(/ /g, ''),
      accessAllStores: this.hasStoreAuthority ? this.selectedAllStoreInMerchant : false,
      accessStoreGroups: this.hasStoreAuthority ? this.selectedStoreGroup : [],
      accessStores: this.hasStoreAuthority ? this.selectedStore : [],
      roles: this.rolesFormArray
        .getRawValue()
        .filter(item => item.role !== null)
        .map(item => item.role.code),
      tenant: {
        merchant: rawData.merchant.merchant
      }
    } as UserDto;
    delete dataRequest['merchant'];
    return dataRequest;
  }
}
