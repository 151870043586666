import Decimal from 'decimal.js-light';

export class DecimalJsUtil {
  static toDecimal(value: number): Decimal {
    return new Decimal(value);
  }

  static add(...args: number[]): number {
    let decimal = new Decimal(0);
    for (const val of args) {
      decimal = decimal.add(val || 0);
    }
    return decimal.toNumber();
  }

  static divide(v1: number, v2: number): number {
    return new Decimal(v1 || 0).div(v2 || 0).toNumber();
  }

  static multiply(v1: number, v2: number): number {
    return new Decimal(v1 || 0).mul(v2 || 0).toNumber();
  }

  static minus(v1: number, v2: number): number {
    return new Decimal(v1 || 0).minus(v2 || 0).toNumber();
  }

  static round(x: number): number {
    return Number(new Decimal(x).toFixed(2, Decimal.ROUND_HALF_UP));
  }
}
