import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { UserListViewDto, UserSearchCriteria, UserViewResponse } from '../../models';
import { BaseApiResponse } from '../../models/base-api-response';
import { UserActionTypes, UserInfoActions } from '../actions/user-info.action';

export interface UsersState extends EntityState<UserListViewDto> {
  isLoading: boolean;
  criteriaObject: UserSearchCriteria;
  totalElements: number;
  totalPages: number;
  selected: UserViewResponse;
  changeStatusResponse: BaseApiResponse;
}

export const adapter: EntityAdapter<UserListViewDto> = createEntityAdapter<UserListViewDto>();

export const initialUsersListState: UsersState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  selected: null,
  changeStatusResponse: null
});

export function UsersReducers(state = initialUsersListState, action: UserInfoActions): UsersState {
  switch (action.type) {
    case UserActionTypes.USER_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case UserActionTypes.USER_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case UserActionTypes.USER_GET_BY_NO_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case UserActionTypes.USER_GET_BY_NO_RESET:
      return {
        ...state,
        selected: null
      };

    case UserActionTypes.USER_ACTIVATE_BY_USER_NAME_RESPONSE:
      return {
        ...state,
        changeStatusResponse: action.payload
      };
    case UserActionTypes.USER_ACTIVATE_BY_USER_NAME_RESET:
      return {
        ...state,
        changeStatusResponse: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
