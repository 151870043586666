import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OnlineCouponCompensateCriteria } from '../models/report.model';
import { metricsReporting } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable()
export class OnlineCouponCompensateService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.exportReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  reportExport(criteria: OnlineCouponCompensateCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.onlineCouponCompensate, null, true);
    const metrics = metricsReporting([this.envService.onlineCouponCompensate]);

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
