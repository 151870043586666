import { ForgotPasswordStepEnum } from '../../enum/forgot-password.enum';
import { ErrorResponse } from '../../models';
import { ForgotPasswordActionTypes, ForgotPasswordActions } from '../actions/forgot-password.action';
import { BaseForgotPasswordState, initialForgotPasswordState } from '../state/forgot-password.state';

export function forgotPasswordReducers(
  state = initialForgotPasswordState,
  action: ForgotPasswordActions
): BaseForgotPasswordState {
  switch (action.type) {
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_OTP_SUCCESS: {
      return {
        ...state,
        result: {
          ...state.result,
          isSuccess: false,
          isSentOTP: true,
          phoneNo: action.payload.data.phoneNo,
          refNo: action.payload.data.refNo,
          userName: action.payload.data.userName,
          step: ForgotPasswordStepEnum.ENTER_OTP,
          errorResponse: new ErrorResponse()
        }
      };
    }
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_OTP_TIMEOUT_REQUEST: {
      return {
        ...state,
        result: {
          ...state.result,
          errorResponse: new ErrorResponse()
        }
      };
    }
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_OTP_FAILED:
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_VERIFY_OTP_FAILED:
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        result: {
          ...state.result,
          errorResponse: action.payload.error
        }
      };
    }
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        result: {
          ...state.result,
          isVerfiedOTP: true,
          step: ForgotPasswordStepEnum.CHANGE_PASSWORD,
          errorResponse: new ErrorResponse()
        }
      };
    }
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        result: {
          ...state.result,
          isSuccess: true,
          isSentOTP: false,
          refNo: null,
          userName: null,
          step: ForgotPasswordStepEnum.COMPLETED,
          errorResponse: new ErrorResponse()
        }
      };
    }
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_RESET: {
      return initialForgotPasswordState;
    }
    default: {
      return state;
    }
  }
}
