import { Action } from '@ngrx/store';

import { ErrorResponse } from '../../../../shared/models';
import {
  RewardCatalog,
  RewardCatalogListResponse,
  RewardCatalogListSearchCriteria,
  RewardCatalogViewResponse
} from '../../../../shared/models/reward-catalog.model';

export enum RewardCatalogActionType {
  REWARD_CATALOG_LIST_REQUEST = '[RewardCatalog] RewardCatalog List Request',
  REWARD_CATALOG_LIST_RESPONSE = '[RewardCatalog] RewardCatalog List Response',
  REWARD_CATALOG_LIST_ERROR = '[RewardCatalog] RewardCatalog List Error',
  REWARD_CATALOG_GET_BY_REQUEST_NO = '[RewardCatalog] Get RewardCatalog by Request No',
  REWARD_CATALOG_GET_BY_REQUEST_NO_RESPONSE = '[RewardCatalog] Get RewardCatalog by Request No Response',
  REWARD_CATALOG_GET_BY_REQUEST_NO_ERROR = '[RewardCatalog] Get RewardCatalog by Request No Error',
  REWARD_CATALOG_GET_BY_REQUEST_NO_RESET = '[RewardCatalog] Get RewardCatalog by Request No Reset',
  REWARD_CATALOG_LIST_HISTORY_REQUEST = '[RewardCatalog] List History Request',
  REWARD_CATALOG_LIST_HISTORY_RESPONSE = '[RewardCatalog] List History Response'
}

export class RewardCatalogListRequestAction implements Action {
  readonly type = RewardCatalogActionType.REWARD_CATALOG_LIST_REQUEST;

  constructor(public payload: RewardCatalogListSearchCriteria) {}
}

export class RewardCatalogListResponseAction implements Action {
  readonly type = RewardCatalogActionType.REWARD_CATALOG_LIST_RESPONSE;

  constructor(public payload: RewardCatalogListResponse) {}
}

export class RewardCatalogListErrorAction implements Action {
  readonly type = RewardCatalogActionType.REWARD_CATALOG_LIST_ERROR;

  constructor(public payload: RewardCatalogListResponse) {}
}

export class RewardCatalogByRequestNoAction implements Action {
  readonly type = RewardCatalogActionType.REWARD_CATALOG_GET_BY_REQUEST_NO;

  constructor(public payload: RewardCatalog) {}
}

export class RewardCatalogByRequestNoResponseAction implements Action {
  readonly type = RewardCatalogActionType.REWARD_CATALOG_GET_BY_REQUEST_NO_RESPONSE;

  constructor(public payload: RewardCatalogViewResponse) {}
}

export class RewardCatalogByRequestNoErrorAction implements Action {
  readonly type = RewardCatalogActionType.REWARD_CATALOG_GET_BY_REQUEST_NO_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResetRewardCatalogSelected implements Action {
  readonly type = RewardCatalogActionType.REWARD_CATALOG_GET_BY_REQUEST_NO_RESET;
}

export class RewardCatalogHistoryRequestAction implements Action {
  readonly type = RewardCatalogActionType.REWARD_CATALOG_LIST_HISTORY_REQUEST;

  constructor(public payload: string) {}
}

export class RewardCatalogHistoryResponseAction implements Action {
  readonly type = RewardCatalogActionType.REWARD_CATALOG_LIST_HISTORY_RESPONSE;

  constructor(public payload: { auditLogs }) {}
}

export type RewardCatalogActions =
  | RewardCatalogListRequestAction
  | RewardCatalogListResponseAction
  | RewardCatalogListErrorAction
  | RewardCatalogByRequestNoAction
  | RewardCatalogByRequestNoResponseAction
  | RewardCatalogByRequestNoErrorAction
  | ResetRewardCatalogSelected
  | RewardCatalogHistoryRequestAction
  | RewardCatalogHistoryResponseAction;
