import { createSelector } from '@ngrx/store';

import * as fromStockInformationMovementState from './stock-information-movement.reducers';
import * as fromStockInformationStoreState from './stock-information-store.reducers';
import * as fromStockInformationWarehouseState from './stock-information-warehouse.reducers';
import * as fromStockInformationState from './stock-information.reducers';
import { StockInformationStates } from './stock-information.states';

const selectStoreInformationState = (state: StockInformationStates) => state.stockInformation;
const selectStoreInformationMovementState = (state: StockInformationStates) => state.stockInformationMovement;
const selectStoreInformationStoreState = (state: StockInformationStates) => state.stockInformationStore;
const selectStoreInformationWarehouseState = (state: StockInformationStates) => state.stockInformationWarehouse;

export const selectAllStockInformationList = createSelector(
  selectStoreInformationState,
  fromStockInformationState.selectAll
);

export const selectStockInformationList = createSelector(
  selectStoreInformationState,
  (state: fromStockInformationState.StockInformationState) => state
);

export const selectStockInformationListCriteria = createSelector(selectStoreInformationState, state => {
  return state.criteriaObject;
});

export const selectStockInformation = createSelector(
  selectStoreInformationState,
  (state: fromStockInformationState.StockInformationState) => state.stockInformation
);

export const selectAllStockWarehouseMovementList = createSelector(
  selectStoreInformationMovementState,
  fromStockInformationMovementState.selectAll
);

export const selectStockWarehouseMovementList = createSelector(
  selectStoreInformationMovementState,
  (state: fromStockInformationMovementState.StockInformationMovementState) => state
);

export const selectStockWarehouseMovementListCriteria = createSelector(selectStoreInformationMovementState, state => {
  return state.criteriaObject;
});

export const selectAllStockStoreList = createSelector(
  selectStoreInformationStoreState,
  fromStockInformationStoreState.selectAll
);

export const selectStockStoreList = createSelector(
  selectStoreInformationStoreState,
  (state: fromStockInformationStoreState.StockInformationStoreState) => state
);

export const selectStockStoreListCriteria = createSelector(selectStoreInformationStoreState, state => {
  return state.criteriaObject;
});

export const selectAllStockWarehouseList = createSelector(
  selectStoreInformationWarehouseState,
  fromStockInformationWarehouseState.selectAll
);

export const selectStockWarehouseList = createSelector(
  selectStoreInformationWarehouseState,
  (state: fromStockInformationWarehouseState.StockInformationWarehouseState) => state
);

export const selectStockWarehouseListCriteria = createSelector(selectStoreInformationWarehouseState, state => {
  return state.criteriaObject;
});
