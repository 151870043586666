import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { StoreRequestList, StoreRequestListSearchCriteria, StoreRequestViewResponse } from '../../models';
import { StoreRequestActionType, StoreRequestActions } from '../actions/store-request.actions';
import { BaseState } from '../state/base.state';

export interface StoreRequestState extends EntityState<StoreRequestList>, BaseState {
  selected: StoreRequestViewResponse;
  criteriaObject: StoreRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  isDeleteSuccess: boolean;
}

export const adapter: EntityAdapter<StoreRequestList> = createEntityAdapter<StoreRequestList>();

export const initialStoreResponseState: StoreRequestState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  isDeleteSuccess: null
});

export function storeRequestReducers(
  state = initialStoreResponseState,
  action: StoreRequestActions
): StoreRequestState {
  switch (action.type) {
    case StoreRequestActionType.STORE_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StoreRequestActionType.STORE_REQUEST_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StoreRequestActionType.STORE_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case StoreRequestActionType.STORE_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case StoreRequestActionType.STORE_REQUEST_GET_VERSION_RESPONSE:
      return {
        ...state,
        selected: {
          ...state.selected,
          version: action.payload.version
        }
      };
    case StoreRequestActionType.STORE_REQUEST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    case StoreRequestActionType.STORE_REQUEST_DELETE_RESPONSE:
      return {
        ...state,
        isDeleteSuccess: action.payload.isSuccess
      };
    case StoreRequestActionType.STORE_REQUEST_DELETE_RESET:
      return {
        ...state,
        isDeleteSuccess: false
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
