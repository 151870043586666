import { AuditLog } from '@shared/models/audit-log.model';

import { ClaimRequestStatusEnum, MissingToteStatusEnum } from '../../../shared/enum/claim-status.enum';
import { StoreType } from '../../../shared/enum/store.enum';
import { Amount } from '../../../shared/models/amount.model';
import { BaseReport } from '../../../shared/models/base-report.model';

export interface ClaimRequest {
  id: string;
  claimRequestNo: string;
  claimRequestStatus: ClaimRequestStatusEnum;
  orderNo: string;
  doNo: string;
  roNo: string;
  storeNo?: string;
  storeName: string;
  storeCode: string;
  storeType: StoreType;
  orderType: string;
  merchantNo?: string;
  shipmentNo?: string;
  requestedBy: string;
  requestedDate: string;
  totalApprovedAmount: number;
  totalApprovedExcVatAmount: number;
  totalApprovedVatAmount: number;
  toteFee?: ToteFee;
  amount?: number;
  totalClaimAmount?: number;
  missingTotes?: MissingTote[];
  items: ClaimRequestItem[];
  version: number;
  crDate?: string;
  crBy?: string;
  crByName?: string;
  crByLocalName?: string;
  createdWithRO?: boolean;
  warehouseCode?: string;
  warehouseName?: string;
  warehouseDisplayName?: string;

  isNewGr?: boolean;
  histories?: AuditLog[];
  crType: claimRequestType;

  shipTo?: string;
  shipFrom?: string;
}

export type claimRequestType = 'SHIPPING_DAMAGE' | null;

export interface ClaimRequestSubmit {
  grNo: string;
  id: string;
  claimRequestNo: string;
  claimRequestStatus: ClaimRequestStatusEnum;
  orderNo: string;
  doNo: string;
  roNo: string;
  storeNo?: string;
  storeName: string;
  storeCode: string;
  storeType: StoreType;
  orderType: string;
  merchantNo?: string;
  shipmentNo?: string;
  requestedBy: string;
  requestedDate: string;
  totalApprovedAmount: Amount;
  totalApprovedExcVatAmount: Amount;
  totalApprovedVatAmount: Amount;
  amount?: Amount;
  totalClaimAmount?: Amount;
  items: ClaimRequestItemSubmit[];
  version: number;
  crDate?: string;
  crBy?: string;
  crByName?: string;
  crByLocalName?: string;
}

export interface ClaimApproveRequest {
  version: number;
  comment: string;
  claimRequestNo: string;
  totalApprovedAmount: Amount;
  totalApprovedExcVatAmount: Amount;
  totalApprovedVatAmount: Amount;
  items: ClaimApproveRequestItem[];
  toteItems: ToteItemRequest[];
}

export interface ClaimApproveRequestItem {
  itemNo: number;
  articleNo: string;
  barcode: string;
  productType?: string;
  receiveQty: number;
  receiveAmount?: Amount;
  unit?: string;
  localUnit?: string;
  unitPrice?: Amount;
  unitFactor?: string;

  reasons: ClaimApproveRequestReason[];
}

export interface ClaimApproveRequestReason {
  claimReasonNo: number;
  claimReason: string;
  approvedQty: number;
  approvedAmount: number;
  claimFrom: string;
}

export interface ClaimRejectRequest {
  version: number;
  claimRequestNo: string;
  comment: string;
  toteItems: ToteItemRequest[];
}

export interface ToteItemRequest {
  toteId: string;
  claimStatus: string;
  claimFrom: string;
}

export interface MissingTote {
  toteId: string;
  price: number;
  claimStatus: MissingToteStatusEnum;
  claimFrom: string;
  priceIncVat: Amount;

  expanded?: boolean;
  items?: ClaimRequestItem[];
}

export interface ToteFee {
  amount?: number;
  currency?: string;
}

export interface ClaimRequestItem {
  itemNo: number;
  barcode: string;
  articleNo: string;
  productName: string;
  vatAmountPerUnit?: Amount;
  /***
   * WSP (Inc VAT)
   */
  wholesalePricePerUnit?: Amount;
  /***
   * WSP (VAT)
   */
  wholesalePriceVatPerUnit?: Amount;
  /***
   * WSP (Exc VAT)
   */
  wholesalePriceExcVatPerUnit?: Amount;
  /***
   * RSP (Inc VAT)
   */
  retailPricePerUnit?: Amount;
  /***
   * RSP (VAT)
   */
  retailPriceExcVatPerUnit?: Amount;
  /***
   * RSP (Exc VAT)
   */
  retailPriceVatPerUnit?: Amount;
  isCigarette?: boolean;
  receiveQty?: number;
  reasons: Reason[];
  productType?: string;
  receiveAmount?: Amount;
  unit?: string;
  localUnit?: string;
  unitPrice?: Amount;
  unitFactor?: string;
  vat?: boolean;
}

export interface ClaimRequestItemSubmit {
  itemNo: number;
  barcode: string;
  articleNo: string;
  productName: string;
  vatAmountPerUnit?: Amount;
  receiveQty?: number;
  reasons: ReasonSubmit[];
  productType?: string;
  receiveAmount?: Amount;
  unit?: string;
  localUnit?: string;
  unitPrice?: Amount;
  unitFactor?: string;
}

export interface Reason {
  claimReasonNo: number;
  claimQty: number;
  claimAmount: number;
  claimReason: string;
  claimImageList: ClaimImage[];
  approvedQty: number;
  approvedAmount: number;
  claimFrom: string;
  unit: string;
  approvedExcVatAmount: number;
  approvedVatAmount: number;
  missingToteId?: string;
}

export interface ClaimImage {
  path: string;
  tempPath: string;
}

export interface ReasonSubmit {
  claimReasonNo: number;
  claimQty: number;
  claimAmount: Amount;
  reason: string;
  claimImage: string;
  approvedQty: number;
  approvedAmount: Amount;
  claimFrom: string;
  unit: string;
  approvedExcVatAmount: Amount;
  approvedVatAmount: Amount;
}

/* Claim list */

export class ClaimRequestSearchCriteria {
  searchCriteria?: string;
  status?: ClaimRequestStatusEnum;
  requestedDateFrom?: string;
  requestedDateTo?: string;
  page: number;
  size: number;
}

export class ClaimRequestPagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: ClaimRequestContent[];
}

export class ClaimRequestContent {
  id: string;
  claimRequestNo: string;
  storeCodeName?: string;
  roNo: string;
  orderNo: string;
  storeCode: string;
  storeName: string;
  requestAmt: number;
  requestedDate: string;
  claimRequestStatus: ClaimRequestStatusEnum;
  histories: AuditLogDtoAuditLog[];
  requestedBy: string;
  shipTo: string;
}

export class AuditLogDtoAuditLog {
  activity: string;
  description: string;
  editBy: string;
  editByName: string;
  editDate: string;
}

export class ClaimRequestExportCriteria extends BaseReport {
  requestedDateFrom: string;
  requestedDateTo: string;
  claimStatus: string[];
  warehouseCodes: string;
  fileType: string;

  constructor(init?: Partial<ClaimRequestExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
