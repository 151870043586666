import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { ShelfList, ShelfListSearchCriteria, ShelfViewResponse } from '../../models/shelf.model';
import { ShelfActionType, ShelfActions } from '../actions/shelf.actions';
import { BaseState } from '../state/base.state';

export interface ShelfState extends EntityState<ShelfList>, BaseState {
  selected: ShelfViewResponse;
  criteriaObject: ShelfListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<ShelfList> = createEntityAdapter<ShelfList>();

export const initialShelfResponseState: ShelfState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null
});

export function shelfReducers(state = initialShelfResponseState, action: ShelfActions): ShelfState {
  switch (action.type) {
    case ShelfActionType.SHELF_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ShelfActionType.SHELF_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ShelfActionType.SHELF_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case ShelfActionType.EDIT_SHELF:
      return {
        ...state,
        selected: {
          ...state.selected,
          type: action.payload.type,
          ...(action.payload.status && { status: action.payload.status })
        }
      };
    case ShelfActionType.SHELF_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case ShelfActionType.SHELF_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
