import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { ForceChangePasswordLeftComponent } from './pages/force-change-password/force-change-password-left/force-change-password-left.component';
import { ForceChangePasswordComponent } from './pages/force-change-password/force-change-password.component';
import { ForgotPasswordLeftComponent } from './pages/forgot-password/forgot-password-left/forgot-password-left.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginLeftComponent } from './pages/login/login-left/login-left.component';
import { LoginRightComponent } from './pages/login/login-right.component';
import { OrderListComponent } from './pages/order/order-list/order-list.component';
import { OrderRequestFixAssetComponent } from './pages/order/order-request-fix-asset/order-request-fix-asset.component';
import { OrderRequestInventoryComponent } from './pages/order/order-request-inventory/order-request-inventory.component';
import { OrderRequestListComponent } from './pages/order/order-request-list/order-request-list.component';
import { OrderRequestComponent } from './pages/order/order-request/order-request.component';
import { OrderViewComponent } from './pages/order/order-view/order-view.component';
import { ClassMarkupComponent } from './pages/price-setting/class-markup/class-markup.component';
import { StackPricingViewComponent } from './pages/price-setting/stack-pricing/stack-pricing-view/stack-pricing-view.component';
import { MonthlyPartnerProfitSharingListComponent } from './pages/profit-sharing/monthly-partner-profit-sharing-list/monthly-partner-profit-sharing-list.component';
import { ProfitSharingViewComponent } from './pages/profit-sharing/profit-sharing-view/profit-sharing-view.component';
import { PromotionListComponent } from './pages/promotion/promotion-list/promotion-list.component';
import { PromotionRequestListComponent } from './pages/promotion/promotion-request-list/promotion-request-list.component';
import { PromotionRequestComponent } from './pages/promotion/promotion-request/promotion-request.component';
import { ShelfListComponent } from './pages/shelf/shelf-list/shelf-list.component';
import { ShelfRequestListComponent } from './pages/shelf/shelf-request-list/shelf-request-list.component';
import { ShelfRequestComponent } from './pages/shelf/shelf-request/shelf-request.component';
import { ViewShelfComponent } from './pages/shelf/view-shelf/view-shelf.component';
import { StockInformationComponent } from './pages/stock/stock-information/stock-information.component';
import { StoreListComponent } from './pages/store/store-list/store-list.component';
import { StoreRequestListComponent } from './pages/store/store-request-list/store-request-list.component';
import { StoreRequestComponent } from './pages/store/store-request/store-request.component';
import { ViewStoreComponent } from './pages/store/view-store/view-store.component';
import { UserCreateComponent } from './pages/user/user-create/user-create.component';
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { LoginComponent } from './shared/layouts';
import { AdminLayoutComponent } from './shared/layouts/admin/admin-layout.component';
import { LoginLayoutComponent } from './shared/layouts/login-layout/login-layout.component';
import {
  grManagePermissions,
  grViewPermissions,
  poManagePermissions,
  poViewPermissions,
  prApprovePermissions,
  prManagePermissions,
  prViewPermissions
} from './shared/permissions/permissions';
import { AuthGuardService } from './shared/services';

export const appRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
    children: [
      {
        path: '',
        component: LoginRightComponent
      },
      {
        path: '',
        outlet: 'leftsection',
        component: LoginLeftComponent
      }
    ],
    pathMatch: 'full'
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'forgot-password',
        children: [
          {
            path: '',
            component: ForgotPasswordComponent
          },
          {
            path: '',
            outlet: 'leftsection',
            component: ForgotPasswordLeftComponent
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'force-change-password',
        canActivate: [AuthGuardService],
        children: [
          {
            path: '',
            component: ForceChangePasswordComponent
          },
          {
            path: '',
            outlet: 'leftsection',
            component: ForceChangePasswordLeftComponent
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuardService],
        data: {
          title: 'HOME',
          group: 'HOME',
          icon: 'icon-td-home',
          active: true,
          order: 1,
          isShowOnMenu: true
        },
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'merchant-store',
        data: {
          title: 'MERCHANT_STORE',
          group: 'STORE',
          icon: 'icon-td-store',
          active: false,
          order: 2,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'merchant',
            canActivate: [AuthGuardService],
            data: {
              title: 'MERCHANT',
              isShowOnMenu: true,
              subgroup: 'MERCHANT',
              permissions: [
                'merchant_v',
                'merchant_vs',
                'merchant_key',
                'merchant_new_m',
                'merchant_edit_m',
                'merchant_order_m',
                'merchant_app'
              ]
            },
            loadChildren: () => import('./pages/merchant/merchant.module').then(m => m.MerchantModule)
          },
          {
            path: 'store-list',
            component: StoreListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'STORE',
              isShowOnMenu: true,
              subgroup: 'STORE',
              permissions: [
                'store_v',
                'store_eq',
                'store_locksale',
                'store_terminate',
                'merchant_new_m',
                'merchant_edit_m',
                'merchant_order_m',
                'merchant_app'
              ]
            }
          },
          {
            path: 'store-request-list',
            component: StoreRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'STORE',
              permissions: ['merchant_new_m', 'merchant_edit_m', 'merchant_order_m', 'merchant_app']
            }
          },
          {
            path: 'view-store',
            component: ViewStoreComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'store-request',
            component: StoreRequestComponent,
            canActivate: [AuthGuardService]
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'store-assortment',
        data: {
          title: 'STORE_ASSORTMENT',
          group: 'STORE',
          icon: 'icon-td-store-assortment',
          active: false,
          order: 3,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'store-assortment',
            canActivate: [AuthGuardService],
            data: {
              title: 'STORE_ASSORTMENT',
              isShowOnMenu: true,
              subgroup: 'STORE_ASSORTMENT',
              permissions: [
                'store_assortment_v',
                'store_assortment_m',
                'store_assortment_app',
                'store_assortment_req_v'
              ]
            },
            loadChildren: () =>
              import('./pages/store-assortment/store-assortment.module').then(m => m.StoreAssortmentModule)
          },
          {
            path: 'hold-assortment',
            canActivate: [AuthGuardService],
            data: {
              title: 'HOLD_ASSORTMENT',
              isShowOnMenu: true,
              subgroup: 'HOLD_ASSORTMENT',
              permissions: ['hold_assort_v', 'hold_assort_m', 'hold_assort_app']
            },
            loadChildren: () =>
              import('./pages/permanent-hold/permanent-hold.module').then(m => m.PermanentHoldModule)
          },
          {
            path: 'store-assortment-recommendation',
            canActivate: [AuthGuardService],
            data: {
              title: 'STORE_ASSORTMENT_RECOMMENDATION',
              isShowOnMenu: true,
              subgroup: 'STORE_ASSORTMENT_RECOMMENDATION',
              permissions: ['store_assort_recom_v']
            },
            loadChildren: () =>
              import('./pages/store-assortment-recommendation/store-assortment-recommendation.module').then(
                m => m.StoreAssortmentRecommendationModule
              )
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'task-assignment',
        data: {
          title: 'TASK_RESPONSE',
          group: 'STORE',
          icon: 'icon-task',
          active: false,
          order: 4,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'task-request',
            canActivate: [AuthGuardService],
            data: {
              title: 'TASK',
              isShowOnMenu: true,
              subgroup: 'TASK',
              permissions: ['task_m', 'task_req_v', 'task_v', 'task_req_app']
            },
            loadChildren: () => import('./pages/task/task.module').then(m => m.TaskModule)
          },
          {
            path: 'task-response',
            canActivate: [AuthGuardService],
            data: {
              title: 'RESPONSE',
              isShowOnMenu: true,
              subgroup: 'TASK',
              permissions: ['resp_v', 'resp_app']
            },
            loadChildren: () => import('./pages/task-response/task-response.module').then(m => m.TaskResponseModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'membership',
        data: {
          title: 'MEMBERSHIP',
          group: 'STORE',
          icon: 'icon-newmember',
          active: false,
          order: 5,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'member',
            canActivate: [AuthGuardService],
            data: {
              title: 'MEMBER',
              isShowOnMenu: true,
              subgroup: 'MEMBERSHIP',
              isRegex: false,
              permissions: ['mem_v']
            },
            loadChildren: () => import('./pages/member/member.module').then(m => m.MemberModule)
          },
          {
            path: 'exclude-item',
            canActivate: [AuthGuardService],
            data: {
              title: 'MEMBER_EXCLUDE_ITEM',
              isShowOnMenu: true,
              subgroup: 'MEMBER_EXCLUDE_ITEM',
              isRegex: false,
              permissions: ['exc_item_m', 'exc_item_v']
            },
            loadChildren: () =>
              import('./pages/member-exclude-item/member-exclude-item.module').then(m => m.MemberExcludeItemModule)
          },
          {
            path: 'reward-catalog',
            canActivate: [AuthGuardService],
            data: {
              title: 'REWARD_CATALOG',
              isShowOnMenu: true,
              subgroup: 'REWARD_CATALOG',
              permissions: ['rwc_m', 'rwc_app', 'rwc_req_v', 'rwc_v']
            },
            loadChildren: () =>
              import('./pages/reward-catalog/reward-catalog.module').then(m => m.RewardCatalogModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'order',
        data: {
          title: 'ORDER',
          group: 'STORE',
          icon: 'icon-td-order',
          active: false,
          order: 6,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'order-list',
            component: OrderListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'ORDER',
              isShowOnMenu: true,
              subgroup: 'ORDER',
              permissions: [
                'so_v',
                'or_v',
                'so_firstlot_m',
                'so_special_m',
                'so_app',
                'or_fl_ast_m',
                'or_fl_ast_app',
                'or_st_equip_m',
                'or_fl_inv_m',
                'or_fl_inv_app',
                'or_fl_qty_inv_m',
                'pe_order_m',
                'me_order_m'
              ]
            }
          },
          {
            path: 'order-request-list',
            component: OrderRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'ORDER',
              permissions: [
                'or_v',
                'so_firstlot_m',
                'so_special_m',
                'so_app',
                'or_fl_ast_m',
                'or_fl_ast_app',
                'or_st_equip_m',
                'or_fl_inv_m',
                'or_fl_inv_app',
                'or_fl_qty_inv_m',
                'pe_order_m',
                'me_order_m'
              ]
            }
          },
          {
            path: 'order-view',
            component: OrderViewComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'order-request',
            component: OrderRequestComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'order-request-fix-asset',
            component: OrderRequestFixAssetComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'order-request-inventory',
            component: OrderRequestInventoryComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'group-order-list',
            canActivate: [AuthGuardService],
            data: {
              title: 'GROUP_ORDER',
              isShowOnMenu: true,
              subgroup: 'GROUP_ORDER',
              permissions: ['go_v']
            },
            loadChildren: () => import('./pages/group-order/group-order.module').then(m => m.GroupOrderModule)
          },
          {
            path: 'delivery-order-list',
            canActivate: [AuthGuardService],
            data: {
              title: 'DELIVERY_ORDER_SHIPMENT',
              isShowOnMenu: true,
              subgroup: 'DELIVERY_ORDER_SHIPMENT',
              isRegex: true,
              permissions: [
                '^do_logis_v_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_v_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_m_[a-zA-Z]{2}[0-9]{1,3}$'
              ]
            },
            loadChildren: () =>
              import('./pages/delivery-order/delivery-order-list/delivery-order-list.module').then(
                m => m.DeliveryOrderListModule
              )
          },
          {
            path: 'shipment-list',
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'DELIVERY_ORDER_SHIPMENT',
              isRegex: true,
              permissions: [
                '^do_logis_v_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_m_[a-zA-Z]{2}[0-9]{1,3}$'
              ]
            },
            loadChildren: () =>
              import('./pages/shipment/shipment-list/shipment-list.module').then(m => m.ShipmentListModule)
          },
          {
            path: 'receive-order-list',
            canActivate: [AuthGuardService],
            data: {
              title: 'RECEIVE_ORDER',
              isShowOnMenu: true,
              subgroup: 'RECEIVE_ORDER',
              permissions: ['ro_v', 'cr_m']
            },
            loadChildren: () => import('./pages/receive-order/receive-order.module').then(m => m.ReceiveOrderModule)
          },
          {
            path: 'claim-request',
            canActivate: [AuthGuardService],
            data: {
              title: 'CLAIM',
              isShowOnMenu: true,
              subgroup: 'CLAIM',
              isRegex: false,
              permissions: ['cr_v', 'cr_app', 'cr_m', 'cr_v', 'cr_app']
            },
            loadChildren: () => import('./pages/claim-request/claim-request.module').then(m => m.ClaimRequestModule)
          },
          {
            path: 'hold-order',
            canActivate: [AuthGuardService],
            data: {
              title: 'HOLD_ORDER',
              isShowOnMenu: true,
              subgroup: 'HOLD_ORDER',
              isRegex: false,
              permissions: ['ho_pn_m', 'ho_td_m', 'ho_req_v', 'ho_v']
            },
            loadChildren: () => import('./pages/hold-order/hold-order.module').then(m => m.HoldOrderModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'billing',
        data: {
          title: 'BILLING',
          group: 'STORE',
          icon: 'icon-td-bill',
          active: false,
          order: 7,
          isShowOnMenu: true
        },
        children: [
          // {
          //   path: 'bill-payment-list',
          //   component: CompensateListComponent,
          //   canActivate: [AuthGuardService],
          //   data: {
          //     title: 'BILL_PAYMENT',
          //     isShowOnMenu: true,
          //     subgroup: 'BILL_PAYMENT'
          //   }
          // },
          {
            path: 'profit-sharing',
            canActivate: [AuthGuardService],
            data: {
              title: 'PROFIT_SHARING',
              isShowOnMenu: true,
              subgroup: 'PROFIT_SHARING',
              permissions: ['profitsharing_v', 'profitsharing_m']
            },
            loadChildren: () =>
              import('./pages/profit-sharing/profit-sharing.module').then(m => m.ProfitSharingModule)
          },
          {
            path: 'profit-sharing-view',
            component: ProfitSharingViewComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['profitsharing_v', 'profitsharing_m']
            }
          },
          {
            path: 'refund',
            canActivate: [AuthGuardService],
            data: {
              title: 'REFUND',
              isShowOnMenu: true,
              permissions: ['rf_m', 'rf_app', 'rf_req_v', 'rf_v', 'rf_cf']
            },
            loadChildren: () => import('./pages/refund/refund.module').then(m => m.RefundModule)
          },
          {
            path: 'monthly-partner-profit-sharing-list',
            component: MonthlyPartnerProfitSharingListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'MONTHLY_PROFIT_SHARING',
              subgroup: 'PROFIT_SHARING',
              permissions: ['profitsharing_m']
            }
          },
          {
            path: 'late-payment-notice',
            canActivate: [AuthGuardService],
            data: {
              title: 'LATE_PAYMENT_NOTICE',
              isShowOnMenu: true,
              subgroup: 'LATE_PAYMENT_NOTICE',
              isRegex: false,
              permissions: ['late_notice_m', 'late_notice_v']
            },
            loadChildren: () =>
              import('./pages/late-payment-notice/late-payment-notice.module').then(m => m.LatePaymentNoticeModule)
          },
          {
            path: 'pre-order-credit-term',
            canActivate: [AuthGuardService],
            data: {
              title: 'PRE_ORDER_CREDIT_TERM',
              isShowOnMenu: true,
              subgroup: 'PRE_ORDER_CREDIT_TERM',
              isRegex: false,
              permissions: ['sp_credit_m', 'sp_credit_v']
            },
            loadChildren: () =>
              import('./pages/pre-order-credit-term/pre-order-credit-term.module').then(
                m => m.PreOrderCreditTermModule
              )
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'products',
        data: {
          title: 'PRODUCT',
          group: 'ADMIN',
          icon: 'icon-td-product',
          active: false,
          order: 8,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'master-list',
            canActivate: [AuthGuardService],
            data: {
              title: 'PRODUCT_MASTER',
              isShowOnMenu: true,
              subgroup: 'PRODUCT_MASTER',
              permissions: ['cjproduct_m_v', 'cjproduct_nm_v', 'tdassort_inv_m']
            },
            loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule)
          },
          {
            path: 'request-list',
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'PRODUCT_ASSORTMENT',
              permissions: [
                'tdassort_inv_m',
                'tdassort_asset_m',
                'tdassort_sto_m',
                'tdassort_inv_app',
                'tdassort_asset_app',
                'tdassort_sto_app',
                'tdassort_inv_req_v',
                'tdassort_asset_req_v',
                'tdassort_sto_req_v'
              ]
            },
            loadChildren: () =>
              import('./pages/assortment-request/assortment-request.module').then(m => m.AssortmentRequestModule)
          },
          {
            path: 'product-list',
            canActivate: [AuthGuardService],
            data: {
              title: 'PRODUCT_ASSORTMENT',
              isShowOnMenu: true,
              subgroup: 'PRODUCT_ASSORTMENT',
              permissions: [
                'tdassort_inv_m',
                'tdassort_asset_m',
                'tdassort_sto_m',
                'tdassort_inv_app',
                'tdassort_asset_app',
                'tdassort_sto_app',
                'tdassort_inv_v',
                'tdassort_asset_v',
                'tdassort_sto_v',
                'tdassort_inv_req_v',
                'tdassort_asset_req_v',
                'tdassort_sto_req_v'
              ]
            },
            loadChildren: () =>
              import('./pages/assortment-product/assortment-product-list/assortment-product-list.module').then(
                m => m.AssortmentProductListModule
              )
          },
          {
            path: 'stack-pricing-edit',
            component: StackPricingViewComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['price_m_v', 'price_m_m', 'price_nm_v', 'price_nm_m']
            }
          },
          {
            path: 'class-markup',
            component: ClassMarkupComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['price_m_v', 'price_m_m', 'price_nm_v', 'price_nm_m']
            }
          },
          {
            path: 'shelf-list',
            component: ShelfListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'SHELF',
              isShowOnMenu: true,
              subgroup: 'SHELF',
              permissions: ['shelf_v', 'shelf_m', 'shelf_app', 'shelf_firstlot_m']
            }
          },
          {
            path: 'shelf-request-list',
            component: ShelfRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'SHELF',
              permissions: ['shelf_m', 'shelf_app', 'shelf_firstlot_m']
            }
          },
          {
            path: 'shelf-request',
            component: ShelfRequestComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'view-shelf',
            component: ViewShelfComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'pre-order',
            canActivate: [AuthGuardService],
            data: {
              title: 'PRE_ORDER',
              isShowOnMenu: true,
              subgroup: 'PRE_ORDER',
              permissions: ['pe_cat_m', 'pe_cat_v', 'pe_fls_m']
            },
            loadChildren: () => import('./pages/pre-order/pre-order.module').then(m => m.PreOrderModule)
          },
          {
            path: 'not-for-sale',
            canActivate: [AuthGuardService],
            data: {
              title: 'NOT_FOR_SALE_ITEM',
              isShowOnMenu: true,
              subgroup: 'NOT_FOR_SALE_ITEM',
              isRegex: false,
              permissions: ['notsale_item_m', 'notsale_item_v']
            },
            loadChildren: () =>
              import('./pages/not-for-sale-item/not-for-sale-item.module').then(m => m.NotForSaleItemModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'shelf',
        data: {
          title: 'SHELF',
          group: 'ADMIN',
          icon: 'icon-shelf',
          active: false,
          order: 9,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'shelf-inventory',
            canActivate: [AuthGuardService],
            data: {
              title: 'SHELF_INVENTORY',
              isShowOnMenu: true,
              subgroup: 'SHELF_INVENTORY',
              permissions: ['sh_inv_rq_v', 'sh_inv_v', 'sh_inv_m', 'sh_inv_app']
            },
            loadChildren: () =>
              import('./pages/shelf/shelf-inventory/shelf-inventory.module').then(m => m.ShelfInventoryModule)
          },
          {
            path: 'shelf-fix-asset',
            canActivate: [AuthGuardService],
            data: {
              title: 'SHELF_FIX_ASSET',
              isShowOnMenu: true,
              subgroup: 'SHELF_FIX_ASSET',
              permissions: ['sh_ast_v', 'sh_ast_rq_v', 'sh_ast_m', 'sh_ast_app']
            },
            loadChildren: () =>
              import('./pages/shelf/shelf-fix-asset/shelf-fix-asset.module').then(m => m.ShelfFixAssetModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'campaign',
        data: {
          title: 'CAMPAIGN',
          group: 'ADMIN',
          icon: 'icon-td-marketing',
          active: false,
          order: 10,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'promotion-list',
            component: PromotionListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'PROMOTION',
              isShowOnMenu: true,
              subgroup: 'PROMOTION',
              permissions: ['promotion_m', 'promotion_app', 'promotion_v']
            }
          },
          {
            path: 'promotion-request-list',
            component: PromotionRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'PROMOTION',
              permissions: ['promotion_m', 'promotion_app']
            }
          },
          {
            path: 'promotion-request-create',
            component: PromotionRequestComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['promotion_m', 'promotion_app']
            }
          },
          {
            path: 'promotion-v2',
            canActivate: [AuthGuardService],
            data: {
              title: 'PROMOTION_V2',
              isShowOnMenu: environment.prefixCompany === 'CJX' ? true : false,
              subgroup: 'PROMOTION_V2'
            },
            loadChildren: () => import('./pages/promotion-v2/promotion-v2.module').then(m => m.PromotionV2Module)
          },
          {
            path: 'voucher',
            canActivate: [AuthGuardService],
            data: {
              title: 'VOUCHER',
              isShowOnMenu: true,
              subgroup: 'VOUCHER',
              permissions: ['voucher_m', 'voucher_v', 'voucher_app']
            },
            loadChildren: () => import('./pages/voucher/voucher.module').then(m => m.VoucherModule)
          },
          {
            path: 'reward',
            canActivate: [AuthGuardService],
            data: {
              title: 'REWARD',
              isShowOnMenu: true,
              subgroup: 'REWARD',
              isRegex: false,
              permissions: ['rw_v', 'rw_m', 'rr_v', 'rw_app']
            },
            loadChildren: () => import('./pages/reward/reward.module').then(m => m.RewardModule)
          },
          {
            path: 'member-point',
            canActivate: [AuthGuardService],
            data: {
              title: 'MEMBER_POINT',
              isShowOnMenu: true,
              subgroup: 'MEMBER_POINT',
              isRegex: false,
              permissions: ['point_m', 'point_app', 'point_v', 'point_req_v']
            },
            loadChildren: () => import('./pages/member-point/member-point.module').then(m => m.MemberPointModule)
          },
          {
            path: 'online-coupon',
            canActivate: [AuthGuardService],
            data: {
              title: 'ONLINE_COUPON',
              isShowOnMenu: true,
              subgroup: 'ONLINE_COUPON',
              isRegex: false,
              permissions: ['oc_m', 'oc_v', 'oc_req_v', 'oc_app']
            },
            loadChildren: () => import('./pages/online-coupon/online-coupon.module').then(m => m.OnlineCouponModule)
          },
          {
            path: 'pos-advertisement',
            canActivate: [AuthGuardService],
            data: {
              title: 'POS_ADVERTISEMENT',
              isShowOnMenu: true,
              subgroup: 'POS_ADVERTISEMENT',
              permissions: ['pos_ads_m', 'pos_ads_v']
            },
            loadChildren: () =>
              import('./pages/pos-advertisement/pos-advertisement.module').then(m => m.PosAdvertisementModule)
          },
          {
            path: 'create-audience',
            data: {
              title: 'CREATE_AUDIENCE',
              isShowOnMenu: true,
              subgroup: 'CREATE_AUDIENCE',
              permissions: ['aud_m', 'aud_v']
            },
            loadChildren: () =>
              import('./pages/create-audience/create-audience.module').then(m => m.CreateAudienceModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'purchase',
        data: {
          title: 'PURCHASE',
          group: 'ADMIN',
          icon: 'icon-td-purchase',
          active: false,
          order: 11,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'purchase-request',
            canActivate: [AuthGuardService],
            data: {
              title: 'PR_PO_GR',
              isShowOnMenu: true,
              subgroup: 'PR_PO_GR',
              isRegex: true,
              permissions: [
                ...prViewPermissions,
                ...prManagePermissions,
                ...prApprovePermissions,
                ...poViewPermissions,
                ...poManagePermissions,
                ...grViewPermissions,
                ...grManagePermissions
              ]
            },
            loadChildren: () => import('./pages/purchase/purchase.module').then(m => m.PurchaseModule)
          },
          // {
          //   path: 'purchase-request-list',
          //   component: PurchaseRequestListComponent,
          //   canActivate: [AuthGuardService],
          //   data: {
          //     title: 'PR_PO_GR',
          //     isShowOnMenu: true,
          //     subgroup: 'PR_PO_GR',
          //     isRegex: true,
          //     permissions: [
          //       ...prViewPermissions,
          //       ...prManagePermissions,
          //       ...prApprovePermissions,
          //       ...poViewPermissions,
          //       ...poManagePermissions,
          //       ...grViewPermissions,
          //       ...grManagePermissions
          //     ]
          //   }
          // },
          // {
          //   path: 'purchase-request-view',
          //   component: PurchaseRequestViewComponent,
          //   canActivate: [AuthGuardService],
          //   data: {
          //     isRegex: true,
          //     permissions: [...prViewPermissions, ...prManagePermissions, ...prApprovePermissions]
          //   }
          // },
          // {
          //   path: 'purchase-order-list',
          //   component: PurchaseOrderListComponent,
          //   canActivate: [AuthGuardService],
          //   data: {
          //     subgroup: 'PR_PO_GR',
          //     isRegex: true,
          //     permissions: [...poViewPermissions, ...poManagePermissions, ...grManagePermissions]
          //   }
          // },
          // {
          //   path: 'goods-receive-list',
          //   component: GoodsReceiveListComponent,
          //   canActivate: [AuthGuardService],
          //   data: {
          //     subgroup: 'PR_PO_GR',
          //     isRegex: true,
          //     permissions: [...grViewPermissions, ...grManagePermissions]
          //   }
          // },
          // {
          //   path: 'goods-receive-view',
          //   component: GoodsReceiveViewComponent,
          //   canActivate: [AuthGuardService],
          //   data: {
          //     isRegex: true,
          //     permissions: [...grViewPermissions, ...grManagePermissions]
          //   }
          // },
          {
            path: 'purchase-condition',
            canActivate: [AuthGuardService],
            data: {
              title: 'PURCHASE_CONDITION',
              isShowOnMenu: true,
              subgroup: 'PURCHASE_CONDITION',
              isRegex: false,
              permissions: ['pc_req_m', 'pc_req_app', 'pc_req_v', 'pc_v']
            },
            loadChildren: () =>
              import('./pages/purchase-condition/purchase-condition.module').then(m => m.PurchaseConditionModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'stock',
        data: {
          title: 'STOCK',
          group: 'ADMIN',
          icon: 'icon-stock',
          active: false,
          order: 12,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'stock-information',
            canActivate: [AuthGuardService],
            data: {
              title: 'STOCK_INFORMATION',
              isShowOnMenu: true,
              subgroup: 'STOCK_INFORMATION',
              permissions: ['^stk_v_[a-zA-Z]{2}[0-9]{1,3}$', 'stk_v_sto', 'stk_v_all'],
              isRegex: true
            },
            loadChildren: () => import('./pages/stock/stock.module').then(m => m.StockModule)
          },
          {
            path: 'stock-information',
            component: StockInformationComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: []
            }
          },
          {
            path: 'manage-stock',
            canActivate: [AuthGuardService],
            data: {
              title: 'STOCK_ADJUSTMENT',
              isShowOnMenu: true,
              subgroup: 'STOCK_ADJUSTMENT',
              permissions: [
                'stkadj_m_sto',
                'stkadj_v_sto',
                '^stkadj_v_[a-zA-Z]{2}[0-9]{1,3}$',
                '^stkadj_m_[a-zA-Z]{2}[0-9]{1,3}$'
              ],
              isRegex: true
            },
            loadChildren: () => import('./pages/manage-stock/manage-stock.module').then(m => m.ManageStockModule)
          },
          {
            path: 'store-stock-planning',
            canActivate: [AuthGuardService],
            data: {
              title: 'STORE_STOCK_PLANNING',
              isShowOnMenu: true,
              subgroup: 'STORE_STOCK_PLANNING',
              permissions: ['^ssr_v_[a-zA-Z]{2}[0-9]{1,3}$'],
              isRegex: true
            },
            loadChildren: () =>
              import('./pages/store-stock-planning/store-stock-planning.module').then(m => m.StoreStockPlanningModule)
          },
          {
            path: 'stock-transfer',
            canActivate: [AuthGuardService],
            data: {
              title: 'STOCK_TRANSFER',
              isShowOnMenu: true,
              subgroup: 'STOCK_TRANSFER',
              permissions: ['tr_m', 'to_v', 'tr_v', 'tr_app', 'to_gr_v']
            },
            loadChildren: () =>
              import('./pages/stock-transfer/stock-transfer.module').then(m => m.StockTransferModule)
          },
          {
            path: 'return-to-warehouse',
            canActivate: [AuthGuardService],
            data: {
              title: 'RETURN-TO-WAREHOUSE',
              isShowOnMenu: true,
              subgroup: 'RETURN-TO-WAREHOUSE',
              permissions: ['rtw_m', 'rtw_r_v', 'rtw_v', 'rtw_gr_v', 'rtw_req_app'],
              isRegex: false
            },
            loadChildren: () =>
              import('./pages/return-to-warehouse/return-to-warehouse.module').then(m => m.ReturnToWarehouseModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'tote',
        data: {
          title: 'TOTE',
          group: 'ADMIN',
          icon: 'icon-tote',
          active: false,
          order: 12,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'tote-information',
            canActivate: [AuthGuardService],
            data: {
              title: 'TOTE_INFORMATION',
              isShowOnMenu: true,
              subgroup: 'TOTE_INFORMATION',
              isRegex: false,
              permissions: ['tote_info_v']
            },
            loadChildren: () =>
              import('./pages/tote-information/tote-information.module').then(m => m.ToteInformationModule)
          },
          {
            path: 'tote-adjustment',
            canActivate: [AuthGuardService],
            data: {
              title: 'TOTE_ADJUSTMENT',
              isShowOnMenu: true,
              subgroup: 'TOTE_ADJUSTMENT',
              isRegex: false,
              permissions: ['tote_adjust_v']
            },
            loadChildren: () =>
              import('./pages/tote-adjustment/tote-adjustment.module').then(m => m.ToteAdjustmentModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'supplier',
        data: {
          title: 'SUPPLIER',
          group: 'ADMIN',
          icon: 'icon-td-supplier',
          active: false,
          order: 13,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'supplier',
            canActivate: [AuthGuardService],
            data: {
              title: 'SUPPLIER',
              isShowOnMenu: true,
              subgroup: 'SUPPLIER',
              permissions: ['supplier_m', 'supplier_v', 'supplier_set_m']
            },
            loadChildren: () => import('./pages/supplier/supplier.module').then(m => m.SupplierModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'workflow',
        data: {
          title: 'WORKFLOW',
          group: 'ADMIN',
          icon: 'icon-approval-workflow',
          active: false,
          order: 14,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'approval-workflow',
            canActivate: [AuthGuardService],
            data: {
              title: 'APPROVAL_WORKFLOW',
              isShowOnMenu: true,
              subgroup: 'APPROVAL_WORKFLOW',
              isRegex: false,
              permissions: ['app_workflow_m', 'app_workflow_v']
            },
            loadChildren: () =>
              import('./pages/approval-workflow/approval-workflow.module').then(m => m.ApprovalWorkflowModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'user-management',
        data: {
          title: 'USER_MANAGEMENT',
          group: 'ADMIN',
          icon: 'icon-td-user',
          active: false,
          order: 15,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'role-list',
            canActivate: [AuthGuardService],
            data: {
              title: 'ROLE',
              isShowOnMenu: true,
              subgroup: 'ROLE',
              permissions: ['role_m', 'role_v']
            },
            loadChildren: () => import('./pages/roles/roles.module').then(m => m.RolesModule)
          },
          {
            path: 'store-group',
            canActivate: [AuthGuardService],
            data: {
              title: 'STORE_GROUP',
              isShowOnMenu: true,
              subgroup: 'STORE_GROUP',
              permissions: ['store_group_m', 'store_group_v']
            },
            loadChildren: () => import('./pages/store-group/store-group.module').then(m => m.StoreGroupModule)
          },
          {
            path: 'store-consultant',
            canActivate: [AuthGuardService],
            data: {
              title: 'STORE_CONSULTANT',
              isShowOnMenu: true,
              subgroup: 'STORE_CONSULTANT',
              permissions: ['store_consult_m', 'store_consult_v']
            },
            loadChildren: () =>
              import('./pages/store-consultant/store-consultant.module').then(m => m.StoreConsultantModule)
          },
          {
            path: 'user-list',
            component: UserListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'USER',
              isShowOnMenu: true,
              subgroup: 'USERS',
              permissions: ['user_bo_m', 'user_bo_v']
            }
          },
          {
            path: 'user-create',
            component: UserCreateComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['user_bo_m']
            }
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'setting',
        canActivate: [AuthGuardService],
        data: {
          title: 'SETTING',
          group: 'ADMIN',
          icon: 'icon-setting',
          active: false,
          order: 16,
          isShowOnMenu: true,
          permissions: [
            'product_wh_s',
            'prod_cat_inv_s',
            'prod_cat_fix_s',
            'pre_cat_s',
            'sap_store_code_s',
            'wh_oper_s',
            'order_flow_s',
            'sale_schedule_s'
          ]
        },
        loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'reports',
        canActivate: [AuthGuardService],
        data: {
          title: 'REPORT',
          group: 'ADMIN',
          icon: 'icon-td-report',
          active: false,
          order: 16,
          isShowOnMenu: true,
          permissions: [
            'rep_voucher_v',
            'rep_sup_cp_rec_v',
            'rep_rewardtx_v',
            'rep_redeemtx_v',
            'rep_pec_v',
            'rep_point_earn_v',
            'rep_point_burn_v',
            'rep_me_tx_v',
            'rep_me_acc_v',
            'rep_oc_tx_v',
            'rep_store_v',
            'rep_storec_v',
            'rep_store_sch_v',
            'rep_st_lo_dc_v',
            'rep_task_v',
            'rep_resp_v',
            'rep_survey_v',
            'rep_moneyin_v',
            'rep_sales_summary_v',
            'rep_saletx_v',
            'rep_saletxc_v',
            'rep_rf_sum_v',
            'rep_rftxc_v',
            'rep_pe_tx_v',
            'rep_pe_acc_v',
            'rep_bill_payment_v',
            'rep_ps_v',
            'rep_promotion_compensate_v',
            'rep_pe_com_v',
            'rep_sp_pt_com_v',
            'rep_order_v',
            'rep_do_v',
            'rep_shipment_v',
            'rep_ro_v',
            'rep_roexceed_v',
            'rep_claim_v',
            'rep_awaiting_firstlot_v',
            'rep_await_go_v',
            'rep_go_unpick_v',
            'rep_stock_pro_v',
            'rep_stock_loc_v',
            'rep_sa_v',
            'rep_stockcard_v',
            'rep_to_v',
            'rep_to_gr_v',
            'rep_rtw_order_v',
            'rep_rtw_gr_v',
            'rep_inventory_count_v',
            'rep_inventorycount_tmp_v',
            'rep_inventory_adj_req_wp_v',
            'rep_inventory_vr_v',
            'rep_dt_v',
            'rep_dt_tns_v',
            'rep_td_assort_v',
            'rep_tdassortc_v',
            'rep_td_assort_loc_v',
            'rep_dcassortment_v',
            'rep_tdmoving_avg_v',
            'rep_pe_cat_v',
            'rep_shelf_fix_v',
            'rep_shelf_inv_v',
            'rep_store_assort_v',
            'rep_hold_assort_v',
            'rep_temp_hold_v',
            'rep_pr_v',
            'rep_po_v',
            'rep_gr_v',
            'rep_cross_dock_invoice_v',
            'rep_user_v',
            'rep_sc_v',
            'rep_supplier_v',
            'rep_hold_order_v',
            'rep_promo_v',
            'rep_rw_v',
            'rep_sppoint_v',
            'rep_oc_v',
            'rep_me_cat_v',
            'rep_pe_credit_v',
            'rep_oc_com_v',
            'rep_oc_summary_v',
            'rep_tote_adjust_v',
            'rep_store_tote_v',
            'rep_tote_fine_v',
            'rep_tote_inout_v',
            'rep_tote_in_track_v',
            'rep_mn_chg_status_v'
          ]
        },
        loadChildren: () => import('./pages/new-reports/new-report.module').then(m => m.NewReportModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'customer360',
        canActivate: [AuthGuardService],
        data: {
          title: 'CUSTOMER_360',
          group: 'DATA_INSIGHT',
          icon: 'icon-customer-360',
          active: false,
          order: 2,
          isShowOnMenu: true,
          permissions: ['cus_360_m']
        },
        loadChildren: () => import('./pages/customer360/customer360.module').then(m => m.Customer360Module)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'nest-dashboard',
        data: {
          title: 'DASHBOARD',
          group: 'DATA_INSIGHT',
          icon: 'icon-dashboard',
          active: false,
          order: 3,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'flash-sale',
            canActivate: [AuthGuardService],
            data: {
              title: 'FLASH_SALE',
              isShowOnMenu: true,
              subgroup: 'FLASH_SALE',
              permissions: ['fls_dash_v']
            },
            loadChildren: () =>
              import('./pages/flash-sale-dashboard/flash-sale-dashboard.module').then(m => m.FlashSaleDashboardModule)
          }
        ]
      }
    ]
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
