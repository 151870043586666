<div class="custom-control custom-switch">
  <input
    type="checkbox"
    class="custom-control-input"
    id="switchlanguage"
    [(ngModel)]="status"
    (change)="changeToggleStatus()"
  />
  <label class="custom-control-label" for="switchlanguage">{{ labelToggle }}</label>
</div>
