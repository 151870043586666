import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import { MyReportContent, MyReportListResponse, MyReportSearchCriteria } from '../../models/my-report.model';
import { MyReportAction, MyReportActionTypes } from '../actions/my-report.actions';

export const myReportModuleReducers: ActionReducerMap<{ myReport: MyReportState }> = {
  myReport: MyReportReducers
};

export interface MyReportState extends EntityState<MyReportContent> {
  isLoading: boolean;
  criteriaObject: MyReportSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  selected: MyReportListResponse | null;
}

export const adapter: EntityAdapter<MyReportContent> = createEntityAdapter<MyReportContent>();

export const initialMyReportListState: MyReportState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  auditLogs: null,
  selected: null
});

export function MyReportReducers(state = initialMyReportListState, action: MyReportAction): MyReportState {
  switch (action.type) {
    case MyReportActionTypes.MY_REPORT_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload === null ? { ...state.criteriaObject } : action.payload
      };
    case MyReportActionTypes.MY_REPORT_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
