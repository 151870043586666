import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-modal-template-container',
  templateUrl: './modal-template-container.component.html',
  styleUrls: ['./modal-template-container.component.scss']
})
export class ModalTemplateContainerComponent implements OnInit {
  @ViewChild('multiDescAndHeader', { static: false }) multiDescAndHeader: TemplateRef<HTMLElement>;

  public dateTimeDisplay = environment.dateTimeDisplay;

  constructor() {}

  ngOnInit(): void {}
}
