import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import {
  ResponseAssignmentContent,
  ResponseAssignmentListSearchCriteria,
  TaskAssignmentResponseModel
} from '../../models/task-assignment-response.model';
import { ResponseAssignmentActionType, ResponseAssignmentActions } from '../actions/response-assignment.actions';
import { BaseState } from '../state/base.state';

export interface ResponseAssignmentState extends EntityState<ResponseAssignmentContent>, BaseState {
  selected: TaskAssignmentResponseModel;
  criteriaObject: ResponseAssignmentListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  isDeleteSuccess: boolean;
}

export const adapter: EntityAdapter<ResponseAssignmentContent> = createEntityAdapter<ResponseAssignmentContent>();

export const initialResponseAssignmentResponseState: ResponseAssignmentState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  isDeleteSuccess: null
});

export function responseAssignmentReducers(
  state = initialResponseAssignmentResponseState,
  action: ResponseAssignmentActions
): ResponseAssignmentState {
  switch (action.type) {
    case ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };

    case ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
