import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ClassMarkup, ClassMarkupResponse, ClassMarkupSearchCriteria } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class ClassMarkupService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.priceSetting;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteria(criteria: ClassMarkupSearchCriteria): Observable<ClassMarkupResponse> {
    const url = this.getFullUrl(this.envService.request);
    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  updateById(data: ClassMarkup): Observable<any> {
    const url = this.getFullUrl(this.envService.request);
    return this.http.post<any>(url, data, { headers: this.headers });
  }

  getById(classCode: string): Observable<any> {
    const url = this.getFullUrl(this.envService.get, { classCode });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
}
