import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApproveStatusEnum } from '../enum/approve-status.enum';
import {
  ChangeStatusRewardCatalogRequest,
  RewardCatalog,
  RewardCatalogExportCriteria,
  RewardCatalogListResponse,
  RewardCatalogListSearchCriteria,
  RewardCatalogRequestResponse,
  RewardCatalogViewResponse
} from '../models/reward-catalog.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class RewardCatalogService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.rewardCatalog;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchRewardCatalogByCriteria(
    criteria: RewardCatalogListSearchCriteria
  ): Observable<RewardCatalogListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null),
      observe: 'body',
      params
    });
  }

  public getRewardCatalogByRequestNo(reward: RewardCatalog): Observable<RewardCatalogViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      requestNo: reward.requestNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getHistoryLogs(catalogId: string): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      catalogId
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public changeRequestStatus(request: ChangeStatusRewardCatalogRequest): Observable<RewardCatalogRequestResponse> {
    let url = '';

    if (request.status === ApproveStatusEnum.APPROVED) {
      url = this.getFullUrl(this.envService.approve);
    } else if (request.status === ApproveStatusEnum.REJECTED) {
      url = this.getFullUrl(this.envService.reject);
    } else if (request.status === ApproveStatusEnum.CANCELLED) {
      url = this.getFullUrl(this.envService.cancel);
    }

    const changeStatusRequest = { id: request.id, comment: request.comment };

    return this.http.post<RewardCatalogRequestResponse>(url, changeStatusRequest, {
      headers: this.loaderHeaders(2000),
      observe: 'body'
    });
  }

  cancel(cancelData: ChangeStatusRewardCatalogRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.cancel);
    const cancelRewardCatalog = { id: cancelData.id, comment: cancelData.comment };

    return this.http.post<any>(url, cancelRewardCatalog, {
      headers: this.loaderHeaders(2000),
      observe: 'body'
    });
  }

  exportRewardCatalog(exportCriteria: RewardCatalogExportCriteria): Observable<any> {
    const params = this.getParams(exportCriteria);
    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportStore(rewardCatalogNo: string): Observable<any> {
    const url = this.getFullUrl(this.envService.exportStore, {
      rewardCatalogNo: rewardCatalogNo
    });

    return this.http.get<any>(url, { headers: this.headers, responseType: 'blob' as 'json' });
  }
}
