import { EventEmitter, Injectable, Output, Type } from '@angular/core';

import { ChildComponent } from './child-component';

@Injectable()
export class ChildItem implements ChildComponent {
  @Output() notifyParent: EventEmitter<any> = new EventEmitter<any>();

  constructor(public component: Type<any>, public data: any, public isRequiredLeaveForm: boolean) {}
}
