import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { FreshLiteService } from '../../services/fresh-lite.service';
import {
  FreshLiteActionTypes,
  FreshLiteListLoaded,
  FreshLiteListRequest,
  UpdateFreshLiteRequest,
  UpdateFreshLiteResponse
} from '../actions/fresh-lite.actions';
import { LayoutActionLoadError } from '../actions/layout.action';

@Injectable()
export class FreshLiteEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly freshLiteService: FreshLiteService,
    private readonly logger: NGXLogger
  ) {}

  searchFreshLite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<FreshLiteListRequest>(FreshLiteActionTypes.FRESH_LITE_LIST_REQUEST),
      tap(() => this.logger.debug('@Effect Fresh Lite List Requested: ')),
      switchMap(() =>
        this.freshLiteService.searchFreshLite().pipe(
          map(response => {
            response.forEach((res, index) => (res.index = index.toString()));
            return new FreshLiteListLoaded(response);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  updateFreshLite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UpdateFreshLiteRequest>(FreshLiteActionTypes.UPDATE_FRESH_LITE_REQUEST),
      tap(action => this.logger.debug('@Effect Fresh Lite Update Requested: ' + JSON.stringify(action.payload))),
      switchMap(action =>
        this.freshLiteService.addFreshLiteList(action.payload).pipe(
          map(response => new UpdateFreshLiteResponse(response)),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });
}
