import { Injectable } from '@angular/core';
import {
  ParsingOptions,
  Sheet2JSONOpts,
  SheetJSONOpts,
  StyleZ,
  WorkBook,
  WorkSheet,
  WritingOptions,
  read,
  readFile,
  utils,
  write,
  writeFile
} from '@sheet/core';

export interface HeaderSheet {
  title: string;
  headers: string[];
}

export enum AlignmentEnum {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
}

export enum ColumnTypeEnum {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  CURRENCY = 'currency',
  TEXT_CENTER = 'text_center',
  TEXT_RIGHT = 'text_right'
}

export interface ColumnType {
  [column: string]: ColumnTypeEnum;
}

@Injectable()
export class ExcelUtil {
  constructor() {
    // intentionally empty
  }

  public static GeneratedSheet(title: string, items: any, sheetName = 'Sheet1', showTitle = true): WorkBook {
    const workBook: WorkBook = utils.book_new();
    const workSheet: WorkSheet = utils.aoa_to_sheet([[title]]);
    utils.sheet_add_json(workSheet, items, {
      origin: showTitle ? -1 : 0 // append to bottom of worksheet starting on first column
    });

    utils.book_append_sheet(workBook, workSheet, sheetName);

    return workBook;
  }

  public static GeneratedWorkSheet(
    headerSheet: HeaderSheet,
    items: any,
    columnType: ColumnType,
    opts?: SheetJSONOpts
  ): WorkSheet {
    const { title, headers } = headerSheet;
    const dataHeader = [];

    if (title.length) {
      dataHeader.push([title]);
    }

    if (headers.length) {
      dataHeader.push(headers);
    }

    const startSetStyleColumn = dataHeader.length;

    const workSheet: WorkSheet = utils.aoa_to_sheet([...dataHeader]);

    const startFromRow = title ? 1 : 0;

    if (title) {
      this.setHeaderStyle(workSheet, 0, 'title');
      this.setHeaderStyle(workSheet, 1, 'header');
    } else {
      this.setHeaderStyle(workSheet, 0, 'header');
    }
    this.setBorderStyle(workSheet, startFromRow);
    this.setAutoFitColumn(workSheet, startFromRow);

    utils.sheet_add_json(workSheet, items, {
      origin: -1,
      skipHeader: true,
      ...opts
    });

    this.sheetSetColumnStyle(workSheet, columnType, startSetStyleColumn);

    return workSheet;
  }

  public static GeneratedWorkBook(workSheet: WorkSheet, sheetName = 'Sheet1'): WorkBook {
    const workBook: WorkBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, sheetName);
    return workBook;
  }

  public static setAutoFitColumn(workSheet: WorkSheet, startFromRow = 1) {
    if (!workSheet['!cols']) {
      workSheet['!cols'] = [];
    }

    const range = utils.decode_range(workSheet['!ref']);
    for (let column = startFromRow; column <= range.e.c; column++) {
      workSheet['!cols'][column] = { auto: 1 };
    }
  }

  public static setBorderStyle(workSheet: WorkSheet, startFromRow = 1) {
    const range = utils.decode_range(workSheet['!ref']);

    utils.sheet_set_range_style(
      workSheet,
      `${utils.encode_col(range.s.c)}${utils.encode_row(startFromRow)}:${utils.encode_col(
        range.e.c
      )}${utils.encode_row(range.e.r)}`,
      this.cellStyle()['border_thin']
    );
  }

  public static setHeaderStyle(workSheet: WorkSheet, startFromRow = 1, cellStyle: string) {
    const range = utils.decode_range(workSheet['!ref']);
    range.s.r = startFromRow;
    range.e.r = startFromRow;
    const newRange = utils.encode_range(range);
    utils.sheet_set_range_style(workSheet, newRange, this.cellStyle()[cellStyle]);
  }

  public static sheetSetColumnStyle(workSheet, columns, startFromRow = 1) {
    const range = utils.decode_range(workSheet['!ref']);

    for (const [key, value] of Object.entries(columns)) {
      let styleColumns: StyleZ = this.cellStyle()[value.toString()];

      if (styleColumns === undefined || styleColumns === null) {
        styleColumns = this.cellStyle()[ColumnTypeEnum.TEXT];
      }

      utils.sheet_set_range_style(
        workSheet,
        `${key}${utils.encode_row(startFromRow)}:${key}${utils.encode_row(range.e.r)}`,
        { ...styleColumns, ...this.cellStyle()['border_thin'] } as StyleZ
      );
    }
  }

  private static cellStyle(): any {
    const border = 'thin';

    return {
      ...{
        title: {
          bold: true,
          sz: 16,
          alignment: {
            vertical: AlignmentEnum.CENTER,
            horizontal: AlignmentEnum.LEFT
          }
        },
        header: {
          bold: true,
          sz: 12,
          alignment: {
            vertical: AlignmentEnum.CENTER,
            horizontal: AlignmentEnum.CENTER
          }
        },
        border_thin: {
          top: { style: border },
          bottom: { style: border },
          left: { style: border },
          right: { style: border },
          incol: { style: border },
          inrow: { style: border }
        },
        text: {
          alignment: {
            vertical: AlignmentEnum.CENTER,
            horizontal: AlignmentEnum.LEFT
          }
        },
        number: {
          z: '#,##0',
          alignment: {
            vertical: AlignmentEnum.CENTER,
            horizontal: AlignmentEnum.CENTER
          }
        },
        date: {
          alignment: {
            vertical: AlignmentEnum.CENTER,
            horizontal: AlignmentEnum.CENTER
          }
        },
        currency: {
          z: '#,##0.00',
          alignment: {
            vertical: AlignmentEnum.CENTER,
            horizontal: AlignmentEnum.RIGHT
          }
        },
        text_center: {
          alignment: {
            vertical: AlignmentEnum.CENTER,
            horizontal: AlignmentEnum.CENTER
          }
        },
        text_right: {
          alignment: {
            vertical: AlignmentEnum.CENTER,
            horizontal: AlignmentEnum.RIGHT
          }
        }
      }
    };
  }

  public static ReadFile(filename: string, opts: WritingOptions = null): WorkBook {
    return readFile(filename, opts);
  }

  public static Read(data: any, opts: ParsingOptions = null): WorkBook {
    return read(data, opts);
  }

  public static WriteFile(wb: WorkBook, fileName: string, opts: WritingOptions = { cellStyles: true }) {
    return writeFile(wb, fileName, opts);
  }

  public static Write(data: WorkBook, opts: WritingOptions = null) {
    return write(data, opts);
  }

  public static getHeaders(ws: WorkSheet, headerRow = 1, startColumn?: number, endColumn?: number): Array<string> {
    const range = utils.decode_range(ws['!ref']);
    const headers = [];
    let C = startColumn ? startColumn : range.s.c;
    endColumn = endColumn ? endColumn : range.e.c;

    for (C; C <= endColumn; ++C) {
      const cell = ws[utils.encode_cell({ c: C, r: headerRow })];

      headers.push(cell);
    }

    return headers.filter(Boolean);
  }

  public static getJsonData(ws: WorkSheet, opts?: Sheet2JSONOpts) {
    const range = utils.decode_range(ws['!ref']);
    range.s.r = 2; // skip header and title
    const newRange = utils.encode_range(range);

    return utils.sheet_to_json(ws, {
      range: newRange,
      header: 'A',
      ...opts
    });
  }
}
