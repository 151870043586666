import { BaseReport, BaseReportAsFile } from './base-report.model';

export enum ReportFileType {
  XLSX = 'XLSX',
  CSV = 'CSV'
}

export interface ReportItem {
  title: string;
  section: ReportSection;
  action: ActionReportEnum;
  permission: string[];
}

export enum ActionReportEnum {
  AWAITING_ALLOCATION_FOR_GROUP_ORDER = 'awaiting_allocation_for_group_order',
  VOUCHER_REPORT = 'voucher',
  STOCK_CARD = 'stockcard',
  SPECIAL_POINT_COMPENSATE = 'special_point_compensate',
  SALES_TRANSACTION = 'sales_transaction',
  PROMOTION_COMPENSATE = 'promotion_compensate',
  SALES_SUMMARY = 'sales_summary',
  TOTE_ADJUSTMENT = 'tote_adjustment',
  INVENTORY_COUNT_TRANSACTION_TEMP = 'inventory_count_transaction_temp',
  MOVING_AVERAGE = 'moving_average',
  INVENTORY_COUNT_TRANSACTION = 'inventory_count_transaction',
  MONEY_IN = 'money_in',
  INVENTORY_ADJUSTMENT_REQUEST = 'inventory_adjustment_request',
  INVENTORY_VARIANCE = 'inventory_variance',
  DC_ASSORTMENT = 'dc_assortment',
  BILL_PAYMENT = 'bill_payment',
  SUPPLIER_COUPON_RECONCILE = 'supplier_coupon_reconcile',
  STORE_CONFIDENTIAL = 'store_confidential',
  SALES_TRANSACTION_CONFIDENTIAL = 'sales_transaction_confidential',
  TD_ASSORTMENT = 'td_assortment',
  TD_ASSORTMENT_CONFIDENTIAL = 'td_assortment_confidential',
  DESTROY_TRANSACTION = 'destroy_transaction',
  DESTROY_DOCUMENT = 'destroy_document',
  REWARD_TRANSACTION = 'reward_transaction',
  REDEEM_TRANSACTION = 'redeem_transaction',
  RECEIVE_ORDER_EXCEED = 'receive_order_exceed',
  AWAITING_FIRST_LOT = 'awaiting_first_lot',
  TEMPORARY_HOLD = 'temporary_hold',
  POINT_BURN_TRANSACTION = 'point_burn_transaction',
  POINT_EARN_TRANSACTION = 'point_earn_transaction',
  PRE_ORDER_TRANSACTION = 'pre_order_transaction',
  PRE_ORDER_TRANSACTION_CONFIDENTIAL = 'pre_order_transaction_confidential',
  PRE_ORDER_CATALOG_CONFIDENTIAL = 'pre_order_catalog_confidential',
  PRE_ORDER_COMPENSATE = 'pre_order_compensate',
  MEMBER_REWARD_TRANSACTION = 'member_reward_transaction',
  MEMBER_REWARD_TRANSACTION_WITH_PRICE = 'member_reward_transaction_with_price',
  MEMBER_REWARD_ACCOUNTING_CONFIDENTIAL = 'member_reward_accounting_confidential',
  REFUND_TRANSACTION_REPORT_WITH_PRICE = 'refund_transaction_report_with_price',
  STORE_LOCATION_BY_DC = 'store_location_by_dc',
  GROUP_ORDER_UNPICKED_ITEM = 'group_order_unpicked_item',
  HOLD_ASSORTMENT = 'hold_assortment',
  PROMOTION = 'promotion',
  SPECIAL_POINT = 'special_point',
  SHIPMENT = 'shipment',
  RETURN_TO_WAREHOUSE_ORDER = 'return_to_warehouse_order',
  RETURN_TO_WAREHOUSE_GR = 'return_to_warehouse_gr',
  RETURN_TO_WAREHOUSE_CHARGE = 'return_to_warehouse_charge',
  RESPONSE = 'response',
  SURVEY = 'survey',
  TASK_ASSIGNMENT = 'task_assignment',
  FLASH_SALE_ORDER = 'flash_sale_order',
  ONLINE_COUPON_TRANSACTION = 'Online Coupon Transaction',
  ONLINE_COUPON_COMPENSATE = 'online_coupon_compensate',
  ORDER = 'order',
  REFUND_SUMMARY = 'refund_summary',
  DELIVERY_ORDER = 'delivery_order',
  RECEIVE_ORDER = 'receive_order',
  CLAIM = 'claim',
  STOCK_BY_PRODUCT = 'stock_by_product',
  STOCK_ADJUSTMENT = 'stock_adjustment',
  TRANSFER_ORDER = 'transfer_order',
  TRANSFER_GOODS_RECEIVE = 'transfer_goods_receive',
  USER = 'user',
  PROFIT_SHARING = 'profit_sharing',
  SUPPLIER = 'supplier',
  STORE_CONSULTANT = 'store_consultant',
  STORE = 'store',
  STORE_ORDER_SCHEDULE = 'store_order_schedule',
  REWARD = 'reward',
  ONLINE_COUPON = 'online_coupon',
  ONLINE_COUPON_SUMMARY = 'online_coupon_summary',
  MEMBER_REWARD_CATALOG = 'member_reward_catalog',
  ASSORTMENT_LOCATION = 'assortment_location',
  PRE_ORDER_CATALOG = 'pre_order_catalog',
  SHELF_FIX_ASSET = 'shelf_fix_asset',
  SHELF_INVENTORY = 'shelf_inventory',
  STORE_ASSORTMENT = 'store_assortment',
  HOLD_ORDER = 'hold_order',
  STOCK_BY_LOCATION = 'stock_by_location',
  PURCHASE_REQUEST = 'purchase_request',
  PURCHASE_ORDER = 'purchase_order',
  GOODS_RECEIVE = 'goods_receive',
  PRE_ORDER_CREDIT_REMAINING = 'pre_order_credit_remaining',
  PRODUCT_WAREHOUSE_MAPPING = 'PRODUCT_WAREHOUSE_MAPPING',
  PRODUCT_CATEGORY_FIXASSET_STOREUSE = 'PRODUCT_CATEGORY_FIXASSET_STOREUSE',
  PRODUCT_CATEGORY_INVENTORY = 'PRODUCT_CATEGORY_INVENTORY',
  PRE_ORDER_CATEGORY = 'PRE_ORDER_CATEGORY',
  SAP_STORE_CODE_MAPPING = 'SAP_STORE_CODE_MAPPING',
  CROSS_DOCK_INVOICE = 'CROSS_DOCK_INVOICE',
  STORE_TOTE = 'store_tote',
  TOTE_FINE = 'tote_fine',
  TOTE_IN_OUT = 'tote_in_out',
  TOTE_IN_TRACKING = 'tote_in_tracking',
  MANUAL_CHANGE_DELIVERY_STATUS = 'manual_change_delivery_status',
  SALE_SCHEDULE = 'SALE_SCHEDULE',
  NEW_SALE_SCHEDULE = 'NEW_SALE_SCHEDULE'
}

export enum ReportSection {
  TD_NEST = 'TD_NEST',
  TD_POS = 'TD_POS',
  TD_EYES = 'TD_EYES',
  PERFORMANCE = 'PERFORMANCE',
  OTHERS = 'OTHERS'
}

export class StockCardExportCriteria extends BaseReport {
  location: string;
  documentDateFrom?: string;
  documentDateTo?: string;
  segment: string;
  family?: string;
  classCode?: string;
  subClass?: string;

  constructor(init?: Partial<StockCardExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class VoucherCriteria extends BaseReport {
  receiptDateFrom?: string;
  receiptDateTo?: string;
  fileType: string;

  constructor(init?: Partial<VoucherCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class VoucherExportCriteria extends BaseReport {
  receiptDateFrom?: string;
  receiptDateTo?: string;
  fileType: string;

  constructor(init?: Partial<VoucherExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class SalesTransactionExportCriteria extends BaseReport {
  transactionDateFrom?: string;
  transactionDateTo?: string;
  store?: string;
  salesType?: string;
  fileType?: string;

  constructor(init?: Partial<SalesTransactionExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class PreOrderCatalogExportCriteria extends BaseReport {
  status?: string;
  validDateFrom?: string;
  validDateTo?: string;
  effectiveDateFrom?: string;
  effectiveDateTo?: string;
  expireDateFrom?: string;
  expireDateTo?: string;
  fileType: ReportFileType;

  constructor(init?: Partial<PreOrderCatalogExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class PreOrderCatalogConfidentialCriteria extends BaseReport {
  status?: string;
  categoryLv1: string;
  categoryLv2: string;
  categoryLv3: string;
  fileType: ReportFileType;

  constructor(init?: Partial<PreOrderCatalogConfidentialCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class SalesSummaryReportExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  storeType?: string;

  constructor(init?: Partial<SalesSummaryReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class StoreLocationByDcReportCriteria extends BaseReportAsFile {
  constructor(init?: Partial<StoreLocationByDcReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class InventoryCountTransactionTempReportExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  storeType?: string;
  storeCodes?: string | string[];
  fileType?: string;

  constructor(init?: Partial<InventoryCountTransactionTempReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class PromotionCompensateReportExportCriteria extends BaseReport {
  receiptDateFrom: string;
  receiptDateTo: string;
  storeType?: string;
  supplierCode?: string;
  classes?: string[];
  fileType: string;

  constructor(init?: Partial<PromotionCompensateReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class MovingAverageReportExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;

  constructor(init?: Partial<MovingAverageReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class InventoryCountTransactionReportExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  storeType?: string;
  storeCodes?: string | string[];
  fileType?: string;

  constructor(init?: Partial<InventoryCountTransactionReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class InventoryAdjustmentRequestReportExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  storeType?: string;
  storeNo?: string;

  constructor(init?: Partial<InventoryAdjustmentRequestReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class MoneyInReportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  store?: string[];
  fileType: ReportFileType;

  constructor(init?: Partial<MoneyInReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class InventoryVarianceCriteria extends BaseReport {
  createdDate?: string;
  storeNo?: string[];

  constructor(init?: Partial<InventoryVarianceCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class DcAssortmentExportCriteria extends BaseReport {
  warehouseCode: string[];
  fileType: string;

  constructor(init?: Partial<DcAssortmentExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class BillPaymentReportCriteria extends BaseReport {
  saleDateFrom?: string;
  saleDateTo?: string;
  paymentDateFrom?: string;
  paymentDateTo?: string;
  store?: string;
  status?: string;
  fileType: ReportFileType;

  constructor(init?: Partial<BillPaymentReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class SupplierCouponReconcileReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  storeNo?: string;

  constructor(init?: Partial<SupplierCouponReconcileReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class SupplierCouponReconcileReportExportCriteria extends BaseReport {
  transactionDateFrom?: string;
  transactionDateTo?: string;
  store?: string[];

  constructor(init?: Partial<SupplierCouponReconcileReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class DestroyTransactionReportCriteria extends BaseReport {
  createdDateFrom: string;
  createdDateTo: string;
  storeType?: string;
  storeNo?: string;

  constructor(init?: Partial<DestroyTransactionReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class DestroyTransactionReportExportCriteria extends BaseReport {
  createdDateFrom?: Date;
  createdDateTo?: Date;
  storeType?: string;
  storeNo?: string[];

  constructor(init?: Partial<DestroyTransactionReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class DestroyDocumentReportCriteria extends BaseReport {
  docNo: string;

  constructor(init?: Partial<DestroyDocumentReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class RewardTransactionReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  storeCodes?: string[];
  fileType: ReportFileType;

  constructor(init?: Partial<RewardTransactionReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class RedeemTransactionReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  storeCodes?: string[];
  fileType: ReportFileType;

  constructor(init?: Partial<RedeemTransactionReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ReceiveOrderExceedReportCriteria extends BaseReport {
  receiveOrderDateFrom?: string;
  receiveOrderDateTo?: string;

  constructor(init?: Partial<ReceiveOrderExceedReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ReceiveOrderExceedReportExportCriteria extends BaseReport {
  receiveOrderDateFrom?: string;
  receiveOrderDateTo?: string;

  constructor(init?: Partial<ReceiveOrderExceedReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class AwaitingFirstLotExportCriteria extends BaseReport {
  storeOpenDateFrom?: string;
  storeOpenDateTo?: string;
  storeType?: string[];
  region?: string[];
  state?: string[];

  constructor(init?: Partial<AwaitingFirstLotExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class OrderProcessingHoldCriteria extends BaseReport {
  requestedDateFrom?: string | null;
  requestedDateTo?: string | null;
  store?: string | string[] | null;
  delistSuggestion?: string | string[];
  fileType: ReportFileType;

  constructor(init?: Partial<OrderProcessingHoldCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class PreOrderTransactionReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  preorderDueDateFrom?: string;
  preorderDueDateTo?: string;
  storeCodes?: string[];
  fileType?: ReportFileType;

  constructor(init?: Partial<PreOrderTransactionReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class PreOrderCompensateReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  storeCode?: string;
  supplierCode?: string;
  fileType: ReportFileType;

  constructor(init?: Partial<PreOrderCompensateReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class PreOrderAccountingReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  storeCodes?: string;

  constructor(init?: Partial<PreOrderAccountingReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class GenerateMyReportResponse {
  id: string;
  version: number;
  requestNo: string;
  reportName: string;
  jobId: string;
  createBy: string;
  requestedDate: string;
  completedDate: string;
  status: string;
}

export class MemberRewardTransactionReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  storeCodes?: string[];
  fileType?: ReportFileType;

  constructor(init?: Partial<MemberRewardTransactionReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class MemberRewardTransactionCriteria {
  memberRewardTransactionDateFrom: string;
  memberRewardTransactionDateTo: string;
  store: string[];
  fileType: ReportFileType;

  constructor(init?: Partial<MemberRewardTransactionCriteria>) {
    Object.assign(this, init);
  }
}

export class MemberRewardAccountingReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  storeCodes?: string;

  constructor(init?: Partial<MemberRewardAccountingReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class RefundTransactionReportConfidentialExportCriteria extends BaseReport {
  transactionDateFrom?: string;
  transactionDateTo?: string;
  refundTypes?: string[];
  fileType?: string;

  constructor(init?: Partial<RefundTransactionReportConfidentialExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class RefundExportCriteria extends BaseReport {
  transactionDateTo?: string;
  transactionDateFrom?: string;
  refundTypes?: string;
  fileType?: string;

  constructor(init?: Partial<RefundExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class GroupOrderUnpickedItemReportCriteria extends BaseReport {
  dispatchDateFrom: string;
  dispatchDateTo: string;
  storeCode: string[];
  warehouseCode: string[];
  orderType: string[];
  fileType: string;

  constructor(init?: Partial<GroupOrderUnpickedItemReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class AwaitingAllocationForGroupOrderReportCriteria extends BaseReport {
  orderDateFrom: string;
  orderDateTo: string;
  storeCode: string[];
  warehouseCode: string[];
  orderType: string[];
  fileType: string;

  constructor(init?: Partial<AwaitingAllocationForGroupOrderReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class PromotionReportCriteria extends BaseReport {
  effectiveDateFrom: string;
  effectiveDateTo: string;
  validDateFrom: string;
  validDateTo: string;
  status?: string;
  fileType: string;

  constructor(init?: Partial<PromotionReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class SpecialPointCompensateReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  storeNos: string[];
  supplierCode: string;
  fileType: string;

  constructor(init?: Partial<SpecialPointCompensateReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class SpecialPointReportCriteria extends BaseReport {
  effectiveDateFrom: string;
  effectiveDateTo: string;
  expireDateFrom: string;
  expireDateTo: string;
  validDateFrom: string;
  validDateTo: string;
  offerSupplierCode: string[];
  status: string[];
  fileType: string;

  constructor(init?: Partial<SpecialPointReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ResponseReportCriteria extends BaseReport {
  taskNo?: string;
  status?: string;
  releaseDateFrom?: string;
  releaseDateTo?: string;
  responseDueDateFrom?: string;
  responseDueDateTo?: string;
  fileType: string;

  constructor(init?: Partial<ResponseReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class SurveyReportCriteria extends BaseReport {
  taskNo: string;
  fileType: string;

  constructor(init?: Partial<SurveyReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ReturnToWarehouseOrderReportCriteria {
  createdDateFrom: string;
  createdDateTo: string;
  shipFromCodeName: string;
  shipToCodeName: string;
  status: string;
  returnType: string;
  fileType: string;
}

export class ReturnToWarehouseGRReportCriteria extends BaseReport {
  createdDateFrom: string;
  createdDateTo: string;
  shipFromCodeName: string;
  shipToCodeName: string;
  status: string;
  returnType: string;
  fileType: string;

  constructor(init?: Partial<ReturnToWarehouseGRReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ReturnToWarehouseChargeReportCriteria {
  createdDateFrom: string;
  createdDateTo: string;
  shipFromCodeName: string;
  shipToCodeName: string;
  returnType: string;
  fileType: string;
}

export class TaskAssignmentReportCriteria extends BaseReport {
  releaseDateFrom: string;
  releaseDateTo: string;
  responseDueDateFrom: string;
  responseDueDateTo: string;
}

export class FlashSaleOrderReportCriteria {
  flashSaleId: string;
  storeCodes?: string[];
  fileType: string;
}

export class TemporaryHoldReportCriteria extends BaseReport {
  requestedDateFrom: string;
  requestedDateTo: string;
  store: string | string[];
  delistSuggestion: boolean;
  fileType: string;

  constructor(init?: Partial<TemporaryHoldReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class OnlineCouponTransactionCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  supplierCode?: string;
  fileType: ReportFileType;
  couponType?: string[];

  constructor(init?: Partial<OnlineCouponTransactionCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class UserReportCriteria extends BaseReport {
  merchantNo: string;
  departmentCodes: string[];
  roles: string[];

  constructor(init?: Partial<UserReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class HoldOrderReportCriteria extends BaseReport {
  holdDateFrom: string;
  holdDateTo: string;
  storeCodes?: string[];
  fileType: string;
}

export class PointEarnTransactionReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  storeCodes?: string[];
  memberId: string;
  fileType: string;

  constructor(init?: Partial<PointEarnTransactionReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class PointBurnTransactionReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  storeCode: string[];

  constructor(init?: Partial<PointBurnTransactionReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class PreOrderCreditRemainingReportCriteria extends BaseReport {
  creditDateFrom: string;
  creditDateTo: string;
  storeCodes: string[];
  fileType: string;

  constructor(init?: Partial<PreOrderCreditRemainingReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class OnlineCouponCompensateCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  couponType?: string[];
  storeCode?: string[];
  supplierCode?: string;
  fileType: string;

  constructor(init?: Partial<OnlineCouponCompensateCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ToteAdjustmentReportCriteria extends BaseReport {
  createdDateFrom: string;
  createdDateTo: string;
  adjustmentType: string[];
  locationType: string[];
  storeCodes: string[];
  fileType: string;

  constructor(init?: Partial<ToteAdjustmentReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class StoreToteReportCriteria extends BaseReport {
  storeCodes: string[];
  toteDisplayBy: string;
  fileType: string;

  constructor(init?: Partial<StoreToteReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ToteFineReportCriteria extends BaseReport {
  transactionDateFrom: string;
  transactionDateTo: string;
  fineType: string;
  location: string[];
  fileType: string;

  constructor(init?: Partial<ToteFineReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ToteInOutReportCriteria extends BaseReport {
  dispatchedDateFrom: string;
  dispatchedDateTo: string;
  toteInDateFrom: string;
  toteInDateTo: string;
  toteOutDateFrom: string;
  toteOutDateTo: string;
  shipFrom: string;
  shipTo: string;
  fileType: string;

  constructor(init?: Partial<ToteInOutReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ToteInTrackingReportCriteria extends BaseReport {
  fileType: string;

  constructor(init?: Partial<ToteInTrackingReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class CrossDockInvoiceReportCriteria extends BaseReport {
  createdDateFrom: string;
  createdDateTo: string;
  warehouseCodes: string[];
  fileType: string;

  constructor(init?: Partial<CrossDockInvoiceReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ManualChangeDeliveryStatusReportCriteria extends BaseReport {
  manualChangeDateFrom: string;
  manualChangeDateTo: string;
  fileType: string;

  constructor(init?: Partial<ManualChangeDeliveryStatusReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
