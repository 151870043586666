import { createSelector } from '@ngrx/store';

import * as fromStringPricing from '../reducers/stack-pricing.reducers';
import { AppStates } from '../state/app.states';

const selectStackPricingState = (state: AppStates) => state.stackPricing;

export const selectStackPricingList = createSelector(selectStackPricingState, fromStringPricing.selectAll);

export const selectStackPricing = createSelector(
  selectStackPricingState,
  (state: fromStringPricing.StackPricingState) => state
);

export const selectStackPricingItem = createSelector(
  selectStackPricingState,
  (state: fromStringPricing.StackPricingState) => state.selected
);

export const selectStackPricingHistory = createSelector(
  selectStackPricingState,
  (state: fromStringPricing.StackPricingState) => state.auditLogs
);
