import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthGuardService } from '../../services';
import { PermissionsUtil } from '../../utils/permissions-util';

@Component({
  selector: 'app-reference-document-link',
  templateUrl: './reference-document-link.component.html',
  styleUrls: ['./reference-document-link.component.scss']
})
export class ReferenceDocumentLinkComponent {
  @Input() module: string;
  @Input() text: string;
  @Input() referenceNo?: string;
  @Input() referenceID?: string;
  @Input() permissions?: string[];
  @Input() permissionsByWarehouse? = false;
  @Input() warehouseCode: string | string[];
  @Input() id: string;

  constructor(
    protected router: Router,
    protected authGuardService: AuthGuardService,
    protected readonly permissionUtil: PermissionsUtil
  ) {}

  linkToComponent(event) {
    if (!this.isAllowToLink(this.permissions, this.text, this.warehouseCode)) {
      return;
    }

    event.stopPropagation();

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/dashboard/my-task'], {
        queryParams: { module: this.module, referenceNo: this.referenceNo, referenceID: this.referenceID }
      })
    );

    window.open(url, '_blank');
  }

  isAllowToLink = (permissions, text, whCode?) => {
    if (!permissions) {
      return;
    }

    if (!this.permissionsByWarehouse && !this.authGuardService.checkPermission(permissions)) {
      return false;
    } else if (
      this.permissionsByWarehouse &&
      whCode &&
      !this.permissionUtil.checkPermissionByLocation(permissions, whCode)
    ) {
      return false;
    }

    return !text || text === '(None)' || text === '-' ? false : true;
  };
}
