<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="confirm-modal-closeBtn">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-store-info-header">
    <span>{{ historyHeader }}</span>
  </div>

  <div class="modal-body store-info-modal-body">
    <div class="">
      <div class="info-header-label">
        <span>Address:</span>
      </div>
      <div class="info-header-value">
        <div>
          {{ customerDetail.address | dashDisplay: '' }}
          {{ customerDetail.state | dashDisplay: '' }}
          {{ customerDetail.postCode | dashDisplay: '' }}
        </div>
      </div>
    </div>

    <div class="mt-3">
      <div class="info-header-label">
        <span>Contact Name:</span>
      </div>
      <div class="info-header-value">
        {{ customerDetail.contactName | dashDisplay }}
      </div>
    </div>

    <div class="mt-3">
      <div class="info-header-label">
        <span>Contact Number:</span>
      </div>
      <div class="info-header-value">
        {{ customerDetail.contactNumber | dashDisplay }}
      </div>
    </div>
  </div>
</div>
