import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SubmitWithComment } from '../models/base-modal.component.model';
import {
  TaskAssignmentRequest,
  TaskRequestListResponse,
  TaskRequestListSearchCriteria
} from '../models/task-assignment-request.model';
import { BaseService } from './base.service';

@Injectable()
export class TaskAssignmentRequestService extends BaseService {
  public headers: HttpHeaders;
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.taskAssignmentRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: TaskRequestListSearchCriteria): Observable<TaskRequestListResponse> {
    const params = this.getParams(criteria, true);
    return this.http.get<TaskRequestListResponse>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public saveRequest(taskRequest: TaskAssignmentRequest) {
    return this.http.post<any>(this.getUrl(), taskRequest, { headers: this.loaderHeaders() });
  }

  downloadFileStoreTemplate() {
    const url = this.getFullUrl(this.envService.downloadFileStoreTemplate);
    return this.http.get<any>(url, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  public getTaskRequestById<T1 extends TaskAssignmentRequest>(id: string): Observable<T1> {
    const url = this.getFullUrl(this.envService.get, {
      id: id
    });

    return this.http.get<T1>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public importFileStore(file): Observable<any> {
    const url = this.getFullUrl(this.envService.importStoreSubmit);

    let formData = null;
    formData = new FormData();
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public submitRequest(taskRequest: TaskAssignmentRequest) {
    const url = this.getFullUrl(this.envService.submit);
    if (taskRequest.status === 'W') {
      return this.http.put<any>(url, taskRequest, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    }
    return this.http.post<any>(url, taskRequest, { headers: this.loaderHeaders() });
  }

  approve(data: SubmitWithComment) {
    const url = this.getFullUrl(this.envService.approve);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  reject(data: SubmitWithComment) {
    const url = this.getFullUrl(this.envService.reject);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  delete(id: string) {
    const url = this.getFullUrl(this.envService.get, {
      id: id
    });
    return this.http.delete<any>(url, {
      headers: this.loaderHeaders()
    });
  }

  cancel(data: SubmitWithComment) {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  public exportStore(id: string): Observable<any> {
    const url = this.getFullUrl(this.envService.exportStore);
    const data = { id: id };
    return this.http.post<any>(url, data, { headers: this.headers, responseType: 'blob' as 'json' });
  }
}
