import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';

import { SegmentList, segmentListByArticleTypeQuery } from '../gql/segmentList.gql';
import { ProductAssortmentResponse, ProductAssortmentSearchCriteria, ProductStatusEnum } from '../models';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { OrderSelectItemSearchCriteria } from '../models/order-request.model';
import { TdAssortmentListPagination } from '../models/purchase-request.model';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class ProductAssortmentService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly masterService: MasterService) {
    super();
    this.envService = this.env.services.assortment;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: ProductAssortmentSearchCriteria): Observable<ProductAssortmentResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.get);
    return this.http.get<ProductAssortmentResponse>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public getSegmentListData(articleType: string = null, active = null): Observable<ApolloQueryResult<SegmentList>> {
    return this.masterService.watchQuery<SegmentList>({
      query: segmentListByArticleTypeQuery,
      fetchPolicy: 'network-only',
      variables: {
        articleType: articleType,
        active: active
      }
    });
  }

  public searchSelectiveCatalogByCriteria(criteria: OrderSelectItemSearchCriteria): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams({
      ...criteria,
      productType: criteria.productType.toString(),
      excStatus: [ProductStatusEnum.DELETE, ProductStatusEnum.RECALL, ProductStatusEnum.HOLD_BUY_SELL].toString(),
      sortBy: ['productName.en_sort', 'articleNo.sort'].toString(),
      sortOrder: 'asc'
    });
    const url = this.getFullUrl(this.envService.catalogs);

    return this.http.get<TdAssortmentListPagination>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public searchStoreUseCatalogByCriteria(criteria: OrderSelectItemSearchCriteria): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams({
      ...criteria,
      productType: criteria.productType.toString(),
      excStatus: [ProductStatusEnum.DELETE, ProductStatusEnum.RECALL, ProductStatusEnum.HOLD_BUY_SELL].toString(),
      sortBy: ['productName.en_sort', 'articleNo.sort'].toString(),
      sortDirection: 'asc'
    });
    const url = this.getFullUrl(this.envService.catalogs);

    return this.http.get<TdAssortmentListPagination>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public searchFirstLotCatalogByCriteria(criteria: OrderSelectItemSearchCriteria): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams({
      ...criteria,
      productType: criteria.productType.toString(),
      excClassCode: 'K0400',
      excStatus: [ProductStatusEnum.DELETE, ProductStatusEnum.RECALL, ProductStatusEnum.HOLD_BUY_SELL].toString(),
      sortBy: ['productName.en_sort', 'articleNo.sort'].toString(),
      sortOrder: 'asc'
    });
    const url = this.getFullUrl(this.envService.catalogs);

    return this.http.get<TdAssortmentListPagination>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public searchCatalogByCriteria(criteria: OrderSelectItemSearchCriteria): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: this.envService.catalogs,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams({
      ...criteria,
      productType: criteria.productType.toString(),
      sortBy: ['productName.en_sort'].toString(),
      sortOrder: 'asc'
    });
    const url = this.getFullUrl(this.envService.catalogs);

    return this.http.get<TdAssortmentListPagination>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public getAuditLog(productId: string): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      productId
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }
}
