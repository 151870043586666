import { ApproveStatusEnum } from '../enum/approve-status.enum';
import { RequestStatusEnum } from '../enum/request-status.enum';
import { RequestStepEnum, RequestTypeEnum } from '../enum/request-step.enum';
import { FirstLotInfo } from './first-lot-order.model';
import {
  LegalDocument,
  MerchantInfo,
  OrderSchedule,
  OrderScheduleList,
  StoreProfile,
  Wallet
} from './merchant.model';

export class StoreRequestListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  startRequestDate?: string;
  endRequestDate?: string;
  type?: string;
  page: number;
  size: number;
}

export class StoreRequestListResponse {
  content: StoreRequestList[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class StoreRequestList {
  id: string;
  merchantId: string;
  merchantNo: string;
  requestBy: string;
  requestNo: string;
  requestStatus: RequestStatusEnum;
  requestedDate: string;
  step: RequestStepEnum;
  storeCode: string;
  storeId?: string;
  storeName: string;
  type: RequestTypeEnum;
}

export class StoreRequestTemplate {
  id?: string;
  version?: number;
  requestNo?: string;
  no?: string;
  merchantNo?: string;
  type: RequestTypeEnum;
  step: RequestStepEnum;
  status: RequestStatusEnum;
  orderSchedule: OrderScheduleList;
  firstLotPo?: FirstLotInfo;
  storeProfile: StoreProfile;
  taxId: string;
  merchantId: string;
  merchantName: string;
  merchantType: string;
  contactName: string;
  mobilePhone: string;
  updatedFields?: string[];

  public static mappingByStoreRequestViewResponse(
    storeRequestViewResponse: StoreRequestViewResponse,
    original: StoreRequestTemplate = null
  ): StoreRequestTemplate {
    return {
      ...original,
      ...{
        id: storeRequestViewResponse.id,
        requestNo: storeRequestViewResponse.requestNo,
        no: storeRequestViewResponse.no,
        merchant: storeRequestViewResponse.merchant,
        type: storeRequestViewResponse.type,
        step: storeRequestViewResponse.step,
        status: storeRequestViewResponse.status,
        firstLotPo: storeRequestViewResponse.firstLotPo,
        orderSchedule: storeRequestViewResponse.orderSchedule.orderScheduleList[0],
        storeProfile: storeRequestViewResponse.merchantInfo.storeProfile[0],
        taxId: storeRequestViewResponse.taxId,
        merchantName: storeRequestViewResponse.merchantName,
        merchantType: storeRequestViewResponse.merchantType,
        contactName: storeRequestViewResponse.contactName,
        mobilePhone: storeRequestViewResponse.mobilePhone
      }
    } as StoreRequestTemplate;
  }
}

export class StoreRequestViewResponse {
  id?: string;
  version?: number;
  requestNo?: string;
  no: string;
  merchant: string;
  type: RequestTypeEnum;
  step: RequestStepEnum;
  status: RequestStatusEnum;
  orderSchedule: OrderSchedule;
  firstLotPo?: FirstLotInfo;
  taxId: string;
  merchantName: string;
  merchantType: string;
  contactName: string;
  mobilePhone: string;
  merchantInfo?: MerchantInfo;
  updatedFields?: string[];

  public static mappingByStoreRequestTemplate(
    storeRequestTemplate: StoreRequestTemplate,
    original: StoreRequestViewResponse = null
  ): StoreRequestViewResponse {
    return {
      ...original,
      ...{
        id: storeRequestTemplate.id,
        requestNo: storeRequestTemplate.requestNo,
        no: storeRequestTemplate.no,
        merchantNo: storeRequestTemplate.merchantNo,
        type: storeRequestTemplate.type,
        step: storeRequestTemplate.step,
        status: storeRequestTemplate.status,
        firstLotPo: storeRequestTemplate.firstLotPo,
        orderSchedule: { orderScheduleList: [storeRequestTemplate.orderSchedule] },
        merchantInfo: { storeProfile: [storeRequestTemplate.storeProfile] },
        taxId: storeRequestTemplate.taxId,
        merchantName: storeRequestTemplate.merchantName,
        merchantType: storeRequestTemplate.merchantType,
        contactName: storeRequestTemplate.contactName,
        mobilePhone: storeRequestTemplate.mobilePhone
      }
    } as StoreRequestViewResponse;
  }
}

export class StoreCreateRequest {
  id: string;
  version: number;
  requestNo: string;
  status: string;
  step: string;
  merchantNo: string;
  merchantInfo: MerchantInfo;
  orderSchedule: OrderSchedule;
}

export class MerchantSearch {
  merchantName: string;
  taxId: string;
  merchantType: string;
}

export class ApproveStoreRequest {
  requestNo: string;
  status: ApproveStatusEnum;
  step: RequestStepEnum; // [ P, F ]
  comment: string;
}

export class StoreRequestView {
  id: string;
  version: number;
  requestNo: string;
  type: RequestTypeEnum;
  status: RequestStatusEnum;
  step: RequestStepEnum;
  merchantInfo: MerchantInfo;
  legalDocument: LegalDocument;
  wallet: Wallet;
  orderSchedule: OrderSchedule;
  firstLotPo: FirstLotInfo;
  createdDate: string;
  createdBy: string;
  createdByName: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  lastModifiedByName: string;
}
