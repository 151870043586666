<div id="import-export-button" *ngIf="isShowing()">
  <button
    type="button"
    class="btn btn-standard min-width-auto"
    placement="bottom"
    containerClass="bottomLeft"
    [popover]="imExTemplate"
    [outsideClick]="true"
    [disabled]="disabled"
    data-id="importExportBtn"
    id="importExportBtn"
  >
    <em class="icon-excel"></em>
  </button>

  <ng-template #imExTemplate>
    <div *ngFor="let button of buttons">
      <div class="form-row" *ngIf="!button.hidden">
        <span
          class="btn btn-link"
          (click)="onSubmit(button)"
          [attr.id]="button.type + 'Btn'"
          [attr.data-id]="button.type + 'Btn'"
        >
          <em class="icon-{{ getButtonType(button.type) }}"></em>{{ button.name }}
        </span>
      </div>
    </div>
  </ng-template>
</div>
