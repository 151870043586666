import { createSelector } from '@ngrx/store';

import { ResetPasswordPinState } from '../reducers/reset-password-pin.reducer';
import { AppStates } from '../state/app.states';

const selectResetPasswordPinState = (state: AppStates) => state.resetPasswordPin;

export const selectResetPasswordResult = createSelector(
  selectResetPasswordPinState,
  (state: ResetPasswordPinState) => state.result.resetPasswordResult
);

export const selectResetPinResult = createSelector(
  selectResetPasswordPinState,
  (state: ResetPasswordPinState) => state.result.resetPinCodeResult
);
