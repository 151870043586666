import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { round } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ProductOrderItem } from '../../models/order-request.model';
import { OrderInventoryRequestService } from '../../services/order-inventory-request.service';
import { ShelfInventoryService } from '../../services/shelf-inventory.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  OrderInventoryApproveRequestAction,
  OrderInventoryApproveResponseAction,
  OrderInventoryCreateSaveRequestAction,
  OrderInventoryCreateSaveResponseAction,
  OrderInventoryCreateSubmitRequestAction,
  OrderInventoryCreateSubmitResponseAction,
  OrderInventoryCreateSubmitResponseErrorAction,
  OrderInventoryRequestActionType,
  OrderInventoryRequestByIdRequestAction,
  OrderInventoryRequestByIdResponseAction,
  OrderInventoryRequestGenerateShelfItemsRequestAction
} from '../actions/order-inventory-request.actions';
import { OrderInventorySelectItemOrderAddAllItem } from '../actions/order-select-item-inventory-order.actions';
import {
  ShelfSelectInventoryActionTypes,
  ShelfSelectInventoryListRequest,
  ShelfSelectInventoryListResponse
} from '../actions/shelf-select-inventory.actions';

@Injectable()
export class OrderInventoryRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly orderInventoryRequestService: OrderInventoryRequestService,
    private readonly shelfInventoryService: ShelfInventoryService,
    private readonly logger: NGXLogger
  ) {}

  orderInventoryCreateSave$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderInventoryCreateSaveRequestAction>(
        OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SAVE_REQUEST
      ),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SAVE_REQUEST}: ` +
            this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.orderInventoryRequestService.saveRequest(payload).pipe(
          map(res => new OrderInventoryCreateSaveResponseAction(res)),
          catchError(error => of(new OrderInventoryCreateSubmitResponseErrorAction(error.error)))
        );
      })
    );
  });

  OrderInventoryCreateSubmit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderInventoryCreateSubmitRequestAction>(
        OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SUBMIT_REQUEST
      ),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SUBMIT_REQUEST}: ` +
            this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.orderInventoryRequestService.submitRequest(payload).pipe(
          map(res => new OrderInventoryCreateSubmitResponseAction(res)),
          catchError(error => of(new OrderInventoryCreateSubmitResponseErrorAction(error.error)))
        );
      })
    );
  });

  getOrderInventoryRequestById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderInventoryRequestByIdRequestAction>(
        OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GET_BY_ID_REQUEST
      ),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GET_BY_ID_REQUEST}: ` +
            this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.orderInventoryRequestService.getOrderById(payload).pipe(
          map(res => {
            return new OrderInventoryRequestByIdResponseAction(res);
          }),

          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  orderInventoryApprove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderInventoryApproveRequestAction>(
        OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_APPROVE_REQUEST
      ),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_APPROVE_REQUEST}: ` +
            this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload =>
        this.orderInventoryRequestService.approveRequest(payload).pipe(
          map(() => new OrderInventoryApproveResponseAction({ isSuccess: true })),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  orderInventoryRequestShelfListRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfSelectInventoryListRequest>(ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_REQUEST}: ` + this.stringify(action)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfInventoryService.getShelfInventoryList(payload).pipe(
          map(response => {
            return new ShelfSelectInventoryListResponse(response);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  orderInventoryRequestGenerateShelfItemsRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderInventoryRequestGenerateShelfItemsRequestAction>(
        OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GENERATE_SHELF_ITEM_REQUEST
      ),
      map(action => {
        this.logger.debug(
          `@Effect ${OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GENERATE_SHELF_ITEM_REQUEST}: ` +
            this.stringify(action)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfInventoryService.generateShelfItems(payload).pipe(
          map(response => {
            return new OrderInventorySelectItemOrderAddAllItem({ itemList: this.mappingShelfItemOrder(response) });
          }),
          catchError(err => of(new LayoutActionLoadError(err)))
        );
      }),
      catchError(error => of(new LayoutActionLoadError(error)))
    );
  });

  mappingShelfItemOrder(shelfItems: ProductOrderItem[]): ProductOrderItem[] {
    shelfItems.forEach(item => {
      item.qty = item.quantity || 1;
      item.totalVat = {
        amount: (item.vatAmount && round(item.vatAmount.amount * item.qty, 2)) || 0,
        currency: (item.vatAmount && item.vatAmount.currency) || 'THB'
      };
      item.amountExcVat = {
        amount: (item.wholesalePriceExcVat && round(item.wholesalePriceExcVat.amount * item.qty, 2)) || 0,
        currency: (item.wholesalePriceExcVat && item.wholesalePriceExcVat.currency) || 'THB'
      };
      item.amount = {
        amount: round(item.amountExcVat.amount + item.totalVat.amount, 2) || 0,
        currency: (item.amountExcVat && item.amountExcVat.currency) || 'THB'
      };
      item.isAddItem = true;
    });

    return shelfItems;
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
