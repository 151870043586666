<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hide()" id="confirm-modal-closeBtn">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-body text-center" [innerHTML]="message"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="decline()" id="confirm-modal-cancelBtn">
      {{ cancelText }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      id="confirm-modal-okBtn"
      appDebounceClick
      (debounceClick)="confirm()"
    >
      {{ okText }}
    </button>
  </div>
</div>
