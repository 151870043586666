<div class="modal-content admin-form">
  <div class="modal-header">
    <h6 class="modal-title pull-left">Delivery Details</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="closeBtn">
      <span aria-hidden="true"><em class="icon-close"></em></span>
    </button>
  </div>
  <div class="modal-body" [formGroup]="specialForm">
    <div class="form-group mb-3">
      <label for="deliveryDate" class="input-label">Delivery Date<span class="text-danger">*</span></label>
      <input
        id="deliveryDate"
        name="deliveryDate"
        type="text"
        placeholder="{{ dateFormat }}"
        class="form-control calendar"
        autocomplete="off"
        (bsValueChange)="onSelectDeliveryDate($event)"
        [ngClass]="{
          'is-invalid': submitted && specialForm.controls.deliveryDate.errors
        }"
        bsDatepicker
        [bsConfig]="bsConfig"
        formControlName="deliveryDate"
        readonly
      />
      <label for="deliveryDate" class="icon-calendar btn-calendar"></label>
      <div
        *ngIf="estimatedDeliveryDate && estimatedOrderDate && orderSubType === orderSubTypeEnum.REPLENISH"
        class="estimated-display"
      >
        Estimated Order Date {{ estimatedOrderDate | dateDisplay }}, Estimated Delivery Date
        {{ estimatedDeliveryDate | dateDisplay }}
      </div>
      <div *ngIf="submitted && specialForm.controls.deliveryDate.errors" class="invalid-feedback">
        <div *ngIf="specialForm.controls.deliveryDate.errors.required; else error">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
        <ng-template #error>
          <div *ngIf="specialForm.controls.deliveryDate.errors.beforeToday">
            Date is invalid.
          </div>
        </ng-template>
      </div>
    </div>
    <div name="deliveryNote">
      <label for="deliveryDate">Delivery Note</label>
      <textarea
        rows="4"
        cols="50"
        class="form-control h-100"
        maxlength="250"
        id="deliveryNote"
        formControlName="deliveryNote"
        placeholder="Delivery Note(Receiver Name/Phone)"
      ></textarea>
    </div>
  </div>
  <div class="modal-footer" *ngIf="mode !== pageMode.REQUEST_VIEW">
    <button type="button" class="btn btn-standard" (click)="decline()" id="cancelBtn">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="confirm()" id="okBtn">
      OK
    </button>
  </div>
</div>
