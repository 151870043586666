import { BaseReport } from '@shared/models/base-report.model';

export class StockTransferGrListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  shipFrom?: string;
  shipTo?: string;
  page: number;
  size: number;
}

export class StockTransferGrList {
  id: string;
  docNo: string;
  status: string;
  toRefNo: string;
  doRefNo: string;
  shipFromCode: string;
  shipFromCodeName: string;
  shipToCode: string;
  shipToCodeName: string;
  receivedByName: string;
  receivedDate: string;
}

export class StockTransferGrListResponse {
  content: StockTransferGrList[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class StockTransferGrView {
  id: string;
  docNo: string;
  status: string;
  toRefNo: string;
  doRefNo: string;
  shipFromCode: string;
  shipFromCodeName: string;
  shipToCode: string;
  shipToCodeName: string;
  receivedByName: string;
  receivedBy: string;
  receivedDate: string;
  createdDate: string;
  items: StockTransferGrItem[];
}

export class StockTransferGrItem {
  itemNo: number;
  articleNo: string;
  barcode: string;
  productName: string;
  unit: string;
  unitFactor: number;
  productType: string;
  deliveredQty: number;
  receivedQty: number;
}

export class StockTransferGRExportCriteria extends BaseReport {
  createdDateTo?: string;
  createdDateFrom?: string;
  shipTo?: string;
  shipFrom?: string;
  fileType: string;

  constructor(init?: Partial<StockTransferGRExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
