import { Injectable } from '@angular/core';
import { some } from 'lodash';

import { PermissionAction } from '../enum/permission-action';
import { ProductTypeEnum } from '../enum/product-type.enum';
import { ShipToType } from '../enum/purchase-order.enum';
import { AuthGuardService } from '../services';

@Injectable()
export class PermissionsUtil {
  constructor(private readonly authGuardService: AuthGuardService) {}

  checkPermissionByLocation(collection: string[], code: string = null) {
    return some(collection, identity => this.authGuardService.checkPermission(code.replace(/(.*)/, identity)));
  }

  determinePrPermission = (
    shipToType: ShipToType = null,
    shipToCode: string = null,
    productType: ProductTypeEnum,
    action: PermissionAction[]
  ) => {
    if (shipToType && [ShipToType.STORE].includes(shipToType)) {
      if (productType && [ProductTypeEnum.INVENTORY].includes(productType)) {
        return this.authGuardService.checkPermission(action.map(act => `pr_inv_${act}_sto`));
      } else if (productType && [ProductTypeEnum.FIX_ASSET].includes(productType)) {
        return this.authGuardService.checkPermission(action.map(act => `pr_ast_${act}_sto`));
      } else {
        return this.authGuardService.checkPermission(action.map(act => `pr_sto_${act}_sto`));
      }
    } else {
      if (productType && [ProductTypeEnum.INVENTORY].includes(productType)) {
        return (
          shipToCode &&
          this.checkPermissionByLocation(
            action.map(act => `pr_inv_${act}_$1`),
            shipToCode
          )
        );
      } else if (productType && [ProductTypeEnum.FIX_ASSET].includes(productType)) {
        return (
          shipToCode &&
          this.checkPermissionByLocation(
            action.map(act => `pr_ast_${act}_$1`),
            shipToCode
          )
        );
      } else {
        return (
          shipToCode &&
          this.checkPermissionByLocation(
            action.map(act => `pr_sto_${act}_$1`),
            shipToCode
          )
        );
      }
    }
  };

  determinePoPermission(
    shipToType: ShipToType = null,
    shipToCode: string = null,
    productType: ProductTypeEnum,
    action: PermissionAction[]
  ) {
    if (shipToType && [ShipToType.STORE].includes(shipToType)) {
      if (productType && [ProductTypeEnum.INVENTORY].includes(productType)) {
        return this.authGuardService.checkPermission(action.map(act => `po_inv_${act}_sto`));
      } else if (productType && [ProductTypeEnum.FIX_ASSET].includes(productType)) {
        return this.authGuardService.checkPermission(action.map(act => `po_ast_${act}_sto`));
      } else {
        return this.authGuardService.checkPermission(action.map(act => `po_sto_${act}_sto`));
      }
    } else {
      if (productType && [ProductTypeEnum.INVENTORY].includes(productType)) {
        return (
          shipToCode &&
          this.checkPermissionByLocation(
            action.map(act => `po_inv_${act}_$1`),
            shipToCode
          )
        );
      } else if (productType && [ProductTypeEnum.FIX_ASSET].includes(productType)) {
        return (
          shipToCode &&
          this.checkPermissionByLocation(
            action.map(act => `po_ast_${act}_$1`),
            shipToCode
          )
        );
      } else {
        return (
          shipToCode &&
          this.checkPermissionByLocation(
            action.map(act => `po_sto_${act}_$1`),
            shipToCode
          )
        );
      }
    }
  }

  determineGrPermission(
    shipToType: ShipToType = null,
    shipToCode: string = null,
    productType: ProductTypeEnum,
    action: PermissionAction[]
  ) {
    if (shipToType && [ShipToType.STORE].includes(shipToType)) {
      if (productType && [ProductTypeEnum.INVENTORY].includes(productType)) {
        return this.authGuardService.checkPermission(action.map(act => `gr_inv_${act}_sto`));
      } else if (productType && [ProductTypeEnum.FIX_ASSET].includes(productType)) {
        return this.authGuardService.checkPermission(action.map(act => `gr_ast_${act}_sto`));
      } else {
        return this.authGuardService.checkPermission(action.map(act => `gr_sto_${act}_sto`));
      }
    } else {
      if (productType && [ProductTypeEnum.INVENTORY].includes(productType)) {
        return (
          shipToCode &&
          this.checkPermissionByLocation(
            action.map(act => `gr_inv_${act}_$1`),
            shipToCode
          )
        );
      } else if (productType && [ProductTypeEnum.FIX_ASSET].includes(productType)) {
        return (
          shipToCode &&
          this.checkPermissionByLocation(
            action.map(act => `gr_ast_${act}_$1`),
            shipToCode
          )
        );
      } else {
        return (
          shipToCode &&
          this.checkPermissionByLocation(
            action.map(act => `gr_sto_${act}_$1`),
            shipToCode
          )
        );
      }
    }
  }

  determineDoPermission(warehouseCode: string, actions: PermissionAction[]) {
    return this.checkPermissionByLocation(
      actions.map(action => `do_${action}_$1`),
      warehouseCode
    );
  }

  determineLogisticPermission(warehouseCode: string, actions: PermissionAction[]) {
    return this.checkPermissionByLocation(
      actions.map(action => `do_logis_${action}_$1`),
      warehouseCode
    );
  }
}
