function findDifference<T>(originalData: T, inputData: T): string[] {
  const keysSet: Set<string> = new Set([...Object.keys(originalData), ...Object.keys(inputData)]);
  const keys: string[] = Array.from(keysSet) || [];
  return keys.filter(key => {
    if (
      (originalData[key] === undefined && inputData[key] !== undefined && inputData[key] !== null) ||
      (inputData[key] === undefined && originalData[key] !== undefined && originalData[key] !== null)
    ) {
      return true;
    } else if (
      typeof originalData[key] === 'object' &&
      originalData[key] !== null &&
      typeof inputData[key] === 'object' &&
      inputData[key] !== null
    ) {
      if (Array.isArray(originalData[key]) || Array.isArray(inputData[key])) {
        if (originalData[key].length !== inputData[key].length) {
          return true;
        }
        if (originalData[key].length === 0 && inputData[key].length === 0) {
          return false;
        }
        return originalData[key].every((v: any, i: number) => {
          return Object.keys(v).some((k: string) => {
            if (v[k] !== inputData[key][i][k]) {
              return true;
            }
          });
        });
      }
    } else {
      if (
        (inputData[key] === null || inputData[key] === undefined) &&
        (originalData[key] === null || originalData[key] === undefined)
      ) {
        return false;
      } else if (originalData[key] !== inputData[key]) {
        return true;
      }
    }

    return false;
  });
}

export function findObjectDifference<T>(section: string, originalData: T, inputData: T): string[] {
  return findDifference(originalData, inputData).map(key => `${section}.${key}`);
}
