import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { has } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { BaseComponent } from '../../../../base/base.component';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import { StackPricingView, WholesaleStackPrice } from '../../../../shared/models/stack-pricing.model';
import {
  ProductAssortmentLoadRequested,
  RequestAssortmentReset
} from '../../../../shared/store/actions/request-assortment.actions';
import {
  StackPricingDeleteRequested,
  StackPricingViewRequested,
  StackPricingViewSubmit
} from '../../../../shared/store/actions/stack-pricing.actions';
import { selectRequestAssortmentEdit } from '../../../../shared/store/selectors/request-assortment.selectors';
import { selectStackPricingItem } from '../../../../shared/store/selectors/stack-pricing.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-stack-pricing-view',
  templateUrl: './stack-pricing-view.component.html',
  styleUrls: ['./stack-pricing-view.component.scss']
})
export class StackPricingViewComponent extends BaseComponent implements OnInit, OnDestroy {
  headerRow: string[];
  pricings$: Observable<StackPricingView>;
  productAssortmentSubscription: Subscription;
  productAssortment: any;
  currentPickingUnit = '';

  wholesalePrices: WholesaleStackPrice[];
  manageStackPricings: WholesaleStackPrice[];

  isManageMode = false;

  @Output() data: any;
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() notifyLeaveFormOutput: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: UntypedFormBuilder,
    protected readonly modalService: BsModalService
  ) {
    super(store, modalService, true);
  }

  ngOnInit() {
    this.headerRow = ['Lot', 'Cost Range', 'Total Stock', 'Safety Stock', 'Wholesale Price', 'GP%'];

    this.currentPickingUnit = '';

    this.store.dispatch(new ProductAssortmentLoadRequested({ data: this.data.id, isProduct: false }));
    this.store.dispatch(new StackPricingViewRequested({ articleNo: this.data.articleNo }));

    this.pricings$ = this.store.select(selectStackPricingItem).pipe(
      tap(data => {
        if (data) {
          this.wholesalePrices = data.wholesalePrices;
          this.manageStackPricings = data.manageStackPricings;
        }
      })
    );

    this.productAssortmentSubscription = this.store
      .select(selectRequestAssortmentEdit)
      .pipe(
        filter(assortment => assortment && has(assortment, 'id')),
        take(1)
      )
      .subscribe(productAssortmentEdit => {
        this.productAssortment = productAssortmentEdit;

        if (productAssortmentEdit.document.tdProductSnapshot.pickingUnitBarcode) {
          const unit = this.getTdUnitFactor(
            productAssortmentEdit,
            productAssortmentEdit.document.tdProductSnapshot.pickingUnitBarcode
          );
          this.currentPickingUnit = `${unit.unit}(${unit.unitFactor})`;
        }
      });
  }

  getTdUnitFactor(assortment, selectedBarcode: string) {
    let unitFactor = null;
    if (assortment && assortment.document) {
      assortment.document.barcodeSnapshots.forEach(barcode => {
        if (
          barcode.tdBarcodeSnapshot &&
          barcode.tdBarcodeSnapshot.barcode &&
          barcode.tdBarcodeSnapshot.barcode === selectedBarcode
        ) {
          unitFactor = {};
          unitFactor.unitFactor = barcode.tdBarcodeSnapshot.unitFactor;
          unitFactor.unit = barcode.tdBarcodeSnapshot.unit;
        }
      });
    }
    return unitFactor;
  }

  ngOnDestroy(): void {
    if (this.productAssortmentSubscription) {
      this.productAssortmentSubscription.unsubscribe();
    }
    if (this.notifyLeaveFormOutput) {
      this.notifyLeaveFormOutput.unsubscribe();
    }

    this.unsubscribeBase();

    this.store.dispatch(new RequestAssortmentReset());
  }

  doAfterVersionAlertModal() {}

  toggleToManageMode() {
    if (this.manageStackPricings.length <= 0) {
      this.manageStackPricings = this.wholesalePrices.map(price => {
        return Object.assign({}, price);
      });
    }
    this.isManageMode = !this.isManageMode;
    this.notifyLeaveFormOutput.emit(true);
  }

  deleteStackPricing() {
    const initialState = {
      title: 'Delete',
      message: 'Are you sure you want to delete new stack pricing?',
      label: 'Reason*',
      isRequiredConfirmMessage: true
    };

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new StackPricingDeleteRequested({
              articleNo: this.data.articleNo,
              reason: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onCancel() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  doAfterSuccessModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  onSubmit() {
    let isChange = false;

    const manageStackPricingTmp = this.manageStackPricings.map(price => {
      return Object.assign({}, price);
    });

    manageStackPricingTmp.forEach((data, index) => {
      if (data.order !== index) {
        isChange = true;
        data.order = index;
      }
      return data;
    });

    if (isChange) {
      const initialState = {
        title: 'Confirm',
        message: 'Are you sure you want to submit?',
        cancelText: 'Cancel',
        okText: 'OK'
      };

      const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
        initialState
      });
      confirmModalRef.content.action
        .pipe(untilComponentDestroyed(this))
        .subscribe((result: ModalButtonResponseEnum) => {
          if (result === ModalButtonResponseEnum.OK) {
            this.store.dispatch(
              new StackPricingViewSubmit({
                wholesalePrices: this.wholesalePrices,
                manageStackPricings: manageStackPricingTmp
              })
            );
          }
        });
    } else {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message:
            'The new stack pricing has same order as the current price. Please change lot sequence before submit.'
        }
      });
    }
  }
}
