export enum MetricType {
  COUNTER = 'COUNTER',
  HISTOGRAM = 'HISTOGRAM',
  RESPONSE_TIME = 'RESPONSE_TIME'
}

export enum MetricActionType {
  SEARCH = 'SEARCH',
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
  EXPORT_XLSX = 'EXPORT_XLSX',
  EXPORT_CSV = 'EXPORT_CSV',
  SUBMIT = 'SUBMIT'
}

export enum MetricModule {
  PO = 'PO',
  PRODUCT = 'PRODUCT',
  PR = 'PR',
  GR = 'GR',
  PROMOTION = 'PROMOTION',
  PERMANENT_HOLD = 'PERMANENT_HOLD',
  ADJUST_STOCK = 'ADJUST_STOCK',
  BILLING = 'BILLING',
  VOUCHER = 'VOUCHER',
  VOUCHER_REQUEST = 'VOUCHER_REQUEST',
  REWARD = 'REWARD',
  MEMBER = 'MEMBER',
  MERCHANT = 'MERCHANT',
  STORE = 'STORE',
  DELIVERY_ORDER = 'DELIVERY_ORDER',
  ORDER = 'ORDER',
  GROUP_ORDER = 'GROUP_ORDER',
  CLAIM = 'CLAIM',
  ORDER_FIX_ASSET = 'ORDER_FIX_ASSET',
  ORDER_INVENTORY = 'ORDER_INVENTORY',
  ORDER_REQUEST = 'ORDER_REQUEST',
  STORE_ASSORTMENT = 'STORE_ASSORTMENT',
  SHELF = 'SHELF',
  SHELF_INVENTORY = 'SHELF_INVENTORY',
  SHELF_FIX_ASSET = 'SHELF_FIX_ASSET',
  REPORT = 'REPORT',
  REPORT_DATA_HUB = 'REPORT_DATA_HUB',
  RETURN_TO_WAREHOUSE = 'RETURN_TO_WAREHOUSE',
  Z8 = 'Z8',
  PURCHASE_CONDITION = 'PURCHASE_CONDITION',
  PURCHASE_CONDITION_REQUEST = 'PURCHASE_CONDITION_REQUEST',
  ROLE = 'ROLE',
  SHIPMENT = 'SHIPMENT',
  STOCK = 'STOCK',
  STORE_USE = 'STORE_USE',
  STORE_CONSULTANT = 'STORE_CONSULTANT',
  STOCK_INFORMATION = 'STOCK_INFORMATION',
  STOCK_STORE = 'STOCK_STORE',
  STOCK_LOCATION = 'STOCK_LOCATION',
  STOCK_PRODUCT = 'STOCK_PRODUCT',
  STOCK_TRANSFER = 'STOCK_TRANSFER',
  STORE_GROUP = 'STORE_GROUP',
  SUPPLIER = 'SUPPLIER',
  SUPPLIER_STORE = 'SUPPLIER_STORE',
  SUPPLIER_PRICE = 'SUPPLIER_PRICE',
  PRE_ORDER = 'PRE_ORDER',
  PRE_ORDER_FLASH_SALE = 'PRE_ORDER_FLASH_SALE',
  USER = 'USER',
  PRODUCT_ASSORTMENT = 'PRODUCT_ASSORTMENT',
  PROFIT_SHARING = 'PROFIT_SHARING',
  RECEIVE_ORDER = 'RECEIVE_ORDER',
  TD_ASSORTMENT = 'TD_ASSORTMENT',
  TOTE = 'TOTE',
  APPROVAL_WORKFLOW = 'APPROVAL_WORKFLOW',
  LATE_PAYMENT_NOTICE = 'LATE_PAYMENT_NOTICE',
  CREATE_AUDIENCE = 'CREATE_AUDIENCE',
  SPECIAL_POINT = 'SPECIAL_POINT',
  FLASH_SALE_DASHBOARD = 'FLASH_SALE_DASHBOARD',
  ONLINE_COUPON_REQUEST = 'ONLINE_COUPON_REQUEST',
  ONLINE_COUPON = 'ONLINE_COUPON',
  HOLD_ASSORTMENT_REQUEST = 'HOLD_ASSORTMENT_REQUEST',
  HOLD_ASSORTMENT = 'HOLD_ASSORTMENT',
  STORE_ASSORTMENT_RECOMMENDATION = 'STORE_ASSORTMENT_RECOMMENDATION'
}

export enum MetricAction {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  IMPORT = 'IMPORT',
  PROMOTION_SUBMIT_STORE = 'PROMOTION_SUBMIT_STORE',
  PROMOTION_SUBMIT_ITEM = 'PROMOTION_SUBMIT_ITEM',
  ADJUST_STOCK_SUBMIT_ITEM = 'ADJUST_STOCK_SUBMIT_ITEM',
  ADJUST_STOCK_IMPORT_ITEM = 'ADJUST_STOCK_IMPORT_ITEM',
  ADJUST_STOCK_EXPORT_ITEM = 'ADJUST_STOCK_EXPORT_ITEM',
  REWARD_SUBMIT_PURCHASE_AMOUNT_COUPON = 'REWARD_SUBMIT_PURCHASE_AMOUNT_COUPON',
  REWARD_SUBMIT_PURCHASE_AMOUNT_GOODS = 'REWARD_SUBMIT_PURCHASE_AMOUNT_GOODS',
  REWARD_SUBMIT_PURCHASE_QUANTITY_COUPON = 'REWARD_SUBMIT_PURCHASE_QUANTITY_COUPON',
  REWARD_SUBMIT_PURCHASE_QUANTITY_GOODS = 'REWARD_SUBMIT_PURCHASE_QUANTITY_GOODS',
  REWARD_SUBMIT_SUPPLIER_COUPON_COUPON = 'REWARD_SUBMIT_SUPPLIER_COUPON_COUPON',
  REWARD_SUBMIT_SUPPLIER_COUPON_GOODS = 'REWARD_SUBMIT_SUPPLIER_COUPON_GOODS',
  VOUCHER_REQUEST_IMPORT_ITEM = 'VOUCHER_REQUEST_IMPORT_ITEM',
  VOUCHER_REQUEST_CREATE_ITEM = 'VOUCHER_REQUEST_CREATE_ITEM',
  VOUCHER_REQUEST_APPROVE = 'VOUCHER_REQUEST_APPROVE',
  VOUCHER_REQUEST_REJECT = 'VOUCHER_REQUEST_REJECT',
  VOUCHER_REQUEST_CANCEL = 'VOUCHER_REQUEST_CANCEL',
  VOUCHER_CANCEL = 'VOUCHER_CANCEL',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_EDIT = 'ROLE_EDIT',
  STORE_GROUP_CREATE = 'STORE_GROUP_CREATE',
  STORE_GROUP_EDIT = 'STORE_GROUP_EDIT',
  USER_CREATE = 'USER_CREATE',
  USER_EDIT = 'USER_EDIT',
  STORE_ASSORTMENT_SUBMIT_STORE = 'STORE_ASSORTMENT_SUBMIT_STORE',
  STORE_ASSORTMENT_SUBMIT_ITEM = 'STORE_ASSORTMENT_SUBMIT_ITEM',
  SHELF_INVENTORY_APPROVE = 'SHELF_INVENTORY_APPROVE',
  SHELF_INVENTORY_REJECT = 'SHELF_INVENTORY_REJECT',
  SHELF_INVENTORY_CANCEL_REQUEST = 'SHELF_INVENTORY_CANCEL_REQUEST',
  SHELF_INVENTORY_DELETE = 'SHELF_INVENTORY_DELETE',
  SHELF_FIX_ASSET_APPROVE = 'SHELF_FIX_ASSET_APPROVE',
  SHELF_FIX_ASSET_REJECT = 'SHELF_FIX_ASSET_REJECT',
  SHELF_FIX_ASSET_CANCEL_REQUEST = 'SHELF_FIX_ASSET_CANCEL_REQUEST',
  SHELF_FIX_ASSET_DELETE = 'SHELF_FIX_ASSET_DELETE',
  Z8_SUBMIT_ITEM = 'Z8_SUBMIT_ITEM'
}

export class Metrics {
  metrics: (CounterMetric | HistogramMetric | ResponseTimeMetric)[];
}

export class Metric {
  module: MetricModule | string;
  metricType: MetricType;
}

export class CounterMetric extends Metric {
  method?: string;
  path?: string;
  statusCode?: number;
}

export class HistogramMetric extends Metric {
  action: MetricAction | string;
  items: number;
  type: string;
}

export class ResponseTimeMetric extends Metric {
  action: MetricAction | string;
  duration: number;
  route: string;
  method: string;
  actionType?: MetricActionType | string;
}
