<div class="full-popup-head">
  <div class="full-popup-title flex"><em class="modal-title-icon icon-td-marketing"></em>{{ data.title }}</div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="isRequestViewMode && (promotionRequestView$ | async)?.status === 'W' && hasEditPromotionPermission"
    (click)="onCancelPromotion()"
  >
    <em class="icon-forbidden"></em>Cancel Request
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div>
  <div class="admin-form" [formGroup]="form">
    <div class="info-header-container">
      <div class="info-header-item" *ngIf="promotion?.requestNo">
        <div class="info-header-label">
          <span>Request No.:</span>
        </div>
        <div class="info-header-value">
          <span>{{ promotion?.requestNo }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Request Status:</span>
        </div>
        <div class="info-header-value">
          <span
            *ngIf="promotionRequestPageMode.REQUEST_CREATE === data.mode; else getStatus"
            [ngClass]="getColorStatus('D')"
            class="request-status"
          >
            {{ 'PROMOTION.STATUS.D' | translate }}</span
          >
          <ng-template #getStatus>
            <span [ngClass]="getColorStatus(status)" class="request-status">
              {{ 'PROMOTION.STATUS.' + status | translate | titlecase }}</span
            >
          </ng-template>
        </div>
      </div>
    </div>

    <div>
      <div class="row section-header">
        <span>Promotion Details</span>
      </div>
      <div>
        <app-promotion-details
          #promotionDetails
          [mode]="data.mode"
          [submitted]="submitted"
          [parentForm]="promotionForm"
          [saveDraft]="saveDraft"
        >
        </app-promotion-details>
      </div>

      <hr class="section-divider" />

      <div class="row section-header">
        <span>Promotion Condition</span>
      </div>
      <div>
        <app-promotion-condition
          #promotionCondition
          [mode]="data.mode"
          [submitted]="submitted"
          [parentForm]="promotionForm"
          [saveDraft]="saveDraft"
        >
        </app-promotion-condition>
      </div>

      <hr class="section-divider" />

      <div class="row section-header">
        <span>Promotion Item</span>
      </div>
      <div class="mb-5">
        <app-promotion-item
          id="promotionItem"
          class="mb-5"
          #promotionItem
          [mode]="data.mode"
          [submitted]="submitted"
          [promotionItemType]="'promotionItem'"
          [parentForm]="promotionForm"
          [saveDraft]="saveDraft"
        >
        </app-promotion-item>
      </div>

      <hr class="section-divider" />

      <div class="row section-header" [hidden]="!isCoPromotion()">
        <span>Co-Promotion Item</span>
      </div>
      <div class="mb-5" [hidden]="!isCoPromotion()">
        <app-promotion-item
          id="coPromotionItem"
          class="mb-5"
          #coPromotionItem
          [submitted]="submitted"
          [promotionItemType]="'coPromotionItem'"
          [mode]="data.mode"
          [parentForm]="promotionForm"
          [saveDraft]="saveDraft"
        >
        </app-promotion-item>
      </div>

      <div class="row section-header">
        <span>Select Store</span>
      </div>
      <div class="mb-5">
        <app-promotion-store
          id="promotionStore"
          class="mb-5"
          [submitted]="submitted"
          [mode]="data.mode"
          [status]="status"
          [promotion]="promotion"
          [parentForm]="promotionForm"
          [saveDraft]="saveDraft"
          #promotionStore
        >
        </app-promotion-store>
      </div>

      <!--footer-->
      <div
        class="fixed-row-bottom form-row"
        *ngIf="
          (isRequestEditMode || isRequestCreateMode || isRequestViewMode) &&
          (hasEditPromotionPermission || hasApprovePromotionPermission)
        "
      >
        <div class="float-left">
          <button
            *ngIf="isRequestEditMode || isRequestCreateMode"
            type="button"
            id="btn-save"
            class="btn btn-secondary"
            (click)="onSave()"
            appPreventDoubleClick
          >
            Save
          </button>
        </div>

        <div class="float-right">
          <button
            *ngIf="isRequestEditMode || (isRequestCreateMode && hasEditPromotionPermission)"
            type="button"
            id="btn-cancel"
            class="btn btn-standard mr-2"
            (click)="onExit()"
          >
            Cancel
          </button>
          <button
            *ngIf="isRequestEditMode || (isRequestCreateMode && hasEditPromotionPermission)"
            type="button"
            id="btn-submit"
            class="btn btn-primary"
            (click)="onSubmit()"
            appPreventDoubleClick
          >
            Submit
          </button>
          <button
            *ngIf="isRequestViewMode && (promotionRequestView$ | async)?.status === 'D' && hasEditPromotionPermission"
            type="button"
            id="btn-request-edit"
            class="btn btn-primary"
            (click)="toggleToEditMode()"
          >
            Edit
          </button>
          <button
            *ngIf="
              isRequestViewMode && (promotionRequestView$ | async)?.status === 'W' && hasApprovePromotionPermission
            "
            type="button"
            class="btn btn-special-reject mr-3"
            id="btn-reject"
            (click)="reject()"
            appPreventDoubleClick
          >
            Reject
          </button>
          <button
            *ngIf="
              isRequestViewMode && (promotionRequestView$ | async)?.status === 'W' && hasApprovePromotionPermission
            "
            type="button"
            class="btn btn-special-approve mr-3"
            id="btn-approve"
            (click)="approve()"
            appPreventDoubleClick
          >
            Approve
          </button>
        </div>
      </div>
      <!--End footer-->
    </div>
  </div>
</div>
