import { ApproveStatusEnum } from '../enum/approve-status.enum';
import { ProductTypeEnum } from '../enum/product-type.enum';
import { RequestStatusEnum } from '../enum/request-status.enum';
import { RequestStepEnum, RequestTypeEnum } from '../enum/request-step.enum';
import { RewardCatalogRequestStatusEnum, RewardCatalogStatusEnum } from '../enum/reward-catalog.enum';
import { StoreType } from '../enum/store.enum';
import { AuditLog } from './audit-log.model';

import { AssortmentDeliveryMethodEnum, Money, ProductStatusEnum, Sort } from '.';

export class RewardCatalogListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  effectiveDateFrom?: string;
  effectiveDateTo?: string;
  validDateFrom?: string;
  validDateTo?: string;
  expireDateFrom?: string;
  expireDateTo?: string;
  page: number;
  size: number;
}

export class RewardCatalogRequestListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  effectiveDateFrom?: string;
  effectiveDateTo?: string;
  validDateFrom?: string;
  validDateTo?: string;
  expireDateFrom?: string;
  expireDateTo?: string;
  page: number;
  size: number;
}

export interface RewardCatalogList {
  id: string;
  requestNo: string;
  productName: string;
  catalogNo: string;
  rewardCatalogNo: string;
  productImages: ProductImages;
  articleNo: string;
  barcode: string;
  effectiveDate: string;
  expireDate: string;
  createdDate: string;
  requestBy: string;
  requestDate: string;
  step: RequestStepEnum;
  status: RequestStatusEnum;
  type: RequestTypeEnum;
}

export interface RewardCatalogRequestList {
  id: string;
  requestNo: string;
  productName: string;
  catalogNo: string;
  effectiveDate: string;
  expireDate: string;
  createdBy: string;
  createdDate: string;
  requestedBy: string;
  requestedDate: string;
  status: RewardCatalogRequestStatusEnum;
  step: RequestStepEnum;
  requestStatus: RequestStatusEnum;
  auditLogs: AuditLog[];
  type: RequestTypeEnum;
}

export class RewardCatalogListResponse {
  id: number;
  content: RewardCatalogList[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class RewardCatalogRequestListResponse {
  id: number;
  content: RewardCatalogRequestList[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}
export class RewardCatalogViewResponse {
  id?: string;
  version?: number;
  productName: string;
  rewardCatalogNo: string;
  requestNo: string;
  catalogNo: string;
  isCancelled: boolean;
  type: RequestTypeEnum;
  status: RewardCatalogStatusEnum;
  articleNo: string;
  barcode: string;
  unit: string;
  unitFactor: string;
  leadTimeByDay: number;
  conditionType: string;
  conditions: Conditions[];
  unlimitedRewardPerBillQty: boolean;
  limitRewardPerBillQty: number;
  unlimitedRewardPerMemberQty: boolean;
  limitRewardPerMemberQty: number;
  selectStoreType: string;
  stores: Stores[];
  expireDate: string;
  effectiveDate: string;
  createdDate: string;
  createdBy: string;
  createdByName: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
}

export class RewardCatalogCreateRequest {
  rewardCatalogCreatRequest: RewardCatalogRequestTemplate;
}

export class RewardCatalogRequestTemplate {
  id: string;
  version: number;
  catalogNo: string;
  articleNo: string;
  barcode: string;
  unit: string;
  unitFactor: string;
  conditionType: string;
  conditions: Conditions[];
  unlimitedRewardPerBillQty: boolean;
  limitRewardPerBillQty: number;
  unlimitedRewardPerMemberQty: boolean;
  limitRewardPerMemberQty: number;
  leadTimeByDay: number;
  selectStoreType: string;
  status: string;
  type: string;
  expireDate: string;
  effectiveDate: string;
  stores?: Stores;
  byPassDupCat?: boolean;
}

export class RewardCatalogRequestResponse {}

export class RewardCatalogRequestId {
  rewardCatalogRequestId: string;
}
export class RewardCatalogRequestViewResponse {
  id?: string;
  version?: number;
  requestNo?: string;
  rewardCatalogNo?: string;
  type: RequestTypeEnum;
  status: RewardCatalogRequestStatusEnum;
  catalogNo: string;
  articleNo: string;
  barcode: string;
  unit: string;
  unitFactor: string;
  conditionType: string;
  conditions: Conditions[];
  unlimitedRewardPerBillQty: boolean;
  limitRewardPerBillQty: number;
  unlimitedRewardPerMemberQty: boolean;
  limitRewardPerMemberQty: number;
  leadTimeByDay: number;
  selectStoreType: string;
  stores: Stores[];
  expireDate: string;
  effectiveDate: string;
}

export class RewardCatalog {
  requestNo: string;
}

export class ChangeStatusRewardCatalogRequest {
  id: string;
  status: ApproveStatusEnum;
  comment: string;
}

export interface ProductNameSearchCriteria {
  searchCriteria?: string;
  deliveryMethod?: AssortmentDeliveryMethodEnum;
  excStatus?: ProductStatusEnum[];
  productType?: ProductTypeEnum;
  excRestrictedItem: boolean;
  size?: number;
}

export interface ProductNameResponse {
  articleNo: string;
  productName: string;
  pickingUnitBarcode: string;
  unit: string;
  unitFactor: number;
}

export class Conditions {
  type: string;
  point?: string;
  cash?: string;
}
export class Stores {
  no: string;
  code: string;
  merchantType: StoreType;
  name: string;
  region: string;
  state: string;
}

export class RewardCatalogPointCash {
  basePoint: boolean;
  point: number;
  cash: Money;
}
export class RewardCatalogProduct {
  articleNo: string;
  productName: string;
  pickingUnitBarcode: string;
  unit: string;
  unitFactor: string;
  retailPriceIncVat?: Money;
}

export class ProductImages {
  directory: string;
  fileName: string;
  id: string;
  mediumSizeFilePath: string;
  smallSizeFilePath: string;
}

export class RewardCatalogExportCriteria {
  status?: string;
  validDateFrom?: string;
  validDateTo?: string;
  expireDateFrom?: string;
  expireDateTo?: string;
  effectiveDateFrom?: string;
  effectiveDateTo?: string;

  constructor(init?: Partial<RewardCatalogExportCriteria>) {
    Object.assign(this, init);
  }
}
