import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { delay } from 'rxjs/operators';

import { DisableButtonService } from '../services/disable-button.service';

@Directive({
  selector: '[appDisableButton]'
})
export class DisableButtonDirective implements OnInit {
  @Input() isFormInvalid: boolean;
  @Output() click = new EventEmitter();

  @HostListener('click')
  onClick() {
    if (!this.isFormInvalid) {
      this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'disabled');
    }
  }

  @HostListener('dblclick')
  onDoubleClicked() {
    this.onClick();
  }

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly disableButtonService: DisableButtonService
  ) {}

  ngOnInit() {
    this.disableButtonService.loaded.pipe(delay(500)).subscribe(() => {
      this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
    });
  }
}
