import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { DataInsightSaveTypeEnum } from '../../enum/data-insight.enum';
import { Customer360Service } from '../../services/customer360.service';
import {
  Customer360ActionTypes,
  Customer360GetTemplateByIDRequestAction,
  Customer360GetTemplateByIDResponseAction,
  Customer360ListRequestAction,
  Customer360ListResponseAction,
  Customer360PreviewRequest,
  Customer360PreviewResponse,
  Customer360SaveNewTemplateErrorAction,
  Customer360SaveNewTemplateRequestAction,
  Customer360SaveNewTemplateSuccessAction,
  Customer360TemplateResponseViewPageErrorAction
} from '../actions/customer360.action';
import { LayoutActionLoadError, LayoutActionVersionError } from '../actions/layout.action';

export enum CUSTOMER_360_ERROR_CODE {
  DUPLICATED_FOLDER = '01001',
  DUPLICATED_TEMPLATE = '01002',
  INVALID_FOLDER = '00005'
}

@Injectable()
export class Customer360Effects {
  constructor(
    private readonly actions$: Actions,
    private readonly customer360Service: Customer360Service,
    private readonly logger: NGXLogger
  ) {}

  searchCustomer360List$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<Customer360ListRequestAction>(Customer360ActionTypes.CUSTOMER360_LIST_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${Customer360ActionTypes.CUSTOMER360_LIST_REQUEST}: ` + JSON.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.customer360Service.searchCustomer360Criteria(payload).pipe(
          map(requests => {
            return new Customer360ListResponseAction(requests);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  previewData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<Customer360PreviewRequest>(Customer360ActionTypes.CUSTOMER360_PREVIEW_REQUEST),
      tap(action => this.logger.debug(`@Effect Customer360 Preview Requested:` + JSON.stringify(action.payload))),
      switchMap(action =>
        this.customer360Service.previewData(action.payload).pipe(
          map(response => new Customer360PreviewResponse(response)),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  saveNewTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<Customer360SaveNewTemplateRequestAction>(Customer360ActionTypes.CUSTOMER360_SAVE_NEW_TEMPLATE_REQUEST),
      tap(action =>
        this.logger.debug(
          `@Effect ${Customer360ActionTypes.CUSTOMER360_SAVE_NEW_TEMPLATE_REQUEST}: ` + JSON.stringify(action)
        )
      ),
      switchMap(action => {
        const { actionMode } = action.data;
        return this.customer360Service.saveNewTemplate(action.data.payload).pipe(
          map(() => new Customer360SaveNewTemplateSuccessAction(true)),
          catchError(err => of(this.responseError(err, actionMode)))
        );
      })
    );
  });

  onCustomer360TemplateResponseViewPageErrorAction$ = this.actions$.pipe(
    ofType(Customer360ActionTypes.CUSTOMER360_GET_TEMPLATE_RESPONSE_VIEW_PAGE_ERROR)
  );

  getCustomer360TemplateByID$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<Customer360GetTemplateByIDRequestAction>(Customer360ActionTypes.CUSTOMER360_GET_TEMPLATE_BY_ID_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${Customer360ActionTypes.CUSTOMER360_GET_TEMPLATE_BY_ID_REQUEST}: ` +
            JSON.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.customer360Service.getTemplateByID(payload.id).pipe(
          map(response => {
            return new Customer360GetTemplateByIDResponseAction(response);
          }),
          catchError(error => {
            return of(this.responseCustomer360TemplateError(error, payload?.isViewPage));
          })
        );
      })
    );
  });

  responseCustomer360TemplateError(error, isViewPage?: boolean) {
    if (error.error && error.error.code === '00004') {
      if (isViewPage) {
        return new Customer360TemplateResponseViewPageErrorAction();
      }
      return new LayoutActionVersionError(true);
    }
    return new LayoutActionLoadError(error);
  }

  private responseError(error, actionMode?: DataInsightSaveTypeEnum) {
    if (
      error &&
      error.error &&
      [CUSTOMER_360_ERROR_CODE.DUPLICATED_FOLDER, CUSTOMER_360_ERROR_CODE.DUPLICATED_TEMPLATE].includes(
        error.error.code
      )
    ) {
      return new Customer360SaveNewTemplateErrorAction(error.error);
    }
    if (error && error.error && error.error.code === CUSTOMER_360_ERROR_CODE.INVALID_FOLDER) {
      if (actionMode === DataInsightSaveTypeEnum.SAVE_NEW) {
        return new Customer360SaveNewTemplateErrorAction(error.error);
      }
      // Action save template then invalid folder open error modal.
      if (actionMode === DataInsightSaveTypeEnum.SAVE) {
        return new Customer360TemplateResponseViewPageErrorAction();
      }
    }
    if (error && error.error && error.error.code === '00004') {
      return new Customer360TemplateResponseViewPageErrorAction();
    }
    if (error && error.error && error.error.code === '00001') {
      return new LayoutActionVersionError(true);
    }
    return new LayoutActionLoadError(error);
  }
}
