import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { SettingService } from '../../services/setting.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { PriceSettingRequested, PriceSettingSuccess, SettingActionTypes } from '../actions/setting.actions';
import { selectPriceSetting } from '../selectors/setting.selectors';
import { AppStates } from '../state/app.states';

@Injectable()
export class SettingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly settingService: SettingService,
    private readonly logger: NGXLogger,
    private readonly store: Store<AppStates>
  ) {}

  getPriceSetting$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<PriceSettingRequested>(SettingActionTypes.PRICE_SETTING_REQUESTED),
      tap(action =>
        this.logger.debug(`@Effect ${SettingActionTypes.PRICE_SETTING_REQUESTED}: ` + JSON.stringify(action))
      ),
      concatLatestFrom(() => this.store.select(selectPriceSetting)),
      // Check data store is empty  before calling api
      filter(([, selector]) => selector === null),
      switchMap(() =>
        this.settingService.getPriceSetting().pipe(
          map(response => new PriceSettingSuccess({ priceSetting: response })),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });
}
