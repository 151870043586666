import { Component } from '@angular/core';

@Component({
  selector: 'app-force-change-password-left',
  templateUrl: './force-change-password-left.component.html',
  styleUrls: ['./force-change-password-left.component.scss']
})
export class ForceChangePasswordLeftComponent {
  constructor() {
    // intentionally empty
  }
}
