import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  Customer360ManageAccessRequest,
  Customer360Pagination,
  Customer360SearchCriteria
} from '../models/customer360.model';
import {
  DataInsightPreviewDataRequest,
  DataInsightPreviewDataResponse,
  DataInsightSaveTemplateRequest,
  DataInsightTemplate,
  DataInsightViewResponse
} from '../models/data-insight.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class Customer360Service extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.customer360;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchCustomer360Criteria(criteria: Customer360SearchCriteria): Observable<Customer360Pagination> {
    const url = this.getUrl();
    const params = this.getParams(criteria, true);

    return this.http.get<Customer360Pagination>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getTemplate(): Observable<DataInsightTemplate> {
    const url = this.getFullUrl(this.envService.template);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getFolderList(): Observable<any> {
    const url = this.getFullUrl(this.envService.folderList);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public previewData(request: DataInsightPreviewDataRequest): Observable<DataInsightPreviewDataResponse> {
    const url = this.getFullUrl(this.envService.preview);

    return this.http.post<DataInsightPreviewDataResponse>(url, request, {
      headers: this.loaderHeaders(0),
      observe: 'body'
    });
  }

  public saveNewTemplate(request: DataInsightSaveTemplateRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.saveTemplate);

    return this.http.post<any>(url, request, {
      headers: this.loaderHeaders(2000),
      observe: 'body'
    });
  }

  public getTemplateByID(id: string): Observable<DataInsightViewResponse> {
    const url = this.getFullUrl(this.envService.viewTemplate, {
      id
    });

    return this.http.get<DataInsightViewResponse>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  manageAccess(request: Customer360ManageAccessRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.manageAccess);

    return this.http.post<any>(url, request, {
      headers: this.loaderHeaders(2000),
      observe: 'body'
    });
  }

  deleteTemplate(templateId: string): Observable<any> {
    const url = this.getFullUrl(this.envService.deleteTemplate, { templateId });

    return this.http.delete<any>(url, {
      headers: this.loaderHeaders(2000),
      observe: 'body'
    });
  }
}
