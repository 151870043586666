<!-- Modal Product Section -->
<div
  id="searchProductModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #searchProductModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog" [ngStyle]="{ 'min-width': '550px' }">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h6 class="modal-title pull-left">
            Select Item
          </h6>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="closeSelectProductModal()"
            id="selectProduce-modal-closeBtn"
          >
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body my-2 pt-0">
          <div class="container" [formGroup]="searchForm">
            <div class="row">
              <div class="col-12 mt-2">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Search By : Product Name, Barcode, Article No.<span class="text-danger">*</span></label
                        >
                        <ng-select
                          #productSelect
                          [items]="productList | async"
                          bindLabel="productName"
                          dropdownPosition="auto"
                          labelForId="album"
                          placeholder="Please select..."
                          notFoundText="No Data"
                          [loading]="productSearchLoading"
                          [typeahead]="productSearchInput$"
                          [virtualScroll]="true"
                          (change)="onChangeProductName($event)"
                          (blur)="onBlurProductName()"
                          formControlName="ProductName"
                          [ngClass]="{
                            'is-invalid':
                              submittedSelectProduct && form.ProductName.errors && form.ProductName.errors.required
                          }"
                        >
                          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <div>
                              <strong>Product Name:</strong>
                              <span [optionHighlight]="search" class="ml-1">
                                {{ item.productName }}
                              </span>
                            </div>
                            <small>
                              <strong>Barcode :</strong
                              ><span [ngOptionHighlight]="search" class="ml-1">{{ item.barcode }}</span
                              >{{ ' | ' }} <strong>Unit :</strong
                              ><span [ngOptionHighlight]="search" class="ml-1">{{ item.unit }}</span> {{ ' | ' }}
                              <strong>Article No.:</strong
                              ><span [ngOptionHighlight]="search" class="ml-1">{{ item.articleNo }}</span>
                            </small>
                          </ng-template>
                        </ng-select>
                        <div
                          *ngIf="
                            submittedSelectProduct && form.ProductName.errors && form.ProductName.errors.required
                          "
                          class="invalid-feedback"
                        >
                          {{ 'ERRORS.REQUIRED' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-standard"
            (click)="closeSelectProductModal()"
            id="product-modal-cancelBtn"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            id="product-modal-addBtn"
            (click)="saveProduct()"
            appPreventDoubleClick
          >
            OK
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Modal Product Section -->
