import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { AppStates } from '@shared/store/state/app.states';

import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { ShelfDetailRequest } from '../../../../shared/models/first-lot-order.model';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import { selectAllShelfDetails } from '../../../../shared/store/selectors/shelf-details.selectors';

@Component({
  selector: 'app-view-shelf-details',
  templateUrl: './view-shelf-details.component.html',
  styleUrls: ['./view-shelf-details.component.scss']
})
export class ViewShelfDetailsComponent extends OnDestroyMixin implements OnInit, OnDestroy, AfterContentChecked {
  @Input() data: { backPageTitle: string };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public searchForm: UntypedFormGroup;
  public shelfDetails: ShelfDetailRequest[];
  public filteredShelfDetail: ShelfDetailRequest[];
  public currentPage = 1;
  public pageSize = 20;

  private localStore: Observable<any>;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly store: Store<AppStates>,
    protected fb: UntypedFormBuilder,
    private readonly cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.initialData();
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });
  }

  initialData() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore.pipe(select(selectAllShelfDetails)).subscribe(response => {
      this.shelfDetails = [...response].sort(this.sortList);

      this.search();
    });
  }

  onBack() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  onSubmit() {
    this.setFirstPage();
    this.search();
  }

  search() {
    this.setFirstPage();

    const criteria = this.searchForm.value;
    if (criteria.searchCriteria && criteria.searchCriteria.length > 0) {
      this.filteredShelfDetail = [...this.shelfDetails].filter(item => {
        return (
          this.isMatchKeyword(item.shelfCode, criteria.searchCriteria) ||
          this.isMatchKeyword(item.shelfType, criteria.searchCriteria) ||
          this.isMatchKeyword(item.shelfOption, criteria.searchCriteria)
        );
      });
    } else {
      this.filteredShelfDetail = [...this.shelfDetails];
    }
  }

  sortList(a: ShelfDetailRequest, b: ShelfDetailRequest) {
    const aShelfType = a.shelfType;
    const bShelfType = b.shelfType;

    if (aShelfType < bShelfType) {
      return -1;
    }

    return 0;
  }

  isMatchKeyword(keyword: string, criteria: string) {
    return keyword.toLowerCase().includes(criteria.toLowerCase());
  }

  clearSearchText() {
    this.clearSearch();
  }

  clearLastKeyUpSearchText() {
    const criteria = this.searchForm.value;
    if (criteria.searchCriteria && criteria.searchCriteria.length > 0) {
      return;
    }

    this.clearSearch();
  }

  clearSearch() {
    this.setFirstPage();
    this.searchForm.controls['searchCriteria'].reset();
    this.filteredShelfDetail = [...this.shelfDetails];
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  setInitialValue() {
    this.pageSize = 20;
  }

  getItemNo(index: number) {
    return index + (this.currentPage - 1) * this.pageSize + 1;
  }
}
