<div [formGroup]="parentForm">
  <div
    class="row"
    [formArrayName]="formPreOrderName"
    *ngFor="let tdStore of schedules.controls; let i = index; first as firstIndex; last as lastIndex"
  >
    <div class="col-md-12 inline-flex mb-3 align-items-baseline">
      <div class="row flex-item align-items-baseline" [formGroupName]="i">
        <div class="col">
          <label for="orderScheduleDate" *ngIf="firstIndex">
            Order Schedule Date<span class="text-danger">*</span>
          </label>
          <ng-select
            id="preOrderScheduleDate"
            data-id="preOrderScheduleDate"
            placeholder="Please select..."
            [items]="orderScheduleDateOptions"
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            bindLabel="nameTh"
            bindValue="code"
            [dropdownPosition]="ddlPosition"
            formControlName="orderScheduleDate"
            (change)="onSelectedPreOrderScheduleDate(i)"
            (open)="updatePreOrderScheduleDateOptions(i)"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.orderScheduleDate.errors
            }"
          >
          </ng-select>
          <div *ngIf="submitted && tdStore.controls.orderScheduleDate.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.orderScheduleDate.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <div class="col">
          <label for="deliverySchedule" *ngIf="firstIndex">Delivery Schedule<span class="text-danger">*</span></label>
          <ng-select
            id="preOrderDeliverySchedule"
            data-id="preOrderDeliverySchedule"
            placeholder="Please select..."
            [items]="deliveryScheduleDateOptions"
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            bindLabel="nameTh"
            bindValue="code"
            [dropdownPosition]="ddlPosition"
            formControlName="deliverySchedule"
            (change)="onSelectedDeliveryScheduleDate(i)"
            (open)="updateDeliveryScheduleDateOptions(i)"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.deliverySchedule.errors
            }"
          ></ng-select>
          <div *ngIf="submitted && tdStore.controls.deliverySchedule.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.deliverySchedule.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col pl-0">
          <label for="pickScheduleDate" *ngIf="firstIndex">Pick Schedule<span class="text-danger">*</span></label>
          <ng-select
            id="preOrderPickScheduleDate"
            data-id="preOrderPickScheduleDate"
            placeholder="Please select..."
            [items]="pickScheduleOptions"
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            bindLabel="nameTh"
            bindValue="code"
            [dropdownPosition]="ddlPosition"
            formControlName="pickScheduleDate"
            (open)="updatePickScheduleOptions(tdStore.value.orderScheduleDate, tdStore.value.deliverySchedule)"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.pickScheduleDate.errors
            }"
          ></ng-select>
          <div *ngIf="submitted && tdStore.controls.pickScheduleDate.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.pickScheduleDate.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div
          [ngClass]="{
            'd-none': mode === 'REQUEST_VIEW',
            'col-1 pr-0 pl-0 d-flex justify-content-center align-content-center':
              mode !== 'REQUEST_VIEW' && checkEditPermission() && canEditSection()
          }"
        >
          <button
            type="button"
            id="delete-pre-order-schedule"
            data-id="delete-pre-order-schedule"
            class="btn btn-bin-delete"
            (click)="deleteSchedule(i)"
            title="Delete"
            appTippy
            [tippyOptions]="{ arrow: true }"
            [ngClass]="{
              'd-none': firstIndex || !(checkEditPermission() && canEditSection()),
              'd-block': !(firstIndex || !(checkEditPermission() && canEditSection()))
            }"
          >
            <em class="icon-delete mr-0"></em>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="showAddMore">
    <div class="col-md-12 mb-3">
      <button
        type="button"
        id="add-pre-order-schedule"
        data-id="add-pre-order-schedule"
        *ngIf="showBtn()"
        class="btn btn-secondary"
        (click)="addSchedule()"
      >
        <em class="icon-plus"></em>
        Add More
      </button>
    </div>
  </div>
</div>
