import { createSelector } from '@ngrx/store';

import * as fromClassMarkup from '../reducers/class-markup.reducers';
import { AppStates } from '../state/app.states';

const selectClassMarkupState = (state: AppStates) => state.classMarkup;

export const selectClassMarkupList = createSelector(selectClassMarkupState, fromClassMarkup.selectAll);

export const selectClassMarkupTotal = createSelector(
  selectClassMarkupState,
  (state: fromClassMarkup.ClassMarkupState) => state
);

export const selectCurrentClassMarkup = createSelector(
  selectClassMarkupState,
  (state: fromClassMarkup.ClassMarkupState) => state.currentClassMarkup
);
