import gql from 'graphql-tag';

export const holdAssortmentReasonSchema = 'holdAssortmentReason';

export const holdAssortmentReasonListQuery = gql`
  query HoldAssortmentReason($holdType: Boolean!, $unholdType: Boolean!) {
    holdAssortmentReason(holdType: $holdType, unholdType: $unholdType) {
      nameEn
      code
      holdType
      unholdType
    }
  }
`;
