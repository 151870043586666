import { Component, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-modal-template',
  templateUrl: './alert-modal-template.component.html',
  styleUrls: ['./alert-modal-template.component.scss']
})
export class AlertModalTemplateComponent {
  template: TemplateRef<HTMLElement>;
  details: any;
  submitText;
  hasCancel = false;
  hasSubmit = true;
  message: string;
  title = 'Alert';
  messageClass = 'text-center w-100 mb-4';
  bodyClass = '';
  submitAction: () => void;
  cancelAction: () => void;

  constructor(public bsModalRef: BsModalRef) {}

  cancel() {
    if (this.cancelAction) {
      this.cancelAction();
    }

    this.bsModalRef.hide();
  }

  close() {
    this.bsModalRef.hide();
  }

  submit() {
    if (this.submitAction) {
      this.submitAction();
    } else {
      this.bsModalRef.hide();
    }
  }
}
