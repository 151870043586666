import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OptionHighlightDirective } from './option-higlight.directive';

@NgModule({
  declarations: [OptionHighlightDirective],
  imports: [CommonModule],
  exports: [OptionHighlightDirective]
})
export class OptionHiglightModule {}
