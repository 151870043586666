<div class="select-item-modal full-popup-head">
  <a aria-label="Back" class="clickable" (click)="onBack()">
    <div class="full-popup-close float-left">
      <span aria-label="Close" class="close">
        <em class="icon-prev ml-2"></em>
      </span>
    </div>
    <div class="full-popup-title">Back to Order Request</div>
  </a>
  <div class="full-popup-title ml-auto">
    <em class="icon-shelf"></em>
    <span class="badge badge-pill badge-success">{{ getSelectedItem() }}</span>
  </div>
</div>

<div class="admin-form">
  <div class="row section-header">
    <span>Select Shelf</span>
  </div>

  <ng-container *ngIf="formShelfItems.controls && formShelfItems.controls.length === 0">
    <div class="align-middle" *ngIf="true">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="no item" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="formShelfItems.controls && formShelfItems.controls.length > 0">
    <div class="table-responsive table-scroll mt-3" [formGroup]="shelfListForm">
      <table id="shelf-table" class="table table-striped table-bordered w-100" aria-label="shelf-table">
        <thead>
          <tr>
            <th class="text-center w-5" scope="col">No.</th>
            <th class="text-center w-40" scope="col">Shelf Type</th>
            <th class="text-center w-45" scope="col">Shelf Option</th>
            <th class="text-center w-10" scope="col">Action</th>
          </tr>
        </thead>
        <tbody formArrayName="shelfItems">
          <tr *ngFor="let item of formShelfItems.controls; index as i" [formGroupName]="i">
            <td class="text-center">
              <div class="mt-2">{{ i + 1 }}</div>
            </td>
            <td>
              <div class="mt-2">{{ item.get('shelfType').value }}</div>
            </td>
            <td>
              <ng-select
                formControlName="shelfCode"
                id="shelfOptions"
                name="shelfOptions"
                class="select-default-width"
                placeholder="Please select..."
                [items]="getShelfOption(i)"
                *ngIf="getShelfOption(i) && getShelfOption(i).length > 1"
                [searchable]="false"
                [clearable]="true"
                bindLabel="name"
                bindValue="code"
                appendTo="body"
                (open)="disableTableScroll(true)"
                (close)="disableTableScroll(false)"
                [ngClass]="{
                  'is-invalid': submitted && item.get('shelfCode').errors
                }"
              >
              </ng-select>
              <div class="mt-2" *ngIf="getShelfOption(i) && getShelfOption(i).length === 1">
                {{ getShelfOption(i)[0].name }}
              </div>
              <div *ngIf="submitted && item.get('shelfCode').errors" class="text-danger">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </td>
            <td class="text-center">
              <button
                type="button"
                class="btn btn-standard btn-block"
                [ngClass]="{
                  'btn-standard': !isSelectedShelf(item),
                  'btn-special-approve': isSelectedShelf(item)
                }"
                (click)="selectShelf(i)"
              >
                {{ isSelectedShelf(item) ? 'Selected' : 'Select' }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <!--footer-->
  <div class="fixed-row-bottom form-row">
    <div class="float-left"></div>
    <div class="float-right">
      <span class="mr-2">
        <button type="button" id="btnOK" class="btn btn-primary" [disabled]="!getSelectedItem()" (click)="onSubmit()">
          OK
        </button>
      </span>
    </div>
  </div>
  <!--End footer-->
</div>
