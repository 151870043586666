import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable()
export class PointEarnAndBurnReportService extends BaseService {
  public headers: HttpHeaders;
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.pointEarnAndBurnReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportPointBurnTransactionReport(data): Observable<any> {
    const url = this.getFullUrl(this.envService.exportBurnTransaction);

    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public exportPointEarnTransactionReport(data): Observable<any> {
    const url = this.getFullUrl(this.envService.exportEarnTransaction);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  getStore() {
    const url = this.getFullUrl(this.envService.stores);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
}
