import { createSelector } from '@ngrx/store';

import { AppStates } from '../state/app.states';
import { AuthUserInfoState } from '../state/auth-user-info.state';

const selectUserInfo = (state: AppStates) => state.userInfo;

export const selectUserInfoResult = createSelector(selectUserInfo, (state: AuthUserInfoState) => state);

export const selectDisplayNameResult = createSelector(
  selectUserInfo,
  (state: AuthUserInfoState) => state.displayName
);

export const selectAuthoritiesResult = createSelector(selectUserInfo, (state: AuthUserInfoState) => {
  return state && state.authorities && state.authorities.length > 0 ? state.authorities : null;
});
