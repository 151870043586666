import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { Menu } from '../../models/menu.model';
import { MenuActionTypes, MenuActions } from '../actions/menu.action';

export interface MenuState extends EntityState<Menu> {
  menus: Menu[];
}

export const adapter: EntityAdapter<Menu> = createEntityAdapter<Menu>();

export const initialMenuState: MenuState = adapter.getInitialState({
  menus: []
});

export function menuReducers(state = initialMenuState, action: MenuActions): MenuState {
  if (action.type === MenuActionTypes.MenuLoaded) {
    return {
      ...state,
      menus: action.payload
    };
  } else if (action.type === MenuActionTypes.MenuUpdated) {
    const menus = state.menus.map(menu =>
      menu.group === action.payload.group && menu.link === action.payload.link && menu.title === action.payload.title
        ? { ...menu, active: true }
        : { ...menu, active: false }
    );
    return {
      ...state,
      menus
    };
  } else {
    return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
