import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { ModalButtonResponseEnum } from '@shared/enum/modal-button-response.enum';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-terminate-store-modal',
  templateUrl: './terminate-store-modal.component.html',
  styleUrls: ['./terminate-store-modal.component.scss']
})
export class TerminateStoreModalComponent implements OnInit {
  storeCode: string;
  storeName: string;
  openDate: string;
  storeOpenDate: Date;
  currentDate: Date = new Date();

  action = new Subject<ModalButtonResponseEnum>();
  bsConfig: BsDatepickerConfig;
  dateFormat = environment.dateFormat;
  submitted = false;

  terminateDate: FormControl;

  constructor(private bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.bsConfig = ({
      dateInputFormat: this.dateFormat,
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue',
      useUtc: false
    } as unknown) as BsDatepickerConfig;

    this.terminateDate = new FormControl(null, Validators.required);

    this.storeOpenDate = this.openDate ? new Date(this.openDate) : null;
  }

  confirm(): void {
    this.submitted = true;

    if (this.terminateDate.invalid) {
      return;
    }

    this.action.next(ModalButtonResponseEnum.OK);
  }

  decline(): void {
    this.action.next(ModalButtonResponseEnum.CANCEL);
    this.bsModalRef.hide();
  }

  hide(): void {
    this.bsModalRef.hide();
  }
}
