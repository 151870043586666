import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import { StoreList, StoreListSearchCriteria, StoreLockSaleResponse, StoreViewResponse } from '@shared/models';
import { BaseState } from '@shared/store/state/base.state';

import { StoreActionType, StoreActions } from './store.actions';
import { StoreModuleStates } from './store.states';

export const storeModuleReducers: ActionReducerMap<Partial<StoreModuleStates>> = {
  store: storeReducers
};

export interface StoreState extends EntityState<StoreList>, BaseState {
  selected: StoreViewResponse;
  criteriaObject: StoreListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  saleLock: StoreLockSaleResponse;
}

export const adapter: EntityAdapter<StoreList> = createEntityAdapter<StoreList>();

export const initialStoreResponseState: StoreState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  saleLock: null
});

export function storeReducers(state = initialStoreResponseState, action: StoreActions): StoreState {
  switch (action.type) {
    case StoreActionType.STORE_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StoreActionType.STORE_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StoreActionType.STORE_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload.storeView
      };
    case StoreActionType.STORE_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case StoreActionType.STORE_POS_SUBMIT_REQUEST:
      return {
        ...state,
        selected: {
          ...state.selected,
          devices: [action.payload]
        }
      };
    case StoreActionType.STORE_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };

    case StoreActionType.LOCK_SALE_RESPONSE:
      return {
        ...state,
        saleLock: action.payload
      };
    case StoreActionType.LOCK_SALE_RESET:
      return {
        ...state,
        saleLock: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
