<ng-container *ngIf="isShelfInformationUpload()">
  <span class="image-upload-wrapper">
    <div *ngIf="isShelfInformationUpload() && fileList && fileList.length > 0; else noImage">
      <div class="upload-image-preview-container" *ngFor="let file of fileList">
        <div class="upload-image-display" (click)="onShowImage()">
          <span *ngIf="hasChange" class="badge badge-warning new badge-position">Update</span>
          <img
            *ngIf="file.name && file.isImg"
            [src]="file.filePath"
            class="upload-image-preview"
            alt="upload image"
          /><br />
        </div>
        <em *ngIf="file.name && !disabled" class="icon-close-mini" (click)="onClickDelete()"></em>
      </div>
    </div>

    <ng-template #noImage>
      <div class="upload-image-container text-center" [ngClass]="{ disabled: disabled }">
        <input
          id="uploadImageFile"
          #uploadInput
          type="file"
          class="upload-input"
          [disabled]="disabled"
          [multiple]="multiple"
        />
        <div class="d-flex flex-column upload-inner" (click)="uploadInput.click()">
          <em class="icon-plus"></em>
          <label for="uploadFile" class="upload-label">Add Image</label>
        </div>
      </div>
    </ng-template>

    <div class="image-description">
      {{ descriptionTxt }}
    </div>
  </span>
</ng-container>

<ng-container class="upload-wrapper" *ngIf="!isShelfInformationUpload()">
  <span class="upload-wrapper">
    <div *ngIf="fileList && fileList.length > 0; else noFiles">
      <div class="position-relative image-container" *ngFor="let file of fileList">
        <input
          class="form-control cursor-default"
          [ngClass]="{ enabled: !disabled, disabled: disabled, 'bg-h-change': hasChange }"
          readonly
        />
        <img
          *ngIf="file.name && file.isImg && !isHiddenFileName"
          appSrc
          [src]="file.filePath"
          class="upload-preview"
          alt="upload image"
        />
        <a
          class="input-upload-display d-block"
          target="_blank"
          [href]="file.filePath"
          [ngClass]="{
            'link-file': !file.isImg,
            'link-image': file.isImg
          }"
          *ngIf="!isHiddenFileName; else hiddenFile"
        >
          <span>{{ file.name }}</span>
        </a>

        <ng-template #hiddenFile>
          <span class="input-upload-hidden link-file">Attached</span>
        </ng-template>

        <em
          *ngIf="file.name && !disabled && !isHiddenFileName"
          class="icon-close-mini"
          (click)="onClickDelete()"
        ></em>
      </div>
    </div>

    <div *ngIf="hasError" class="invalid-feedback">
      <div *ngIf="hasError.required">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>

    <div class="input-label" *ngIf="!isAddable || lastIndex">
      <div *ngIf="descriptionTxt" class="image-description">{{ descriptionTxt }}</div>
      <div>
        <button
          type="button"
          id="add-image"
          *ngIf="!disabled && isAddable && maxImages > index + 1"
          class="btn btn-secondary mt-2"
          (click)="onAddImage()"
        >
          <em class="icon-plus"></em>
          Add More
        </button>
      </div>
    </div>

    <ng-template #noFiles>
      <div
        class="upload-container text-center form-control"
        [ngClass]="{ disabled: disabled, 'is-invalid': hasError }"
      >
        <input
          id="uploadFile"
          class="d-none"
          #uploadInput
          type="file"
          [disabled]="disabled"
          [multiple]="multiple || false"
        />
        <div class="input-group position-relative">
          <input
            placeholder="No File Chosen"
            class="form-control upload-input cursor-default"
            [disabled]="disabled"
            (click)="uploadInput.click()"
            readonly
            [ngClass]="{
              'is-invalid': hasError,
              'bg-h-change': hasChange,
              enabled: !disabled,
              disabled: disabled
            }"
          />
          <div class="input-group-append" *ngIf="!disabled">
            <button
              type="button"
              class="btn upload-btn"
              [disabled]="disabled"
              (click)="uploadInput.click()"
              [ngClass]="{
                'is-invalid': hasError
              }"
            >
              <em class="icon-upload"></em>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </span>
</ng-container>
