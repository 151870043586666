import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import {
  TdAssortmentFreeItemListPagination,
  TdAssortmentFreeSearchCriteria,
  TdAssortmentListPagination,
  TdAssortmentSearchCriteria
} from '../models/purchase-request.model';
import { BaseService } from './base.service';

@Injectable()
export class TdAssortmentService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.purchaseRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: TdAssortmentSearchCriteria): Observable<TdAssortmentListPagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: this.envService.purchaseProductPrice,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria, true);
    const url = `${this.env.serverUrl}${this.envService.purchaseProductPrice}`;

    return this.http.get<TdAssortmentListPagination>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public searchFreeItemByCriteria(
    criteria: TdAssortmentFreeSearchCriteria
  ): Observable<TdAssortmentFreeItemListPagination> {
    const params = this.getParams(criteria, true);
    const url = `${this.env.serverUrl}${this.envService.purchaseProductFreePrice}`;

    return this.http.get<TdAssortmentFreeItemListPagination>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public exportTdAssortment(criteria: TdAssortmentSearchCriteria): Observable<any> {
    const params = this.getParams(criteria, true);
    const url = `${this.env.serverUrl}${this.envService.purchaseProductPrice}${this.envService.exportPr}`;

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.TD_ASSORTMENT,
          route: `${this.envService.purchaseProductPrice}${this.envService.exportPr}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public getUrlImport() {
    return this.getFullUrl(this.envService.import);
  }

  // public uploadPurchaseRequestFiles(files: Array<File>, url: string): Array<Observable<UploadResultType>> {
  //   return files.map(file => this.uploadPurchaseRequestFile(file, url));
  // }

  public uploadPurchaseRequestFile(file: File, url: string, metrisc?: Metrics): Observable<any> {
    const formData = new FormData();

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      observe: 'body',
      headers: this.loaderHeaders(0, metrisc)
    });
  }
}
