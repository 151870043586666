export enum RewardCatalogPage {
  REWARD_CATALOG_REQUEST = 'Reward Catalog Request',
  REWARD_CATALOG = 'Reward Catalog'
}

export enum RewardCatalogRequestStatusEnum {
  APPROVED = 'A',
  DRAFT = 'D',
  REJECTED = 'R',
  AWAITING_APPROVAL = 'W',
  CANCELLED = 'C'
}

export enum RewardCatalogStatusEnum {
  CANCELLED = 'C',
  AWAITING_SCHEDULE = 'AS',
  ACTIVE = 'A',
  EXPIRED = 'E'
}
