import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ChangeStatusUserRequest,
  ExportUserReportCriteria,
  UserDto,
  UserListResponse,
  UserSearchCriteria,
  UserViewResponse
} from '../models';
import { FileResponse } from '../models/fileResponse';
import { ChangePasswordRequest } from '../models/force-change-password.model';
import { ForgotUsername } from '../models/forgot-username.model';
import { MetricAction, MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { Progress } from '../models/progress.model';
import { selectUserInfoResult } from '../store/selectors/auth-user-info.selector';
import { AppStates } from '../store/state/app.states';
import { BaseService } from './base.service';

type UploadResultType = Progress | FileResponse | null | any;

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {
  public headers: HttpHeaders;
  private userName: string;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: NGXLogger,
    private readonly store: Store<AppStates>
  ) {
    super();
    this.envService = this.env.services.users;
    this.headers = new HttpHeaders(this.envService.headers);
    this.store.select(selectUserInfoResult).subscribe(val => {
      if (val) {
        this.userName = val.userName;
      }
    });
  }

  public uploadUserFiles(files: Array<File>): Array<Observable<UploadResultType>> {
    return files.map(file => this.uploadUserFile(file));
  }

  public uploadUserFile(file: File): Observable<UploadResultType> {
    const url = this.getFullUrl(this.envService.uploadUserFileAppend, { userName: this.userName });
    const formData = new FormData();

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.headers
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return event.body;
            default:
              const msg = `Unhandled event: ${HttpEventType[event.type]}`;
              this.logger.info(msg);
              return null;
          }
        })
      );
  }

  public resetPassword(userName: string): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.env.services.users.resetPassword, { userName });
    return this.http.post<any>(url, null, { headers: this.headers, observe: 'response' });
  }

  public resetPinCode(userName: string): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.env.services.users.resetPinCode, {
      userName
    });
    return this.http.post<any>(url, null, { headers: this.headers, observe: 'response' });
  }

  public forgotUsername<T>(mobileNumber: ForgotUsername, accessToken: string): Observable<HttpResponse<T>> {
    const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
    const url = this.getFullUrl(this.env.services.users.forgotUsername, {
      mobileNumber: mobileNumber.mobileNumber
    });
    return this.http.post<T>(url, null, { headers, observe: 'response' });
  }

  public forgotPasswordCreateOTP<T>(userName: string, accessToken: string): Observable<HttpResponse<T>> {
    const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
    const url = this.getFullUrl(this.envService.createOTP, { userName });

    return this.http.post<T>(url, null, { headers, observe: 'response' });
  }

  public forgotPasswordVerifyOTP<T>(userName: string, otp: string, accessToken: string): Observable<HttpResponse<T>> {
    const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
    const url = this.getFullUrl(this.envService.verifyOTP, { userName });
    const data = { otp };
    return this.http.post<T>(url, data, { headers, observe: 'response' });
  }

  public forgotPassword<T>(
    userName: string,
    newPassword: string,
    otp: string,
    accessToken: string
  ): Observable<HttpResponse<T>> {
    const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
    const url = this.getFullUrl(this.envService.forgotPassword, { userName });
    const data = { newPassword, otp };
    return this.http.post<T>(url, data, { headers, observe: 'response' });
  }

  searchByCriteriaRequest(criteria: UserSearchCriteria): Observable<UserListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.USER,
          route: this.envService.list,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getFullUrl(this.envService.list);
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public crateUser(data: UserDto) {
    const url = this.getUrl();
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.USER,
          path: window.location.pathname
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.USER,
          action: MetricAction.USER_CREATE,
          type: 'CREATE',
          items: 1
        }
      ]
    };

    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(3000, metricsReq),
      observe: 'body'
    });
  }

  public updateUser(data: UserDto) {
    const url = this.getUrl();
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.USER,
          path: window.location.pathname
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.USER,
          action: MetricAction.USER_EDIT,
          type: 'EDIT',
          items: 1
        }
      ]
    };

    return this.http.put<any>(url, data, {
      headers: this.loaderHeaders(3000, metricsReq),
      observe: 'body'
    });
  }

  public getUserByNo(userNo: string) {
    const url = this.getFullUrl(this.envService.get, { no: userNo });
    return this.http.get<UserViewResponse>(url, { headers: this.headers, observe: 'body' });
  }

  public changeStatusUserByUserName(user: ChangeStatusUserRequest) {
    const url = this.getFullUrl(this.envService.activateUser, { userName: user.userName, status: `${user.status}` });
    return this.http.put<any>(url, {}, { headers: this.headers, observe: 'response' });
  }

  public changePassword<T>(request: ChangePasswordRequest): Observable<HttpResponse<T>> {
    const url = this.getFullUrl(this.envService.forceChangePassword, {
      userName: request.userName
    });

    return this.http.post<T>(
      url,
      { newPassword: request.newPassword },
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  public exportUserReport(criteria: ExportUserReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportUserReport);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.USER,
          path: `${this.envService.url}${this.envService.exportUserReport}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.USER,
          route: `${this.envService.url}${this.envService.exportUserReport}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
