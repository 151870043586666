import { createSelector } from '@ngrx/store';

import { AppStates } from '@shared/store/state/app.states';

import * as fromRoleListState from './role-list.reducers';
import { RoleState } from './role.reducers';
import * as fromUserByRoleState from './user-by-role.reducers';

const selectRoleState = (state: AppStates) => state.roles;
const selectRoleListState = (state: AppStates) => state.rolesList;
const selectUserByRoleState = (state: AppStates) => state.userByRoleList;

export const selectAllRoleList = createSelector(selectRoleListState, fromRoleListState.selectAll);

export const selectRoleList = createSelector(selectRoleListState, (state: fromRoleListState.RoleListState) => state);

export const selectRoleListCriteria = createSelector(selectRoleListState, state => {
  return state.criteriaObject;
});

export const selectRoleMasterList = createSelector(selectRoleState, (state: RoleState) => state.permissions);

export const selectRoleErrorResponse = createSelector(selectRoleState, state => {
  return state.roleErrorResponse;
});

export const selectRoleAllList = createSelector(selectRoleState, (state: RoleState) => state.roleList);

export const selectRoleById = createSelector(selectRoleState, (state: RoleState) => state.selected);

export const selectAlUserByRoleList = createSelector(selectUserByRoleState, fromUserByRoleState.selectAll);

export const selectUserByRoleList = createSelector(
  selectUserByRoleState,
  (state: fromUserByRoleState.UserByRoleState) => state
);

export const selectUserByRoleListCriteria = createSelector(selectUserByRoleState, state => {
  return state.criteriaObject;
});
