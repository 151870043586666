import { Action } from '@ngrx/store';

import { ErrorResponse } from '../../models';
import { Customer360Pagination, Customer360SearchCriteria } from '../../models/customer360.model';
import {
  DataInsightPreviewDataRequest,
  DataInsightPreviewDataResponse,
  DataInsightSaveTemplateData,
  DataInsightViewResponse
} from '../../models/data-insight.model';

export enum Customer360ActionTypes {
  CUSTOMER360_LIST_REQUEST = '[Customer360] List Request',
  CUSTOMER360_LIST_RESPONSE = '[Customer360] List Response',

  CUSTOMER360_PREVIEW_REQUEST = '[Customer360] Preview Request',
  CUSTOMER360_PREVIEW_RESPONSE = '[Customer360] Preview Response',
  CUSTOMER360_PREVIEW_RESET = '[Customer360] Preview Reset',

  CUSTOMER360_SAVE_NEW_TEMPLATE_REQUEST = '[Customer360] Save New Template Request',
  CUSTOMER360_SAVE_NEW_TEMPLATE_SUCCESS = '[Customer360] Save New Template Success',
  CUSTOMER360_SAVE_NEW_TEMPLATE_ERROR = '[Customer360] Save New Template Error',
  CUSTOMER360_SAVE_NEW_TEMPLATE_RESET = '[Customer360] Save New Template Reset',

  CUSTOMER360_GET_TEMPLATE_BY_ID_REQUEST = '[Customer360] Get Template by ID Request',
  CUSTOMER360_GET_TEMPLATE_BY_ID_RESPONSE = '[Customer360] Get Template by ID Response',
  CUSTOMER360_GET_TEMPLATE_RESPONSE_VIEW_PAGE_ERROR = '[Customer360] Get Template Response View Page Error',
  CUSTOMER360_GET_TEMPLATE_BY_ID_RESET = '[Customer360] Get Template by ID Reset'
}

export class Customer360ListRequestAction implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_LIST_REQUEST;
  constructor(public payload: Customer360SearchCriteria) {}
}
export class Customer360ListResponseAction implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_LIST_RESPONSE;
  constructor(public payload: Customer360Pagination) {}
}

export class Customer360PreviewRequest implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_PREVIEW_REQUEST;
  constructor(public payload: DataInsightPreviewDataRequest) {}
}

export class Customer360PreviewResponse implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_PREVIEW_RESPONSE;
  constructor(public payload: DataInsightPreviewDataResponse) {}
}

export class Customer360PreviewReset implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_PREVIEW_RESET;
  constructor() {}
}

export class Customer360SaveNewTemplateRequestAction implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_SAVE_NEW_TEMPLATE_REQUEST;
  constructor(public data: DataInsightSaveTemplateData) {}
}

export class Customer360SaveNewTemplateSuccessAction implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_SAVE_NEW_TEMPLATE_SUCCESS;
  constructor(public payload: boolean) {}
}

export class Customer360SaveNewTemplateErrorAction implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_SAVE_NEW_TEMPLATE_ERROR;
  constructor(public payload: ErrorResponse) {}
}

export class Customer360SaveNewTemplateResetAction implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_SAVE_NEW_TEMPLATE_RESET;
  constructor() {}
}

export class Customer360GetTemplateByIDRequestAction implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_GET_TEMPLATE_BY_ID_REQUEST;

  constructor(public payload: { id: string; isViewPage?: boolean }) {}
}

export class Customer360GetTemplateByIDResponseAction implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_GET_TEMPLATE_BY_ID_RESPONSE;

  constructor(public payload: DataInsightViewResponse) {}
}

export class Customer360TemplateResponseViewPageErrorAction implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_GET_TEMPLATE_RESPONSE_VIEW_PAGE_ERROR;

  constructor() {}
}

export class Customer360GetTemplateByIDResetAction implements Action {
  readonly type = Customer360ActionTypes.CUSTOMER360_GET_TEMPLATE_BY_ID_RESET;

  constructor() {}
}

export type Customer360Actions =
  | Customer360ListRequestAction
  | Customer360ListResponseAction
  | Customer360PreviewRequest
  | Customer360PreviewResponse
  | Customer360PreviewReset
  | Customer360SaveNewTemplateRequestAction
  | Customer360SaveNewTemplateErrorAction
  | Customer360SaveNewTemplateResetAction
  | Customer360GetTemplateByIDRequestAction
  | Customer360GetTemplateByIDResponseAction
  | Customer360SaveNewTemplateSuccessAction
  | Customer360TemplateResponseViewPageErrorAction
  | Customer360GetTemplateByIDResetAction;
