import { Action } from '@ngrx/store';

import { StoresPagingListResponse, StoresRequestSearchCriteria } from '../../models/stores.model';

export enum StoresActionTypes {
  STORES_PAGING_LIST_REQUEST = '[Stores] Stores List request',
  STORES_PAGING_LIST_RESPONSE = '[Stores] Stores List response',
  REMOVE_ALL_SELECT_STORES_LIST = '[Stores] Remove All Select Stores List',
  REMOVE_ALL_SELECT_STORE_LIST = '[Stores] Remove All Select Store List',
  RESET_STORE_LIST = '[Stores] Reset All Store List'
}

export class StoresListRequest implements Action {
  readonly type = StoresActionTypes.STORES_PAGING_LIST_REQUEST;
  constructor(public payload: StoresRequestSearchCriteria) {}
}

export class StoresListResponse implements Action {
  readonly type = StoresActionTypes.STORES_PAGING_LIST_RESPONSE;
  constructor(public payload: StoresPagingListResponse) {}
}
export class RemoveAllSelectStoresListAction implements Action {
  readonly type = StoresActionTypes.REMOVE_ALL_SELECT_STORES_LIST;
  constructor() {}
}
export class RemoveAllSelectStoreListAction implements Action {
  readonly type = StoresActionTypes.REMOVE_ALL_SELECT_STORE_LIST;
  constructor() {}
}

export class ResetStoresListAction implements Action {
  readonly type = StoresActionTypes.RESET_STORE_LIST;
  constructor() {}
}

export type StoresActions =
  | StoresListRequest
  | StoresListResponse
  | RemoveAllSelectStoresListAction
  | ResetStoresListAction
  | RemoveAllSelectStoreListAction;
