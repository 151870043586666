import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { RequestSectionEnum } from '@shared/enum/request-section.enum';
import { RequestStatusEnum } from '@shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '@shared/enum/request-step.enum';
import { TDStorePage } from '@shared/enum/td-store-page.enum';
import { StoreProfile } from '@shared/models';
import { AppStates } from '@shared/store/state/app.states';
import { getSelectByPage } from '@shared/utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '@shared/utils/td-store-workflow-util';

const MAX_STORE_CONTRACT = 3;
@Component({
  selector: 'app-store-contact',
  templateUrl: './store-contact.component.html',
  styleUrls: ['./store-contact.component.scss']
})
export class StoreContactComponent extends OnDestroyMixin implements OnInit {
  @Input() parentForm: UntypedFormGroup = new FormGroup({});
  @Input() set mode(value: RequestPageModesEnum) {
    this._mode = value;
  }

  @Input() page: TDStorePage;
  @Input() submitted: boolean;
  @Input() listOfChange: any;
  type: RequestTypeEnum;
  step: RequestStepEnum;
  status: RequestStatusEnum;
  isViewMode = true;
  storeContactView$: any;
  localStore: Observable<any>;

  contactPhoneDisplay: string[] = [];

  onPatchValueFinish$ = new BehaviorSubject<boolean>(null);
  onEnableOrDisable$ = new BehaviorSubject<{ next: boolean; enable: boolean }>({ next: null, enable: null });

  get mode(): RequestPageModesEnum {
    return this._mode;
  }

  private _mode: RequestPageModesEnum;

  constructor(private store: Store<AppStates>, private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil) {
    super();
  }

  ngOnInit(): void {
    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.initialStoreContactForm();
    this.onToggleEditEffect();
  }

  onToggleEditEffect() {
    combineLatest([this.onPatchValueFinish$, this.onEnableOrDisable$])
      .pipe(
        filter(([isPatched, isNextFlow]) => isPatched && isNextFlow.next),
        untilComponentDestroyed(this)
      )
      .subscribe(([_, isNextFlow]) => {
        if (isNextFlow.enable) {
          this.storeContact.enable();
        } else {
          this.storeContact.disable();
        }
      });
  }

  initialStoreContactForm() {
    this.parentForm.addControl('storeContact', new FormArray([]));
    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.mode)) {
      this.setStoreContactValue();
    } else if ([RequestPageModesEnum.REQUEST_CREATE].includes(this.mode)) {
      this.storeContact.push(this.storeContactForm());
      this.isViewMode = false;
    }
  }

  setStoreContactValue() {
    this.storeContactView$ = this.localStore.pipe(
      select(getSelectByPage(this.page)),
      filter(data => data !== null)
    );

    this.storeContactView$
      .pipe(
        filter(value => value !== null),
        take(1)
      )
      .subscribe(value => {
        this.type = value.type || RequestTypeEnum.EDIT;
        this.step = value.step || RequestStepEnum.EDIT_PROFILE;
        this.status = value.status || RequestStatusEnum.DRAFT;

        this.patchFormValue(value);

        this.setStoreContactCtrl(this.type, this.page, this.step, this.mode);
      });
  }

  patchFormValue(response) {
    this.type = response.type || RequestTypeEnum.EDIT;
    this.step = response.step || RequestStepEnum.EDIT_PROFILE;
    this.status = response.status || RequestStatusEnum.DRAFT;

    if (response.merchantInfo?.storeProfile?.length > 0) {
      const storeProfile: StoreProfile = response.merchantInfo?.storeProfile[0];
      if (storeProfile.contactName && storeProfile.phone) {
        this.storeContact.push(
          this.storeContactForm(
            storeProfile.contactName,
            storeProfile.countryCode && storeProfile.phone ? storeProfile.countryCode + storeProfile.phone : null
          )
        );
        this.contactPhoneDisplay.push(storeProfile.phone);
      } else {
        this.storeContact.push(this.storeContactForm());
      }

      if (storeProfile.contactName2 && storeProfile.phone2) {
        this.storeContact.push(
          this.storeContactForm(
            storeProfile.contactName2,
            storeProfile.countryCode2 && storeProfile.phone2 ? storeProfile.countryCode2 + storeProfile.phone2 : null
          )
        );
        this.contactPhoneDisplay.push(storeProfile.phone2);
      }
      if (storeProfile.contactName3 && storeProfile.phone3) {
        this.storeContact.push(
          this.storeContactForm(
            storeProfile.contactName3,
            storeProfile.countryCode3 && storeProfile.phone3 ? storeProfile.countryCode3 + storeProfile.phone3 : null
          )
        );
        this.contactPhoneDisplay.push(storeProfile.phone3);
      }
    }
    this.storeContact.disable();

    this.onPatchValueFinish$.next(true);
  }

  toggleEditStoreContact() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;
    this.setStoreContactCtrl(this.type, this.page, this.step, this.mode);
  }

  setStoreContactCtrl(
    localType: RequestTypeEnum,
    localPage: TDStorePage,
    localStep: RequestStepEnum,
    localMode: RequestPageModesEnum
  ) {
    const editSection = RequestSectionEnum.STORE_CONTACT;

    const canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(localType, localPage, localStep, editSection);
    if (localMode === RequestPageModesEnum.REQUEST_EDIT && canEditByWorkflow) {
      this.onEnableOrDisable$.next({ next: true, enable: true });
      this.isViewMode = false;
    } else {
      this.onEnableOrDisable$.next({ next: true, enable: false });
    }
  }

  onAddContact() {
    if (this.storeContact.length < MAX_STORE_CONTRACT) {
      this.storeContact.push(this.storeContactForm());
    }
  }

  storeContactForm(contactName = '', phone = '') {
    return new FormGroup({
      contactName: new FormControl(contactName, Validators.required),
      phone: new FormControl(phone, Validators.required)
    });
  }

  get storeContact() {
    return this.parentForm.get('storeContact') as FormArray;
  }

  onDeleteContact(index: number) {
    this.storeContact.removeAt(index);
  }

  isRequestTypeEdit(requestType: RequestTypeEnum) {
    return requestType === RequestTypeEnum.EDIT;
  }
}
