import { ExportFileTypeEnum } from '../enum/export-file-type.enum';

export const b64toBlob = (dataURI, typeFile = ExportFileTypeEnum.EXCEL_XLSX) => {
  const byteString = atob(dataURI);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    /* tslint:disable:no-bitwise */
    ia[i] = byteString.charCodeAt(i) & 0xff;
  }
  return new Blob([ab], { type: typeFile });
};
