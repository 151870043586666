import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ClientIdTypeEnum } from '@shared/enum/client-id.enum';
import { LogInFailureAction } from '@shared/store/actions/auth.actions';
import { HideAllModalAction } from '@shared/store/actions/hide-modal.action';
import { ClientIdAction } from '@shared/store/actions/user-info.action';
import { CookiesUtil } from '@shared/utils/cookies-util';
import { hideAllModal } from '@shared/utils/hide-modal-util';

import { environment as env, environment } from '../../../environments/environment';
import { LayoutActionErrorUpdate } from '../store/actions/layout.action';
import { AppStates } from '../store/state/app.states';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store<AppStates>,
    protected readonly logger: NGXLogger,
    protected cookieService: CookieService,
    protected cookiesUtil: CookiesUtil = new CookiesUtil(cookieService),
    protected readonly modalService: BsModalService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry(1),
      catchError(err => this.handleError(err, request))
    );
  }

  handleError(err: any, request): Observable<any> {
    if (err.error) {
      let obs: Observable<any>;

      if (err.error instanceof Blob && (err.status === 400 || err.status === 404)) {
        // Parser Blob error case
        const reader: FileReader = new FileReader();

        obs = new Observable((observer: any) => {
          reader.onloadend = e => {
            if (typeof e.target['result'] === 'string') {
              err.error = JSON.parse(e.target['result']); // srcElement is deprecated then we use target
            }

            observer.error(err);
            observer.complete();
          };
        });

        reader.readAsText(err.error);
      } else {
        // Parser general error case
        const isApiError =
          err.status === 500 ||
          (err.status === 503 &&
            !request.url.includes(environment.services.deliveryOrder.importPicking) &&
            !request.url.includes(environment.services.auth.url) &&
            !request.url.includes(environment.services.exporter.metrics) &&
            !request.url.includes(environment.services.exporter.notify));

        if (isApiError) {
          this.store.dispatch(new LayoutActionErrorUpdate(true));
        }

        if (typeof err.error === 'string') {
          err.error = JSON.parse(err.error);
        }

        err.error.translateKey = `ERROR_CODE.${err.error.code}`;

        obs = new Observable((observer: any) => {
          observer.error(err);
          observer.complete();
        });
      }

      return obs;
    } else if (err.status === 403) {
      this.forbidden(err);
    }

    return throwError(err);
  }

  forbidden(err: any) {
    this.logger.info(err);
    this.deleteTokenCookie();
    hideAllModal(this.modalService);

    this.store.dispatch(new HideAllModalAction(true));
    this.store.dispatch(new ClientIdAction(env.defaultClientId as ClientIdTypeEnum));
    this.store.dispatch(new LayoutActionErrorUpdate(false));
    this.store.dispatch(new LogInFailureAction(err));
  }

  deleteTokenCookie() {
    const accessTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.accessToken);
    const refreshTokenTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.refreshToken);

    Array.isArray(accessTokenCookiesName)
      ? accessTokenCookiesName.map(value => {
          this.cookieService.delete(value);
        })
      : this.cookieService.delete(accessTokenCookiesName);

    Array.isArray(refreshTokenTokenCookiesName)
      ? refreshTokenTokenCookiesName.map(value => {
          this.cookieService.delete(value);
        })
      : this.cookieService.delete(refreshTokenTokenCookiesName);

    this.cookieService.delete(`${env.cookies.accessToken}_amount`);
    this.cookieService.delete(`${env.cookies.refreshToken}_amount`);
  }
}
