import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-simple-info-list-modal',
  templateUrl: './simple-info-list-modal.component.html',
  styleUrls: ['./simple-info-list-modal.component.scss']
})
export class SimpleInfoListModalComponent {
  public title: string;
  public data: any[];
  public columns: SimpleListColumn[];

  constructor(public bsModalRef: BsModalRef) {}

  hide(): void {
    this.bsModalRef.hide();
  }
}

export class SimpleListColumn {
  field: string;
  width?: number;
  align?: string;
}
