import { Action } from '@ngrx/store';

import { ErrorResponse } from '../../models';
import { SubmitWithComment } from '../../models/base-modal.component.model';
import {
  TaskAssignmentListResponse,
  TaskAssignmentListSearchCriteria,
  TaskAssignmentResponse
} from '../../models/task-assignment.model';

export enum TaskAssignmentActionType {
  TASK_ASSIGNMENT_LIST_REQUEST = '[TaskAssignment] Task List Request',
  TASK_ASSIGNMENT_LIST_RESPONSE = '[TaskAssignment] Task  List Response',
  TASK_ASSIGNMENT_LIST_ERROR = '[TaskAssignment] Task  List Error',

  TASK_ASSIGNMENT_GET_BY_ID_REQUEST = '[TaskAssignment] Get Task by Id Request',
  TASK_ASSIGNMENT_GET_BY_ID_RESPONSE = '[TaskAssignment] Get Task by Id Response',
  TASK_ASSIGNMENT_GET_BY_ID_ERROR = '[TaskAssignment] Get Task by Id Error',
  TASK_ASSIGNMENT_GET_BY_ID_RESET = '[TaskAssignment] Get Task by Id Reset',

  TASK_CANCEL_REQUESTED = '[TaskAssignment] Cancel Requested',
  TASK_CANCEL_SUCCESS = '[TaskAssignment] Cancel Success'
}

export class TaskAssignmentListRequestAction implements Action {
  readonly type = TaskAssignmentActionType.TASK_ASSIGNMENT_LIST_REQUEST;

  constructor(public payload: TaskAssignmentListSearchCriteria) {}
}

export class TaskAssignmentListResponseAction implements Action {
  readonly type = TaskAssignmentActionType.TASK_ASSIGNMENT_LIST_RESPONSE;

  constructor(public payload: TaskAssignmentListResponse) {}
}

export class TaskAssignmentListErrorAction implements Action {
  readonly type = TaskAssignmentActionType.TASK_ASSIGNMENT_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class TaskAssignmentByIdRequestAction implements Action {
  readonly type = TaskAssignmentActionType.TASK_ASSIGNMENT_GET_BY_ID_REQUEST;

  constructor(public payload: { taskNo: string }) {}
}

export class TaskAssignmentByIdResponseAction implements Action {
  readonly type = TaskAssignmentActionType.TASK_ASSIGNMENT_GET_BY_ID_RESPONSE;

  constructor(public payload: TaskAssignmentResponse) {}
}

export class TaskAssignmentByIdErrorAction implements Action {
  readonly type = TaskAssignmentActionType.TASK_ASSIGNMENT_GET_BY_ID_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class TaskAssignmentByIdResetAction implements Action {
  readonly type = TaskAssignmentActionType.TASK_ASSIGNMENT_GET_BY_ID_RESET;
}

export class TaskAssignmentCancelRequest implements Action {
  readonly type = TaskAssignmentActionType.TASK_CANCEL_REQUESTED;
  constructor(public payload: SubmitWithComment) {}
}

export class TaskAssignmentCancelSuccess implements Action {
  readonly type = TaskAssignmentActionType.TASK_CANCEL_SUCCESS;
  constructor(public payload: { isSuccess: boolean }) {}
}

export type TaskAssignmentActions =
  | TaskAssignmentListRequestAction
  | TaskAssignmentListResponseAction
  | TaskAssignmentListErrorAction
  | TaskAssignmentByIdRequestAction
  | TaskAssignmentByIdResponseAction
  | TaskAssignmentByIdErrorAction
  | TaskAssignmentByIdResetAction
  | TaskAssignmentCancelRequest
  | TaskAssignmentCancelSuccess;
