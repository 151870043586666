import { BaseReport } from '@shared/models';

export class StoreAssortmentRequestStoreListSearchCriteria {
  keyword?: string;
  searchCriteria?: string;
  storeType?: string;
  region?: string;
  province?: string;
  poiType?: string;
  page: number;
  size: number;
}

export class StoreAssortmentRequestStoreListResponse {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: StoreAssortmentRequestStoreContent[];
}

export class StoreAssortmentRequestStoreContent {
  storeNo: string;
  storeCode: string;
  storeName: string;
  storeType: string;
  region: string;
  province: string;
  poiType: string;
}

export class ExportStoreAssortmentByProductCriteria {
  barcode: string;
  articleNo: string;
  fileType: string;
}

export class ExportStoreAssortmentByStoreCriteria extends BaseReport {
  storeNo: string[];
  fileType: string;

  constructor(init?: Partial<ExportStoreAssortmentByStoreCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class StoreAssortmentRequestUnselected {
  storeNo: string;
  currentPage: { [key: number]: boolean };
}
