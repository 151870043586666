import { Action } from '@ngrx/store';

import { shelfTypeStatusEnum } from '../../enum/request-status.enum';
import { ErrorResponse, ShelfItems } from '../../models';
import {
  ApproveOrderRequest,
  DeliveryDetails,
  OrderCreateRequest,
  OrderId,
  OrderRequestListResponse,
  OrderRequestListSearchCriteria,
  OrderRequestNo,
  OrderRequestViewResponse
} from '../../models/order-request.model';

export enum OrderRequestActionType {
  ORDER_REQUEST_LIST_REQUEST = '[OrderRequest] Order Request List Request',
  ORDER_REQUEST_LIST_RESPONSE = '[OrderRequest] Order Request List Response',
  ORDER_REQUEST_HISTORY_REQUEST = '[OrderRequest] Request History Request',
  ORDER_REQUEST_HISTORY_RESPONSE = '[OrderRequest] Request History Response',
  ORDER_REQUEST_GET_BY_STORE_RESPONSE = '[OrderRequest] Get Order by Store Response',
  ORDER_REQUEST_GET_BY_ID_REQUEST = '[OrderRequest] Get Order by Id Request',
  ORDER_REQUEST_GET_BY_ID_RESPONSE = '[OrderRequest] Get Order by Id Response',
  ORDER_REQUEST_GET_BY_REF_NO_REQUEST = '[OrderRequest] Get Order by Ref No Request',
  ORDER_REQUEST_GET_BY_REF_NO_RESPONSE = '[OrderRequest] Get Order by Ref No Response',
  ORDER_REQUEST_GET_BY_ID_RESET = '[OrderRequest] Get Order by Id Reset',
  ORDER_CREATE_SAVE_REQUEST = '[OrderRequest] Create Order Save Request',
  ORDER_CREATE_SAVE_RESPONSE = '[OrderRequest] Create Order Save Response',
  ORDER_CREATE_SUBMIT_REQUEST = '[OrderRequest] Create Order Submit Request',
  ORDER_CREATE_SUBMIT_RESPONSE = '[OrderRequest] Create Order Submit Response',
  ORDER_CREATE_SUBMIT_ERROR = '[OrderRequest] Create Order Submit Error',
  ORDER_CREATE_RESET = '[OrderRequest] Create Order Reset',
  ORDER_REQUEST_APPROVE_REQUEST = '[OrderRequest] Request Approve Order Request',
  ORDER_REQUEST_APPROVE_RESPONSE = '[OrderRequest] Request Approve Order Response',
  ORDER_REQUEST_SHELF_SELECTION_REQUEST = '[OrderRequest] Shelf selection Request',
  ORDER_REQUEST_SHELF_SELECTION_RESPONSE = '[OrderRequest] Shelf selection Response',
  ORDER_REQUEST_SHELF_SELECTION_RESET = '[OrderRequest] Shelf selection Reset',
  ORDER_REQUEST_GENERATE_SHELF_ITEM_REQUEST = '[OrderRequest] Generate Shelf items Request',
  ORDER_REQUEST_SAVE_DELIVERY_DETAILS = '[OrderRequest] Save Delivery Details'
}

export class OrderRequestListRequestAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_LIST_REQUEST;

  constructor(public payload: OrderRequestListSearchCriteria) {}
}

export class OrderRequestListResponseAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_LIST_RESPONSE;

  constructor(public payload: OrderRequestListResponse) {}
}

export class OrderRequestByStoreResponseAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_GET_BY_STORE_RESPONSE;

  constructor(public payload: any) {}
}

export class OrderRequestByIdRequestAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: OrderId) {}
}

export class OrderRequestByIdResponseAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: OrderRequestViewResponse) {}
}

export class OrderRequestByRefNoRequestAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_GET_BY_REF_NO_REQUEST;

  constructor(public payload: OrderRequestNo) {}
}

export class OrderRequestByRefNoResponseAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_GET_BY_REF_NO_RESPONSE;

  constructor(public payload: OrderRequestViewResponse) {}
}

export class ResetOrderRequestSelected implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_GET_BY_ID_RESET;
}

export class OrderCreateSaveRequestAction implements Action {
  readonly type = OrderRequestActionType.ORDER_CREATE_SAVE_REQUEST;

  constructor(public payload: OrderCreateRequest) {}
}

export class OrderCreateSaveResponseAction implements Action {
  readonly type = OrderRequestActionType.ORDER_CREATE_SAVE_RESPONSE;

  constructor(public payload: OrderRequestViewResponse) {}
}

export class OrderCreateSubmitRequestAction implements Action {
  readonly type = OrderRequestActionType.ORDER_CREATE_SUBMIT_REQUEST;

  constructor(public payload: OrderCreateRequest) {}
}

export class OrderCreateSubmitResponseAction implements Action {
  readonly type = OrderRequestActionType.ORDER_CREATE_SUBMIT_RESPONSE;

  constructor(public payload: OrderRequestViewResponse) {}
}

export class OrderCreateSubmitResponseErrorAction implements Action {
  readonly type = OrderRequestActionType.ORDER_CREATE_SUBMIT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class OrderCreateResetAction implements Action {
  readonly type = OrderRequestActionType.ORDER_CREATE_RESET;
}

export class OrderApproveRequestAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_APPROVE_REQUEST;

  constructor(public payload: ApproveOrderRequest) {}
}

export class OrderApproveResponseAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_APPROVE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class OrderRequestHistoryRequestAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_HISTORY_REQUEST;

  constructor(public payload: OrderId) {}
}

export class OrderRequestHistoryResponseAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_HISTORY_RESPONSE;

  constructor(public payload: { auditLogs }) {}
}

export class OrderRequestShelfSelectionRequestAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_SHELF_SELECTION_REQUEST;

  constructor(public payload?: shelfTypeStatusEnum) {}
}

export class OrderRequestShelfSelectionResponseAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_SHELF_SELECTION_RESPONSE;

  constructor(public payload: ShelfItems[]) {}
}

export class OrderRequestShelfSelectionResetAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_SHELF_SELECTION_RESET;
}

export class OrderRequestGenerateShelfItemsRequestAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_GENERATE_SHELF_ITEM_REQUEST;

  constructor(public payload: { shelfNo: string[]; storeNo: string }) {}
}

export class OrderRequestSaveDeliveryDetailsAction implements Action {
  readonly type = OrderRequestActionType.ORDER_REQUEST_SAVE_DELIVERY_DETAILS;

  constructor(public payload: DeliveryDetails) {}
}

export type OrderRequestActions =
  | OrderRequestListRequestAction
  | OrderRequestListResponseAction
  | OrderRequestByIdRequestAction
  | OrderRequestByIdResponseAction
  | OrderRequestByRefNoRequestAction
  | OrderRequestByRefNoResponseAction
  | ResetOrderRequestSelected
  | OrderCreateSaveRequestAction
  | OrderCreateSaveResponseAction
  | OrderCreateSubmitRequestAction
  | OrderCreateSubmitResponseAction
  | OrderCreateSubmitResponseErrorAction
  | OrderCreateResetAction
  | OrderRequestByStoreResponseAction
  | OrderApproveRequestAction
  | OrderApproveResponseAction
  | OrderRequestHistoryRequestAction
  | OrderRequestHistoryResponseAction
  | OrderRequestShelfSelectionRequestAction
  | OrderRequestShelfSelectionResponseAction
  | OrderRequestShelfSelectionResetAction
  | OrderRequestGenerateShelfItemsRequestAction
  | OrderRequestSaveDeliveryDetailsAction;
