import { createSelector } from '@ngrx/store';

import * as fromStoreRequestResponseState from '../reducers/store-request.reducers';
import { AppStates } from '../state/app.states';

const selectStoreRequestState = (state: AppStates) => state.storeRequest;

export const selectAllStoreRequestList = createSelector(
  selectStoreRequestState,
  fromStoreRequestResponseState.selectAll
);

export const selectStoreRequestList = createSelector(
  selectStoreRequestState,
  (state: fromStoreRequestResponseState.StoreRequestState) => state
);

export const selectStoreRequestListCriteria = createSelector(selectStoreRequestState, state => {
  return state.criteriaObject;
});

export const selectStoreRequestById = createSelector(
  selectStoreRequestState,
  (state: fromStoreRequestResponseState.StoreRequestState) => state.selected
);

export const selectStoreRequestHistory = createSelector(
  selectStoreRequestState,
  (state: fromStoreRequestResponseState.StoreRequestState) => state.auditLogs
);

export const selectStoreDeleteStatus = createSelector(
  selectStoreRequestState,
  (state: fromStoreRequestResponseState.StoreRequestState) => {
    return state.isDeleteSuccess;
  }
);
