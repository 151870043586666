<ng-container *ngIf="(resultList$ | async).totalElements === 0 && hasSearchCriteria">
  <div class="no-result-container text-center" data-id="noSearchResult" id="noSearchResult">
    <div class="d-flex justify-content-center">
      <img src="/assets/images/search-not-found.svg" alt="No Search Result" />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="(resultList$ | async).totalElements === 0 && !hasSearchCriteria">
  <div class="no-result-container text-center" [attr.data-id]="'noData'" id="noData">
    <div class="d-flex justify-content-center">
      <img alt="Data not found" [src]="env.assetPath + '/no-data.svg'" />
    </div>
  </div>
</ng-container>
