import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { RequestStatusEnum } from '../../enum/request-status.enum';
import { RequestStepEnum } from '../../enum/request-step.enum';
import { SubmitSuccessEnum } from '../../enum/submit-success.enum';
import { ValidateStoreEnum } from '../../enum/validate-store.enum';
import {
  StoreCreateRequest,
  StoreRequestTemplate,
  StoreRequestViewResponse,
  UploadedFirstLotOrder,
  ValidateOrderRequest
} from '../../models';
import { MerchantService } from '../../services/merchant.service';
import { StoreRequestService } from '../../services/store-request.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  StoreApproveRequestAction,
  StoreApproveResponseAction,
  StoreCreateSaveRequestAction,
  StoreCreateSaveResponseAction,
  StoreCreateSubmitRequestAction,
  StoreCreateSubmitResponseAction,
  StoreCreateSubmitResponseError,
  StoreDeleteRequestAction,
  StoreDeleteResponseAction,
  StoreFirstLotOrderValidateSubmitRequestAction,
  StoreGetVersionRequestAction,
  StoreGetVersionResponseAction,
  StoreMerchantByIdRequestAction,
  StoreRequestActionType,
  StoreRequestByIdRequestAction,
  StoreRequestByIdResponseAction,
  StoreRequestHistoryRequestAction,
  StoreRequestHistoryResponseAction,
  StoreRequestListRequestAction,
  StoreRequestListResponseAction
} from '../actions/store-request.actions';

@Injectable()
export class StoreRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly storeRequestService: StoreRequestService,
    private readonly merchantService: MerchantService,
    private readonly logger: NGXLogger
  ) {}

  searchStoreRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreRequestListRequestAction>(StoreRequestActionType.STORE_REQUEST_LIST_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${StoreRequestActionType.STORE_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.storeRequestService.searchStoreRequestByCriteria(payload).pipe(
          map(response => {
            return new StoreRequestListResponseAction(response);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  getStoreRequestMerchantById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreMerchantByIdRequestAction>(StoreRequestActionType.STORE_MERCHANT_BY_ID_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${StoreRequestActionType.STORE_MERCHANT_BY_ID_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.merchantService.getMerchantById(payload).pipe(
          map(merchantView => {
            const storeRequestViewResponse = {
              merchantId: merchantView.id,
              ...this.parseStoreRequestResponse(merchantView)
            };

            return new StoreRequestByIdResponseAction(storeRequestViewResponse);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  getStoreRequestById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreRequestByIdRequestAction>(StoreRequestActionType.STORE_REQUEST_GET_BY_ID_REQUEST),
      tap(action =>
        this.logger.debug(
          `@Effect ${StoreRequestActionType.STORE_REQUEST_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload)
        )
      ),
      map(action => action.payload),
      switchMap(payload => {
        return this.storeRequestService.getStoreById(payload).pipe(
          switchMap(store =>
            combineLatest([
              of(store).pipe(
                tap(action =>
                  this.logger.debug(
                    `@Effect ${StoreRequestActionType.STORE_REQUEST_GET_BY_ID_RESPONSE}: ` + this.stringify(action)
                  )
                )
              ),
              this.merchantService.getMerchantById({ merchant: store.merchantNo })
            ])
          ),
          map(([store, merchant]) => {
            const storeRequestViewResponse = {
              ...this.parseStoreCreateResponse(store),
              ...this.parseStoreRequestResponse(merchant)
            };

            if (
              storeRequestViewResponse.step === RequestStepEnum.FIRST_LOT_ORDER &&
              storeRequestViewResponse.status !== RequestStatusEnum.AWAITING_RESERVATION
            ) {
              return new StoreFirstLotOrderValidateSubmitRequestAction({
                storeRequestTemplate: StoreRequestTemplate.mappingByStoreRequestViewResponse(
                  storeRequestViewResponse
                ),
                validateStore: ValidateStoreEnum.LOAD
              });
            }

            return new StoreRequestByIdResponseAction(storeRequestViewResponse);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  getStoreRequestVersionById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreGetVersionRequestAction>(StoreRequestActionType.STORE_REQUEST_GET_VERSION_REQUEST),
      tap(action =>
        this.logger.debug(
          `@Effect ${StoreRequestActionType.STORE_REQUEST_GET_VERSION_REQUEST}: ` + this.stringify(action.payload)
        )
      ),
      map(action => action.payload),
      switchMap(payload => {
        return this.storeRequestService.getStoreRequestVersionById(payload).pipe(
          tap(response => {
            this.logger.debug(
              `@Effect ${StoreRequestActionType.STORE_REQUEST_GET_VERSION_RESPONSE}: ` + this.stringify(response)
            );
          }),
          map(version => {
            return new StoreGetVersionResponseAction(version);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  storeCreateSave$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreCreateSaveRequestAction>(StoreRequestActionType.STORE_CREATE_SAVE_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${StoreRequestActionType.STORE_CREATE_SAVE_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.storeRequestService.saveRequest(payload).pipe(
          map(res => new StoreCreateSaveResponseAction(this.parseStoreCreateResponse(res))),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  storeCreateSubmit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreCreateSubmitRequestAction>(StoreRequestActionType.STORE_CREATE_SUBMIT_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${StoreRequestActionType.STORE_CREATE_SUBMIT_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.storeRequestService.submitRequest(payload).pipe(
          map(res => new StoreCreateSubmitResponseAction(this.parseStoreCreateResponse(res))),
          catchError(error => of(new StoreCreateSubmitResponseError(error.error)))
        );
      })
    );
  });

  storeApprove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreApproveRequestAction>(StoreRequestActionType.STORE_REQUEST_APPROVE_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${StoreRequestActionType.STORE_REQUEST_APPROVE_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload =>
        this.storeRequestService.approveRequest(payload).pipe(
          map(() => new StoreApproveResponseAction({ isSuccess: true })),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  firstLotOrderSubmit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreFirstLotOrderValidateSubmitRequestAction>(
        StoreRequestActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_REQUEST
      ),
      map(action => {
        this.logger.debug(
          `@Effect ${StoreRequestActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_REQUEST}:` +
            this.stringify(action.payload)
        );

        const validateOrderRequest = this.parseValidateOrderRequest(
          action.payload.storeRequestTemplate,
          action.payload.validateStore
        );

        return {
          validateOrderRequest,
          storeRequestTemplate: action.payload.storeRequestTemplate
        };
      }),
      switchMap(data =>
        combineLatest([
          of(data),
          this.merchantService.getMerchantById({ merchant: data.storeRequestTemplate.merchantNo })
        ])
      ),
      switchMap(([data, merchantViewResponse]) =>
        this.storeRequestService.validateSubmitFirstLotOrder(data.validateOrderRequest).pipe(
          tap(response => {
            this.logger.debug(
              `@Effect ${StoreRequestActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_RESPONSE}:` + this.stringify(response)
            );
          }),
          map(response => {
            const storeRequestTemplate = data.storeRequestTemplate;
            const indexStoreFirstLot = 0; // We have only one firstLotOrder in store request.

            storeRequestTemplate.firstLotPo.storeFirstLot[indexStoreFirstLot] = response.storeFirstLot;

            if (response.action === ValidateStoreEnum.SUBMIT) {
              storeRequestTemplate.firstLotPo.submitSuccess = response.pass
                ? SubmitSuccessEnum.SUCCESS
                : SubmitSuccessEnum.FAILED;
            }
            return new StoreRequestByIdResponseAction(
              StoreRequestViewResponse.mappingByStoreRequestTemplate(storeRequestTemplate, {
                merchantInfo: merchantViewResponse.merchantInfo
              } as StoreRequestViewResponse)
            );
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  storeRequestHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreRequestHistoryRequestAction>(StoreRequestActionType.STORE_REQUEST_HISTORY_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${StoreRequestActionType.STORE_REQUEST_HISTORY_REQUEST}: ` + JSON.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.storeRequestService.getHistoryLogs(payload).pipe(
          map(response => new StoreRequestHistoryResponseAction({ auditLogs: response.auditLogs })),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  storeRequestDelete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreDeleteRequestAction>(StoreRequestActionType.STORE_REQUEST_DELETE_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${StoreRequestActionType.STORE_REQUEST_DELETE_REQUEST}: ` + JSON.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload =>
        this.storeRequestService.deleteByRequestId(payload).pipe(
          map(() => new StoreDeleteResponseAction({ isSuccess: true })),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });
  id: string;
  action: ValidateStoreEnum;
  step: string;
  version: number;
  storeFirstLot: UploadedFirstLotOrder;

  private parseValidateOrderRequest(
    merchantRequestView: StoreRequestTemplate,
    validateStore: ValidateStoreEnum
  ): ValidateOrderRequest {
    return {
      id: merchantRequestView.id,
      action: validateStore,
      step: merchantRequestView.step,
      version: merchantRequestView.version,
      storeFirstLot: merchantRequestView.firstLotPo.storeFirstLot[0]
    };
  }

  private parseStoreCreateResponse(store: StoreRequestTemplate): StoreCreateRequest {
    return {
      id: store.id,
      version: store.version,
      requestNo: store.requestNo,
      type: store.type,
      status: store.status,
      step: store.step,
      merchantNo: store.merchantNo,
      merchantInfo: {
        storeProfile: [{ ...store.storeProfile }]
      },
      orderSchedule: store.orderSchedule && {
        orderScheduleList: [{ ...store.orderSchedule }]
      },
      firstLotPo: store.firstLotPo,
      updatedFields: store.updatedFields
    } as StoreCreateRequest;
  }

  private parseStoreRequestResponse(merchant): StoreRequestViewResponse {
    return {
      no: merchant.no,
      taxId: merchant.merchantInfo.merchantProfile.taxId,
      merchantName: merchant.merchantInfo.merchantProfile.merchantName,
      merchantType: merchant.merchantInfo.merchantProfile.merchantType,
      contactName: `${merchant.merchantInfo.ownerProfile.contactFirstName} ${merchant.merchantInfo.ownerProfile.contactLastName}`,
      mobilePhone: `${merchant.merchantInfo.ownerProfile.countryCode} ${merchant.merchantInfo.ownerProfile.mobilePhone}`
    } as StoreRequestViewResponse;
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
