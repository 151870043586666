<div id="full-modal-content" class="full-popup-wrap" #fullScreen>
  <div class="full-popup-head" *ngIf="title">
    <div class="full-popup-title float-left">{{ title }}</div>
    <div class="full-popup-close float-right">
      <a aria-label="Close" class="close" id="full-modal-closeBtn" (click)="close()">
        <em class="icon-close" data-id="iconClose"></em>
      </a>
    </div>

    <div class="popup-top-action d-none d-md-block" *ngIf="canPrint">
      <a aria-label="Print" (click)="onPrint()" id="full-modal-print">
        <em class="icon-print"></em>
        <span>Print</span>
      </a>
    </div>

    <div class="clearfix"></div>
  </div>
  <div class="full-popup-inner" [ngClass]="this.cssInner">
    <ng-template appChildHost></ng-template>
  </div>
</div>
