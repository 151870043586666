import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import {
  NationalityAndOccupationMasterData,
  nationalityAndOccupationMasterQuery
} from '../gql/nationality-occupation.gql';
import {
  MemberPagination,
  MemberProfileResponse,
  MemberSearchCriteria,
  PrintPointStatementCriteria,
  StoreRecordCriteria,
  StoreRecordPagination
} from '../models/member.model';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class MembershipService extends BaseService {
  public headers: HttpHeaders;

  constructor(
    private readonly http: HttpClient,
    protected readonly logger: NGXLogger,
    private readonly masterService: MasterService
  ) {
    super();
    this.envService = this.env.services.membership;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchMemberCriteria(criteria: MemberSearchCriteria): Observable<MemberPagination> {
    const url = this.getFullUrl(this.envService.member);
    const params = this.getParams(criteria, true);

    return this.http.get<MemberPagination>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getMasterNationalityAndOccupationByCode(
    nationalityCode: string,
    occupationCode: string
  ): Observable<ApolloQueryResult<NationalityAndOccupationMasterData>> {
    return this.masterService.watchQuery<NationalityAndOccupationMasterData>({
      query: nationalityAndOccupationMasterQuery,
      variables: {
        nationalityCode,
        occupationCode
      }
    });
  }

  public getViewMembersById(id: string): Observable<MemberProfileResponse> {
    const url = this.getFullUrl(this.envService.view, {
      id
    });

    return this.http.get<MemberProfileResponse>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getMembersStoreRecordList(criteria: StoreRecordCriteria): Observable<StoreRecordPagination> {
    const url = this.getFullUrl(this.envService.getMemberStore);

    const params = this.getParams(criteria, true);

    return this.http.get<StoreRecordPagination>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public printPointStatement(criteria: PrintPointStatementCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.printPointStatement, { ...criteria });

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
