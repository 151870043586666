<div class="row">
  <div class="col-6 no-gutters">
    <div
      class="error-label"
      *ngIf="
        isRequestTypeEdit(type) &&
        (listOfChange?.contactName ||
          listOfChange?.contactName2 ||
          listOfChange?.contactName3 ||
          listOfChange?.phone ||
          listOfChange?.phone2 ||
          listOfChange?.phone3 ||
          listOfChange?.countryCode ||
          listOfChange?.countryCode2 ||
          listOfChange?.countryCode3)
      "
    >
      <app-tag-updated updated="Store Contact"></app-tag-updated>
    </div>
    <div class="form-group" *ngFor="let contact of storeContact.controls; let i = index">
      <app-phone-contact
        [parentForm]="contact"
        [index]="i"
        (onDeleteContact)="onDeleteContact(i)"
        [submitted]="submitted"
        [mode]="mode"
        [isViewMode]="isViewMode"
        [contactPhoneDisplay]="contactPhoneDisplay[i]"
      ></app-phone-contact>
    </div>
    <span
      id="add-contact"
      data-id="add-contact"
      class="pe-auto"
      (click)="onAddContact()"
      *ngIf="storeContact.controls.length < 3 && !isViewMode"
      ><em class="icon-plus"></em>&nbsp;<u class="pe-auto">Add Contact</u></span
    >
  </div>
</div>
