import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { TDStoreValidatorTypeEnum } from '../../../shared/enum/merchant-validator-type.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { RequestPageModesEnum } from '../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { ErrorResponse, RouteLinkTab, ShelfItems } from '../../../shared/models';
import { AuditLog, HistoryType } from '../../../shared/models/audit-log.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { ShelfList, ShelfListSearchCriteria } from '../../../shared/models/shelf.model';
import { AuthGuardService } from '../../../shared/services';
import { ShelfRequestService } from '../../../shared/services/shelf-request.service';
import { ShelfService } from '../../../shared/services/shelf.service';
import { ShelfItemsRequestAction } from '../../../shared/store/actions/first-lot-order.action';
import {
  ShelfListHistoryRequestAction,
  ShelfListHistoryResponseAction,
  ShelfListRequestAction
} from '../../../shared/store/actions/shelf.actions';
import { ShelfState } from '../../../shared/store/reducers/shelf.reducers';
import { selectShelfItems } from '../../../shared/store/selectors/first-lot-order.selector';
import {
  selectAllShelfList,
  selectShelfList,
  selectShelfListCriteria,
  selectShelfListHistory
} from '../../../shared/store/selectors/shelf.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ExportShelfComponent } from '../shelf-components/export-shelf/export-shelf.component';
import { ViewShelfComponent } from '../view-shelf/view-shelf.component';

@Component({
  selector: 'app-shelf-list',
  templateUrl: './shelf-list.component.html',
  styleUrls: ['./shelf-list.component.scss']
})
export class ShelfListComponent extends BaseSearchComponent<ShelfListSearchCriteria, ShelfList, ShelfState> {
  private localStore: Observable<any>;

  public listRoute: Array<RouteLinkTab>;
  public dateFormat = environment.dateFormat;

  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public shelfTypeTag: string;
  public shelfTypeStringTag: string;
  public statusList = filterDropdown.listStatusFilter;
  public shelfTypeList: ShelfItems[];

  public auditLogs$: Observable<AuditLog[]>;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: UntypedFormBuilder,
    protected readonly translate: TranslateService,
    protected shelfRequestService: ShelfRequestService,
    protected shelfService: ShelfService,
    protected readonly authGuardService: AuthGuardService
  ) {
    super(store, modalService, selectAllShelfList, selectShelfList);
  }

  doInit() {
    this.store.dispatch(new ShelfItemsRequestAction());

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectShelfListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.auditLogs$ = this.localStore.pipe(select(selectShelfListHistory));
    this.localStore
      .pipe(
        select(selectShelfItems),
        filter(res => Boolean(res))
      )
      .subscribe(res => {
        if (res.response) {
          this.shelfTypeList = res.response;
        }
      });
  }

  doDestroy() {
    // intentionally empty
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      lastUpdatedDateFrom: [null],
      lastUpdatedDateTo: [null],
      shelfType: [null]
    });
  }

  onchangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  clearFilterDate() {
    this.setFirstPage();
    this.searchForm.controls['lastUpdatedDateFrom'].reset();
    this.searchForm.controls['lastUpdatedDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      lastUpdatedDateFrom: null,
      lastUpdatedDateTo: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterShelfType() {
    this.searchForm.controls['shelfType'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      shelfType: null
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['lastUpdatedDateFrom'].reset();
    this.searchForm.controls['lastUpdatedDateTo'].reset();
    this.searchForm.controls['shelfType'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      lastUpdatedDateFrom: null,
      lastUpdatedDateTo: null,
      shelfType: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = value;
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = value;
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.lastUpdatedDateFrom && !formValue.lastUpdatedDateTo && !formValue.shelfType) {
      return;
    }

    let dateFrom = this.searchForm.value.lastUpdatedDateFrom;
    let dateTo = this.searchForm.value.lastUpdatedDateTo;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      lastUpdatedDateFrom: dateFrom,
      lastUpdatedDateTo: dateTo,
      shelfType: formValue.shelfType && formValue.shelfType.length > 0 ? formValue.shelfType.toString() : null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.shelfTypeTag = null;
    this.shelfTypeStringTag = null;

    const lastUpdatedDateFrom = dateStringToTagCriteria(this.criteriaObject.lastUpdatedDateFrom);
    const lastUpdatedDateTo = dateStringToTagCriteria(this.criteriaObject.lastUpdatedDateTo);
    const lastUpdatedDate = generateDateStringTag({
      dateName: 'Last Updated Date',
      dateFrom: lastUpdatedDateFrom,
      dateTo: lastUpdatedDateTo
    });

    this.dateStringTag = lastUpdatedDate.dateStringTag;
    this.dateTag = lastUpdatedDate.dateTag;

    if (this.criteriaObject.shelfType && this.criteriaObject.shelfType.length) {
      this.shelfTypeStringTag = 'Shelf Type';
      const types = this.shelfTypeList
        .filter(data => this.criteriaObject.shelfType.includes(data.code))
        .map(data => data.name)
        .join(', ');

      this.shelfTypeTag = `"${types}"`;
    }
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new ShelfListRequestAction(criteriaObj));
  }

  handleEdit(shelfNo: string) {
    this.shelfRequestService
      .getShelfValidate(TDStoreValidatorTypeEnum.REQUESTED, shelfNo)
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: () => {
          this.goToView(shelfNo, true);
        },
        error: error => {
          const initialState = {
            title: 'Alert',
            message: this.translate.instant('ERROR_CODE.' + error.error.code)
          };
          this.modalService.show(AlertModalComponent, {
            initialState
          });
        }
      });
  }

  goToView(id: string, editing?) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        ViewShelfComponent,
        {
          title: editing ? 'Edit Shelf' : 'View Shelf',
          mode: editing ? RequestPageModesEnum.REQUEST_EDIT : RequestPageModesEnum.REQUEST_VIEW,
          shelfNo: id
        },
        false
      )
    };
    this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  exportShelf() {
    this.modalService.show(ExportShelfComponent, {
      backdrop: 'static'
    });
  }

  showHistory(data: ShelfList) {
    this.store.dispatch(new ShelfListHistoryRequestAction({ shelfNo: data.no }));
    const initialState = {
      title: 'History',
      historyHeader: `Shelf: ${data.shelfCode}-${data.shelfName}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.SHELF,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new ShelfListHistoryResponseAction({ auditLogs: null }));
    });
  }

  onActivateShelf(shelfNo, toActivate) {
    const messages = this.getActivatedMessage(toActivate);
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: messages.confirmMessage,
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          return this.shelfService.activateShelf({ shelfNo }, toActivate).pipe(untilComponentDestroyed(this));
        })
      )
      .subscribe({
        next: () => {
          this.alertSuccessModal(messages.successMessage);
        },
        error: error => {
          this.alertErrorModal(error.error);
        },
        complete: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  getActivatedMessage(toActivate) {
    return {
      confirmMessage: !toActivate ? 'Are you sure you want to deactivate?' : 'Are you sure you want to activate?',
      successMessage: !toActivate ? 'The shelf has been deactivated.' : 'The shelf has been activated.'
    };
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide();
        this.setFirstPage();
        this.search({ ...this.criteriaObject, page: 0 });
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  hasShelfEditPermission() {
    return this.authGuardService.checkPermission(['shelf_m']);
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission(['shelf_v', 'shelf_m', 'shelf_firstlot_m']);
    const hasRequestPagePermission = this.authGuardService.checkPermission([
      'shelf_m',
      'shelf_app',
      'shelf_firstlot_m'
    ]);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Shelf List', url: '/products/shelf-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Shelf Request', url: '/products/shelf-request-list' });
    }
  }
}
