import { FullModalActionTypes, FullModalActions } from '../actions/full-modal.action';
import { FullModalState } from '../state/full-modal.state';

export function fullModalReducers(state = { isLeaveFormDirty: false }, action: FullModalActions): FullModalState {
  if (action.type === FullModalActionTypes.UPDATE_LEAVE_FORM_DIRTY) {
    return {
      ...state,
      isLeaveFormDirty: action.payload
    };
  } else {
    return state;
  }
}
