import { createSelector } from '@ngrx/store';

import * as fromSetting from '../reducers/setting.reducers';
import { AppStates } from '../state/app.states';

const selectSettingState = (state: AppStates) => state.setting;

export const selectPriceSetting = createSelector(
  selectSettingState,
  (state: fromSetting.SettingState) => state.priceSetting
);
