import { ArticleTypeEnum } from '../enum/article-type.enum';
import { ProductTypeEnum } from '../enum/product-type.enum';
import { RequestProductErrorEnum } from '../enum/request-step.enum';
import { ProductStatusEnum } from './request-assortment.model';

export class BarcodeListSearchCriteria {
  allowProductType?: ProductTypeEnum = ProductTypeEnum.ALL;
  allowRestrictItem? = false;
  allowDelistItem? = false;
  sortByProductName? = false;
  page? = 0;
  productType?: ProductTypeEnum = ProductTypeEnum.ALL;
  size? = 200;

  constructor(searchCriteria?: BarcodeListSearchCriteria) {
    if (searchCriteria) {
      this.productType = searchCriteria.productType || this.productType;
      this.allowRestrictItem = searchCriteria.allowRestrictItem || this.allowRestrictItem;
      this.allowProductType = searchCriteria.allowProductType || this.allowProductType;
      this.allowDelistItem = searchCriteria.allowDelistItem || this.allowDelistItem;
      this.sortByProductName = searchCriteria.sortByProductName || this.sortByProductName;
      this.page = searchCriteria.page || this.page;
      this.size = searchCriteria.size || this.size;
    }
  }
}

export class BarcodeNameSearchCriteria extends BarcodeListSearchCriteria {
  searchCriteria = '';

  constructor(criteria?: BarcodeNameSearchCriteria) {
    super(criteria);
    if (criteria) {
      this.searchCriteria = criteria.searchCriteria || this.searchCriteria;
    }
  }
}

export class SuggestedRetPrice {
  amount: number;
  currency: string;
}

export class CompetitorRetPrice {
  amount: number;
  currency: string;
}

export class TdPriceConfig {
  active: boolean;
  articleNo: string;
  barcode: string;
  configType: string;
  createdBy: string;
  createdDate: string;
  effectiveDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  price: Price;
}

export class Price {
  amount: number;
  currency: string;
}

// TDSearchBarcode is a Backend Name.
export class BarcodeResponse {
  allowPromotion: boolean;
  allowToBuy: boolean;
  allowVoucher: boolean;
  articleNo: string;
  articleType?: ArticleTypeEnum;
  barcode: string;
  barcodeStatus: BarcodeStatusEnum;
  barSize: string;
  classCode: string;
  errorMessage: RequestProductErrorEnum;
  id: string;
  movingAverage: number;
  productDisplayName?: string;
  productGrading: string;
  productLocation: string;
  productName?: string;
  productStatus?: ProductStatusEnum;
  productTier: string;
  productType: string;
  restrictedItem: boolean;
  retailPrice: number;
  retailPrices?: any[];
  unit: string;
  unitFactor: number;
  wholesalePrice: number;
  vat?: boolean;
  segment?: string;
  subClass?: string;
  family?: string;
  baseUnit?: string;
  itemNo?: number;
}

export enum BarcodeStatusEnum {
  ACTIVE = 'A',
  INACTIVE = 'I'
}
