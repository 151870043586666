<div class="iti iti--allow-dropdown" [ngClass]="separateDialCodeClass">
  <div class="iti__flag-container" dropdown [ngClass]="{ disabled: disabled }" [isDisabled]="disabled">
    <div class="iti__selected-flag dropdown-toggle" dropdownToggle>
      <div class="iti__flag" [ngClass]="selectedCountry.flagClass || ''"></div>
      <div *ngIf="separateDialCode" class="selected-dial-code">+{{ selectedCountry.dialCode }}</div>
      <div class="iti__arrow"></div>
    </div>
    <div *dropdownMenu class="dropdown-menu country-dropdown">
      <div class="search-container" *ngIf="searchCountryFlag && searchCountryField">
        <input
          id="country-search-box"
          [(ngModel)]="countrySearchText"
          (keyup)="searchCountry()"
          (click)="$event.stopPropagation()"
          [placeholder]="searchCountryPlaceholder"
          [ngClass]="{
            'bg-h-change': hasChange
          }"
          autofocus
        />
      </div>
      <ul class="iti__country-list" #countryList>
        <li
          class="iti__country iti__preferred"
          *ngFor="let country of preferredCountriesInDropDown"
          (click)="onCountrySelect(country, focusable)"
          [id]="country.htmlId + '-preferred'"
        >
          <div class="iti__flag-box">
            <div class="iti__flag" [ngClass]="country.flagClass"></div>
          </div>
          <span class="iti__country-name">{{ country.name }}</span>
          <span class="iti__dial-code">+{{ country.dialCode }}</span>
        </li>
        <li class="iti__divider" *ngIf="preferredCountriesInDropDown?.length"></li>
        <li
          class="iti__country iti__standard"
          *ngFor="let country of allCountries"
          (click)="onCountrySelect(country, focusable)"
          [id]="country.htmlId"
        >
          <div class="iti__flag-box">
            <div class="iti__flag" [ngClass]="country.flagClass"></div>
          </div>
          <span class="iti__country-name">{{ country.name }}</span>
          <span class="iti__dial-code">+{{ country.dialCode }}</span>
        </li>
      </ul>
    </div>
  </div>
  <input
    type="tel"
    [id]="inputId"
    autocomplete="off"
    [class]="cssClass"
    [ngClass]="{cssClass,'bg-h-change': hasChange}"
    (keypress)="onInputKeyPress($event)"
    [(ngModel)]="phoneNumber"
    (blur)="onTouched()"
    (ngModelChange)="onPhoneNumberChange()"
    [disabled]="disabled"
    [placeholder]="resolvePlaceholder()"
    [attr.maxLength]="maxLength"
    [attr.validation]="phoneValidation"
    #focusable
  />
</div>
