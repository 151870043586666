import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';

import { BaseUploadButtonComponent } from '../../../base/base-upload-button.component';
import { ModalButtonResponseEnum } from '../../enum/modal-button-response.enum';
import { AlertModalComponent } from '../../layouts';
import { ConfirmModalComponent } from '../../layouts/modals/confirm-modal/confirm-modal.component';
import { CommonUploadService } from '../../services/common.upload.service';

@Component({
  selector: 'app-upload-document-button',
  templateUrl: './upload-document-button.component.html',
  styleUrls: ['./upload-document-button.component.scss']
})
export class UploadDocumentButtonComponent extends BaseUploadButtonComponent {
  @ViewChild('uploadInput') uploadInput: ElementRef;
  @Input() isExistingUpload: boolean;
  @Input() text: string;

  constructor(
    protected readonly modalService: BsModalService,
    protected uploadService: CommonUploadService,
    protected readonly translate: TranslateService,
    protected spinner: NgxSpinnerService,
    protected readonly logger: NGXLogger
  ) {
    super(modalService, uploadService, translate, spinner, logger);
  }

  checkExistingUpload(isUploadWithModal = false, callBack?: () => void) {
    if (this.isExistingUpload) {
      this.alertConfirmReUploadModal(undefined, isUploadWithModal, callBack);
    } else {
      if (isUploadWithModal) {
        callBack();
      } else {
        this.uploadInput.nativeElement.click();
      }
    }
  }

  alertConfirmReUploadModal(
    initialState = {
      title: 'Confirm',
      message: 'Are you sure you want to import new file? All existing data will be lost.',
      okText: 'OK'
    },
    isUploadWithModal = false,
    callBack?: () => void
  ) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          if (isUploadWithModal) {
            callBack();
          } else {
            this.uploadInput.nativeElement.click();
          }
        } else {
          return;
        }
      });
  }

  alertSuccessUploadModal() {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: 'The data have been imported.'
      }
    });
  }

  onHandleUploadError({ value }: { value: HttpErrorResponse }) {
    this.logger.debug('onHandleUploadError', value);

    this.spinner.hide();

    if (['00006', '08022'].includes(value.error.code)) {
      this.alertFailValidation(value.error.message.replace('Export Failed: ', ''));
    } else {
      this.alertFailValidation(this.translate.instant(value.error.translateKey));
    }
  }
}
