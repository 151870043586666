<ng-container *ngIf="listTags.size > 0">
  <div class="top-search-criteria space-search-criteria">
    <div class="search-criteria" *ngFor="let tag of listTags | keyvalue: originalOrder">
      <a class="remove-criteria" (click)="clearTag(tag.key)">
        <em data-id="icon-close-request-date-tage" class="icon-close"></em>
      </a>
      <span>{{ tag.value.name }}: {{ tag.value.value }}</span>
    </div>
    <div data-id="clear-all" class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAllTags()">Clear All</a>
    </div>
  </div>
</ng-container>
