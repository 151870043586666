import { createSelector } from '@ngrx/store';

import * as fromRequestAssortment from '../reducers/request-assortment.reducers';
import { AppStates } from '../state/app.states';

const selectRequestAssortmentState = (state: AppStates) => state.requestAssortment;

export const selectRequestAssortmentList = createSelector(
  selectRequestAssortmentState,
  fromRequestAssortment.selectAll
);

export const selectRequestAssortment = createSelector(
  selectRequestAssortmentState,
  (state: fromRequestAssortment.RequestAssortmentState) => {
    return state;
  }
);

export const selectRequestAssortmentDeleteStatus = createSelector(
  selectRequestAssortmentState,
  (state: fromRequestAssortment.RequestAssortmentState) => {
    return state.isDeleteSuccess;
  }
);

export const selectRequestAssortmentEdit = createSelector(selectRequestAssortmentState, state => {
  return state.requestAssortmentEdit ? state.requestAssortmentEdit : null;
});

export const selectRequestAssortmentCJProduct = createSelector(selectRequestAssortmentEdit, state => {
  return state && state.productDetail && state.productDetail.cjProduct ? state.productDetail.cjProduct : null;
});

export const selectRequestAssortmentEditCJProduct = createSelector(selectRequestAssortmentState, state => {
  return state.requestAssortmentEdit &&
    state.requestAssortmentEdit.document &&
    state.requestAssortmentEdit.document.cjProductSnapshot
    ? state.requestAssortmentEdit.document.cjProductSnapshot
    : {};
});

export const selectWholesale = createSelector(selectRequestAssortmentState, state => {
  return state.requestAssortmentEdit &&
    state.requestAssortmentEdit.document &&
    state.requestAssortmentEdit.document.wholesaleSettings
    ? state.requestAssortmentEdit.document.wholesaleSettings
    : null;
});

export const selectRequestAssortmentEditTDProduct = createSelector(selectRequestAssortmentState, state => {
  return state.requestAssortmentEdit &&
    state.requestAssortmentEdit.document &&
    state.requestAssortmentEdit.document.tdProductSnapshot
    ? state.requestAssortmentEdit.document.tdProductSnapshot
    : null;
});

export const selectTDBarcodes = createSelector(selectRequestAssortmentState, state => {
  return state.requestAssortmentEdit && state.requestAssortmentEdit.document
    ? state.requestAssortmentEdit.document.barcodeSnapshots
    : null;
});

export const selectSupplierPrices = createSelector(selectRequestAssortmentState, state => {
  return state.requestAssortmentEdit && state.requestAssortmentEdit.document
    ? state.requestAssortmentEdit.document.supplierPriceSnapshots
    : null;
});

export const selectRequestAssortmentSaveStatus = createSelector(
  selectRequestAssortmentState,
  (state: fromRequestAssortment.RequestAssortmentState) => {
    return state.saveSuccess;
  }
);

export const selectRequestAssortmentApproveRejectStatus = createSelector(
  selectRequestAssortmentState,
  (state: fromRequestAssortment.RequestAssortmentState) => {
    return state.isApproveRejectSuccess;
  }
);

export const selectRequestAssortmentCriteria = createSelector(selectRequestAssortmentState, state => {
  return state.criteriaObject;
});

export const selectAssortmentChangeList = createSelector(selectRequestAssortmentState, state => {
  return state.requestAssortmentEdit &&
    state.requestAssortmentEdit.document &&
    state.requestAssortmentEdit.document.changeProducts
    ? state.requestAssortmentEdit.document.changeProducts
    : null;
});

export const selectRequestAssortmentCopyStatus = createSelector(selectRequestAssortmentState, state => {
  return state.requestAssortment;
});

export const selectIsCJBarcodeDuplicate = createSelector(selectRequestAssortmentState, state => {
  return state.CJBarcodeDuplicateList;
});

export const selectDuplicateBarcode = createSelector(selectRequestAssortmentState, state => {
  return state.duplicateBarcode;
});

export const selectRequestAssortmentSubmitError = createSelector(selectRequestAssortmentState, state => {
  return state.submitError;
});

export const selectRequestAssortmentSupplierPriceError = createSelector(selectRequestAssortmentState, state => {
  return state.supplierPriceError;
});

export const selectLinkProductCJDetail = createSelector(selectRequestAssortmentState, state => {
  return state.cjProductLinkArticleDetail;
});

export const selectProductHistory = createSelector(selectRequestAssortmentState, state => {
  return state.auditLogs;
});
