import { Action } from '@ngrx/store';

import { ErrorResponse } from '../../models';
import { SubmitWithComment } from '../../models/base-modal.component.model';
import {
  TaskAssignmentRequest,
  TaskRequestListResponse,
  TaskRequestListSearchCriteria
} from '../../models/task-assignment-request.model';

export enum TaskRequestActionType {
  TASK_REQUEST_LIST_REQUEST = '[TaskRequest] Task Request List Request',
  TASK_REQUEST_LIST_RESPONSE = '[TaskRequest] Task Request List Response',
  TASK_REQUEST_LIST_ERROR = '[TaskRequest] Task Request List Error',

  TASK_REQUEST_GET_BY_ID_REQUEST = '[TaskRequest] Get TaskRequest by Id Request',
  TASK_REQUEST_GET_BY_ID_RESPONSE = '[TaskRequest] Get TaskRequest by Id Response',
  TASK_REQUEST_GET_BY_ID_ERROR = '[TaskRequest] Get TaskRequest by Id Error',
  TASK_REQUEST_GET_BY_ID_RESET = '[TaskRequest] Get TaskRequest by Id Reset',

  TASK_REQUEST_SAVE_REQUEST = '[TaskRequest] Task Save Request',
  TASK_REQUEST_SAVE_RESPONSE = '[TaskRequest] Task Save Response',
  TASK_REQUEST_SAVE_RESET = '[TaskRequest] Task Save Reset',

  TASK_REQUEST_SUBMIT_REQUEST = '[TaskRequest] Task Submit Request',
  TASK_REQUEST_SUBMIT_RESPONSE = '[TaskRequest] Task Submit Response',
  TASK_REQUEST_SUBMIT_RESET = '[TaskRequest] Task Submit Reset',

  TASK_REQUEST_APPROVE_REQUESTED = '[Task Request] Task Request Approve Requested',
  TASK_REQUEST_REJECT_REQUESTED = '[Task Request] Task Request Reject Requested',
  TASK_REQUEST_CANCEL_REQUESTED = '[Task Request] Task Request Cancel Requested',
  TASK_REQUEST_DELETE_REQUESTED = '[Task Request] Task Request Delete Requested'
}

export class TaskRequestApproveRequestedAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_APPROVE_REQUESTED;
  constructor(public payload: SubmitWithComment) {}
}

export class TaskRequestRejectRequestedAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_REJECT_REQUESTED;
  constructor(public payload: SubmitWithComment) {}
}

export class TaskRequestCancelRequestedAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_CANCEL_REQUESTED;
  constructor(public payload: SubmitWithComment) {}
}

export class TaskRequestDeleteRequestAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_DELETE_REQUESTED;

  constructor(public payload: any) {}
}

export class TaskRequestListRequestAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_LIST_REQUEST;

  constructor(public payload: TaskRequestListSearchCriteria) {}
}

export class TaskRequestListResponseAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_LIST_RESPONSE;

  constructor(public payload: TaskRequestListResponse) {}
}

export class TaskRequestListErrorAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class TaskRequestByIdRequestAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: { id: string }) {}
}

export class TaskRequestByIdResponseAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: TaskAssignmentRequest) {}
}

export class TaskRequestByIdErrorAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_GET_BY_ID_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class TaskRequestByIdResetAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_GET_BY_ID_RESET;
}

export class TaskRequestSaveRequestAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_SAVE_REQUEST;

  constructor(public payload: TaskAssignmentRequest) {}
}

export class TaskRequestSaveResponseAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_SAVE_RESPONSE;

  constructor(public payload: any) {}
}

export class TaskRequestSaveResetAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_SAVE_RESET;
}

export class TaskRequestSubmitRequestAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_SUBMIT_REQUEST;

  constructor(public payload: TaskAssignmentRequest) {}
}

export class TaskRequestSubmitResponseAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_SUBMIT_RESPONSE;

  constructor(public payload: any) {}
}

export class TaskRequestSubmitResetAction implements Action {
  readonly type = TaskRequestActionType.TASK_REQUEST_SUBMIT_RESET;
}

export type TaskRequestActions =
  | TaskRequestSubmitRequestAction
  | TaskRequestSubmitResponseAction
  | TaskRequestSubmitResetAction
  | TaskRequestListRequestAction
  | TaskRequestListResponseAction
  | TaskRequestListErrorAction
  | TaskRequestSaveRequestAction
  | TaskRequestSaveResponseAction
  | TaskRequestSaveResetAction
  | TaskRequestByIdRequestAction
  | TaskRequestByIdResponseAction
  | TaskRequestByIdErrorAction
  | TaskRequestByIdResetAction
  | TaskRequestApproveRequestedAction
  | TaskRequestRejectRequestedAction
  | TaskRequestCancelRequestedAction
  | TaskRequestDeleteRequestAction;
