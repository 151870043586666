export enum StoreType {
  STORE_MODEL = 'STORE_MODEL',
  PARTNER_MODEL = 'PARTNER_MODEL'
}

export enum StoreSectionEnum {
  PROFILE = 'PROFILE',
  FIRST_LOT_ORDER = 'FIRST_LOT_ORDER',
  CREATED_STORE = 'CREATED_STORE',
  ORDER_POLICY = 'ORDER_POLICY'
}

export enum PosRegisterModes {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  REGENERATE = 'REGENERATE'
}

export enum StoreOrderScheduleImportStatusEnum {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}
