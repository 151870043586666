import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { DeliveryByTypeEnum } from '@shared/enum/delivery-by-type.enum';
import { OrderStatusEnum } from '@shared/enum/order-status.enum';
import { OrderTypeEnum } from '@shared/enum/ordering-method.enum';
import { OrderContent } from '@shared/models/order.model';
import { NumberFormatDisplayPipe } from '@shared/pipes/number-display.pipe';
import { selectViewOrder } from '@shared/store/selectors/order.selectors';
import { AppStates } from '@shared/store/state/app.states';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  providers: [NumberFormatDisplayPipe]
})
export class OrderDetailsComponent extends OnDestroyMixin implements OnInit, OnDestroy, AfterContentChecked {
  private localStore: Observable<any>;
  viewOrder$: Observable<OrderContent>;

  currentPage = 1;
  pageSize = 20;

  constructor(
    public readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    private cdr: ChangeDetectorRef,
    public numberFormatDisplayPipe: NumberFormatDisplayPipe
  ) {
    super();
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.viewOrder$ = this.localStore.pipe(
      select(selectViewOrder),
      distinctUntilChanged(),
      filter(viewOrder => viewOrder !== null)
    );
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  getItemStatus(status) {
    return status ? this.translate.instant('ORDER.ITEM_STATUS.' + status) : null;
  }

  displayByCondition(
    data: any,
    deliveryBy: DeliveryByTypeEnum,
    orderType: OrderTypeEnum,
    status: OrderStatusEnum,
    defaultWareHouseValue?: number | string,
    formatFunction?: (num, precision, dash) => any
  ) {
    if (
      deliveryBy === DeliveryByTypeEnum.SUPPLIER &&
      [OrderTypeEnum.FRESH_LITE, OrderTypeEnum.SPECIAL_REQUEST, OrderTypeEnum.FIRST_LOT_ORDER].includes(orderType) &&
      [
        OrderStatusEnum.NEW_ORDER,
        OrderStatusEnum.PROCESSING,
        OrderStatusEnum.DELIVERED,
        OrderStatusEnum.CANCELED,
        OrderStatusEnum.CLOSED,
        OrderStatusEnum.REJECTED_BY_ERP,
        OrderStatusEnum.PARTIAL_DELIVERED,
        OrderStatusEnum.CANCELED_BY_SUPPLIER
      ].includes(status)
    ) {
      return '';
    } else if (deliveryBy === DeliveryByTypeEnum.TD) {
      if (
        [OrderTypeEnum.FIRST_LOT_ORDER, OrderTypeEnum.SPECIAL_REQUEST, OrderTypeEnum.STORE_REPLENISHMENT].includes(
          orderType
        ) &&
        [OrderStatusEnum.NEW_ORDER].includes(status)
      )
        return defaultWareHouseValue;
    }
    if (formatFunction) {
      return formatFunction(data, 0, data);
    }
    return data;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
