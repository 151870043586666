import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tag-updated',
  templateUrl: './tag-updated.component.html',
  styleUrls: ['./tag-updated.component.scss']
})
export class TagUpdatedComponent implements OnInit {
  @Input() updated: string;

  constructor() {}

  ngOnInit(): void {}
}
