import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { WarehouseService } from '../../services/warehouse.service';
import {
  BillToMasterActionTypes,
  BillToMasterListRequestAction,
  BillToMasterListResponseAction
} from '../actions/bill-to-master.actions';
import { LayoutActionLoadError } from '../actions/layout.action';

@Injectable()
export class BillToMasterEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly warehouseService: WarehouseService
  ) {}

  billToMasterList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<BillToMasterListRequestAction>(BillToMasterActionTypes.BILL_TO_MASTER_LIST_REQUEST),
      map(() => {
        this.logger.debug(`@Effect ${BillToMasterActionTypes.BILL_TO_MASTER_LIST_REQUEST}`);
      }),
      switchMap(() => {
        return this.warehouseService.getBillingMasterData().pipe(
          map(response => {
            return new BillToMasterListResponseAction(response.data.billings);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });
}
