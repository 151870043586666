import { Action } from '@ngrx/store';

import { ImportOrderRequest, ProductOrderItem } from '../../models/order-request.model';

export enum OrderStoreUseSelectItemOrderActionTypes {
  ORDER_STORE_USE_SELECT_ITEM_ORDER_ADD_ITEM = '[Order Select Item Store Use] Add Item',
  ORDER_STORE_USE_SELECT_ITEM_ORDER_UPDATE_ITEM = '[Order Select Item Store Use] Update Item',
  ORDER_STORE_USE_SELECT_ITEM_ORDER_UPDATE_ALL_ITEM = '[Order Select Item Store Use] Update ALL Item',
  ORDER_STORE_USE_SELECT_ITEM_ORDER_REMOVE_ITEM = '[Order Select Item Store Use] Remove Item',
  ORDER_STORE_USE_SELECT_ITEM_ORDER_RESET = '[Order Select Item Store Use] Reset',
  ORDER_STORE_USE_SELECT_ITEM_ORDER_ADD_ALL_ITEM = '[Order Select Item Store Use] Add All Item'
}

export class OrderStoreUseSelectItemOrderAddItem implements Action {
  readonly type = OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_ADD_ITEM;

  constructor(public payload: ProductOrderItem) {}
}

export class OrderStoreUseSelectItemOrderUpdateItem implements Action {
  readonly type = OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_UPDATE_ITEM;

  constructor(public payload: ProductOrderItem) {}
}

export class OrderStoreUseSelectItemOrderUpdateAllItem implements Action {
  readonly type = OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_UPDATE_ALL_ITEM;

  constructor(public payload: Array<ImportOrderRequest>) {}
}

export class OrderStoreUseSelectItemOrderAddAllItem implements Action {
  readonly type = OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_ADD_ALL_ITEM;

  constructor(public payload: { itemList: ProductOrderItem[]; skipUpdated?: boolean }) {}
}

export class OrderStoreUseSelectItemOrderRemoveItem implements Action {
  readonly type = OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_REMOVE_ITEM;

  constructor(public payload: string) {}
}

export class OrderStoreUseSelectItemOrderReset implements Action {
  readonly type = OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_RESET;

  constructor() {}
}

export type OrderStoreUseSelectItemOrderActions =
  | OrderStoreUseSelectItemOrderAddItem
  | OrderStoreUseSelectItemOrderRemoveItem
  | OrderStoreUseSelectItemOrderReset
  | OrderStoreUseSelectItemOrderAddAllItem
  | OrderStoreUseSelectItemOrderUpdateItem
  | OrderStoreUseSelectItemOrderUpdateAllItem;
