import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { PromotionRequest, PromotionSearchCriteria } from '../../models';
import { PromotionAction, PromotionActionTypes } from '../actions/promotion.action';

export interface PromotionRequestState extends EntityState<PromotionRequest> {
  isLoading: boolean;
  criteriaObject: PromotionSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<PromotionRequest> = createEntityAdapter<PromotionRequest>();

export const initialPromotionRequestListState: PromotionRequestState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  auditLogs: null
});

export function promotionRequestReducers(
  state = initialPromotionRequestListState,
  action: PromotionAction
): PromotionRequestState {
  switch (action.type) {
    case PromotionActionTypes.PROMOTION_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case PromotionActionTypes.PROMOTION_REQUEST_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
