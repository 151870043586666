import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DiscountTypeEnum, PromotionTypeEnum } from '../../pages/promotion-v2/enum/promotion-v2.enum';
import {
  PromotionRequestListResponse,
  PromotionRequestResponse,
  PromotionSearchCriteria,
  RequestWithComment
} from '../models';
import { MetricAction, MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';
@Injectable()
export class PromotionRequestService extends BaseService {
  public headers: HttpHeaders;
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.promotionsRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }
  searchByCriteria(criteria: PromotionSearchCriteria): Observable<PromotionRequestListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PROMOTION,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };

    let url = null;
    if (environment.prefixCompany === 'CJX') {
      url = this.getUrl() + '/V2/list';
    } else {
      url = this.getUrl();
    }

    const params = this.getParams(criteria, true);
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  getByRequestId(id: string): Observable<any> {
    let url: string;
    if (environment.prefixCompany === 'CJX') {
      url = this.getFullUrl(this.envService.viewRequest, { id });
      return this.http.get<any>(url, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    } else {
      url = this.getFullUrl(this.envService.requestByID, {
        requestId: id
      });
      return this.http.get<any>(url, {
        headers: this.headers,
        observe: 'body'
      });
    }
  }
  createRequest(data: PromotionRequestResponse): Observable<any> {
    const url = this.getUrl();
    if (environment.prefixCompany === 'CJX') {
      return this.http.post<any>(url + '/V2', data, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    } else {
      return this.http.post<any>(url, data, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    }
  }
  updateByRequestId(data: PromotionRequestResponse): Observable<any> {
    const url = this.getUrl();
    if (environment.prefixCompany === 'CJX') {
      return this.http.put<any>(url + '/V2', data, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    } else {
      return this.http.put<any>(url, data, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    }
  }

  submit(data: PromotionRequestResponse): Observable<any> {
    const url = this.getFullUrl(this.envService.requestsSubmit);
    let metricsReq: Metrics = null;
    if (environment.prefixCompany !== 'CJX') {
      metricsReq = {
        metrics: [
          {
            metricType: MetricType.COUNTER,
            module: MetricModule.PROMOTION,
            path: window.location.pathname
          },
          {
            metricType: MetricType.RESPONSE_TIME,
            module: MetricModule.PROMOTION,
            route: this.envService.requestsSubmit,
            action: MetricActionType.SUBMIT
          },
          {
            metricType: MetricType.HISTOGRAM,
            module: MetricModule.PROMOTION,
            path: window.location.pathname,
            action: MetricAction.PROMOTION_SUBMIT_ITEM,
            type: data.details.promotionType,
            items: data.promotionItems.length
          }
        ]
      };
    }

    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(3000, metricsReq),
      observe: 'body'
    });
  }
  approve(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.approve);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }
  reject(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.reject);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }
  getPromotionById(promotionId: string): Observable<any> {
    const url = this.getFullUrl(this.envService.get, { id: promotionId });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
  /**
   * @description For delete promotion request
   */
  delete(promotionId: string) {
    const url = this.getFullUrl(this.envService.requestsID, {
      requestId: promotionId
    });
    return this.http.delete<any>(url, {
      headers: this.loaderHeaders()
    });
  }

  /**
   * @description For cancel promotion request
   */
  requestCancel(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.requestCancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  importFilePromotionStore(file): Observable<any> {
    const url = this.getFullUrl(this.envService.import);
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  onExportTemplate() {
    const url = this.getFullUrl(this.envService.exportTemplate);
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  onExportStoreRequest(id: string) {
    const url = this.getFullUrl(this.envService.exportRequest, {
      id
    });

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public downloadFileTemplate(type: PromotionTypeEnum, promotionConditionKey: DiscountTypeEnum): Observable<any> {
    const url = this.getFullUrl(this.envService.downloadFileTemplate, { type, promotionConditionKey });
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public importPromotionItem(
    file: any,
    type: PromotionTypeEnum,
    promotionConditionKey: DiscountTypeEnum,
    criteria: any
  ): Observable<any> {
    const url = this.getFullUrl(this.envService.importPromotionItem, { type, promotionConditionKey });
    const formData = new FormData();
    formData.append('file', file, file.name);
    this.headers.append('Content-Type', 'multipart/form-data');
    const params = this.getParams(criteria, true);
    return this.http.post<any>(url, formData, {
      observe: 'body',
      headers: this.loaderHeaders(),
      params
    });
  }

  public export(id: string) {
    const url = this.getFullUrl(this.envService.exportView, { id });

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  generateApprovalWorkflow(payload: any): Observable<any> {
    const url = this.getFullUrl(this.envService.generateWorkflow);

    console.log(url, payload);

    return this.http.post<any>(url, payload, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });

    // return of({
    //   id: '65c9d9df9296e75a03176f8f',
    //   approvalFlowNo: 'AF22-00004',
    //   docNo: 'OE24-000019',
    //   status: 'APPROVED',
    //   type: 'CASH_COUPON_UNLIMIT',
    //   auditLogs: null,
    //   steps: [
    //     {
    //       name: 'Coupon Approver Report To',
    //       type: 'REPORT_TO',
    //       approvalStatus: 'APPROVED',
    //       autoApprove: false,
    //       receivedAt: '2024-02-12T08:42:23.842',
    //       approvedAt: '2024-02-12T08:44:19.41',
    //       rejectedAt: null,
    //       approvers: [
    //         {
    //           userNo: '009124',
    //           firstName: 'Approver',
    //           lastName: 'Aaa',
    //           phone: '0666647475',
    //           approve: true
    //         }
    //       ],
    //       autoApprovalLimit: null
    //     },
    //     {
    //       name: 'Coupon Approver Report To',
    //       type: 'REPORT_TO',
    //       approvalStatus: 'APPROVED',
    //       autoApprove: false,
    //       receivedAt: '2024-02-12T08:44:19.41',
    //       approvedAt: '2024-02-12T08:45:37.958',
    //       rejectedAt: null,
    //       approvers: [
    //         {
    //           userNo: '009125',
    //           firstName: 'Approver',
    //           lastName: 'Bbb',
    //           phone: '0666682211',
    //           approve: true
    //         }
    //       ],
    //       autoApprovalLimit: null
    //     },
    //     {
    //       name: 'Coupon Approval Group',
    //       type: 'GROUP',
    //       approvalStatus: 'APPROVED',
    //       autoApprove: false,
    //       receivedAt: '2024-02-12T08:45:37.958',
    //       approvedAt: '2024-02-12T08:47:10.506',
    //       rejectedAt: null,
    //       approvers: [
    //         {
    //           userNo: '009124',
    //           firstName: 'Approver',
    //           lastName: 'Aaa',
    //           phone: '0666647475',
    //           approve: true
    //         },
    //         {
    //           userNo: '009125',
    //           firstName: 'Approver',
    //           lastName: 'Bbb',
    //           phone: '0666682211',
    //           approve: null
    //         }
    //       ],
    //       autoApprovalLimit: null
    //     }
    //   ],
    //   version: 4,
    //   requesterFirstName: 'Req',
    //   requesterLastName: 'One'
    // });
  }
}
