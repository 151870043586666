import { ExportAssortmentSearchCriteria } from '@shared/models';
import {
  ActionReportEnum,
  AwaitingAllocationForGroupOrderReportCriteria,
  CrossDockInvoiceReportCriteria,
  DcAssortmentExportCriteria,
  GroupOrderUnpickedItemReportCriteria,
  PreOrderCreditRemainingReportCriteria,
  PromotionCompensateReportExportCriteria,
  ReturnToWarehouseGRReportCriteria,
  ReturnToWarehouseOrderReportCriteria,
  StoreToteReportCriteria,
  ToteAdjustmentReportCriteria,
  ToteFineReportCriteria,
  ToteInOutReportCriteria,
  ToteInTrackingReportCriteria
} from '@shared/models/report.model';
import { dateTimeToDateOnlyString } from '@shared/utils/date-util';

import {
  ExportStoreAssortmentByProductCriteria,
  ExportStoreAssortmentByStoreCriteria
} from '../../../../pages/store-assortment/models/store-assortment-request-store.model';
import { dynamicReportAction } from '../stores/dynamic-reports.actions';

export class DynamicReportLogic {
  private reportActions: Map<string, any>;

  constructor() {
    this.reportActions = new Map<string, any>();

    this.initAction();
  }

  private initAction() {
    this.reportActions.set(ActionReportEnum.RETURN_TO_WAREHOUSE_ORDER, {
      action: (payload: any) => {
        return this.returnToWarehouseOrderDoCriteria(payload);
      }
    });

    this.reportActions.set(ActionReportEnum.RETURN_TO_WAREHOUSE_GR, {
      action: (payload: any) => {
        return this.returnToWarehouseGRDoCriteria(payload);
      }
    });

    this.reportActions.set(ActionReportEnum.RETURN_TO_WAREHOUSE_CHARGE, {
      action: (payload: any) => {
        return this.returnToWarehouseChargeDoCriteria(payload);
      }
    });

    this.reportActions.set(ActionReportEnum.TOTE_ADJUSTMENT, {
      action: (payload: any) => {
        return dynamicReportAction.toteAdjustment({
          payload: this.mappingToCriteria(ToteAdjustmentReportCriteria, payload, ['storeCodes'])
        });
      }
    });

    this.reportActions.set(ActionReportEnum.PROMOTION_COMPENSATE, {
      action: (payload: any) => {
        return dynamicReportAction.promotionCompensate({
          payload: this.mappingToCriteria(PromotionCompensateReportExportCriteria, payload, ['classes'])
        });
      }
    });

    this.reportActions.set(ActionReportEnum.AWAITING_ALLOCATION_FOR_GROUP_ORDER, {
      action: (payload: any) => {
        return this.awaitingAllocationForGroupOrderCriteria(payload);
      }
    });

    this.reportActions.set(ActionReportEnum.PRE_ORDER_CREDIT_REMAINING, {
      action: (payload: any) => {
        return this.preOrderCreditRemainingCriteria(payload);
      }
    });

    this.reportActions.set(ActionReportEnum.GROUP_ORDER_UNPICKED_ITEM, {
      action: (payload: any) => {
        return dynamicReportAction.groupOrderUnpickedItem({
          payload: this.mappingToCriteria(GroupOrderUnpickedItemReportCriteria, payload, [
            'storeCode',
            'warehouseCode',
            'orderType'
          ])
        });
      }
    });

    this.reportActions.set(ActionReportEnum.TD_ASSORTMENT, {
      action: (payload: any) => {
        return dynamicReportAction.tdAssortment({
          payload: this.mappingToCriteria(ExportAssortmentSearchCriteria, payload, [
            'productType',
            'orderingMethods',
            'deliveryMethods',
            'productStatuses'
          ])
        });
      }
    });

    this.reportActions.set(ActionReportEnum.TD_ASSORTMENT_CONFIDENTIAL, {
      action: (payload: any) => {
        return dynamicReportAction.tdAssortmentConfidential({
          payload: this.mappingToCriteria(ExportAssortmentSearchCriteria, payload, [
            'productType',
            'orderingMethods',
            'deliveryMethods',
            'productStatuses'
          ])
        });
      }
    });

    this.reportActions.set(ActionReportEnum.DC_ASSORTMENT, {
      action: (payload: any) => {
        payload.warehouseCode = [payload.warehouseCode];
        return dynamicReportAction.dcAssortment({
          payload: this.mappingToCriteria(DcAssortmentExportCriteria, payload, ['warehouseCode'])
        });
      }
    });

    this.reportActions.set(ActionReportEnum.STORE_ASSORTMENT, {
      action: (payload: any) => {
        return dynamicReportAction.storeAssortment({
          payload: this.mappingToCriteria(ExportStoreAssortmentByStoreCriteria, payload, ['storeNo'])
        });
      }
    });

    this.reportActions.set(ActionReportEnum.ASSORTMENT_LOCATION, {
      action: (payload: any) => {
        return this.assortLocationCriteria(payload);
      }
    });

    this.reportActions.set(ActionReportEnum.CROSS_DOCK_INVOICE, {
      action: (payload: any) => {
        return dynamicReportAction.crossDockInvoice({
          payload: this.mappingToCriteria(CrossDockInvoiceReportCriteria, payload)
        });
      }
    });

    this.reportActions.set(ActionReportEnum.STORE_TOTE, {
      action: (payload: any) => {
        payload.storeCodes = payload.storeCodes?.length ? payload.storeCodes : null;
        payload.toteId = payload.toteId ?? null;
        return dynamicReportAction.storeTote({
          payload: this.mappingToCriteria(StoreToteReportCriteria, payload, ['storeCodes'])
        });
      }
    });

    this.reportActions.set(ActionReportEnum.TOTE_FINE, {
      action: (payload: any) => {
        return dynamicReportAction.toteFine({
          payload: this.mappingToCriteria(ToteFineReportCriteria, payload, ['fineType', 'location'])
        });
      }
    });

    this.reportActions.set(ActionReportEnum.TOTE_IN_OUT, {
      action: (payload: any) => {
        return dynamicReportAction.toteInOut({
          payload: this.mappingToCriteria(ToteInOutReportCriteria, payload)
        });
      }
    });

    this.reportActions.set(ActionReportEnum.TOTE_IN_TRACKING, {
      action: (payload: any) => {
        return dynamicReportAction.toteInTracking({
          payload: this.mappingToCriteria(ToteInTrackingReportCriteria, payload)
        });
      }
    });
  }

  public transformPayloadAndCriteria(reportType: ActionReportEnum, payload: any) {
    if (this.reportActions.has(reportType)) {
      return this.reportActions.get(reportType).action(payload);
    }

    return null;
  }

  private exportReportErrorMessage(message: string) {
    return dynamicReportAction.exportReportError({
      isSuccess: false,
      error: {
        error: {
          message
        }
      }
    });
  }

  private returnToWarehouseGRDoCriteria(payload) {
    const validateLimitStore = this.validateLimitListExceeded(
      (payload?.shipFrom as any) as string[],
      100,
      'locations'
    );
    if (validateLimitStore) {
      return validateLimitStore;
    }

    const criteriaObj = {
      createdDateFrom: payload.createdDateFrom ? dateTimeToDateOnlyString(new Date(payload.createdDateFrom)) : null,
      createdDateTo: payload.createdDateTo ? dateTimeToDateOnlyString(new Date(payload.createdDateTo)) : null,
      shipFromCodeName: payload.shipFrom ? ((payload.shipFrom as any) as string[]).join(',') : null,
      shipToCodeName: payload.shipTo ? ((payload.shipTo as any) as string[]).join(',') : null,
      status: payload.status ? ((payload.status as any) as string[]).join(',') : null,
      returnType: payload.returnType ? ((payload.returnType as any) as string[]).join(',') : null,
      fileType: payload.fileType
    };

    return dynamicReportAction.returnToWarehouseGR({ payload: new ReturnToWarehouseGRReportCriteria(criteriaObj) });
  }

  private returnToWarehouseOrderDoCriteria(payload) {
    const validateLimitStore = this.validateLimitListExceeded(
      (payload?.shipFrom as any) as string[],
      100,
      'locations'
    );
    if (validateLimitStore) {
      return validateLimitStore;
    }

    const criteriaObj: ReturnToWarehouseOrderReportCriteria = {
      createdDateFrom: payload.createdDateFrom ? dateTimeToDateOnlyString(new Date(payload.createdDateFrom)) : null,
      createdDateTo: payload.createdDateTo ? dateTimeToDateOnlyString(new Date(payload.createdDateTo)) : null,
      shipFromCodeName: payload.shipFrom ? ((payload.shipFrom as any) as string[]).join(',') : null,
      shipToCodeName: payload.shipTo ? ((payload.shipTo as any) as string[]).join(',') : null,
      status: payload.status ? ((payload.status as any) as string[]).join(',') : null,
      returnType: payload.returnType ? ((payload.returnType as any) as string[]).join(',') : null,
      fileType: payload.fileType
    };

    return dynamicReportAction.returnToWarehouseOrder({
      payload: criteriaObj
    });
  }

  private returnToWarehouseChargeDoCriteria(payload) {
    const validateLimitStore = this.validateLimitListExceeded(
      (payload?.shipFrom as any) as string[],
      100,
      'locations'
    );
    if (validateLimitStore) {
      return validateLimitStore;
    }

    const criteriaObj = {
      createdDateFrom: payload.createdDateFrom ? dateTimeToDateOnlyString(new Date(payload.createdDateFrom)) : null,
      createdDateTo: payload.createdDateTo ? dateTimeToDateOnlyString(new Date(payload.createdDateTo)) : null,
      shipFromCodeName: payload.shipFrom ? ((payload.shipFrom as any) as string[]).join(',') : null,
      shipToCodeName: payload.shipTo ? ((payload.shipTo as any) as string[]).join(',') : null,
      returnType: payload.returnType ? ((payload.returnType as any) as string[]).join(',') : null,
      fileType: payload.fileType
    };

    return dynamicReportAction.returnToWarehouseCharge({ payload: criteriaObj });
  }

  awaitingAllocationForGroupOrderCriteria(payload) {
    const validateLimitStore = this.validateLimitListExceeded((payload?.storeCode as any) as string[], 100, 'stores');
    if (validateLimitStore) {
      return validateLimitStore;
    }
    return dynamicReportAction.awaitingAllocationForGroupOrder({
      payload: this.mappingToCriteria(AwaitingAllocationForGroupOrderReportCriteria, payload, [
        'storeCode',
        'orderType',
        'warehouseCode'
      ])
    });
  }

  private preOrderCreditRemainingCriteria(payload) {
    const validateLimitStore = this.validateLimitListExceeded(payload?.storeCodes, 100, 'stores');
    if (validateLimitStore) {
      return validateLimitStore;
    }
    const reportCriteria = this.mappingToCriteria(PreOrderCreditRemainingReportCriteria, payload, ['storeCodes']);
    return dynamicReportAction.preOrderCreditRemaining({ payload: reportCriteria });
  }

  private assortLocationCriteria(payload) {
    const reportCriteria: ExportStoreAssortmentByProductCriteria = {
      articleNo: payload.barcode.articleNo,
      barcode: payload.barcode.barcode,
      fileType: payload.fileType
    };
    return dynamicReportAction.assortmentLocation({ payload: reportCriteria });
  }

  private mappingToCriteria<T>(type: { new (): T }, value: any, direct: string[] = null): T {
    let criteria = new type();
    for (let name in value) {
      if (direct && direct.includes(name)) {
        criteria[name] = value[name];
      } else if (value[name] && name.toLocaleLowerCase().indexOf('datefrom') > 0) {
        criteria[name] = dateTimeToDateOnlyString(new Date(value[name]));
      } else if (value[name] && name.toLocaleLowerCase().indexOf('dateto') > 0) {
        criteria[name] = dateTimeToDateOnlyString(new Date(value[name]));
      } else if (value[name] instanceof Array) {
        criteria[name] = value[name].length > 0 ? value[name].toString() : null;
      } else {
        criteria[name] = value[name];
      }
    }
    return criteria;
  }

  private validateLimitListExceeded<T>(items: T[], limit: number, limitType: string) {
    if (items && items.length > limit) {
      return this.exportReportErrorMessage(`Export limit exceeded (up to ${limit} ${limitType}).`);
    }

    return null;
  }
}
