import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

import { FileModuleEnum } from '../../enum/file-url.enum';

@Component({
  selector: 'app-multiple-files-upload',
  templateUrl: './multiple-files-upload.component.html',
  styleUrls: ['./multiple-files-upload.component.scss']
})
export class MultipleFilesUploadComponent {
  @Input() form: UntypedFormGroup;
  @Input() arrayName: string;
  @Input() submitted: boolean;
  @Input() allowedTypes: string;
  @Input() allowedExt: string;
  @Input() descriptionTxt: string;
  @Input() fileSizeErrorTxt: string;
  @Input() fileTypeErrorTxt: string;
  @Input() maxImages: number;
  @Input() size: number;
  @Input() fileModule: FileModuleEnum;
  @Input() hasChange: boolean;

  constructor(private fb: UntypedFormBuilder, private readonly logger: NGXLogger) {}

  get formArray() {
    return this.form.get(this.arrayName) as UntypedFormArray;
  }

  get getImage() {
    return this.fb.group({
      image: ''
    });
  }

  addMoreImage() {
    this.logger.debug('addMoreImage->field', this.arrayName);
    this.formArray.push(this.getImage);
  }
}
