import { createSelector } from '@ngrx/store';

import * as fromOrderInventoryRequestResponseState from '../reducers/order-inventory-request.reducers';
import { AppStates } from '../state/app.states';

const selectOrderInventoryRequestState = (state: AppStates) => state.orderInventoryRequest;

export const selectAllOrderInventoryRequestList = createSelector(
  selectOrderInventoryRequestState,
  fromOrderInventoryRequestResponseState.selectAll
);

export const selectOrderInventoryRequestList = createSelector(
  selectOrderInventoryRequestState,
  (state: fromOrderInventoryRequestResponseState.OrderInventoryRequestState) => state
);

export const selectOrderInventoryRequestById = createSelector(
  selectOrderInventoryRequestState,
  (state: fromOrderInventoryRequestResponseState.OrderInventoryRequestState) => state.selected
);

export const selectOrderInventoryRequestShelfList = createSelector(
  selectOrderInventoryRequestState,
  (state: fromOrderInventoryRequestResponseState.OrderInventoryRequestState) => state.shelfList
);

export const selectInventoryDeliveryDetails = createSelector(
  selectOrderInventoryRequestState,
  (state: fromOrderInventoryRequestResponseState.OrderInventoryRequestState) => {
    return state.selected && state.selected.deliveryDetails;
  }
);
