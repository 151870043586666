import { Action } from '@ngrx/store';

import {
  SelectWarehouseList,
  SelectWarehousePagingList,
  WarehouseListResponse,
  WarehousesStoresSearchCriteria
} from '../../models/warehouse.model';

export enum WarehouseActionTypes {
  WAREHOUSE_LIST_REQUEST = '[Warehouse List] Warehouse List Request',
  WAREHOUSE_LIST_RESPONSE = '[Warehouse List] Warehouse List Response',
  SELECT_WAREHOUSES_LIST_REQUEST = '[Select Warehouse List] Select Warehouse List Request',
  SELECT_WAREHOUSES_LIST_RESPONSE = '[Select Warehouse List] Select Warehouse List Response',
  SELECT_WAREHOUSES_PAGING_LIST_RESPONSE = '[Select Warehouse List] Select Warehouse Paging List Response',
  REMOVE_ALL_SELECT_WAREHOUSE_LIST = '[Select Warehouse List] Remove All Select Warehouse List',
  RESET_WAREHOUSE_LIST = '[Select Warehouse List] Reset All Warehouse List',
  WAREHOUSE_ACTIVE_LIST_REQUEST = '[Warehouse List] Active Warehouse List Request',
  WAREHOUSE_ACTIVE_LIST_RESPONSE = '[Warehouse List] Active Warehouse List Response'
}

export class WarehouseListRequestAction implements Action {
  readonly type = WarehouseActionTypes.WAREHOUSE_LIST_REQUEST;

  constructor(public delimiter: string = '-') {}
}

export class WarehouseListResponseAction implements Action {
  readonly type = WarehouseActionTypes.WAREHOUSE_LIST_RESPONSE;
  constructor(public payload: WarehouseListResponse) {}
}

export class WarehouseActiveListRequestAction implements Action {
  readonly type = WarehouseActionTypes.WAREHOUSE_ACTIVE_LIST_REQUEST;

  constructor(public delimiter: string = '-') {}
}

export class WarehouseActiveListResponseAction implements Action {
  readonly type = WarehouseActionTypes.WAREHOUSE_ACTIVE_LIST_RESPONSE;
  constructor(public payload: WarehouseListResponse) {}
}

export class SelectWarehouseListRequestAction implements Action {
  readonly type = WarehouseActionTypes.SELECT_WAREHOUSES_LIST_REQUEST;

  constructor(public payload: WarehousesStoresSearchCriteria) {}
}

export class SelectWarehouseListResponseAction implements Action {
  readonly type = WarehouseActionTypes.SELECT_WAREHOUSES_LIST_RESPONSE;
  constructor(public payload: SelectWarehouseList[]) {}
}

export class SelectWarehousePagingListResponseAction implements Action {
  readonly type = WarehouseActionTypes.SELECT_WAREHOUSES_PAGING_LIST_RESPONSE;
  constructor(public payload: SelectWarehousePagingList) {}
}

export class RemoveAllSelectWarehouseListAction implements Action {
  readonly type = WarehouseActionTypes.REMOVE_ALL_SELECT_WAREHOUSE_LIST;
  constructor() {}
}

export class ResetWarehouseListAction implements Action {
  readonly type = WarehouseActionTypes.RESET_WAREHOUSE_LIST;
  constructor() {}
}

export type WarehouseAction =
  | WarehouseListRequestAction
  | WarehouseListResponseAction
  | SelectWarehouseListRequestAction
  | SelectWarehouseListResponseAction
  | RemoveAllSelectWarehouseListAction
  | SelectWarehousePagingListResponseAction
  | ResetWarehouseListAction
  | WarehouseActiveListRequestAction
  | WarehouseActiveListResponseAction;
