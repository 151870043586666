import { createSelector } from '@ngrx/store';

import * as fromOrderStoreUseSelectItemOrderState from '../reducers/order-store-use-select-item-order.reducers';
import { AppStates } from '../state/app.states';

const selectOrderStoreUseSelectItemOrderState = (state: AppStates) => state.orderStoreUseSelectItemOrder;

export const getOrderStoreUseSelectItemOrderById = articleNo =>
  createSelector(selectOrderStoreUseSelectItemOrderState, orders => orders.entities[articleNo]);

export const getCountOrderStoreUseSelectItemOrder = createSelector(
  selectOrderStoreUseSelectItemOrderState,
  fromOrderStoreUseSelectItemOrderState.selectTotal
);

export const selectAllOrderStoreUseSelectItemOrder = createSelector(
  selectOrderStoreUseSelectItemOrderState,
  fromOrderStoreUseSelectItemOrderState.selectAll
);

export const selectOrderStoreUseUpdated = createSelector(
  selectOrderStoreUseSelectItemOrderState,
  orders => orders.skipUpdated
);
