import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

import { ICustomFile } from '../../../pages/purchase/purchase-request-file-upload/purchase-request-file-upload.component';

export enum FileType {
  XLSX = 'XLSX',
  CSV = 'CSV'
}

@Component({
  selector: 'app-files-input',
  templateUrl: './files-input.component.html',
  styleUrls: ['./files-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilesInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FilesInputComponent),
      multi: true
    }
  ],
  exportAs: 'FilesInputComponent'
})
export class FilesInputComponent implements OnChanges, OnInit, ControlValueAccessor {
  @HostBinding('class.is-invalid') get invalid() {
    return this.hasError;
  }

  constructor(
    @Optional() @SkipSelf() private readonly controlContainer: ControlContainer,
    private readonly logger: NGXLogger
  ) {
    this.ngChange = () => {};
    this.ngTouched = () => {};
  }

  url = '';
  inputValue;
  isLoad = true;
  ngChange;
  ngTouched;
  value;
  fileList: ICustomFile[] = [];
  progress;
  fileService;

  @ViewChild('uploadInput', { static: false }) uploadInput: ElementRef;
  @Output() clearInput: EventEmitter<any> = new EventEmitter<any>();
  @Output() showErrorMessage: EventEmitter<string> = new EventEmitter<string>();
  @Output() submit: EventEmitter<Array<File>> = new EventEmitter<Array<File>>();
  @Input() size: number;
  @Input() controlName: string;
  @Input() disabled: boolean;
  @Input() fileTypeErrorTxt = 'Incorrect format (allow only format file .xlsx).';
  @Input() hasError: boolean;
  @Input() cssDisabled: boolean;
  @Input() fileSizeErrorTxt = `File size limit exceeded. <br/><br/>`;
  @Input() fileTypeAllowed = [FileType.XLSX];
  @Input() isEnableFileInput: boolean;
  @HostListener('change', ['$event.target.files']) onChange = (_value: any) => {};
  @HostListener('blur') onTouched = () => {};

  ngOnInit(): void {
    this.fileList = [];
    this.hasError = false;
  }

  ngOnChanges(): void {
    this.ngChange(this.value);
  }

  propagateChange: any = () => {};

  writeValue(fileList): void {
    this.value = this.fileList = this.inputValue = fileList;
  }

  registerOnChange(fn: any): void {
    this.onChange = this.onChangeGenerator(fn);
  }

  registerOnTouched(fn: any): void {
    this.ngTouched = fn;
    this.propagateChange = fn;
  }

  private onChangeGenerator(fn: (_: any) => {}): (_: ICustomFile[]) => void {
    this.ngChange(this.value);
    this.ngTouched();

    return (files: ICustomFile[]) => {
      const fileArr: File[] = [];
      this.isLoad = false;

      for (const f of files) {
        fileArr.push(f);
      }

      fn(fileArr);
    };
  }

  validate(c: AbstractControl) {
    if (!c.value || !c.value.length || c.disabled) {
      return null;
    }

    let errors: ValidationErrors = {};

    for (const f of c.value) {
      if (this.size && this.size < f.size) {
        f.errors = { ...f.errors, fileSize: true };
        errors = { ...errors, fileSize: true };
      }

      let extPXlsx = false;
      let typePXlsx = false;
      let extPCsv = false;
      let typePCsv = false;

      this.fileTypeAllowed.forEach(type => {
        if (type === FileType.XLSX) {
          extPXlsx = new RegExp('.(xlsx)', 'ig').test(f.name);
          typePXlsx = new RegExp('^application/vnd.openxmlformats-.*$', 'ig').test(f.type);
        } else if (type === FileType.CSV) {
          extPCsv = new RegExp('.(csv)', 'ig').test(f.name);
          typePCsv = new RegExp('^text/csv.*$', 'ig').test(f.type);
        }
      });

      const fileErrors = {
        ...(!(extPXlsx || extPCsv) && {
          fileExt: true
        }),
        ...(f.type &&
          !(typePXlsx || typePCsv) && {
            fileType: true
          })
      };

      errors = {
        ...errors,
        ...fileErrors
      };

      f.errors = {
        ...f.errors,
        ...fileErrors
      };
    }

    this.fileList = c.value;

    if (Object.keys(errors).length) {
      const isFileTypeError = errors['fileType'] || errors['fileExt'];
      const isFileSizeError = errors['fileSize'];
      this.onShowErrorMessage(isFileTypeError, isFileSizeError);
      this.controlContainer.control.get(this.controlName).setErrors(errors);
      return errors;
    } else {
      this.submit.emit(c.value);
    }
  }

  onClickDelete() {
    this.ngChange(this.value);
    this.fileList = [];
    this.controlContainer.control.get(this.controlName).setValue(null);
    if (this.isEnableFileInput) {
      this.disabled = false;
      this.cssDisabled = false;
    }
    this.clearInput.emit();
  }

  onShowErrorMessage(isFileTypeError, isFileSizeError) {
    this.showErrorMessage.emit(isFileTypeError ? this.fileTypeErrorTxt : this.fileSizeErrorTxt);
    this.logger.debug('alertFailModal->isFileSizeError', isFileSizeError);
  }
}
