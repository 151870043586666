import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxCaptureService } from 'ngx-capture';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { AlertModalComponent } from '..';
import { environment } from '../../../../environments/environment';
import { ModalButtonResponseEnum } from '../../enum/modal-button-response.enum';
import { ErrorConnectorService } from '../../services/error-connector.service';
import { NotifyService } from '../../services/notify.service';
import { LayoutActionErrorUpdate, LayoutForceCloseCurrentModal } from '../../store/actions/layout.action';
import { selectLayoutState } from '../../store/selectors/layout-selector';
import { AppStates } from '../../store/state/app.states';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @ViewChild('adminScreen', { static: true }) adminScreen: any;
  url: string;
  isGeneralError: Subscription;
  isAlreadyDisplayError: boolean;
  public env = { ...environment };

  constructor(
    private router: Router,
    // private location: Location,
    public sideBarService: SidebarService,
    private readonly store: Store<AppStates>,
    private readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    private captureService: NgxCaptureService,
    private notifyService: NotifyService,
    private errorConnectorService: ErrorConnectorService
  ) {
    super();

    const pageContent = document.querySelector('#page-content');

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      if (pageContent) {
        pageContent.scrollTop = 0;
        pageContent.scrollLeft = 0;
      }
    });

    this.errorConnectorService.errorEvent.subscribe(async errorContent => {
      if (this.env.notifyError && !errorContent.isFullModal) {
        try {
          const img = await this.captureService.getImage(this.adminScreen.nativeElement, true);
          await this.notifyService.sendNotify(errorContent.module, errorContent.message, errorContent.status, img);
        } catch (err) {
          this.logger.info('cannot connect to notify');
        }
      }
    });
  }

  ngOnInit() {
    this.isGeneralError = this.store
      .pipe(untilComponentDestroyed(this), select(selectLayoutState))
      .subscribe(layoutError => {
        let initialState = null;
        if (layoutError.isApiError && !this.isAlreadyDisplayError) {
          if (
            layoutError.isUseBackEndMessage &&
            layoutError.errorResponse.error &&
            layoutError.errorResponse.error.message
          ) {
            this.isAlreadyDisplayError = true;
            initialState = {
              title: 'Failed',
              message: layoutError.errorResponse.error.message,
              isRefresh: true
            };
          } else {
            this.isAlreadyDisplayError = true;
            initialState = {
              title: 'Error',
              message: 'There is something went wrong!!! Please try again later.',
              isRefresh: true
            };
            const pageContent = document.querySelector('#full-modal-content');
            if (layoutError.errorResponse) {
              const errorContent = {
                module: window.location.pathname,
                message: layoutError.errorResponse.error.message,
                status: layoutError.errorResponse.error.status,
                isFullModal: !!pageContent
              };
              this.errorConnectorService.showError(errorContent);
            }
          }
        }

        if (initialState) {
          const alertModal = this.modalService.show(AlertModalComponent, {
            initialState
          });

          if (alertModal) {
            this.subscribeForCloseModal(alertModal, layoutError);
          }
        }
      });

    this.modalService.onHide.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.isAlreadyDisplayError = false;
      this.store.dispatch(new LayoutActionErrorUpdate(false));
    });
  }

  ngOnDestroy(): void {
    this.isGeneralError.unsubscribe();
    this.store.dispatch(new LayoutActionErrorUpdate(false));
  }

  subscribeForCloseModal(alertModal, layoutError) {
    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        if (layoutError.forceCloseAfterConfirm) {
          this.store.dispatch(new LayoutForceCloseCurrentModal(true));
        }
        alertModal.hide();
      }
    });
  }

  get sideBarState$() {
    return this.sideBarService.sidebarState$;
  }

  get sideBarState() {
    return this.sideBarService.sidebarState;
  }
}
