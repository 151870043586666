import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { RequestPageModesEnum, RequestTypeEnum } from '@shared/enum/request-step.enum';

@Component({
  selector: 'app-phone-contact',
  templateUrl: './phone-contact.component.html',
  styleUrls: ['./phone-contact.component.scss']
})
export class PhoneContactComponent {
  @Input() index: number;
  @Input() submitted: boolean;
  @Input() mode: RequestPageModesEnum;
  @Input() type: RequestTypeEnum;
  @Input() listOfChange: any;
  @Input() contactPhoneDisplay: string;
  @Input() isViewMode: boolean;
  @Output() onDeleteContact = new EventEmitter();

  @Input() set parentForm(value: AbstractControl<any>) {
    this._parentForm = value as UntypedFormGroup;
  }

  get getParentForm(): UntypedFormGroup {
    return this._parentForm as UntypedFormGroup;
  }

  get contactName() {
    return this.getParentForm.get('contactName') as AbstractControl;
  }

  get phone() {
    return this.getParentForm.get('phone') as AbstractControl;
  }

  _parentForm: UntypedFormGroup;

  constructor() {}

  isPhoneError(control: AbstractControl): string {
    return this.submitted && control.errors ? 'is-invalid' : '';
  }

  deleteContact() {
    if (this.index > 0) {
      this.onDeleteContact.emit(this.index);
    } else {
      this.getParentForm.reset();
    }
  }

  isRequestTypeEdit(requestType: RequestTypeEnum) {
    return requestType === RequestTypeEnum.EDIT;
  }
}
