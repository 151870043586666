import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, of } from 'rxjs';

import { Metrics } from '../models/metrics';
import { BaseService } from './base.service';

@Injectable()
export class NotifyService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.exporter;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public sendNotify(module: string, message: string, status: string, base64: string) {
    const url = this.getFullUrl(this.envService.notify);

    const data = {
      env: this.env.tier,
      module,
      message,
      status,
      base64
    };

    return this.env.notifyError
      ? this.http
          .post<any>(url, data, {
            headers: this.headers,
            observe: 'body'
          })
          .toPromise()
      : of(EMPTY).toPromise();
  }

  public sendMetrics(data: Metrics) {
    const url = this.getFullUrl(this.envService.metrics);

    return this.env.metrics
      ? this.http.post<any>(url, data, {
          headers: this.headers,
          observe: 'body'
        })
      : of(EMPTY);
  }
}
