import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SignedUrlObject } from '../models';
import { SubmitWithComment } from '../models/base-modal.component.model';
import { ResponseAssignmentListResponse } from '../models/task-assignment-response.model';
import {
  TaskAssignmentListResponse,
  TaskAssignmentListSearchCriteria,
  TaskAssignmentResponse
} from '../models/task-assignment.model';
import { BaseService } from './base.service';

@Injectable()
export class TaskAssignmentService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.taskAssignment;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: TaskAssignmentListSearchCriteria): Observable<TaskAssignmentListResponse> {
    const params = this.getParams(criteria, true);
    return this.http.get<TaskAssignmentListResponse>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getTaskById<T1 extends TaskAssignmentResponse>(taskNo: string): Observable<T1> {
    const url = this.getFullUrl(this.envService.get, {
      taskNo
    });

    return this.http.get<T1>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  cancel(data: SubmitWithComment) {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  public loadTaskList(
    searchCriteria?: string,
    responseRule?: string,
    page = 0,
    size = 500
  ): Observable<ResponseAssignmentListResponse> {
    const url = this.getFullUrl(this.envService.loadTaskList);
    const params = this.getParams({ searchCriteria, responseRule, page, size }, true);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public exportStore(taskNo: string): Observable<any> {
    const url = this.getFullUrl(this.envService.exportStore);
    const data = { taskNo: taskNo };
    return this.http.post<any>(url, data, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  public getFileUrl(refId: string): Observable<SignedUrlObject> {
    const url = this.getFullUrl(this.envService.imageSignedUrl);
    return this.http.post<SignedUrlObject>(url, { refId }, { headers: this.headers });
  }
}
