import { Action } from '@ngrx/store';

import { MyReportListResponse, MyReportSearchCriteria } from '../../models/my-report.model';

export enum MyReportActionTypes {
  MY_REPORT_LIST_REQUEST = '[My Report] MyReports request list',
  MY_REPORT_LIST_RESPONSE = '[My Report] MyReports response list'
}

export class MyReportListRequestAction implements Action {
  readonly type = MyReportActionTypes.MY_REPORT_LIST_REQUEST;
  constructor(public payload: MyReportSearchCriteria = null) {}
}

export class MyReportListResponseAction implements Action {
  readonly type = MyReportActionTypes.MY_REPORT_LIST_RESPONSE;
  constructor(public payload: MyReportListResponse) {}
}

export type MyReportAction = MyReportListRequestAction | MyReportListResponseAction;
