import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { StoreByIdRequestAction } from '../../../../../pages/store/store/store.actions';
import { ModalButtonResponseEnum } from '../../../../enum/modal-button-response.enum';
import { RequestPageModesEnum } from '../../../../enum/request-step.enum';
import { TDStoreEnum, TDStorePage } from '../../../../enum/td-store-page.enum';
import { AlertModalComponent } from '../../../../layouts';
import { ErrorResponse, UpdateOrderScheduleTemplate } from '../../../../models';
import { StoreService } from '../../../../services/store.service';
import { AppStates } from '../../../../store/state/app.states';
import { removeObjectArray } from '../../../../utils/remove-object-array.util';
import { OrderPolicyComponent } from '../order-policy.component';
import { PreOrderPolicyComponent } from '../pre-order-policy/pre-order-policy.component';

@Component({
  selector: 'app-update-order-policy',
  templateUrl: './update-order-policy.component.html',
  styleUrls: ['./update-order-policy.component.scss']
})
export class UpdateOrderPolicyComponent extends OnDestroyMixin implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('orderPolicy') orderPolicy: OrderPolicyComponent;
  @ViewChild('preOrderPolicy') preOrderPolicy: PreOrderPolicyComponent;
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() mode: RequestPageModesEnum;
  @Input() page: TDStorePage;
  @Input() storeNo: string;
  @Input() merchant: string;
  @Input() version: string;
  @Input() typeSection: TDStoreEnum;

  public form: UntypedFormGroup;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly bsModalRef: BsModalRef,
    private readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly storeService: StoreService
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.createForm();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    if (this.isPreOrder) {
      this.preOrderPolicy.toggleEditPreOrderPolicy();
    } else if (this.isOrder) {
      this.orderPolicy.toggleEditOrderPolicy();
    }
  }

  createForm() {
    return this.fb.group({});
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    let data;
    switch (this.typeSection) {
      case TDStoreEnum.REPLENISH:
        data = this.prepareOrderSchedule();
        break;
      case TDStoreEnum.PRE_ORDER:
        data = this.preparePreOrderSchedule();
        break;
      default:
        break;
    }

    this.storeService.updateOrderSchedule(data).subscribe({
      next: () => {
        this.store.dispatch(
          new StoreByIdRequestAction({
            merchant: { merchant: this.merchant },
            storeNo: { storeNo: this.storeNo }
          })
        );

        this.alertSuccessModal();
      },
      error: error => {
        this.alertErrorModal(error);
      }
    });
  }

  prepareOrderSchedule() {
    const template: UpdateOrderScheduleTemplate = new UpdateOrderScheduleTemplate();
    const formData = this.form.getRawValue();
    const schedules = formData.orderSchedule.orderScheduleList[0].schedules;
    const preOrderSchedules = formData.orderSchedule.orderScheduleList[0].preOrderSchedules;

    template.no = this.storeNo;
    template.preOrderWarehouseCode = formData.orderSchedule.orderScheduleList[0].preOrderWarehouseCode;
    template.warehouseCode = formData.orderSchedule.orderScheduleList[0].warehouseCode;
    template.minOrder = formData.orderSchedule.orderScheduleList[0].minOrder;
    template.version = this.version;
    template.schedules = removeObjectArray(schedules);
    template.preOrderSchedules = removeObjectArray(preOrderSchedules);
    template.preOrderScheduleAction = false;

    return template;
  }

  preparePreOrderSchedule() {
    const template: UpdateOrderScheduleTemplate = new UpdateOrderScheduleTemplate();
    const formData = this.form.getRawValue();
    const preOrderSchedules = formData.preOrderSchedule.preOrderScheduleList[0].preOrderSchedules;
    const schedules = formData.preOrderSchedule.preOrderScheduleList[0].schedules;

    template.no = this.storeNo;
    template.preOrderWarehouseCode = formData.preOrderSchedule.preOrderScheduleList[0].preOrderWarehouseCode;
    template.warehouseCode = formData.preOrderSchedule.preOrderScheduleList[0].warehouseCode;
    template.minOrder = formData.preOrderSchedule.preOrderScheduleList[0].minOrder;
    template.version = this.version;
    template.preOrderSchedules = removeObjectArray(preOrderSchedules);
    template.schedules = removeObjectArray(schedules);
    template.preOrderScheduleAction = true;

    return template;
  }

  alertSuccessModal() {
    const initialState = {
      title: 'Success',
      message: `Order Schedule has been updated`
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.bsModalRef.hide();
      }
    });
  }

  alertErrorModal(errorResponse: any) {
    const error: ErrorResponse = errorResponse.error;
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(error.translateKey, { context: error.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  get isOrder() {
    return this.typeSection === TDStoreEnum.REPLENISH;
  }

  get isPreOrder() {
    return this.typeSection === TDStoreEnum.PRE_ORDER;
  }

  get title() {
    switch (this.typeSection) {
      case TDStoreEnum.REPLENISH:
        return 'Update Order Schedule (for Replenish)';
      case TDStoreEnum.PRE_ORDER:
        return 'Update Order Schedule (for Pre Order)';
      case TDStoreEnum.PRE_ORDER_HUB:
        return 'Update Order Schedule (for Pre Order Hub)';
      default:
        return '';
    }
  }
}
