import { Component } from '@angular/core';

import { Theme } from '@shared/services/theme/theme';

import { environment as env } from '../../../../environments/environment';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent {
  protected readonly Theme = Theme;
  protected readonly env = env;

  constructor() {
    // intentionally empty
  }
}
