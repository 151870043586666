import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MovingAverageReportExportCriteria } from '../models/report.model';
import { metricsReporting } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable()
export class MovingAverageReportService extends BaseService {
  public headers: HttpHeaders;
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.movingAverageReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  reportExport(reportCriteria: MovingAverageReportExportCriteria) {
    const params = this.getParams(reportCriteria, true);

    const url = this.getFullUrl(this.envService.export);
    const metrics = metricsReporting([this.envService.url, this.envService.export]);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
