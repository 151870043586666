import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import { ClaimRequest, ClaimRequestContent, ClaimRequestSearchCriteria } from '../../model/claim-request.model';
import { AppClaimState } from '../claim.state';
import { ClaimRequestActionTypes, ClaimRequestActions } from './claim-request.actions';

export const claimRequestModuleReducers: ActionReducerMap<Partial<AppClaimState>> = {
  claimRequest: claimRequestReducers
};

export interface ClaimRequestState extends EntityState<ClaimRequestContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  selected: ClaimRequest[];
  claimRequest: ClaimRequest;
  criteriaObject: ClaimRequestSearchCriteria;
  totalElements: number;
  totalPages: number;
  isVersionError: boolean;
}

export const adapter: EntityAdapter<ClaimRequestContent> = createEntityAdapter<ClaimRequestContent>();

export const initialClaimRequestState: ClaimRequestState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  selected: null,
  claimRequest: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  isVersionError: false
});

export function claimRequestReducers(
  state = initialClaimRequestState,
  action: ClaimRequestActions
): ClaimRequestState {
  switch (action.type) {
    case ClaimRequestActionTypes.CLAIM_REQUEST_VIEW_LOADED: {
      return {
        ...state,
        claimRequest: action.payload
      };
    }
    case ClaimRequestActionTypes.CLAIM_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ClaimRequestActionTypes.CLAIM_REQUEST_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ClaimRequestActionTypes.CLAIM_REQUEST_RESET: {
      return {
        ...state,
        claimRequest: null
      };
    }
    case ClaimRequestActionTypes.CLAIM_REQUEST_VERSION_ERROR: {
      return {
        ...state,
        isVersionError: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
