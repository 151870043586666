import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthGuardService } from '../../services';
import { MenuActionTypes, MenuLoadedAction, MenuRequestedAction } from '../actions/menu.action';

@Injectable()
export class MenuEffects {
  constructor(
    private readonly actions: Actions,
    private readonly logger: NGXLogger,
    private readonly authGuardService: AuthGuardService
  ) {}

  MenuRequested: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType<MenuRequestedAction>(MenuActionTypes.MenuRequested),
      map(action => {
        this.logger.debug(`@Effect ${MenuActionTypes.MenuRequested}: ` + this.stringify(action.payload));
        return new MenuLoadedAction(this.authGuardService.getMenuByPermission(action.payload));
      })
    );
  });

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
