import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AuditLog } from '../../models';
import { OrderService } from '../../services/order.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import {
  OrderActionTypes,
  OrderHistoryLoaded,
  OrderHistoryRequested,
  OrderListSearchLoaded,
  OrderListSearchRequested,
  OrderReleaseOrderRequested,
  OrderReleaseOrderRequestedError,
  OrderViewLoaded,
  OrderViewRequested
} from '../actions/order.actions';

@Injectable()
export class OrderEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly orderService: OrderService,
    private readonly logger: NGXLogger
  ) {}

  searchOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderListSearchRequested>(OrderActionTypes.OrderListSearchRequested),
      tap(action => this.logger.debug('@Effect Order List Search: ' + JSON.stringify(action.payload))),
      switchMap(action => {
        return this.orderService.searchByCriteria(action.payload, action.isGroupOrder).pipe(
          map(orders => new OrderListSearchLoaded({ orders })),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  loadOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderViewRequested>(OrderActionTypes.OrderViewRequested),
      tap(action => this.logger.debug('@Effect Order View Requested: ' + JSON.stringify(action.payload))),
      switchMap(action =>
        this.orderService.getOrderRequest(action.payload).pipe(
          map(result => new OrderViewLoaded(result)),
          catchError(err => of(new LayoutActionLoadError(err)))
        )
      )
    );
  });

  releaseOrderRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderReleaseOrderRequested>(OrderActionTypes.OrderReleaseOrderRequested),
      tap(action => this.logger.debug('@Effect Order View Requested: ' + JSON.stringify(action.payload))),
      switchMap(action =>
        this.orderService.releaseOrderRequested(action.payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The order has been released.'
              })
          ),
          catchError(error => {
            if (error.error && error.error.code === '03034') {
              return of(new OrderReleaseOrderRequestedError(error));
            }
            return of(new LayoutActionLoadError(error));
          })
        )
      )
    );
  });

  orderHistory = createEffect(() => {
    return this.actions$.pipe(
      ofType<OrderHistoryRequested>(OrderActionTypes.OrderHistoryRequested),
      tap(action => this.logger.debug('@Effect Order History: ' + JSON.stringify(action.orderNo))),
      switchMap(action =>
        this.orderService.getHistoryLogs(action.orderNo).pipe(
          map(result => new OrderHistoryLoaded(result as AuditLog[])),
          catchError(err => of(new LayoutActionLoadError(err)))
        )
      )
    );
  });
}
