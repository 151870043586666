<div class="modal-content admin-form">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="closeBtn">
      <span aria-hidden="true"><em class="icon-close"></em></span>
    </button>
  </div>
  <div class="modal-body" [formGroup]="form">
    <div class="row">
      <div class="col-md-6 mb-3">
        <div class="form-group">
          <label for="deviceId">Device ID<span class="text-danger">*</span></label>
          <input
            id="deviceId"
            name="deviceId"
            type="text"
            class="form-control"
            maxlength="16"
            formControlName="deviceId"
            placeholder="Device ID"
            appHexadecimalOnly
            [ngClass]="{
              'is-invalid':
                (submitted && form.controls.deviceId.errors?.required) || form.controls.deviceId.errors?.pattern
            }"
          />
          <div *ngIf="submitted && form.controls.deviceId.errors?.required" class="invalid-feedback">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <div class="form-group">
          <label for="registeredNo">RD Registered No.<span class="text-danger">*</span></label>
          <input
            id="registeredNo"
            name="registeredNo"
            type="text"
            class="form-control"
            maxlength="20"
            appAlphanumericOnly
            formControlName="registeredNo"
            placeholder="RD Registered No."
            [ngClass]="{
              'is-invalid': submitted && form.controls.registeredNo.errors?.required
            }"
          />
          <div *ngIf="submitted && form.controls.registeredNo.errors?.required" class="invalid-feedback">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <div class="form-group">
          <label for="serialNo">Serial No.<span class="text-danger">*</span></label>
          <input
            id="serialNo"
            name="serialNo"
            type="text"
            class="form-control"
            maxlength="50"
            trim="blur"
            formControlName="serialNo"
            placeholder="Serial No."
            [ngClass]="{
              'is-invalid': submitted && form.controls.serialNo.errors?.required
            }"
          />
          <div *ngIf="submitted && form.controls.serialNo.errors?.required" class="invalid-feedback">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" *ngIf="!viewMode">
    <button type="button" class="btn btn-standard" (click)="decline()" id="cancelBtn">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="confirm()" id="okBtn">
      Submit
    </button>
  </div>
</div>
