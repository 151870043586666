import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ShelfService } from '../../services/shelf.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ShelfActionType,
  ShelfByIdRequestAction,
  ShelfByIdResponseAction,
  ShelfListHistoryRequestAction,
  ShelfListHistoryResponseAction,
  ShelfListRequestAction,
  ShelfListResponseAction,
  SubmitShelfFirstLotErrorAction,
  SubmitShelfFirstLotRequestAction,
  SubmitShelfFirstLotResponseAction
} from '../actions/shelf.actions';

@Injectable()
export class ShelfEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly shelfService: ShelfService,
    private readonly logger: NGXLogger
  ) {}

  searchShelf$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfListRequestAction>(ShelfActionType.SHELF_LIST_REQUEST),
      map(action => {
        this.logger.debug(`@Effect ${ShelfActionType.SHELF_LIST_REQUEST}: ` + this.stringify(action.payload));
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfService.searchByCriteria(payload).pipe(
          map(response => {
            return new ShelfListResponseAction(response);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  shelfHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfListHistoryRequestAction>(ShelfActionType.SHELF_LIST_HISTORY_REQUEST),
      map(action => {
        this.logger.debug(`@Effect ${ShelfActionType.SHELF_LIST_HISTORY_REQUEST}: ` + this.stringify(action.payload));
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfService.getHistoryLogs(payload).pipe(
          map(response => new ShelfListHistoryResponseAction({ auditLogs: response.auditLogs })),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  getMerchantById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfByIdRequestAction>(ShelfActionType.SHELF_GET_BY_ID_REQUEST),
      map(action => {
        this.logger.debug(`@Effect ${ShelfActionType.SHELF_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload));
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfService.getShelfById(payload).pipe(
          map(res => {
            return new ShelfByIdResponseAction(res);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  submitFirstLot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SubmitShelfFirstLotRequestAction>(ShelfActionType.SUBMIT_SHELF_FIRST_LOT_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfActionType.SUBMIT_SHELF_FIRST_LOT_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfService.submitFirstLot(payload).pipe(
          map(res => new SubmitShelfFirstLotResponseAction(res)),
          catchError(error => of(new SubmitShelfFirstLotErrorAction(error.error)))
        );
      })
    );
  });

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
