import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import {
  VoucherRequestAction,
  VoucherRequestActionTypes
} from '../../../pages/voucher/store/voucher-request.actions';
import {
  VoucherRequestContent,
  VoucherRequestSearchCriteria,
  VoucherRequestViewResponse
} from '../models/voucher.model';
import { VoucherRequestStates } from './voucher-request.states';

export const voucherRequestModuleReducers: ActionReducerMap<Partial<VoucherRequestStates>> = {
  voucherRequest: VoucherRequestReducers
};

export interface VoucherRequestState extends EntityState<VoucherRequestContent> {
  isLoading: boolean;
  selected: VoucherRequestViewResponse;
  criteriaObject: VoucherRequestSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<VoucherRequestContent> = createEntityAdapter<VoucherRequestContent>();

export const initialVoucherRequestListState: VoucherRequestState = adapter.getInitialState({
  isLoading: false,
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  auditLogs: null
});

export function VoucherRequestReducers(
  state = initialVoucherRequestListState,
  action: VoucherRequestAction
): VoucherRequestState {
  switch (action.type) {
    case VoucherRequestActionTypes.VOUCHER_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case VoucherRequestActionTypes.VOUCHER_REQUEST_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case VoucherRequestActionTypes.VOUCHER_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: { ...action.payload.VoucherRequestView }
      };
    case VoucherRequestActionTypes.VOUCHER_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
