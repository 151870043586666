import { enableExperimentalFragmentVariables, gql } from 'graphql-tag';

import { MasterData } from '../models';

enableExperimentalFragmentVariables();

export class NationalityAndOccupationMasterData {
  nationalities: MasterData[];
  occupations: MasterData[];
}

const nationality = gql`
  fragment nationalities on Nationality {
    id
    code
    nameTh
  }
`;

const occupation = gql`
  fragment occupations on Occupation {
    id
    code
    nameTh
  }
`;

export const nationalityAndOccupationMasterQuery = gql`
  query nationalityAndOccupationMasterData($nationalityCode: String!, $occupationCode: String!) {
      nationalities(code: $nationalityCode) {
        ...nationalities,
      },
      occupations(code: $occupationCode) {
        ...occupations
      }
  }
  ${nationality},
  ${occupation}
`;
