import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { ProductMaster } from '../../models/product-master.model';
import { ProductMasterActionTypes, ProductMasterActions } from '../actions/product-master.actions';

export interface ProductMasterState extends EntityState<ProductMaster> {
  isLoading: boolean;
  productMaster: any;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<ProductMaster> = createEntityAdapter<ProductMaster>();

export const initialProductMasterState: ProductMasterState = adapter.getInitialState({
  isLoading: false,
  productMaster: {},
  totalElements: 0,
  totalPages: 0
});

export function productMasterReducers(
  state = initialProductMasterState,
  action: ProductMasterActions
): ProductMasterState {
  if (action.type === ProductMasterActionTypes.ProductMasterViewLoaded) {
    return adapter.addOne(action.payload.productMaster, state);
  } else {
    return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
