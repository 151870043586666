<div id="order-details" *ngIf="viewOrder$ | async as order">
  <div class="mt-3 table-responsive">
    <table
      id="datatable"
      class="table table-striped table-bordered table-hover border-none w-100"
      aria-label="datatable"
    >
      <thead>
        <tr>
          <th class="text-center align-top" scope="col">No.</th>
          <th class="text-center align-top" scope="col">Product Name</th>
          <th class="text-center align-top" scope="col">Article</th>
          <th class="text-center align-top w-15" scope="col">Delivered Qty</th>
          <th class="text-center align-top w-15" scope="col">Remaining Qty</th>
          <th class="text-center align-top w-15" scope="col">Received Qty</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of paging.currentPageData; index as i">
          <td class="text-center">{{ paging.getItemNo(i) }}</td>
          <td>{{ item.productName }}</td>
          <td class="text-left">{{ item.articleNo }}</td>
          <td class="text-center">
            {{ item.deliveredQty | dashDisplay }}
            <div class="sub-header">({{ item.pickedQty + ' ' + item.unit }})</div>
          </td>
          <td class="text-center">
            {{ item.remainQty }}
          </td>
          <td class="text-center">
            {{ item.receivedQty | dashDisplay }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-ui-pagination
    #paging
    [currentPage]="currentPage"
    [pageSize]="pageSize"
    [items]="order?.items"
    [valueChangeDetector]="viewOrder$ | async"
  >
  </app-ui-pagination>
</div>
