import { MemoizedSelector } from '@ngrx/store';

import { selectMerchantRequest } from '../../pages/merchant/stores/merchant-request/merchant-request.selector';
import { selectMerchantById } from '../../pages/merchant/stores/merchant/merchant.selectors';
import { selectRewardCatalogRequest } from '../../pages/reward-catalog/store/reward-catalog-request/reward-catalog-request.selector';
import { selectRewardCatalogById } from '../../pages/reward-catalog/store/reward-catalog/reward-catalog.selectors';
import { selectShelfFixAssetRequestById } from '../../pages/shelf/shelf-fix-asset/store/shelf-fix-asset-request/shelf-fix-asset-request.selectors';
import { selectShelfFixAssetById } from '../../pages/shelf/shelf-fix-asset/store/shelf-fix-asset/shelf-fix-asset.selectors';
import { selectShelfInventoryRequestById } from '../../pages/shelf/shelf-inventory/store/shelf-inventory-request/shelf-inventory-request.selectors';
import { selectShelfInventoryById } from '../../pages/shelf/shelf-inventory/store/shelf-inventory/shelf-inventory.selectors';
import { selectStoreById } from '../../pages/store/store/store.selectors';
import { RewardCatalogPage } from '../enum/reward-catalog.enum';
import { ShelfPages, TDStorePage } from '../enum/td-store-page.enum';
import { selectShelfRequestById } from '../store/selectors/shelf-request.selectors';
import { selectShelfById } from '../store/selectors/shelf.selectors';
import { selectStoreRequestById } from '../store/selectors/store-request.selectors';
import { AppStates } from '../store/state/app.states';

export const getSelectByPage: any = (page: TDStorePage): MemoizedSelector<AppStates, any> | undefined => {
  switch (page) {
    case TDStorePage.MERCHANT_REQUEST:
      return selectMerchantRequest;
    case TDStorePage.MERCHANT_EDIT:
      return selectMerchantById;
    case TDStorePage.STORE_EDIT:
    case TDStorePage.STORE_EDIT_ORDER_SCHEDULE:
      return selectStoreById;
    case TDStorePage.STORE_EDIT_PRE_ORDER_HUB_SCHEDULE:
      return selectStoreById;
    case TDStorePage.STORE_REQUEST:
      return selectStoreRequestById;
    default:
      return;
  }
};

export const getSelectShelf: any = (page: ShelfPages): MemoizedSelector<AppStates, any> | undefined => {
  switch (page) {
    case ShelfPages.SHELF_REQUEST:
      return selectShelfRequestById;
    case ShelfPages.SHELF_EDIT:
      return selectShelfById;
    default:
      return;
  }
};

export const getSelectShelfPage: any = (page: ShelfPages): MemoizedSelector<AppStates, any> | undefined => {
  switch (page) {
    case ShelfPages.SHELF_REQUEST:
      return selectShelfFixAssetRequestById;
    case ShelfPages.SHELF_REQUEST_FIX_ASSET:
      return selectShelfFixAssetRequestById;
    case ShelfPages.SHELF_REQUEST_INVENTORY:
      return selectShelfInventoryRequestById;
    case ShelfPages.SHELF_EDIT:
      return selectShelfById;
    case ShelfPages.SHELF_INVENTORY_EDIT:
      return selectShelfInventoryById;
    case ShelfPages.SHELF_FIX_ASSET_EDIT:
      return selectShelfFixAssetById;
    default:
      return;
  }
};

export const getSelectRewardCatalog: any = (
  page: RewardCatalogPage
): MemoizedSelector<AppStates, any> | undefined => {
  switch (page) {
    case RewardCatalogPage.REWARD_CATALOG_REQUEST:
      return selectRewardCatalogRequest;
    case RewardCatalogPage.REWARD_CATALOG:
      return selectRewardCatalogById;
    default:
      return;
  }
};
