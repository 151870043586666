import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ModeEnum } from '../../../../shared/components/edit-in-place/edit-in-place.component';
import {
  AddShelfTypeRequestAction,
  ShelfItemsRequestAction
} from '../../../../shared/store/actions/first-lot-order.action';
import { selectShelfItems } from '../../../../shared/store/selectors/first-lot-order.selector';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-add-shelf-type',
  templateUrl: './add-shelf-type.component.html',
  styleUrls: ['./add-shelf-type.component.scss']
})
export class AddShelfTypeComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @Output() addedShelfType: EventEmitter<any> = new EventEmitter();
  private localStore: Observable<any>;

  public shelfTypeForm: UntypedFormGroup;
  public submitted: boolean;
  public isUpdated: boolean;
  public isLoading: boolean;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly bsModalRef: BsModalRef,
    private readonly store: Store<AppStates>
  ) {
    super();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.isUpdated || this.shelfTypeListForm.dirty) {
      this.store.dispatch(new ShelfItemsRequestAction());
    }
  }

  ngOnInit() {
    this.shelfTypeForm = this.fb.group({
      shelfTypeName: [{ value: null, disabled: false }, [Validators.required, this.isUniqueValidator('name')]],
      shelfTypeList: this.fb.array([]),
      editInPlaceMode: ModeEnum.VIEW
    });

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(
        select(selectShelfItems),
        filter(res => Boolean(res))
      )
      .subscribe(res => {
        this.initDataHandling(res);
      });
  }

  initDataHandling(res: any) {
    if (res.isSuccess) {
      this.addedShelfType.emit();
      this.bsModalRef.hide();
      this.isLoading = false;
    } else if (res.error) {
      const regex = /\[(.*)\]/;
      const errorFields = regex.exec(res.error.message);

      if (errorFields && errorFields[1]) {
        const duplicateFields = errorFields[1].split(', ');

        this.shelfTypeListForm.controls.forEach(item => {
          item.get('name').setErrors(duplicateFields.includes(item.value.name) && { duplicated: true });
        });
        this.addedShelfType.emit();
        this.isLoading = false;
      }
    } else if (res.response) {
      const sortedResult = [...res.response].sort((a, b) => b.code.localeCompare(a.code));

      sortedResult.forEach(shelfItem => {
        const control = this.fb.group({
          code: shelfItem.code,
          name: [{ value: shelfItem.name, disabled: false }, [Validators.required, this.isUniqueValidator('name')]]
        });

        control.get('name').setErrors(shelfItem.isDuplicated && { duplicated: true });

        this.shelfTypeListForm.push(control);
      });
    }
  }

  isUniqueValidator(controlName): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null && this.shelfTypeListForm.length) {
        const isDuplicated = this.shelfTypeListForm.controls
          .map((formGroup: AbstractControl[] | any) => formGroup.controls[controlName])
          .filter(formControl => formControl !== control)
          .some((formControl: UntypedFormControl) => formControl.value === control.value);

        return isDuplicated ? { duplicated: isDuplicated } : null;
      }
      return null;
    };
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  addShelfTypeName() {
    const name = this.shelfTypeForm.value.shelfTypeName;

    this.submitted = true;
    if (this.addedShelfTypeForm.invalid) {
      return;
    }

    this.shelfTypeListForm.insert(
      0,
      this.fb.group({
        code: null,
        name: [{ value: name, disabled: false }, [Validators.required, this.isUniqueValidator('name')]]
      })
    );
    this.addedShelfTypeForm.reset();
    this.submitted = false;
    this.isUpdated = true;
  }

  onSubmit() {
    if (this.shelfTypeListForm.invalid) {
      return;
    }

    if (this.isUpdated || this.shelfTypeListForm.dirty) {
      this.store.dispatch(new AddShelfTypeRequestAction(this.shelfTypeListForm.getRawValue()));
      this.isLoading = true;
    } else {
      this.bsModalRef.hide();
    }
  }

  onEditInPlaceModeChange(editInPlaceMode) {
    this.form.get('editInPlaceMode').setValue(editInPlaceMode);

    if (editInPlaceMode === ModeEnum.FORCE_VIEW) {
      setTimeout(() => {
        this.form.get('editInPlaceMode').setValue(ModeEnum.VIEW);
      }, 0);
    }
  }

  get form(): UntypedFormGroup {
    return this.shelfTypeForm;
  }

  get shelfTypeListForm() {
    return this.form.controls['shelfTypeList'] as UntypedFormArray;
  }

  get addedShelfTypeForm() {
    return this.form.get('shelfTypeName') as UntypedFormControl;
  }
}
