import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { TaskAssignmentRequestService } from '../../services/task-assignment-request.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import {
  TaskRequestActionType,
  TaskRequestApproveRequestedAction,
  TaskRequestByIdRequestAction,
  TaskRequestByIdResponseAction,
  TaskRequestCancelRequestedAction,
  TaskRequestDeleteRequestAction,
  TaskRequestListRequestAction,
  TaskRequestListResponseAction,
  TaskRequestRejectRequestedAction,
  TaskRequestSaveRequestAction,
  TaskRequestSubmitRequestAction
} from '../actions/task-request.actions';

@Injectable()
export class TaskRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly taskAssignmentRequestService: TaskAssignmentRequestService,
    private readonly logger: NGXLogger
  ) {}

  searchTaskRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TaskRequestListRequestAction>(TaskRequestActionType.TASK_REQUEST_LIST_REQUEST),
      tap(action =>
        this.logger.debug(
          `@Effect ${TaskRequestActionType.TASK_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload)
        )
      ),
      switchMap(action => {
        return this.taskAssignmentRequestService.searchByCriteria(action.payload).pipe(
          map(response => new TaskRequestListResponseAction(response)),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  taskRequestSave$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TaskRequestSaveRequestAction>(TaskRequestActionType.TASK_REQUEST_SAVE_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${TaskRequestActionType.TASK_REQUEST_SAVE_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.taskAssignmentRequestService.saveRequest(payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been saved.'
              })
          ),
          catchError(err => of(new LayoutActionLoadError(err)))
        );
      })
    );
  });

  taskRequestSubmit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TaskRequestSubmitRequestAction>(TaskRequestActionType.TASK_REQUEST_SUBMIT_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${TaskRequestActionType.TASK_REQUEST_SUBMIT_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.taskAssignmentRequestService.submitRequest(payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been sent to approver.'
              })
          ),
          catchError(err => of(new LayoutActionLoadError(err)))
        );
      })
    );
  });

  getTaskRequestById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TaskRequestByIdRequestAction>(TaskRequestActionType.TASK_REQUEST_GET_BY_ID_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${TaskRequestActionType.TASK_REQUEST_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.taskAssignmentRequestService.getTaskRequestById(payload.id).pipe(
          map(taskRequest => {
            return new TaskRequestByIdResponseAction(taskRequest);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  rejectRequestTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TaskRequestRejectRequestedAction>(TaskRequestActionType.TASK_REQUEST_REJECT_REQUESTED),
      tap(action => this.logger.debug('@Effect Request Reject: ' + JSON.stringify(action.payload))),
      mergeMap(action =>
        this.taskAssignmentRequestService.reject(action.payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been rejected.'
              })
          ),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  approveRequestTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TaskRequestApproveRequestedAction>(TaskRequestActionType.TASK_REQUEST_APPROVE_REQUESTED),
      tap(action => this.logger.debug('@Effect Request Approve: ' + JSON.stringify(action.payload))),
      mergeMap(action =>
        this.taskAssignmentRequestService.approve(action.payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been approved.'
              })
          ),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  cancelTaskRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TaskRequestCancelRequestedAction>(TaskRequestActionType.TASK_REQUEST_CANCEL_REQUESTED),
      tap(action => this.logger.debug('@Effect Request Cancel: ' + JSON.stringify(action.payload))),
      switchMap(action =>
        this.taskAssignmentRequestService.cancel(action.payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been cancelled.'
              })
          ),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  deleteTaskRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TaskRequestDeleteRequestAction>(TaskRequestActionType.TASK_REQUEST_DELETE_REQUESTED),
      tap(action => this.logger.debug('@Effect Request Delete: ' + JSON.stringify(action.payload))),
      switchMap(action =>
        this.taskAssignmentRequestService.delete(action.payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been deleted.'
              })
          ),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
