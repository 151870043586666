<div id="home-page" class="page-wrapper">
  <div class="page-content">
    <div class="right-wrapper">
      <div class="form-section">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <p class="footer">&copy; {{ env.copyRight }} <app-version-tag></app-version-tag></p>
  </div>
</div>
