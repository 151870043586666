import { STORE_DEVICE } from '../../pages/store/view-store/enum/store';
import { MerchantInfo, OrderSchedule, OrderScheduleList, StoreProfile } from './merchant.model';

export class StoreListSearchCriteria {
  storeType?: string;
  state?: string;
  region?: string;
  searchCriteria?: string;
  text?: string;
  status?: string;
  startCreatedDate?: string;
  endCreatedDate?: string;
  orderSubType?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
  defaultGroup?: boolean;
  active?: boolean;
  searchCriteriaFieldName?: string;
}

export class StoreListSearchByName {
  storeType?: string;
  searchCriteria?: string;
  status?: string;
  warehouse?: string;
}

export class StoreListResponse {
  content: StoreList[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface StoreList {
  version: number;
  no: string;
  code: string;
  createdByName: string;
  createdDate: string;
  deleted: boolean;
  deviceId: string;
  deviceStatus: string;
  group: string;
  id: string;
  lastModifiedDate: string;
  merchant: string;
  merchantType: string;
  merchantStatus: string;
  name: string;
  status: string;
  site: string;
  state: string;
  region: string;
  saleStatus?: SaleStatusEnum;
  terminateDate: string;
  openDate: string;
}

export class StoreId {
  storeId: string;
}

export class StoreNo {
  storeNo: string;
}

export class StoreViewResponse {
  id: string;
  no?: string;
  version: number;
  merchantId: string;
  taxId: string;
  merchantName: string;
  merchantType: string;
  contactName: string;
  mobilePhone: string;
  merchantInfo?: MerchantInfo;
  orderSchedule?: OrderSchedule;
  devices?: Device[];
  // handDevices?: Device[];
  saleStatus?: SaleStatusEnum;
}

export class StoreResponse {
  id: string;
  no: string;
  version: number;
  status: string;
  storeProfile: StoreProfile;
  orderSchedule: OrderScheduleList;
  devices: Device[];
  saleStatus?: SaleStatusEnum;
}

export class Device {
  deviceId: string;
  deviceNo: string;
  registerDate: string;
  effectiveDate: string;
  generatedDate: string;
  serialNo: string;
  registeredNo: string;
  status: POSStatus;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  runningNo: number;
  type: STORE_DEVICE;
}

export class POSRegisteredData {
  deviceId: string;
  registeredNo: string;
  serialNo: string;
}

export enum SaleStatusEnum {
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE'
}

export enum StorePageTabs {
  STORE_INFO = 'STORE_INFO',
  FIRST_LOT_ORDER = 'FIRST_LOT_ORDER'
}

export enum POSStatus {
  WAITING = 'WAITING',
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}
export class ImportOrderScheduleContent {
  storeCode: string;
  storeName: string;
  scheduleType: string;
  wmsCode: string;
  warehouseName: string;
  orderScheduleDate: string;
  pickScheduleDate: string;
  deliverySchedule: string;
  remark?: string;
}

export class ImportOrderScheduleData {
  storeCode: string;
  scheduleType: string;
  wmsCode: string;
  orderScheduleDate: string;
  pickScheduleDate: string;
  deliverySchedule: string;
}
export class StoreExportCriteria {
  storeType: string;
  status: string;
  fileType: string;

  constructor(init?: Partial<StoreExportCriteria>) {
    Object.assign(this, init);
  }
}
export class StoreExportOrderScheduleCriteria {
  scheduleType: string;
  warehouseCode: string;
  status: string;
  fileType: string;

  constructor(init?: Partial<StoreExportOrderScheduleCriteria>) {
    Object.assign(this, init);
  }
}

export class StorePagingSearchCriteria {
  isPaging?: boolean;
  searchCriteria?: string;
  isWarehouse?: boolean;
  module?: string;
  page?: number;
  size?: number;
}
export class StoreLockSaleRequest {
  storeNo: string;
  version: number;
  saleStatus: SaleStatusEnum;
}

export class StoreLockSaleResponse {
  storeNo?: string;
  merchantNo?: string;
  status: string;
  lockSaleSuccess: boolean;
}

export enum StoreTabs {
  STORE_PROFILE = 'STORE_PROFILE',
  STORE_DEVICE = 'STORE_DEVICE'
}
