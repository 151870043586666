<nav id="sidebar" class="sidebar">
  <div class="sidebar-content">
    <perfect-scrollbar>
      <ng-container *ngFor="let item of menuGroup">
        <div class="section-title" *ngIf="item.title && getMenuByGroup(item.group).length > 0">
          <span>{{ item.title }}</span>
        </div>
        <div class="sidebar-menu">
          <ul id="side-menu">
            <li
              *ngFor="let menu of getMenuByGroup(item.group)"
              [ngClass]="{
                active: menu.active,
                'sidebar-dropdown': menu.type === 'dropdown',
                'header-menu': menu.type === 'header',
                'active-link': isMenuActive(menu.link)
              }"
              [class]="menu.title.toLocaleLowerCase()"
            >
              <span *ngIf="menu.type === 'header'">{{ 'MENU.' + menu.title | translate }}</span>
              <a
                *ngIf="menu.type === 'simple'"
                (click)="toggle(menu)"
                routerLink="{{ menu.link }}"
                [routerLink]="[menu.link]"
              >
                <em class="{{ menu.icon }}"></em>
                <span>{{ 'MENU.' + menu.title | translate }}</span>
              </a>
              <a *ngIf="menu.type === 'dropdown'" (click)="toggle(menu)">
                <em class="{{ menu.icon }}"></em>
                <span>{{ 'MENU.' + menu.title | translate }}</span>
              </a>
              <div *ngIf="menu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(menu)">
                <ul id="side-submenu">
                  <li
                    *ngFor="let submenu of menu.submenus"
                    [ngClass]="{ 'active-link': isSubmenuActive(submenu) }"
                    [routerLinkActive]="'active-link-lazy'"
                  >
                    <a
                      [routerLink]="submenu.link.includes(router.url) ? null : [menu.link, submenu.link]"
                      (click)="update(menu)"
                    >
                      {{ 'MENU.' + submenu.title | translate }}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </ng-container>
    </perfect-scrollbar>
    <app-version-tag class="footer"></app-version-tag>
  </div>
</nav>
