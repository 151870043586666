import { Action } from '@ngrx/store';

import { AuditLog } from '../../models';
import { OrderContent, OrderListResponse, OrderSearchCriteria } from '../../models/order.model';

export enum OrderActionTypes {
  OrderListSearchRequested = '[Order List Page] Order List Search Requested',
  OrderListSearchLoaded = '[Order List API] Order List Search Loaded',
  OrderViewRequested = '[Order View Page] Order View Requested',
  OrderViewLoaded = '[Order API] Order View Loaded',
  OrderReleaseOrderRequested = '[Order API] Order Release Order Requested',
  OrderReleaseOrderRequestedError = '[Order API] Order Release Order Requested Error',
  OrderHistoryRequested = '[Order API] Order Release Order History Requested',
  OrderHistoryLoaded = '[Order API] Order Release Order History Loaded'
}

export class OrderListSearchRequested implements Action {
  readonly type = OrderActionTypes.OrderListSearchRequested;
  constructor(public payload: OrderSearchCriteria, public isGroupOrder: boolean) {}
}

export class OrderListSearchLoaded implements Action {
  readonly type = OrderActionTypes.OrderListSearchLoaded;
  constructor(public payload: { orders: OrderListResponse }) {}
}

export class OrderViewRequested implements Action {
  readonly type = OrderActionTypes.OrderViewRequested;
  constructor(public payload: string) {}
}

export class OrderViewLoaded implements Action {
  readonly type = OrderActionTypes.OrderViewLoaded;
  constructor(public payload: OrderContent) {}
}

export class OrderReleaseOrderRequested implements Action {
  readonly type = OrderActionTypes.OrderReleaseOrderRequested;
  constructor(public payload: { orderNo: string; reason: string; version: number }) {}
}

export class OrderReleaseOrderRequestedError implements Action {
  readonly type = OrderActionTypes.OrderReleaseOrderRequestedError;
  constructor(public payload: any) {}
}

export class OrderHistoryRequested implements Action {
  readonly type = OrderActionTypes.OrderHistoryRequested;
  constructor(public orderNo: string) {}
}

export class OrderHistoryLoaded implements Action {
  readonly type = OrderActionTypes.OrderHistoryLoaded;
  constructor(public payload: AuditLog[]) {}
}

export type OrderActions =
  | OrderListSearchRequested
  | OrderListSearchLoaded
  | OrderViewRequested
  | OrderViewLoaded
  | OrderReleaseOrderRequested
  | OrderReleaseOrderRequestedError
  | OrderHistoryRequested
  | OrderHistoryLoaded;
