<div [formGroup]="parentForm">
  <div class="row" formGroupName="promotionStores">
    <div class="col-md-3">
      <label for="selectStore">Store<span class="text-danger">*</span></label>
      <ng-select
        id="selectStore"
        placeholder="Please select..."
        [items]="storeList"
        [searchable]="false"
        [clearable]="false"
        [readonly]="!this.canEdit"
        class=" mr-sm-3"
        bindLabel="label"
        bindValue="value"
        [formControlName]="getFormParams.selectStore"
        (change)="onChangeStoreList($event)"
        [ngClass]="{
          'is-invalid': submitted && form.controls.selectStore.errors
        }"
      >
      </ng-select>
      <div *ngIf="submitted && form.controls.selectStore.errors" class="invalid-feedback">
        <div *ngIf="form.controls.selectStore.errors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-end">
      <div class="mr-2">
        <app-import-export-button
          [buttons]="buttons"
          *ngIf="isSelectedByStores"
          (export)="checkExistingItemExport()"
          (import)="checkExistingItem()"
        ></app-import-export-button>
      </div>
      <a class="btn-text-link " *ngIf="isSelectedByStores && canEdit" (click)="openSelectByStore()">+Select Store</a>
    </div>

    <!-- <div class="col-md-3 box-select-store d-inline-block">
    <div class="">
    <app-import-export-button
    style="padding-top: 25px"
    [buttons]="buttons"
    (import)="true"
    ></app-import-export-button>
    </div>
    <a *ngIf="isSelectedByStores && canEdit" class="btn-text-link " (click)="openSelectByStore()">+Select Store</a>
    </div> -->

    <div class="col-md-12 mt-3">
      <table *ngIf="formStores.controls.length" class="table table-striped table-bordered w-100" aria-hidden="true">
        <thead>
          <tr>
            <th class="text-center w-5" scope="col">{{ headerRow[0] }}</th>
            <th class="text-center w-20" scope="col">{{ headerRow[1] }}</th>
            <th class="text-center w-20" scope="col">{{ headerRow[2] }}</th>
            <th class="text-center w-10" scope="col">{{ headerRow[3] }}</th>
            <th class="text-center w-10" scope="col">{{ headerRow[4] }}</th>
            <th class="text-center w-10" scope="col">{{ headerRow[5] }}</th>
          </tr>
        </thead>
        <tbody [formArrayName]="getFormParams.stores">
          <tr *ngFor="let store of paging.currentPageData; let i = index" [formGroupName]="i">
            <td class="text-center align-top">{{ i + 1 + (itemPage - 1) * pageSize }}</td>
            <td class="align-top">
              {{ store.get('code').value }}
            </td>
            <td class="align-top">
              {{ store.get('name').value }}
            </td>
            <td class="align-top">
              {{ 'STORE_TYPE.' + store.get('merchantType').value | translate }}
            </td>
            <td class="align-top">
              {{ store.get('region').value | masterDataDisplay: masterDataEnum.REGION | async }}
            </td>
            <td class="align-top">
              {{ store.get('state').value | masterDataDisplay: masterDataEnum.STATE | async }}
            </td>
          </tr>
        </tbody>
      </table>
      <app-ui-pagination
        #paging
        [(currentPage)]="itemPage"
        [(pageSize)]="pageSize"
        [items]="formStores.controls"
        [valueChangeDetector]="formStores.valueChanges | async"
      >
      </app-ui-pagination>
    </div>
  </div>
</div>
