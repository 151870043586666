import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { MerchantList, MerchantListSearchCriteria, MerchantViewResponse } from '@shared/models';
import { BaseState } from '@shared/store/state/base.state';

import { MerchantActionType, MerchantActions } from './merchant.actions';

export interface MerchantState extends EntityState<MerchantList>, BaseState {
  selected: MerchantViewResponse;
  criteriaObject: MerchantListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<MerchantList> = createEntityAdapter<MerchantList>();

export const initialMerchantResponseState: MerchantState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null
});

export function merchantReducers(state = initialMerchantResponseState, action: MerchantActions): MerchantState {
  switch (action.type) {
    case MerchantActionType.MERCHANT_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case MerchantActionType.MERCHANT_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case MerchantActionType.MERCHANT_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload.merchantView
      };
    case MerchantActionType.MERCHANT_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case MerchantActionType.MERCHANT_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
