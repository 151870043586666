export enum PurchaseOrderStatusEnum {
  AWAITING_SEND_PO = 'AWAITING_SEND_PO',
  AWAITING_SUPPLIER_RESPONSE = 'AWAITING_SUPPLIER_RESPONSE',
  AWAITING_GR = 'AWAITING_GR',
  GR_COMPLETED = 'GR_COMPLETED',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  AWAITING_CLOSED = 'AWAITING_CLOSED',
  AWAITING_CANCELLED = 'AWAITING_CANCELLED'
}

export enum PurchaseOrderItemStatusEnum {
  AWAITING_RESPONSE = 'AWAITING_RESPONSE',
  CONFIRMED = 'CONFIRMED',
  CANCELLED_BY_CJ = 'CANCELLED_BY_CJ',
  CANCELLED_BY_TD = 'CANCELLED_BY_TD',
  COMPLETED = 'COMPLETED',
  CANCELLED_BY_SYSTEM = 'CANCELLED_BY_SYSTEM'
}

export enum PurchaseOrderModeEnum {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  CREATE = 'CREATE'
}

export enum ShipToType {
  STORE = 'STORE',
  WAREHOUSE = 'WAREHOUSE'
}
