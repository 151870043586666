import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import {
  OrderContent,
  OrderExportCriteria,
  OrderListResponse,
  OrderPrintCriteria,
  OrderSearchCriteria
} from '../models/order.model';
import { AwaitingFirstLotExportCriteria } from '../models/report.model';
import { metricsReporting } from '../utils/metrics-util';
import { BaseService, CustomEncoder } from './base.service';

@Injectable()
export class OrderService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.order;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteria(criteria: OrderSearchCriteria, isGroupOrder: boolean): Observable<OrderListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ORDER,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams({ ...criteria, groupOrder: isGroupOrder });
    return this.http.get<any>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  getOrderRequest(orderNo: string): Observable<OrderContent> {
    const encoder = new CustomEncoder();
    const url = this.getFullUrl(this.envService.view, { orderNo: encoder.encodeKey(orderNo) });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  releaseOrderRequested(requested: any): Observable<OrderContent> {
    const url = this.getFullUrl(this.envService.release);
    return this.http.put<any>(url, requested, {
      headers: this.loaderHeaders()
    });
  }

  printPdfOrder(criteria: OrderPrintCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.print, { ...criteria });
    const body = new HttpParams();

    return this.http.post<any>(url, body, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public exportOrder(criteria: OrderExportCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.export);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.ORDER,
          path: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ORDER,
          route: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportAwaitingFirstLot(criteria: AwaitingFirstLotExportCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.exportAwaitingFirstLot);
    const metrics = metricsReporting([this.envService.url, this.envService.exportAwaitingFirstLot]);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  getHistoryLogs(orderNo: string): Observable<any> {
    const encoder = new CustomEncoder();
    const url = this.getFullUrl(this.envService.history, { orderNo: encoder.encodeKey(orderNo) });
    return this.http
      .get<any>(url, { headers: this.headers, observe: 'body' })
      .pipe(map(result => result.auditLogs));
  }
}
