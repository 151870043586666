<div id="monthly-profit-sharing" data-id="monthly-profit-sharing" class="admin-form mt-2">
  <ng-container *ngIf="(listState$ | async)?.totalElements > 0">
    <div class="td-card" *ngFor="let monthlyProfitSharing of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 flex-item d-flex flex-column cursor-pointer">
          <div class="px-3">
            <div class="row">
              <span>
                <label data-id="period" id="period" class="chk-container">{{
                  monthlyProfitSharing.scheduleDisplay
                }}</label>
                <div
                  *ngIf="['ADDITIONAL', 'MANUAL'].includes(monthlyProfitSharing.scheduleType)"
                  class="info-badge-label font-weight-bold mb-3"
                >
                  Add
                </div>
              </span>
              <span
                [ngClass]="getColorStatus(monthlyProfitSharing.status)"
                class="card-status monthly-partner-profit-sharing-status ml-auto"
              >
                {{ 'MONTHLY_PARTNER_PROFIT_SHARING.STATUS.' + monthlyProfitSharing.status | translate }}</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="lb-period">Period:</span>
              <span class="result-value" id="txt-period" data-id="txt-period">{{ monthlyProfitSharing.period }}</span>
            </div>

            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="lb-no-of-store">No. of Store:</span>
              <span class="result-value" id="txt-no-of-store" data-id="txt-no-of-store">{{
                monthlyProfitSharing.noOfStore | dashDisplay
              }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="lb-create-by">Created By:</span>
              <span class="result-value" id="txt-create-by" data-id="txt-create-by">{{
                monthlyPartnerProfitSharingEnum.AWAITING === monthlyProfitSharing.status
                  ? '(None)'
                  : monthlyProfitSharing.lastModifiedByName
              }}</span>
            </div>

            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="product-lb-catalog">Created Date:</span>
              <span class="result-value" id="product-txt-catalog" data-id="product-txt-catalog">{{
                monthlyPartnerProfitSharingEnum.AWAITING === monthlyProfitSharing.status
                  ? '(None)'
                  : (monthlyProfitSharing.lastModifiedDate | dateDisplay)
              }}</span>
            </div>
          </div>
        </div>

        <div class="col-0 col-md-2 action-box d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter bottom-button"
            data-id="btn-submit-data"
            type="button"
            *ngIf="
              monthlyPartnerProfitSharingEnum.AWAITING === monthlyProfitSharing.status &&
              permission.hasManageProfitSharing
            "
            (click)="showMonthlyPartnerProfitSharingDownloadModal(monthlyProfitSharing)"
          >
            <em class="icon-reserved"></em>
            Submit Data
          </button>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <ng-container *ngIf="(listState$ | async)?.totalElements === 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/no-data.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>
</div>
