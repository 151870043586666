import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MyReportDownloadCriteria, MyReportListResponse, MyReportSearchCriteria } from '../models/my-report.model';
import { BaseService } from './base.service';

@Injectable()
export class MyReportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.report;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchMyReport(criteria: MyReportSearchCriteria): Observable<MyReportListResponse> {
    const url = this.getFullUrl(this.envService.myReport);
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  onDownloadReport(criteria: MyReportDownloadCriteria) {
    const url = this.getFullUrl(this.envService.downloadReport, { reportNo: criteria.reportNo }, true);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
}
