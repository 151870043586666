import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject, Subscription, combineLatest, concat, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import { MerchantRequestService } from '@shared/services/merchant-request.service';

import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { emailValidator } from '../../../shared/custom-validators/email-validator';
import { ClientIdTypeEnum } from '../../../shared/enum/client-id.enum';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ResetPasswordTypeEnum } from '../../../shared/enum/reset-password-type.enum';
import { StoreType } from '../../../shared/enum/store.enum';
import { UserPageModes, UserStatusEnum } from '../../../shared/enum/user.enum';
import {
  GraphqlQueryObject,
  GraphqlQuerySortOptions,
  OrderByEnum,
  OrderDirectionEnum
} from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import {
  ChangeStatusUserRequest,
  Department,
  RoleViewResponse,
  StoreGroupStoreUser,
  StoreGroupUser,
  UserDto,
  UserSearchCriteria,
  UserViewResponse
} from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { gender } from '../../../shared/models/list-value/list-key-value.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { RoleService } from '../../../shared/services/role.service';
import { StoreRequestService } from '../../../shared/services/store-request.service';
import {
  ActivateUserRequestAction,
  ActivateUserResetAction,
  UserByUserNoRequestAction,
  UserByUserNoResetAction,
  UserCreateResponseResetAction,
  UserListRequestAction,
  UserResetPasswordRequestAction,
  UserResetPasswordResponseResetAction,
  UserUpdateRequestAction,
  UserUpdateResponseResetAction,
  UsersResetPinCodeRequestAction
} from '../../../shared/store/actions/user-info.action';
import {
  selectResetPasswordResult,
  selectResetPinResult
} from '../../../shared/store/selectors/reset-password-pin.selector';
import {
  selectChangeStatusUsersByUsernameResult,
  selectUserCreateResponseError,
  selectUsersByNo,
  selectUsersListCriteria
} from '../../../shared/store/selectors/user-selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { RoleAllRequestAction, RoleAllResetAction } from '../../roles/stores/role/role.actions';
import { selectRoleAllList } from '../../roles/stores/role/role.selectors';
import { StoreGroupService } from '../../store-group/services/store-group.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: UserPageModes;
    userNo: string;
  };

  public userForm: UntypedFormGroup;
  public localStore: Observable<any>;
  public merchantList: Observable<any[]>;
  public listStore$: Observable<StoreGroupUser[]>;
  public merchantSearchInput$ = new Subject<string>();
  public userSelectedSubscription: Subscription;
  public merchantSearchLoading: boolean;
  public isFormDirty: boolean;
  public listOfValue: {};
  public submitted: boolean;
  public bsConfig: BsDatepickerConfig;
  public roleList: RoleViewResponse[];
  public departmentList: Array<Department>;
  public hasStoreAuthority: boolean;
  public storeGroupList: StoreGroupUser[];
  public selectedAllStoreInMerchant: boolean;
  public selectedStoreGroup: string[];
  public selectedStore: string[];
  public isSendToSubmit: boolean;
  public criteriaObject: UserSearchCriteria;
  public duplicatePhoneNo: string;
  public userView: UserViewResponse;
  public status: string;
  public hasViewPermission = false;
  public hasManagePermission = false;
  public roleStoreOwner = ['STORE_OWNER', 'STORE_OWNER_PARTNER'];
  public isStoreOwner = false;
  public dateFormat = environment.dateFormat;
  public accessStoreAuthority: Array<ClientIdTypeEnum>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService,
    protected readonly fb: UntypedFormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected storeRequestService: StoreRequestService,
    protected storeGroupService: StoreGroupService,
    protected masterService: MasterService,
    protected roleService: RoleService,
    protected authGuardService: AuthGuardService,
    protected merchantRequestService: MerchantRequestService
  ) {
    super(store, modalService, false);
    this.accessStoreAuthority = [
      ClientIdTypeEnum.STORE_PORTAL,
      ClientIdTypeEnum.POS_APPL,
      ClientIdTypeEnum.TD_HAND_APPL,
      ClientIdTypeEnum.CJX_POS_APPL,
      ClientIdTypeEnum.CJX_HAND_APPL,
      ClientIdTypeEnum.CJX_POS_MANAGER_APPL
    ];
    this.hasStoreAuthority = false;
    this.storeGroupList = [];
    this.selectedAllStoreInMerchant = false;
    this.selectedStoreGroup = [];
    this.selectedStore = [];
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.hasViewPermission = this.authGuardService.checkPermission(['user_bo_v']);
    this.hasManagePermission = this.authGuardService.checkPermission(['user_bo_m']);
  }

  ngOnInit() {
    this.initControl();
    this.initData();
    this.initState();
  }

  initControl() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    const initialNull = [{ value: null, disabled: false }];
    const initialNullDisable = [{ value: null, disabled: true }];
    this.userForm = this.fb.group({
      firstName: initialNullRequired,
      lastName: initialNullRequired,
      localFirstName: initialNullRequired,
      localLastName: initialNullRequired,
      birthDate: initialNull,
      gender: initialNull,
      countryCode: initialNull,
      mobileNo: initialNullRequired,
      email: [{ value: null, disabled: false }, [emailValidator()]],
      merchant: [
        { value: null, disabled: false },
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ],
      employeeCode: initialNullDisable,
      department: initialNullDisable,
      roles: this.fb.array([], this.duplicatedValidator)
    });

    this.addRole();
  }

  initData() {
    this.store.dispatch(new RoleAllRequestAction());
    this.store.dispatch(new UserByUserNoRequestAction(this.data.userNo));

    const departmentQuery = new GraphqlQueryObject();
    departmentQuery.name = MasterDataEnum.DEPARTMENTS;
    departmentQuery.fields = ['code', 'id', 'nameEn', 'nameTh'];
    departmentQuery.active = true;
    departmentQuery.sort = {
      orderBy: OrderByEnum.NAME_EN,
      orderDirection: OrderDirectionEnum.ASC
    } as GraphqlQuerySortOptions;
    this.masterService.getMasterDataByNames([departmentQuery]).subscribe(result => {
      if (result.data) {
        this.departmentList = result.data.departments;
      }
    });
    this.localStore
      .pipe(select(selectUsersListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));

    this.localStore
      .pipe(
        select(selectResetPasswordResult),
        filter(result => Boolean(result) && Boolean(result.statusCode)),
        tap(result => this.resetPasswordEvent(result.statusCode, ResetPasswordTypeEnum.PASSWORD))
      )
      .subscribe();

    this.localStore
      .pipe(
        select(selectChangeStatusUsersByUsernameResult),
        filter(result => Boolean(result) && Boolean(result.statusCode)),
        tap(result => {
          if (result.statusCode === '200') {
            this.store.dispatch(new UserByUserNoRequestAction(this.data.userNo));
          }
          this.alertChangeStatusUser(result.statusCode);
        })
      )
      .subscribe();

    this.localStore
      .pipe(
        select(selectResetPinResult),
        filter(result => Boolean(result) && Boolean(result.statusCode)),
        tap(result => this.resetPasswordEvent(result.statusCode, ResetPasswordTypeEnum.PIN))
      )
      .subscribe();

    this.userSelectedSubscription = combineLatest([
      this.store.select(selectRoleAllList),
      this.store.select(selectUsersByNo)
    ]).subscribe(([roles, userResponse]) => {
      if (roles && userResponse) {
        this.userView = userResponse;
        this.status = userResponse.active ? UserStatusEnum.ACTIVE : UserStatusEnum.INACTIVE;
        this.setInitialValue(userResponse, roles);

        if (this.data.mode === UserPageModes.EDIT) {
          this.toggleToEditMode();
        }
      }
    });
  }

  initState() {
    this.bsConfig = ({
      dateInputFormat: this.dateFormat,
      maxDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue',
      useUtc: false
    } as unknown) as BsDatepickerConfig;

    this.listOfValue = {
      gender
    };

    this.userForm.controls['merchant'].valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(data => {
          if (!Boolean(data)) {
            this.listStore$ = null;
          }
        }),
        filter(data => Boolean(data))
      )
      .subscribe(merchantValue => {
        if (merchantValue.merchantType === StoreType.STORE_MODEL) {
          this.userForm.controls['employeeCode'].enable();
          this.userForm.controls['department'].enable();
          this.userForm.controls['employeeCode'].setValidators(Validators.required);
          this.userForm.controls['employeeCode'].updateValueAndValidity();
          this.userForm.controls['department'].setValidators([Validators.required, this.departmentValidator]);
          this.userForm.controls['department'].updateValueAndValidity();
        } else {
          if (this.isCreateMode) {
            this.userForm.controls['employeeCode'].setValue(null);
            this.userForm.controls['department'].setValue(null);
          }
          this.userForm.controls['employeeCode'].disable();
          this.userForm.controls['department'].disable();
          this.userForm.controls['employeeCode'].clearValidators();
        }
        if (this.isCreateMode) {
          this.loadStoreGroup(merchantValue.merchant);
        }
      });

    this.localStore.pipe(select(selectUserCreateResponseError)).subscribe(error => {
      if (error && error.code) {
        switch (error.code) {
          case '02003':
            const message = this.translate.instant('ERROR_CODE.' + error.code);
            this.showAlert('Failed', message);
            break;
          case '02008':
            const PhoneNo = this.userForm.get('mobileNo').value;
            this.duplicatePhoneNo = PhoneNo.number;
            this.userForm.get('mobileNo').setValidators(this.isMobileNoDuplicatedValidator);
            this.userForm.get('mobileNo').updateValueAndValidity();
            break;
          case '02010':
            this.userForm.get('email').setValidators(Validators.email);
            this.userForm.get('email').updateValueAndValidity();
            break;
          default:
            this.showAlert('Failed', error.message);
            break;
        }
      }
    });
  }

  onChangeRole() {
    const items = this.rolesFormArray.getRawValue();

    this.hasStoreAuthority = items
      .filter(item => Boolean(item.role))
      .some(item => {
        const roleApps = item.role.applications;
        if (roleApps && roleApps.length) {
          return roleApps.some(app => this.accessStoreAuthority.includes(ClientIdTypeEnum[app]));
        } else {
          return false;
        }
      });

    this.validateRole();
  }

  setInitialValue(userView: UserViewResponse, roles: RoleViewResponse[]) {
    if (userView === null) {
      return;
    }

    this.selectedAllStoreInMerchant = userView.accessAllStores;
    this.selectedStore.push(...userView.accessStores);
    this.selectedStoreGroup.push(...userView.accessStoreGroups);
    this.loadStoreGroup(userView.merchant.merchantNo);

    this.userForm.controls['firstName'].setValue(userView.firstName);
    this.userForm.controls['lastName'].setValue(userView.lastName);
    this.userForm.controls['localFirstName'].setValue(userView.localFirstName);
    this.userForm.controls['localLastName'].setValue(userView.localLastName);
    this.userForm.controls['birthDate'].setValue(userView.birthDate);
    this.userForm.controls['gender'].setValue(userView.gender);

    const mobilePhone = userView.countryCode && userView.mobileNo ? userView.countryCode + userView.mobileNo : null;

    this.userForm.controls['mobileNo'].setValue(mobilePhone);
    this.userForm.controls['countryCode'].setValue(userView.countryCode);

    this.userForm.controls['email'].setValue(userView.email);
    this.userForm.controls['employeeCode'].setValue(userView.employeeCode);
    this.userForm.controls['department'].setValue(userView.department);
    this.userForm.controls['department'].setValidators([Validators.required, this.departmentValidator]);
    this.userForm.controls['merchant'].setValue(userView.merchant.merchantName);

    if (userView.roles.some(v => this.roleStoreOwner.indexOf(v) > -1)) {
      this.roleList = roles.filter(v => this.roleStoreOwner.indexOf(v.code) > -1);
      this.isStoreOwner = true;
    } else {
      this.roleList = roles.filter(v => this.roleStoreOwner.indexOf(v.code) === -1);
    }

    if (userView.roles.length) {
      this.rolesFormArray.clear();
      userView.roles.forEach(role => {
        const roleData = this.roleList.find((roleValue: RoleViewResponse) => roleValue.code === role);

        if (!roleData) {
          return;
        }
        this.rolesFormArray.push(this.createFormRole(roleData));

        const isAuthority = roleData.applications
          .filter(appList => appList.length)
          .some(app => this.accessStoreAuthority.includes(ClientIdTypeEnum[app]));

        if (!this.hasStoreAuthority && isAuthority) {
          this.hasStoreAuthority = true;
        }
      });
    }

    if (this.isViewMode) {
      this.userForm.disable();
    }
  }

  loadMerchant(initialTerm: string) {
    this.merchantList = concat(
      of([]),
      this.merchantSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.merchantSearchLoading = true)),
        switchMap(term =>
          this.storeRequestService.searchMerchantByName({ searchCriteria: term }, 20).pipe(
            catchError(() => of([])),
            tap(() => {
              this.merchantSearchLoading = false;
            })
          )
        )
      )
    );
  }

  loadStoreGroup(merchantNo: string) {
    if (merchantNo == null) {
      return;
    }

    this.listStore$ = this.storeGroupService.getStoreGroupsByMerchant(merchantNo).pipe(
      map(storeGroupList => {
        storeGroupList.forEach(storeGroup => {
          const selectGroup = this.selectedStoreGroup.includes(storeGroup.no) && !this.selectedAllStoreInMerchant;
          storeGroup.selected = selectGroup;
          storeGroup.stores.forEach(store => {
            if (!selectGroup) {
              store.selected = this.selectedStore.includes(store.no) && !this.selectedAllStoreInMerchant;
            }
          });
        });
        return storeGroupList;
      }),
      catchError(() => of([]))
    );
  }

  onExit() {
    this.isFormDirty = this.userForm.touched || this.isFormDirty;
    if (this.isFormDirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
    }
  }

  onSubmit() {
    this.submitted = true;
    this.validateRole();
    if (this.userForm.invalid || !this.validateStore) {
      if (!this.validateStore) {
        this.showAlert('Failed', 'Please select at least 1 store.');
      }
      return;
    }

    const data = this.prepareData();
    this.sendToSubmit(data);
  }

  validateRole() {
    const roles = this.rolesFormArray.getRawValue();

    for (let i = 0; i < roles.length; i++) {
      if (roles[i].role) {
        this.rolesFormArray
          .at(0)
          .get('role')
          .setErrors(null);
        break;
      } else {
        this.rolesFormArray
          .at(0)
          .get('role')
          .setErrors({ required: true });
      }
    }

    this.rolesFormArray.updateValueAndValidity({ onlySelf: false });
    this.userForm.updateValueAndValidity({ onlySelf: false });
  }

  get validateStore(): boolean {
    return !this.hasStoreAuthority || this.checkSelectedStore;
  }

  get checkSelectedStore(): boolean {
    return this.selectedAllStoreInMerchant || this.selectedStoreGroup.length > 0 || this.selectedStore.length > 0;
  }

  sendToSubmit(data: UserDto) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.isSendToSubmit = true;
          this.store.dispatch(new UserUpdateRequestAction(data));
        }
      });
  }

  prepareData(): UserDto {
    const rawData = this.userForm.getRawValue();
    const dataRequest = {
      ...this.userView,
      ...rawData,
      birthDate:
        rawData.birthDate !== null ? moment(rawData.birthDate, this.dateFormat).format(this.dateFormat) : null,
      countryCode: rawData.mobileNo && rawData.mobileNo.internationalNumber.split(' ')[0],
      mobileNo: rawData.mobileNo && rawData.mobileNo.nationalNumber.replace(/ /g, ''),
      accessAllStores: this.hasStoreAuthority ? this.selectedAllStoreInMerchant : false,
      accessStoreGroups: this.hasStoreAuthority ? this.selectedStoreGroup : [],
      accessStores: this.hasStoreAuthority ? this.selectedStore : [],
      roles: this.rolesFormArray
        .getRawValue()
        .filter(item => item.role !== null)
        .map(item => item.role.code)
    } as UserDto;

    delete dataRequest['merchant'];
    return dataRequest;
  }

  get rolesFormArray() {
    return this.userForm.get('roles') as UntypedFormArray;
  }

  addRole() {
    if (this.rolesFormArray.length === 20) {
      return;
    }
    this.rolesFormArray.push(this.createFormRole());
  }

  createFormRole(data: any = null): UntypedFormGroup {
    return this.fb.group({
      role: [{ value: data, disabled: false }]
    });
  }

  onChangeAll(event: any, storeGroupList: StoreGroupUser[]) {
    if (event.target.checked) {
      this.selectedAllStoreInMerchant = true;
      this.selectedStoreGroup = [];
      this.selectedStore = [];
      storeGroupList.forEach((storeGroup: StoreGroupUser) => this.clearSelectedStoreAll(storeGroup));
    } else {
      this.selectedAllStoreInMerchant = false;
      storeGroupList.forEach((storeGroup: StoreGroupUser) => this.selectedEnableStoreAll(storeGroup));
    }
  }

  onCheckboxChangeGroup(event: any, storeGroup: StoreGroupUser) {
    if (event.target.checked) {
      this.selectStoreGroup(storeGroup);
    } else {
      this.deSelectStoreGroup(storeGroup);
    }
  }

  onCheckboxChange(event: any, store: StoreGroupStoreUser) {
    if (event.target.checked) {
      store.selected = true;
      this.selectedStore.push(store.no);
    } else {
      store.selected = false;
      this.removeSelectedStore(store.no);
    }
  }

  selectStoreGroup(storeGroupUser: StoreGroupUser) {
    storeGroupUser.selected = true;
    this.selectedStoreGroup.push(storeGroupUser.no);
    storeGroupUser.stores.forEach((store: StoreGroupStoreUser) => {
      store.selected = false;
      store.disabled = true;
      this.removeSelectedStore(store.no);
    });
  }

  deSelectStoreGroup(storeGroupUser: StoreGroupUser) {
    storeGroupUser.selected = false;
    this.removeSelectedStoreGroup(storeGroupUser.no);
    storeGroupUser.stores.forEach((store: StoreGroupStoreUser) => {
      store.disabled = false;
    });
  }

  clearSelectedStoreAll(storeGroupUser: StoreGroupUser) {
    storeGroupUser.selected = false;
    storeGroupUser.disabled = true;
    storeGroupUser.stores.forEach((store: StoreGroupStoreUser) => {
      store.selected = false;
      store.disabled = true;
    });
  }

  selectedEnableStoreAll(storeGroupUser: StoreGroupUser) {
    storeGroupUser.disabled = false;
    storeGroupUser.stores.forEach((store: StoreGroupStoreUser) => {
      store.disabled = false;
    });
  }

  removeSelectedStoreGroup(code: string) {
    this.selectedStoreGroup.splice(
      this.selectedStoreGroup.findIndex(itemSelected => itemSelected === code),
      1
    );
  }

  removeSelectedStore(code: string) {
    this.selectedStore.splice(
      this.selectedStore.findIndex(itemSelected => itemSelected === code),
      1
    );
  }

  showHistory() {
    const initialState = {
      title: 'History',
      action: HistoryType.REQUEST,
      historyHeader: `User ID: ${this.userView.no}`,
      historyType: HistoryType.USER,
      auditLogs: this.userView.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  toggleToEditMode() {
    const isRoleStoreOwner = this.userView.roles?.find(role => {
      return this.roleStoreOwner.includes(role);
    });

    if (isRoleStoreOwner) {
      this.merchantRequestService.getMerchantPending(this.userView.merchant.merchantNo).subscribe({
        next: () => {
          this.changeToEditMode();
        },
        error: _ => {
          const initialState = {
            title: 'Failed',
            message:
              'Not allow to edit this user.<br/> Merchant Request associated with this user has been edited and waiting for approval.'
          };
          const modalRef = this.modalService.show(AlertModalComponent, { initialState });
          modalRef.content.action
            .pipe(
              filter(result => result === ModalButtonResponseEnum.OK),
              take(1)
            )
            .subscribe({
              next: () => {
                modalRef.hide();
              }
            });
        }
      });
    } else {
      this.changeToEditMode();
    }
  }

  changeToEditMode() {
    this.data.mode = UserPageModes.EDIT;
    this.data.title = 'Edit User';

    this.userForm.enable();
    this.userForm.controls['merchant'].disable();

    if (this.isStoreOwner) {
      this.rolesFormArray
        .at(0)
        .get('role')
        .disable();
    }

    if (this.userView.merchant !== null && this.userView.merchant.merchantType === StoreType.STORE_MODEL) {
      this.userForm.controls['employeeCode'].enable();
      this.userForm.controls['department'].enable();
    } else {
      this.userForm.controls['employeeCode'].disable();
      this.userForm.controls['department'].disable();
    }
  }

  showAlert(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  get isViewMode(): boolean {
    return [UserPageModes.VIEW].includes(this.data.mode);
  }

  get isCreateMode(): boolean {
    return [UserPageModes.CREATE].includes(this.data.mode);
  }

  get isEditMode(): boolean {
    return [UserPageModes.EDIT].includes(this.data.mode);
  }

  isPhoneError(control: AbstractControl): string {
    return this.submitted && control.errors ? 'is-invalid' : '';
  }

  get isMobileNoDuplicatedValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value.number === this.duplicatePhoneNo ? { isDuplicatedMobile: true } : null;
      }
      return null;
    };
  }

  get duplicatedValidator(): ValidatorFn {
    return (fa: UntypedFormArray) => {
      const array = fa.getRawValue();
      const availableItems = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i].role !== null && array[i].role.code) {
          const isDuplicated = availableItems.indexOf(array[i].role.code) > -1;
          if (!isDuplicated) {
            availableItems.push(array[i].role.code);
          }
          if (!fa.controls[i].get('role').errors || fa.controls[i].get('role').getError('duplicated')) {
            fa.controls[i].get('role').setErrors(isDuplicated && { duplicated: true });
          }
        }
      }
      return null;
    };
  }

  get departmentValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const isValidDepartment = this.departmentList.some(
        d => d.code === control.value.code && d.nameTh === control.value.nameTh
      );
      return !isValidDepartment ? { isInvalidDepartment: true } : null;
    };
  }

  handleResetPassword() {
    if (this.userView === null) {
      return;
    }

    const initialState = {
      title: this.translate.instant('USER.RESET_PASSWORD'),
      message: this.translate.instant('USER.ARE_YOU_SURE_YOU_WANT_TO_RESET_PASSWORD', {
        fullName: `${this.userView.firstName} ${this.userView.lastName}`
      }),
      okText: this.translate.instant('OK')
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new UserResetPasswordRequestAction(this.userView.userName));
        }
      });
  }

  handleResetPinCode() {
    const initialState = {
      title: this.translate.instant('USER.RESET_PIN_CODE'),
      message: this.translate.instant('USER.ARE_YOU_SURE_YOU_WANT_TO_RESET_PIN_CODE', {
        fullName: `${this.userView.firstName} ${this.userView.lastName}`
      }),
      okText: this.translate.instant('OK')
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new UsersResetPinCodeRequestAction({ userName: this.userView.userName }));
        }
      });
  }

  handleChangeStatusUser() {
    if (!this.userView) {
      return;
    }
    const initialState = {
      title: this.translate.instant('USER.CONFIRM'),
      message: this.translate.instant('USER.ARE_YOU_SURE_YOU_WANT_TO_ACTIVATE', {
        activate: this.userView.active ? `deactivate` : `activate`
      }),
      okText: this.translate.instant('Submit')
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const dataRequest = {
            userName: this.userView.userName,
            status: !this.userView.active
          } as ChangeStatusUserRequest;

          this.store.dispatch(new ActivateUserRequestAction(dataRequest));
        }
      });
  }

  private resetPasswordEvent(statusCode: string, type: ResetPasswordTypeEnum) {
    if (statusCode === '200') {
      this.alertSuccessResetPasswordPin(type);
    } else {
      this.alertFailedResetPasswordPin(type);
    }
  }

  alertFailedResetPasswordPin(type: ResetPasswordTypeEnum) {
    const initialState = {
      title: this.translate.instant('USER.FAILED'),
      message:
        type === ResetPasswordTypeEnum.PASSWORD
          ? this.translate.instant('USER.PASSWORD_RESET_FAILED')
          : this.translate.instant('USER.PIN_RESET_FAILED')
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  alertSuccessResetPasswordPin(type: ResetPasswordTypeEnum) {
    const initialState = {
      title: this.translate.instant('USER.SUCCESS'),
      message:
        type === ResetPasswordTypeEnum.PASSWORD
          ? this.translate.instant('USER.PASSWORD_RESET_SUCCESS', { mobileNo: this.userView.mobileNo })
          : this.translate.instant('USER.PIN_RESET_SUCCESS', { mobileNo: this.userView.mobileNo })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  alertChangeStatusUser(statusCode: string) {
    let title: string;
    let message: string;

    if (statusCode === '200') {
      title = this.translate.instant('USER.SUCCESS');
      message = this.translate.instant('USER.CHANGE_STATUS_SUCCESS', {
        status: this.userView.active ? 'deactivated' : 'activated'
      });
    } else {
      title = this.translate.instant('USER.FAILED');
      message = this.translate.instant('USER.CHANGE_STATUS_FAILED', {
        status: this.userView.active ? 'Deactivated' : 'Activated'
      });
    }

    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  defaultGroupName(name: string, defaultGroup: boolean) {
    return defaultGroup ? this.translate.instant('STORE.STORE_GROUP.DEFAULT_GROUP') : name;
  }

  doAfterVersionAlertModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  ngOnDestroy(): void {
    if (this.data.mode === UserPageModes.CREATE && this.isSendToSubmit) {
      this.criteriaObject = {
        ...this.criteriaObject,
        page: 0
      };
    }

    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }

    if (this.userSelectedSubscription) {
      this.userSelectedSubscription.unsubscribe();
    }

    this.store.dispatch(new UserListRequestAction(this.criteriaObject));
    this.store.dispatch(new UserByUserNoResetAction());
    this.store.dispatch(new UserUpdateResponseResetAction());
    this.store.dispatch(new RoleAllResetAction());
    this.store.dispatch(new UserResetPasswordResponseResetAction());
    this.store.dispatch(new ActivateUserResetAction());
    this.store.dispatch(new UserCreateResponseResetAction());

    super.ngOnDestroy();
  }
}
