<div class="full-popup-head">
  <div class="full-popup-title flex"><em class="modal-title-icon icon-td-bill"></em>{{ data.title }}</div>
  <button
    type="button"
    class="btn btn-header-button d-none d-md-block"
    (click)="onPrintProfitSharing()"
    *ngIf="data.mode === profitSharingModeEnum.VIEW"
  >
    <em class="icon-print"></em>Print
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    data-id="showHistoryBth"
    id="showHistoryBth"
    *ngIf="data.mode === profitSharingModeEnum.VIEW"
    (click)="showHistory()"
  >
    <em class="icon-history"></em>History
  </button>

  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCloseFullModal()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="profit-sharing" class="admin-form mb-5 mb-sm-0" *ngIf="profitSharing && profitSharingForm">
  <div class="info-header-container  mb-0 border-0" *ngIf="profitSharing?.profitStatus">
    <div class="info-header-item" *ngIf="profitSharing?.docNo">
      <div class="info-header-label">
        <span>{{ 'PROFIT_SHARING.PROFIT_SHARING_NO' | translate }}</span>
      </div>
      <div class="info-header-value">
        <span>{{ profitSharing?.docNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>{{ 'PROFIT_SHARING.STATUS_HEADER' | translate }}</span>
      </div>
      <div class="info-header-value">
        <span class="profit-sharing-status" [ngClass]="getColorStatus(profitSharing?.profitStatus)">
          {{ 'PROFIT_SHARING.STATUS.' + profitSharing?.profitStatus | translate }}
        </span>
      </div>
    </div>
    <div class="toggle" *ngIf="data.mode !== profitSharingModeEnum.EDIT">
      <label>Language</label>
      <label>|</label>
      <div>
        <app-toggle (toggleStatus)="switchLanguage($event)" [labelToggle]="'TH'" [status]="langTH"></app-toggle>
      </div>
    </div>
  </div>

  <div class="row mr-0 ml-0 info-header-min-height mb-3">
    <div class="col-md-6 pl-0">
      <div class="information-container mb-0">
        <div class="row info-header-min-height ">
          <div class="col-lg-4 col-md-3 flex-column">
            <div class="info-header-label">
              <span>{{ 'PROFIT_SHARING.STORE' | translate }}</span>
            </div>
            <div class="info-header-value">{{ profitSharing?.store.code }} - {{ profitSharing?.store.name }}</div>
          </div>
          <div class="col-lg col-md-3 flex-column">
            <div class="info-header-label">
              <span>{{ 'PROFIT_SHARING.AS_OF_MONTH' | translate }}</span>
            </div>
            <div class="info-header-value">
              {{ profitSharing?.scheduleDisplay }}
            </div>
          </div>
          <div class="col-lg col-md-3 flex-column">
            <div class="info-header-label">
              <span>{{ 'PROFIT_SHARING.UPDATED_BY' | translate }}</span>
            </div>
            <div class="info-header-value">
              {{ profitSharing?.lastModifiedByName }}
            </div>
          </div>
          <div class="col-lg col-md-3 flex-column">
            <div class="info-header-label">
              <span>{{ 'PROFIT_SHARING.LAST_UPDATED' | translate }}</span>
            </div>
            <div class="info-header-value">
              {{ profitSharing?.lastModifiedDate | dateDisplay }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3 pl-0">
      <div class="information-container header-gap mx-0 row mb-0 h-100">
        <em class="header-grey-icon icon-remaining negative"></em>
        <div class="flex-column">
          <div class="info-header-label w-100">
            <span>{{ 'PROFIT_SHARING.REMAINING_BALANCE' | translate }}</span>
          </div>
          <div class="info-header-value-amount negative">
            {{ profitSharing?.remainingBalanceAmount | numberFormatDisplay }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3 pl-0">
      <div class="information-container header-gap mx-0 row pr-md-0 mb-0 h-100">
        <em class="header-red-icon icon-profit-sharing profit-sharing"></em>
        <div class="float-left">
          <div class="info-header-label w-100">
            <span>{{ 'PROFIT_SHARING.PROFIT_SHARING_HEAD' | translate }}</span>
          </div>
          <div class="info-total-amount profit-sharing">
            {{ profitSharing?.profitSharingAmount | numberFormatDisplay }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row flex-wrap" [formGroup]="profitSharingForm">
    <div class="col-12 col-sm-6 col-lg-3 pl-0">
      <div class="section-header w-100">
        <span id="gp">{{ 'PROFIT_SHARING.GROSS_PROFIT' | translate }}</span>
      </div>
      <div class="w-100">
        <table id="gp-table" class="table table-bordered table-hover w-100" aria-hidden="true">
          <thead>
            <tr>
              <th [ngClass]="{ 'extra-padding': profitSharing.templateVersion === 2 }">
                <div class="header-center">
                  <span class="float-left table-header-text">{{ 'PROFIT_SHARING.GROSS_PROFIT' | translate }}</span>
                  <span class="float-right table-header-value">{{
                    profitSharing.grossProfitIncVatAmount | numberFormatDisplay
                  }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="float-left w-60">
                  <div class="container-fluid table-icon-position row w-100">
                    <em class="table-grey-icon  icon-sales-rate"></em>
                    <div class="text-left" [innerHTML]="'PROFIT_SHARING.SALES_INC_VAT' | translate"></div>
                  </div>
                </div>
                <div class="float-right gross-profit-value">
                  {{ profitSharing.salesIncVatAmount | numberFormatDisplay }}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left w-60">
                  <div class="container-fluid pr-0 table-icon-position row w-100">
                    <em class="table-grey-icon  icon-cogs"></em>
                    <div class="text-left w-60" [innerHTML]="'PROFIT_SHARING.COGS_WSP_INC_VAT' | translate"></div>
                  </div>
                </div>
                <div class="float-right  gross-profit-value">
                  {{ profitSharing.cogsIncVatAmount > 0 ? '-' : ''
                  }}{{ profitSharing.cogsIncVatAmount | numberFormatDisplay }}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left w-60">
                  <div class="container-fluid table-icon-position  row w-100">
                    <em class="table-grey-icon icon-compensate"></em>
                    <div class="text-left w-60">{{ 'PROFIT_SHARING.COMPENSATE' | translate }}</div>
                  </div>
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="compensateAmount"
                    placeholder="0.00"
                    formControlName="compensateAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.compensateAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.compensateAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.compensateAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left w-60">
                  <div class="container-fluid pr-0 table-icon-position row w-100">
                    <em class="table-grey-icon icon-cigarette"></em>
                    <div class="text-left w-60">{{ 'PROFIT_SHARING.CIGARETTE_VAT_ADJUSTMENT' | translate }}</div>
                  </div>
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="cigaretteVatAdjustmentAmount"
                    placeholder="0.00"
                    formControlName="cigaretteVatAdjustmentAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.cigaretteVatAdjustmentAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.cigaretteVatAdjustmentAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div
                      *ngIf="isSubmitted && profitSharingForm.controls.cigaretteVatAdjustmentAmount.errors?.required"
                    >
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left w-60">
                  <div class="container-fluid table-icon-position row w-100">
                    <em class="table-grey-icon icon-refund-gross-profit"></em>
                    <div class="text-left" [innerHTML]="'PROFIT_SHARING.REFUND_GROSS_PROFIT' | translate"></div>
                  </div>
                </div>
                <div class="float-right gross-profit-value">
                  {{ profitSharing.refundGrossProfitIncVatAmount > 0 ? '-' : ''
                  }}{{ profitSharing.refundGrossProfitIncVatAmount | numberFormatDisplay }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3 pl-0">
      <div class="section-header row">
        <span id="profit-sharing-table">{{ 'PROFIT_SHARING.PROFIT_SHARING' | translate }}</span>
      </div>
      <div class="w-100">
        <table
          id="profit-sharing-detail"
          class="table table-bordered table-hover w-100"
          aria-label="profit-sharing-detail"
        >
          <thead>
            <tr>
              <th class="header-text" [ngClass]="{ 'extra-padding': profitSharing.templateVersion === 2 }">
                <div class="header-center ">
                  <div class="float-left" *ngIf="!langTH">
                    <span class="table-header-text">{{ 'PROFIT_SHARING.GROSS_PROFIT' | translate }}</span
                    ><br /><span class="sub-header-text"> {{ 'PROFIT_SHARING.AFTER_SHARE_TO_TD' | translate }}</span>
                  </div>
                  <div class="float-left" *ngIf="langTH">
                    <span class="table-header-text">{{ 'PROFIT_SHARING.GROSS_AFTER_SHARE_TO_TD' | translate }}</span>
                  </div>
                  <span class="float-right table-header-value">
                    {{ profitSharing.grossProfitAfterShareAmount | numberFormatDisplay }}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="flex-container">
                  <div class="float-left text-left pt-0 w-40">
                    {{ 'PROFIT_SHARING.GROSS_PROFIT' | translate }}
                  </div>
                  <div class="float-right profit-sharing-value">
                    {{ profitSharing.grossProfitIncVatAmount | numberFormatDisplay }}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="flex-container">
                  <div class="float-left text-left pt-0 w-40">
                    {{ 'PROFIT_SHARING.SHARE_TO_TD' | translate }}
                  </div>
                  <div class="float-right profit-sharing-value">
                    {{ profitSharing.shareToTDIncVatAmount > 0 ? '-' : ''
                    }}{{ profitSharing.shareToTDIncVatAmount | numberFormatDisplay }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-lg-3 pl-0">
      <div class="row blank-space"></div>
      <div class="w-100">
        <table
          id="administrative-table"
          class="table table-bordered table-hover w-100"
          aria-label="administrative-table"
        >
          <thead>
            <tr>
              <th scope="col">
                <div class="header-center">
                  <div class="float-left table-header-text" *ngIf="profitSharing.templateVersion === 1">
                    {{ 'PROFIT_SHARING.ADMIMISTRATIVE_EXPENSE' | translate }}
                  </div>
                  <div
                    class="float-left table-header-text w-60 line-height-18"
                    *ngIf="profitSharing.templateVersion === 2"
                    [innerHTML]="'PROFIT_SHARING.ADVANCE_PAYMENT_AND_ADMININISTRATIVE_EXPENSE' | translate"
                  ></div>
                  <span
                    class="float-right table-header-value"
                    [ngClass]="{
                      negative: profitSharing.totalOtherExpense > 0
                    }"
                    >{{ profitSharing.totalOtherExpense > 0 ? '-' : ''
                    }}{{ profitSharing.totalOtherExpense || 0 | numberFormatDisplay }}</span
                  >
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="profitSharing.templateVersion === 2">
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  {{ 'PROFIT_SHARING.ADVANCE_PAYMENT' | translate }}
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="advancePaymentAmount"
                    placeholder="0.00"
                    formControlName="advancePaymentAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.advancePaymentAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.advancePaymentAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.advancePaymentAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  {{ 'PROFIT_SHARING.WITHHOLDING_TAX' | translate }}
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="withHoldingTaxAmount"
                    placeholder="0.00"
                    formControlName="withHoldingTaxAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.withHoldingTaxAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.withHoldingTaxAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.withHoldingTaxAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr formGroupName="lessCashTransfer">
              <td>
                <div class="flex-container">
                  <div class="grid-container w-60" (click)="toggleExpandableType('lessCashTransfer')">
                    <div [innerHTML]="'PROFIT_SHARING.LESS_CASH_TRANSFER_DEDUCTIONS' | translate"></div>
                    <div>
                      <em class="icon-down-arrow" [ngClass]="{ expanded: isExpandedType('lessCashTransfer') }"> </em>
                    </div>
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="lessCashTransferAmountTotal"
                      placeholder="0.00"
                      formControlName="totalAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.get('lessCashTransfer').get('totalAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.get('lessCashTransfer').get('totalAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted && profitSharingForm.get('lessCashTransfer').get('totalAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <div [@slide]="isExpandedType('lessCashTransfer') ? 'down' : 'up'">
              <div formGroupName="lessCashTransfer" class="profit-sub-item-container">
                <div class="row pb-2 align-items-center">
                  <div class="float-left text-left pl-3 pr-3 w-60">
                    {{ 'PROFIT_SHARING.LESS_CASH_TRANSFER_DROP' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="lessCashTransferAmount"
                      placeholder="0.00"
                      formControlName="lessCashTransferAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted &&
                          profitSharingForm.get('lessCashTransfer').get('lessCashTransferAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted && profitSharingForm.get('lessCashTransfer').get('lessCashTransferAmount').errors
                      "
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.get('lessCashTransfer').get('lessCashTransferAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2 align-items-center">
                  <div class="float-left text-left pl-3 pr-3 w-60">
                    {{ 'PROFIT_SHARING.STOCK_LOSS_INSTALLMENT' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="stockLossInstallmentAmount"
                      placeholder="0.00"
                      formControlName="stockLossInstallmentAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted &&
                          profitSharingForm.get('lessCashTransfer').get('stockLossInstallmentAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted &&
                        profitSharingForm.get('lessCashTransfer').get('stockLossInstallmentAmount').errors
                      "
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.get('lessCashTransfer').get('stockLossInstallmentAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2 align-items-center">
                  <div class="float-left text-left line-height-18 pl-3 pr-3 w-60">
                    {{ 'PROFIT_SHARING.BILL_PAYMENT_INSTALLMENT' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="billPaymentInstallmentAmount"
                      placeholder="0.00"
                      formControlName="billPaymentInstallmentAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted &&
                          profitSharingForm.get('lessCashTransfer').get('billPaymentInstallmentAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted &&
                        profitSharingForm.get('lessCashTransfer').get('billPaymentInstallmentAmount').errors
                      "
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.get('lessCashTransfer').get('billPaymentInstallmentAmount').errors
                            ?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="float-left text-left line-height-18 pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.NEGATIVE_PROFIT_SHARING' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="negativeProfitSharingAmount"
                      placeholder="0.00"
                      formControlName="negativeProfitSharingAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted &&
                          profitSharingForm.get('lessCashTransfer').get('negativeProfitSharingAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted &&
                        profitSharingForm.get('lessCashTransfer').get('negativeProfitSharingAmount').errors
                      "
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.get('lessCashTransfer').get('negativeProfitSharingAmount').errors
                            ?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 align-items-center" *ngIf="profitSharing?.templateVersion === 1">
                  <div class="float-left text-left line-height-18 pl-3 pr-3 w-60">
                    {{ 'PROFIT_SHARING.ADVANCE_PAYMENT' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="advancePaymentAmount"
                      placeholder="0.00"
                      formControlName="advancePaymentAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.get('lessCashTransfer').get('advancePaymentAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted && profitSharingForm.get('lessCashTransfer').get('advancePaymentAmount').errors
                      "
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.get('lessCashTransfer').get('advancePaymentAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center">
                  <div class="float-left  text-left line-height-18 pl-3 pr-3 w-60">
                    {{ 'PROFIT_SHARING.OTHER_LESS_CASH_TRANSFER' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="otherLessCashTransferAmount"
                      placeholder="0.00"
                      formControlName="otherLessCashTransferAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted &&
                          profitSharingForm.get('lessCashTransfer').get('otherLessCashTransferAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted &&
                        profitSharingForm.get('lessCashTransfer').get('otherLessCashTransferAmount').errors
                      "
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.get('lessCashTransfer').get('otherLessCashTransferAmount').errors
                            ?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  {{ 'PROFIT_SHARING.STORE_USE' | translate }}
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="storeUseAmount"
                    placeholder="0.00"
                    formControlName="storeUseAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.storeUseAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.storeUseAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.storeUseAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr formGroupName="stockLossAdjustment">
              <td>
                <div class="flex-container">
                  <div class="grid-container w-60" (click)="toggleExpandableType('stockLossAdjustment')">
                    <div>
                      {{ 'PROFIT_SHARING.STOCK_LOSS_ADJUSTMENT' | translate }}
                    </div>
                    <div>
                      <em
                        class="icon-down-arrow"
                        [ngClass]="{ expanded: isExpandedType('stockLossAdjustment') }"
                      ></em>
                    </div>
                  </div>
                  <div class="w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="stockLossAdjustmentAmountTotal"
                      placeholder="0.00"
                      formControlName="totalAmount"
                      class="td-numeric-textbox"
                    ></app-numeric-textbox>
                    <!-- <div
                      *ngIf="isSubmitted && profitSharingForm.get('stockLossAdjustment').get('totalAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.get('stockLossAdjustment').get('totalAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div> -->
                  </div>
                </div>
              </td>
            </tr>
            <div [@slide]="isExpandedType('stockLossAdjustment') ? 'down' : 'up'">
              <div formGroupName="stockLossAdjustment" class="profit-sub-item-container">
                <div class="row pb-2">
                  <div class="float-left text-left pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.IC_LOSS' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="icLostAmount"
                      placeholder="0.00"
                      formControlName="icLostAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          (isSubmitted &&
                            profitSharingForm.get('stockLossAdjustment').get('icLostAmount').errors?.required) ||
                          profitSharingForm
                            .get('stockLossAdjustment')
                            .get('icLostAmount')
                            .hasError('invalid')
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted &&
                        profitSharingForm.get('stockLossAdjustment').get('icLostAmount').errors?.required
                      "
                      class="invalid-display text-left"
                    >
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="float-left text-left pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.IC_PAID' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="icLostPaidAmount"
                      placeholder="0.00"
                      formControlName="icLostPaidAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          (isSubmitted &&
                            profitSharingForm.get('stockLossAdjustment').get('icLostPaidAmount').errors?.required) ||
                          profitSharingForm
                            .get('stockLossAdjustment')
                            .get('icLostPaidAmount')
                            .hasError('invalid')
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted &&
                        profitSharingForm.get('stockLossAdjustment').get('icLostPaidAmount').errors?.required
                      "
                      class="invalid-display text-left"
                    >
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="float-left text-left pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.IC_LOST_NEXT_BILL' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="icLostNextBillAmount"
                      placeholder="0.00"
                      formControlName="icLostNextBillAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          (isSubmitted &&
                            profitSharingForm.get('stockLossAdjustment').get('icLostNextBillAmount').errors
                              ?.required) ||
                          profitSharingForm
                            .get('stockLossAdjustment')
                            .get('icLostNextBillAmount')
                            .hasError('invalid')
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted &&
                        profitSharingForm.get('stockLossAdjustment').get('icLostNextBillAmount').errors?.required
                      "
                      class="invalid-display text-left"
                    >
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  *ngIf="
                    profitSharingForm
                      .get('stockLossAdjustment')
                      .get('totalAmount')
                      .hasError('invalid')
                  "
                >
                  <div class="float-left stock-lost-invalid text-left pt-md-2 pl-3 pr-3">
                    Not allow IC Loss Paid + IC Loss Next Bill grater than IC Loss
                  </div>
                </div>
              </div>
            </div>
            <tr *ngIf="profitSharing.templateVersion === 1">
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  {{ 'PROFIT_SHARING.DESTROY' | translate }}
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="destroyAmount"
                    placeholder="0.00"
                    formControlName="destroyAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.destroyAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.destroyAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.destroyAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr formGroupName="netPenalty">
              <td>
                <div class="flex-container">
                  <div class="grid-container w-60" (click)="toggleExpandableType('netPenalty')">
                    <div>
                      {{ 'PROFIT_SHARING.NET_PENALTY' | translate }}
                    </div>
                    <div>
                      <em class="icon-down-arrow" [ngClass]="{ expanded: isExpandedType('netPenalty') }"> </em>
                    </div>
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="netPenaltyAmountTotal"
                      placeholder="0.00"
                      formControlName="totalAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid': isSubmitted && profitSharingForm.get('netPenalty').get('totalAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.get('netPenalty').get('totalAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="isSubmitted && profitSharingForm.get('netPenalty').get('totalAmount').errors?.required"
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <div [@slide]="isExpandedType('netPenalty') ? 'down' : 'up'">
              <div formGroupName="netPenalty" class="profit-sub-item-container">
                <div class="row pb-2">
                  <div class="float-left text-left pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.LATE_PAYMENT' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="latePaymentAmount"
                      placeholder="0.00"
                      formControlName="latePaymentAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.get('netPenalty').get('latePaymentAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.get('netPenalty').get('latePaymentAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted && profitSharingForm.get('netPenalty').get('latePaymentAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="float-left text-left pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.TOTE_FINE' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="toteFineAmount"
                      placeholder="0.00"
                      formControlName="toteFineAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid': isSubmitted && profitSharingForm.get('netPenalty').get('toteFineAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.get('netPenalty').get('toteFineAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted && profitSharingForm.get('netPenalty').get('toteFineAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="float-left text-left pt-md-2 pl-3 pr-3 w-60">
                    {{ 'PROFIT_SHARING.OTHER_PENALTY' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="otherPenaltyAmount"
                      placeholder="0.00"
                      formControlName="otherPenaltyAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.get('netPenalty').get('otherPenaltyAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.get('netPenalty').get('otherPenaltyAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.get('netPenalty').get('otherPenaltyAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <tr formGroupName="otherExpense">
              <td>
                <div class="flex-container">
                  <div class="grid-container w-60" (click)="toggleExpandableType('otherExpense')">
                    <div>
                      {{ 'PROFIT_SHARING.OTHER_EXPENSE' | translate }}
                    </div>
                    <div>
                      <em class="icon-down-arrow" [ngClass]="{ expanded: isExpandedType('otherExpense') }"> </em>
                    </div>
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="otherExpenseAmountTotal"
                      placeholder="0.00"
                      formControlName="totalAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid': isSubmitted && profitSharingForm.get('otherExpense').get('totalAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.get('otherExpense').get('totalAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted && profitSharingForm.get('otherExpense').get('totalAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <div [@slide]="isExpandedType('otherExpense') ? 'down' : 'up'">
              <div formGroupName="otherExpense" class="profit-sub-item-container">
                <div class="row pb-2">
                  <div class="float-left text-left pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.INTERNET' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="internetAmount"
                      placeholder="0.00"
                      formControlName="internetAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.get('otherExpense').get('internetAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.get('otherExpense').get('internetAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted && profitSharingForm.get('otherExpense').get('internetAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="float-left text-left pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.ASSET_REPAIR' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="assetRepairAmount"
                      placeholder="0.00"
                      formControlName="assetRepairAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.get('otherExpense').get('assetRepairAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.get('otherExpense').get('assetRepairAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.get('otherExpense').get('assetRepairAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="float-left text-left pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.OTHER_EXPENSE_DROP' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="otherExpenseAmount"
                      placeholder="0.00"
                      formControlName="otherExpenseAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.get('otherExpense').get('otherExpenseAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.get('otherExpense').get('otherExpenseAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.get('otherExpense').get('otherExpenseAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-lg-3 pl-0">
      <div class="row blank-space"></div>
      <div class="w-100">
        <table id="other-income-table" class="table table-bordered table-hover w-100" aria-label="other-income-table">
          <thead>
            <tr>
              <th [ngClass]="{ 'extra-padding': profitSharing.templateVersion === 2 }">
                <div class="header-center">
                  <div class="float-left table-header-text">
                    {{ 'PROFIT_SHARING.OTHER_INCOME_HEADER' | translate }}
                  </div>
                  <span class="float-right table-header-value">
                    {{ profitSharing.totalOtherIncome || 0 | numberFormatDisplay }}</span
                  >
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr formGroupName="refund">
              <td>
                <div class="flex-container">
                  <div class="grid-container w-60" (click)="toggleExpandableType('refund')">
                    <div [innerHTML]="'PROFIT_SHARING.OVER_CASH_TRANSFER_REFUND' | translate"></div>
                    <div>
                      <em class="icon-down-arrow" [ngClass]="{ expanded: isExpandedType('refund') }"> </em>
                    </div>
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="overCashTransferAmountTotal"
                      placeholder="0.00"
                      formControlName="totalAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid': isSubmitted && profitSharingForm.get('refund').get('totalAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.get('refund').get('totalAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div *ngIf="isSubmitted && profitSharingForm.get('refund').get('totalAmount').errors?.required">
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <div [@slide]="isExpandedType('refund') ? 'down' : 'up'">
              <div formGroupName="refund" class="profit-sub-item-container">
                <div class="row pb-2">
                  <div class="float-left text-left pt-md-2 pl-3 pr-3 w-60">
                    {{ 'PROFIT_SHARING.OVER_CASH_TRANSFER' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="overCashTransferAmount"
                      placeholder="0.00"
                      formControlName="overCashTransferAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.controls.refund.controls.overCashTransferAmount.errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.controls.refund.controls.overCashTransferAmount.errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.controls.refund.controls.overCashTransferAmount.errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="float-left text-left pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.REFUND' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="refundAmount"
                      placeholder="0.00"
                      formControlName="refundAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid': isSubmitted && profitSharingForm.controls.refund.controls.refundAmount.errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.controls.refund.controls.refundAmount.errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted && profitSharingForm.controls.refund.controls.refundAmount.errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="float-left text-left pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.APPEAL_FOR_FINE' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="appealForFineAmount"
                      placeholder="0.00"
                      formControlName="appealForFineAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.controls.refund.controls.appealForFineAmount.errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.controls.refund.controls.appealForFineAmount.errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.controls.refund.controls.appealForFineAmount.errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="float-left text-left pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.APPEAL_FOR_STOCK_LOSS' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="appealForStockLossAmount"
                      placeholder="0.00"
                      formControlName="appealForStockLossAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.controls.refund.controls.appealForStockLossAmount.errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted && profitSharingForm.controls.refund.controls.appealForStockLossAmount.errors
                      "
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.controls.refund.controls.appealForStockLossAmount.errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="float-left text-left pt-md-2 pl-3 pr-3 w-60">
                    {{ 'PROFIT_SHARING.OTHER_REFUND' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="otherRefundAmount"
                      placeholder="0.00"
                      formControlName="otherRefundAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.controls.refund.controls.otherRefundAmount.errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.controls.refund.controls.otherRefundAmount.errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted && profitSharingForm.controls.refund.controls.otherRefundAmount.errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <tr>
              <td>
                <div class="flex-container">
                  <div class="float-left text-left w-60">
                    {{ 'PROFIT_SHARING.INCENTIVE' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="incentiveAmount"
                      placeholder="0.00"
                      formControlName="incentiveAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid': isSubmitted && profitSharingForm.controls.incentiveAmount.errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.controls.incentiveAmount.errors"
                      class="invalid-display text-left"
                    >
                      <div *ngIf="isSubmitted && profitSharingForm.controls.incentiveAmount.errors?.required">
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr formGroupName="otherIncome">
              <td>
                <div class="flex-container">
                  <div class="grid-container w-60" (click)="toggleExpandableType('otherIncome')">
                    <div>
                      {{ 'PROFIT_SHARING.OTHER_INCOME' | translate }}
                    </div>
                    <div>
                      <em class="icon-down-arrow" [ngClass]="{ expanded: isExpandedType('otherIncome') }"> </em>
                    </div>
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="otherIncomeAmountTotal"
                      placeholder="0.00"
                      formControlName="totalAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid': isSubmitted && profitSharingForm.get('otherIncome').get('totalAmount').errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.get('otherIncome').get('totalAmount').errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted && profitSharingForm.get('otherIncome').get('totalAmount').errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <div [@slide]="isExpandedType('otherIncome') ? 'down' : 'up'">
              <div formGroupName="otherIncome" class="profit-sub-item-container">
                <div class="row pb-2 align-items-center">
                  <div class="float-left line-height-18 text-left  pl-3 pr-3 w-60">
                    {{ 'PROFIT_SHARING.MARKETING_SUPPORT_INCOME' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="marketingSupportAmount"
                      placeholder="0.00"
                      formControlName="marketingSupportAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.controls.otherIncome.controls.marketingSupportAmount.errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted && profitSharingForm.controls.otherIncome.controls.marketingSupportAmount.errors
                      "
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.controls.otherIncome.controls.marketingSupportAmount.errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="float-left text-left line-height-18 pl-3 pr-3 w-60 flex-container">
                    {{ 'PROFIT_SHARING.WITHHOLDING_TAX_CORPORATE' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="withHoldingTaxCorpAmount"
                      placeholder="0.00"
                      formControlName="withHoldingTaxCorpAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted &&
                          profitSharingForm.controls.otherIncome.controls.withHoldingTaxCorpAmount.errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="
                        isSubmitted && profitSharingForm.controls.otherIncome.controls.withHoldingTaxCorpAmount.errors
                      "
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.controls.otherIncome.controls.withHoldingTaxCorpAmount.errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="float-left text-left pt-md-2 pl-3 pr-3 w-60">
                    {{ 'PROFIT_SHARING.OTHER_INCOME_DROP' | translate }}
                  </div>
                  <div class="float-right w-40">
                    <app-numeric-textbox
                      [format]="'0,0.00'"
                      [negativeSign]="false"
                      id="otherIncomeAmount"
                      placeholder="0.00"
                      formControlName="otherIncomeAmount"
                      class="td-numeric-textbox"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitted && profitSharingForm.controls.otherIncome.controls.otherIncomeAmount.errors
                      }"
                    ></app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && profitSharingForm.controls.otherIncome.controls.otherIncomeAmount.errors"
                      class="invalid-display text-left"
                    >
                      <div
                        *ngIf="
                          isSubmitted &&
                          profitSharingForm.controls.otherIncome.controls.otherIncomeAmount.errors?.required
                        "
                      >
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="fixed-row-bottom form-row">
  <div class="float-left"></div>

  <div class="float-right">
    <button
      *ngIf="isViewMode && permission.hasManageProfitSharing"
      type="button"
      id="btn-request-edit"
      class="btn btn-primary"
      (click)="toggleToEditMode()"
    >
      Edit
    </button>
    <button *ngIf="!isViewMode" type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">
      Cancel
    </button>
    <button *ngIf="!isViewMode" type="button" id="btn-submit" (click)="onSubmit()" class="btn btn-primary">
      Submit
    </button>
  </div>
</div>
