import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';

import { holdAssortmentReasonListQuery, holdAssortmentReasonSchema } from '@shared/gql/holdAssortmentReason.gql';
import { HoldAssortmentRequestTypeEnum } from '@shared/models/list-value/list-key-value.model';
import { BaseService } from '@shared/services/base.service';
import { MasterService } from '@shared/services/master.service';

import {
  HoldAssortmentApproveRejectCancelRequest,
  HoldAssortmentApproveRejectResponse,
  HoldAssortmentListResponse,
  HoldAssortmentRequestListCriteria,
  HoldAssortmentRequestReason,
  HoldAssortmentRequestView
} from '../models/hold-assortment.model';

@Injectable({
  providedIn: 'root'
})
export class HoldAssortmentRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private masterService: MasterService) {
    super();
    this.envService = this.env.services.holdAssortment;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  loadHoldAssortmentRequestList(payload: HoldAssortmentRequestListCriteria): Observable<HoldAssortmentListResponse> {
    const url = this.getUrl();
    const params = this.getParams(payload, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null),
      observe: 'body',
      params
    });
  }

  loadHoldAssortmentRequestView(requestNo): Observable<HoldAssortmentRequestView> {
    const url = this.getFullUrl(this.envService.view, { requestNo });
    return this.http.get<HoldAssortmentRequestView>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  downloadHoldAssortmentRequest(fileName: string): Observable<any> {
    const url = this.getFullUrl(this.envService.exportHoldAssortmentRequest, undefined, true);
    return this.http
      .post<any>(
        url,
        {
          bucketName: 'HOLD_ASSORTMENT_REQUEST',
          filePath: fileName
        },
        {
          headers: this.headers,
          observe: 'body'
        }
      )
      .pipe(
        switchMap(res => {
          return this.http.get(res.link, {
            responseType: 'blob' as 'json'
          });
        })
      );
  }

  approveRequest(request: HoldAssortmentApproveRejectCancelRequest): Observable<HoldAssortmentApproveRejectResponse> {
    const url = this.getFullUrl(this.envService.approve);

    return this.http.put<HoldAssortmentApproveRejectResponse>(url, request, {
      headers: this.loaderHeaders(2000),
      observe: 'body'
    });
  }

  rejectRequest(request: HoldAssortmentApproveRejectCancelRequest): Observable<HoldAssortmentApproveRejectResponse> {
    const url = this.getFullUrl(this.envService.reject);

    return this.http.put<HoldAssortmentApproveRejectResponse>(url, request, {
      headers: this.loaderHeaders(2000),
      observe: 'body'
    });
  }

  getHoldAssortmentReasonsMasterData(type: HoldAssortmentRequestTypeEnum): Observable<HoldAssortmentRequestReason[]> {
    return this.masterService
      .watchQuery<HoldAssortmentRequestReason[]>({
        query: holdAssortmentReasonListQuery,
        variables: {
          holdType: type === HoldAssortmentRequestTypeEnum.HOLD,
          unholdType: type !== HoldAssortmentRequestTypeEnum.HOLD
        }
      })
      .pipe(
        map(data => {
          return data.data[holdAssortmentReasonSchema];
        })
      );
  }

  onExportTemplate() {
    const url = this.getFullUrl(this.envService.importTemplate);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  importItem(requestType: string, reason: string, file: File): Observable<any> {
    const url = this.getUrl();
    const formData = new FormData();

    formData.append('file', file, file.name);
    formData.append('requestType', requestType);
    formData.append('reason', reason);
    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'body',
      headers: this.loaderHeaders(3000)
    });
  }

  cancelHoldAssortmentRequest(data: HoldAssortmentApproveRejectCancelRequest) {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.put<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  getHistoryLogs(requestNo: string): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      requestNo
    });

    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }
}
