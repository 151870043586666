import { createSelector } from '@ngrx/store';

import { AppProfitSharingState } from '../profit-sharing.state';
import * as fromProfitSharing from './profit-sharing.reducers';

const selectProfitSharingState = (state: AppProfitSharingState) => state.profitSharing;

export const selectAllProfitSharingList = createSelector(selectProfitSharingState, fromProfitSharing.selectAll);

export const selectProfitSharingList = createSelector(
  selectProfitSharingState,
  (state: fromProfitSharing.ProfitSharingState) => state
);

export const selectProfitSharingData = createSelector(
  selectProfitSharingState,
  (state: fromProfitSharing.ProfitSharingState) => state.profitSharing
);

export const selectProfitSharingSubmitError = createSelector(
  selectProfitSharingState,
  (state: fromProfitSharing.ProfitSharingState) => state.submitError
);

export const selectProfitSharingSearchCriteria = createSelector(
  selectProfitSharingState,
  (state: fromProfitSharing.ProfitSharingState) => state.criteriaObject
);
