export enum ToteLocationType {
  /** Store */
  STORE = 'STORE'
}

export enum ToteMovementTypeEnum {
  CLAIM = 'CLAIM',
  LOST = 'LOST',
  RECEIVE_ORDER = 'RECEIVE_ORDER',
  RECEIVE_ORDER_RE = 'RECEIVE_ORDER_RE',
  RECALL_SPECIAL_CASE = 'RECALL_SPECIAL_CASE',
  STORE_TERMINATED = 'STORE_TERMINATED',
  TOTE_FOUND = 'TOTE_FOUND'
}
