import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { RequestSectionEnum } from '../../../../enum/request-section.enum';
import { RequestStatusEnum } from '../../../../enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../../enum/request-step.enum';
import { TDStoreEnum, TDStorePage } from '../../../../enum/td-store-page.enum';
import { MerchantRequestViewResponse, OrderScheduleList, StoreRequestViewResponse } from '../../../../models';
import { WarehouseListContent } from '../../../../models/warehouse.model';
import { WarehouseListRequestAction } from '../../../../store/actions/warehouse.actions';
import { selectAllWarehouse } from '../../../../store/selectors/warehouse.selectors';
import { AppStates } from '../../../../store/state/app.states';
import { getSelectByPage } from '../../../../utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../../utils/td-store-workflow-util';

@Component({
  selector: 'app-pre-order-policy',
  templateUrl: './pre-order-policy.component.html',
  styleUrls: ['./pre-order-policy.component.scss']
})
export class PreOrderPolicyComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() mode: RequestPageModesEnum;
  @Input() page: TDStorePage;
  @Input() ddlPosition: string;

  public merchantRequestView$: Observable<MerchantRequestViewResponse>;
  public warehouseList$: Observable<WarehouseListContent[]>;

  private localStore: Observable<any>;
  private type: RequestTypeEnum;
  // @ts-ignore
  private status: RequestStatusEnum;
  private step: RequestStepEnum;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly store: Store<AppStates>,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil
  ) {
    super();
  }

  get orderSchedule() {
    return this.parentForm.get('preOrderSchedule') as UntypedFormGroup;
  }

  get preOrderScheduleList() {
    return this.orderSchedule.get('preOrderScheduleList') as UntypedFormArray;
  }

  get merchantPage() {
    return TDStorePage;
  }

  get typeSection() {
    return TDStoreEnum;
  }

  ngOnInit() {
    this.type = RequestTypeEnum.NEW;
    this.status = RequestStatusEnum.DRAFT;
    this.step = RequestStepEnum.PROFILE;

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.warehouseList$ = this.localStore.pipe(select(selectAllWarehouse('Warehouse')));

    this.store.dispatch(new WarehouseListRequestAction('-'));

    this.parentForm.addControl('preOrderSchedule', this.fb.group({ preOrderScheduleList: this.fb.array([]) }));
    this.addPreOrderPolicy();

    if (this.mode !== RequestPageModesEnum.REQUEST_CREATE) {
      this.setPreOrderPolicyValue();
    }

    this.preOrderScheduleList.disable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  get createForm() {
    return this.fb.group({
      warehouseCode: [{ value: null, disabled: false }],
      minOrder: [{ value: 0, disabled: false }],
      schedules: this.fb.array([]),
      preOrderWarehouseCode: [{ value: null, disabled: false }, [Validators.required]],
      preOrderSchedules: this.fb.array([])
    });
  }

  addPreOrderPolicy() {
    this.preOrderScheduleList.push(this.createForm);
  }

  toggleEditPreOrderPolicy() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;
    this.setPreOrderPolicyCtrl(this.type, this.page, this.step, this.mode);
  }

  setPreOrderPolicyValue() {
    this.merchantRequestView$ = this.localStore.pipe(
      select(getSelectByPage(this.page)),
      filter(value => value !== null)
    );
    this.merchantRequestView$
      .pipe(
        map(response => response),
        filter(value => value !== null)
      )
      .subscribe((value: MerchantRequestViewResponse | StoreRequestViewResponse) => {
        this.type = value.type || RequestTypeEnum.EDIT;
        this.step = value.step || RequestStepEnum.EDIT_PROFILE;
        this.status = value.status || RequestStatusEnum.DRAFT;

        if (value.orderSchedule && value.orderSchedule.orderScheduleList.length > 0) {
          value.orderSchedule.orderScheduleList.forEach((field: OrderScheduleList, i) => {
            if (!this.preOrderScheduleList.at(i)) {
              this.addPreOrderPolicy();
            }

            this.preOrderScheduleList.controls[i].patchValue({
              warehouseCode: field.warehouseCode,
              minOrder: field.minOrder,
              preOrderWarehouseCode: field.preOrderWarehouseCode
            });
          });
        }

        this.setPreOrderPolicyCtrl(this.type, this.page, this.step, this.mode);
      });
  }

  setPreOrderPolicyCtrl(
    localType: RequestTypeEnum,
    localPage: TDStorePage,
    localStep: RequestStepEnum,
    localMode: RequestPageModesEnum
  ) {
    const canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(
      localType,
      localPage,
      localStep,
      RequestSectionEnum.ORDER_POLICY
    );

    if (
      localPage === TDStorePage.STORE_EDIT_ORDER_SCHEDULE ||
      (localMode === RequestPageModesEnum.REQUEST_EDIT && canEditByWorkflow && this.preOrderScheduleList)
    ) {
      this.preOrderScheduleList.enable();

      for (const list of this.preOrderScheduleList.controls) {
        const schedules = list.get('preOrderSchedules') as UntypedFormArray;

        schedules.controls
          .filter(control => !control.value.orderScheduleDate)
          .forEach(control => control.get('deliverySchedule').disable());

        schedules.controls
          .filter(control => !control.value.orderScheduleDate && !control.value.deliverySchedule)
          .forEach(control => control.get('pickScheduleDate').disable());
      }
    }
  }
}
