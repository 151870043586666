import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

@Injectable()
export class PointEarnTransactionReport extends BaseService {
  public headers: HttpHeaders;
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.pointEarnTransactionReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  reportExport(reportCriteria: any) {
    const url = this.getFullUrl(this.envService.export);

    return this.http.post<any>(url, reportCriteria, {
      headers: this.loaderHeaders(0),
      observe: 'body'
    });
  }
}
