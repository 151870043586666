import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import {
  HoldItemListRequest,
  HoldItemListResponse,
  PermanentHoldList,
  PermanentHoldListResponse,
  PermanentHoldListSearchCriteria,
  UnholdItemListResponse
} from '../models/permanent-hold.model';
import { BaseService } from './base.service';

@Injectable()
export class PermanentHoldService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.permanentHold;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteria(criteria: PermanentHoldListSearchCriteria): Observable<PermanentHoldListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PERMANENT_HOLD,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  getPermanentHoldRequestByStoreNameAndStoreType(
    storeCodeName: string,
    storeType: string
  ): Observable<PermanentHoldList> {
    const criteria: PermanentHoldListSearchCriteria = {
      page: 0,
      size: 1,
      searchCriteria: storeCodeName,
      storeType
    };
    return this.searchByCriteria(criteria).pipe(map(response => response.content?.[0]));
  }

  getPermanentHoldHoldItemsByStoreCode(
    criteria: HoldItemListRequest
  ): Observable<HoldItemListResponse | UnholdItemListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PERMANENT_HOLD,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };

    const url = this.getFullUrl(this.envService.view);
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  submitUnholdItems(comment: string, ids: string[]) {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PERMANENT_HOLD,
          route: `${this.envService.url}${this.envService.unhold}`,
          action: MetricActionType.SUBMIT
        }
      ]
    };
    const url = this.getFullUrl(this.envService.unhold);

    return this.http.post<any>(
      url,
      { comment, ids },
      {
        headers: this.loaderHeaders(2000, metricsReq),
        observe: 'body'
      }
    );
  }
}
