import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ImportOrderScheduleData, StoreListSearchCriteria } from '../../../../shared/models/store.model';
import { MerchantService } from '../../../../shared/services/merchant.service';
import { StoreService } from '../../../../shared/services/store.service';
import { WarehouseListRequestAction } from '../../../../shared/store/actions/warehouse.actions';
import { selectAllWarehouse } from '../../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import { ExcelUtil } from '../../../../shared/utils/excel-util';
import { StoreListRequestAction } from '../../store/store.actions';
import { selectStoreListCriteria } from '../../store/store.selectors';
import { TDWorker } from './worker';

@Component({
  selector: 'app-import-order-schedule',
  templateUrl: './import-order-schedule.component.html',
  styleUrls: ['./import-order-schedule.component.scss']
})
export class ImportOrderScheduleModalComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @Output() validateObjChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() validateObj;

  private localStore: Observable<any>;
  public form: UntypedFormGroup;
  public loading: boolean;
  public isValidated: boolean;
  public loadedMaster: boolean;
  public worker: Worker;
  public errorData: any;
  public noOfErrors: number;
  public errorMessage: string;
  public storeList$: Observable<any>;
  public storeList;
  public warehouseList$: Observable<any>;
  public warehouseList;
  public validatedData: ImportOrderScheduleData[] = [];
  private listSearchCriteria: StoreListSearchCriteria;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    private readonly logger: NGXLogger,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected readonly storeService: StoreService,
    public readonly merchantService: MerchantService
  ) {
    super();
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.store.dispatch(new WarehouseListRequestAction('-'));
    this.warehouseList$ = this.localStore.pipe(select(selectAllWarehouse('Warehouse')));

    this.storeList$ = this.merchantService
      .searchStoreByCriteria({
        page: 0,
        size: 20000
      })
      .pipe(filter(v => Boolean(v)));

    this.localStore
      .pipe(select(selectStoreListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.createForm();
    this.loadMasterList();
    this.startWorker(this.processWorkbook.bind(this));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.worker) {
      this.worker.terminate();
    }
  }

  loadMasterList() {
    combineLatest([
      this.warehouseList$.pipe(
        map(v => {
          const arr = v.map(item => {
            return { wmsCode: item.wmsCode, code: item.code };
          });

          return arr.reduce((acc, curr) => ((acc[curr.wmsCode?.toUpperCase()] = curr), acc), {});
        })
      ),
      this.storeList$.pipe(
        map(v => {
          const arr = v.content.map(item => {
            return item.code;
          });

          return _.keyBy(arr);
        })
      )
    ]).subscribe(([warehouseList, storeList]) => {
      this.warehouseList = warehouseList;
      this.storeList = storeList;

      this.loadedMaster = true;
    });
  }

  createForm() {
    this.form = this.fb.group({
      importData: [null, Validators.required]
    });
  }

  downloadTemplate() {
    this.storeService.downloadStoreOrderScheduleTemplate().subscribe(response => {
      const blob = new Blob([response]);
      saveAs(blob, `${environment.fileName.exportStoreOrderScheduleTemplate.prefix}.xlsx`);
    });
  }

  generateFileName() {
    return environment.fileName.exportStoreOrderScheduleTemplate.prefix;
  }

  generateErrorFileName() {
    return `${environment.fileName.exportStoreOrderScheduleTemplate.exported} Error ${this.timeError}.xlsx`;
  }

  get timeError(): string {
    return moment().format(environment.fileName.exportStoreOrderScheduleTemplate.timeFormat);
  }

  onSelectFile() {
    if (!this.errorMessage) {
      this.loading = true;
      this.isValidated = false;

      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        /* create workbook */
        const result = new Uint8Array(e.target.result);
        this.readImportData(result);
      };
      reader.readAsArrayBuffer(this.form.value.importData[0]);
    }
  }

  onSubmit() {
    this.loading = true;

    this.storeService.importStoreOrderSchedule(this.validatedData).subscribe({
      next: () => {
        this.listSearchCriteria = {
          ...this.listSearchCriteria,
          page: 0
        };

        this.store.dispatch(new StoreListRequestAction(this.listSearchCriteria));

        this.closeModal();
        this.alertSuccessModal('The data have been imported.');
      },
      error: errorResponse => {
        this.loading = false;
        this.isValidated = false;
        this.validateObj = {};

        this.setErrorMessage(errorResponse.error.message);
      }
    });
  }

  readImportData(data) {
    this.worker.postMessage({
      v: this.validateObj,
      d: data,
      storeList: this.storeList,
      warehouseList: this.warehouseList
    });
  }

  processWorkbook(data: ImportOrderScheduleData[]) {
    this.validatedData = data;
    this.loading = false;
    this.isValidated = true;
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  startWorker(callback) {
    this.worker = TDWorker();
    this.worker.onmessage = e => {
      switch (e.data.t) {
        case 'ready':
          break;
        case 'e':
          this.errorData = JSON.parse(e.data.d);
          this.noOfErrors = e.data.n;
          this.setErrorMessage(
            `${this.numberWithCommas(
              this.noOfErrors
            )} error found. Download this file to edit or delete invalid row then re-import.`
          );
          this.loading = false;
          this.isValidated = false;
          break;
        case 'alert':
          this.setErrorMessage(e.data.d);
          this.errorData = '';
          this.noOfErrors = 0;
          this.loading = false;
          this.isValidated = false;
          break;
        case 'xlsx':
          this.validateObj = JSON.parse(e.data.v);
          this.validateObjChange.emit(this.validateObj);
          callback(JSON.parse(e.data.d));
          break;
        case 'log':
          this.logger.log(JSON.parse(e.data.d));
          break;
        default:
      }
    };
  }

  downloadErrorFile() {
    ExcelUtil.WriteFile(this.errorData, this.generateErrorFileName());
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.bsModalRef.hide();
      }
    });
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  reset() {
    this.errorMessage = null;
    this.errorData = null;
    this.isValidated = false;
    this.validateObj = {};
  }

  closeModal() {
    if (this.worker) {
      this.worker.terminate();
    }

    this.bsModalRef.hide();
  }
}
