import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MasterDataEnum } from '../enum/master-data.enum';
import { NewRequestStatusEnum } from '../enum/request-status.enum';
import { GraphqlQueryObject, GraphqlQuerySortOptions, OrderByEnum } from '../gql/common.gql';
import { SegmentList } from '../gql/segmentList.gql';
import { StoreListSearchCriteria } from '../models';
import { FileResponse } from '../models/fileResponse';
import { MetricAction, MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import {
  OrderInventoryCreateRequest,
  OrderInventoryRequestViewResponse
} from '../models/order-request-inventory.model';
import {
  ApproveOrderRequest,
  OrderId,
  OrderRequestListResponse,
  OrderRequestListSearchCriteria,
  OrderRequestViewResponse
} from '../models/order-request.model';
import { Progress } from '../models/progress.model';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

type UploadResultType = Progress | FileResponse | null | any;
@Injectable()
export class OrderInventoryRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(
    private readonly http: HttpClient,
    private readonly masterService: MasterService,
    private readonly logger: NGXLogger
  ) {
    super();
    this.envService = this.env.services.orderRequestInventory;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: OrderRequestListSearchCriteria): Observable<OrderRequestListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ORDER_INVENTORY,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria);

    return this.http.get<OrderRequestListResponse>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public getOrderById(orderId: OrderId): Observable<OrderInventoryRequestViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      orderId: orderId.orderId
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public saveRequest(OrderRequest: OrderInventoryCreateRequest): Observable<OrderInventoryRequestViewResponse> {
    const url =
      OrderRequest.status === NewRequestStatusEnum.ASSIGN_QUANTITY
        ? this.env.serverUrl +
          this.env.services.orderRequest.url +
          this.env.services.orderRequestInventory.assignQuantitySave
        : this.getFullUrl(this.envService.save);

    return this.http.put<any>(url, OrderRequest, { headers: this.loaderHeaders(2000) });
  }

  public submitRequest(OrderRequest: OrderInventoryCreateRequest): Observable<OrderInventoryRequestViewResponse> {
    const url =
      OrderRequest.status === NewRequestStatusEnum.ASSIGN_QUANTITY
        ? this.env.serverUrl +
          this.env.services.orderRequest.url +
          this.env.services.orderRequestInventory.assignQuantitySubmit
        : this.getFullUrl(this.envService.submit);

    return this.http.post<any>(url, OrderRequest, {
      headers: this.loaderHeaders(2000, this.prepareOrderMatrix(OrderRequest))
    });
  }

  public approveRequest(request: ApproveOrderRequest): Observable<OrderRequestViewResponse> {
    const url = this.getFullUrl(this.envService.approve);

    return this.http.put<OrderRequestViewResponse>(url, request, {
      headers: this.loaderHeaders(2000),
      observe: 'body'
    });
  }

  public deleteByRequestId(orderId: OrderId): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.delete, {
      orderId: orderId.orderId
    });
    return this.http.delete<any>(url, { headers: this.loaderHeaders(2000), observe: 'body' });
  }

  public searchStoreByCriteria(criteria: StoreListSearchCriteria): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ORDER_INVENTORY,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getFullUrl(this.envService.getStore);
    const params = this.getParams(criteria);
    return this.http
      .get<any>(url, {
        headers: this.loaderHeaders(null, metricsReq),
        observe: 'body',
        params
      })
      .pipe(
        map(res => {
          if (res && res.content) {
            res.content.forEach(item => {
              item.storeCodeName = `${item.code}-${item.name}`;
            });
          }
          return res;
        })
      );
  }

  public getSegmentListData(): Observable<ApolloQueryResult<SegmentList>> {
    const segmentListQuery = new GraphqlQueryObject();
    segmentListQuery.name = MasterDataEnum.SEGMENT;
    segmentListQuery.sort = { orderBy: OrderByEnum.CODE } as GraphqlQuerySortOptions;

    return this.masterService.getMasterDataByNames([segmentListQuery]);
  }

  public downloadFileTemplate(): Observable<any> {
    const url = this.getFullUrl(this.envService.downloadFileTemplate);
    return this.http.get<any>(url, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  public importFileRequest(file: File, guid: string): Observable<any> {
    const url = this.getFullUrl(this.envService.importValidate);
    let formData = null;
    formData = new FormData();
    formData.append('guid', guid);
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public submitFileRequest(guid: string): Observable<any> {
    const url = this.getFullUrl(this.envService.importSubmit);
    const params = this.getParams({ guid });
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.ORDER_REQUEST,
          path: `${this.envService.importSubmit}`
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ORDER_REQUEST,
          route: `${this.envService.importSubmit}`,
          action: MetricAction.IMPORT
        }
      ]
    };
    return this.http.post<any>(url, null, { headers: this.loaderHeaders(0, metricsReq), observe: 'body', params });
  }

  public exportItemProduct(selected: string): Observable<any> {
    const url = this.getFullUrl(this.envService.export, {
      orderRequestId: selected
    });

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.ORDER_INVENTORY,
          path: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ORDER_INVENTORY,
          route: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public uploadOrderSelectiveItemFiles(files: Array<File>, url: string): Array<Observable<UploadResultType>> {
    return files.map(file => this.uploadOrderSelectiveItemFile(file, url));
  }

  public uploadOrderSelectiveItemFile(file: File, url: string): Observable<UploadResultType> {
    const formData = new FormData();

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.loaderHeaders()
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return { ...event.body, status: 'done' };
            default:
              const msg = `Unhandled event: ${HttpEventType[event.type]}`;
              this.logger.info(msg);
              return null;
          }
        }),
        catchError(err => {
          return of(err);
        })
      );
  }

  public uploadOrderSelectiveItemUpdateQtyFiles(
    files: Array<File>,
    paramUrl: string,
    selected: string,
    metricsReq?: Metrics
  ): Array<Observable<UploadResultType>> {
    return files.map(file => this.uploadOrderSelectiveItemUpdateQtyFile(file, paramUrl, selected, metricsReq));
  }

  public uploadOrderSelectiveItemUpdateQtyFile(
    file: File,
    paramUrl: string,
    selected: string,
    metricsReq?: Metrics
  ): Observable<UploadResultType> {
    const formData = new FormData();

    const url = this.getFullUrl(paramUrl, {
      orderRequestId: selected
    });

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.loaderHeaders(0, metricsReq)
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return { ...event.body, status: 'done' };
            default:
              const msg = `Unhandled event: ${HttpEventType[event.type]}`;
              this.logger.info(msg);
              return null;
          }
        }),
        catchError(err => {
          return of(err);
        })
      );
  }

  prepareOrderMatrix(request: OrderInventoryCreateRequest): Metrics {
    let module: string = null;
    let path: string = null;

    if (request.status === NewRequestStatusEnum.DRAFT) {
      module = MetricModule.ORDER_REQUEST;
      path = this.envService.submit;
    } else if (request.status === NewRequestStatusEnum.ASSIGN_QUANTITY) {
      module = MetricModule.ORDER;
      path = [this.env.services.orderRequest.url, this.env.services.orderRequestInventory.assignQuantitySave].join(
        ''
      );
    }

    if (module && path) {
      return {
        metrics: [
          {
            metricType: MetricType.COUNTER,
            module: module,
            path: path
          },
          {
            metricType: MetricType.HISTOGRAM,
            module: module,
            action: MetricAction.CREATE,
            type: request.orderSubType,
            items: 1
          }
        ]
      };
    }

    return null;
  }
}
