import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pureFn'
})
export class PureFnPipe implements PipeTransform {
  transform(fn: Function, ...args: any[]): any {
    return fn(...args);
  }
}
