import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {
  @Input() labelToggle: string;
  @Output() toggleStatus = new EventEmitter<boolean>();

  @Input() status = false;

  changeToggleStatus() {
    this.toggleStatus.emit(this.status);
  }

  constructor() {}
}
