export enum OrderColorStatusEnum {
  NEW_ORDER = 'new',
  PROCESSING = 'processing',
  PARTIAL_DELIVERED = 'partial',
  AWAITING_ALLOCATION = 'awaiting-allocation',
  DELIVERED = 'deliver',
  CANCELED = 'cancel',
  CANCELED_BY_SUPPLIER = 'cancel',
  REJECTED_BY_ERP = 'rejected_by_erp',
  CLOSED = 'closed',
  GO_CREATED = 'go-created',
  AWAITING_CREATE_GO = 'awaiting-create-go'
}
