import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MetricActionType, MetricModule, MetricType, Metrics } from '@shared/models/metrics';
import { BaseService } from '@shared/services/base.service';

import {
  RequestRewardWithComment,
  RewardExportCriteria,
  RewardListResponse,
  RewardRequestResponse,
  RewardSearchCriteria
} from '../models/reward.model';

@Injectable()
export class RewardService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.reward;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteria(criteria: RewardSearchCriteria): Observable<RewardListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.REWARD,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getUrl();
    const params = this.getParams(criteria, true);
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  getByNo(rewardNo: string): Observable<RewardRequestResponse> {
    const url = this.getFullUrl(this.envService.get, { rewardNo: rewardNo });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  cancel(data: RequestRewardWithComment) {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  activeSummary(): Observable<any> {
    const url = this.getFullUrl(this.envService.summary);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  activeList(criteria: RewardSearchCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.active);
    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  exportReward(exportCriteria: RewardExportCriteria): Observable<any> {
    const params = this.getParams(exportCriteria, true);
    const url = this.getFullUrl(this.envService.export);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.REWARD,
          path: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.REWARD,
          route: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
