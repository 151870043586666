import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { ButtonType, ImportExportButton } from '../../models/import-export-button.model';

@Component({
  selector: 'app-import-export-button',
  templateUrl: './import-export-button.component.html',
  styleUrls: ['./import-export-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportExportButtonComponent {
  @Output() import: EventEmitter<string> = new EventEmitter<string>();
  @Output() importAdditional: EventEmitter<string> = new EventEmitter<string>();
  @Output() export: EventEmitter<string> = new EventEmitter<string>();
  @Output() exportAdditional: EventEmitter<string> = new EventEmitter<string>();
  @Input() buttons: Array<ImportExportButton> = [];
  @Input() disabled: boolean;

  onSubmit(button: ImportExportButton) {
    if (button.type === ButtonType.IMPORT) {
      this.import.emit(button.by);
    } else if (button.type === ButtonType.IMPORT_ADDITIONAL) {
      this.importAdditional.emit(button.by);
    } else if (button.type === ButtonType.EXPORT) {
      this.export.emit(button.by);
    } else if (button.type === ButtonType.EXPORT_ADDITIONAL) {
      this.exportAdditional.emit(button.by);
    }
  }

  getButtonType(buttonType: ButtonType) {
    if ([ButtonType.IMPORT, ButtonType.IMPORT_ADDITIONAL].includes(buttonType)) {
      return ButtonType.IMPORT;
    } else if ([ButtonType.EXPORT, ButtonType.EXPORT_ADDITIONAL].includes(buttonType)) {
      return ButtonType.EXPORT;
    }

    return null;
  }

  isShowing() {
    return this.buttons.filter(v => !v.hidden).length > 0;
  }
}
