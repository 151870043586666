<div class="flex-item d-flex justify-content-center">
  <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" class="right-section col-sm-10">
    <div class="flex">
      <div class="mb-4">
        <div class="title-text">New Password</div>
        <div class="sub-title-text">Create a new password for your account.</div>
      </div>
      <div *ngIf="alertDanger" class="form-group">
        <div class="alert-danger alert-icon" role="alert">
          <span
            [innerHTML]="(forceChangePasswordResult$ | async)?.result.errorResponse.translateKey | translate"
          ></span>
        </div>
      </div>

      <div class="right-section-control">
        <div class="form-group">
          <label for="new-password"> {{ 'NEW_PASSWORD' | translate }}<span class="text-danger">*</span> </label>
          <div class="inner-addon right-addon">
            <span (click)="toggleInputPasswordType()" [ngClass]="{ 'is-show': isShowPassword }">
              <em
                class="fas nicon glyphicon"
                [ngClass]="{
                  'is-invalid': submitted && changePasswordFormGetter.newPassword.errors,
                  'icon-eye': !isShowPassword,
                  'icon-invisible': isShowPassword
                }"
              ></em>
            </span>
            <input
              type="{{ isShowPassword ? 'text' : 'password' }}"
              class="form-control"
              formControlName="newPassword"
              id="new-password"
              name="newPassword"
              placeholder="{{ 'ENTER_NEW_PASSWORD' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && changePasswordFormGetter.newPassword.errors }"
              (keydown.space)="$event.preventDefault()"
              (paste)="$event.preventDefault()"
            />
            <div
              *ngIf="submitted && changePasswordFormGetter.newPassword.errors"
              class="invalid-feedback"
              id="new-password-error"
            >
              <div *ngIf="changePasswordFormGetter.newPassword.errors.required; else invalidPassword">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <ng-template #invalidPassword>
                <div *ngIf="changePasswordFormGetter.newPassword.errors.invalidPassword">
                  {{ 'ERRORS.PASSWORD_RULE' | translate }}
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="re-enter-password">
            {{ 'CONFIRM_NEW_PASSWORD' | translate }}<span class="text-danger">*</span>
          </label>
          <div class="inner-addon right-addon">
            <span (click)="toggleInputReEnterPasswordType()" [ngClass]="{ 'is-show': isShowReEnterPassword }">
              <em
                class="fas nicon glyphicon"
                [ngClass]="{
                  'is-invalid': submitted && changePasswordFormGetter.reEnterPassword.errors,
                  'icon-eye': !isShowReEnterPassword,
                  'icon-invisible': isShowReEnterPassword
                }"
              ></em>
            </span>
            <input
              type="{{ isShowReEnterPassword ? 'text' : 'password' }}"
              class="form-control"
              formControlName="reEnterPassword"
              id="re-enter-password"
              name="reEnterPassword"
              placeholder="{{ 'ENTER_NEW_PASSWORD' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && changePasswordFormGetter.reEnterPassword.errors }"
              (keydown.space)="$event.preventDefault()"
              (paste)="$event.preventDefault()"
            />
            <div
              *ngIf="submitted && changePasswordFormGetter.reEnterPassword.errors"
              class="invalid-feedback"
              id="re-enter-password-error"
            >
              <div *ngIf="changePasswordFormGetter.reEnterPassword.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div *ngIf="changePasswordFormGetter.reEnterPassword.errors.passwordNotEquivalent">
                {{ 'ERRORS.PASSWORD_NOT_MATCH' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group py-2">
          <button id="submit" type="submit" class="btn btn-primary btn-block px-5">
            {{ 'SUBMIT' | translate }}
          </button>
        </div>
        <div class="form-group">
          <button type="button" class="btn btn-standard btn-block px-5" (click)="backToHome()">
            {{ 'CANCEL' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
