import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { SelectWarehousePagingList, WarehouseListResponse } from '../../models/warehouse.model';
import { WarehouseService } from '../../services/warehouse.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  SelectWarehouseListRequestAction,
  SelectWarehouseListResponseAction,
  SelectWarehousePagingListResponseAction,
  WarehouseActionTypes,
  WarehouseActiveListResponseAction,
  WarehouseListRequestAction,
  WarehouseListResponseAction
} from '../actions/warehouse.actions';

@Injectable()
export class WarehouseEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly warehouseService: WarehouseService
  ) {}

  warehouseList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WarehouseListRequestAction>(WarehouseActionTypes.WAREHOUSE_LIST_REQUEST),
      tap(() => {
        this.logger.debug(`@Effect ${WarehouseActionTypes.WAREHOUSE_LIST_REQUEST}`);
      }),
      switchMap(action => {
        return this.warehouseService.listWarehouse().pipe(
          map(response => {
            const warehouseListResponse = new WarehouseListResponse();
            const warehouseListArray = [];
            Object.keys(response.data).forEach(deliveryLocationType => {
              response.data[deliveryLocationType].forEach(deliveryLocation => {
                const warehouseObject = {
                  id: null,
                  code: null,
                  name: null,
                  type: null,
                  wmsCode: null,
                  warehouseNameDisplay: null,
                  locationDisplay: null,
                  defaultFirstLot: null
                };
                warehouseObject.id = deliveryLocation.id;
                warehouseObject.code = deliveryLocation.code;
                warehouseObject.name = deliveryLocation.nameTh;
                warehouseObject.wmsCode = deliveryLocation.wmsCode;
                warehouseObject.type = deliveryLocation.__typename;
                warehouseObject.warehouseNameDisplay = `${deliveryLocation.wmsCode}${action.delimiter}${deliveryLocation.nameTh}`;
                warehouseObject.locationDisplay = `${deliveryLocation.wmsCode}${action.delimiter}${deliveryLocation.nameTh}`;
                warehouseObject.defaultFirstLot = deliveryLocation.defaultFirstLot;
                warehouseListArray.push(warehouseObject);
              });
            });
            warehouseListResponse.content = warehouseListArray;
            return new WarehouseListResponseAction(warehouseListResponse);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  activeWarehouseList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WarehouseListRequestAction>(WarehouseActionTypes.WAREHOUSE_ACTIVE_LIST_REQUEST),
      tap(() => {
        this.logger.debug(`@Effect ${WarehouseActionTypes.WAREHOUSE_ACTIVE_LIST_REQUEST}`);
      }),
      switchMap(action => {
        return this.warehouseService.listActiveWarehouse().pipe(
          map(response => {
            const warehouseListResponse = new WarehouseListResponse();
            const warehouseListArray = [];
            Object.keys(response.data).forEach(deliveryLocationType => {
              response.data[deliveryLocationType].forEach(deliveryLocation => {
                const warehouseObject = {
                  id: null,
                  code: null,
                  name: null,
                  type: null,
                  wmsCode: null,
                  warehouseNameDisplay: null,
                  locationDisplay: null,
                  defaultFirstLot: null
                };
                warehouseObject.id = deliveryLocation.id;
                warehouseObject.code = deliveryLocation.code;
                warehouseObject.name = deliveryLocation.nameTh;
                warehouseObject.wmsCode = deliveryLocation.wmsCode;
                warehouseObject.type = deliveryLocation.__typename;
                warehouseObject.warehouseNameDisplay = `${deliveryLocation.wmsCode}${action.delimiter}${deliveryLocation.nameTh}`;
                warehouseObject.locationDisplay = `${deliveryLocation.wmsCode}${action.delimiter}${deliveryLocation.nameTh}`;
                warehouseObject.defaultFirstLot = deliveryLocation.defaultFirstLot;
                warehouseListArray.push(warehouseObject);
              });
            });
            warehouseListResponse.content = warehouseListArray;
            return new WarehouseActiveListResponseAction(warehouseListResponse);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  selectWarehouseList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SelectWarehouseListRequestAction>(WarehouseActionTypes.SELECT_WAREHOUSES_LIST_REQUEST),
      tap(() => {
        this.logger.debug(`@Effect ${WarehouseActionTypes.SELECT_WAREHOUSES_LIST_REQUEST}`);
      }),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(action => {
        return this.warehouseService.selectWarehouseList(action.payload).pipe(
          map((warehouses: SelectWarehousePagingList) => {
            return action.payload.isPaging
              ? new SelectWarehousePagingListResponseAction(warehouses)
              : new SelectWarehouseListResponseAction(warehouses.masterDtoList);
          }),
          catchError(err => of(new LayoutActionLoadError(err)))
        );
      })
    );
  });
}
