import { createSelector } from '@ngrx/store';

import { BaseForgotUsernameState } from '../reducers/forgot-username.reducer';
import { AppStates } from '../state/app.states';
const selectForgotUsername = (state: AppStates) => state.forgotUsername;

export const selectForgotUsernameResult = createSelector(
  selectForgotUsername,
  (state: BaseForgotUsernameState) => state
);
