<div class="row" [formGroup]="orderSchedule">
  <div
    class="col"
    formArrayName="preOrderScheduleList"
    *ngFor="let tdStore of preOrderScheduleList.controls; let i = index"
  >
    <div [formGroupName]="i">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md mb-3">
              <label for="warehouse">Warehouse<span class="text-danger">*</span></label>
              <ng-select
                id="preOrderWarehouseCode"
                data-id="preOrderWarehouseCode"
                placeholder="Please select..."
                [items]="warehouseList$ | async"
                [searchable]="false"
                [clearable]="false"
                bindLabel="warehouseNameDisplay"
                bindValue="code"
                formControlName="preOrderWarehouseCode"
                [ngClass]="{ 'is-invalid': submitted && tdStore.controls.preOrderWarehouseCode.errors }"
              >
              </ng-select>
              <div
                *ngIf="submitted && tdStore.controls.preOrderWarehouseCode.errors?.required"
                class="invalid-feedback"
              >
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-pre-order-schedule
        #orderPolicySchedule
        [parentForm]="tdStore"
        formPreOrderName="preOrderSchedules"
        [submitted]="submitted"
        [mode]="mode"
        [index]="i"
        [page]="page"
        [ddlPosition]="ddlPosition"
      ></app-pre-order-schedule>
    </div>
  </div>
</div>
