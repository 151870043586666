import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import { StockInformationContent, StockInformationDto, StockInformationSearchCriteria } from '@shared/models';

import { StockInformationAction, StockInformationActionTypes } from './stock-information.actions';
import { StockInformationStates } from './stock-information.states';

export const stockInformationModuleReducers: ActionReducerMap<Partial<StockInformationStates>> = {
  stockInformation: stockInformationReducers
};

export interface StockInformationState extends EntityState<StockInformationContent> {
  isLoading: boolean;
  criteriaObject: StockInformationSearchCriteria;
  stockInformation: StockInformationDto;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<StockInformationContent> = createEntityAdapter<StockInformationContent>();

export const initialStockInformationState: StockInformationState = adapter.getInitialState({
  isLoading: false,
  stockInformation: null,
  criteriaObject: {
    warehouse: null,
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function stockInformationReducers(
  state = initialStockInformationState,
  action: StockInformationAction
): StockInformationState {
  switch (action.type) {
    case StockInformationActionTypes.STOCK_INFORMATION_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StockInformationActionTypes.STOCK_INFORMATION_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StockInformationActionTypes.STOCK_INFORMATION_LIST_RESET:
      return {
        ...initialStockInformationState
      };
    case StockInformationActionTypes.STOCK_INFORMATION_GET_RESPONSE:
      return {
        ...state,
        stockInformation: action.payload
      };

    case StockInformationActionTypes.STOCK_INFORMATION_GET_RESET:
      return {
        ...state,
        stockInformation: null
      };

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
