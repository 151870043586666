import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { TaskAssignmentResponseService } from '../../services/task-assignment-response.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ResponseAssignmentActionType,
  ResponseAssignmentByIdRequestAction,
  ResponseAssignmentByIdResponseAction,
  ResponseAssignmentListRequestAction,
  ResponseAssignmentListResponseAction
} from '../actions/response-assignment.actions';

@Injectable()
export class ResponseAssignmentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly responseAssignmentService: TaskAssignmentResponseService,
    private readonly logger: NGXLogger
  ) {}

  searchResponse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ResponseAssignmentListRequestAction>(ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_LIST_REQUEST),
      tap(action =>
        this.logger.debug(
          `@Effect ${ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_LIST_REQUEST}: ` +
            this.stringify(action.payload)
        )
      ),
      switchMap(action => {
        return this.responseAssignmentService.searchByCriteria(action.payload).pipe(
          map(response => new ResponseAssignmentListResponseAction(response)),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  getTaskResponseById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ResponseAssignmentByIdRequestAction>(ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_GET_BY_ID_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_GET_BY_ID_REQUEST}: ` +
            this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.responseAssignmentService.getTaskResponseById(payload.responseNo).pipe(
          map(res => {
            return new ResponseAssignmentByIdResponseAction(res);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
