import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { MerchantSelectValueRequest } from '../models';

@Injectable()
export class MerchantSelectValueService {
  private readonly env = environment;

  constructor(private readonly http: HttpClient) {}

  public getMerchantSelectValue(merchantSelectValueRequest: MerchantSelectValueRequest): Observable<any> {
    const params = this.getParams(merchantSelectValueRequest);
    const headers: HttpHeaders = new HttpHeaders(this.env.services.merchantSelectValue.headers);

    return this.http.get<any>(this.env.serverUrl + this.env.services.merchantSelectValue.url, {
      headers,
      observe: 'body',
      params
    });
  }
  private getParams(query) {
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(query)) {
      if (query[key]) {
        params = params.append(key.toString(), query[key]);
      }
    }
    return params;
  }
}
