<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="confirm-modal-closeBtn">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-body admin-form">
    <span [innerHTML]="message"></span>
    <br />
    <br />
    {{ label }}<span class="text-danger" *ngIf="isRequiredConfirmMessage">*</span>
    <br />
    <textarea
      [placeholder]="placeholder || label"
      rows="4"
      cols="50"
      class="form-control h-100"
      maxlength="250"
      id="confirmMessage"
      [(ngModel)]="confirmMessage"
      [ngClass]="{ 'is-invalid': showErrorMessage }"
      [placeholder]="placeholder"
    >
    </textarea>
    <div *ngIf="showErrorMessage" class="invalid-feedback">
      {{ 'ERRORS.REQUIRED' | translate }}
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="decline()" id="confirm-modal-cancelBtn">
      {{ cancelText }}
    </button>
    <button type="button" [ngClass]="okClass" (click)="confirm()" id="confirm-modal-okBtn">
      {{ okText }}
    </button>
  </div>
</div>
