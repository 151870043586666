import { BaseReport, Sort } from '../../../shared/models';
import { Activity } from '../../../shared/models/audit-log.model';

export class StoreConsultantSearchCriteria {
  searchCriteria?: string;
  state?: string;
  region?: string;
  active?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export interface AuditLog {
  activity: string;
  description?: string;
  descriptions?: string[];
  editBy: string;
  editByName: string;
  editDate: string;
  activities?: Activity[];
}

export interface StoreConsultantContent {
  id: string;
  userId: string;
  fullName: string;
  noOfStore: string;
  lastModifiedDate: Date;
  active: boolean;
  auditLogs: AuditLog[];
}

export interface StoreConsultantResponse {
  content: StoreConsultantContent[];
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  sort: Sort;
  numberOfElements: number;
  hasContent: boolean;
  totalPages: number;
  last: boolean;
  empty: boolean;
}

export class StoreConsultantModel {
  id: string;
  userId: string;
  fullName: string;
  active: boolean;
  stores: StoreConsultantStore[];
  auditLogs: any;
  version: number;
}

export class StoreConsultantStore {
  code: string;
  name: string;
  region: string;
  regionName: string;
  state: string;
  stateName: string;
  status: string;
}

export class StoreConsultantExportCriteria extends BaseReport {
  state?: string;
  region?: string;
  active?: string;

  constructor(init?: Partial<StoreConsultantExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
