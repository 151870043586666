import { ErrorResponse } from '../../models';
import { ForgotUsername } from '../../models/forgot-username.model';
import { ForgotUsernameActionTypes, ForgotUsernameActions } from '../actions/forgot-username.action';
import { BaseState } from '../state/base.state';

export interface BaseForgotUsernameState extends BaseState {
  result: {
    isSuccess: boolean;
    response: ForgotUsername | null;
    errorResponse: ErrorResponse | null;
    mobileNumber: string | null;
  };
}

export const initialForgotUsernameState: BaseForgotUsernameState = {
  result: {
    isSuccess: false,
    response: null,
    errorResponse: new ErrorResponse(),
    mobileNumber: null
  }
};

export function forgotUsernameReducers(
  state = initialForgotUsernameState,
  action: ForgotUsernameActions
): BaseForgotUsernameState {
  switch (action.type) {
    case ForgotUsernameActionTypes.FORGOT_USERNAME_SUCCESS: {
      return {
        ...state,
        result: {
          ...state.result,
          isSuccess: true,
          response: action.payload.data,
          errorResponse: new ErrorResponse(),
          mobileNumber: action.payload.mobileNumber
        }
      };
    }
    case ForgotUsernameActionTypes.FORGOT_USERNAME_FAILED: {
      return {
        ...state,
        result: {
          ...state.result,
          isSuccess: false,
          response: null,
          errorResponse: action.payload.error,
          mobileNumber: action.payload.mobileNumber
        }
      };
    }
    case ForgotUsernameActionTypes.FORGOT_USERNAME_RESET: {
      return initialForgotUsernameState;
    }
    default: {
      return state;
    }
  }
}
