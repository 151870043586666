<div class="full-popup-head">
  <div class="full-popup-title flex"><em class="modal-title-icon icon-td-order"></em>{{ data.title }}</div>
  <button type="button" class="btn btn-header-button" *ngIf="hasCancelPermission()" (click)="cancelRequest()">
    <em class="icon-forbidden"></em>Cancel Request
  </button>
  <button type="button" class="btn btn-header-button" *ngIf="hasDeletePermission()" (click)="deleteRequest()">
    <em class="icon-delete"></em>Delete
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="data.mode === pageModeEnum.REQUEST_VIEW && (orderRequestView$ | async)?.status !== 'DRAFT'"
    (click)="showHistory()"
  >
    <em class="icon-history"></em>History
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="order-request" class="admin-form">
  <div class="info-header-container">
    <div class="info-header-item" *ngIf="(orderRequestView$ | async)?.requestNo">
      <div class="info-header-label">
        <span>Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (orderRequestView$ | async)?.requestNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Request Status:</span>
      </div>
      <div class="info-header-value">
        <span
          *ngIf="pageModeEnum.REQUEST_CREATE === data.mode; else getStatus"
          [ngClass]="getColorStatus('DRAFT')"
          class="request-status"
        >
          {{ 'ORDER_REQUEST.STATUS.DRAFT' | translate }}
        </span>
        <ng-template #getStatus>
          <span [ngClass]="getColorStatus((orderRequestView$ | async)?.status)" class="request-status">
            {{ 'ORDER_REQUEST.STATUS.' + (orderRequestView$ | async)?.status | translate }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="information-container">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="flex-column pl-2">
          <div class="info-store-type">
            <em [ngClass]="getStoreIcon((orderRequestView$ | async)?.store.storeType)"></em>
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Store</span>
          </div>
          <div class="info-header-value">
            {{ (orderRequestView$ | async)?.store.storeCodeName }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Order Type</span>
          </div>
          <div class="info-header-value">
            {{ 'ORDER_TYPE.' + orderRequest.orderType | translate }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Order Subtype</span>
          </div>
          <div class="info-header-value">
            {{ 'ORDER_SUBTYPE.' + orderRequest.orderSubType | translate }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Warehouse</span>
          </div>
          <div class="info-header-value">
            {{ data.warehouseDisplayName ? data.warehouseDisplayName : '-' }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Order Flow</span>
          </div>
          <div class="info-header-value">
            <ng-container *ngIf="data.mode === pageModeEnum.REQUEST_CREATE; else orderFlowViewMode">
              {{
                data.orderFlow === orderFlowEnum.KEEP_STOCK
                  ? ('ORDER_FLOW.' + data.orderFlow | translate)
                  : (('ORDER_FLOW.' + data.orderFlow | translate) + '-' + data.crossDockSupplier
                    | dashDisplay: '(None)')
              }}
            </ng-container>
            <ng-template #orderFlowViewMode>
              {{ (orderRequestView$ | async)?.orderFlowDisplayName | dashDisplay: '(None)' }}
            </ng-template>
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Requested Date</span>
          </div>
          <div class="info-header-value" *ngIf="orderRequest.requestedDate; else none">
            {{ orderRequest.requestedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
          </div>
          <ng-template #none>
            <div class="info-header-value">
              {{ (orderRequestView$ | async)?.requestedDate | dashDisplay: '(None)' }}
            </div>
          </ng-template>
        </div>

        <div class="col-lg-3">
          <div class="total-amount">
            <div class="text-right">
              <span class="info-total">
                {{ orderRequest.amount?.amount | numberFormatDisplay }}
              </span>
              <span class="info-total-currency">
                THB
              </span>
            </div>
            <div class="info-total-detail">
              <span>(Total Amount before VAT: {{ orderRequest.amountExcVat?.amount | numberFormatDisplay }} | </span
              ><span>VAT: {{ orderRequest.vatAmount?.amount | numberFormatDisplay }})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmitSearch()">
    <div class="d-flex flex-row">
      <div
        *ngIf="data.mode !== pageModeEnum.REQUEST_VIEW && (orderRequestView$ | async)?.status !== 'ASSIGN_QUANTITY'"
      >
        <button
          type="button"
          id="selectShelf"
          class="btn btn-secondary mr-2 my-3"
          (click)="openSelectShelfInventory()"
        >
          <em class="icon-plus"></em>
          Select Shelf Inventory
        </button>
        <button type="button" id="selectItem" class="btn btn-secondary mr-2 my-3" (click)="openSelectiveItem()">
          <em class="icon-plus"></em>
          Selective Item
        </button>
      </div>
      <ng-container *ngIf="hasImportFilePermission()">
        <app-import-export-button
          [buttons]="buttonsSelective"
          (import)="onImportSelectiveItem()"
          class="d-flex align-items-center"
        ></app-import-export-button>
      </ng-container>

      <app-import-export-button
        [buttons]="buttons"
        (export)="onExportProductItem()"
        (import)="onImportOrderSelectiveItemUpdateQty()"
        class="d-flex align-items-center"
      ></app-import-export-button>

      <div class="d-flex ml-auto">
        <div class="filter-search-box ml-2 my-3"></div>
        <button
          type="button"
          class="btn btn-standard ml-2 my-3"
          (click)="showShelfDetails()"
          *ngIf="orderRequest.orderType === orderTypeEnum.FIRST_LOT_ORDER"
        >
          <em class="icon-shelf"></em>
          Shelf Details
        </button>
        <button type="button" id="deliveryDetails" class="btn btn-standard ml-2 my-3" (click)="openDeliveryDetails()">
          <em
            class="fa nicon icon-truck mr-2"
            [ngClass]="{
              success: baseShelfFormArray.controls.length && orderRequest.deliveryStatus === 'success',
              error: !baseShelfFormArray.controls.length || orderRequest.deliveryStatus === 'error'
            }"
          ></em>
          Delivery Details
        </button>
      </div>
    </div>
  </form>

  <ng-container
    *ngIf="
      !filteredShelfFormArray.controls.length &&
      !filteredSelectiveFormArray.controls.length &&
      !searchForm.controls['searchCriteria'].value
    "
  >
    <div class="align-middle">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/no-data.svg" alt="No data" />
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      !filteredShelfFormArray.controls.length &&
      !filteredSelectiveFormArray.controls.length &&
      searchForm.controls['searchCriteria'].value
    "
  >
    <div class="align-middle">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="No item" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="filteredShelfFormArray.controls.length">
    <div class="row section-header justify-content-between mt-3">
      <span>Shelf Inventory</span>
    </div>

    <div class="table-responsive table-scroll my-3" [formGroup]="filteredShelfFormGroup">
      <table id="shelfItemsTable" class="table table-striped table-bordered w-100" aria-label="shelfItemsTable">
        <thead>
          <tr>
            <th class="text-center align-top" scope="col">No.</th>
            <th class="text-center align-top" scope="col">Product Name</th>
            <th class="text-center align-top" scope="col">Article</th>
            <th class="text-center align-top" scope="col">Barcode</th>
            <th class="text-center align-top" scope="col">Product Type</th>
            <th class="text-center align-top" scope="col">Unit</th>
            <th class="text-center align-top min-w-160px" scope="col">Unit Price</th>
            <th class="text-center align-top max-w-150px" scope="col">Quantity</th>
            <th class="text-center align-top min-w-160px" scope="col">Amount (THB)</th>
            <th class="text-center align-top padding-x-0" *ngIf="isShowDeleteColumn()" scope="col">
              <a (click)="deleteShelf()" class="is-link">
                <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
              </a>
            </th>
          </tr>
        </thead>

        <tbody formArrayName="shelfOrderItems">
          <ng-container *ngFor="let item of shelfPaging.currentPageData; index as i">
            <ng-container [formGroupName]="shelfPaging.getIndexNo(i)">
              <tr
                [id]="'shelf-' + shelfPaging.getIndexNo(i)"
                [ngClass]="{
                  'error-background': item.errors?.isSubmitError && data.mode !== pageModeEnum.REQUEST_VIEW
                }"
              >
                <td class="text-center">{{ shelfPaging.getItemNo(i) }}</td>
                <td class="text-left align-top">
                  {{ item.value.productName }}

                  <ng-container *ngIf="item.errors as errors">
                    <span
                      class="d-block invalid-feedback text-danger"
                      *ngIf="errors?.isSubmitError && errors?.field === 'product'"
                    >
                      {{ errors?.isSubmitError }}
                    </span>
                  </ng-container>
                </td>
                <td>{{ item.value.articleNo }}</td>
                <td>
                  {{ item.value.barcode }}

                  <span
                    class="d-block invalid-feedback text-danger"
                    *ngIf="item.errors?.isSubmitError && item.errors?.field === 'barcode'"
                  >
                    {{ item.errors?.isSubmitError }}
                  </span>
                </td>
                <td>{{ 'PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + item.value.productType | translate }}</td>
                <td class="text-center">{{ item.value.unit }}</td>
                <td class="text-right">{{ item.value.unitPrice.amount | numberFormatDisplay }}</td>
                <td class="text-center td-input-column-150">
                  <app-numeric-textbox
                    id="{{ 'shelf-quantity-item-' + shelfPaging.getIndexNo(i) }}"
                    class="td-numeric-textbox-center"
                    formControlName="qty"
                    [negativeSign]="false"
                    placeholder="Quantity"
                    [format]="'0'"
                    [decimals]="0"
                    maxlength="5"
                    [minValue]="1"
                    [maxValue]="99999"
                    [allowPaste]="false"
                    [ngClass]="{
                      'is-invalid':
                        submitted && (item?.get('qty').errors?.isZero || item?.get('qty').errors?.required)
                    }"
                    [disabled]="item.errors?.isSubmitError && data.mode !== pageModeEnum.REQUEST_VIEW"
                    (blur)="updateValueChangesEvent($event, item.value)"
                  >
                  </app-numeric-textbox>
                  <div *ngIf="submitted && item?.get('qty').errors" class="invalid-display text-danger text-left">
                    <div *ngIf="item?.get('qty').errors?.isZero">
                      {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                    </div>
                    <div *ngIf="item?.get('qty').errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </td>
                <td class="text-right total-amount-bg">
                  {{ item.value.amount.amount | numberFormatDisplay }}
                </td>
                <td class="text-center padding-x-0" *ngIf="isShowDeleteColumn()">
                  <ng-container *ngIf="item.errors as errors">
                    <a *ngIf="errors?.isSubmitError" (click)="deleteShelf(shelfPaging.getIndexNo(i))">
                      <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
                    </a>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-ui-pagination
      #shelfPaging
      [(currentPage)]="currentShelfPage"
      [(pageSize)]="shelfPageSize"
      [items]="filteredShelfFormArray.controls"
      [valueChangeDetector]="filteredShelfFormArray.valueChanges | async"
    >
    </app-ui-pagination>
  </ng-container>

  <ng-container *ngIf="filteredSelectiveFormArray.controls.length">
    <div class="section-header justify-content-between clearfix mt-3">
      <span>Selective Item</span>
    </div>

    <div
      class="table-responsive table-scroll my-3"
      *ngIf="filteredSelectiveFormArray.controls.length"
      [formGroup]="filteredSelectiveFormGroup"
    >
      <table
        id="selectiveItemsTable"
        class="table table-striped table-bordered w-100"
        aria-label="selectiveItemsTable"
      >
        <thead>
          <tr>
            <th class="text-center align-top" scope="col">No.</th>
            <th class="text-center align-top" scope="col">Product Name</th>
            <th class="text-center align-top" scope="col">Article</th>
            <th class="text-center align-top" scope="col">Barcode</th>
            <th class="text-center align-top" scope="col">Product Type</th>
            <th class="text-center align-top" scope="col">Unit</th>
            <th class="text-center align-top min-w-160px" scope="col">Unit Price</th>
            <th class="text-center align-top max-w-150px" scope="col">Quantity</th>
            <th class="text-center align-top min-w-160px" scope="col">Amount (THB)</th>
            <th class="text-center align-top padding-x-0" *ngIf="isShowDeleteColumn()" scope="col">
              <a (click)="deleteItemSelective()" class="is-link">
                <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
              </a>
            </th>
          </tr>
        </thead>

        <tbody formArrayName="selectiveOrderItems">
          <ng-container *ngFor="let item of selectivePaging.currentPageData; index as i">
            <ng-container [formGroupName]="selectivePaging.getIndexNo(i)">
              <tr
                [id]="'selective-' + selectivePaging.getIndexNo(i)"
                [ngClass]="{
                  'error-background':
                    ((submitted && item.errors?.duplicated) || item.errors?.isSubmitError) &&
                    data.mode !== pageModeEnum.REQUEST_VIEW
                }"
              >
                <td class="text-center">{{ selectivePaging.getItemNo(i) }}</td>
                <td class="text-left align-top">
                  {{ item.value.productName }}

                  <ng-container *ngIf="item.errors as errors">
                    <span class="d-block invalid-feedback text-danger" *ngIf="submitted && errors?.duplicated">
                      {{ 'Duplicated in shelf.' }}
                    </span>
                    <span
                      class="d-block invalid-feedback text-danger"
                      *ngIf="
                        !(submitted && errors?.duplicated) && errors?.isSubmitError && errors?.field === 'product'
                      "
                    >
                      {{ errors?.isSubmitError }}
                    </span>
                  </ng-container>
                </td>
                <td>{{ item.value.articleNo }}</td>
                <td>
                  {{ item.value.barcode }}

                  <span
                    class="d-block invalid-feedback text-danger"
                    *ngIf="item.errors?.isSubmitError && item.errors?.field === 'barcode'"
                  >
                    {{ item.errors?.isSubmitError }}
                  </span>
                </td>
                <td>{{ 'PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + item.value.productType | translate }}</td>
                <td class="text-center">{{ item.value.unit }}</td>
                <td class="text-right">{{ item.value.unitPrice.amount | numberFormatDisplay }}</td>
                <td class="text-center td-input-column-150">
                  <app-numeric-textbox
                    id="{{ 'selective-quantity-item-' + selectivePaging.getIndexNo(i) }}"
                    class="td-numeric-textbox-center"
                    formControlName="qty"
                    [negativeSign]="false"
                    placeholder="Quantity"
                    [format]="'0'"
                    [decimals]="0"
                    maxlength="5"
                    [minValue]="1"
                    [maxValue]="99999"
                    [allowPaste]="false"
                    [ngClass]="{
                      'is-invalid':
                        submitted && (item?.get('qty').errors?.isZero || item?.get('qty').errors?.required)
                    }"
                    [disabled]="item.errors?.isSubmitError && data.mode !== pageModeEnum.REQUEST_VIEW"
                    (blur)="updateValueSelectiveChangesEvent($event, item.value)"
                  >
                  </app-numeric-textbox>
                  <div *ngIf="submitted && item?.get('qty').errors" class="invalid-display text-danger text-left">
                    <div *ngIf="item?.get('qty').errors?.isZero">
                      {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                    </div>
                    <div *ngIf="item?.get('qty').errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </td>
                <td class="text-right total-amount-bg">
                  {{ item.value.amount.amount | numberFormatDisplay }}
                </td>
                <td class="text-center padding-x-0 " *ngIf="isShowDeleteColumn()">
                  <a (click)="deleteItemSelective(selectivePaging.getIndexNo(i))">
                    <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
                  </a>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-ui-pagination
      #selectivePaging
      [(currentPage)]="currentSelectivePage"
      [(pageSize)]="selectivePageSize"
      [items]="filteredSelectiveFormArray.controls"
      [valueChangeDetector]="filteredSelectiveFormArray.valueChanges | async"
    >
    </app-ui-pagination>
  </ng-container>

  <!--footer-->
  <ng-container *ngIf="hasAtLeastOnePermission()">
    <ng-container *ngIf="data.mode !== pageModeEnum.REQUEST_VIEW; else otherMode">
      <div class="fixed-row-bottom form-row">
        <div class="float-left" *ngIf="hasSavePermission()">
          <button type="button" id="btn-save" class="btn btn-secondary" (click)="onSave()" [disabled]="isLoading">
            Save
          </button>
        </div>
        <div class="float-right" *ngIf="hasSubmitPermission()">
          <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
          <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()" [disabled]="isLoading">
            Submit
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #otherMode>
      <ng-container>
        <div class="fixed-row-bottom form-row">
          <div class="float-left"></div>
          <div class="float-right">
            <span *ngIf="hasEditPermission()" class="mr-2">
              <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
            </span>
            <span *ngIf="hasApprovePermission()">
              <button
                type="button"
                id="btn-reject"
                class="btn btn-special-reject mr-2"
                (click)="doApproveRejectRequest(false)"
              >
                Reject
              </button>
              <button
                type="button"
                id="btn-approve"
                class="btn btn-special-approve"
                (click)="doApproveRejectRequest(true)"
              >
                Approve
              </button>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <!--End footer-->
</div>

<!-- Import Result -->
<div class="modal fade" bsModal #importResultModel="bs-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pull-left">{{ importResult?.title }}</h6>
      </div>
      <div class="modal-body">
        <div
          *ngIf="importResult && importResult.table && importResult.table.length > 0"
          class="max-height-table table-responsive"
        >
          <table class="table table-striped table-bordered table-hover w-100" aria-hidden="true">
            <thead>
              <tr class="error-background text-center">
                <th scope="col">Row</th>
                <th scope="col">Column</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of importResult.table; index as i">
                <td class="text-center">{{ item.row }}</td>
                <td>{{ item.column }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center" *ngIf="importResult && importResult.message">
          {{ importResult.message }}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="importResultModel.hide()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
