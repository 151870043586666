import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { environment } from '../../../../environments/environment';
import { FileSubmitBeforeUploadComponent } from '../../../shared/components/file-submit-before-upload/file-submit-before-upload.component';
import { UploadStatusEnum } from '../../../shared/enum/upload-status.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { PromotionRequestService } from '../../../shared/services/promotion-request.service';
import { b64toBlob } from '../../../shared/utils/b64toBlob-util';

@Component({
  selector: 'app-promotion-modal-upload',
  templateUrl: './promotion-modal-upload.component.html',
  styleUrls: ['./promotion-modal-upload.component.scss']
})
export class PromotionModalUploadComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @ViewChild(FileSubmitBeforeUploadComponent, { static: false })
  fileUpload: FileSubmitBeforeUploadComponent;

  @Output() submitUpload: EventEmitter<any> = new EventEmitter();

  public uploadStatus = UploadStatusEnum;
  public form: UntypedFormGroup;
  public errors = [];
  public errorMessage: string;

  public fileList: any;
  public errorFile: Blob;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    protected modalService: BsModalService,
    protected readonly promotionRequestService: PromotionRequestService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  createForm() {
    this.form = this.fb.group({
      storeUploadItems: [null]
    });
  }

  downloadTemplate() {
    this.promotionRequestService.onExportTemplate().subscribe({
      next: response => {
        this.saveFileTemplate(response);
      },
      error: error => {
        this.modalService.show(AlertModalComponent, {
          initialState: {
            title: 'Failed',
            message: error.error.message
          }
        });
      }
    });
  }

  generateFileName() {
    const fileConfig = environment.fileName.exportPromotionStoreRequest;

    return `${fileConfig} ${this.timeToExport}`;
  }

  saveFileTemplate(response: Blob) {
    const blob = new Blob([response]);
    saveAs(blob, `${environment.fileName.exportPromotionStoreRequest.fileTemplate}.xlsx`);
  }

  onDownloadErrorExcel() {
    saveAs(this.errorFile, this.fileList.value[0].name);
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportItemCondition.timeFormat);
  }

  onUpload($event) {
    this.fileList = $event.files;
    this.errorFile = null;
    this.promotionRequestService.importFilePromotionStore(this.fileList.value[0]).subscribe({
      next: res => {
        this.submitUpload.emit(res);
        this.bsModalRef.hide();
      },
      error: errorResponse => {
        this.form.get('storeUploadItems').setErrors({ importFail: true });
        if (errorResponse.error.fileBase64String) {
          this.errorMessage = `${errorResponse.error.rowErrorNo} error found. Download this file to edit or delete invalid row then re-import.`;
          this.errorFile = b64toBlob(errorResponse.error.fileBase64String);
        } else {
          this.errorMessage = errorResponse.error.message;
        }
      }
    });
  }
}
