import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { DeliveryByTypeEnum } from '../../../../shared/enum/delivery-by-type.enum';
import { OrderContent } from '../../../../shared/models/order.model';
import { selectViewOrder } from '../../../../shared/store/selectors/order.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-receive-summary',
  templateUrl: './receive-summary.component.html',
  styleUrls: ['./receive-summary.component.scss']
})
export class ReceiveSummaryComponent extends OnDestroyMixin implements OnInit, AfterContentChecked {
  private localStore: Observable<any>;
  viewOrder$: Observable<OrderContent>;
  deliveryByEnum = DeliveryByTypeEnum;
  currentPage = 1;
  pageSize = 20;

  constructor(public readonly store: Store<AppStates>, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.viewOrder$ = this.localStore.pipe(
      select(selectViewOrder),
      distinctUntilChanged(),
      filter(viewOrder => viewOrder !== null)
    );
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
}
