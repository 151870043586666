<div class="table-scroll">
  <table
    id="data-table"
    class="table table-striped table-hover w-100"
    [formGroup]="parentForm"
    aria-label="data-table"
  >
    <thead>
      <tr>
        <th class="text-center w-25" scope="col">POI Type<span class="text-danger">*</span></th>
        <th class="text-center w-25" scope="col">POI Name<span class="text-danger">*</span></th>
        <th class="text-center w-25" scope="col">Distance<span class="text-danger">*</span></th>
        <th class="text-center w-10" *ngIf="!nearByPois.disabled" scope="col">Action</th>
      </tr>
    </thead>
    <tbody formArrayName="nearByPois">
      <tr *ngFor="let tdStore of nearByPois.controls; index as i" [formGroupName]="i">
        <td class="p-1 w-25">
          <ng-select
            name="poiType"
            placeholder="Please select..."
            [items]="poiSelectValue"
            [searchable]="false"
            [clearable]="false"
            appendTo=".store-profile-wrapper"
            bindLabel="nameTh"
            bindValue="code"
            formControlName="poiType"
            (change)="onSelectedPoi(i)"
            (open)="disableTableScroll(true)"
            (close)="disableTableScroll(false)"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.poiType.errors
            }"
          ></ng-select>
          <div *ngIf="submitted && tdStore.controls.poiType.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.poiType.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </td>
        <td class="p-1 w-25">
          <input
            name="poiName"
            type="text"
            class="form-control"
            maxlength="100"
            formControlName="poiName"
            placeholder="Name"
            (change)="onSelectedPoi(i)"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.poiName.errors
            }"
          />
          <div *ngIf="submitted && tdStore.controls.poiName.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.poiName.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </td>
        <td class="p-1 w-25">
          <ng-select
            name="distance"
            placeholder="Please select..."
            [items]="poiDistanceSelectValue"
            [searchable]="false"
            [clearable]="false"
            appendTo=".store-profile-wrapper"
            bindLabel="nameTh"
            bindValue="code"
            formControlName="distance"
            (change)="onSelectedPoi(i)"
            (open)="disableTableScroll(true)"
            (close)="disableTableScroll(false)"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.distance.errors
            }"
          ></ng-select>
          <div *ngIf="submitted && tdStore.controls.distance.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.distance.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </td>
        <td class="p-1 w-10 text-center align-middle" *ngIf="!nearByPois.disabled">
          <a class="btn-text-link" (click)="deleteNearByPoi(i)">
            <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="row">
  <div class="col-md-12">
    <button type="button" id="add" *ngIf="!checkEditPermission()" class="btn btn-secondary mt-2" (click)="addForm()">
      <em class="icon-plus"></em>
      Add More
    </button>
  </div>
</div>
