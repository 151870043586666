import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetricActionType } from '@shared/models/metrics';

import { PromotionCompensateReportExportCriteria, ReportFileType } from '../models/report.model';
import { metricsReportDataHub } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable()
export class PromotionCompensateReportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.promotionCompensateReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  reportExport(reportCriteria: PromotionCompensateReportExportCriteria) {
    const url = this.getFullUrl(this.envService.export);
    const exportType =
      reportCriteria.fileType === ReportFileType.CSV ? MetricActionType.EXPORT_CSV : MetricActionType.EXPORT_XLSX;
    const metrics = metricsReportDataHub([this.envService.url, this.envService.export], exportType);

    return this.http.post<any>(url, reportCriteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
