import gql from 'graphql-tag';

import { MasterData } from '../models';

export class WarehouseConditionMasterData {
  preorderWarehouseTypes: PreorderWarehouseTypes[];
}

export class PreorderWarehouseTypes extends MasterData {
  active: boolean;
}

export const warehouseMasterQuery = gql`
  query {
    warehouses {
      code
      nameEn
      nameTh
      contactName
      contactNumber
      address
      fullNameTh
      fullNameEn
      wmsCode
      billTo {
        code
        nameEn
        nameTh
        contactName
        contactNumber
        address
      }
    }
  }
`;

export const warehouseQuery = gql`
  query {
    offices {
      id
      code
      nameTh
    }
    warehouses {
      id
      code
      nameTh
      wmsCode
      defaultFirstLot
    }
  }
`;

export const warehouseConditionMasterQuery = gql`
  query {
    preorderWarehouseTypes {
      nameEn
      code
      active
    }
  }
`;

export const activeWarehouseQuery = gql`
  query {
    warehouses(status: "ACTIVE") {
      id
      code
      nameTh
      wmsCode
      defaultFirstLot
    }
  }
`;
