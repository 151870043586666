import * as moment from 'moment';

import { environment } from '../../../environments/environment';

export const generatedFilenamePdf = (data: string) => {
  const date = new Date();
  const formattedDate = moment(date).format(environment.fileName.downloadPdf.timeFormat);
  const formattedName = data ? data.replace('/', '_') : '';
  return `${formattedName}_${formattedDate}`;
};
