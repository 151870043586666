import { Directive, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { selectHideAllModal } from '@shared/store/selectors/hide-modal.selector';
import { hideAllModal } from '@shared/utils/hide-modal-util';

import { ModalButtonResponseEnum } from '../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../shared/layouts';
import { LayoutActionSaveSuccess, LayoutActionVersionError } from '../shared/store/actions/layout.action';
import { selectSaveSuccess, selectVersionError } from '../shared/store/selectors/layout-selector';
import { AppStates } from '../shared/store/state/app.states';

/* tslint:disable:directive-class-suffix */
@Directive()
export abstract class BaseComponent<T = any> extends OnDestroyMixin implements OnDestroy {
  protected subscriptions: Subscription = new Subscription();
  public bsDateConfig: BsDatepickerConfig;

  protected constructor(
    protected readonly store: Store<AppStates | T>,
    protected readonly modalService: BsModalService,
    protected isChildComponent = false
  ) {
    super();

    if (this.isChildComponent) {
      this.subscribeForSaveSuccess();
    } else {
      this.subscribeForVersionError();
    }

    this.subscribeForHideAllModal();

    this.bsDateConfig = {
      containerClass: 'theme-dark-blue',
      dateInputFormat: 'DD/MM/YYYY',
      showWeekNumbers: false,
      adaptivePosition: true
    } as BsDatepickerConfig;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.unsubscribeBase();
  }

  subscribeForVersionError() {
    // Listen for Version Error
    this.subscriptions.add(
      this.store
        .pipe(
          select(selectVersionError),
          filter(isError => isError),
          untilComponentDestroyed(this)
        )
        .subscribe(() => {
          this.alertForVersionErrorModal();
        })
    );
  }

  subscribeForSaveSuccess(isShowSuccess = true) {
    this.subscriptions.add(
      this.store
        .pipe(
          select(selectSaveSuccess),
          filter(saveSuccess => saveSuccess),
          untilComponentDestroyed(this)
        )
        .subscribe(success => {
          if (success && success.isSuccess) {
            if (isShowSuccess) {
              this.alertForSaveSuccessModal(success);
            } else {
              this.doAfterSuccessModal();
            }
            this.store.dispatch(new LayoutActionSaveSuccess(null));
          }
        })
    );
  }

  subscribeForHideAllModal() {
    this.subscriptions.add(
      this.store.pipe(select(selectHideAllModal), untilComponentDestroyed(this)).subscribe(isHideAllModal => {
        if (isHideAllModal) {
          hideAllModal(this.modalService);
        }
      })
    );
  }

  alertForSaveSuccessModal(saveSuccess: any) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: saveSuccess.title,
        message: saveSuccess.message,
        isRefresh: false,
        routerLink: saveSuccess.routerLink
      },
      backdrop: 'static'
    });
    alertModal.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK)
      )
      .subscribe(() => {
        alertModal.hide();
        this.doAfterSuccessModal();
      });
  }

  alertForVersionErrorModal() {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message: 'The current session is no longer because data has been changed.',
        isRefresh: false
      }
    });
    alertModal.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK)
      )
      .subscribe(() => {
        alertModal.hide();
        this.doAfterVersionAlertModal();
      });

    this.store.dispatch(new LayoutActionVersionError(false));
  }

  getValue(value: any) {
    return this.isEmpty(value) ? null : value;
  }

  isEmpty(value: string) {
    return value === undefined || value === null || value === '';
  }

  // All component inherit from this base component
  // must call this function in its ngOnDestroy
  unsubscribeBase() {
    this.subscriptions.unsubscribe();
  }

  abstract doAfterVersionAlertModal();

  doAfterSuccessModal() {
    // intentionally empty
  }
}
