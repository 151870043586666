import { createSelector } from '@ngrx/store';

import * as fromOrderSelectiveSelectItemState from '../reducers/order-selective-select-item.reducers';
import { AppStates } from '../state/app.states';

const selectorderSelectiveSelectItemState = (state: AppStates) => state.orderSelectiveSelectItem;

export const selectAllOrderSelectiveSelectItemList = createSelector(
  selectorderSelectiveSelectItemState,
  fromOrderSelectiveSelectItemState.selectAll
);

export const selectOrderSelectiveSelectItemList = createSelector(
  selectorderSelectiveSelectItemState,
  (state: fromOrderSelectiveSelectItemState.OrderSelectiveSelectItemState) => state
);
