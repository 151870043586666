import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { RouteLinkTab } from '../../models';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit, OnDestroy {
  @Input() routeLinkTabs: Array<RouteLinkTab>;
  @Input() notRefresh: boolean;
  @Input() refresh: boolean;
  currentUrl: string;
  currentTab: string;
  subscription = new Subscription();
  constructor(private readonly router: Router) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.routeLinkTabs.forEach(routeLinkTab => {
      if (this.currentUrl.startsWith(routeLinkTab.url) && !this.currentTab) {
        this.currentTab = routeLinkTab.tabName;
      }
      // this.subscription.add(
      //   this.translate.get(routeLinkTab.tabName).subscribe(value => (routeLinkTab.tabName = value))
      // );
    });
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onClickTab(obj: any) {
    this.currentTab = obj.tabName;
  }

  handleActiveUrl(url: string, tabName: string): boolean {
    if (this.notRefresh) {
      if (this.routeLinkTabs.length === 1) {
        return true;
      }
      this.currentUrl = this.router.url;
    }

    this.currentUrl = this.currentUrl.split('?').length > 1 ? this.currentUrl.split('?')[0] : this.currentUrl;

    if (!this.refresh) {
      return this.currentUrl === url;
    }

    if (this.currentTab === tabName) {
      return true;
    }
    return false;
  }
}
