import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { ProductOrderItem } from '../models/order-request.model';
import {
  ShelfCreateRequest,
  ShelfRequestViewResponse,
  ShelfSelectFixAssetSearchCriteria
} from '../models/shelf-request.model';
import { ShelfListResponse, ShelfListSearchCriteria, ShelfNo, ShelfViewResponse } from '../models/shelf.model';
import { BaseService } from './base.service';

@Injectable()
export class ShelfService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.shelf;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: ShelfListSearchCriteria): Observable<ShelfListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.SHELF,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public getHistoryLogs(shelfNo: ShelfNo): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      shelfNo: shelfNo.shelfNo
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getShelfById(shelfNo: ShelfNo): Observable<ShelfViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      shelfNo: shelfNo.shelfNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public submitFirstLot(shelf: ShelfCreateRequest): Observable<ShelfRequestViewResponse> {
    const url = this.getFullUrl(this.envService.submitFirstLot);

    return this.http.post<ShelfRequestViewResponse>(url, shelf, { headers: this.loaderHeaders() });
  }

  public activateShelf(shelfNo: ShelfNo, activate: boolean): Observable<any> {
    const url = this.getFullUrl(this.envService.activate, {
      shelfNo: shelfNo.shelfNo
    });
    const params = this.getParams({ activate });

    return this.http.post<ShelfRequestViewResponse>(url, null, { headers: this.headers, observe: 'body', params });
  }

  public generateShelfItems(selected: string[], storeNo: string): Observable<ProductOrderItem[]> {
    const url = this.getFullUrl(this.envService.generateItems);
    const selectedShelf = {
      storeNo,
      shelfNo: selected
    };

    return this.http.post<ProductOrderItem[]>(url, selectedShelf, { headers: this.headers });
  }

  public searchShelfFixAssetByCriteria(criteria: ShelfSelectFixAssetSearchCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.catalogs);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }
}
