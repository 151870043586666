import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOption } from '@ng-select/ng-select';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import { CriteriaUtil } from '@shared/utils/criteria-util';

import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../../shared/layouts/modals/alert-modal/alert-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  PromotionContent,
  PromotionRequestPageModes,
  PromotionSearchCriteria,
  RouteLinkTab
} from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import { PromotionCancelRequest, PromotionListRequestAction } from '../../../shared/store/actions/promotion.action';
import { PromotionState } from '../../../shared/store/reducers/promotion.reducers';
import {
  selectAllPromotionList,
  selectPromotionList,
  selectPromotionListCriteria
} from '../../../shared/store/selectors/promotion.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { PromotionViewComponent } from '../promotion-view/promotion-view.component';

@Component({
  selector: 'app-promotion-list',
  templateUrl: './promotion-list.component.html',
  styleUrls: ['./promotion-list.component.scss']
})
export class PromotionListComponent extends BaseSearchComponent<
  PromotionSearchCriteria,
  PromotionContent,
  PromotionState
> {
  public minDate: Date;
  public maxDate: Date;
  public expireMaxDate: Date;
  public expireMinDate: Date;
  public validMaxDate: Date;
  public validMinDate: Date;
  public createdMaxDate: Date;
  public createdMinDate: Date;

  public createdDateTag: string;
  public createdDateStringTag: string;
  public effectiveDateTag: string;
  public effectiveDateStringTag: string;
  public expireDateTag: string;
  public expireDateStringTag: string;
  public validDateTag: string;
  public validDateStringTag: string;
  public promotionTypeTag: string;
  public promotionTypeStringTag: string;

  private bsModalRef: BsModalRef;
  public currentPage: number;
  private localStore: Observable<any>;
  listRoute: Array<RouteLinkTab>;
  promotionTypeList: NgOption[];
  isShowView: boolean;
  hasViewPromotionPermission: boolean;
  hasEditPromotionPermission: boolean;
  hasSearchCriteria: boolean;
  dateFormat = environment.dateFormat;

  public statusList = filterDropdown.promotionStatusFilter;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: UntypedFormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    private readonly router: Router,
    protected readonly logger: NGXLogger
  ) {
    super(store, modalService, selectAllPromotionList, selectPromotionList);
    super.subscribeForSaveSuccess();

    this.hasViewPromotionPermission = this.authGuardService.checkPermission(['promotion_v']);
    this.hasEditPromotionPermission = this.authGuardService.checkPermission(['promotion_m']);

    if (!this.hasEditPromotionPermission && !this.hasViewPromotionPermission) {
      this.router.navigateByUrl('campaign/promotion-request-list');
    }

    this.isShowView = false;
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  setInitialValue() {
    this.pageSize = 20;
    const todayDate = new Date();

    this.maxDate = todayDate;
    this.expireMaxDate = todayDate;
    this.validMaxDate = todayDate;
    this.createdMaxDate = todayDate;
    this.promotionTypeList = filterDropdown.promotionType;
    this.maxDate.setDate(todayDate.getDate() + 365);
    this.expireMaxDate.setDate(todayDate.getDate() + 365);
    this.validMaxDate.setDate(todayDate.getDate() + 365);
    this.createdMaxDate.setDate(todayDate.getDate() + 365);
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.effectiveDateFrom &&
      !formValue.effectiveDateTo &&
      !formValue.expireDateFrom &&
      !formValue.expireDateTo &&
      !formValue.validDateFrom &&
      !formValue.validDateTo &&
      !formValue.createdDateFrom &&
      !formValue.createdDateTo &&
      !formValue.promotionType
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      effectiveDateFrom: dateToStringCriteria(formValue.effectiveDateFrom),
      effectiveDateTo: dateToStringCriteria(formValue.effectiveDateTo, false),
      validDateFrom: dateToStringCriteria(formValue.validDateFrom),
      validDateTo: dateToStringCriteria(formValue.validDateTo, false),
      expireDateFrom: dateToStringCriteria(formValue.expireDateFrom),
      expireDateTo: dateToStringCriteria(formValue.expireDateTo, false),
      createdDateFrom: dateToStringCriteria(formValue.createdDateFrom),
      createdDateTo: dateToStringCriteria(formValue.createdDateTo, false),
      promotionType: formValue.promotionType,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.hasSearchCriteria = CriteriaUtil.hasCriteria(this.criteriaObject);
    this.store.dispatch(new PromotionListRequestAction(criteriaObj));
  }

  goToView(viewParams?: any) {
    this.isShowView = true;
    const initialState = {
      title: null,
      childItem: new ChildItem(
        PromotionViewComponent,
        {
          title: 'View Promotion',
          mode: PromotionRequestPageModes.VIEW,
          promotionCode: viewParams.promotionCode
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectPromotionListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  doDestroy() {
    // intentionally empty
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      effectiveDateFrom: [null],
      effectiveDateTo: [null],
      expireDateFrom: [null],
      validDateFrom: [null],
      validDateTo: [null],
      expireDateTo: [null],
      createdDateFrom: [null],
      createdDateTo: [null],
      promotionType: [null]
    });
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  clearFilterEffectiveDate() {
    this.setFirstPage();
    this.searchForm.controls['effectiveDateFrom'].reset();
    this.searchForm.controls['effectiveDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      effectiveDateFrom: null,
      effectiveDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterExpireDate() {
    this.setFirstPage();
    this.searchForm.controls['expireDateFrom'].reset();
    this.searchForm.controls['expireDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      expireDateFrom: null,
      expireDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterValidDate() {
    this.setFirstPage();
    this.searchForm.controls['validDateFrom'].reset();
    this.searchForm.controls['validDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      validDateFrom: null,
      validDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterPromotionType() {
    this.setFirstPage();
    this.searchForm.controls['promotionType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      promotionType: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterCreateDate() {
    this.setFirstPage();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.setFirstPage();
    this.searchForm.controls['effectiveDateFrom'].reset();
    this.searchForm.controls['effectiveDateTo'].reset();
    this.searchForm.controls['expireDateFrom'].reset();
    this.searchForm.controls['expireDateTo'].reset();
    this.searchForm.controls['validDateFrom'].reset();
    this.searchForm.controls['validDateTo'].reset();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.searchForm.controls['promotionType'].reset();

    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      effectiveDateFrom: null,
      effectiveDateTo: null,
      expireDateFrom: null,
      expireDateTo: null,
      validDateFrom: null,
      validDateTo: null,
      createdDateFrom: null,
      createdDateTo: null,
      promotionType: null
    };

    this.doSearch(this.criteriaObject);
  }

  onChangeExpireDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.expireMinDate = new Date(value);
    } else {
      this.expireMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExpireDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.expireMaxDate = new Date(value);
    } else {
      this.expireMaxDate = new Date();
      this.expireMaxDate.setDate(this.expireMaxDate.getDate() + 365);
    }
  }

  onChangeValidDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.validMinDate = new Date(value);
    } else {
      this.validMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeValidDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.validMaxDate = new Date(value);
    } else {
      this.validMaxDate = new Date();
      this.validMaxDate.setDate(this.validMaxDate.getDate() + 365);
    }
  }

  onChangeCreatedDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.createdMinDate = new Date(value);
    } else {
      this.createdMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeCreatedDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.createdMaxDate = new Date(value);
    } else {
      this.createdMaxDate = new Date();
      this.createdMaxDate.setDate(this.createdMaxDate.getDate() + 365);
    }
  }

  prepareSearchCriteriaTags() {
    this.effectiveDateTag = null;
    this.effectiveDateStringTag = null;
    this.expireDateTag = null;
    this.expireDateStringTag = null;
    this.createdDateTag = null;
    this.createdDateStringTag = null;
    this.promotionTypeStringTag = null;
    this.validDateStringTag = null;
    this.validDateTag = null;

    const effectiveDateFrom = dateStringToTagCriteria(this.criteriaObject.effectiveDateFrom);
    const effectiveDateTo = dateStringToTagCriteria(this.criteriaObject.effectiveDateTo);
    const effectiveDate = generateDateStringTag({
      dateName: 'Effective Date',
      dateFrom: effectiveDateFrom,
      dateTo: effectiveDateTo
    });

    this.effectiveDateStringTag = effectiveDate.dateStringTag;
    this.effectiveDateTag = effectiveDate.dateTag;

    const expireDateFrom = dateStringToTagCriteria(this.criteriaObject.expireDateFrom);
    const expireDateTo = dateStringToTagCriteria(this.criteriaObject.expireDateTo);
    const expireDate = generateDateStringTag({
      dateName: 'Expire Date',
      dateFrom: expireDateFrom,
      dateTo: expireDateTo
    });

    this.expireDateStringTag = expireDate.dateStringTag;
    this.expireDateTag = expireDate.dateTag;

    const validDateFrom = dateStringToTagCriteria(this.criteriaObject.validDateFrom);
    const validDateTo = dateStringToTagCriteria(this.criteriaObject.validDateTo);
    const validDate = generateDateStringTag({
      dateName: 'Valid Date',
      dateFrom: validDateFrom,
      dateTo: validDateTo
    });

    this.validDateStringTag = validDate.dateStringTag;
    this.validDateTag = validDate.dateTag;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'Created Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.createdDateStringTag = createdDate.dateStringTag;
    this.createdDateTag = createdDate.dateTag;

    if (this.criteriaObject.promotionType && this.criteriaObject.promotionType.length > 0) {
      this.promotionTypeStringTag = 'Promotion Type';

      const listTags = this.criteriaObject.promotionType
        .map(value => filterDropdown.promotionType.find(obj => obj.value === value).label)
        .join(', ');

      this.promotionTypeTag = `"${listTags}"`;
    }
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission(['promotion_m', 'promotion_v']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['promotion_m', 'promotion_app']);
    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Promotion List', url: '/campaign/promotion-list' });
    }
    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Promotion Request', url: '/campaign/promotion-request-list' });
    }
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  showCancelButton(status: string): boolean {
    if (status.toLocaleLowerCase() === 'awaiting_schedule' || status.toLocaleLowerCase() === 'active') {
      return true;
    }

    return false;
  }

  goToCancel(item: any) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel promotion code <strong>&quot;${item.promotionCode}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PromotionCancelRequest({
              id: item.promotionCode,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  alertModalSuccess() {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: 'This promotion has been cancelled.'
      },
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.search(this.criteriaObject);
        alertModal.hide();
      }
    });
  }

  alertErrorModal(errorResponse) {
    if (errorResponse && errorResponse.error && errorResponse.error.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.error.translateKey
      };
      initialState.message = this.translate.instant(errorResponse.error.translateKey);

      this.modalService.show(AlertModalComponent, {
        initialState
      });
    }
  }

  showHistory(item: any) {
    this.modalService.show(HistoryComponent, {
      initialState: {
        action: HistoryType.REQUEST,
        title: 'History',
        historyHeader: `Promotion Code: ${item.promotionCode}`,
        historyType: HistoryType.PROMOTION,
        auditLogs: item.auditLogs
      }
    });
  }
}
