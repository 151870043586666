import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { formatDate } from '../utils/date-util';

@Pipe({ name: 'dateDisplay' })
export class DateDisplayPipe implements PipeTransform {
  transform(date: any, format = environment.dateFormat, text = '-') {
    if (moment.isMoment(date)) {
      return date.isValid() ? formatDate(date, format) : text;
    } else {
      return date ? formatDate(date, format) : text;
    }
  }
}
