import { AbstractControl, ValidatorFn } from '@angular/forms';

export function isZeroValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    return control.value !== null && !isNaN(+control.value) && +control.value === 0 ? { isZero: true } : null;
  };
}

export function notAllowZeroValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    return control.value !== null && Number(control.value) === 0 ? { isZero: true } : null;
  };
}
