import { Action } from '@ngrx/store';

import { ImportOrderRequest, ProductOrderItem } from '../../models/order-request.model';

export enum OrderSelectiveSelectItemOrderActionTypes {
  ORDER_SELECTIVE_SELECT_ITEM_ORDER_ADD_ITEM = '[Order Select Item Selective] Add Item',
  ORDER_SELECTIVE_SELECT_ITEM_ORDER_UPDATE_ITEM = '[Order Select Item Selective] Update Item',
  ORDER_SELECTIVE_SELECT_ITEM_ORDER_UPDATE_ALL_ITEM = '[Order Select Item Selective] Update ALL Item',
  ORDER_SELECTIVE_SELECT_ITEM_ORDER_REMOVE_ITEM = '[Order Select Item Selective] Remove Item',
  ORDER_SELECTIVE_SELECT_ITEM_ORDER_RESET = '[Order Select Item Selective] Reset',
  ORDER_SELECTIVE_SELECT_ITEM_ORDER_ADD_ALL_ITEM = '[Order Select Item Selective] Add All Item'
}

export class OrderSelectiveSelectItemOrderAddItem implements Action {
  readonly type = OrderSelectiveSelectItemOrderActionTypes.ORDER_SELECTIVE_SELECT_ITEM_ORDER_ADD_ITEM;

  constructor(public payload: ProductOrderItem) {}
}

export class OrderSelectiveSelectItemOrderUpdateItem implements Action {
  readonly type = OrderSelectiveSelectItemOrderActionTypes.ORDER_SELECTIVE_SELECT_ITEM_ORDER_UPDATE_ITEM;

  constructor(public payload: ProductOrderItem) {}
}

export class OrderSelectiveSelectItemOrderUpdateAllItem implements Action {
  readonly type = OrderSelectiveSelectItemOrderActionTypes.ORDER_SELECTIVE_SELECT_ITEM_ORDER_UPDATE_ALL_ITEM;

  constructor(public payload: Array<ImportOrderRequest>) {}
}

export class OrderSelectiveSelectItemOrderAddAllItem implements Action {
  readonly type = OrderSelectiveSelectItemOrderActionTypes.ORDER_SELECTIVE_SELECT_ITEM_ORDER_ADD_ALL_ITEM;

  constructor(public payload: { itemList: ProductOrderItem[]; skipUpdated?: boolean }) {}
}

export class OrderSelectiveSelectItemOrderRemoveItem implements Action {
  readonly type = OrderSelectiveSelectItemOrderActionTypes.ORDER_SELECTIVE_SELECT_ITEM_ORDER_REMOVE_ITEM;

  constructor(public payload: string) {}
}

export class OrderSelectiveSelectItemOrderReset implements Action {
  readonly type = OrderSelectiveSelectItemOrderActionTypes.ORDER_SELECTIVE_SELECT_ITEM_ORDER_RESET;

  constructor() {}
}

export type OrderSelectiveSelectItemOrderActions =
  | OrderSelectiveSelectItemOrderAddItem
  | OrderSelectiveSelectItemOrderRemoveItem
  | OrderSelectiveSelectItemOrderReset
  | OrderSelectiveSelectItemOrderAddAllItem
  | OrderSelectiveSelectItemOrderUpdateItem
  | OrderSelectiveSelectItemOrderUpdateAllItem;
