<div [formGroup]="parentForm">
  <ng-select
    #multiSelect
    placeholder="Please select..."
    [items]="items"
    [multiple]="true"
    [bindValue]="bindValue"
    [bindLabel]="bindLabel"
    [searchable]="false"
    [clearable]="false"
    [closeOnSelect]="false"
    [formControlName]="controlName"
    [virtualScroll]="true"
    (search)="onSearch($event)"
    (close)="onClose()"
    [attr.data-id]="controlName"
    [id]="controlName"
    [loading]="loading"
    [ngClass]="{
      'is-invalid': invalid
    }"
  >
    <ng-template ng-multi-label-tmp let-items="items">
      <div class="ng-value">
        <span class="ng-value-label " *ngIf="showTotalItem; else elseBlock">
          Selected {{ items.length }} of {{ getItemsLength() }}</span
        >
        <ng-template #elseBlock>
          <span class="ng-value-label ">{{ items.length }} Selected</span>
        </ng-template>
      </div>
    </ng-template>
    <ng-template *ngIf="showSelectAll || showClearAll || showSearch" ng-header-tmp>
      <div class="header-row d-flex" *ngIf="showSelectAll || showClearAll">
        <button type="button" id="selectAll" class="btn btn-text-link" (click)="onSelectAll()" *ngIf="showSelectAll">
          Select All
        </button>
        <button type="button" id="clearAll" class="btn btn-text-link" (click)="onClearAll()" *ngIf="showClearAll">
          Clear All
        </button>
      </div>
      <div class="header-row" *ngIf="showSearch">
        <input
          id="searchBox"
          type="text"
          placeholder="Search"
          class="searchBox"
          (input)="multiSelect.filter($event.target.value)"
        />
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected" [disabled]="item$.disabled" />
      {{ item[bindLabel] }}
    </ng-template>
  </ng-select>
</div>
