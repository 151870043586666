import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { PurchaseRequestFreeItem, PurchaseRequestItem } from '../../models/purchase-request.model';
import { TdAssortmentOrderActionTypes, TdAssortmentOrderActions } from '../actions/td-assortment-order.actions';

export interface TdAssortmentOrderState extends EntityState<PurchaseRequestItem> {
  isLoading: boolean;
  tdAssortmentFreeItemOrder: TdAssortmentFreeItemOrderState;
}

export interface TdAssortmentFreeItemOrderState extends EntityState<PurchaseRequestFreeItem> {
  isLoading: boolean;
}

export const adapterTdAssortmentFreeItemOrder: EntityAdapter<PurchaseRequestFreeItem> = createEntityAdapter<
  PurchaseRequestFreeItem
>({
  selectId: (tdAssortmentFreeOrder: PurchaseRequestFreeItem) =>
    `${tdAssortmentFreeOrder.supplierCode}_${tdAssortmentFreeOrder.barcode}`
});

export const adapter: EntityAdapter<PurchaseRequestItem> = createEntityAdapter<PurchaseRequestItem>({
  selectId: (tdAssortmentContent: PurchaseRequestItem) =>
    `${tdAssortmentContent.supplierCode}_${tdAssortmentContent.barcode}`
});

export const initialTdAssortmentFreeItemOrderState: TdAssortmentFreeItemOrderState = adapterTdAssortmentFreeItemOrder.getInitialState(
  {
    isLoading: false
  }
);

export const initialTdAssortmentOrderState: TdAssortmentOrderState = adapter.getInitialState({
  isLoading: false,
  tdAssortmentFreeItemOrder: initialTdAssortmentFreeItemOrderState
});

export function tdAssortmentOrderReducers(
  state = initialTdAssortmentOrderState,
  action: TdAssortmentOrderActions
): TdAssortmentOrderState {
  switch (action.type) {
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_ADD_ITEM:
      return adapter.upsertOne(action.payload, {
        ...state
      });
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_UPDATE_ITEM:
      return adapter.updateOne(
        {
          id: `${action.payload.supplierCode}_${action.payload.barcode}`,
          changes: { ...action.payload }
        },
        {
          ...state
        }
      );
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_FREE_ITEM_ORDER_UPDATE_ITEM:
      return {
        ...state,
        tdAssortmentFreeItemOrder: adapterTdAssortmentFreeItemOrder.updateOne(
          {
            id: `${action.payload.supplierCode}_${action.payload.barcode}`,
            changes: { ...action.payload }
          },
          {
            ...state.tdAssortmentFreeItemOrder
          }
        )
      };
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_ADD_ALL_ITEM:
    case TdAssortmentOrderActionTypes.PURCHASE_REQUEST_ITEM_SELECTED_LIST:
      return adapter.upsertMany(action.payload, {
        ...state
      });
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_INSERT_ALL_ITEM:
      return adapter.setAll(action.payload, {
        ...state
      });
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_REMOVE_ITEM:
      return adapter.removeOne(action.payload, {
        ...state
      });
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_FREE_ITEM_ORDER_REMOVE_ITEM:
      return {
        ...state,
        tdAssortmentFreeItemOrder: adapterTdAssortmentFreeItemOrder.removeOne(action.payload, {
          ...state.tdAssortmentFreeItemOrder
        })
      };
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_RESET:
      return {
        ...state,
        ...initialTdAssortmentOrderState
      };

    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_FREE_ITEM_ORDER_ADD_ITEM:
      return {
        ...state,
        tdAssortmentFreeItemOrder: adapterTdAssortmentFreeItemOrder.upsertOne(action.payload, {
          ...state.tdAssortmentFreeItemOrder
        })
      };
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_FREE_ITEM_ORDER_ADD_ALL_ITEM:
      return {
        ...state,
        tdAssortmentFreeItemOrder: adapterTdAssortmentFreeItemOrder.upsertMany(action.payload, {
          ...state.tdAssortmentFreeItemOrder
        })
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const {
  selectAll: selectAllTdAssortmentFreeItemOrder,
  selectTotal: selectTotalAllTdAssortmentFreeItemOrder,
  selectEntities: selectEntitiesFree
} = adapterTdAssortmentFreeItemOrder.getSelectors();
