import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ClaimRequestExportCriteria } from '../../pages/claim-request/model/claim-request.model';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';

@Injectable()
export class ClaimReportService extends BaseService {
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.exportReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportClaimRequest(criteria: ClaimRequestExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.claimExport, null, true);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.CLAIM,
          path: `${this.envService.url}${this.envService.claimExport}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.CLAIM,
          route: `${this.envService.url}${this.envService.claimExport}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body'
    });
  }
}
