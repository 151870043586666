import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ExportAssortmentSearchCriteria } from '@shared/models';
import {
  CrossDockInvoiceReportCriteria,
  DcAssortmentExportCriteria,
  ManualChangeDeliveryStatusReportCriteria,
  MoneyInReportCriteria,
  PreOrderCreditRemainingReportCriteria,
  StoreToteReportCriteria,
  TaskAssignmentReportCriteria,
  ToteFineReportCriteria,
  ToteInOutReportCriteria,
  ToteInTrackingReportCriteria
} from '@shared/models/report.model';
import { StockTransferGRExportCriteria } from '@shared/models/stock-transfer-gr.model';
import { metricsReporting } from '@shared/utils/metrics-util';

import {
  OnlineCouponExportCriteria,
  OnlineCouponSummaryCriteria
} from '../../pages/online-coupon/model/online-coupon.model';
import {
  ExportStoreAssortmentByProductCriteria,
  ExportStoreAssortmentByStoreCriteria
} from '../../pages/store-assortment/models/store-assortment-request-store.model';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { StockTransferExportCriteria } from '../models/stock-transfer.model';
import { BaseService } from './base.service';

@Injectable()
export class ExportReportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.exportReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  onExportStockTransferGR(exportCriteria: StockTransferGRExportCriteria) {
    const url = this.getFullUrl(this.envService.stockTransferGrExport, null, true);
    const metrics = metricsReporting([this.envService.stockTransferGrExport]);

    return this.http.post<any>(url, exportCriteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportStockTransfer(criteria: StockTransferExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.stockTransferOrderExport, null, true);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STOCK_TRANSFER,
          path: this.envService.export,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body'
    });
  }

  public exportOnlineCoupon(criteria: OnlineCouponExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.onlineCoupon, null, true);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ONLINE_COUPON,
          path: this.envService.onlineCoupon,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body'
    });
  }

  public exportTaskAssignmentReport(criteria: TaskAssignmentReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.taskAssignmentExport, null, true);
    const metrics = metricsReporting([this.envService.taskAssignmentExport]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportMoneyIn(criteria: MoneyInReportCriteria) {
    const url = this.getFullUrl(this.envService.moneyIn, null, true);
    const metrics = metricsReporting([this.envService.moneyIn]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportPreOrderCreditRemaining(criteria: PreOrderCreditRemainingReportCriteria) {
    const url = this.getFullUrl(this.envService.preOrderCreditRemaining, null, true);
    const metrics = metricsReporting([this.envService.preOrderCreditRemaining]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportOnlineCouponSummary(criteria: OnlineCouponSummaryCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.onlineCouponSummary, null, true);

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0),
      observe: 'body'
    });
  }

  public exportStoreTote(criteria: StoreToteReportCriteria) {
    const url = this.getFullUrl(this.envService.storeToteExport, null, true);
    const metrics = metricsReporting([this.envService.storeToteExport]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportToteFine(criteria: ToteFineReportCriteria) {
    const url = this.getFullUrl(this.envService.toteFineExport, null, true);
    const metrics = metricsReporting([this.envService.toteFineExport]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportToteInOut(criteria: ToteInOutReportCriteria) {
    const url = this.getFullUrl(this.envService.toteInOutExport, null, true);
    const metrics = metricsReporting([this.envService.toteInOutExport]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportToteInTracking(criteria: ToteInTrackingReportCriteria) {
    const url = this.getFullUrl(this.envService.toteInTrackingExport, null, true);
    const metrics = metricsReporting([this.envService.toteInTrackingExport]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportAssortment(criteria: ExportAssortmentSearchCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportAssortment, null, true);
    const metrics = metricsReporting([this.envService.exportAssortment]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportAssortmentConfidential(criteria: ExportAssortmentSearchCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportAssortmentConfidential, null, true);
    const metrics = metricsReporting([this.envService.exportAssortmentConfidential]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportDcAssortment(criteria: DcAssortmentExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportDcAssortment, null, true);
    const metrics = metricsReporting([this.envService.exportDcAssortment]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportByStore(criteria: ExportStoreAssortmentByStoreCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportByStore, null, true);

    const metrics = metricsReporting([this.envService.exportAssortment]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportAssortmentLocation(criteria: ExportStoreAssortmentByProductCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportAssortmentLocation, null, true);

    const metrics = metricsReporting([this.envService.exportAssortmentLocation]);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportCrossDockInvoice(criteria: CrossDockInvoiceReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.crossDockInvoice, null, true);

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0),
      observe: 'body'
    });
  }

  public exportManualChangeDeliveryStatus(criteria: ManualChangeDeliveryStatusReportCriteria) {
    const url = this.getFullUrl(this.envService.manualChangeDeliveryStatusExport, null, true);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0),
      observe: 'body'
    });
  }
}
