import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HoldAssortmentExportCriteria } from '@shared/models/permanent-hold.model';

import { metricsReporting } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable()
export class HoldAssortmentReportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.exportReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  reportExport(reportCriteria: HoldAssortmentExportCriteria) {
    const url = this.getFullUrl(this.envService.holdAssortment, null, true);
    const metrics = metricsReporting([this.envService.url, this.envService.export]);

    return this.http.post<any>(url, reportCriteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
