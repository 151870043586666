import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { UserByRoleContent, UserByRoleCriteria } from '@shared/models/user.model';

import { RoleActionTypes, RoleActions } from './role.actions';

export interface UserByRoleState extends EntityState<UserByRoleContent> {
  isLoading: boolean;
  criteriaObject: UserByRoleCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<UserByRoleContent> = createEntityAdapter<UserByRoleContent>({
  selectId: (model: UserByRoleContent) => model.no
});

export const initialUserByRoleState: UserByRoleState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    roleCode: null,
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function userByRoleReducers(state = initialUserByRoleState, action: RoleActions): UserByRoleState {
  switch (action.type) {
    case RoleActionTypes.USER_BY_ROLE_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload.criteria
      };
    case RoleActionTypes.USER_BY_ROLE_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case RoleActionTypes.USER_BY_ROLE_RESET:
      return initialUserByRoleState;
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
