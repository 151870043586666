import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { AppStates } from '@shared/store/state/app.states';

import { ShelfDetailRequest } from '../../models';
import { selectAllShelfDetails } from '../../store/selectors/shelf-details.selectors';

@Component({
  selector: 'app-shelf-details',
  templateUrl: './shelf-details.component.html',
  styleUrls: ['./shelf-details.component.scss']
})
export class ShelfDetailsComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public shelfDetails$: Observable<ShelfDetailRequest[]>;
  private localStore: Observable<any>;

  constructor(public bsModalRef: BsModalRef, private readonly store: Store<AppStates>) {
    super();
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.shelfDetails$ = this.localStore.pipe(select(selectAllShelfDetails));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  decline(): void {
    this.bsModalRef.hide();
  }
}
