import { Action } from '@ngrx/store';

import { NewRequestStatusEnum } from '../../enum/request-status.enum';
import { NewRequestTypeEnum } from '../../enum/request-step.enum';
import { ErrorResponse } from '../../models';
import { ShelfCreateRequest, ShelfRequestViewResponse } from '../../models/shelf-request.model';
import { ShelfListResponse, ShelfListSearchCriteria, ShelfNo, ShelfViewResponse } from '../../models/shelf.model';

export enum ShelfActionType {
  SHELF_LIST_REQUEST = '[Shelf] Shelf List Request',
  SHELF_LIST_RESPONSE = '[Shelf] Shelf List Response',
  SHELF_GET_BY_ID_REQUEST = '[Shelf] Get Shelf by Id Request',
  SHELF_GET_BY_ID_RESPONSE = '[Shelf] Get Shelf by Id Response',
  EDIT_SHELF = '[Shelf] Edit Shelf',
  SHELF_GET_BY_ID_RESET = '[Shelf] Get Shelf by Id Reset',
  SHELF_LIST_HISTORY_REQUEST = '[Shelf] List History Request',
  SHELF_LIST_HISTORY_RESPONSE = '[Shelf] List History Response',
  SUBMIT_SHELF_FIRST_LOT_REQUEST = '[Shelf] Submit First Lot Request',
  SUBMIT_SHELF_FIRST_LOT_RESPONSE = '[Shelf] Submit First Lot Response',
  SUBMIT_SHELF_FIRST_LOT_ERROR = '[Shelf] Submit First Lot Error'
}

export class ShelfListRequestAction implements Action {
  readonly type = ShelfActionType.SHELF_LIST_REQUEST;

  constructor(public payload: ShelfListSearchCriteria) {}
}

export class ShelfListResponseAction implements Action {
  readonly type = ShelfActionType.SHELF_LIST_RESPONSE;

  constructor(public payload: ShelfListResponse) {}
}

export class ShelfListHistoryRequestAction implements Action {
  readonly type = ShelfActionType.SHELF_LIST_HISTORY_REQUEST;
  constructor(public payload: ShelfNo) {}
}

export class ShelfListHistoryResponseAction implements Action {
  readonly type = ShelfActionType.SHELF_LIST_HISTORY_RESPONSE;
  constructor(public payload: { auditLogs }) {}
}

export class ShelfByIdRequestAction implements Action {
  readonly type = ShelfActionType.SHELF_GET_BY_ID_REQUEST;

  constructor(public payload: ShelfNo) {}
}

export class ShelfByIdResponseAction implements Action {
  readonly type = ShelfActionType.SHELF_GET_BY_ID_RESPONSE;

  constructor(public payload: ShelfViewResponse) {}
}

export class EditShelfAction implements Action {
  readonly type = ShelfActionType.EDIT_SHELF;

  constructor(public payload: { type: NewRequestTypeEnum; status?: NewRequestStatusEnum }) {}
}

export class SubmitShelfFirstLotRequestAction implements Action {
  readonly type = ShelfActionType.SUBMIT_SHELF_FIRST_LOT_REQUEST;

  constructor(public payload: ShelfCreateRequest) {}
}

export class SubmitShelfFirstLotResponseAction implements Action {
  readonly type = ShelfActionType.SUBMIT_SHELF_FIRST_LOT_RESPONSE;

  constructor(public payload: ShelfRequestViewResponse) {}
}

export class SubmitShelfFirstLotErrorAction implements Action {
  readonly type = ShelfActionType.SUBMIT_SHELF_FIRST_LOT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResetShelfSelected implements Action {
  readonly type = ShelfActionType.SHELF_GET_BY_ID_RESET;
}

export type ShelfActions =
  | ShelfListRequestAction
  | ShelfListResponseAction
  | ShelfListHistoryRequestAction
  | ShelfListHistoryResponseAction
  | ShelfByIdRequestAction
  | ShelfByIdResponseAction
  | EditShelfAction
  | SubmitShelfFirstLotRequestAction
  | SubmitShelfFirstLotResponseAction
  | SubmitShelfFirstLotErrorAction
  | ResetShelfSelected;
