import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { ShelfItems } from '../../models';
import {
  OrderRequestList,
  OrderRequestListSearchCriteria,
  OrderRequestViewResponse
} from '../../models/order-request.model';
import { OrderRequestActionType, OrderRequestActions } from '../actions/order-request.actions';
import { BaseState, initialCriteriaObject } from '../state/base.state';

export interface OrderRequestState extends EntityState<OrderRequestList>, BaseState {
  selected: OrderRequestViewResponse;
  criteriaObject: OrderRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  shelfList: ShelfItems[];
}

export const adapter: EntityAdapter<OrderRequestList> = createEntityAdapter<OrderRequestList>();

export const initialOrderResponseState: OrderRequestState = adapter.getInitialState({
  selected: null,
  criteriaObject: initialCriteriaObject,
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  shelfList: null
});

export function OrderRequestReducers(
  state = initialOrderResponseState,
  action: OrderRequestActions
): OrderRequestState {
  switch (action.type) {
    case OrderRequestActionType.ORDER_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case OrderRequestActionType.ORDER_REQUEST_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case OrderRequestActionType.ORDER_REQUEST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    case OrderRequestActionType.ORDER_REQUEST_GET_BY_STORE_RESPONSE:
      return {
        ...state,
        selected: {
          ...state.selected,
          store: {
            merchantNo: action.payload.merchant,
            storeCode: action.payload.code,
            storeName: action.payload.name,
            storeCodeName: `${action.payload.code}-${action.payload.name}`,
            storeNo: action.payload.no,
            storeType: action.payload.merchantType,
            minOrder: action.payload.minOrder,
            openDate: action.payload.openDate
          }
        }
      };
    case OrderRequestActionType.ORDER_REQUEST_GET_BY_ID_RESPONSE:
    case OrderRequestActionType.ORDER_REQUEST_GET_BY_REF_NO_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case OrderRequestActionType.ORDER_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case OrderRequestActionType.ORDER_REQUEST_SHELF_SELECTION_RESPONSE:
      return {
        ...state,
        shelfList: action.payload
      };
    case OrderRequestActionType.ORDER_REQUEST_SHELF_SELECTION_RESET:
      return {
        ...state,
        shelfList: null
      };
    case OrderRequestActionType.ORDER_REQUEST_SAVE_DELIVERY_DETAILS:
      return {
        ...state,
        selected: {
          ...state.selected,
          deliveryDetails: action.payload,
          deliveryStatus: 'success'
        }
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
