import { Action } from '@ngrx/store';

import { ValidateStoreEnum } from '../../enum/validate-store.enum';
import {
  ApproveStoreRequest,
  ErrorResponse,
  Merchant,
  MerchantRequestList,
  StoreCreateRequest,
  StoreId,
  StoreRequestListResponse,
  StoreRequestListSearchCriteria,
  StoreRequestTemplate,
  StoreRequestViewResponse
} from '../../models';

export enum StoreRequestActionType {
  STORE_REQUEST_LIST_REQUEST = '[StoreRequest] Store Request List Request',
  STORE_REQUEST_LIST_RESPONSE = '[StoreRequest] Store Request List Response',
  STORE_REQUEST_LIST_HISTORY_REQUEST = '[StoreRequest] Store Request List History Request',
  STORE_REQUEST_LIST_HISTORY_RESPONSE = '[StoreRequest] Store Request List History Response',
  STORE_REQUEST_GET_BY_ID_REQUEST = '[StoreRequest] Get Store by Id Request',
  STORE_REQUEST_GET_BY_ID_RESPONSE = '[StoreRequest] Get Store by Id Response',
  STORE_MERCHANT_BY_ID_REQUEST = '[StoreRequest] Get Store Merchant by Id Request',
  STORE_MERCHANT_BY_ID_RESPONSE = '[StoreRequest] Get Store Merchant by Id Response',
  STORE_REQUEST_GET_BY_ID_RESET = '[StoreRequest] Get Store by Id Reset',
  STORE_CREATE_SAVE_REQUEST = '[StoreRequest] Create Store Save Request',
  STORE_CREATE_SAVE_RESPONSE = '[StoreRequest] Create Store Save Response',
  STORE_CREATE_SUBMIT_REQUEST = '[StoreRequest] Create Store Submit Request',
  STORE_CREATE_SUBMIT_RESPONSE = '[StoreRequest] Create Store Submit Response',
  STORE_CREATE_SUBMIT_ERROR = '[StoreRequest] Create Store Submit Error',
  STORE_CREATE_RESET = '[StoreRequest] Create Store Reset',
  STORE_REQUEST_APPROVE_REQUEST = '[StoreRequest] Request Approve Store Request',
  STORE_REQUEST_APPROVE_RESPONSE = '[StoreRequest] Request Approve Store Response',
  VALIDATE_SUBMIT_FIRST_LOT_ORDER_REQUEST = '[StoreRequest] Submit First Lot Order Request',
  VALIDATE_SUBMIT_FIRST_LOT_ORDER_RESPONSE = '[StoreRequest] Submit First Lot Order Response',
  VALIDATE_SUBMIT_FIRST_LOT_ORDER_ERROR = '[StoreRequest] Submit First Lot Order Error',
  STORE_REQUEST_HISTORY_REQUEST = '[StoreRequest] Request History Request',
  STORE_REQUEST_HISTORY_RESPONSE = '[StoreRequest] Request History Response',
  STORE_REQUEST_DELETE_REQUEST = '[StoreRequest] Request Delete Store Request',
  STORE_REQUEST_DELETE_RESPONSE = '[StoreRequest] Request Delete Store Response',
  STORE_REQUEST_DELETE_RESET = '[StoreRequest] Request Delete Store Reset',
  STORE_REQUEST_GET_VERSION_REQUEST = '[StoreRequest] Get Store Version Request',
  STORE_REQUEST_GET_VERSION_RESPONSE = '[StoreRequest] Get Store Version Response'
}

export class StoreRequestListRequestAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_LIST_REQUEST;

  constructor(public payload: StoreRequestListSearchCriteria) {}
}

export class StoreRequestListResponseAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_LIST_RESPONSE;

  constructor(public payload: StoreRequestListResponse) {}
}

export class StoreRequestListHistoryRequestAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_LIST_HISTORY_REQUEST;

  constructor(public payload: StoreId) {}
}

export class StoreRequestListHistoryResponseAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_LIST_HISTORY_RESPONSE;

  constructor(public payload: { auditLogs }) {}
}

export class StoreRequestByIdRequestAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: StoreId) {}
}

export class StoreRequestByIdResponseAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: StoreRequestViewResponse) {}
}

export class StoreMerchantByIdRequestAction implements Action {
  readonly type = StoreRequestActionType.STORE_MERCHANT_BY_ID_REQUEST;

  constructor(public payload: Merchant) {}
}

export class StoreMerchantByIdResponseAction implements Action {
  readonly type = StoreRequestActionType.STORE_MERCHANT_BY_ID_RESPONSE;

  constructor(public payload: StoreRequestViewResponse) {}
}

export class ResetStoreRequestSelected implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_GET_BY_ID_RESET;
}

export class StoreCreateSaveRequestAction implements Action {
  readonly type = StoreRequestActionType.STORE_CREATE_SAVE_REQUEST;

  constructor(public payload: StoreRequestTemplate) {}
}

export class StoreCreateSaveResponseAction implements Action {
  readonly type = StoreRequestActionType.STORE_CREATE_SAVE_RESPONSE;

  constructor(public payload: StoreCreateRequest) {}
}

export class StoreCreateSubmitRequestAction implements Action {
  readonly type = StoreRequestActionType.STORE_CREATE_SUBMIT_REQUEST;

  constructor(public payload: StoreRequestTemplate) {}
}

export class StoreCreateSubmitResponseAction implements Action {
  readonly type = StoreRequestActionType.STORE_CREATE_SUBMIT_RESPONSE;

  constructor(public payload: StoreCreateRequest) {}
}

export class StoreCreateSubmitResponseError implements Action {
  readonly type = StoreRequestActionType.STORE_CREATE_SUBMIT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class StoreCreateResetAction implements Action {
  readonly type = StoreRequestActionType.STORE_CREATE_RESET;
}

export class StoreApproveRequestAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_APPROVE_REQUEST;

  constructor(public payload: ApproveStoreRequest) {}
}

export class StoreApproveResponseAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_APPROVE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class StoreFirstLotOrderValidateSubmitRequestAction implements Action {
  readonly type = StoreRequestActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_REQUEST;

  constructor(public payload: { storeRequestTemplate: StoreRequestTemplate; validateStore: ValidateStoreEnum }) {}
}

export class StoreFirstLotOrderValidateSubmitResponseAction implements Action {
  readonly type = StoreRequestActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_RESPONSE;

  constructor(public payload: { merchantRequest: MerchantRequestList }) {}
}

export class StoreFirstLotOrderValidateSubmitErrorAction implements Action {
  readonly type = StoreRequestActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_ERROR;

  constructor() {}
}

export class StoreRequestHistoryRequestAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_HISTORY_REQUEST;

  constructor(public payload: StoreId) {}
}

export class StoreRequestHistoryResponseAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_HISTORY_RESPONSE;

  constructor(public payload: { auditLogs }) {}
}

export class StoreDeleteRequestAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_DELETE_REQUEST;

  constructor(public payload: StoreId) {}
}

export class StoreDeleteResponseAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_DELETE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class StoreDeleteResetAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_DELETE_RESET;
}

export class StoreGetVersionRequestAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_GET_VERSION_REQUEST;

  constructor(public payload: StoreId) {}
}

export class StoreGetVersionResponseAction implements Action {
  readonly type = StoreRequestActionType.STORE_REQUEST_GET_VERSION_RESPONSE;

  constructor(public payload: any) {}
}

export type StoreRequestActions =
  | StoreRequestListRequestAction
  | StoreRequestListResponseAction
  | StoreRequestByIdRequestAction
  | StoreRequestByIdResponseAction
  | StoreCreateSaveRequestAction
  | StoreCreateSaveResponseAction
  | StoreCreateSubmitRequestAction
  | StoreCreateSubmitResponseAction
  | StoreCreateSubmitResponseError
  | StoreCreateResetAction
  | StoreMerchantByIdRequestAction
  | StoreMerchantByIdResponseAction
  | ResetStoreRequestSelected
  | StoreApproveRequestAction
  | StoreApproveResponseAction
  | StoreFirstLotOrderValidateSubmitRequestAction
  | StoreFirstLotOrderValidateSubmitResponseAction
  | StoreFirstLotOrderValidateSubmitErrorAction
  | StoreRequestHistoryRequestAction
  | StoreRequestHistoryResponseAction
  | StoreDeleteRequestAction
  | StoreDeleteResponseAction
  | StoreDeleteResetAction
  | StoreGetVersionRequestAction
  | StoreGetVersionResponseAction;
