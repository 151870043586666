import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { SelectWarehouseList, WarehouseListContent } from '../../models/warehouse.model';
import { WarehouseAction, WarehouseActionTypes } from '../actions/warehouse.actions';

export interface WarehouseState extends EntityState<WarehouseListContent> {
  isLoading: boolean;
  selectWarehousePagingList: SelectWarehouseState;
  selectWarehouseList: SelectWarehouseList[];
}

export interface SelectWarehouseState extends EntityState<SelectWarehouseList> {
  isLoading: boolean;
  totalElement: number;
}

export const adapter: EntityAdapter<WarehouseListContent> = createEntityAdapter<WarehouseListContent>();
export const adapterSelectWarehouse: EntityAdapter<SelectWarehouseList> = createEntityAdapter<SelectWarehouseList>();

export const initialSelectWarehouseState: SelectWarehouseState = adapterSelectWarehouse.getInitialState({
  isLoading: false,
  totalElement: 0
});
export const initialWarehouseState: WarehouseState = adapter.getInitialState({
  isLoading: false,
  selectWarehousePagingList: initialSelectWarehouseState,
  selectWarehouseList: []
});

export function warehouseReducers(state = initialWarehouseState, action: WarehouseAction): WarehouseState {
  switch (action.type) {
    case WarehouseActionTypes.WAREHOUSE_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, { ...state });
    case WarehouseActionTypes.SELECT_WAREHOUSES_LIST_RESPONSE:
      return {
        ...state,
        selectWarehouseList: action.payload
      };
    case WarehouseActionTypes.SELECT_WAREHOUSES_LIST_REQUEST:
      return {
        ...state,
        selectWarehousePagingList: {
          ...state.selectWarehousePagingList,
          isLoading: true
        }
      };
    case WarehouseActionTypes.SELECT_WAREHOUSES_PAGING_LIST_RESPONSE:
      return {
        ...state,
        selectWarehousePagingList: adapterSelectWarehouse.upsertMany(action.payload.masterDtoList, {
          ...state.selectWarehousePagingList,
          isLoading: false,
          totalElement: action.payload.totalElement
        })
      };
    case WarehouseActionTypes.REMOVE_ALL_SELECT_WAREHOUSE_LIST:
      return {
        ...state,
        selectWarehousePagingList: adapterSelectWarehouse.removeAll({ ...state.selectWarehousePagingList })
      };
    case WarehouseActionTypes.RESET_WAREHOUSE_LIST:
      return {
        ...state,
        selectWarehousePagingList: initialSelectWarehouseState
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const { selectAll: selectAllWarehousePagingList } = adapterSelectWarehouse.getSelectors();
