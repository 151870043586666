import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { StackPricingService } from '../../services/stack-pricing.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import {
  StackPricingActionTypes,
  StackPricingDeleteRequested,
  StackPricingHistoryLoaded,
  StackPricingHistoryRequested,
  StackPricingSearchLoaded,
  StackPricingSearchRequested,
  StackPricingViewLoaded,
  StackPricingViewRequested,
  StackPricingViewSubmit
} from '../actions/stack-pricing.actions';

@Injectable()
export class StackPricingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly stackPricingService: StackPricingService,
    private readonly logger: NGXLogger
  ) {}

  searchStackPricing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StackPricingSearchRequested>(StackPricingActionTypes.StackPricingSearchRequested),
      tap(action => this.logger.debug('@Effect Stack Pricing Search: ' + JSON.stringify(action.payload))),
      switchMap(action =>
        this.stackPricingService.searchByCriteria(action.payload).pipe(
          map(stackPricings => new StackPricingSearchLoaded({ stackPricings })),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  viewStackPricing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StackPricingViewRequested>(StackPricingActionTypes.StackPricingViewRequested),
      tap(action => this.logger.debug('@Effect StackPricingViewRequested: ' + JSON.stringify(action.payload))),
      switchMap(action =>
        this.stackPricingService.getStackPricingByID(action.payload.articleNo).pipe(
          map(stackPricingView => new StackPricingViewLoaded({ stackPricingView })),
          catchError(err => of(new LayoutActionLoadError(err)))
        )
      )
    );
  });

  submitStackPricing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StackPricingViewSubmit>(StackPricingActionTypes.StackPricingViewSubmitRequested),
      tap(action => this.logger.debug('@Effect StackPricingView Submit: ' + JSON.stringify(action.payload))),
      switchMap(action => {
        return this.stackPricingService.submit(action.payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been saved.'
              })
          ),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  deleteStackPricing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StackPricingDeleteRequested>(StackPricingActionTypes.StackPricingDeleteRequested),
      tap(action => this.logger.debug('@Effect StackPricingView Delete: ' + JSON.stringify(action.payload))),
      switchMap(action => {
        return this.stackPricingService.deleteStackPricingByID(action.payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The new stack pricing has been deleted.'
              })
          ),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  historyStackPricing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StackPricingHistoryRequested>(StackPricingActionTypes.StackPricingHistoryRequested),
      tap(action => this.logger.debug('@Effect StackPricingView Delete: ' + JSON.stringify(action.payload))),
      switchMap(action => {
        return this.stackPricingService.getHistoryByID(action.payload.articleNo).pipe(
          map(response => new StackPricingHistoryLoaded({ auditLogs: response.auditLogs })),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });
}
