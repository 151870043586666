import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { SelectStoreDropdownPagingList } from '../../models/store-dropdown.model';
import { StoreDropdownService } from '../../services/store-dropdown.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  SelectStoreDropdownListRequestAction,
  SelectStoreDropdownListResponseAction,
  SelectStoreDropdownPagingListResponseAction,
  StoreDropdownActionTypes
} from '../actions/store-dropdown.actions';

@Injectable()
export class StoreDropdownEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly storeDropdownService: StoreDropdownService
  ) {}

  selectStoreDropdownList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SelectStoreDropdownListRequestAction>(StoreDropdownActionTypes.SELECT_STORE_DROPDOWNS_LIST_REQUEST),
      tap(() => {
        this.logger.debug(`@Effect ${StoreDropdownActionTypes.SELECT_STORE_DROPDOWNS_LIST_REQUEST}`);
      }),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(action => {
        return this.storeDropdownService.selectStoreDropdownList(action.payload).pipe(
          map((storeDropdowns: SelectStoreDropdownPagingList) => {
            return action.payload.isPaging
              ? new SelectStoreDropdownPagingListResponseAction(storeDropdowns)
              : new SelectStoreDropdownListResponseAction(storeDropdowns.masterDtoList);
          }),
          catchError(err => of(new LayoutActionLoadError(err)))
        );
      })
    );
  });
}
