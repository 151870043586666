import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { ErrorResponse } from '../../models';
import { SupplierContent, SupplierSearchCriteria, SupplierViewResponse } from '../../models/supplier.model';
import { SupplierActionTypes, SupplierActions } from '../actions/supplier.actions';

export interface SupplierState extends EntityState<SupplierContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  selected: SupplierViewResponse;
  criteriaObject: SupplierSearchCriteria;
  totalElements: number;
  totalPages: number;
  activatedStatus: string;
  validateSupplier: any;
  submitError: ErrorResponse;
}

export const adapter: EntityAdapter<SupplierContent> = createEntityAdapter<SupplierContent>();

export const initialSupplierState: SupplierState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  activatedStatus: null,
  validateSupplier: null,
  submitError: null
});

export function supplierReducers(state = initialSupplierState, action: SupplierActions): SupplierState {
  switch (action.type) {
    case SupplierActionTypes.SUPPLIER_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case SupplierActionTypes.SUPPLIER_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case SupplierActionTypes.SUPPLIER_GET_BY_CODE_RESPONSE: {
      return {
        ...state,
        selected: action.payload.supplierView
      };
    }
    case SupplierActionTypes.SUPPLIER_GET_BY_CODE_RESET: {
      return {
        ...state,
        selected: null,
        activatedStatus: null,
        submitError: null,
        validateSupplier: null
      };
    }
    case SupplierActionTypes.SUPPLIER_ACTIVATE_BY_ID_RESPONSE: {
      return {
        ...state,
        activatedStatus: action.payload.status
      };
    }
    case SupplierActionTypes.SUPPLIER_VALIDATE_RESPONSE: {
      return {
        ...state,
        validateSupplier: action.payload
      };
    }
    case SupplierActionTypes.SUPPLIER_VALIDATE_RESET: {
      return {
        ...state,
        validateSupplier: null
      };
    }
    case SupplierActionTypes.SUPPLIER_SUBMIT_EDIT_ERROR:
      return {
        ...state,
        submitError: action.payload
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
