import { Action } from '@ngrx/store';

import {
  StockInformationDto,
  StockInformationListPagination,
  StockInformationSearchCriteria,
  StockMovementPagination,
  StockMovementSearchCriteria,
  StockStorePagination,
  StockStoreSearchCriteria,
  StockWarehousePagination,
  StockWarehouseSearchCriteria
} from '@shared/models';

export enum StockInformationActionTypes {
  STOCK_INFORMATION_LIST_REQUEST = '[Stock Information] List Request',
  STOCK_INFORMATION_LIST_RESPONSE = '[Stock Information] List Response',
  STOCK_INFORMATION_LIST_RESET = '[Stock Information] List Reset',

  STOCK_INFORMATION_GET_REQUEST = '[Stock Information] Get Request',
  STOCK_INFORMATION_GET_RESPONSE = '[Stock Information] Get Response',
  STOCK_INFORMATION_GET_RESET = '[Stock Information] Get Reset',

  STOCK_INFORMATION_MOVEMENT_REQUEST = '[Stock Information Movement] Get Request',
  STOCK_INFORMATION_MOVEMENT_RESPONSE = '[Stock Information Movement] Get Response',
  STOCK_INFORMATION_MOVEMENT_RESET = '[Stock Information Movement] Get Reset',

  STOCK_INFORMATION_STORE_REQUEST = '[Stock Information Store] Get Request',
  STOCK_INFORMATION_STORE_RESPONSE = '[Stock InformationStore] Get Response',
  STOCK_INFORMATION_STORE_RESET = '[Stock Information Store] Get Reset',

  STOCK_INFORMATION_WAREHOUSE_REQUEST = '[Stock Information Warehouse] Get Request',
  STOCK_INFORMATION_WAREHOUSE_RESPONSE = '[Stock Information Warehouse] Get Response',
  STOCK_INFORMATION_WAREHOUSE_RESET = '[Stock Information Warehouse] Get Reset'
}

export class StockInformationListRequestAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_LIST_REQUEST;
  constructor(public payload: StockInformationSearchCriteria) {}
}

export class StockInformationListResponseAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_LIST_RESPONSE;
  constructor(public payload: StockInformationListPagination) {}
}

export class StockInformationListResetAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_LIST_RESET;
}

export class StockInformationRequestAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_GET_REQUEST;
  constructor(public payload: { warehouse: string; articleNo: string }) {}
}

export class StockInformationResponseAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_GET_RESPONSE;
  constructor(public payload: StockInformationDto) {}
}

export class StockInformationResetAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_GET_RESET;
}

export class StockInformationMovementRequestAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_MOVEMENT_REQUEST;
  constructor(public payload: { criteria: StockMovementSearchCriteria; warehouse: string; articleNo: string }) {}
}

export class StockInformationMovementResponseAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_MOVEMENT_RESPONSE;
  constructor(public payload: StockMovementPagination) {}
}

export class StockInformationMovementResetAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_MOVEMENT_RESET;
}

export class StockInformationStoreRequestAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_STORE_REQUEST;
  constructor(public payload: { criteria: StockStoreSearchCriteria; warehouse: string; articleNo: string }) {}
}

export class StockInformationStoreResponseAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_STORE_RESPONSE;
  constructor(public payload: StockStorePagination) {}
}

export class StockInformationStoreResetAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_STORE_RESET;
}

export class StockInformationWarehouseRequestAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_WAREHOUSE_REQUEST;
  constructor(public payload: { criteria: StockWarehouseSearchCriteria; warehouse: string; articleNo: string }) {}
}

export class StockInformationWarehouseResponseAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_WAREHOUSE_RESPONSE;
  constructor(public payload: StockWarehousePagination) {}
}

export class StockInformationWarehouseResetAction implements Action {
  readonly type = StockInformationActionTypes.STOCK_INFORMATION_WAREHOUSE_RESET;
}

export type StockInformationAction =
  | StockInformationListRequestAction
  | StockInformationListResponseAction
  | StockInformationListResetAction
  | StockInformationRequestAction
  | StockInformationResponseAction
  | StockInformationResetAction
  | StockInformationMovementRequestAction
  | StockInformationMovementResponseAction
  | StockInformationMovementResetAction
  | StockInformationStoreRequestAction
  | StockInformationStoreResponseAction
  | StockInformationStoreResetAction
  | StockInformationWarehouseRequestAction
  | StockInformationWarehouseResponseAction
  | StockInformationWarehouseResetAction;
