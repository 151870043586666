<div class="store-location-wrapper position-relative" [formGroup]="storeLocation" *ngIf="isShowSection()">
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="storeAddress">Address<span class="text-danger">*</span></label>
      <input
        id="storeAddress"
        name="storeAddress"
        type="text"
        class="form-control"
        maxlength="250"
        formControlName="address"
        placeholder="Address"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.address.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.address
        }"
      />
      <div *ngIf="submitted && storeLocation.controls.address.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.address.errors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <label for="storeStateProvince">State / Province<span class="text-danger">*</span></label>
      <ng-select
        id="storeStateProvince"
        name="storeStateProvince"
        placeholder="Please select..."
        [items]="stateSelectValue"
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameTh"
        bindValue="code"
        formControlName="state"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.state.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.storeStateProvince
        }"
      >
      </ng-select>
      <div *ngIf="submitted && storeLocation.controls.state.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.state.errors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <label for="storeRegion">Region<span class="text-danger">*</span></label>
      <ng-select
        id="storeRegion"
        name="storeRegion"
        placeholder="Please select..."
        [items]="regionSelectValue"
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameTh"
        bindValue="code"
        formControlName="region"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.region.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.storeRegion
        }"
      >
      </ng-select>
      <div *ngIf="submitted && storeLocation.controls.region.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.region.errors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 mb-3">
      <label for="storePostCode">Postcode<span class="text-danger">*</span></label>
      <input
        id="storePostCode"
        name="storePostCode"
        type="text"
        class="form-control"
        maxlength="5"
        appDigitOnly
        formControlName="postCode"
        placeholder="Postcode"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.postCode.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.postCode
        }"
      />

      <div *ngIf="submitted && storeLocation.controls.postCode.errors as controlErrors" class="invalid-feedback">
        <div *ngIf="controlErrors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
        <div *ngIf="controlErrors.pattern">
          {{ 'ERRORS.REQUIRED_5_DIGITS' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <label for="storeCountry">Country<span class="text-danger">*</span></label>
      <ng-select
        id="storeCountry"
        placeholder="Please select..."
        [items]="countrySelectValue"
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameTh"
        bindValue="code"
        formControlName="country"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.country.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.storeCountry
        }"
      >
      </ng-select>
      <div *ngIf="submitted && storeLocation.controls.country.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.country.errors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>

    <div class="col-md-3 mb-3">
      <label for="storeTimezone">Timezone<span class="text-danger">*</span></label>
      <ng-select
        id="storeTimezone"
        name="storeTimezone"
        placeholder="Please select..."
        [items]="timeZoneSelectValue"
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameTh"
        bindValue="code"
        formControlName="timezone"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.timezone.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.storeTimezone
        }"
      >
      </ng-select>
      <div *ngIf="submitted && storeLocation.controls.timezone.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.timezone.errors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 mb-3">
      <label for="storeBuildingType">Building Type<span class="text-danger">*</span></label>
      <ng-select
        id="storeBuildingType"
        name="storeBuildingType"
        placeholder="Please select..."
        [items]="buildingSelectValue"
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameTh"
        bindValue="code"
        formControlName="buildingType"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.buildingType.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.buildingType
        }"
      >
      </ng-select>
      <div *ngIf="submitted && storeLocation.controls.buildingType.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.buildingType.errors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <label for="unitSize">Unit Size<span class="text-danger">*</span></label>
      <ng-select
        id="unitSize"
        name="unitSize"
        placeholder="Please select..."
        [items]="buildingSizeSelectValue"
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameTh"
        bindValue="code"
        formControlName="unitSize"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.unitSize.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.unitSize
        }"
      >
      </ng-select>
      <div *ngIf="submitted && storeLocation.controls.unitSize.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.unitSize.errors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <label for="unitWLH">Unit W x L x H (in metre)</label>
      <app-numeric-textbox
        [negativeSign]="false"
        [format]="'0.00'"
        [maxValue]="999.99"
        id="unitWLH"
        formControlName="unitWLH"
        class="form-control"
        maxlength="6"
        placeholder="Cubic metre"
        [ngClass]="{
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.unitWLH
        }"
      ></app-numeric-textbox>
    </div>
    <div class="col-md-3 mb-3">
      <div class="form-row">
        <div class="col-8">
          <label for="storePropertyOwnership">Property Ownership<span class="text-danger">*</span></label>
          <ng-select
            id="storePropertyOwnership"
            name="storePropertyOwnership"
            placeholder="Please select..."
            [items]="propertyOwnerhipSelectValue"
            [searchable]="false"
            [clearable]="false"
            (change)="disableRentalField(storeLocation)"
            bindLabel="nameTh"
            bindValue="code"
            formControlName="propertyOwnership"
            [ngClass]="{
              'is-invalid': submitted && storeLocation.controls.propertyOwnership.errors,
              'bg-h-change': isRequestTypeEdit(type) && listOfChange?.propertyOwnership
            }"
          >
          </ng-select>
          <div *ngIf="submitted && storeLocation.controls.propertyOwnership.errors" class="invalid-feedback">
            <div *ngIf="storeLocation.controls.propertyOwnership.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-4">
          <label for="storeRental">Rental/Month</label>
          <app-numeric-textbox
            [negativeSign]="false"
            [format]="'0,0.00'"
            [maxValue]="9999999.99"
            id="storeRental"
            formControlName="rentalFee"
            class="form-control"
            placeholder="THB"
            maxlength="10"
            [ngClass]="{
              'is-invalid': submitted && storeLocation.controls.rentalFee.errors,
              'bg-h-change': isRequestTypeEdit(type) && listOfChange?.rentalFee
            }"
          >
          </app-numeric-textbox>
          <div *ngIf="submitted && storeLocation.controls.rentalFee.errors" class="invalid-feedback">
            <div *ngIf="storeLocation.controls.rentalFee.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 mb-3">
      <label for="storeSaleSpace">Sale Space (sqm.)<span class="text-danger">*</span></label>
      <app-numeric-textbox
        [negativeSign]="false"
        [format]="'0.00'"
        [maxValue]="999.99"
        id="storeSaleSpace"
        formControlName="saleSpace"
        class="form-control"
        maxlength="6"
        placeholder="Sqm."
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.saleSpace.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.saleSpace
        }"
      ></app-numeric-textbox>
      <div *ngIf="submitted && storeLocation.controls.saleSpace.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.saleSpace.errors.required; else validateStoreSaleSpace">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
        <ng-template #validateStoreSaleSpace>
          <div *ngIf="storeLocation.controls.saleSpace.errors.isZero">
            {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <label for="storeStockSpace">Stock Space (sqm.)<span class="text-danger">*</span></label>
      <app-numeric-textbox
        [negativeSign]="false"
        [format]="'0.00'"
        [maxValue]="999.99"
        id="storeStockSpace"
        formControlName="stockSpace"
        class="form-control"
        maxlength="6"
        placeholder="Sqm."
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.stockSpace.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.stockSpace
        }"
      ></app-numeric-textbox>
      <div *ngIf="submitted && storeLocation.controls.stockSpace.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.stockSpace.errors.required; else validateStoreStockSpace">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
        <ng-template #validateStoreStockSpace>
          <div *ngIf="storeLocation.controls.stockSpace.errors.isZero">
            {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <label for="storeLatitude">Latitude<span class="text-danger">*</span></label>
      <input
        id="storeLatitude"
        name="storeLatitude"
        type="text"
        class="form-control"
        formControlName="latitude"
        placeholder="Latitude"
        appDecimalPlaces="6"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.latitude.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.latitude
        }"
      />
      <div *ngIf="submitted && storeLocation.controls.latitude.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.latitude.errors.required; else invalidStoreLatitude">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
        <ng-template #invalidStoreLatitude>
          <div *ngIf="storeLocation.controls.latitude.errors.pattern">
            {{ 'ERRORS.INVALID_LATITUDE' | translate }}
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <label for="storeLongitude">Longitude<span class="text-danger">*</span></label>
      <input
        id="storeLongitude"
        name="storeLongitude"
        type="text"
        class="form-control"
        formControlName="longitude"
        placeholder="Longitude"
        appDecimalPlaces="6"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.longitude.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.longitude
        }"
      />
      <div *ngIf="submitted && storeLocation.controls.longitude.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.longitude.errors.required; else invalidStoreLongitude">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
        <ng-template #invalidStoreLongitude>
          <div *ngIf="storeLocation.controls.longitude.errors.pattern">
            {{ 'ERRORS.INVALID_LONGITUDE' | translate }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 mb-3">
      <label for="storeParking">Parking Condition<span class="text-danger">*</span></label>
      <ng-select
        id="storeParking"
        name="storeParking"
        placeholder="Please select..."
        [items]="parkingSelectValue"
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameTh"
        bindValue="code"
        formControlName="parking"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.parking.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.parking
        }"
      >
      </ng-select>
      <div *ngIf="submitted && storeLocation.controls.parking.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.parking.errors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <label for="storeDeliveryByFourWheelsTruck"
        >Delivery By 4 Wheels Truck Only<span class="text-danger">*</span></label
      >
      <ng-select
        id="storeDeliveryByFourWheelsTruck"
        name="storeDeliveryByFourWheelsTruck"
        placeholder="Please select..."
        [items]="deliveryBy4WheelsList"
        [searchable]="false"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
        formControlName="deliveryByFourWheelsTruck"
        [ngClass]="{
          'is-invalid': submitted && storeLocation.controls.deliveryByFourWheelsTruck.errors,
          'bg-h-change': isRequestTypeEdit(type) && listOfChange?.deliveryByFourWheelsTruck
        }"
      >
      </ng-select>
      <div *ngIf="submitted && storeLocation.controls.deliveryByFourWheelsTruck.errors" class="invalid-feedback">
        <div *ngIf="storeLocation.controls.deliveryByFourWheelsTruck.errors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mb-3">
      <label for="storeDeliveryTimes">Restricted Delivery Time</label>
      <div class="error-label row" *ngIf="isRequestTypeEdit(type) && listOfChange?.deliveryTimes">
        <div class="col-md-6">
          <app-tag-updated updated="Restricted Delivery Time"></app-tag-updated>
        </div>
      </div>
      <app-delivery-times
        id="storeDeliveryTimes"
        data-id="storeDeliveryTimes"
        #deliveryTimes
        [parentForm]="storeLocation"
        [submitted]="submitted"
        [page]="page"
        [index]="0"
        [mode]="mode"
        [restrictDeliveryTimeSelectValue]="restrictDeliveryTimeSelectValue"
      ></app-delivery-times>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="titleDeed">Title Deed</label>
      <app-files-upload
        id="titleDeed"
        formControlName="titleDeed"
        class="form-control"
        [hasError]="submitted && !!storeLocation.controls.titleDeed.errors"
        [hasChange]="isRequestTypeEdit(type) && listOfChange?.titleDeed"
        [allowedTypes]="'(pdf|png|jpe?g)'"
        [withMeta]="true"
        [size]="500000"
        [controlName]="'titleDeed'"
        [allowedExt]="'(.pdf|.png|.jpe?g)'"
        descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
        fileSizeErrorTxt="Size up to 500 KB."
        fileTypeErrorTxt="Incorrect format (allow only format file .pdf, .jpg, .jpeg, .png)."
        [fileModule]="fileModule.MERCHANT_REQUEST"
      ></app-files-upload>
    </div>
    <div class="col-md-6 mb-3">
      <label for="houseRegistrationPicture">Landlord House Registration</label>
      <app-files-upload
        id="houseRegistrationPicture"
        formControlName="houseRegistrationPicture"
        class="form-control"
        [hasError]="submitted && !!storeLocation.controls.houseRegistrationPicture.errors"
        [hasChange]="isRequestTypeEdit(type) && listOfChange?.houseRegistrationPicture"
        [allowedTypes]="'(pdf|png|jpe?g)'"
        [withMeta]="true"
        [size]="500000"
        [controlName]="'houseRegistrationPicture'"
        [allowedExt]="'(.pdf|.png|.jpe?g)'"
        descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
        fileSizeErrorTxt="Size up to 500 KB."
        fileTypeErrorTxt="Incorrect format (allow only format file .pdf, .jpg, .jpeg, .png)."
        [fileModule]="fileModule.MERCHANT_REQUEST"
      ></app-files-upload>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="idCardPicture">Landlord ID Card</label>
      <app-files-upload
        id="idCardPicture"
        formControlName="idCardPicture"
        class="form-control"
        [hasError]="submitted && !!storeLocation.controls.idCardPicture.errors"
        [hasChange]="isRequestTypeEdit(type) && listOfChange?.idCardPicture"
        [allowedTypes]="'(pdf|png|jpe?g)'"
        [withMeta]="true"
        [size]="500000"
        [controlName]="'idCardPicture'"
        [allowedExt]="'(.pdf|.png|.jpe?g)'"
        descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
        fileSizeErrorTxt="Size up to 500 KB."
        fileTypeErrorTxt="Incorrect format (allow only format file .pdf, .jpg, .jpeg, .png)."
        [fileModule]="fileModule.MERCHANT_REQUEST"
      ></app-files-upload>
    </div>
    <div class="col-md-6 mb-3">
      <label for="consentLetterPicture">Landlord Consent Letter</label>
      <app-files-upload
        id="consentLetterPicture"
        formControlName="consentLetterPicture"
        class="form-control"
        [hasError]="submitted && !!storeLocation.controls.consentLetterPicture.errors"
        [hasChange]="isRequestTypeEdit(type) && listOfChange?.consentLetterPicture"
        [allowedTypes]="'(pdf|png|jpe?g)'"
        [withMeta]="true"
        [size]="500000"
        [controlName]="'consentLetterPicture'"
        [allowedExt]="'(.pdf|.png|.jpe?g)'"
        descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
        fileSizeErrorTxt="Size up to 500 KB."
        fileTypeErrorTxt="Incorrect format (allow only format file .pdf, .jpg, .jpeg, .png)."
        [fileModule]="fileModule.MERCHANT_REQUEST"
      ></app-files-upload>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="storePicFront">Picture (Store Front)</label>
      <app-multiple-files-upload
        id="storePicFront"
        [form]="storeLocation"
        [arrayName]="'storeFrontPicture'"
        [submitted]="submitted"
        [allowedTypes]="'(pdf|png|jpe?g)'"
        [size]="500000"
        [allowedExt]="'(.pdf|.png|.jpe?g)'"
        descriptionTxt="Format file .pdf, .jpg, .jpeg, .png (Size up to 500KB)"
        fileSizeErrorTxt="Size up to 500 KB."
        fileTypeErrorTxt="Incorrect format (allow only format file .pdf, .jpg, .jpeg, .png)."
        [maxImages]="10"
        [fileModule]="fileModule.MERCHANT_REQUEST"
        [hasChange]="isRequestTypeEdit(type) && listOfChange?.storeFrontPicture"
      ></app-multiple-files-upload>
    </div>
    <div class="col-md-6 mb-3">
      <label for="storeVDOFront">Video (Store Front)</label>
      <app-files-upload
        id="storeVDOFront"
        formControlName="storeVDOFront"
        class="form-control"
        [hasError]="submitted && !!storeLocation.controls.storeVDOFront.errors"
        [hasChange]="isRequestTypeEdit(type) && listOfChange?.storeFrontVideo"
        [allowedTypes]="'(mp4)'"
        [withMeta]="true"
        [size]="5000000"
        [controlName]="'storeVDOFront'"
        [allowedExt]="'(.mp4)'"
        descriptionTxt="Format file .mp4 (Size up to 5 MB)"
        fileSizeErrorTxt="Size up to 5 MB."
        fileTypeErrorTxt="Incorrect Format (allow only format file .mp4)."
        [fileModule]="fileModule.MERCHANT_REQUEST"
      ></app-files-upload>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="attachmentPicture">Attachment</label>
      <app-multiple-files-upload
        id="attachmentPicture"
        [form]="storeLocation"
        [arrayName]="'attachmentPicture'"
        [submitted]="submitted"
        [allowedTypes]="'(pdf|png|jpe?g)'"
        [size]="500000"
        [allowedExt]="'(.pdf|.png|.jpe?g)'"
        descriptionTxt="Format file .pdf, .jpg, .jpeg, .png (Size up to 500KB)"
        fileSizeErrorTxt="Size up to 500 KB."
        fileTypeErrorTxt="Incorrect format (allow only format file .pdf, .jpg, .jpeg, .png)."
        [maxImages]="10"
        [fileModule]="fileModule.MERCHANT_REQUEST"
        [hasChange]="isRequestTypeEdit(type) && listOfChange?.attachmentPicture"
      ></app-multiple-files-upload>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mb-3">
      <label for="nearByPois">Nearby POIs<span class="text-danger">*</span></label>
      <div class="error-label" *ngIf="isRequestTypeEdit(type) && listOfChange?.nearByPois">
        <app-tag-updated updated="Nearby POIs"></app-tag-updated>
      </div>
      <app-near-by-poi
        id="nearByPois"
        [parentForm]="storeLocation"
        [submitted]="submitted"
        [page]="page"
        [index]="0"
        [mode]="mode"
        [poiSelectValue]="poiSelectValue"
        [poiDistanceSelectValue]="poiDistanceSelectValue"
      ></app-near-by-poi>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mb-3">
      <label for="nearByCompetitors">Nearby Competitors</label>
      <div class="error-label" *ngIf="isRequestTypeEdit(type) && listOfChange?.nearByCompetitors">
        <app-tag-updated updated="Nearby Competitors"></app-tag-updated>
      </div>
      <app-near-by-competitor
        id="nearByCompetitors"
        [parentForm]="storeLocation"
        [submitted]="submitted"
        [page]="page"
        [index]="0"
        [mode]="mode"
        [competitorSelectValue]="competitorSelectValue"
        [buildingSizeSelectValue]="buildingSizeSelectValue"
        [poiDistanceSelectValue]="poiDistanceSelectValue"
      ></app-near-by-competitor>
    </div>
  </div>
</div>
