import { Action } from '@ngrx/store';

import { MyTaskListResponse, MyTaskSearchCriteria } from '../../models';
export enum DashboardActionTypes {
  TASKS_BY_ROLE_LIST_REQUEST = '[Tasks By Role list] My Tasks request list',
  TASKS_BY_ROLE_LIST_RESPONSE = '[Tasks By Role list] My Tasks response list'
}

export class TasksByRoleListRequestAction implements Action {
  readonly type = DashboardActionTypes.TASKS_BY_ROLE_LIST_REQUEST;
  constructor(public payload: MyTaskSearchCriteria = null) {}
}

export class TasksByRoleListResponseAction implements Action {
  readonly type = DashboardActionTypes.TASKS_BY_ROLE_LIST_RESPONSE;
  constructor(public payload: MyTaskListResponse) {}
}

export type DashboardAction = TasksByRoleListRequestAction | TasksByRoleListResponseAction;
