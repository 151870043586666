import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SignedUrlObject } from '../models';
import {
  ResponseAssignmentListResponse,
  ResponseAssignmentListSearchCriteria,
  ResponseAssignmentStatusRequest,
  TaskAssignmentResponseModel
} from '../models/task-assignment-response.model';
import { TaskExportCriteria } from '../models/task-assignment.model';
import { BaseService } from './base.service';

@Injectable()
export class TaskAssignmentResponseService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.taskAssignmentResponse;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(
    criteria: ResponseAssignmentListSearchCriteria
  ): Observable<ResponseAssignmentListResponse> {
    const params = this.getParams(criteria, true);
    return this.http.get<any>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getTaskResponseById(responseNo: string): Observable<TaskAssignmentResponseModel> {
    const url = this.getFullUrl(this.envService.get, {
      responseNo
    });

    return this.http.get<TaskAssignmentResponseModel>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public putTaskResponseByStatus(payload: ResponseAssignmentStatusRequest): Observable<TaskAssignmentResponseModel> {
    const url = this.getFullUrl(this.envService.put, {
      status: payload.status
    });

    delete payload.status;

    return this.http.put<any>(url, payload, { headers: this.loaderHeaders(), observe: 'body' });
  }

  public getFileUrl(refId: string): Observable<SignedUrlObject> {
    const url = this.getFullUrl(this.envService.imageSignedUrl);
    return this.http.post<SignedUrlObject>(url, { refId }, { headers: this.headers });
  }

  public exportResponse(criteria: TaskExportCriteria): Observable<any> {
    const params = this.getParams(criteria, false, true);
    const url = this.getFullUrl(this.envService.exportResponse);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportSurvey(criteria: TaskExportCriteria): Observable<any> {
    const params = this.getParams(criteria, false, true);
    const url = this.getFullUrl(this.envService.exportSurvey);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public getStoreConsultant(responseNo: string): Observable<any> {
    const url = this.getFullUrl(this.envService.getStoreConsultant, {
      responseNo
    });
    return this.http.get<any>(url, { headers: this.headers });
  }
}
