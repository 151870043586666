import { createSelector } from '@ngrx/store';

import { AppStates } from '../../../../shared/store/state/app.states';
import * as fromRewardCatalogRequestState from './reward-catalog-request.reducers';

const selectRewardCatalogRequestState = (state: AppStates) => state.rewardCatalogRequest;

export const selectAllRewardCatalogRequestList = createSelector(
  selectRewardCatalogRequestState,
  fromRewardCatalogRequestState.selectAll
);

export const selectRewardCatalogRequestList = createSelector(
  selectRewardCatalogRequestState,
  (state: fromRewardCatalogRequestState.RewardCatalogRequestState) => state
);

export const selectRewardCatalogRequest = createSelector(
  selectRewardCatalogRequestState,
  (state: fromRewardCatalogRequestState.RewardCatalogRequestState) => state.selected
);

export const selectRewardCatalogRequestListCriteria = createSelector(selectRewardCatalogRequestState, state => {
  return state.criteriaObject;
});

export const selectRewardCatalogDeleteStatus = createSelector(
  selectRewardCatalogRequestState,
  (state: fromRewardCatalogRequestState.RewardCatalogRequestState) => {
    return state.isDeleteSuccess;
  }
);

export const selectRewardCatalogRequestHistory = createSelector(
  selectRewardCatalogRequestState,
  (state: fromRewardCatalogRequestState.RewardCatalogRequestState) => state.auditLogs
);
