import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { isEmpty } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { PasswordValidation } from '../../shared/custom-validators/password-validation';
import { ForgotPasswordStepEnum } from '../../shared/enum/forgot-password.enum';
import { ModalButtonResponseEnum } from '../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../shared/layouts';
import { ErrorResponse } from '../../shared/models';
import {
  ForgotPasswordChangePasswordRequestAction,
  ForgotPasswordOTPRequestAction,
  ForgotPasswordOTPTimeoutRequestAction,
  ForgotPasswordReset,
  ForgotPasswordVerifyOTPRequestAction
} from '../../shared/store/actions/forgot-password.action';
import { selectForgotPasswordResult } from '../../shared/store/selectors/forgot-password.selector';
import { AppStates } from '../../shared/store/state/app.states';
import { BaseForgotPasswordState } from '../../shared/store/state/forgot-password.state';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends OnDestroyMixin implements OnInit {
  public forgotPasswordForm: UntypedFormGroup;
  public inputOTP: UntypedFormGroup;
  public forgotPasswordResult$: Observable<BaseForgotPasswordState>;
  public submitted: boolean;
  public step: ForgotPasswordStepEnum;
  public buttonDisplay: string;
  public otp = '';
  public userName = '';
  public isTimeout = false;
  public isShowPassword: boolean;
  public isShowReEnterPassword: boolean;
  public hasOtpDelay: boolean;
  public otpTimerSubscribe;
  public countDownTimerSubscribe;

  private readonly countDownMinutes = 5;
  private readonly otpDelayMinutes = 1;
  private isCountingDown = false;
  private localStore: Observable<any>;
  private interval;

  constructor(
    private readonly store: Store<AppStates>,
    private readonly fb: UntypedFormBuilder,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly router: Router
  ) {
    super();
  }

  get formRequestOTP() {
    return this.forgotPasswordForm.get('requestOTP') as UntypedFormGroup;
  }

  get formChangePassword() {
    return this.forgotPasswordForm.get('changePassword') as UntypedFormGroup;
  }

  ngOnInit() {
    this.hasOtpDelay = false;
    this.step = ForgotPasswordStepEnum.REQUEST_OTP;

    this.store.dispatch(new ForgotPasswordReset());

    const controlsConfig = {
      requestOTP: this.fb.group({
        userName: ['', Validators.required]
      }),
      inputOTP: this.fb.group({}),
      changePassword: this.fb.group({
        newPassword: ['', [Validators.required, PasswordValidation, this.checkNewpassMismatch]],
        reEnterPassword: ['', [Validators.required, this.checkConfirmpassMismatch]]
      })
    };

    this.forgotPasswordForm = this.fb.group(controlsConfig);

    this.formChangePassword.disable();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.forgotPasswordResult$ = this.localStore.pipe(select(selectForgotPasswordResult));
    this.forgotPasswordResult$.pipe(map(value => value.result)).subscribe(result => {
      this.step = result.step;
      this.userName = result.userName;

      switch (this.step) {
        case ForgotPasswordStepEnum.REQUEST_OTP:
          this.doRequestOtpStep(result);
          this.buttonDisplay = 'OK';
          break;
        case ForgotPasswordStepEnum.ENTER_OTP:
          this.doEnterOtpStep(result);
          this.buttonDisplay = 'CONTINUE';
          break;
        case ForgotPasswordStepEnum.CHANGE_PASSWORD:
          this.doChangePasswordStep(result);
          this.buttonDisplay = 'SUBMIT';
          break;
        default:
          break;
      }
    });
  }

  onCancel() {
    this.router.navigateByUrl('/');
  }

  private doRequestOtpStep(result) {
    if (!result.response && ['00000', '01002', '01003'].includes(result.errorResponse.code)) {
      this.submitted = false;
    }
  }

  private doEnterOtpStep(result) {
    if (!this.isCountingDown && !this.isTimeout) {
      this.doOtpDelay();
      this.startCountDown();
    }

    if (!result.response && ['01006', '01007'].includes(result.errorResponse.code)) {
      this.otp = '';
    }
  }

  private doChangePasswordStep(result) {
    clearInterval(this.interval);

    this.formChangePassword.enable();
    this.submitted = false;

    if (!result.response && ['01008'].includes(result.errorResponse.code)) {
      this.alertAndBack(result.errorResponse);
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.forgotPasswordForm.invalid || this.isDisableButton()) {
      return;
    }

    if (this.step === ForgotPasswordStepEnum.REQUEST_OTP) {
      this.store.dispatch(new ForgotPasswordOTPRequestAction(this.formRequestOTP.value.userName));
    } else if (this.step === ForgotPasswordStepEnum.ENTER_OTP) {
      this.store.dispatch(
        new ForgotPasswordVerifyOTPRequestAction({ userName: this.formRequestOTP.value.userName, otp: this.otp })
      );
    } else if (this.step === ForgotPasswordStepEnum.CHANGE_PASSWORD) {
      this.store.dispatch(
        new ForgotPasswordChangePasswordRequestAction({
          userName: this.formRequestOTP.value.userName,
          newPassword: this.formChangePassword.value.newPassword,
          otp: this.otp
        })
      );
    }
  }

  getPageTitle(): string | undefined {
    if (this.step === ForgotPasswordStepEnum.REQUEST_OTP) {
      return 'FORGOT_PASSWORD';
    } else if (this.step === ForgotPasswordStepEnum.ENTER_OTP) {
      return 'ENTER_OTP';
    } else if (this.step === ForgotPasswordStepEnum.CHANGE_PASSWORD) {
      return 'ENTER_NEW_PASSWORD';
    }
  }

  isDisableButton() {
    if (this.step === ForgotPasswordStepEnum.ENTER_OTP && this.otp.length !== 6) {
      return true;
    }

    return false;
  }

  alertAndBack(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant('ERROR_CODE.' + errorResponse.code)
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();

        this.router.navigateByUrl('/');
      }
    });
  }

  onEnterKey(event) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  resendOtp() {
    if (this.step === ForgotPasswordStepEnum.ENTER_OTP) {
      this.isTimeout = false;
      this.isCountingDown = false;
      this.otp = '';

      this.store.dispatch(new ForgotPasswordOTPRequestAction(this.formRequestOTP.value.userName));
      this.otpTimerSubscribe.unsubscribe();
      this.countDownTimerSubscribe.unsubscribe();
      this.doOtpDelay();
      this.startCountDown();
    }
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  otpTimeOut() {
    if (this.step === ForgotPasswordStepEnum.ENTER_OTP) {
      this.isTimeout = true;
      this.isCountingDown = false;

      this.otp = '';

      this.store.dispatch(new ForgotPasswordOTPTimeoutRequestAction());
    }
  }

  doOtpDelay() {
    const delaySeconds = this.otpDelayMinutes * 60;

    this.hasOtpDelay = true;

    this.otpTimerSubscribe = timer(delaySeconds * 1000).subscribe(() => {
      this.hasOtpDelay = false;
    });
  }

  startCountDown() {
    this.isCountingDown = true;

    let counter = this.countDownMinutes * 60;

    const minutesElement = document.querySelector('.minutes');
    const secondsElement = document.querySelector('.seconds');

    this.displayCountdownTime(counter, minutesElement, secondsElement);

    this.countDownTimerSubscribe = timer(0, 1000)
      .pipe(take(counter + 1))
      .subscribe(() => {
        this.displayCountdownTime(counter, minutesElement, secondsElement);
        counter--;

        if (counter < 0) {
          clearInterval(this.interval);
          this.otpTimeOut();
        }
      });
  }

  displayCountdownTime(second: number, minutesElement: Element, secondsElement: Element) {
    const toMinutes = Math.floor(second / 60);
    const toSeconds = Math.floor(second) % 60;

    const toMinutesDisplay = toMinutes.toString();
    let toSecondsDisplay = toSeconds.toString();

    if (toSecondsDisplay.length === 1) {
      toSecondsDisplay = '0' + toSecondsDisplay;
    }

    this.updateDom(toMinutesDisplay, minutesElement);
    this.updateDom(toSecondsDisplay, secondsElement);
  }

  updateDom(value: string, element: Element) {
    element.innerHTML = value;
  }

  checkPasswords(group: UntypedFormGroup) {
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.reEnterPassword.value;

    return group.controls.reEnterPassword.setErrors(pass === confirmPass ? null : { passwordNotEquivalent: true });
  }

  toggleInputPasswordType() {
    this.isShowPassword = !this.isShowPassword;
  }

  toggleInputReEnterPasswordType() {
    this.isShowReEnterPassword = !this.isShowReEnterPassword;
  }

  clearPasswordSamePreviousError() {
    this.formChangePassword.get('newPassword').setErrors(null);
  }

  get checkConfirmpassMismatch(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const pass = this.formChangePassword?.get('newPassword').value;
      const confirmPass = control.value;
      return pass === confirmPass ? null : { passwordNotEquivalent: true };
    };
  }

  get checkNewpassMismatch(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      if (this.formChangePassword?.controls) {
        const pass = control.value;
        const confirmPass = this.formChangePassword?.get('reEnterPassword').value;
        if (pass === confirmPass) {
          this.formChangePassword?.get('reEnterPassword').setErrors(null);
          if (this.submitted && isEmpty(confirmPass)) {
            this.formChangePassword?.get('reEnterPassword').setErrors({ required: true });
          }
        } else {
          this.formChangePassword?.get('reEnterPassword').setErrors({ passwordNotEquivalent: true });
        }
      }
      return null;
    };
  }
}
