import { Action } from '@ngrx/store';

import {
  ApproveRejectAssortmentRequest,
  Assortment,
  AuditLog,
  BarcodeSnapshot,
  CJBarcode,
  CJProductLinkArticleDetail,
  ErrorResponse,
  RequestAssortmentEditResponse,
  RequestAssortmentResponse,
  RequestAssortmentSearchCriteria,
  SupplierPriceErrorResponse,
  SupplierPriceSnapshot
} from '../../models';

export enum RequestAssortmentActionTypes {
  RequestAssortmentSearchRequested = '[Request Assortment List Page] Request Assortment Search Requested',
  RequestAssortmentSearchLoaded = '[Request Assortment API] Request Assortment Search Loaded',
  RequestAssortmentDeleteRequested = '[Request Assortment List Page] Request Assortment Delete Requested',
  RequestAssortmentDeleteSuccess = '[Request Assortment API] Request Assortment Delete Success',
  RequestAssortmentCancelRequested = '[Request Assortment] Request Assortment Cancel Requested',
  RequestAssortmentCancelSuccess = '[Request Assortment] Request Assortment Cancel Success',
  RequestAssortmentCriteriaObjectUpdated = '[Request Assortment List Page] Request Assortment Criteria Updated',
  RequestAssortmentResetDeleteSuccess = '[Request Assortment List Page] Request Assortment Reset Delete Success',
  RequestAssortmentLoadRequested = '[Request Assortment Edit Page] Request Assortment Load Requested',
  RequestAssortmentLoaded = '[Request Assortment API] Request Assortment Load Success',
  RequestAssortmentSet = '[Request Assortment Create Page] Request Assortment Create',
  RequestAssortmentUpdateRequested = '[Request Assortment Edit Page] Request Assortment Update Requested',
  RequestAssortmentCreateRequested = '[Request Assortment Create Page] Request Assortment Create Requested',
  RequestAssortmentUpdateSuccess = '[Request Assortment API] Request Assortment Update Success',
  RequestAssortmentSubmitRequested = '[Request Assortment Edit Page] Request Assortment Submit Requested',
  RequestAssortmentSubmitError = '[Request Assortment Edit Page] Request Assortment Submit Error',
  RequestAssortmentSubmitErrorReset = '[Request Assortment Edit Page] Request Assortment Submit Error Reset',
  RequestAssortmentSubmitSuccess = '[Request Assortment API] Request Assortment Submit Success',
  RequestAssortmentSupplierPriceError = '[Request Assortment API] Request Assortment Supplier Price Error',
  RequestAssortmentSupplierPriceErrorReset = '[Request Assortment API] Request Assortment Supplier Price Error Reset',
  RequestAssortmentReset = '[Request Assortment Edit Page] Request Assortment Reset',
  RequestAssortmentApproveRequested = '[Request Assortment View Page] Request Assortment Approve Requested',
  RequestAssortmentApproveRejectSuccess = '[Request Assortment API] Request Assortment Approve/Reject Success',
  RequestAssortmentRejectRequested = '[Request Assortment View Page] Request Assortment Reject Requested',
  RequestAssortmentCopyRequested = '[Request Assortment List Page] Request Assortment Copy Requested',
  RequestAssortmentCopySuccess = '[Request Assortment API] Request Assortment Copy Success',
  RequestAssortmentClearCopySuccess = '[Request Assortment List Page] Request Assortment Clear Copy Success',

  RequestAssortmentCheckCJDuplicateBarcodeRequest = '[Request Assortment Edit Page] Request Assortment Check CJ Duplicate Barcode Request',
  RequestAssortmentCheckCJDuplicateBarcodeResponse = '[Request Assortment API] Request Assortment Check CJ Duplicate Barcode Response',
  RequestAssortmentCheckCJDuplicateBarcodeReset = '[Request Assortment Edit Page] Request Assortment Check CJ Duplicate Barcode Reset',

  RequestAssortmentDuplicateBarcode = '[Request Assortment API] Request Assortment Check Duplicate Barcode',
  RequestAssortmentDuplicateBarcodeReset = '[Request Assortment API] Request Assortment Duplicate Barcode Reset',

  SupplierPriceUpdated = '[Supplier Price Dialog] Supplier Price Updated',
  SupplierPriceAdded = '[Supplier Price Dialog] Supplier Price Added',
  SupplierPriceDeleted = '[Supplier Price Dialog] Supplier Price Deleted',

  RequestBarcodeUpdated = '[Request Barcode Dialog] Request Barcode Updated',
  RequestBarcodeAdded = '[Request Barcode Dialog] Request Barcode Added',
  RequestBarcodeDeleted = '[Request Barcode Dialog] Request Barcode Deleted',
  RequestBarcodeCouponDiscountUpdated = '[Request Assortment Edit Page] Request Barcode Coupon Discount Updated',

  AllowToOrderBarcodeUpdated = '[Request Assortment Edit Page] Allow To Order Barcode Updated',
  AllowToBuyBarcodeUpdated = '[Request Assortment Edit Page] Allow To Buy Barcode Updated',

  ProductAssortmentLoadRequested = '[Product Assortment View Page] Product Assortment Load Requested',
  ProductAssortmentLoaded = '[Product Assortment API] Product Assortment Load Success',
  ProductAssortmentHistoryRequested = '[Product Assortment API] Product Assortment History Requested',
  ProductAssortmentHistoryLoaded = '[Product Assortment API] Product Assortment History Load Success',

  AssortmentFreshLifeListRequest = '[Assortment Fresh Life List Page] Fresh Life List Search Requested',
  AssortmentFreshLifeListLoaded = '[Assortment Fresh Life API] Fresh Life List Search Loaded',
  LinkArticleDetailRequest = '[Request Assortment link Article] Get link cj article details by article no Request',
  LinkArticleDetailResponse = '[Request Assortment link Article] Get link cj article details by article no Response',
  LinkArticleDetailReset = '[Request Assortment link Article] Get link cj article details by article no Reset'
}

export class RequestAssortmentSearchRequested implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentSearchRequested;
  constructor(public payload: RequestAssortmentSearchCriteria) {}
}

export class RequestAssortmentSearchLoaded implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentSearchLoaded;
  constructor(public payload: { requestAssortment: RequestAssortmentResponse }) {}
}

export class RequestAssortmentDeleteRequested implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentDeleteRequested;
  constructor(public payload: { requestId: string }) {}
}

export class RequestAssortmentDeleteSuccess implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentDeleteSuccess;
  constructor() {}
}

export class RequestAssortmentCancelRequested implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentCancelRequested;
  constructor(public payload: ApproveRejectAssortmentRequest) {}
}

export class RequestAssortmentCancelSuccess implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentCancelSuccess;
  constructor() {}
}

export class RequestAssortmentResetDeleteSuccess implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentResetDeleteSuccess;
  constructor() {}
}

export class RequestAssortmentLoadRequested implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentLoadRequested;
  constructor(public payload: { requestId: string }) {}
}

export class RequestAssortmentLoaded implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentLoaded;
  constructor(public payload: { requestAssortment: RequestAssortmentEditResponse }) {}
}

export class RequestAssortmentSet implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentSet;
  constructor(public payload: { requestAssortment: any }) {}
}

export class SupplierPriceAdded implements Action {
  readonly type = RequestAssortmentActionTypes.SupplierPriceAdded;
  constructor(public payload: SupplierPriceSnapshot) {}
}

export class SupplierPriceUpdated implements Action {
  readonly type = RequestAssortmentActionTypes.SupplierPriceUpdated;
  constructor(public payload: SupplierPriceSnapshot) {}
}

export class SupplierPriceDeleted implements Action {
  readonly type = RequestAssortmentActionTypes.SupplierPriceDeleted;
  constructor(public payload) {}
}

export class RequestBarcodeUpdated implements Action {
  readonly type = RequestAssortmentActionTypes.RequestBarcodeUpdated;
  constructor(public payload: { barcode: BarcodeSnapshot }) {}
}

export class RequestBarcodeCouponDiscountUpdated implements Action {
  readonly type = RequestAssortmentActionTypes.RequestBarcodeCouponDiscountUpdated;
  constructor(public payload: boolean) {}
}

export class RequestBarcodeAdded implements Action {
  readonly type = RequestAssortmentActionTypes.RequestBarcodeAdded;
  constructor(public payload: { barcode: BarcodeSnapshot }) {}
}

export class RequestBarcodeDeleted implements Action {
  readonly type = RequestAssortmentActionTypes.RequestBarcodeDeleted;
  constructor(public payload) {}
}

export class AllowToOrderBarcodeUpdated implements Action {
  readonly type = RequestAssortmentActionTypes.AllowToOrderBarcodeUpdated;
  constructor(public payload: string[]) {}
}

export class AllowToBuyBarcodeUpdated implements Action {
  readonly type = RequestAssortmentActionTypes.AllowToBuyBarcodeUpdated;
  constructor(public payload: string) {}
}

export class RequestAssortmentUpdateRequested implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentUpdateRequested;
  // constructor(public payload: { requestAssortment: RequestAssortmentRequestResponse }) {}
  constructor(public payload: { assortment: Assortment }) {}
}

export class RequestAssortmentCreateRequested implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentCreateRequested;
  constructor(public payload: { assortment: Assortment }) {}
}

export class RequestAssortmentUpdateSuccess implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentUpdateSuccess;
  constructor(public payload: { status: boolean }) {}
}

export class RequestAssortmentSubmitRequested implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentSubmitRequested;
  constructor(public payload: { assortment: Assortment }) {}
}

export class RequestAssortmentSubmitSuccess implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentSubmitSuccess;
  constructor() {}
}

export class RequestAssortmentSubmitError implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentSubmitError;

  constructor(public payload: ErrorResponse) {}
}

export class RequestAssortmentSupplierPriceError implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentSupplierPriceError;

  constructor(public payload: SupplierPriceErrorResponse) {}
}

export class RequestAssortmentSupplierPriceErrorReset implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentSupplierPriceErrorReset;

  constructor() {}
}

export class RequestAssortmentSubmitErrorReset implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentSubmitErrorReset;

  constructor() {}
}

export class RequestAssortmentCriteriaObjectUpdated implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentCriteriaObjectUpdated;
  constructor(public payload: { criteriaObject: RequestAssortmentSearchCriteria }) {}
}

export class RequestAssortmentReset implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentReset;
  constructor() {}
}

export class ProductAssortmentLoadRequested implements Action {
  readonly type = RequestAssortmentActionTypes.ProductAssortmentLoadRequested;
  constructor(public payload: { data: string; isProduct: boolean }) {}
}

export class ProductAssortmentLoaded implements Action {
  readonly type = RequestAssortmentActionTypes.ProductAssortmentLoaded;
  constructor(public payload: { requestAssortment: Assortment }) {}
}

export class ProductAssortmentHistoryRequested implements Action {
  readonly type = RequestAssortmentActionTypes.ProductAssortmentHistoryRequested;

  constructor(public payload: string) {}
}

export class ProductAssortmentHistoryLoaded implements Action {
  readonly type = RequestAssortmentActionTypes.ProductAssortmentHistoryLoaded;

  constructor(public payload: { auditLogs: AuditLog[] }) {}
}

export class RequestAssortmentApproveRequested implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentApproveRequested;
  constructor(public payload: ApproveRejectAssortmentRequest) {}
}

export class RequestAssortmentApproveRejectSuccess implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentApproveRejectSuccess;
  constructor(public payload: { isSuccess: boolean }) {}
}

export class RequestAssortmentRejectRequested implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentRejectRequested;
  constructor(public payload: ApproveRejectAssortmentRequest) {}
}

export class RequestAssortmentCopyRequested implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentCopyRequested;
  constructor(public payload: { requestId: string }) {}
}

export class RequestAssortmentCopySuccess implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentCopySuccess;
  constructor(public payload: { requestId: string }) {}
}

export class RequestAssortmentClearCopySuccess implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentClearCopySuccess;
  constructor() {}
}

export class AssortmentFreshLifeSearchRequested implements Action {
  readonly type = RequestAssortmentActionTypes.AssortmentFreshLifeListRequest;
  constructor() {}
}

export class AssortmentFreshLifeSearchLoaded implements Action {
  readonly type = RequestAssortmentActionTypes.AssortmentFreshLifeListLoaded;
  constructor(public payload: RequestAssortmentSearchCriteria) {}
}

export class RequestAssortmentCheckCJDuplicateBarcodeRequest implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentCheckCJDuplicateBarcodeRequest;
  constructor(public payload: { articleNo: string; barcode: string[] }) {}
}

export class RequestAssortmentCheckCJDuplicateBarcodeResponse implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentCheckCJDuplicateBarcodeResponse;

  constructor(public payload: { cjBarcodeList: CJBarcode[] }) {}
}

export class RequestAssortmentCheckCJDuplicateBarcodeReset implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentCheckCJDuplicateBarcodeReset;
  constructor() {}
}

export class RequestAssortmentDuplicateBarcode implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentDuplicateBarcode;

  constructor(public payload: string) {}
}

export class RequestAssortmentDuplicateBarcodeReset implements Action {
  readonly type = RequestAssortmentActionTypes.RequestAssortmentDuplicateBarcodeReset;

  constructor() {}
}

export class ProductGetLinkArticleDetailRequestAction implements Action {
  readonly type = RequestAssortmentActionTypes.LinkArticleDetailRequest;
  constructor(public payload: string) {}
}

export class ProductGetLinkArticleDetailResponseAction implements Action {
  readonly type = RequestAssortmentActionTypes.LinkArticleDetailResponse;
  constructor(public payload: CJProductLinkArticleDetail) {}
}

export class ProductGetLinkArticleDetailResetAction implements Action {
  readonly type = RequestAssortmentActionTypes.LinkArticleDetailReset;
  constructor() {}
}

export type RequestAssortmentActions =
  | RequestAssortmentSearchRequested
  | RequestAssortmentSearchLoaded
  | RequestAssortmentDeleteRequested
  | RequestAssortmentDeleteSuccess
  | RequestAssortmentResetDeleteSuccess
  | RequestAssortmentLoadRequested
  | RequestAssortmentLoaded
  | RequestAssortmentSet
  | SupplierPriceAdded
  | SupplierPriceUpdated
  | SupplierPriceDeleted
  | RequestBarcodeUpdated
  | RequestBarcodeAdded
  | RequestBarcodeDeleted
  | AllowToOrderBarcodeUpdated
  | AllowToBuyBarcodeUpdated
  | RequestAssortmentCreateRequested
  | RequestAssortmentUpdateRequested
  | RequestAssortmentUpdateSuccess
  | RequestAssortmentSubmitRequested
  | RequestAssortmentSubmitSuccess
  | RequestAssortmentCriteriaObjectUpdated
  | RequestAssortmentReset
  | ProductAssortmentLoadRequested
  | ProductAssortmentLoaded
  | RequestAssortmentApproveRequested
  | RequestAssortmentApproveRejectSuccess
  | RequestAssortmentRejectRequested
  | RequestAssortmentCopyRequested
  | RequestAssortmentCopySuccess
  | RequestAssortmentClearCopySuccess
  | RequestBarcodeCouponDiscountUpdated
  | RequestAssortmentCheckCJDuplicateBarcodeRequest
  | RequestAssortmentCheckCJDuplicateBarcodeResponse
  | RequestAssortmentCheckCJDuplicateBarcodeReset
  | RequestAssortmentCancelRequested
  | RequestAssortmentCancelSuccess
  | RequestAssortmentDuplicateBarcode
  | RequestAssortmentDuplicateBarcodeReset
  | RequestAssortmentSubmitError
  | RequestAssortmentSupplierPriceError
  | RequestAssortmentSupplierPriceErrorReset
  | RequestAssortmentSubmitErrorReset
  | ProductGetLinkArticleDetailRequestAction
  | ProductGetLinkArticleDetailResponseAction
  | ProductGetLinkArticleDetailResetAction
  | ProductAssortmentHistoryRequested
  | ProductAssortmentHistoryLoaded;
