import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { StoresService } from '../../services/stores.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { StoresActionTypes, StoresListRequest, StoresListResponse } from '../actions/stores.action';

@Injectable()
export class StoresEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly storesService: StoresService,
    private readonly logger: NGXLogger
  ) {}

  searchStores$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoresListRequest>(StoresActionTypes.STORES_PAGING_LIST_REQUEST),
      tap(action => this.logger.debug(`@Effect [Stores] Stores List request:` + JSON.stringify(action.payload))),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(action =>
        this.storesService.storePaging(action.payload).pipe(
          map(result => new StoresListResponse(result)),
          catchError(err => of(new LayoutActionLoadError(err)))
        )
      )
    );
  });
}
