import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { AuditLog } from '../../models';
import { OrderContent, OrderSearchCriteria } from '../../models/order.model';
import { OrderActionTypes, OrderActions } from '../actions/order.actions';

export interface OrderState extends EntityState<OrderContent> {
  isLoading: boolean;
  viewOrder: OrderContent;
  criteriaObject: OrderSearchCriteria;
  totalElements: number;
  totalPages: number;
  orderReleaseError: any;
  auditLogs: AuditLog[];
}

export const adapter: EntityAdapter<OrderContent> = createEntityAdapter<OrderContent>();

export const initialOrderState: OrderState = adapter.getInitialState({
  isLoading: false,
  viewOrder: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  orderReleaseError: null,
  auditLogs: null
});

export function orderReducers(state = initialOrderState, action: OrderActions): OrderState {
  switch (action.type) {
    case OrderActionTypes.OrderListSearchLoaded:
      return adapter.setAll(action.payload.orders.content, {
        ...state,
        page: action.payload.orders.page,
        size: action.payload.orders.size,
        totalElements: action.payload.orders.totalElements,
        totalPages: action.payload.orders.totalPages
      });
    case OrderActionTypes.OrderViewLoaded:
      return {
        ...state,
        viewOrder: action.payload
      };
    case OrderActionTypes.OrderReleaseOrderRequestedError:
      return {
        ...state,
        orderReleaseError: action.payload
      };
    case OrderActionTypes.OrderHistoryLoaded: {
      return {
        ...state,
        auditLogs: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
