import { state, style, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import { round } from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import * as numeral from 'numeral';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { orderFlow } from '@shared/models/list-value/list-key-value.model';
import { localCompareEnglish } from '@shared/utils/sorting-localcompare-util';

import { environment } from '../../../../environments/environment';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { UploadDocumentButtonComponent } from '../../../shared/components/upload-document-button/upload-document-button.component';
import { ApproveStatusEnum } from '../../../shared/enum/approve-status.enum';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { OrderSubTypeEnum, OrderTypeEnum, OrderingMethodEnum } from '../../../shared/enum/ordering-method.enum';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { NewRequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestProductErrorEnum } from '../../../shared/enum/request-step.enum';
import { StoreType } from '../../../shared/enum/store.enum';
import { GraphqlQueryObject } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { UiPaginationComponent } from '../../../shared/layouts/ui-pagination/ui-pagination.component';
import {
  ErrorFixAssetResult,
  ErrorOrderRequestResponse,
  ErrorResult,
  Money,
  ShelfSelectedItems,
  TaskModuleUrl
} from '../../../shared/models';
import { AuditLog, HistoryType } from '../../../shared/models/audit-log.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { OrderFixAssetRequestViewResponse } from '../../../shared/models/order-request-fix-asset.model';
import {
  DeliveryDetail,
  DeliveryDetailsByTypeDictionary,
  FixAssetProductOrderItem,
  InventoryTypeEnum,
  OrderRequestListSearchCriteria,
  ProductOrderItem,
  ShelfFixAssetOrderItem
} from '../../../shared/models/order-request.model';
import { ShelfFixAssetTabs } from '../../../shared/models/shelf.model';
import { WarehouseListContent } from '../../../shared/models/warehouse.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { OrderFixAssetRequestService } from '../../../shared/services/order-fix-asset-request.service';
import { OrderRequestExportService } from '../../../shared/services/order-request-export.service';
import { OrderRequestService } from '../../../shared/services/order-request.service';
import {
  OrderFixAssetApproveRequestAction,
  OrderFixAssetCreateResetAction,
  OrderFixAssetCreateSaveRequestAction,
  OrderFixAssetCreateSubmitRequestAction,
  OrderFixAssetRequestByIdRequestAction,
  OrderFixAssetRequestSaveDeliveryDetailsAction,
  ResetOrderFixAssetRequestSelected
} from '../../../shared/store/actions/order-fix-asset-request.actions';
import {
  OrderRequestHistoryRequestAction,
  OrderRequestHistoryResponseAction,
  OrderRequestListRequestAction
} from '../../../shared/store/actions/order-request.actions';
import {
  OrderSelectItemOrderAddAllItem,
  OrderSelectItemOrderRemoveItem,
  OrderSelectItemOrderReset,
  OrderSelectItemOrderUpdateItem
} from '../../../shared/store/actions/order-select-item-order.actions';
import {
  OrderStoreUseSelectItemOrderAddAllItem,
  OrderStoreUseSelectItemOrderAddItem,
  OrderStoreUseSelectItemOrderRemoveItem,
  OrderStoreUseSelectItemOrderReset,
  OrderStoreUseSelectItemOrderUpdateItem
} from '../../../shared/store/actions/order-store-use-select-item.actions';
import {
  ShelfFixAssetDetailsRequestAction,
  ShelfFixAssetDetailsResetAction
} from '../../../shared/store/actions/shelf-fix-asset-details.actions';
import {
  ShelfSelectFixAssetOrderAddAllItem,
  ShelfSelectFixAssetOrderRemoveItem,
  ShelfSelectFixAssetOrderReset,
  ShelfSelectFixAssetOrderSelectedShelf,
  ShelfSelectFixAssetOrderUpdateItem
} from '../../../shared/store/actions/shelf-select-fix-asset-order.actions';
import { ShelfSelectFixAssetListReset } from '../../../shared/store/actions/shelf-select-fix-asset.actions';
import { OrderFixAssetCreateResponseState } from '../../../shared/store/reducers/order-fix-asset-create.reducers';
import {
  selectOrderFixAssetApproveRejectStatus,
  selectOrderFixAssetRequest
} from '../../../shared/store/selectors/order-create.selectors';
import { selectOrderFixAssetRequestById } from '../../../shared/store/selectors/order-fix-asset-request.selector';
import {
  selectOrderRequestHistory,
  selectOrderRequestListCriteria
} from '../../../shared/store/selectors/order-request.selector';
import {
  selectAllOrderSelectItemOrder,
  selectOrderUpdated
} from '../../../shared/store/selectors/order-select-item-order.selector';
import {
  getOrderStoreUseSelectItemOrderById,
  selectAllOrderStoreUseSelectItemOrder,
  selectOrderStoreUseUpdated
} from '../../../shared/store/selectors/order-store-use-select-item-order.selector';
import { selectAllShelfFixAssetDetails } from '../../../shared/store/selectors/shelf-fix-asset-details.selectors';
import {
  selectAllShelfSelectFixAssetOrder,
  selectIsSelectedShelf,
  selectShelfOrderUpdated
} from '../../../shared/store/selectors/shelf-select-fix-asset-order.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { ItemPrice, getPriceFormat } from '../../../shared/utils/calculate-price-util';
import { DeliveryDetailsComponent } from '../order-components/delivery-details/delivery-details.component';
import { OrderModalUploadComponent } from '../order-components/order-modal-upload/order-modal-upload.component';
import { SelectItemModalComponent } from '../order-components/select-item-modal/select-item-modal.component';
import { SelectShelfFixAssetModalComponent } from '../order-components/select-shelf-fix-asset-modal/select-shelf-fix-asset-modal.component';
import { SelectStoreUseModalComponent } from '../order-components/select-store-use-modal/select-store-use-modal.component';

@Component({
  selector: 'app-order-request-fix-asset',
  templateUrl: './order-request-fix-asset.component.html',
  styleUrls: ['./order-request-fix-asset.component.scss'],
  animations: [
    trigger('slide', [state('up', style({ height: 0, display: 'none' })), state('down', style({ height: '*' }))])
  ]
})
export class OrderRequestFixAssetComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @ViewChild('specialPaging', { static: false }) specialPaging: UiPaginationComponent;
  @ViewChild('shelfPaging', { static: false }) shelfPaging: UiPaginationComponent;
  @ViewChild('storeUsePaging', { static: false }) storeUsePaging: UiPaginationComponent;
  @ViewChild('importStoreUse', { static: false }) importStoreUse: UploadDocumentButtonComponent;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Input() data: {
    title: string;
    mode: RequestPageModesEnum;
    orderType?: OrderTypeEnum;
    orderSubType?: OrderSubTypeEnum;
    warehouseCode?: string;
    warehouseDisplayName?: string;
    requestId?: string;
    originPage?: string;
    orderFlow: string;
    crossDockSupplier: string;
  };

  private localStore: Observable<any>;
  private listSearchCriteria: OrderRequestListSearchCriteria;
  private requestNo: string;
  public status: NewRequestStatusEnum;
  private isDataUpdated: boolean;
  private shelfSkipUpdated: boolean;
  private specialSkipUpdated: boolean;
  private storeUseSkipUpdated: boolean;
  private segmentList = [];
  public segments = {};

  public orderRequestView$: Observable<OrderFixAssetRequestViewResponse>;
  public orderRequestForm: UntypedFormGroup;
  public selectedShelf: ShelfSelectedItems[] = [];
  public orderRequest: OrderFixAssetRequestViewResponse;
  public warehouseList: WarehouseListContent[];
  public editingShelf: string[] = [];
  public expandShelf: string[] = [];

  public searchForm: UntypedFormGroup;
  public saved: boolean;
  public submitted: boolean;
  public orderTypeEnum = OrderTypeEnum;
  public pageModeEnum = RequestPageModesEnum;
  public vatPct$: Observable<number>;
  public storeNo: string;
  public storeCode: string;

  public auditLogs$: Observable<AuditLog[]>;

  public dateTimeDisplay = environment.dateTimeDisplay;
  public isLoading = false;
  public isSkipSetFilteredForm = false;

  public numberFormat = '0,0.00';
  public buttons: Array<ImportExportButton>;

  public currentShelfPage = 1;
  public pageShelfSize = 10;
  public pageShelfSizeList = [10, 20, 50];
  public currentSpecialPage = 1;
  public pageSpecialSize = 20;
  public currentStoreUsePage = 1;
  public pageStoreUseSize = 20;
  public filteredFormGroup: UntypedFormGroup;
  public filteredShelfOrderFormGroup: UntypedFormGroup;
  public filterStoreUseFormGroup: UntypedFormGroup;
  public inventoryType = InventoryTypeEnum;
  public orderFlowEnum = orderFlow;

  public errorExport: string;

  public deliveryDetailsCondition;
  public selectedTab: ShelfFixAssetTabs;
  public importBarcodeUrl = `${environment.serverUrl}${environment.services.orderRequestFixAsset.url}${environment.services.orderRequestFixAsset.importValidate}`;
  public importHeaders = environment.services.orderRequestFixAsset.headers;
  public orderModalUpload: BsModalRef;
  importResult: { title: string; table: any[]; message: string };
  @ViewChild('importResultModel', { static: false }) importResultModel: ModalDirective;

  constructor(
    public readonly store: Store<AppStates>,
    private readonly fb: UntypedFormBuilder,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly orderRequestService: OrderRequestService,
    private readonly orderFixAssetRequestService: OrderFixAssetRequestService,
    private readonly orderRequestExportService: OrderRequestExportService,
    private readonly authGuardService: AuthGuardService,
    protected masterService: MasterService,
    private el: ElementRef
  ) {
    super();
  }

  ngOnInit() {
    this.getDeliveryDetailsCondition();
    this.initialControl();
    this.initState();
    this.getButton();
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.EXPORT,
        name: 'Export',
        hidden: !this.isViewMode
      },
      {
        type: ButtonType.IMPORT,
        name: 'Import',
        hidden: this.getHiddenButtonImport()
      }
    ];
  }

  private getHiddenButtonImport(): boolean {
    let result = true;
    if (!this.isViewMode) {
      if ([NewRequestStatusEnum.ASSIGN_STORE_EQUIPMENT].includes(this.status)) {
        result = false;
      }
    }
    return result;
  }

  getDeliveryDetailsCondition() {
    this.deliveryDetailsCondition = [
      {
        title: 'Fix Asset',
        segment: InventoryTypeEnum.FIX_ASSET,
        properties: ['FIX_ASSET_WAREHOUSE', 'FIX_ASSET_SUPPLIER'],
        noOfDateBefore: 2
      },
      {
        title: 'Store Use',
        segment: InventoryTypeEnum.STORE_USE,
        properties: ['STORE_USE_WAREHOUSE', 'STORE_USE_SUPPLIER'],
        noOfDateBefore: 2
      }
    ];
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.store.dispatch(new ResetOrderFixAssetRequestSelected());
    this.store.dispatch(new OrderFixAssetCreateResetAction());
    this.store.dispatch(new OrderSelectItemOrderReset());
    this.store.dispatch(new OrderStoreUseSelectItemOrderReset());
    this.store.dispatch(new ShelfSelectFixAssetListReset());
    this.store.dispatch(new ShelfSelectFixAssetOrderReset());
    this.store.dispatch(new ShelfFixAssetDetailsResetAction());

    if (this.data && this.data.originPage !== TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new OrderRequestListRequestAction(this.listSearchCriteria));
    }
  }

  initialControl() {
    this.selectedTab = this.shelfFixAssetTabs.FIX_ASSET;

    this.orderRequestForm = this.fb.group({
      shelfOrderItems: this.fb.array([]),
      specialOrderItems: this.fb.array([]),
      storeUseOrderItems: this.fb.array([])
    });

    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });

    this.filteredFormGroup = this.fb.group({
      specialOrderItems: this.fb.array([])
    });

    this.filterStoreUseFormGroup = this.fb.group({
      storeUseOrderItems: this.fb.array([])
    });

    this.filteredShelfOrderFormGroup = this.fb.group({
      shelfOrderItems: this.fb.array([])
    });
  }

  initState() {
    const countryQuery = new GraphqlQueryObject();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    countryQuery.name = MasterDataEnum.COUNTRY;
    countryQuery.fields = ['id', 'code', 'nameEn', 'vatPct'];

    this.vatPct$ = this.masterService
      .getMasterDataByNames([countryQuery])
      .pipe(map(result => result.data.countries.find(data => data.nameEn === environment.defaultCountry).vatPct));

    this.orderRequest = new OrderFixAssetRequestViewResponse();
    this.orderRequest.amount = getPriceFormat();
    this.orderRequest.vatAmount = getPriceFormat();
    this.orderRequest.amountExcVat = getPriceFormat();

    this.orderRequestView$ = this.localStore.pipe(select(selectOrderFixAssetRequestById));
    this.orderRequestView$.pipe(filter(data => Boolean(data && data.store))).subscribe(data => {
      this.storeNo = data.store.storeNo;
      this.storeCode = data.store.storeCode;
    });

    this.localStore.pipe(select(selectIsSelectedShelf)).subscribe(data => {
      if (data) {
        this.expandShelf = [];
        this.editingShelf = [];

        this.baseShelfOrderFormArray.clear();
        this.store.dispatch(new ShelfSelectFixAssetOrderSelectedShelf(false));
      }
    });

    this.orderRequestService
      .getSegmentListData()
      .pipe(
        untilComponentDestroyed(this),
        filter(result => Boolean(result && result.data)),
        map(result => result.data)
      )
      .subscribe(data => {
        const segments = data[MasterDataEnum.SEGMENT];
        const regex = /[^\s]+$/;

        this.segments = segments.reduce((prev, obj) => {
          // Check if segment is 'OTHER' or 'PROMOTION' then set it to the section 'PROMOTION_OTHERS'
          prev[obj.code] = ['SEG00003', 'SEG00004'].includes(obj.code)
            ? this.inventoryType.PROMOTION_OTHERS
            : regex
                .exec(obj.nameEn)[0]
                .replace('-', '_')
                .toUpperCase();

          return prev;
        }, {});
      });

    this.localStore
      .pipe(select(selectOrderRequestListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.localStore
      .pipe(
        select(selectOrderFixAssetRequest),
        filter(value => Boolean(value && value.result))
      )
      .subscribe((value: OrderFixAssetCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          this.alertSuccessModal(this.getSubmitMessage(value.isSave));
        } else {
          if (value.result.errorResponse.code === '03005') {
            this.orderRequest.deliveryStatus = 'error';
          } else {
            this.alertErrorModal(value.result.errorResponse);
          }
        }
      });

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.data.mode)) {
      this.store.dispatch(new OrderFixAssetRequestByIdRequestAction({ orderId: this.data.requestId }));
    }
    this.orderRequestView$.pipe(filter(value => Boolean(value))).subscribe(value => {
      this.orderRequest.deliveryDetails = value.deliveryDetails;

      this.orderRequest.deliveryStatus = this.getDeliveryDetailsStatus(
        value.deliveryDetails && value.deliveryDetails.deliveryDetailsByType
      );
    });

    this.localStore
      .pipe(
        select(selectAllShelfFixAssetDetails),
        filter(response => Boolean(response))
      )
      .subscribe(selected => {
        this.selectedShelf = selected;
      });

    this.localStore.pipe(select(selectShelfOrderUpdated)).subscribe(skipUpdated => {
      this.shelfSkipUpdated = skipUpdated;
    });

    this.localStore.pipe(select(selectOrderUpdated)).subscribe(skipUpdated => {
      this.specialSkipUpdated = skipUpdated;
    });

    this.localStore.pipe(select(selectOrderStoreUseUpdated)).subscribe(skipUpdated => {
      this.storeUseSkipUpdated = skipUpdated;
    });
    this.auditLogs$ = this.localStore.pipe(select(selectOrderRequestHistory));

    this.setOrderRequestValue();
    this.initialSelectShelfFixAssetItemOrder();
    this.initialSelectSpecialItemOrder();
    this.initialSelectStoreUseItemOrderStoreUse();
  }

  createShelfFixAssetForm(shelfItem: ShelfFixAssetOrderItem) {
    const formShelf: UntypedFormGroup = this.fb.group({
      shelfNo: shelfItem.shelfNo,
      shelfName: shelfItem.shelfName,
      qty: [
        { value: shelfItem.qty, disabled: true },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ],
      items: this.fb.array([]),
      edit: false,
      previousValue: null
    });

    if (shelfItem.items && shelfItem.items.length) {
      const formShelfItems = formShelf.get('items') as UntypedFormArray;

      shelfItem.items.forEach(item => {
        formShelfItems.push(this.createShelfFixAssetItemsForm(item));
      });
    } else {
      formShelf.setValidators(this.setValidator(RequestProductErrorEnum.UNAVAILABLE_SHELF));
      formShelf.setParent(this.baseShelfOrderFormArray);
      formShelf.updateValueAndValidity();
    }

    return formShelf;
  }

  createShelfFixAssetItemsForm(item: FixAssetProductOrderItem): UntypedFormGroup {
    return this.fb.group({
      itemNo: item.itemNo,
      productName: item.productName,
      productDisplayName: item.productDisplayName,
      articleNo: item.articleNo,
      barcode: item.barcode,
      productType: item.productType,
      segment: item.segment,
      unit: item.unit,
      unitFactor: item.unitFactor,
      unitPrice: item.unitPrice || item.wholesalePrice,
      wholesalePrice: item.wholesalePrice,
      wholesalePricePerUnit: item.wholesalePricePerUnit,
      onShelfQty: item.quantity || item.onShelfQty,
      qty: [{ value: item.qty, disabled: this.data.mode === RequestPageModesEnum.REQUEST_VIEW }, Validators.required],
      vatAmount: item.vatAmount,
      amountExcVat: item.amountExcVat,
      wholesalePriceExcVat: item.wholesalePriceExcVat,
      deliveryMethod: item.deliveryMethod,
      totalVat:
        item.totalVat ||
        getPriceFormat(round(item.vatAmount.amount * item.qty, 2), item.vatAmount && item.vatAmount.currency),
      amount: item.amount,
      vat: item.vat
    });
  }

  createItemForm(item: ProductOrderItem): UntypedFormGroup {
    return this.fb.group({
      productName: item.productName,
      productDisplayName: item.productDisplayName,
      articleNo: item.articleNo,
      barcode: item.barcode,
      productType: item.productType,
      segment: item.segment,
      unit: item.unit,
      unitFactor: item.unitFactor,
      unitPrice: item.unitPrice || item.wholesalePrice,
      wholesalePrice: item.wholesalePrice,
      wholesalePricePerUnit: item.wholesalePricePerUnit,
      qty: [
        { value: item.qty, disabled: this.data.mode === RequestPageModesEnum.REQUEST_VIEW },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ],
      vatAmount: item.vatAmount,
      amountExcVat: item.amountExcVat,
      wholesalePriceExcVat: item.wholesalePriceExcVat,
      deliveryMethod: item.deliveryMethod,
      supplierCode: item.supplierCode,
      supplierName: item.supplierName,
      totalVat:
        item.totalVat ||
        getPriceFormat(round(item.vatAmount.amount * item.qty, 2), item.vatAmount && item.vatAmount.currency),
      amount: item.amount,
      allowToDelete: item.allowToDelete,
      vat: item.vat
    });
  }

  setOrderRequestValue() {
    this.orderRequestView$
      .pipe(
        filter(value => Boolean(value)),
        tap(value => {
          this.requestNo = value.requestNo;
          this.status = value.status || NewRequestStatusEnum.DRAFT;
          this.orderRequest.orderType = value.orderType || this.data.orderType;
          this.orderRequest.orderSubType = value.orderSubType || this.data.orderSubType;
          this.orderRequest.requestedDate = value.requestedDate;
          this.orderRequest.amountExcVat = value.amountExcVat || getPriceFormat();
          this.orderRequest.vatAmount = value.vatAmount || getPriceFormat();
          this.orderRequest.amount = value.amount || getPriceFormat();
          this.orderRequest.store = value.store;

          this.data.warehouseCode = value.warehouseCode || this.data.warehouseCode;
          this.data.warehouseDisplayName = value.warehouseDisplayName || this.data.warehouseDisplayName;

          if (value.shelfFixAssets && value.shelfFixAssets.length > 0) {
            this.store.dispatch(
              new ShelfSelectFixAssetOrderAddAllItem({ itemList: value.shelfFixAssets, skipUpdated: true })
            );

            this.selectedShelf = value.shelfFixAssets.map(item => {
              return { shelfNo: item.shelfNo, qty: item.qty };
            });
            this.store.dispatch(new ShelfFixAssetDetailsRequestAction(this.selectedShelf));
          }

          if (value.specialItems && value.specialItems.length > 0) {
            this.store.dispatch(
              new OrderSelectItemOrderAddAllItem({ itemList: value.specialItems, skipUpdated: true })
            );
          }

          if (value.storeUseItems && value.storeUseItems.length > 0) {
            this.store.dispatch(
              new OrderStoreUseSelectItemOrderAddAllItem({ itemList: value.storeUseItems, skipUpdated: true })
            );
          }

          if (this.data.mode === RequestPageModesEnum.REQUEST_CREATE) {
            this.store.dispatch(
              new OrderFixAssetRequestSaveDeliveryDetailsAction({
                storeOpenDate: value.store.openDate,
                deliveryDetailsByType: null
              })
            );
          }
        }),
        take(1)
      )
      .subscribe();
  }

  onSave() {
    this.saved = true;

    if (this.editingShelf.length > 0) {
      if (this.navigateToError(this.baseShelfOrderFormArray, this.shelfPaging) > -1) {
        this.baseShelfOrderFormArray.controls.forEach(shelf => {
          if (shelf.invalid && !this.isShelfError(shelf) && !this.isInvalidShelf(shelf)) {
            const shelfNo = shelf.get('shelfNo').value;
            this.expandRow(shelfNo);
          }
        });
      }

      this.showModalError('Please confirm shelf quantity.');

      return;
    }

    this.store.dispatch(new OrderFixAssetCreateSaveRequestAction(this.prepareRequestData()));
  }

  onSubmit() {
    this.submitted = true;
    this.isLoading = true;

    this.clearSearchText();

    this.baseShelfOrderFormArray.updateValueAndValidity();
    this.baseSpecialFormArray.updateValueAndValidity();
    this.baseStoreUseFormArray.updateValueAndValidity();

    const errorMessage = this.getFormGroupErrorMessage();

    if (errorMessage) {
      this.showModalError(errorMessage);
      this.isLoading = false;
      return;
    }

    if (this.orderRequest.deliveryStatus === 'error') {
      this.showAlertModal('Delivery details is required or delivery date is invalid.');
      this.isLoading = false;
      return;
    }

    if (
      this.baseShelfOrderFormArray.valid &&
      this.baseSpecialFormArray.valid &&
      this.baseStoreUseFormArray.valid &&
      this.orderRequest.deliveryStatus !== 'error'
    ) {
      this.handleConfirm();
    } else {
      this.validateError();
    }
  }

  itemValidator(itemError: ErrorFixAssetResult) {
    if ([NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status)) {
      if (itemError.shelfItems && itemError.shelfItems.length) {
        itemError.shelfItems.forEach(shelf => {
          const currentShelf = this.baseShelfOrderFormArray.at(shelf.no - 1);
          const shelfNo = currentShelf.get('shelfNo').value;

          if (shelf.errors && shelf.errors.length) {
            this.collapseRow(shelfNo);

            this.validateShelfForm(
              this.baseShelfOrderFormArray,
              this.baseShelfOrderFormArray.at(shelf.no - 1),
              shelf.errors[0]
            );
          } else {
            this.baseShelfOrderFormArray.at(shelf.no - 1).clearValidators();
            this.baseShelfOrderFormArray.at(shelf.no - 1).updateValueAndValidity();
          }

          if (!shelf.errors && shelf.items && shelf.items.length) {
            const items = currentShelf.get('items') as UntypedFormArray;

            let errorCount = 0;
            shelf.items.forEach(item => {
              if (item.errors && item.errors.length) {
                this.validateShelfItemForm(this.baseShelfOrderFormArray, items.at(item.no - 1), item.errors[0]);
                errorCount++;
              } else {
                items.at(item.no - 1).clearValidators();
                items.at(item.no - 1).updateValueAndValidity();
              }
            });

            if (items.length === errorCount) {
              this.collapseRow(shelfNo);

              currentShelf.setValidators(this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ALL_INVALID));
              currentShelf.setParent(this.baseShelfOrderFormArray);
              currentShelf.updateValueAndValidity();
            } else {
              this.expandRow(shelfNo);

              currentShelf.clearValidators();
              currentShelf.setValidators([
                Validators.required,
                this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)
              ]);
            }
          }
        });

        this.baseShelfOrderFormArray.updateValueAndValidity();
      }

      if (itemError.items && itemError.items.length) {
        const items = itemError.items;
        items.forEach(item => {
          if (item.errors && item.errors.length) {
            this.validateForm(this.baseSpecialFormArray, this.baseSpecialFormArray.at(item.no - 1), item.errors[0]);
          } else {
            this.baseSpecialFormArray.at(item.no - 1).clearValidators();
            this.baseSpecialFormArray.at(item.no - 1).updateValueAndValidity();
          }
        });

        this.baseSpecialFormArray.updateValueAndValidity();
      }
    } else if (this.status === NewRequestStatusEnum.ASSIGN_STORE_EQUIPMENT) {
      if (itemError.items && itemError.items.length) {
        const items = itemError.items;
        items.forEach(item => {
          if (item.errors && item.errors.length) {
            this.validateForm(this.baseStoreUseFormArray, this.baseStoreUseFormArray.at(item.no - 1), item.errors[0]);
          } else {
            this.baseStoreUseFormArray.at(item.no - 1).clearValidators();
            this.baseStoreUseFormArray.at(item.no - 1).updateValueAndValidity();
          }
        });

        this.baseStoreUseFormArray.updateValueAndValidity();
      }
    }

    if (
      this.baseShelfOrderFormArray.invalid ||
      this.baseSpecialFormArray.invalid ||
      this.baseStoreUseFormArray.invalid
    ) {
      this.validateError();
    }
  }

  validateForm(parentForm: UntypedFormArray, formItem: AbstractControl, error: ErrorResult) {
    formItem.setValidators(this.setSubmitValidator(error));
    formItem.get('productName').setValidators(this.setSubmitValidator(error));
    formItem.get('qty').disable();
    formItem.setParent(parentForm);
    formItem.updateValueAndValidity();
  }

  validateShelfForm(parentForm: UntypedFormArray, formItem: AbstractControl, error: ErrorResult) {
    formItem.setValidators(this.setSubmitValidator(error));
    formItem.setParent(parentForm);
    formItem.updateValueAndValidity();
  }

  validateShelfItemForm(parentForm: UntypedFormArray, formItem: AbstractControl, error: ErrorResult) {
    formItem.setValidators(this.setSubmitValidator(error));
    formItem.get('productName').setValidators(this.setSubmitValidator(error));
    formItem.get('qty').enable();
    formItem.setParent(parentForm);
    formItem.updateValueAndValidity();
  }

  setSubmitValidator(error: ErrorResult): ValidatorFn {
    return (): { [key: string]: any } | null => {
      return { isSubmitError: error.message, field: error.field };
    };
  }

  validateError() {
    if ([NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status)) {
      this.selectedTab = ShelfFixAssetTabs.FIX_ASSET;

      if (this.navigateToError(this.baseShelfOrderFormArray, this.shelfPaging) > -1) {
        this.baseShelfOrderFormArray.controls.forEach(shelf => {
          if (shelf.invalid && !this.isShelfError(shelf) && !this.isInvalidShelf(shelf)) {
            const shelfNo = shelf.get('shelfNo').value;
            this.expandRow(shelfNo);
          }
        });
      }

      this.navigateToError(this.baseSpecialFormArray, this.specialPaging);
    } else if (this.status === NewRequestStatusEnum.ASSIGN_STORE_EQUIPMENT) {
      this.selectedTab = ShelfFixAssetTabs.STORE_USE;

      this.navigateToError(this.baseStoreUseFormArray, this.storeUsePaging);
    }

    this.isLoading = false;
  }

  navigateToError(formArray: UntypedFormArray, paging: UiPaginationComponent) {
    if (formArray.controls && formArray.controls.length) {
      const invalidIndex = formArray.controls.findIndex(item => item.invalid);
      if (invalidIndex > -1) {
        paging.navigateToErrorIndex(invalidIndex);

        return invalidIndex;
      }
    }

    return -1;
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector('.error-background');

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth'
    });
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  getFormGroupErrorMessage(): string | undefined {
    if ([NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status)) {
      const formGroupShelf = this.orderRequestForm.get('shelfOrderItems') as UntypedFormArray;
      this.selectedTab = ShelfFixAssetTabs.FIX_ASSET;

      if (!formGroupShelf.controls.length) {
        this.orderRequestForm.setErrors({ requiredItem: true });
        return 'Please select at least one shelf fix asset before submit.';
      }

      if (this.editingShelf.length > 0) {
        return 'Please confirm shelf quantity.';
      }
    } else if (this.status === NewRequestStatusEnum.ASSIGN_STORE_EQUIPMENT) {
      const formGroupStoreUse = this.orderRequestForm.get('storeUseOrderItems') as UntypedFormArray;
      this.selectedTab = ShelfFixAssetTabs.STORE_USE;

      if (!formGroupStoreUse.controls.length) {
        this.orderRequestForm.setErrors({ requiredItem: true });

        return 'Please select at least one item before submit.';
      }
    }
  }

  prepareRequestData(): OrderFixAssetRequestViewResponse {
    const formData = this.orderRequestForm.getRawValue();
    let template = this.orderRequest;
    template.status = this.status;
    template.orderType = this.data.orderType;
    template.warehouseCode = this.data.warehouseCode;
    template.orderFlow = this.data.orderFlow || null;
    template.crossDockSupplier = this.data.crossDockSupplier || null;
    this.orderRequestView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe((value: OrderFixAssetRequestViewResponse) => {
        template = {
          ...template,
          id: value.id,
          version: value.version,
          requestNo: value.requestNo,
          store: value.store,
          deliveryDetails: value.deliveryDetails
        };
      });

    const shelfItems = formData.shelfOrderItems;
    shelfItems.forEach(shelf => {
      delete shelf.edit;
      delete shelf.previousValue;
    });

    template.shelfFixAssets = shelfItems;
    template.specialItems = formData.specialOrderItems;
    template.storeUseItems = formData.storeUseOrderItems;

    return template;
  }

  openSelectFixAssetShelf() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        SelectShelfFixAssetModalComponent,
        {
          title: 'Select Fix Asset'
        },
        false
      )
    };

    this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
  }

  openSelectSpecialItem() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        SelectItemModalComponent,
        {
          title: 'Add Special Fix Asset',
          orderType: OrderTypeEnum.SPECIAL_REQUEST,
          storeNo: this.storeNo,
          mode: OrderingMethodEnum.FIRST_LOT_ORDER
        },
        false
      )
    };

    this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    return;
  }

  openSelectStoreUseItem() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        SelectStoreUseModalComponent,
        {
          title: 'Select IT Equipment/Store Use',
          orderType: OrderTypeEnum.SPECIAL_REQUEST,
          requestNo: this.requestNo
        },
        false
      )
    };

    this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    return;
  }

  onCancel() {
    if (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      (this.isDataUpdated || this.orderRequestForm.touched)
    ) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  showHistory() {
    this.store.dispatch(new OrderRequestHistoryRequestAction({ orderId: this.data.requestId }));
    const initialState = {
      title: 'History',
      historyHeader: `Request No.: ${this.requestNo}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.ORDER_REQUEST,
      auditLogs$: this.auditLogs$
    };

    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new OrderRequestHistoryResponseAction({ auditLogs: null }));
    });
  }

  deleteRequest() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?',
        okText: 'Yes, delete',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.orderRequestService
            .deleteByRequestId({ orderId: this.data.requestId })
            .pipe(untilComponentDestroyed(this))
            .subscribe({
              next: () => {
                this.alertSuccessModal('The request has been deleted.');
              },
              error: error => {
                this.alertErrorModal(error.error);
              }
            });
        }

        if (confirmModalRef.content.action) {
          confirmModalRef.content.action.unsubscribe();
        }
      });
  }

  cancelRequest() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${this.requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.orderFixAssetRequestService
            .approveRequest({
              requestNo: this.requestNo,
              status: ApproveStatusEnum.CANCELLED,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe({
              next: () => {
                this.alertSuccessModal('The request has been cancelled.');
              },
              error: error => {
                this.alertErrorModal(error.error);
              }
            });
        }

        if (confirmModalRef.content.action) {
          confirmModalRef.content.action.unsubscribe();
        }
      });
  }

  doApproveRejectRequest(isApprove: boolean) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: isApprove ? 'Are you sure you want to "Approve"?' : 'Are you sure you want to "Reject"?',
        label: 'Comment',
        okText: isApprove ? 'Approve' : 'Reject',
        okClass: isApprove ? 'btn btn-special-approve' : 'btn btn-special-reject',
        isRequiredConfirmMessage: !isApprove
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.localStore
            .pipe(
              select(selectOrderFixAssetApproveRejectStatus),
              filter(isApproveRejectSuccess => isApproveRejectSuccess)
            )
            .subscribe(() => {
              this.alertApproveRejectModalSuccess(
                {
                  result: {
                    response: 'success',
                    errorResponse: null
                  }
                },
                isApprove
              );
            });
          this.store.dispatch(
            new OrderFixAssetApproveRequestAction({
              requestNo: this.requestNo,
              status: isApprove ? ApproveStatusEnum.APPROVED : ApproveStatusEnum.REJECTED,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }

        if (confirmModalRef.content.action) {
          confirmModalRef.content.action.unsubscribe();
        }
      });
  }

  handleConfirm() {
    this.isLoading = false;

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new OrderFixAssetCreateSubmitRequestAction(this.prepareRequestData()));
        }
      });
  }

  alertApproveRejectModalSuccess(resp, isApprove) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: isApprove ? 'The request has been approved.' : 'The request has been rejected.'
      },
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK || result === ModalButtonResponseEnum.CANCEL) {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: resp });
      }
      if (alertModal.content.action) {
        alertModal.content.action.unsubscribe();
      }
    });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState,
      keyboard: false,
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide();
        if (this.status === NewRequestStatusEnum.DRAFT) {
          this.listSearchCriteria = {
            ...this.listSearchCriteria,
            page: 0
          };
        }
      }
    });
  }

  alertErrorModal(errorResponse: ErrorOrderRequestResponse) {
    let minOrder: Money;
    let initialState: { title: string; message: any };

    if (errorResponse.code === '03002') {
      const error = errorResponse.message.split(':');

      if (error.length > 1) {
        const minOrderObj = error[1].split(' ');

        minOrder = {
          amount: minOrderObj[1] ? Number(minOrderObj[1]) : 0,
          currency: minOrderObj[0] ? minOrderObj[0] : 'THB'
        };
      }

      initialState = {
        title: 'Failed',
        message: this.translate.instant(errorResponse.translateKey, {
          context: errorResponse.message,
          amount: (minOrder && numeral(minOrder.amount).format(this.numberFormat)) || 0,
          currency: (minOrder && minOrder.currency) || 'THB'
        })
      };
    } else if (['03030', '05008'].includes(errorResponse.code)) {
      initialState = {
        title: 'Failed',
        message: errorResponse.message
      };
    } else if (errorResponse.code === '03010') {
      const errorMessage =
        errorResponse.result && errorResponse.result.errors && errorResponse.result.errors.length > 0
          ? errorResponse.result.errors[0].message
          : 'Some item has invalid data. Please remove item from the list.';

      initialState = {
        title: 'Failed',
        message: errorMessage
      };

      if (errorResponse.result) {
        this.itemValidator(errorResponse.result);
      }
    } else {
      initialState = {
        title: 'Failed',
        message: this.translate.instant(errorResponse.translateKey, {
          context: errorResponse.message
        })
      };
    }

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  onTriggerEdit() {
    this.data.title = 'Edit Order Request';
    this.data.mode = RequestPageModesEnum.REQUEST_EDIT;

    this.orderRequest.deliveryStatus = this.getDeliveryDetailsStatus(
      this.orderRequest.deliveryDetails && this.orderRequest.deliveryDetails.deliveryDetailsByType
    );

    this.clearSearchText();

    if ([NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status)) {
      this.orderRequestForm.get('shelfOrderItems').enable();
      this.baseShelfOrderFormArray.controls.forEach(shelf => {
        shelf.get('qty').disable();
      });

      this.orderRequestForm.get('specialOrderItems').enable();
      this.getButton();
    } else if (this.status === NewRequestStatusEnum.ASSIGN_STORE_EQUIPMENT) {
      this.orderRequestForm.get('storeUseOrderItems').enable();
      this.getButton();
    }
  }

  getSubmitMessage(isSave): string | undefined {
    if (isSave) {
      return 'The request has been saved.';
    } else if (this.status === NewRequestStatusEnum.DRAFT) {
      return 'The request has been sent to approver.';
    } else if (this.status === NewRequestStatusEnum.AWAITING_APPROVAL) {
      return 'The request has been updated.';
    } else if (this.status === NewRequestStatusEnum.ASSIGN_STORE_EQUIPMENT) {
      return 'The order has been created.';
    }
  }

  search() {
    const formValue = this.searchForm.value;
    const searchTerm = formValue && formValue.searchCriteria && formValue.searchCriteria.toLowerCase();
    const result = !searchTerm
      ? this.baseSpecialFormArray.controls
      : this.baseSpecialFormArray.controls.filter(
          item =>
            item
              .get('articleNo')
              .value.toLowerCase()
              .includes(searchTerm) ||
            item
              .get('barcode')
              .value.toLowerCase()
              .includes(searchTerm) ||
            item
              .get('productName')
              .value.toLowerCase()
              .includes(searchTerm)
        );

    const resultStoreUse = !searchTerm
      ? this.baseStoreUseFormArray.controls
      : this.baseStoreUseFormArray.controls.filter(
          item =>
            item
              .get('articleNo')
              .value.toLowerCase()
              .includes(searchTerm) ||
            item
              .get('barcode')
              .value.toLowerCase()
              .includes(searchTerm) ||
            item
              .get('productName')
              .value.toLowerCase()
              .includes(searchTerm)
        );

    this.resetFilteredFormArrayTo(result);
    this.resetFilteredStoreUseFormArrayTo(resultStoreUse);
    this.resetFilteredShelfOrderFormArrayTo(this.baseShelfOrderFormArray.controls);
  }

  setFirstPage() {
    this.currentShelfPage = 1;
    this.currentSpecialPage = 1;
    this.currentStoreUsePage = 1;
  }

  onSubmitSearch() {
    this.setFirstPage();
    this.search();
  }

  clearSearchText() {
    this.searchForm.controls['searchCriteria'].reset();
    this.setFirstPage();
    this.search();
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.setFirstPage();
      this.search();
    }
  }

  initialSelectShelfFixAssetItemOrder() {
    this.localStore
      .pipe(select(selectAllShelfSelectFixAssetOrder), untilComponentDestroyed(this))
      .subscribe(shelfFixAssetOrderItems => {
        const shelfNoList = this.baseShelfOrderFormArray.controls.map(control => control.get('shelfNo').value);
        const addShelfOrderItems = shelfFixAssetOrderItems.filter(
          item => (item.isAddItem || item.isAddItem === undefined) && shelfNoList.indexOf(item.shelfNo) === -1
        );

        if (addShelfOrderItems.length > 0) {
          this.currentShelfPage = 1;
          this.searchForm.controls['searchCriteria'].reset();

          this.baseShelfOrderFormArray.clear();

          addShelfOrderItems.forEach(item => {
            item = {
              ...item,
              isAddItem: false
            };

            this.baseShelfOrderFormArray.push(this.createShelfFixAssetForm(item));
          });
        }

        if (this.isSkipSetFilteredForm) {
          this.isSkipSetFilteredForm = false;
        } else if (this.searchForm.controls['searchCriteria'].value) {
          this.search();
        } else {
          this.resetFilteredShelfOrderFormArrayTo(this.baseShelfOrderFormArray.controls);
        }

        this.getSegmentList();

        if (!this.shelfSkipUpdated && shelfFixAssetOrderItems.length > 0) {
          this.calculateTotalAmount();
        }
      });
  }

  initialSelectSpecialItemOrder() {
    this.localStore
      .pipe(select(selectAllOrderSelectItemOrder), untilComponentDestroyed(this))
      .subscribe(specialOrderItems => {
        const hasNewItem = specialOrderItems.some(item => item.isAddItem);
        const articleNoList = this.baseSpecialFormArray.controls.map(control => control.get('articleNo').value);
        const addProductOrderItems = specialOrderItems.filter(item => item.isAddItem || item.isAddItem === undefined);

        addProductOrderItems
          .filter(item => articleNoList.length > 0 && articleNoList.indexOf(item.articleNo) >= 0)
          .forEach(item => {
            const ctrlIndex = articleNoList.indexOf(item.articleNo);
            const selectedItem = this.baseSpecialFormArray.controls[ctrlIndex];
            item = {
              ...item,
              isAddItem: false
            };
            item.unitPrice = item.wholesalePrice;
            selectedItem.patchValue(item);
          });

        addProductOrderItems
          .filter(item => articleNoList.indexOf(item.articleNo) === -1)
          .forEach(item => {
            item = {
              ...item,
              isAddItem: false
            };
            this.baseSpecialFormArray.push(this.createItemForm(item));
          });

        this.baseSpecialFormArray.controls.sort(this.sortList);

        if (hasNewItem) {
          this.searchForm.controls['searchCriteria'].reset();
        }

        if (this.isSkipSetFilteredForm) {
          this.isSkipSetFilteredForm = false;
        } else if (this.searchForm.controls['searchCriteria'].value) {
          this.search();
        } else {
          this.resetFilteredFormArrayTo(this.baseSpecialFormArray.controls);
        }

        this.getSegmentList();

        if (!this.specialSkipUpdated && specialOrderItems.length > 0) {
          this.calculateTotalAmount();
        }

        this.isLoading = false;
      });
  }

  initialSelectStoreUseItemOrderStoreUse() {
    this.localStore
      .pipe(select(selectAllOrderStoreUseSelectItemOrder), untilComponentDestroyed(this))
      .subscribe(storeUseOrderItems => {
        const hasNewItem = storeUseOrderItems.some(item => item.isAddItem || item.isAddItem === undefined);
        const articleNoList = this.baseStoreUseFormArray.controls.map(control => control.get('articleNo').value);
        const addProductOrderItems = storeUseOrderItems.filter(
          item => item.isAddItem || item.isAddItem === undefined
        );

        addProductOrderItems
          .filter(item => articleNoList.length > 0 && articleNoList.indexOf(item.articleNo) >= 0)
          .forEach(item => {
            const ctrlIndex = articleNoList.indexOf(item.articleNo);
            const selectedItem = this.baseStoreUseFormArray.controls[ctrlIndex];

            item = {
              ...item,
              isAddItem: false
            };
            item.unitPrice = item.wholesalePrice;
            selectedItem.patchValue(item);
          });

        addProductOrderItems
          .filter(item => articleNoList.indexOf(item.articleNo) === -1)
          .forEach(item => {
            item = {
              ...item,
              isAddItem: false
            };
            this.baseStoreUseFormArray.push(this.createItemForm(item));
          });

        this.baseStoreUseFormArray.controls.sort(this.sortList);

        if (hasNewItem) {
          this.searchForm.controls['searchCriteria'].reset();
        }

        if (this.isSkipSetFilteredForm) {
          this.isSkipSetFilteredForm = false;
        } else if (this.searchForm.controls['searchCriteria'].value) {
          this.search();
        } else {
          this.resetFilteredStoreUseFormArrayTo(this.baseStoreUseFormArray.controls);
        }

        this.getSegmentList();

        if (!this.storeUseSkipUpdated && storeUseOrderItems.length > 0) {
          this.calculateTotalAmount();
        }

        this.isLoading = false;
      });
  }

  updateValueStoreUseChangesEvent(data, item) {
    const control = this.baseStoreUseFormArray.controls.find(ctrl => ctrl.get('articleNo').value === item.articleNo);

    if (!control) {
      return;
    }

    const amountExcVat = round(control.get('wholesalePriceExcVat').value.amount, 2) * data || 0;
    const totalVat = round(control.get('vatAmount').value.amount * data, 2) || 0;
    const amount = round(amountExcVat + totalVat, 2) || 0;

    this.isSkipSetFilteredForm = true;

    control.get('amountExcVat').patchValue({
      amount: amountExcVat,
      currency: control.get('amountExcVat').value.currency
    });
    control.get('totalVat').patchValue({ amount: totalVat, currency: control.get('vatAmount').value.currency });
    control.get('amount').patchValue({ amount, currency: control.get('amount').value.currency });
    control.updateValueAndValidity();

    control.setParent(this.baseStoreUseFormArray);

    this.store.dispatch(new OrderStoreUseSelectItemOrderUpdateItem(control.value));
    this.calculateTotalAmount();
  }

  updateValueChangesEvent(data, item) {
    const control = this.baseSpecialFormArray.controls.find(ctrl => ctrl.get('articleNo').value === item.articleNo);

    if (!control) {
      return;
    }

    const amountExcVat = round(control.get('wholesalePriceExcVat').value.amount, 2) * data || 0;
    const totalVat = round(control.get('vatAmount').value.amount * data, 2) || 0;
    const amount = round(amountExcVat + totalVat, 2) || 0;

    this.isSkipSetFilteredForm = true;

    control.get('amountExcVat').patchValue({
      amount: amountExcVat,
      currency: control.get('amountExcVat').value.currency
    });
    control.get('totalVat').patchValue({ amount: totalVat, currency: control.get('vatAmount').value.currency });
    control.get('amount').patchValue({ amount, currency: control.get('amount').value.currency });
    control.updateValueAndValidity();

    control.setParent(this.baseSpecialFormArray);

    this.store.dispatch(new OrderSelectItemOrderUpdateItem(control.value));
    this.calculateTotalAmount();
  }

  updateShelfItemValueChangesEvent(data, item: AbstractControl, shelfItem: AbstractControl) {
    const amountExcVat = round(item.get('wholesalePriceExcVat').value.amount, 2) * data || 0;
    const totalVat = round(item.get('vatAmount').value.amount * data, 2) || 0;
    const amount = round(amountExcVat + totalVat, 2) || 0;

    this.isSkipSetFilteredForm = true;

    item.get('amountExcVat').patchValue({
      amount: amountExcVat,
      currency: item.get('amountExcVat').value.currency
    });
    item.get('totalVat').patchValue({ amount: totalVat, currency: item.get('vatAmount').value.currency });
    item.get('amount').patchValue({ amount, currency: item.get('amount').value.currency });

    if (data === 0) {
      item.clearValidators();
      item.get('productName').clearValidators();
      item.setValidators([Validators.required]);
    }

    item.updateValueAndValidity();
    item.setParent(this.baseShelfOrderFormArray);

    if (shelfItem) {
      const shelfItems = shelfItem.get('items') as UntypedFormArray;
      const itemsIsNotZero = shelfItems.controls.find(x => x.get('qty').value !== 0);
      const itemsIsNotValid = shelfItems.controls.find(x => x.invalid);

      if (!itemsIsNotZero) {
        shelfItem.setValidators(this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ALL_ZERO));
      } else {
        if (shelfItem.errors && shelfItem.errors.isAllZero) {
          shelfItem.clearValidators();
          shelfItem.setValidators([Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]);
        }
      }

      if (!itemsIsNotValid) {
        shelfItem.get('items').updateValueAndValidity();
      }

      shelfItem.setParent(this.baseShelfOrderFormArray);
      shelfItem.updateValueAndValidity();

      const shelfItemRawValue: ShelfFixAssetOrderItem = shelfItem.value;
      this.store.dispatch(new ShelfSelectFixAssetOrderUpdateItem(shelfItemRawValue));
    }

    this.calculateTotalAmount();
  }

  calculateTotalAmount() {
    let excVatAmt = 0;
    let totalVatAmt = 0;
    let incVatAmt = 0;

    this.baseShelfOrderFormArray.controls.forEach(shelfItem => {
      const items = shelfItem.get('items') as UntypedFormArray;

      items.controls.forEach(item => {
        const amountExcVat = item.get('amountExcVat').value.amount || 0;
        const totalVat = item.get('totalVat').value.amount || 0;
        const amount = item.get('amount').value.amount || 0;

        excVatAmt = round(excVatAmt + amountExcVat, 2);
        totalVatAmt = totalVatAmt + totalVat;
        incVatAmt = round(incVatAmt + amount, 2);
      });
    });

    this.baseSpecialFormArray.controls.forEach(itemForm => {
      const amountExcVat = itemForm.get('amountExcVat').value.amount || 0;
      const totalVat = itemForm.get('totalVat').value.amount || 0;
      const amount = itemForm.get('amount').value.amount || 0;

      excVatAmt = round(excVatAmt + amountExcVat, 2);
      totalVatAmt = totalVatAmt + totalVat;
      incVatAmt = round(incVatAmt + amount, 2);
    });

    this.baseStoreUseFormArray.controls.forEach(itemForm => {
      const amountExcVat = itemForm.get('amountExcVat').value.amount || 0;
      const totalVat = itemForm.get('totalVat').value.amount || 0;
      const amount = itemForm.get('amount').value.amount || 0;

      excVatAmt = round(excVatAmt + amountExcVat, 2);
      totalVatAmt = totalVatAmt + totalVat;
      incVatAmt = round(incVatAmt + amount, 2);
    });

    this.orderRequest = {
      ...this.orderRequest,
      amountExcVat: {
        currency: this.orderRequest.amountExcVat.currency,
        amount: excVatAmt
      },
      vatAmount: {
        currency: this.orderRequest.vatAmount.currency,
        amount: round(totalVatAmt, 2)
      },
      amount: {
        currency: this.orderRequest.amount.currency,
        amount: incVatAmt
      }
    };
    this.isDataUpdated = true;
  }

  editShelfItem(shelfItem: AbstractControl) {
    const shelfNo = shelfItem.get('shelfNo').value;
    const shelfQty = shelfItem.get('qty').value;

    this.expandRow(shelfNo);

    shelfItem.get('qty').enable();
    shelfItem.get('edit').setValue(true);
    shelfItem.get('previousValue').setValue(shelfQty);

    const items = shelfItem.get('items') as UntypedFormArray;
    items.controls.forEach(item => {
      item.get('qty').disable();
    });

    this.editingShelf.push(shelfNo);
  }

  confirmEditShelfItem(shelfItem: AbstractControl) {
    const shelfNo = shelfItem.get('shelfNo').value;
    const shelfQty = shelfItem.get('qty').value;

    if (!shelfQty || Number(shelfQty) === 0) {
      return;
    }

    const items = shelfItem.get('items') as UntypedFormArray;
    items.controls.forEach(item => {
      const onShelfQTY = item.get('onShelfQty').value;

      item.get('qty').setValue(shelfQty * onShelfQTY);
      item.get('qty').enable();

      this.updateShelfItemValueChangesEvent(shelfQty * onShelfQTY, item, shelfItem);
    });

    const editShelfIndex = this.editingShelf.indexOf(shelfNo);
    if (editShelfIndex > -1) {
      this.editingShelf.splice(editShelfIndex, 1);
    }

    const shelfRawValue: ShelfFixAssetOrderItem = shelfItem.value;

    shelfItem.get('edit').setValue(false);
    shelfItem.get('previousValue').setValue(null);
    shelfItem.clearValidators();
    shelfItem.setValidators([Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]);
    shelfItem.get('qty').disable();
    shelfItem.updateValueAndValidity();

    // // Update Shelf Fix Asset Details
    const shelfIndex = this.selectedShelf.findIndex(item => item.shelfNo === shelfNo);
    if (shelfIndex > -1) {
      this.selectedShelf[shelfIndex] = { ...this.selectedShelf[shelfIndex], qty: shelfQty };
    }
    this.store.dispatch(new ShelfFixAssetDetailsRequestAction(this.selectedShelf));

    this.store.dispatch(new ShelfSelectFixAssetOrderUpdateItem(shelfRawValue));
  }

  cancelEditShelfItem(shelfItem: AbstractControl) {
    const shelfNo = shelfItem.get('shelfNo').value;
    const previousValue = shelfItem.get('previousValue').value;

    shelfItem.get('qty').disable();
    shelfItem.get('edit').setValue(false);
    shelfItem.get('qty').setValue(previousValue);

    const items = shelfItem.get('items') as UntypedFormArray;
    items.controls.forEach(item => {
      item.get('qty').enable();
    });

    const editShelfIndex = this.editingShelf.indexOf(shelfNo);
    if (editShelfIndex > -1) {
      this.editingShelf.splice(editShelfIndex, 1);
    }
  }

  toggleExpandShelfItems(isExpand: boolean) {
    if (isExpand) {
      this.baseShelfOrderFormArray.controls.forEach(shelfItem => {
        const shelfNo = shelfItem.get('shelfNo').value;
        const isError = this.isShelfError(shelfItem) || this.isInvalidShelf(shelfItem);
        const index = this.expandShelf.indexOf(shelfNo);
        if (index === -1 && !isError) {
          this.expandShelf.push(shelfNo);
        }
      });
    } else {
      this.expandShelf = [];
    }
  }

  checkDeliveryDetailsDeletion() {
    if (
      this.baseSpecialFormArray.value.length === 0 &&
      this.baseShelfOrderFormArray.value.length === 0 &&
      this.baseStoreUseFormArray.value.length === 0
    ) {
      this.store.dispatch(
        new OrderFixAssetRequestSaveDeliveryDetailsAction({
          storeOpenDate: this.orderRequest.deliveryDetails.storeOpenDate,
          deliveryDetailsByType: null
        })
      );
    }
  }

  deleteShelfItem(shelfItem: AbstractControl, itemIndex: number) {
    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: 'Are you sure you want to delete shelf?'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          if (itemIndex > -1) {
            const shelfNo = shelfItem.get('shelfNo').value;

            // Delete in selected shelf
            const isExist = this.selectedShelf.find(shelf => shelf.shelfNo === shelfNo);
            const index = this.selectedShelf.indexOf(isExist);
            if (index > -1) {
              this.selectedShelf.splice(index, 1);
              this.collapseRow(shelfNo);
            }

            this.baseShelfOrderFormArray.removeAt(itemIndex);

            this.checkDeliveryDetailsDeletion();

            this.store.dispatch(new ShelfSelectFixAssetOrderRemoveItem(shelfNo));
            this.store.dispatch(new ShelfFixAssetDetailsRequestAction(this.selectedShelf));
          }

          this.calculateTotalAmount();
        }

        if (confirmModalRef.content.action) {
          confirmModalRef.content.action.unsubscribe();
        }
      });
  }

  deleteItemWithoutConfirmation(index: number) {
    this.deleteProductItem(index);
    this.calculateTotalAmount();
  }

  deleteItemStoreUseWithoutConfirmation(index: number) {
    this.deleteItemStoreUse(index);
    this.calculateTotalAmount();
  }

  deleteProductItem(index: number) {
    if (index > -1) {
      const articleNo = this.baseSpecialFormArray.at(index).get('articleNo').value;

      this.baseSpecialFormArray.removeAt(index);
      this.store.dispatch(new OrderSelectItemOrderRemoveItem(articleNo));

      this.checkDeliveryDetailsDeletion();
    }
  }

  deleteProductItemStoreUse(index: number) {
    if (index > -1) {
      const articleNo = this.baseStoreUseFormArray.at(index).get('articleNo').value;

      this.baseStoreUseFormArray.removeAt(index);
      this.store.dispatch(new OrderStoreUseSelectItemOrderRemoveItem(articleNo));
      this.checkDeliveryDetailsDeletion();
    }
  }

  deleteItem(index?: number) {
    const deleteAllItem = index === undefined;
    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: !deleteAllItem
        ? 'Are you sure you want to delete this item?'
        : 'Are you sure you want to delete all items?'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          if (!deleteAllItem) {
            this.deleteProductItem(index);
          } else {
            this.setFirstPage();
            this.baseSpecialFormArray.clear();

            this.store.dispatch(new OrderSelectItemOrderReset());

            this.checkDeliveryDetailsDeletion();
          }

          this.calculateTotalAmount();
        }

        if (confirmModalRef.content.action) {
          confirmModalRef.content.action.unsubscribe();
        }
      });
  }

  deleteItemStoreUse(index?: number) {
    const deleteAllItem = index === undefined;
    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: !deleteAllItem
        ? 'Are you sure you want to delete this item?'
        : 'Are you sure you want to delete all items?'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          if (!deleteAllItem) {
            this.deleteProductItemStoreUse(index);
          } else {
            this.setFirstPage();
            this.baseStoreUseFormArray.clear();

            this.store.dispatch(new OrderStoreUseSelectItemOrderReset());

            this.checkDeliveryDetailsDeletion();
          }

          this.calculateTotalAmount();
        }

        if (confirmModalRef.content.action) {
          confirmModalRef.content.action.unsubscribe();
        }
      });
  }

  openDeliveryDetails() {
    if (!this.baseShelfOrderFormArray.controls.length) {
      this.showAlertModal('Please select at least one shelf.');
      return;
    }

    this.store.dispatch(
      new OrderFixAssetRequestSaveDeliveryDetailsAction({
        storeOpenDate: this.orderRequest.deliveryDetails.storeOpenDate,
        deliveryDetailsByType: this.orderRequest.deliveryDetails.deliveryDetailsByType
      })
    );

    const initialState = {
      orderType: this.data.orderType,
      mode: this.data.mode,
      ...{
        segmentList: this.segmentList,
        orderSubType: this.orderRequest.orderSubType
      }
    };

    const modal = this.modalService.show(DeliveryDetailsComponent, {
      class: 'modal-lg',
      initialState
    });

    const updateDeliveryDetails = modal.content.updateDeliveryDetails
      .pipe(untilComponentDestroyed(this))
      .subscribe(updated => {
        this.isDataUpdated = updated;

        if (updateDeliveryDetails) {
          updateDeliveryDetails.unsubscribe();
        }
      });
  }

  showAlertModal(message: string) {
    this.showModalMessage('Alert', message);
  }

  showModalError(message: string) {
    this.showModalMessage('Failed', message);
  }

  showModalMessage(title: string, message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title,
        message
      }
    });
  }

  getSegmentList() {
    const segments = [];
    if (this.baseShelfOrderFormArray.length || this.baseSpecialFormArray.length) {
      segments.push(ProductTypeEnum.FIX_ASSET);
    }

    if (this.baseStoreUseFormArray.length) {
      segments.push(ProductTypeEnum.STORE_USE);
    }

    this.segmentList = this.deliveryDetailsCondition.filter(v => segments.includes(v.segment));

    this.updateDeliveryDetails();
  }

  updateDeliveryDetails() {
    if (this.orderRequest.deliveryDetails) {
      const deliveryDetailsByType = { ...this.orderRequest.deliveryDetails.deliveryDetailsByType };
      const newDeliveryDetailsByType = {};

      for (const segment of this.segmentList) {
        for (const prop of segment.properties) {
          newDeliveryDetailsByType[prop] = {
            ...deliveryDetailsByType[prop],
            deliveryDate:
              (deliveryDetailsByType[prop] && deliveryDetailsByType[prop].deliveryDate) ||
              this.getDefaultDate(segment.noOfDateBefore)
          };
        }
      }

      this.orderRequest.deliveryDetails = {
        ...this.orderRequest.deliveryDetails,
        deliveryDetailsByType: newDeliveryDetailsByType
      };
      this.orderRequest.deliveryStatus = this.getDeliveryDetailsStatus(newDeliveryDetailsByType);

      this.store.dispatch(
        new OrderFixAssetRequestSaveDeliveryDetailsAction({
          storeOpenDate: this.orderRequest.deliveryDetails.storeOpenDate,
          deliveryDetailsByType: this.orderRequest.deliveryDetails.deliveryDetailsByType
        })
      );
    }
  }

  getDefaultDate(noOfDateBefore: number) {
    if (!this.orderRequest.deliveryDetails.storeOpenDate) {
      return;
    }

    const defaultDate = new Date(this.orderRequest.deliveryDetails.storeOpenDate);
    defaultDate.setDate(defaultDate.getDate() - noOfDateBefore);
    return defaultDate;
  }

  getDeliveryDetailsStatus(data: DeliveryDetailsByTypeDictionary<DeliveryDetail>) {
    const dataLength = data && Object.keys(data).length;
    const today = moment().startOf('day');
    const storeOpenDate =
      this.orderRequest.deliveryDetails && moment(this.orderRequest.deliveryDetails.storeOpenDate).startOf('day');
    let isDataInvalid = false;

    for (const segment in data) {
      if (data.hasOwnProperty(segment)) {
        const deliveryDate = data[segment].deliveryDate && moment(data[segment].deliveryDate).startOf('day');

        isDataInvalid =
          !deliveryDate ||
          (this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
            (deliveryDate < today || deliveryDate >= storeOpenDate));
        if (isDataInvalid) {
          break;
        }
      }
    }

    if (!isDataInvalid) {
      isDataInvalid = dataLength !== this.segmentList.length * 2;
    }

    return dataLength && !isDataInvalid ? 'success' : 'error';
  }

  setValidator(errorMessage: string, value = ''): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      switch (errorMessage) {
        case RequestProductErrorEnum.NOT_ALLOW_ZERO:
          if (control.value !== null) {
            return control.value === 0 ? { isZero: true } : null;
          }
          return null;
        case RequestProductErrorEnum.NOT_ALLOW_ALL_ZERO:
          return { isAllZero: true };
        case RequestProductErrorEnum.NOT_ALLOW_ALL_INVALID:
          return { isAllInvalid: true };
        case RequestProductErrorEnum.UNAVAILABLE_SHELF:
          return { isUnavailableShelf: true };
        case RequestProductErrorEnum.MAXIMUM_PURCHASING_PRICE:
          if (value && value.length > 0) {
            return { maximumPurchasingPrice: { hasError: true, price: value } };
          }
          return null;
        default:
          return null;
      }
    };
  }

  resetIsZeroValidator(): ValidatorFn {
    return (): { [key: string]: any } | null => {
      return { isAllZero: true };
    };
  }

  exportFileName(titleSheet = ''): string {
    if (this.status === NewRequestStatusEnum.DRAFT && this.selectedTab === ShelfFixAssetTabs.FIX_ASSET) {
      return `${this.storeCode} ${titleSheet} ${this.timeToExport}.xlsx`;
    }
    return `${this.requestNo} ${titleSheet} ${this.timeToExport}.xlsx`;
  }

  onExportProductItemFixAsset() {
    const selected = this.data.requestId;
    this.callServiceExportItem(selected, this.exportFileName('(Fix Asset & Store Use)'));
  }

  onExportItem() {
    const selected = this.data.requestId;
    this.callServiceExportItem(selected, this.exportFileName());
  }

  private callServiceExportItem(selected: any, filename: any) {
    this.orderRequestExportService.exportItemFixAsset(selected).subscribe({
      next: response => {
        const blob = new Blob([response]);
        saveAs(blob, filename);
      },
      error: errorResponse => {
        this.errorExport = errorResponse.error.message || this.translate.instant(errorResponse.error.translateKey);
        this.showModalError(this.errorExport);
      }
    });
  }

  getStoreIcon(storeType: StoreType) {
    return storeType === StoreType.STORE_MODEL ? 'icon-storemodel' : 'icon-partnermodel';
  }

  toggleRowExpand(shelfNo: string, isError: boolean) {
    if (isError) {
      return;
    }

    const index = this.expandShelf.indexOf(shelfNo);
    if (index > -1) {
      this.expandShelf.splice(index, 1);
    } else {
      this.expandShelf.push(shelfNo);
    }
  }

  isExpandRow(shelfNo: string) {
    return this.expandShelf.indexOf(shelfNo) > -1 ? true : false;
  }

  expandRow(shelfNo: string) {
    const index = this.expandShelf.indexOf(shelfNo);
    if (index === -1) {
      this.expandShelf.push(shelfNo);
    }
  }

  collapseRow(shelfNo: string) {
    const index = this.expandShelf.indexOf(shelfNo);
    if (index > -1) {
      this.expandShelf.splice(index, 1);
    }
  }

  isShelfError(shelf: AbstractControl) {
    return (
      shelf.errors && ((shelf.errors.isSubmitError && shelf.errors.field === 'shelf') || shelf.errors.isAllInvalid)
    );
  }

  isInvalidShelf(shelf: AbstractControl) {
    const shelfItems: Array<any> = shelf.get('items').value || [];
    return shelfItems.length === 0 || (shelf.errors && shelf.errors.isInvalidShelf);
  }

  onClickImport() {
    if (this.formControls && this.formControls.length > 0) {
      this.alertConfirmUploadModal();
    } else {
      this.showOrderStoreUseImportModal();
    }
  }

  alertConfirmUploadModal(
    initialState = {
      title: 'Confirm',
      message: 'Are you sure you want to import new file? All existing data will be lost.',
      okText: 'OK'
    }
  ) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.showOrderStoreUseImportModal();
        }
      });
  }

  showOrderStoreUseImportModal() {
    this.orderModalUpload = this.modalService.show(OrderModalUploadComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        uploadUrl: this.importBarcodeUrl,
        orderSubTypeEnum: OrderSubTypeEnum.FIX_ASSET_AND_STORE_USE,
        titleLabel: 'IT Equipment & Store Use'
      }
    });

    this.orderModalUpload.content.submitUpload.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.onLoadOrderItemDetail(result.file);
    });
  }

  onLoadOrderItemDetail(value: any) {
    if (Array.isArray(value.validations) && value.validations.length > 0) {
      this.importResult = {
        title: `${value.validations.length} Errors Found.`,
        table: value.validations,
        message: null
      };
    } else if (Array.isArray(value.tdCatalogs) && value.tdCatalogs.length > 0) {
      this.baseStoreUseFormArray.clear();
      this.store.dispatch(new OrderStoreUseSelectItemOrderReset());

      value.tdCatalogs.forEach(item => {
        this.addStoreUseItem(item);
      });
      this.resetFilteredStoreUseFormArrayTo(this.baseStoreUseFormArray.controls);

      this.importResult = {
        title: 'Success',
        table: null,
        message: `The data have been imported.`
      };
    }

    this.importResultModel.show();
  }

  addStoreUseItem(productOrderItem) {
    productOrderItem.isAddItem = true;
    if (productOrderItem.qty) {
      let previousQty = 0;
      const selectPreviousQty$ = this.localStore
        .pipe(select(getOrderStoreUseSelectItemOrderById(productOrderItem.articleNo)))
        .pipe(filter(value => Boolean(value)))
        .subscribe(value => {
          previousQty = value.qty;
        });

      productOrderItem.qty = Number(previousQty) + Number(productOrderItem.qty);

      const itemPrice = new ItemPrice<ProductOrderItem>(productOrderItem, 'qty');
      const amountExcVat = itemPrice.getTotalFrom('wholesalePriceExcVat');
      const vatAmount = itemPrice.getTotalFrom('vatAmount');
      const amount = getPriceFormat(amountExcVat.amount + vatAmount.amount);

      productOrderItem.amountExcVat = amountExcVat;
      productOrderItem.totalVat = vatAmount;
      productOrderItem.amount = amount;

      // If allowToDelete is undefined, set to true
      if (typeof productOrderItem.allowToDelete === 'undefined') {
        productOrderItem.allowToDelete = true;
      }

      this.store.dispatch(new OrderStoreUseSelectItemOrderAddItem(productOrderItem));
      selectPreviousQty$.unsubscribe();
    }
  }

  onSelectTabs(tab: ShelfFixAssetTabs) {
    this.selectedTab = tab;
  }

  getColorStatus(status: string): string {
    return status && status.toLowerCase();
  }

  sortList(a: AbstractControl, b: AbstractControl) {
    return localCompareEnglish(a.value.productName, b.value.productName);
  }

  isShowFixAssetButton() {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      [NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status) &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_fl_ast_m'])
    );
  }

  isShowStoreUseButton() {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      this.status === NewRequestStatusEnum.ASSIGN_STORE_EQUIPMENT &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_st_equip_m'])
    );
  }

  hasSavePermission() {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      this.status === NewRequestStatusEnum.DRAFT &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_fl_ast_m'])
    );
  }

  hasSubmitPermission() {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_st_equip_m', 'or_fl_ast_m'])
    );
  }

  hasEditPermission() {
    let permission: boolean;

    if ([NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status)) {
      permission = this.authGuardService.checkPermission(['or_fl_ast_m']);
    } else if (this.status === NewRequestStatusEnum.ASSIGN_STORE_EQUIPMENT) {
      permission = this.authGuardService.checkPermission(['or_st_equip_m']);
    }

    return this.data.mode === RequestPageModesEnum.REQUEST_VIEW && permission;
  }

  hasCancelPermission() {
    return (
      [NewRequestStatusEnum.AWAITING_APPROVAL, NewRequestStatusEnum.ASSIGN_STORE_EQUIPMENT].includes(this.status) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_fl_ast_m'])
    );
  }

  hasDeletePermission() {
    return (
      this.status === NewRequestStatusEnum.DRAFT &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_fl_ast_m'])
    );
  }

  hasApprovePermission() {
    return (
      this.status === NewRequestStatusEnum.AWAITING_APPROVAL &&
      this.authGuardService.checkPermission(['or_fl_ast_app'])
    );
  }

  hasAtLeastOnePermission() {
    return (
      this.hasSavePermission() ||
      this.hasEditPermission() ||
      this.hasApprovePermission() ||
      this.hasSubmitPermission()
    );
  }

  resetFilteredFormArrayTo(controls: Array<AbstractControl>) {
    this.filteredSpecialFormArray.clear();
    controls.forEach(ctrl => this.filteredSpecialFormArray.push(ctrl));
  }

  resetFilteredStoreUseFormArrayTo(controls: Array<AbstractControl>) {
    this.filteredStoreUseFormArray.clear();
    controls.forEach(ctrl => this.filteredStoreUseFormArray.push(ctrl));
  }

  resetFilteredShelfOrderFormArrayTo(controls: Array<AbstractControl>) {
    this.filteredShelfOrderFormArray.clear();
    controls.forEach(ctrl => this.filteredShelfOrderFormArray.push(ctrl));
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportOrderRequest.timeFormat);
  }

  get isViewMode() {
    return this.data.mode === RequestPageModesEnum.REQUEST_VIEW;
  }

  get shelfFixAssetTabs() {
    return ShelfFixAssetTabs;
  }

  get filteredSpecialFormArray() {
    return this.filteredFormGroup.get('specialOrderItems') as UntypedFormArray;
  }

  get baseSpecialFormArray() {
    return this.orderRequestForm.get('specialOrderItems') as UntypedFormArray;
  }

  get filteredShelfOrderFormArray() {
    return this.filteredShelfOrderFormGroup.get('shelfOrderItems') as UntypedFormArray;
  }

  get baseShelfOrderFormArray() {
    return this.orderRequestForm.get('shelfOrderItems') as UntypedFormArray;
  }

  get filteredStoreUseFormArray() {
    return this.filterStoreUseFormGroup.get('storeUseOrderItems') as UntypedFormArray;
  }

  get baseStoreUseFormArray() {
    return this.orderRequestForm.get('storeUseOrderItems') as UntypedFormArray;
  }

  get formControls(): AbstractControl[] {
    return (this.orderRequestForm.get('storeUseOrderItems') as UntypedFormArray).controls;
  }
}
