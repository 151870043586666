<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-marketing"></em>
    <h4>Promotion</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between">
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="statusList"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            class="select-status"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Effective Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>From</label>
                        <input
                          id="effectiveDateFromAdvanced"
                          data-id="effectiveDateFromAdvanced"
                          formControlName="effectiveDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="effectiveDateFromAdvanced" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>To</label>
                        <input
                          id="effectiveDateToAdvanced"
                          data-id="effectiveDateToAdvanced"
                          formControlName="effectiveDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="effectiveDateToAdvanced" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Expire Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>From</label>
                        <input
                          id="expireDateFromAdvanced"
                          data-id="expireDateFromAdvanced"
                          formControlName="expireDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="expireMaxDate"
                          (bsValueChange)="onChangeExpireDateFrom($event)"
                          readonly
                        />
                        <label for="expireDateFromAdvanced" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>To</label>
                        <input
                          id="expireDateToAdvanced"
                          data-id="expireDateToAdvanced"
                          formControlName="expireDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="expireMinDate"
                          (bsValueChange)="onChangeExpireDateTo($event)"
                          readonly
                        />
                        <label for="expireDateToAdvanced" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Valid Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>From</label>
                        <input
                          id="validDateFromAdvanced"
                          data-id="validDateFromAdvanced"
                          formControlName="validDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="this.validMaxDate"
                          (bsValueChange)="onChangeValidDateFrom($event)"
                          readonly
                        />
                        <label for="validDateFromAdvanced" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>To</label>
                        <input
                          id="validDateToAdvanced"
                          data-id="validDateToAdvanced"
                          formControlName="validDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="this.validMinDate"
                          (bsValueChange)="onChangeValidDateTo($event)"
                          readonly
                        />
                        <label for="validDateToAdvanced" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Promotion Type
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="promotionTypeList"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="promotionType"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Created Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>From</label>
                        <input
                          id="createdDateFromAdvanced"
                          data-id="createdDateFromAdvanced"
                          formControlName="createdDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="createdMaxDate"
                          (bsValueChange)="onChangeCreatedDateFrom($event)"
                          readonly
                        />
                        <label for="createdDateFromAdvanced" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>To</label>
                        <input
                          id="createdDateToAdvanced"
                          data-id="createdDateToAdvanced"
                          formControlName="createdDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="createdMinDate"
                          (bsValueChange)="onChangeCreatedDateTo($event)"
                          readonly
                        />
                        <label for="createdDateToAdvanced" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="
      createdDateStringTag ||
      effectiveDateStringTag ||
      expireDateStringTag ||
      validDateStringTag ||
      criteriaObject.promotionType
    "
  >
    <div class="search-criteria" *ngIf="effectiveDateStringTag">
      <a class="remove-criteria" (click)="clearFilterEffectiveDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ effectiveDateStringTag }}: {{ effectiveDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="expireDateStringTag">
      <a class="remove-criteria" (click)="clearFilterExpireDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ expireDateStringTag }}: {{ expireDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="validDateStringTag">
      <a class="remove-criteria" (click)="clearFilterValidDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ validDateStringTag }}: {{ validDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="criteriaObject.promotionType">
      <a class="remove-criteria" (click)="clearFilterPromotionType()">
        <em class="icon-close"></em>
      </a>
      <span>Promotion Type: {{ promotionTypeTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="createdDateStringTag">
      <a class="remove-criteria" (click)="clearFilterCreateDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ createdDateStringTag }}: {{ createdDateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async)?.totalElements > 0">
    <div class="td-card" *ngFor="let item$ of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView({ promotionCode: item$.promotionCode })">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">{{ item$.promotionName }}</label>
              <span [ngClass]="getColorStatus(item$.status)" class="card-status promotion-status ml-auto">
                {{ item$.status.replace('_', ' ') | titlecase }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <span class="result-property">Promotion Code:</span
              ><span class="result-value">{{ item$.promotionCode }}</span>
            </div>
            <div class="col-lg-5 col-md-6">
              <span class="result-property">Theme:</span><span class="result-value">{{ item$.theme }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <span class="result-property">Effective Date:</span
              ><span class="result-value">{{ item$.effectiveDate | dateDisplay: environment.dateTimeDisplay }}</span>
            </div>
            <div class="col-lg-5 col-md-6">
              <span class="result-property"> Expire Date:</span
              ><span class="result-value">{{ item$.expireDate | dateDisplay: environment.dateTimeDisplay }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <span class="result-property"> Pro. Type:</span
              ><span class="result-value">{{ item$.promotionTypeDisplayName }}</span>
            </div>
            <div class="col-lg-5 col-md-6">
              <span class="result-property"> Created Date:</span
              ><span class="result-value">{{
                item$.createdDate | amFromUtc | amLocal | dateDisplay: dateFormat
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom right"
            [adaptivePosition]="false"
            [outsideClick]="true"
          >
            <em class="icon-more"></em
          ></a>
          <ng-template #moreTemplate>
            <div class="form-row">
              <button
                type="button"
                class="btn btn-link"
                *ngIf="showCancelButton(item$.status) && hasEditPromotionPermission"
                (click)="goToCancel(item$)"
              >
                <em class="icon-forbidden"></em>Cancel Promotion
              </button>
              <button type="button" class="btn btn-link" (click)="showHistory(item$)">
                <em class="icon-history"></em>History
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
  <app-no-search-result [resultList$]="listState$" [hasSearchCriteria]="hasSearchCriteria"></app-no-search-result>
</div>
