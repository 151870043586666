import { Action } from '@ngrx/store';

import { ErrorResponse } from '../../models';
import {
  ResponseAssignmentListResponse,
  ResponseAssignmentListSearchCriteria,
  TaskAssignmentResponseModel
} from '../../models/task-assignment-response.model';

export enum ResponseAssignmentActionType {
  RESPONSE_ASSIGNMENT_LIST_REQUEST = '[ResponseAssignment] Response List Request',
  RESPONSE_ASSIGNMENT_LIST_RESPONSE = '[ResponseAssignment] Response  List Response',
  RESPONSE_ASSIGNMENT_LIST_ERROR = '[ResponseAssignment] Response  List Error',

  RESPONSE_ASSIGNMENT_GET_BY_ID_REQUEST = '[ResponseAssignment] Get Task by Id Request',
  RESPONSE_ASSIGNMENT_GET_BY_ID_RESPONSE = '[ResponseAssignment] Get Task by Id Response',
  RESPONSE_ASSIGNMENT_GET_BY_ID_ERROR = '[ResponseAssignment] Get Task by Id Error',
  RESPONSE_ASSIGNMENT_GET_BY_ID_RESET = '[ResponseAssignment] Get Task by Id Reset'
}

export class ResponseAssignmentListRequestAction implements Action {
  readonly type = ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_LIST_REQUEST;

  constructor(public payload: ResponseAssignmentListSearchCriteria) {}
}

export class ResponseAssignmentListResponseAction implements Action {
  readonly type = ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_LIST_RESPONSE;

  constructor(public payload: ResponseAssignmentListResponse) {}
}

export class ResponseAssignmentListErrorAction implements Action {
  readonly type = ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResponseAssignmentByIdRequestAction implements Action {
  readonly type = ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_GET_BY_ID_REQUEST;

  constructor(public payload: { responseNo: string }) {}
}

export class ResponseAssignmentByIdResponseAction implements Action {
  readonly type = ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_GET_BY_ID_RESPONSE;

  constructor(public payload: TaskAssignmentResponseModel) {}
}

export class ResponseAssignmentByIdErrorAction implements Action {
  readonly type = ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_GET_BY_ID_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResponseAssignmentByIdResetAction implements Action {
  readonly type = ResponseAssignmentActionType.RESPONSE_ASSIGNMENT_GET_BY_ID_RESET;
}

export type ResponseAssignmentActions =
  | ResponseAssignmentListRequestAction
  | ResponseAssignmentListResponseAction
  | ResponseAssignmentListErrorAction
  | ResponseAssignmentByIdRequestAction
  | ResponseAssignmentByIdResponseAction
  | ResponseAssignmentByIdErrorAction
  | ResponseAssignmentByIdResetAction;
