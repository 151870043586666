<div class="admin-form mt-2">
  <h6>Price</h6>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-end">
      <div class="filter-search-box flex-item form-group ml-md-2">
        <div class="search-box position-relative">
          <input
            type="text"
            class="searchCriteria form-control"
            placeholder="Enter keyword search"
            formControlName="searchCriteria"
            (keyup.backspace)="clearLastKeyUpSearchText($event)"
            maxlength="50"
          />
          <em
            class="icon-close-mini"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
          <button class="btn-search" type="submit">
            <em class="icon-search"></em>
          </button>
        </div>
      </div>
      <div class="filter-dropdown flex-item form-group ml-md-2">
        <ng-select
          [searchable]="false"
          [clearable]="false"
          [items]="classMarkupFilter"
          bindLabel="label"
          bindValue="value"
          formControlName="status"
          (change)="onChangeStatus($event)"
        >
        </ng-select>
      </div>
    </div>
  </form>

  <!--content-->
  <ng-container *ngIf="(totalElements$ | async).totalElements > 0">
    <div class="table-responsive">
      <table id="datatable" class="table table-striped table-bordered table-hover w-100" aria-label="datatable">
        <thead>
          <tr>
            <th class="text-center" scope="col">{{ headerRow[0] }}</th>
            <th class="text-center" scope="col">{{ headerRow[1] }}</th>
            <th class="text-center" scope="col">{{ headerRow[2] }}</th>
            <th class="text-center" scope="col">{{ headerRow[3] }}</th>
            <th class="text-center" scope="col">{{ headerRow[4] }}</th>
            <th class="text-center" scope="col">{{ headerRow[5] }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let classMarkup of classMarkups$ | async; index as i">
            <td>
              {{ classMarkup.classCode | dashDisplay: '(None)' }}
            </td>
            <td>
              {{ classMarkup.className | dashDisplay: '(None)' }}
            </td>

            <td
              class="text-right"
              [ngStyle]="{
                padding: priceForm[classMarkup.id] && priceForm[classMarkup.id].isEdit ? '0' : '',
                'vertical-align': priceForm[classMarkup.id] && priceForm[classMarkup.id].isEdit ? 'middle' : ''
              }"
            >
              <!--input field price markup-->
              <app-numeric-textbox
                *ngIf="priceForm[classMarkup.id] && priceForm[classMarkup.id].isEdit; else markupDisplay"
                [(ngModel)]="priceForm[classMarkup.id].classMarkup"
                [format]="'0.[00]%'"
                [maxValue]="maxValue"
                maxlength="6"
                placeholder="Percent"
                (valueChange)="clearError(classMarkup.id)"
                [ngClass]="{
                  'is-invalid': priceForm[classMarkup.id] && priceForm[classMarkup.id].isError,
                  'td-numeric-textbox': true
                }"
              >
              </app-numeric-textbox>

              <div *ngIf="priceForm[classMarkup.id] && priceForm[classMarkup.id].isError" class="invalid-display">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>

              <ng-template #markupDisplay>
                {{ classMarkup.classMarkup | numberFormatDisplay }}
              </ng-template>
            </td>

            <td class="text-center">
              {{ classMarkup.editDate | amFromUtc | amLocal | dateDisplay }}
            </td>
            <td>
              {{ classMarkup.editBy | dashDisplay }}
            </td>

            <td
              class="text-center"
              [ngStyle]="{
                padding: priceForm[classMarkup.id] && priceForm[classMarkup.id].isEdit ? '0' : '',
                'vertical-align': priceForm[classMarkup.id] && priceForm[classMarkup.id].isEdit ? 'middle' : ''
              }"
            >
              <div *ngIf="priceForm[classMarkup.id] && priceForm[classMarkup.id].isEdit; else actionDisplay">
                <div class="btn-group">
                  <button
                    type="button"
                    id="btn-cancel"
                    class="btn btn-standard mr-2"
                    (click)="cancelEditClassMarkup(classMarkup.id)"
                  >
                    Cancel
                  </button>
                </div>
                <div class="btn-group">
                  <button
                    type="button"
                    id="btn-save"
                    class="btn btn-special-approve"
                    (click)="updateClassMarkup(classMarkup.id)"
                  >
                    Save
                  </button>
                </div>
              </div>

              <ng-template #actionDisplay>
                <a
                  class="mr-1"
                  (click)="editClassMarkup(classMarkup)"
                  *appHasPermission="['price_m_m', 'price_nm_m']"
                >
                  <em class="icon-edit" title="Edit" appTippy [tippyOptions]="{ arrow: true }"></em>
                </a>
                <a class="ml-1" (click)="showHistory(classMarkup)">
                  <em class="icon-history" title="History" appTippy [tippyOptions]="{ arrow: true }"></em>
                </a>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="totalElements$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <ng-container *ngIf="(totalElements$ | async).totalElements === 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>
</div>
