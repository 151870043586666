export enum ErrorCodeEnum {
  DUPLICATE_TAXID_ERROR_CODE = '04001',
  DUPLICATE_STORE_CODE_ERROR_CODE = '04002',
  DUPLICATE_STORE_NAME_ERROR_CODE = '04003',
  DUPLICATE_MOBILE_ERROR_CODE = '04004',
  INVALID_STEP_ERROR_CODE = '04005',
  DUPLICATE_IDCARD_ERROR_CODE = '04006',
  INVALID_CARDID_ERROR_CODE = '04007',
  DUPLICATE_PREORDER_ITEM = '05020',
  DUPLICATE_CATALOG_NO = '05021',
  INVALID_CATALOG = '05023',
  DUPLICATE_FLASH_SALE = '05025'
}
