import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import {
  RewardCatalogList,
  RewardCatalogListSearchCriteria,
  RewardCatalogViewResponse
} from '../../../../shared/models/reward-catalog.model';
import { RewardCatalogActionType, RewardCatalogActions } from './reward-catalog.actions';
export interface RewardCatalogState extends EntityState<RewardCatalogList> {
  isLoading: boolean;
  selected: RewardCatalogViewResponse;
  criteriaObject: RewardCatalogListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<RewardCatalogList> = createEntityAdapter<RewardCatalogList>();

export const initialRewardCatalogResponseState: RewardCatalogState = adapter.getInitialState({
  isLoading: false,
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null
});

export function rewardCatalogReducers(
  state = initialRewardCatalogResponseState,
  action: RewardCatalogActions
): RewardCatalogState {
  switch (action.type) {
    case RewardCatalogActionType.REWARD_CATALOG_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case RewardCatalogActionType.REWARD_CATALOG_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case RewardCatalogActionType.REWARD_CATALOG_GET_BY_REQUEST_NO_RESPONSE:
      return {
        ...state,
        selected: { ...action.payload }
      };
    case RewardCatalogActionType.REWARD_CATALOG_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
