import { createSelector } from '@ngrx/store';

import { ShelfSelectedItems } from '../../models';
import * as fromShelfFixAssetDetailsState from '../reducers/shelf-fix-asset-details.reducers';
import { AppStates } from '../state/app.states';

const selectShelfFixAssetDetailsState = (state: AppStates) => state.shelfFixAssetDetails;

export const selectAllShelfFixAssetDetails = createSelector(
  selectShelfFixAssetDetailsState,
  fromShelfFixAssetDetailsState.selectAll
);

export const selectShelfFixAssetDetails = createSelector(
  selectShelfFixAssetDetailsState,
  (state: fromShelfFixAssetDetailsState.ShelfFixAssetDetailsState) => {
    const selected: ShelfSelectedItems[] = [];
    for (const [_key, value] of Object.entries(state.entities)) {
      selected.push(value);
    }

    return selected;
  }
);
