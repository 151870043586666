import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subject, Subscription, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, startWith, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import {
  PromotionByEnum,
  PromotionItem,
  PromotionRequestPageModes,
  PromotionRequestResponse,
  PromotionSearchCriteria,
  PromotionTheme,
  PromotionTypeEnum
} from '../../../../shared/models';
import {
  promotionBy,
  promotionType,
  timeRangeTwentyFour
} from '../../../../shared/models/list-value/list-key-value.model';
import { SupplierSearch } from '../../../../shared/models/supplier.model';
import { SupplierService } from '../../../../shared/services/supplier.service';
import { PromotionThemeRequestAction } from '../../../../shared/store/actions/promotion.action';
import {
  selectPromotionThemeComing,
  selectRequestPromotionData
} from '../../../../shared/store/selectors/promotion-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import { formatDate, getDateFromString } from '../../../../shared/utils/date-util';
import { HandleThemeComponent } from '../handle-theme/handle-theme.component';

@Component({
  selector: 'app-promotion-details',
  templateUrl: './promotion-details.component.html',
  styleUrls: ['./promotion-details.component.scss']
})
export class PromotionDetailsComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @ViewChild('handleTheme') handleTheme: HandleThemeComponent;

  @Input() saveDraft: boolean;
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() mode: PromotionRequestPageModes;
  public themeForm: UntypedFormGroup;

  private localStore: Observable<any>;
  themeSearchInput$ = new Subject<string>();
  promotionRequestView$: Observable<PromotionRequestResponse>;
  coPromotionItems$: Observable<PromotionItem[]>;
  promotionByList: NgOption[];
  promotionTypeList: NgOption[];
  timeRange: NgOption[];
  listTheme: PromotionTheme[];
  themeSearchLoading = false;
  promotionBySubscription: Subscription;
  promotionTypeSubscription: Subscription;
  filterTheme$: BehaviorSubject<any>;
  criteriaObject: PromotionSearchCriteria;
  supplierList: Observable<SupplierSearch[]>;
  supplierSearchLoading = false;
  supplierSearchInput$ = new Subject<string>();
  selectedThemeId: string;
  themeDefault: PromotionTheme;
  dateFormat = environment.dateFormat;
  dateTimeDisplay = environment.dateTimeDisplay;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly fb: UntypedFormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly logger: NGXLogger,
    protected supplierService: SupplierService
  ) {
    super();
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.promotionByList = promotionBy;
    this.promotionTypeList = promotionType;
    this.timeRange = timeRangeTwentyFour();
    this.createForm();
    this.initialData();
    this.initState();
  }

  get form(): UntypedFormGroup {
    return this.parentForm.get('promotionDetails') as UntypedFormGroup;
  }

  initialData() {
    this.store.dispatch(new PromotionThemeRequestAction({ type: 'effectiveDate' }));

    this.localStore.pipe(select(selectPromotionThemeComing)).subscribe(data => {
      if (data) {
        this.listTheme = data;
        this.addDefaultThemeInList();
      }
    });

    this.criteriaObject = {
      searchCriteria: '',
      page: 0,
      size: 20
    };
    this.loadSupplier('');

    this.promotionBySubscription = this.form.controls['promotionBy'].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(selected => {
        const controls = ['supplier', 'refPromotionCode'];

        controls.forEach(name => {
          this.form.controls[name].setValue(null);
          this.form.controls[name].updateValueAndValidity({ onlySelf: true });

          if (this.canEdit && selected === PromotionByEnum.SUPPLIER) {
            this.form.controls[name].enable();
          } else {
            this.form.controls[name].disable();
          }
        });
      });

    this.promotionTypeSubscription = this.form.controls['promotionType'].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(selected => {
        const forceBuyQtyControl = this.form.controls['forceBuyQty'];

        switch (selected) {
          case PromotionTypeEnum.BUY_GET_ONE_FREE:
          case PromotionTypeEnum.SPECIAL_PRICE:
            forceBuyQtyControl.setValue('1');
            forceBuyQtyControl.updateValueAndValidity({ onlySelf: true });
            forceBuyQtyControl.disable();
            break;
          case PromotionTypeEnum.BUY_MORE_GET_ONE_FREE:
          case PromotionTypeEnum.MORE_SPECIAL_PRICE:
            forceBuyQtyControl.setValue('');
            forceBuyQtyControl.updateValueAndValidity({ onlySelf: true });
            if (this.canEdit) {
              forceBuyQtyControl.enable();
            }
            break;
          case PromotionTypeEnum.BUY_TWO_SAVE_MORE:
            forceBuyQtyControl.setValue('2');
            forceBuyQtyControl.updateValueAndValidity({ onlySelf: true });
            forceBuyQtyControl.disable();
            break;
          default:
            break;
        }
      });
  }

  onChangePromotionType(event: any) {
    this.logger.debug('onChangePromotionType', event);
  }

  initState() {
    if (this.canEditView) {
      this.setPromotionDetailValue();
    }
  }

  setPromotionDetailValue() {
    this.promotionRequestView$ = this.store.pipe(select(selectRequestPromotionData));
    this.promotionRequestView$.pipe(filter(value => Boolean(value))).subscribe(value => {
      let setEffectiveDate = { date: null, time: '' };
      let setExpireDate = { date: null, time: '' };

      if (value.details.effectiveDate) {
        const tempEffectiveDate = formatDate(value.details.effectiveDate, this.dateTimeDisplay).split(' ');

        setEffectiveDate = {
          date: getDateFromString(tempEffectiveDate[0], this.dateFormat),
          time: tempEffectiveDate[1]
        };
      }
      if (value.details.expireDate) {
        const tempExpireDate = formatDate(value.details.expireDate, this.dateTimeDisplay).split(' ');
        setExpireDate = {
          date: getDateFromString(tempExpireDate[0], this.dateFormat),
          time: tempExpireDate[1]
        };
      }

      this.form.patchValue({
        promotionName: value.details.promotionName,
        theme: value.details.theme ? value.details.theme : null,
        promotionBy: value.details.promotionBy,
        supplier: value.details.supplier,
        refPromotionCode: value.details.refPromotionCode,
        promotionType: value.details.promotionType,
        forceBuyQty: value.details.forceBuyQty,
        effectiveDate: setEffectiveDate.date,
        effectiveTime: setEffectiveDate.time,
        expireDate: setExpireDate.date,
        expireTime: setExpireDate.time
      });

      this.form.get('limitPerBill').setValue(value.details.limitPerBill);

      this.themeDefault = {
        ...value.details.theme,
        effectiveDate: value.details.effectiveDate,
        expireDate: value.details.expireDate
      } as PromotionTheme;
      this.addDefaultThemeInList();
    });
    if (this.mode === PromotionRequestPageModes.REQUEST_VIEW) {
      this.form.disable();
    } else {
      this.toggleEditPromotionDetail();
    }
  }

  createForm() {
    const initialNullRequired = [{ initialValue: { value: null, disabled: false }, validator: Validators.required }];
    const initialNullRequiredDisabled = [
      { initialValue: { value: null, disabled: true }, validator: Validators.required }
    ];

    const setForm = {
      promotionName: initialNullRequired,
      theme: [
        {
          initialValue: { value: null, disabled: false },
          validator: {
            validators: [Validators.required, this.notAllowDateOverEffectiveDateValidator]
          }
        }
      ],
      promotionBy: initialNullRequired,
      refPromotionCode: initialNullRequired,
      promotionType: initialNullRequired,
      forceBuyQty: [
        {
          initialValue: { value: null, disabled: false },
          validator: {
            validators: [Validators.required, this.notAllowLessThanExpectValidator(2)],
            updateOn: 'blur'
          }
        }
      ],
      limitPerBill: initialNullRequired,
      effectiveDate: initialNullRequiredDisabled,
      effectiveTime: initialNullRequiredDisabled,
      expireDate: initialNullRequiredDisabled,
      expireTime: initialNullRequiredDisabled,
      supplier: initialNullRequired
    };

    for (const key in setForm) {
      if (setForm[key]) {
        this.form.addControl(key, new UntypedFormControl(setForm[key][0].initialValue, setForm[key][0].validator));
      }
    }
  }

  onChangeTheme($event) {
    const value = $event;
    let effectiveDate = { date: null, time: '00:00' };
    let expireDate = { date: null, time: '23:59' };

    if (value) {
      if (value.effectiveDate) {
        const tempEffectiveDate = formatDate(value.effectiveDate, this.dateTimeDisplay).split(' ');

        effectiveDate = {
          date: getDateFromString(tempEffectiveDate[0], this.dateFormat) || null,
          time: tempEffectiveDate[1] || null
        };
      }
      if (value.expireDate) {
        const tempExpireDate = formatDate(value.expireDate, this.dateTimeDisplay).split(' ');

        expireDate = {
          date: getDateFromString(tempExpireDate[0], this.dateFormat) || null,
          time: tempExpireDate[1] || null
        };
      }
    }

    this.form.controls['effectiveDate'].setValue(effectiveDate.date);
    this.form.controls['effectiveTime'].setValue(effectiveDate.time);
    this.form.controls['expireDate'].setValue(expireDate.date);
    this.form.controls['expireTime'].setValue(expireDate.time);
  }

  toggleEditPromotionDetail() {
    this.form.enable();
    this.form.controls['effectiveDate'].disable();
    this.form.controls['effectiveTime'].disable();
    this.form.controls['expireDate'].disable();
    this.form.controls['expireTime'].disable();

    const promotionByValue = this.form.controls['promotionBy'].value;

    if (promotionByValue === PromotionByEnum.TD) {
      this.form.controls['refPromotionCode'].updateValueAndValidity({ onlySelf: true });
      this.form.controls['refPromotionCode'].disable();
      this.form.controls['supplier'].updateValueAndValidity({ onlySelf: true });
      this.form.controls['supplier'].disable();
    }

    const promotionTypeValue = this.form.controls['promotionType'].value;
    switch (promotionTypeValue) {
      case PromotionTypeEnum.BUY_GET_ONE_FREE:
        this.form.controls['forceBuyQty'].disable();
        break;
      case PromotionTypeEnum.BUY_MORE_GET_ONE_FREE:
        break;
      case PromotionTypeEnum.SPECIAL_PRICE:
        this.form.controls['forceBuyQty'].disable();
        break;
      case PromotionTypeEnum.MORE_SPECIAL_PRICE:
        break;
      case PromotionTypeEnum.BUY_TWO_SAVE_MORE:
        this.form.controls['forceBuyQty'].disable();
        break;
      default:
        break;
    }
  }

  loadSupplier(initialTerm: string) {
    this.supplierList = concat(
      of([]),
      this.supplierSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.supplierSearchLoading = true)),
        switchMap(term =>
          this.supplierService.searchSupplierByName({ searchCriteria: term }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.supplierSearchLoading = false;
            })
          )
        )
      )
    );
  }

  gotoTheme(): void {
    this.store.dispatch(new PromotionThemeRequestAction({ type: 'expiredate' }));
    this.handleTheme.handleTheme.show();
  }

  addDefaultThemeInList(): void {
    if (this.listTheme && this.listTheme.length > 0 && this.themeDefault && this.themeDefault.id) {
      const themeSelected = this.listTheme.find(item => item.id === this.themeDefault.id);

      if (themeSelected === undefined || themeSelected === null) {
        const list = Array.from(this.listTheme);

        list.push(this.themeDefault);

        this.listTheme = list;
        this.form.get('theme').setValue(this.themeDefault);
      }
    } else if (this.themeDefault && this.themeDefault.id) {
      this.listTheme = [this.themeDefault];
      this.form.get('theme').setValue(this.themeDefault);
    }

    this.form.get('theme').updateValueAndValidity({ onlySelf: true });
  }

  notAllowLessThanExpectValidator(expect: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null && control.value !== '') {
        const isPromotionType = [
          PromotionTypeEnum.BUY_MORE_GET_ONE_FREE,
          PromotionTypeEnum.MORE_SPECIAL_PRICE
        ].includes(this.form.get('promotionType').value);
        if (isPromotionType) {
          return control.value < expect ? { isLessThan: true } : null;
        } else {
          return control.value === 0 ? { isZero: true } : null;
        }
      }
      return null;
    };
  }

  get notAllowDateOverEffectiveDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        const today = moment().utcOffset(7);

        if (control.value.effectiveDate) {
          const effectiveDate = moment(control.value.effectiveDate + '+07:00');

          if (today.diff(effectiveDate) > 0) {
            return { isEffectiveUnder: true };
          }
        }

        return null;
      }
      return null;
    };
  }

  get canEdit(): boolean {
    return [PromotionRequestPageModes.REQUEST_CREATE, PromotionRequestPageModes.REQUEST_EDIT].includes(this.mode);
  }
  get canEditView(): boolean {
    return [
      PromotionRequestPageModes.REQUEST_EDIT,
      PromotionRequestPageModes.REQUEST_VIEW,
      PromotionRequestPageModes.VIEW
    ].includes(this.mode);
  }

  ngOnDestroy(): void {
    if (this.promotionBySubscription) {
      this.promotionBySubscription.unsubscribe();
    }

    if (this.promotionTypeSubscription) {
      this.promotionTypeSubscription.unsubscribe();
    }
  }
}
