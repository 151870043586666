import { Theme } from '@shared/services/theme/theme';

import env from './.env';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  assetPath: '/assets/images/td',
  production: false,
  hmr: false,
  metrics: false,
  notifyError: false,
  appName: env.AppName,
  version: env.version + '-localhost',
  commit: env.commit,
  branch: env.branch,
  tier: 'develop',
  serverUrl: 'https://api-dev.tdshop.io',
  storageUrl: 'https://storage.googleapis.com',
  imgUrl: 'https://storage.googleapis.com/product-assortments-dev',
  storeResourcesPath: '/store-resources-dev',
  defaultClientId: 'ADMIN_PORTAL',
  defaultCountry: 'Thailand',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'th'],
  theme: Theme.TD,
  blockSales: true,
  supplierName: 'TD Tawandang',
  prefixCompany: 'TD',
  copyRight: '2019 TD Tawandang Co.,Ltd. All rightS reserved. www.tdshop.io',
  imgLogo: '/assets/images/td-logo.svg',
  imgLogoVertical: '/assets/images/vertical-logo-border.svg',
  dateFormat: 'DD/MM/YYYY',
  monthFormat: 'MM/YYYY',
  dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
  dateTimeNanoSec: 'YYYY-MM-DDTHH:mm:ss.SSS',
  dateTimeDisplay: 'DD/MM/YYYY HH:mm',
  date_time: 'DDMMYYYY_HHmm',
  dateISO8601: 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]',
  uploadConcurrent: 4,
  uploadChunkSize: 10000,
  loadExistingChunkSize: 25000,
  favicon: 'favicon',
  period: {
    short: 1,
    medium: 3,
    long: 6
  },
  buyCoPromotionItem: 3,
  claimWithinDays: 180,
  cookies: {
    maxSizePerCookies: 3500,
    bufferTimePeriod: 1800000,
    domain: 'localhost',
    path: '/',
    accessToken: 'bo-access-token',
    refreshToken: 'bo-refresh-token'
  },
  currencyOptions: null,
  alert: {
    myReportRequest:
      'System generated report Request No. {request_no}.<br/>You can download the file from My Report menu.'
  },
  autoExtendDays: 99,
  fileName: {
    exportDo: {
      prefix: 'Delivery Order Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportCompensate: {
      promotion: 'Promotion Compensate Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportVoucher: {
      prefixReport: 'Voucher Report',
      fileTemplate: 'Voucher Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportMoneyIn: {
      prefix: 'Money In Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportDestroyDocument: {
      prefix: 'Destroy Summary (PDF) Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPr: {
      prefix: 'Purchase Request Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPrSupplier: {
      errorExcel: 'PR Supplier Error',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPo: {
      prefix: 'Purchase Order Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportClaim: {
      prefix: 'Claim Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportReOrder: {
      prefix: 'Receive Order Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    downloadPdf: {
      timeFormat: 'DDMMYYYYHHmm'
    },
    exportStock: {
      prefixLocation: 'Stock by Location Report',
      prefixProduct: 'Stock by Product Report',
      prefixAdjustment: 'Stock Adjustment Report',
      prefixCard: 'Stock Card Report',
      timeFormat: 'DDMMYYYY_HHmm',
      titleStockAdjustmentTemplate: 'Stock Adjustment Template',
      prefixStockAdjustmentFileError: 'Stock Adjustment Error'
    },
    exportOrder: {
      prefix: 'Order Report',
      prefixError: 'Special Order Error',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSalesTransaction: {
      prefixReport: 'Sales Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSalesTransactionConfidential: {
      prefixReport: 'Sales Transaction Report (Confidential)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPreOrderCatalog: {
      fileTemplate: 'Pre Order Catalog Template',
      fileFlashSaleTemplate: 'Flash Sale Template',
      prefixFileError: 'Pre Order Catalog Error',
      prefixFlashSaleFileError: 'Flash Sale Error',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportDeletedBill: {
      prefixReport: 'Deleted Bill Items Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSalesSummary: {
      prefixReport: 'Sales Summary Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportShipment: {
      prefix: 'Shipment Report ',
      prefixError: 'Shipment Error',
      timeFormat: 'DDMMYYYY_HHmm',
      fileTemplate: 'Shipment Template'
    },
    exportMonthlyProfit: {
      prefix: 'Monthly Profit Sharing Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportReceiveOrderExceed: {
      prefix: 'Receive Order Exceed Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStoreAssortment: {
      prefix: 'Store Assortment Report',
      fileSpecialAssortmentTemplate: 'Special Assortment Template',
      fileShelfOptionTemplate: 'Shelf Option Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportShelf: {
      prefix: 'Shelf Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSupplierCouponReconcile: {
      prefix: 'Supplier Coupon Reconcile Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportShelfInventory: {
      prefix: 'Shelf Inventory Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportShelfFixAsset: {
      prefix: 'Shelf Fix Asset Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportInventoryCountTransactionTemp: {
      prefix: 'Inventory Count Transaction Report (with Price)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportInventoryAdjustmentRequest: {
      prefix: 'Inventory Adjustment Request Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportProfitSharing: {
      prefix: 'Profit Sharing Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPromotionCompensate: {
      prefix: 'Promotion Compensate Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportMovingAverage: {
      prefix: 'TD Moving Average Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPromotion: {
      prefix: 'Promotion Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPromotionStoreRequest: {
      prefix: 'Store',
      fileTemplate: 'Store Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPromotionRequest: {
      prefix: 'Promotion Request',
      fileTemplate: 'Promotion Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStore: {
      prefix: 'Store Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStoreOrderSchedule: {
      prefix: 'Store Order Schedule Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStoreConfidential: {
      prefix: 'Store Report (Confidential)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportInventoryCountTransaction: {
      prefix: 'Inventory Count Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportOrderRequest: {
      prefix: 'Order Request',
      timeFormat: 'DDMMYYYY_HHmm',
      fileTemplate: 'Special Order Template'
    },
    exportInventoryVarianceReport: {
      prefix: 'Inventory Variance Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    printStoreAssortmentPdf: {
      prefix: 'Price Tag',
      timeFormat: 'DDMMYYYYHHmm'
    },
    exportRewardTransaction: {
      prefix: 'Reward Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportRewardCatalogReport: {
      prefix: 'Member Reward Catalog Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportRedeemTransaction: {
      prefix: 'Redeem Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportBillPayment: {
      prefix: 'Bill Payment Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportMemberRewardTransactionReport: {
      prefix: 'Member Reward Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportMemberRewardAccountingConfidentialReport: {
      prefix: 'Member Reward Accounting Report (Confidential)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSupplierPriceConfigs: {
      prefix: 'Supplier Price',
      timeFormat: 'DDMMYYYY_HHmm',
      fileTemplate: 'Supplier Price Template'
    },
    printPointStatementPdf: {
      prefix: 'Point Statement',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSupplier: {
      prefix: 'Supplier Report',
      fileTemplate: 'Supplier List Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportRewardRequestConfigs: {
      prefix: 'Reward Request',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportReward: {
      prefix: 'Reward Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStoreConsultant: {
      prefixReport: '',
      prefix: 'Store Consultant Report',
      prefixError: 'Store Consultant Error',
      fileTemplate: 'Store Consultant Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportTaskAssignment: {
      prefixReport: '',
      prefix: 'Store Consultant',
      prefixError: 'Store Error',
      fileTemplate: 'Store Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportApprovalWorkflow: {
      downloadFileTemplate: 'Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportTask: {
      prefix: 'Task Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },

    exportTaskAssignmentResponse: {
      prefix: 'Response Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },

    exportTaskAssignmentSurvey: {
      prefix: 'Survey Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },

    exportDestroyTransaction: {
      prefix: 'Destroy Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportTransferRequestTemplate: {
      prefix: 'Transfer Request Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportTransferOrder: {
      prefix: 'Transfer Order Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportAwaitingFirstLot: {
      prefix: 'Awaiting First Lot Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportItemCondition: {
      prefix: 'Item Discount Template',
      exported: 'Discount',
      errorExcel: 'Discount Error',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportOrderDeliveryScheduleWeeklyTemplate: {
      prefix: 'Order Delivery Schedule Weekly Template',
      exported: 'Order Delivery Schedule Weekly',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportOrderDeliveryScheduleMonthlyTemplate: {
      prefix: 'Order Delivery Schedule Calendar Template',
      exported: 'Order Delivery Schedule Calendar',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPrItem: {
      prefix: 'PR Supplier Template',
      exported: 'PR Supplier',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportTemporaryHold: {
      prefix: 'Temporary Hold Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportOrderProcessingHold: {
      prefix: 'Order Processing (Hold) Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportMemberExcludeItem: {
      prefix: 'Exclude Item Report ',
      fileTemplate: 'Exclude Item Template',
      errorExcel: 'Exclude Item Error ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportNotForSaleItem: {
      prefix: 'Not for Sale Item Report ',
      fileTemplate: 'Not for Sale Item Template',
      errorExcel: 'Not for Sale Item Error ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPointBurnTransactionItem: {
      prefix: 'Point Burn Transaction Report',
      fileTemplate: 'Point Burn Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPointEarnTransactionItem: {
      prefix: 'Point Earn Transaction Report ',
      fileTemplate: 'Point Earn Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    downloadStoreAssortmentShelfDetails: {
      prefix: 'Store Assortment(Shelf)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportUserReport: {
      prefix: 'User Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportLatePaymentNotice: {
      prefixReport: '',
      prefix: '',
      prefixError: '',
      fileTemplate: 'Late Payment Notice Details Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportRewardCatalog: {
      fileTemplate: 'Store Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStoreOrderScheduleTemplate: {
      prefix: 'Store Order Schedule Template',
      exported: 'Store Order Schedule',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPreOrderTransaction: {
      prefix: 'Pre Order Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPreOrderAccounting: {
      prefix: 'Pre Order Accounting Report (Confidential)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportRefund: {
      prefix: 'Refund Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportAwaitingAllocationForGroupOrder: {
      prefix: 'Awaiting Allocation for Group Order Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportGroupOrderUnpickedItem: {
      prefix: 'Group Order Unpicked Item Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportShelfInventoryTemplate: {
      fileTemplate: 'Shelf Inventory Template',
      prefixError: 'Shelf Inventory Error',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSpecialPointTemplate: {
      fileTemplate: 'Special Point Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportHoldAssortmentRequest: {
      fileTemplate: 'Hold Assortment Template'
    },
    exportHoldAssortmentRequestNo: {
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportToteAdjustment: {
      prefix: '',
      fileTemplate: 'Tote Adjustment Template',
      errorExcel: 'Tote Adjustment Error ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    printReturnTotePdf: {
      prefix: 'ใบรับคืนลังพลาสติก',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStoreAssortmentRequest: {
      fileTemplate: 'Store Assortment Template'
    },
    exportStoreAssortmentRequestNo: {
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPreOrderCreditTerm: {
      prefix: 'Special Pre Order Credit Report ',
      fileTemplate: 'Special Pre Order Credit Template',
      errorExcel: 'Special Pre Order Credit Error ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStoreAssortmentRecommendation: {
      prefix: 'Store Assortment Recommendation',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportHoldOrder: {
      fileTemplate: 'Hold Order Template',
      errorExcel: 'Hold Order Error ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportUnholdOrder: {
      fileTemplate: 'Unhold Order Template',
      errorExcel: 'Unhold Order Error ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    productWarehouseMapping: {
      fileTemplate: 'Product Warehouse Mapping',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    productCategoryInventory: {
      fileTemplate: 'Product Category (Inventory)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    productCategoryFixAssetStoreUse: {
      fileTemplate: 'Product Category (Fix Asset & Store Use)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    preOrderCategory: {
      fileTemplate: 'Pre Order Category',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    saleSchedule: {
      fileTemplate: 'Sale Schedule',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    newSaleSchedule: {
      fileTemplate: 'New Sale Schedule',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    sapStoreCodeMapping: {
      fileTemplate: 'SAP Store Code Mapping',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportReturnToWarehouse: {
      fileTemplate: 'Return to Warehouse (Store Terminate) Template',
      errorExcel: 'Return to Warehouse (Store Terminate) Error ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSSPResultReport: {
      fileTemplate: 'SSP Result Report',
      timeFormat: 'DDMMYYYY_HHmm',
      dataFormat: 'DD-MM-YYYY'
    },
    printReceiveOrderPdf: {
      timeFormat: 'DDMMYYYY_HHmm'
    }
  },
  regexp: {
    imageType: '.*.(gif|jpe?g|bmp|png)$'
  },
  services: {
    base: {
      url: '/lorem',
      serviceAppend: '/{param1}/{param2}',
      headers: {
        'x-host': 'api.lorem.ipsum'
      }
    },
    graphql: {
      url: '/graphql',
      headers: {
        'x-host': 'master.api.tdshop.io'
      }
    },
    auth: {
      url: '/oauth/token',
      headers: {
        Authorization: 'Basic QURNSU5fUE9SVEFMOlBAc3N3MHJk',
        'x-host': 'oauth.api.tdshop.io'
      }
    },
    products: {
      url: '/cjproducts',
      select: '/select',
      getCJArticleDetails: '/{articleNo}',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    productMaster: {
      url: '/cjproducts',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    preOrderCatalog: {
      url: '/preorder-catalogs',
      get: '/{preorderNo}',
      cancel: '/cancel',
      getDropDown: '/dropdown',
      importStoreSubmit: '/import/store',
      downloadFileStoreTemplate: '/import/store/templates',
      submit: '/submit',
      exportStore: '/export/store',
      preOrderTransaction: '/preorder-transaction/export',
      downloadFileTemplate: '/import/templates',
      importValidate: '/import/validate',
      importSubmit: '/import/submit',
      export: '/export',
      createFlashSale: '/flash-sale/create',
      cancelFlashSale: '/flash-sale/cancel',
      downloadFileFlashSaleTemplate: '/flash-sale/import/templates',
      importFlashSaleValidate: '/flash-sale/import/validate',
      importFlashSaleSubmit: '/flash-sale/import/submit',
      checkPromotion: '/promotions/validate',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    preOrderRequests: {
      url: '/preorder-requests',
      transactionExport: '/transaction/export',
      accountingExport: '/accounting/export',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    preOrderReport: {
      url: '/preorder-catalogs',
      exportPreOrderCatalog: '/export',
      reportConfidential: '/confidential/export',
      headers: {
        'x-host': 'report.api.tdshop.io'
      }
    },
    preOrderTransactionsReport: {
      url: '/preorder-transactions',
      transaction: '/export',
      transactionsConfidential: '/confidential/export',
      compensate: '/compensate/export',
      headers: {
        'x-host': 'report.api.tdshop.io'
      }
    },
    stores: {
      url: '/stores',
      storesPaging: '/search/store',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    assortment: {
      url: '/tdproducts',
      get: '/assortment',
      productDetail: '/{articleNo}/details',
      catalogs: '/catalogs',
      z8Parameter: '/assortment/z8parameters',
      history: '/history/{productId}',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    barcodes: {
      url: '/barcodes',
      getList: '/list',
      verifyBarcode: '/exists/{barcodeNumber}',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    article: {
      url: '/articles',
      getList: '/list',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    productAssortment: {
      url: '/tdproducts/requests',
      headers: {
        'x-host': 'product.api.tdshop.io'
      },
      uploadUrl: '/tdproducts/images',
      bucketName: '/product-assortments-dev'
    },
    freshLiteCategory: {
      url: '/fresh-lite-categories',
      findByName: '/exists',
      checkFreshLite: '/{code}',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    masterData: {
      url: '/product-select-values',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    priceSetting: {
      url: '/price-settings',
      request: '/classes',
      get: '/classes/{classCode}',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    pricing: {
      url: '/pricing',
      request: '/stackprices',
      get: '/stackprices/{articleNo}',
      delete: '/stackprices/delete',
      history: '/stackprices/history/{articleNo}',
      cjPrices: '/cjprices',
      tdPrices: '/tdprices',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    shelf: {
      url: '/shelves',
      view: '/view/{shelfNo}',
      history: '/history/{shelfNo}',
      submitFirstLot: '/firstLot',
      activate: '/activate/{shelfNo}',
      generateItems: '/productList',
      headers: {
        'x-host': 'product.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    shelfRequest: {
      url: '/shelves-requests',
      delete: '/{requestId}',
      history: '/history/{requestId}',
      save: '/save',
      submit: '/submit',
      view: '/{requestId}',
      importBarcodes: '/importBarcodes',
      importFirstLot: '/importFirstLot',
      exportFirstLot: '/exportFirstLot',
      validate: '/validate/{type}/{value}',
      approve: '/approveRequest',
      export: '/export',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    shelfFixAssetRequest: {
      url: '/shelf-fix-asset-requests',
      delete: '/{requestId}',
      history: '/history/{requestId}',
      save: '/save',
      submit: '/submit',
      view: '/{requestId}',
      importBarcodes: '/importBarcodes',
      importFirstLot: '/importFirstLot',
      exportFirstLot: '/exportFirstLot',
      validate: '/validate/{type}/{value}',
      approve: '/approveRequest',
      export: '/export',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    shelfInventoryRequest: {
      url: '/shelf-inventory-requests',
      delete: '/{requestId}',
      history: '/history/{requestId}',
      save: '/save',
      submit: '/submit',
      view: '/{requestId}',
      importBarcodes: '/importBarcodes',
      importFirstLot: '/importFirstLot',
      exportFirstLot: '/exportFirstLot',
      downloadImportBarCodeTemplate: '/export/template',
      validate: '/validate/{type}/{value}',
      approve: '/approveRequest',
      export: '/export',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    shelfInventory: {
      url: '/shelf-inventories',
      view: '/view/{shelfNo}',
      history: '/history/{shelfNo}',
      submitFirstLot: '/firstLot',
      activate: '/activate/{shelfNo}',
      getShelfList: '/firstLot',
      generateItems: '/details',
      export: '/export',
      headers: {
        'x-host': 'product.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    shelfFixAsset: {
      url: '/shelf-fix-assets',
      view: '/view/{shelfNo}',
      history: '/history/{shelfNo}',
      submitFirstLot: '/firstLot',
      activate: '/activate/{shelfNo}',
      generateItems: '/productList',
      generateFixAssetItems: '/details',
      export: '/export',
      headers: {
        'x-host': 'product.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    merchantSelectValue: {
      url: '/merchant-select-values',
      headers: {
        'x-host': 'merchant.api.tdshop.io'
      }
    },
    merchantsRequest: {
      url: '/merchants-requests',
      validate: '/validate/{type}/{value}',
      save: '/save',
      submit: '/submit',
      fileUrl: '/fileUrl',
      view: '/{merchantRequestId}',
      approveRequest: '/approveRequest',
      delete: '/{merchantRequestId}',
      history: '/history/{merchantRequestId}',
      getMerchantVersion: '/getVersion/{merchantRequestId}',
      getShelfDetails: '/getShelfDetails/{storeCode}',
      getMerchantPending: '/check-pending/{merchantId}',
      headers: {
        'x-host': 'merchant.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    merchant: {
      url: '/merchants',
      viewMerchant: '/view/{merchant}',
      allStore: '/all/stores',
      listStore: '/{merchant}/stores',
      generateUser: '/{merchant}/generateOwner',
      addResetOwnerLog: '/{merchant}/addResetOwnerLog',
      history: '/history/{merchant}',
      getStore: '/{merchant}/stores',
      headers: {
        'x-host': 'merchant.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeGroup: {
      url: '/merchants',
      submit: '/{merchantId}/groups',
      get: '/{merchantId}/groups/{groupId}',
      groupList: '/groups/list',
      headers: {
        'x-host': 'merchant.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeConsultant: {
      url: '/store-consultants',
      get: '/{userId}',
      submit: '/submit',
      downloadFileTemplate: '/import/templates',
      validateFile: '/import/validate',
      importSubmit: '/import/submit',
      export: '/export',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    store: {
      url: '/stores',
      lockSale: '/lock-sale',
      view: '/view/{storeNo}',
      generatePOSRegisCode: '/{storeNo}/generatePos',
      updatePOSRegisCode: '/{storeNo}/updatePos',
      deletePOS: '/{storeNo}/deletePos',
      deactivatePOS: '/{storeNo}/deactivatePos',
      orderSchedule: '/orderSchedule',
      history: '/history/{storeNo}',
      export: '/export',
      exportStoreConfidential: '/confidential/export',
      exportMoneyIn: '/export/moneyIn',
      printStoreAssortmentPdf: '/print/store-pos-price-tag/{storeNo}/{format}/{locale}',
      activate: '/{storeNo}/status/activate',
      deactivate: '/{storeNo}/status/deactivate',
      exportAdjustment: '/{exportBy}/adjusts/export',
      inventoryCount: '/{exportBy}/export',
      exportNoWSP: '/{exportBy}/no-wsp/export',
      exportInventoryVariance: '/count/exportInventoryVariance/pdf/th',
      redeemTransaction: '/redeem-transaction/export',
      rewardTransaction: '/reward-transaction/export',
      imageSignedUrl: '/imageSignedUrl',
      exportInventoryVarianceNestPDF: '/count/inventoryVariance/nest/pdf/th',
      exportOrderScheduleTemplate: '/storeOrderSchedule/import/templates',
      importStoreOrderSchedule: '/import/storeOrderSchedule',
      terminateDateStore: '/{storeNo}/terminate',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      },
      headersOrder: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeReport: {
      url: '/stores',
      exportBillPayment: '/export/billPayment',
      headers: {
        'x-host': 'report.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeExport: {
      url: '/stores',
      export: '/export',
      exportStoreConfidential: '/confidential/export',
      exportStoreOrderSchedule: '/store-order-schedule/export',
      headers: {
        'x-host': 'report.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeRequest: {
      url: '/stores-requests',
      validate: '/validate/{type}/{value}',
      save: '/save',
      submit: '/submit',
      delete: '/{storeId}',
      view: '/{storeId}',
      history: '/history/{storeId}',
      getStoreVersion: '/getVersion/{storeId}',
      approve: '/approveRequest',
      headers: {
        'x-host': 'merchant.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    destroy: {
      url: '/destroys',
      exportDestroyTransaction: '/export',
      allDestroyDocument: '/all/doc',
      exportDestroyDocument: '/{docNo}/print/pdf/th',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    salesSummaryReport: {
      url: '/sales-summary',
      export: '/export',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    refundTransactionConfidentialReport: {
      url: '/refund-confidential',
      export: '/export',
      headers: {
        'x-host': 'report.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    users: {
      url: '/users',
      get: '/{no}',
      uploadUserFileAppend: '/{userName}/files',
      deleteUserFileAppend: '/{userName}/files/{fileName}',
      resetPassword: '/{userName}/resetPassword',
      resetPinCode: '/{userName}/resetPinCode',
      activateUser: '/{userName}/activate/{status}',
      createOTP: '/{userName}/otp',
      verifyOTP: '/{userName}/verifyOtp',
      forgotPassword: '/{userName}/forgetPassword',
      forgotUsername: '/{mobileNumber}/forgetUserName',
      forceChangePassword: '/{userName}/forceChangePassword',
      list: '/list',
      searchUserByRole: '/search/list',
      exportUserReport: '/export',
      headers: {
        'x-host': 'identity.api.tdshop.io'
      }
    },
    dashboard: {
      url: '',
      tasksByRole: '/tasks',
      coutTask: '/tasks/count',
      headers: {
        'x-host': 'identity.api.tdshop.io'
      }
    },
    report: {
      url: '/report',
      myReport: '/my-report',
      downloadReport: '/artifact/{reportNo}',
      headers: {
        'x-host': 'report.api.tdshop.io'
      }
    },
    order: {
      url: '/orders',
      view: '/view/{orderNo}',
      print: '/{id}/print/{format}/{locale}',
      export: '/export',
      release: '/release',
      exportAwaitingFirstLot: '/reports/awaiting-first-lot',
      history: '/view/history/{orderNo}',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    orderRequest: {
      url: '/orders-requests',
      save: '/save',
      submit: '/submit',
      delete: '/{orderId}',
      view: '/{orderId}',
      viewReferenceNo: '/view/{orderRequestNo}',
      history: '/history/{orderId}',
      approve: '/approveRequest',
      getStoreFirstLot: '/firstlot/stores',
      getStoreSpecial: '/replenish/stores',
      validateOrderList: '/validate/orderList',
      downloadFileTemplate: '/imports/templates',
      importValidate: '/imports/validates',
      importSubmit: '/imports/submits',
      getEstimatedDelivery: '/estimated/{storeNo}/delivery/{deliveryDate}',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    orderRequestFixAsset: {
      url: '/orders-requests/fix-asset',
      save: '/save',
      submit: '/submit',
      storeUseSubmit: '/storeuse/submit',
      delete: '/{orderId}',
      view: '/{orderId}',
      history: '/history/{orderId}',
      approve: '/approveRequest',
      getStore: '/firstlot/stores',
      validateOrderList: '/validate/orderList',
      downloadFileTemplate: '/imports/templates',
      importValidate: '/imports/validates',
      importSubmit: '/imports/submits',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    orderRequestInventory: {
      url: '/orders-requests/inventory',
      save: '/save',
      submit: '/submit',
      assignQuantitySubmit: '/assign-quantity/submit',
      assignQuantitySave: '/assign-quantity/save',
      delete: '/{orderId}',
      view: '/{orderId}',
      approve: '/approveRequest',
      getStore: '/firstlot/stores',
      downloadFileTemplate: '/imports/templates',
      importUpdateQtyValidate: '/imports/{orderRequestId}',
      importAddSelectiveItem: '/importSelectiveItems/validate',
      importSubmit: '/imports/submits',
      export: '/export/{orderRequestId}',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    exportOrderRequestFixAsset: {
      url: '/orders-requests-fix-asset',
      export: '/export/{selected}',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    orderRequestStoreUse: {
      url: '/tdproducts',
      export: '/exportProductOrderList',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    orderRequestSelective: {
      url: '/tdproducts',
      export: '/catalogs/export',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    receiveOrder: {
      url: '/receive-orders',
      getByNo: '/{receiveOrderNo}',
      export: '/export',
      toteExport: '/tote/export',
      exceedExport: '/exceed/export',
      print: '/{receiveOrderNo}/print',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeLocationReport: {
      url: '/store-location',
      exportDc: '/dc/export',
      headers: {
        'x-host': 'report.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    deliveryOrder: {
      url: '/delivery-orders',
      get: '/{id}',
      edit: '/edit',
      update: '/{id}/split/draft',
      submit: '/{id}/split/submit',
      exportPicking: '/export-picking',
      importPicking: '/import-picked',
      print: '/{id}/print/{format}/{locale}',
      cancel: '/cancel',
      cancelDispatch: '/dispatch/cancel',
      reschedule: '/rescheduleDate',
      history: '/history/{doNo}',
      delivered: '/delivered',
      headers: {
        'x-host': 'order.api.tdshop.io'
      }
    },
    shelfType: {
      url: '/shelf-types',
      uploadOrder: '/uploadOrder',
      generateOrder: '/generateOrder',
      getShelfTypes: '/getShelfTypes',
      fileUrl: '/fileUrl',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    shipment: {
      url: '/shipments',
      update: '/{shipmentNo}',
      getByShipmentNumber: '/{shipmentNo}',
      deleteByShipmentNumber: '/{shipmentNo}',
      confirmLoad: '/confirm-load',
      dispatch: '/{shipmentNo}/dispatch',
      exportShipment: '/export',
      downloadFileTemplate: '/import/templates',
      submitImport: '/import/submits',
      importValidate: '/import/validates',
      print: '/{shipmentNo}/print/customer-delivery-note',
      headers: {
        'x-host': 'order.api.tdshop.io'
      }
    },
    claim: {
      url: '/crs',
      list: '/all',
      get: '/get/{claimRequestNo}',
      approve: '/approve',
      reject: '/{claimRequestNo}/reject',
      fileUrl: '/imageSignedUrl',
      draft: '/draft/{receiveOrderNo}',
      headers: {
        'x-host': 'order.api.tdshop.io'
      }
    },
    promotions: {
      url: '/promotions',
      export: '/export',
      exportStore: '/export/{promotionCode}',
      get: '/{id}',
      themes: '/themes',
      themesList: '/themes/list',
      cancel: '/cancel',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    promotionsRequest: {
      url: '/promotion-requests',
      downloadFileTemplate: '/V2/download/promotion/item/template/{type}/{promotionConditionKey}',
      importPromotionItem: '/V2/import/promotion/item/{type}/{promotionConditionKey}',
      import: '/store/import',
      exportTemplate: '/export/template',
      exportRequest: '/export/{id}',
      exportView: '/V2/{id}/export',
      get: '/{id}',
      reject: '/reject',
      approve: '/approve',
      requestCancel: '/cancel',
      requestsSubmit: '/submit',
      requestByID: '/{requestId}',
      viewRequest: '/V2/{id}/view',
      generateWorkflow: '/V2/generate/workflow',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    promotionsReport: {
      url: '/promotions',
      export: '/export',
      headers: {
        'x-host': 'report.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    purchaseConditionRequest: {
      url: '/purchase-condition-request',
      exportTemplate: '/discount/export',
      discount: '/discount',
      import: '/discount/import',
      deleteImportFile: '/discount/import/{previousImportId}',
      exportOrderDeliveryScheduleWeeklyTemplate: '/weekly-schedule/export',
      importOrderDeliveryScheduleWeeklyTemplate: '/weekly-schedule/import',
      exportOrderDeliveryScheduleMonthlyTemplate: '/monthly-schedule/export',
      importOrderDeliveryScheduleMonthlyTemplate: '/monthly-schedule/import',
      DeliveryScheduleWeekly: '/weekly-schedule',
      orderDeliveryScheduleWeekly: '/weekly-schedule',
      orderDeliveryScheduleMonthly: '/monthly-schedule',
      purchaseConditionList: '/purchase-condition',
      exists: '/isPendingForUpdate/{supplierId}',
      get: '/view/{id}',
      submit: '/submit',
      approve: '/approve',
      reject: '/reject',
      cancel: '/cancel',
      headers: {
        'x-host': 'purchase.api.tdshop.io'
      }
    },
    purchaseCondition: {
      url: '/purchase-condition',
      headers: {
        'x-host': 'purchase.api.tdshop.io'
      },
      getViewById: '/view/{supplierCode}',
      discount: '/discount',
      weeklySchedule: '/weekly-schedule',
      monthlySchedule: '/monthly-schedule',
      supplierDc: '/supplier-dc',
      supplierContract: '/supplier-contact',
      deleteContract: '/supplier-contact/{id}',
      deleteDc: '/supplier-dc/{id}',
      exportWeekly: '/weekly-schedule/export',
      exportCalendar: '/monthly-schedule/export',
      exportDiscount: '/discount/export'
    },
    purchaseRequest: {
      url: '/purchase-requests',
      get: '/{id}',
      deliveryDetail: '/deliveryDetail/default',
      contacts: '/contacts',
      deleted: '/{id}',
      cancel: '/cancel',
      submit: '/submit',
      approve: '/approve',
      reject: '/reject',
      supplierPromotions: '/supplier/promotions',
      purchaseProductPrice: '/purchase-product-prices',
      purchaseProductFreePrice: '/purchasing-price/supplier-warehouse-free-article',
      exportPr: '/export',
      import: '/import',
      previewDiscount: '/preview-discount',
      headers: {
        'x-host': 'purchase.api.tdshop.io'
      }
    },
    purchaseProductPricesTemplate: {
      url: '/purchase-product-prices-template',
      export: '/export',
      headers: {
        'x-host': 'purchase.api.tdshop.io'
      }
    },
    purchaseOrder: {
      url: '/purchase-orders',
      get: '/{id}',
      cancel: '/cancel',
      close: '/close',
      print: '/{id}/print/{format}/{locale}',
      exportPo: '/export',
      rescheduleDeliveryDate: '/reschedule-delivery-date',
      headers: {
        'x-host': 'purchase.api.tdshop.io'
      }
    },
    goodsReceive: {
      url: '/goods-receives',
      getByPoNo: '/{poNo}',
      getById: '/view/{id}',
      submit: '/submit',
      cancel: '/cancel',
      headers: {
        'x-host': 'purchase.api.tdshop.io'
      }
    },
    supplierGroupRequest: {
      url: '/supplier-group-requests',
      get: '/{id}',
      getStoreSupplier: '/{id}/store-suppliers',
      submit: '/submit',
      exportTemplate: '/export-template',
      export: '/export',
      import: '/import',
      approve: '/approve',
      reject: '/reject',
      cancel: '/cancel',
      delete: '/{id}',
      checkExisting: '/{supplierGroupNo}/exists',
      headers: {
        'x-host': 'supplier.api.tdshop.io'
      }
    },
    vouchers: {
      url: '/vouchers',
      cancel: '/cancel',
      get: '/{voucherCode}',
      export: '/export',
      report: '/report',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    advertisements: {
      url: '/advertisements',
      defaultAds: '/default',
      get: '/{adsNo}',
      submit: '/submit',
      cancel: '/cancel',
      uploadUrl: '/images',
      imageDirectory: '/images/{directory}',
      bucketName: '/pos-ads-dev',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    vouchersRequest: {
      url: '/voucher-requests',
      requestsID: '/{requestId}',
      submit: '/submit',
      approve: '/approve',
      reject: '/reject',
      cancel: '/cancel',
      importValidate: '/import/validate',
      importTemplates: '/import/templates',
      importSubmit: '/import/submit',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    supplier: {
      url: '/suppliers',
      importValidate: '/import/validate',
      downloadFileTemplate: '/import/templates',
      importSubmit: '/import/submit',
      export: '/export',
      list: '/list',
      get: '/{supplierCode}',
      activate: '/activate',
      validate: '/validate',
      ediSetting: '/ediSetting',
      headers: {
        'x-host': 'supplier.api.tdshop.io'
      }
    },
    rewardRequest: {
      url: '/reward-requests',
      requestsID: '/{requestId}',
      submit: '/submit',
      approve: '/approve',
      reject: '/reject',
      cancel: '/cancel',
      import: '/import',
      export: '/export',
      validate: '/validate',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    reward: {
      url: '/rewards',
      get: '/{rewardNo}',
      cancel: '/cancel',
      export: '/export',
      active: '/active/list',
      summary: '/active/summary',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    rewardCatalogRequest: {
      url: '/reward-catalog-requests',
      validate: '/validate/{type}/{value}',
      save: '/save',
      submit: '/submit',
      fileUrl: '/fileUrl',
      approve: '/approve',
      reject: '/reject',
      cancel: '/cancel',
      view: '/{rewardCatalogRequestId}',
      delete: '/{id}',
      exportStore: '/export/{id}',
      importStoreSubmit: '/store/import',
      downloadFileStoreTemplate: '/export/template',
      history: '/history/{requestId}',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    rewardCatalog: {
      url: '/reward-catalog',
      view: '/{requestNo}',
      history: '/history/{catalogId}',
      export: '/export',
      exportStore: '/export/{rewardCatalogNo}',
      cancel: '/cancel',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    rewardPointCash: {
      url: '/point-cash',
      setting: '/setting',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    tdProduct: {
      url: '/tdproducts',
      getDropDown: '/pickingBarcodes',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    role: {
      url: '/roles',
      get: '/{roleNo}',
      list: '/list',
      deleteRole: '/{id}',
      headers: {
        'x-host': 'identity.api.tdshop.io'
      }
    },
    permission: {
      url: '/permissions',
      headers: {
        'x-host': 'identity.api.tdshop.io'
      }
    },
    stocks: {
      url: '/stocks',
      warehouseArticleNo: '/{articleNo}',
      warehouseMovement: '/{warehouse}/{articleNo}/movements',
      stockByLocationType: '/{locationType}/{articleNo}',
      adjusts: '/adjusts',
      adjustsValidate: '/adjusts/validate',
      getAdjustStoreByDocNo: '/adjusts/{docNo}',
      getAdjustStoreItemsByDocNo: '/adjusts/{docNo}/items',
      forceCompleteAdjust: '/adjusts/complete',
      history: '/adjusts/history/{docNo}',
      getWarehouseArticleNo: '/warehouse/{articleNo}',
      export: '/{exportBy}/export',
      importAdjustsValidate: '/adjust/import/validate',
      importAdjusts: '/adjust/import',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    stocksLocation: {
      url: '/locations',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    stocksLocationByPermission: {
      url: '/locations-by-permission',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    memberRewards: {
      url: '/member-rewards',
      memberRewardTransactionReport: '/transaction/export',
      memberRewardAccountingReport: '/accounting/export',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    memberRewardTransactions: {
      url: '/member-reward-transactions',
      memberRewardTransactionReport: '/export',
      memberRewardTransactionReportWithPrice: '/with-price/export',
      headers: {
        'x-host': 'report.api.tdshop.io'
      }
    },
    stockTransferRequest: {
      url: '/stocks',
      validate: '/transfer-request/validate',
      list: '/transfer/list',
      create: '/transfer/create',
      exportTemplate: '/transfer-request/export',
      get: '/transfer/view/{id}',
      getByDocNo: '/transfer/view/doc-no/{docNo}',
      delete: '/transfer/delete/{id}',
      cancel: '/transfer-request/cancel',
      update: '/transfer/update',
      approval: '/transfer-request/approval',
      import: '/transfer-request/import',
      submit: '/transfer-request/submit',
      history: '/transfer-request/{id}/history',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    stockTransfer: {
      url: '/stocks',
      list: '/transfer-order/list',
      get: '/transfer-order/{docNo}',
      close: '/transfer-order/close',
      history: '/transfer-order/{id}/history',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    stockTransferGr: {
      url: '/stocks',
      list: '/transfer-goods-receive/list',
      history: '/transfer-goods-receive/history/{id}',
      get: '/transfer-goods-receive/{id}',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    groupOrder: {
      url: '/orders',
      view: '/view/{orderNo}',
      history: '/history/{id}',
      get: '/transfer-order/{docNo}',
      close: '/transfer-order/close',
      export: '/transfer-order/export',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    stockProduct: {
      url: '/tdproducts',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    salesTransactionReport: {
      url: '/sales-transactions',
      export: '/export',
      exportConfidential: '/confidential/export',
      headers: {
        'x-host': 'report.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    salesTransactionConfidentialReport: {
      url: '/sales-transactions-confidential',
      export: '/export',
      headers: {
        'x-host': 'report.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    supplierCouponReconcileReport: {
      url: '/supplier-coupon-reconcile',
      export: '/export',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeAssortment: {
      url: '/store-assortments',
      view: '/view/{storeNo}',
      history: '/history/{storeNo}',
      list: '/list',
      getAssortment: '/{storeNo}',
      getZ8Factor: '/z8Factor/{id}',
      downloadTemplate: '/store-assortments-template/export',
      importStoreAssorement: '/import',
      exportByProduct: '/exportByProduct',
      submitDownload: '/{storeCode}/submitDownload',
      download: '/{uuid}/download',
      checkIsReadyDownload: '',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      },
      downloadHeaders: {
        'x-host': 'store.file.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeAssortmentRequest: {
      url: '/store-assortment-requests',
      view: '/{requestNo}',
      validateStoreAssortment: '/validate',
      submit: '/submit',
      downloadSpecialAssortmentTemplate: '/special-assortment/template',
      downloadShelfOptionTemplate: '/shelf-option/template',
      uploadSpecialAssortment: '/import',
      exportStoreAssortmentRequest: '/store-assortment-requests/export/{requestNo}',
      history: '/history/{requestNo}',
      approve: '/approve',
      cancel: '/cancel',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    monthlyPartnerProfitSharing: {
      url: '/monthly-partner-profit-sharing',
      report: '/reportSignedUrl/{id}',
      uploadMonthlyPartnerProfitSharingFileAppend: '/validate-import/{runDate}',
      submit: '/submit/{runDate}',
      headers: {
        'x-host': 'billing.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    profitSharing: {
      url: '/partner-profit-sharing',
      export: '/export',
      print: '/print/{runDate}/{docNo}',
      view: '/view/{id}',
      headers: {
        'x-host': 'billing.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    promotionCompensateReport: {
      url: '/promotion-compensate',
      export: '/export',
      headers: {
        'x-host': 'report.api.tdshop.io'
      }
    },
    movingAverageReport: {
      url: '/moving-average',
      export: '/export',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    ssrResult: {
      url: '/ssr-orders',
      search: '/result',
      export: '/fileUrl',
      history: '/history/{id}',
      headers: {
        'x-host': 'order.api.tdshop.io'
      }
    },
    supplierPrice: {
      url: '/supplier-price-configs',
      get: '/article-price-configs',
      import: '/{deliveryMethod}/import',
      export: '/export',
      template: '/template',
      headers: {
        'x-host': 'product.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    taskAssignmentRequest: {
      url: '/task-assignment-requests',
      get: '/{id}',
      submit: '/submit',
      downloadFileStoreTemplate: '/import/templates',
      validateFileStore: '/import/validate',
      importStoreSubmit: '/import',
      approve: '/approve',
      reject: '/reject',
      cancel: '/cancel',
      exportStore: '/export',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    taskAssignment: {
      url: '/task-assignment',
      get: '/{taskNo}',
      loadTaskList: '/task',
      exportStore: '/store/export',
      uploadUrl: '/upload',
      cancel: '/cancel',
      imageDirectory: '/task-assignment/{directory}',
      imageSignedUrl: '/imageSignedUrl',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    taskRequest: {
      url: '/task-assignment-requests',
      get: '/{id}',
      submit: '/submit',

      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    taskAssignmentResponse: {
      url: '/task-assignment-response',
      get: '/{responseNo}',
      put: '/{status}',
      getStoreConsultant: '/{responseNo}/store-consultant',
      exportResponse: '/export/response',
      exportSurvey: '/export/survey',
      imageDirectory: '/task-assignment/{directory}',
      imageSignedUrl: '/imageSignedUrl',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    masters: {
      url: '/masters',
      store: '/store',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    warehousesStores: {
      url: '/warehouses-stores',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    membership: {
      url: '/membership',
      member: '/members',
      view: '/members/{id}',
      getMemberStore: '/member-stores',
      printPointStatement: '/{memberNo}/point/print/{month}',
      memberPaging: '/members/memberId',
      headers: {
        'x-host': 'membership.api.tdshop.io'
      }
    },
    memberPoint: {
      url: '/membership',
      getPointSettingByCriteria: '/point-setting',
      getPointSettingView: '/point-setting/view/{type}',
      checkMemberPointIsAwaitingApprove: '/point-setting-requests/{type}',
      specialPointList: '/special-point',
      specialPointCancel: '/special-point/cancel',
      submit: '/point-setting-requests/submit',
      headers: {
        'x-host': 'membership.api.tdshop.io'
      }
    },
    memberPointRequest: {
      url: '/membership',
      getPointSettingRequestByCriteria: '/point-setting-requests',
      view: '/point-setting-requests/view/{requestNo}',
      approve: '/point-setting-requests/approve',
      reject: '/point-setting-requests/reject',
      cancel: '/point-setting-requests/cancel',
      save: '/special-point-requests',
      submit: '/special-point-requests/submit',
      specialPointReject: '/special-point-requests/reject',
      specialPointApprove: '/special-point-requests/approve',
      getSpecialPointById: '/special-point-requests/{id}',
      downloadFileTemplate: '/special-point-requests/import/template',
      importValidate: '/special-point-requests/import/validate',
      importSubmit: '/special-point-requests/import/submit',
      headers: {
        'x-host': 'membership.api.tdshop.io'
      }
    },
    memberExcludeItem: {
      url: '/membership',
      getMemberExcludeItemByCriteria: '/point-exclude-item',
      getMemberExcludeItemView: '/point-exclude-item/{id}',
      downloadFileTemplate: '/point-exclude-item/export/template',
      submitImport: '/point-exclude-item/import',
      submit: '/point-exclude-item/submit',
      exportExcludeItems: '/point-exclude-item/export',
      cancel: '/point-exclude-item/cancel',
      headers: {
        'x-host': 'membership.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    notForSale: {
      url: '/product',
      downloadFileTemplate: '/not-for-sale/export/template',
      getNotForSaleItemView: '/not-for-sale/{id}',
      import: '/not-for-sale/import',
      export: '/not-for-sale/export/{id}',
      submit: '/not-for-sale/submit',
      cancel: '/not-for-sale/cancel',
      getNotForSaleByCriteria: '/not-for-sale',
      headers: {
        'x-host': 'product.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    z8HoldRequest: {
      url: '/z8hold-requests',
      export: '/export',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    pointEarnAndBurnReport: {
      url: '/membership',
      stores: '/stores',
      exportBurnTransaction: '/report/burn-point',
      exportEarnTransaction: '/report/earn-point',
      headers: {
        'x-host': 'membership.api.tdshop.io'
      }
    },
    exporter: {
      url: '/exporter',
      notify: '/notify',
      metrics: '/metrics',
      headers: {
        'x-host': 'nest-exporter.api.tdshop.io'
      }
    },
    customer360: {
      url: '/insight/customer360',
      template: '/template',
      viewTemplate: '/template/{id}',
      preview: '/template/preview',
      folderList: '/template/folderList',
      saveTemplate: '/template/save',
      manageAccess: '/template/permission',
      deleteTemplate: '/template/{templateId}',
      headers: {
        'x-host': 'data-insight.api.tdshop.io'
      }
    },
    customer360Export: {
      url: '/customer360',
      export: '/export',
      headers: {
        'x-host': 'report.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    dataInsight: {
      url: '/insight',
      address: '/address',
      audience: '/audience',
      dashboard: '/dashboard/{group}',
      headers: {
        'x-host': 'data-insight.api.tdshop.io'
      }
    },
    workflow: {
      url: '/workflows',
      approvalWorkflowNo: '/{no}',
      template: '/{condition}/import/template',
      validate: '/{condition}/import/validate',
      submit: '/submit',
      exportDetails: '/approval-detail/export',
      headers: {
        'x-host': 'workflow.api.tdshop.io'
      }
    },
    latePaymentNotice: {
      url: '/late-payment-notices',
      view: '/view',
      submit: '/submit',
      validate: '/import/validate',
      downloadTemplate: '/import/template',
      headers: {
        'x-host': 'billing.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    permanentHold: {
      url: '/permanent-hold',
      view: '/view',
      unhold: '/unhold',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    holdAssortment: {
      url: '/hold-assortment-request',
      import: '/{requestType}/{reason}',
      importTemplate: '/import/templates',
      exportHoldAssortmentRequest: '/storage/download-link',
      approve: '/approve',
      reject: '/reject',
      view: '/view/{requestNo}',
      history: '/history/{requestNo}',
      cancel: '/cancel',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    refundRequest: {
      url: '/refund-request',
      get: '/{id}',
      voidBill: '/generate/void_bill',
      preOrder: '/generate/pre_order',
      redeemGoods: '/generate/redeem_good',
      uploadAttachments: '/{refundType}/image',
      generateWorkflow: '/generate/workflow',
      fileUrl: '/fileUrl',
      submit: '/submit',
      approve: '/approve',
      reject: '/reject',
      copy: '/{id}/copy',
      cancel: '/cancel',
      headers: {
        'x-host': 'billing.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    refund: {
      url: '/refund',
      export: '/export',
      get: '/{id}',
      confirm: '/confirm',
      headers: {
        'x-host': 'billing.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    billings: {
      url: '/billings',
      headers: {
        'x-host': 'billing.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    approvalWorkFlow: {
      url: '/approval-flow',
      get: '/{id}',
      headers: {
        'x-host': 'workflow.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    audience: {
      url: '/audience',
      submit: '/submit',
      headers: {
        'x-host': 'membership.api.tdshop.io'
      }
    },
    pointEarnTransactionReport: {
      url: '/point-earn-transaction',
      export: '/export',
      headers: {
        'x-host': 'report.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    groupOrderReport: {
      url: '/group-order',
      headers: {
        'x-host': 'stock.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    inventoryCountTransaction: {
      url: '/inventory-count-transaction',
      inventoryCountTransactionReport: '/export',
      inventoryCountTransactionReportWithPrice: '/with-price/export',
      headers: {
        'x-host': 'report.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    exportReport: {
      specialPointCompensate: '/special-point/compensate/export',
      specialPoint: '/special-point/export',
      holdAssortment: '/hold-assortment/export',
      temporaryHoldAssortment: '/temporary-hold-assortment/export',
      returnToWarehouseOrder: '/rtw-order/export',
      returnToWarehouseGR: '/rtw-gr/export',
      returnToWarehouseCharge: '/rtw-charge-report/export',
      stockTransferGrExport: '/stocks/transfer-goods-receive/export',
      stockTransferOrderExport: '/stocks/transfer-order/export',
      response: '/response-report/export',
      survey: '/task-assignment-response/survey/export',
      flashSaleOrderExport: '/flash-sale-order/export',
      onlineCoupon: '/coupon-setting/export',
      vouchersExport: '/vouchers/export',
      rewardRedeemTransaction: '/redeem-transactions/export',
      rewardOfferTransaction: '/reward-transactions/export',
      onlineCouponTransaction: '/online-coupon-transaction/export',
      onlineCouponCompensate: '/online-coupon-compensate/export',
      taskAssignmentExport: '/task-assignment/export',
      claimExport: '/claim-request/export',
      moneyIn: '/money-ins/export',
      toteAdjustment: '/tote-adjustment/export',
      preOrderCreditRemaining: '/preorder-credit-remaining/export',
      onlineCouponSummary: '/online-coupon-summary/export',
      awaitingAllocationExport: '/awaiting-allocation/export',
      groupGroupUnpickedItem: '/group-order-unpicked-items/export',
      exportAssortment: '/td_assortment/export',
      exportAssortmentConfidential: '/td_assortment_confidential/export',
      exportDcAssortment: '/dc_assortment/export',
      exportByStore: '/store_assortment/export',
      exportAssortmentLocation: '/td_assortment_location/export',
      crossDockInvoice: '/purchase/cross-dock-invoice/export',
      storeToteExport: '/tote-store/export',
      toteFineExport: '/tote-fine/export',
      toteInOutExport: '/tote-inout/export',
      toteInTrackingExport: '/tote-in-tracking/export',
      manualChangeDeliveryStatusExport: '/manual-change-delivery-status/export',
      goodsReceive: '/goods-receive/export',
      headers: {
        'x-host': 'report.api.tdshop.io'
      }
    },
    tote: {
      url: '/tote',
      toteInformationSummary: '/tote-information-summaries',
      toteMovement: '/tote-movements',
      toteByStore: '/tote-by-store',
      headers: {
        'x-host': 'tote.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    toteAdjustment: {
      url: '/tote/tote-adjustments',
      get: '/{toteAdjustmentNo}',
      export: '/export/{toteAdjustmentNo}',
      exportReturnFromStore: '/export/{toteAdjustmentNo}/return-from-store',
      exportTemplate: '/import/templates',
      importValidate: '/import/validates',
      importSubmit: '/import/submits',
      headers: {
        'x-host': 'tote.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    preOrderCreditTerm: {
      url: '/preorder-credit-term',
      downloadFileTemplate: '/template',
      submitImport: '/upload',
      defaultPreOrderCreditTerm: '/default-credit',
      submit: '/submit',
      list: '/settings',
      view: '/settings/{requestNo}',
      cancel: '/settings/{requestNo}/cancel',
      export: '/settings/{requestNo}/export',
      headers: {
        'x-host': 'billing.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    returnToWarehouseRequest: {
      url: '/rtw',
      get: '/{requestNo}',
      history: '/history/{requestNo}',
      template: '/import/templates',
      validate: '/import/validate',
      submit: '/import/submit',
      approve: '/approve',
      reject: '/reject',
      cancel: '/cancel',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    returnToWarehouseOrder: {
      url: '/rtw-order',
      get: '/{docNo}',
      history: '/history/{docNo}',
      closed: '/closed',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    returnToWarehouseGR: {
      url: '/rtw-gr',
      get: '/{docNo}',
      history: '/history/{docNo}',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    flashSaleDashboard: {
      url: '/dashboard/flash-sales',
      headers: {
        'x-host': 'dashboard.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    onlineCouponRequest: {
      url: '/coupon-setting-request',
      workflow: '/generate/workflow',
      image: '/image',
      bucketName: '/coupon-images-dev',
      history: '/history/{id}',
      validate: '/validate/{id}',
      view: '/{id}',
      submit: '/submit',
      reject: '/reject',
      cancel: '/cancel',
      approve: '/approve',
      delete: '/{id}',
      copy: '/{id}/copy',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    onlineCouponManage: {
      url: '/coupon',
      template: '/manage/template',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    onlineCoupon: {
      url: '/coupon-setting',
      view: '/{id}',
      cancel: '/cancel',
      history: '/history/{id}',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    storeAssortmentRecommendation: {
      url: '/store-assortments',
      list: '/recommendation',
      export: '/recommendation/export',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    holdOrderRequest: {
      url: '/hold-order-requests',
      downloadTemplate: '/import/templates',
      importValidate: '/import/validates',
      downloadUnholdTemplate: '/import/unhold/templates',
      importUnholdValidate: '/import/unhold/validates',
      submitRequest: '/submits',
      unHoldSubmitRequest: '/unhold/submits',
      get: '/{requestNo}',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    holdOrderList: {
      url: '/hold-orders',
      items: '/{storeCode}/items',
      get: '/{storeCode}',
      export: '/hold-order/export',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    holdOrderExport: {
      url: '/hold-order',
      export: '/export',
      headers: {
        'x-host': 'report.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    receipt: {
      url: '/receipt',
      receiptNo: '/receiptNo',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    setting: {
      productWarehouseMapping: {
        url: '/setting',
        latest: '/product-warehouse-mapping/latest',
        history: '/history?name={settingName}',
        export: '/product-warehouse-mapping/export-template',
        import: '/product-warehouse-mapping/import',
        headers: {
          'x-host': 'product.api.tdshop.io',
          'x-tenant-id': '*:*:*:*'
        }
      },
      productCategoryInventory: {
        url: '/settings',
        latest: '/latest?name={settingName}',
        history: '/history?name={settingName}',
        export: '/product-category-inventory/export-template',
        import: '/product-category-inventory/import',
        headers: {
          'x-host': 'nest.master.api.tdshop.io',
          'x-tenant-id': '*:*:*:*'
        }
      },
      productCategoryFixAssetStoreUse: {
        url: '/settings',
        latest: '/latest?name={settingName}',
        history: '/history?name={settingName}',
        export: '/product-category-fix-asset/export-template',
        import: '/product-category-fix-asset/import',
        headers: {
          'x-host': 'nest.master.api.tdshop.io',
          'x-tenant-id': '*:*:*:*'
        }
      },
      preOrderCategory: {
        url: '/settings',
        latest: '/latest?name={settingName}',
        history: '/history?name={settingName}',
        export: '/pre-order-category/export-template',
        import: '/pre-order-category/import',
        headers: {
          'x-host': 'nest.master.api.tdshop.io',
          'x-tenant-id': '*:*:*:*'
        }
      },
      saleSchedule: {
        url: '/settings',
        latest: '/sale-schedule/latest',
        history: '/sale-schedule/history',
        export: '/sale-schedule/download/{id}',
        import: '/sale-schedule/import',
        remove: '/sale-schedule/{id}',
        headers: {
          'x-host': 'store.api.tdshop.io',
          'x-tenant-id': '*:*:*:*'
        }
      },
      sapStoreCodeMapping: {
        url: '/settings',
        latest: '/latest?name={settingName}',
        history: '/history?name={settingName}',
        export: '/store-code-sap-mapping/export-template',
        import: '/store-code-sap-mapping/import',
        headers: {
          'x-host': 'nest.master.api.tdshop.io',
          'x-tenant-id': '*:*:*:*'
        }
      },
      warehouseOperatorOrderFlow: {
        url: '/settings',
        list: '/warehouse?conditionName={conditionName}',
        headers: {
          'x-host': 'nest.master.api.tdshop.io',
          'x-tenant-id': '*:*:*:*'
        }
      }
    }
  },
  viewStore: {
    isShowHandDevice: false,
    isShowAddPosDevice: false
  }
};
