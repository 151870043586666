import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import {
  TdAssortmentContent,
  TdAssortmentFreeItemContent,
  TdAssortmentSearchCriteria
} from '../../models/purchase-request.model';
import { TdAssortmentActionTypes, TdAssortmentActions } from '../actions/td-assortment.actions';

export interface TdAssortmentFreeItemState extends EntityState<TdAssortmentFreeItemContent> {
  totalElements: number;
  totalPages: number;
}

export interface TdAssortmentState extends EntityState<TdAssortmentContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  criteriaObject: TdAssortmentSearchCriteria;
  totalElements: number;
  totalPages: number;
  tdAssortmentFreeItem: TdAssortmentFreeItemState;
}

export const adapter: EntityAdapter<TdAssortmentContent> = createEntityAdapter<TdAssortmentContent>({
  selectId: (tdAssortmentContent: TdAssortmentContent) =>
    `${tdAssortmentContent.supplierCode}_${tdAssortmentContent.barcode}`
});

export const adapterFreeTdAssortmentFreeItem: EntityAdapter<TdAssortmentFreeItemContent> = createEntityAdapter<
  TdAssortmentFreeItemContent
>({
  selectId: (tdAssortmentFreeItemContent: TdAssortmentFreeItemContent) =>
    `${tdAssortmentFreeItemContent.supplierCode}_${tdAssortmentFreeItemContent.barcode}`
});

export const initialTdAssortmentFreeItemState: TdAssortmentFreeItemState = adapterFreeTdAssortmentFreeItem.getInitialState(
  {
    totalElements: 0,
    totalPages: 0
  }
);

export const initialTdAssortmentState: TdAssortmentState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  tdAssortmentFreeItem: initialTdAssortmentFreeItemState
});

export function tdAssortmentReducers(
  state = initialTdAssortmentState,
  action: TdAssortmentActions
): TdAssortmentState {
  switch (action.type) {
    case TdAssortmentActionTypes.TD_ASSORTMENT_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case TdAssortmentActionTypes.TD_ASSORTMENT_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case TdAssortmentActionTypes.TD_ASSORTMENT_LIST_RESET:
      return {
        ...state,
        ...initialTdAssortmentState
      };
    case TdAssortmentActionTypes.TD_ASSORTMENT_FREE_ITEM_LIST_RESPONSE:
      return {
        ...state,
        tdAssortmentFreeItem: adapterFreeTdAssortmentFreeItem.setAll(action.payload.content, {
          ...state.tdAssortmentFreeItem,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages
        })
      };
    case TdAssortmentActionTypes.TD_ASSORTMENT_UPDATE_ITEM:
      return adapter.updateOne(
        {
          id: `${action.payload.supplierCode}_${action.payload.barcode}`,
          changes: { ...action.payload }
        },
        {
          ...state
        }
      );

    case TdAssortmentActionTypes.TD_ASSORTMENT_FREE_ITEM_UPDATE_ITEM:
      return {
        ...state,
        tdAssortmentFreeItem: adapterFreeTdAssortmentFreeItem.updateOne(
          {
            id: `${action.payload.supplierCode}_${action.payload.barcode}`,
            changes: { ...action.payload }
          },
          {
            ...state.tdAssortmentFreeItem
          }
        )
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const { selectAll: selectAllTdAssortmentFreeItem } = adapterFreeTdAssortmentFreeItem.getSelectors();
