import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  AdjustStockForceComplete,
  AdjustStockItemCriteria,
  AdjustStockItemPagination,
  AdjustStockRequest,
  AdjustStockRequestNo,
  AdjustStockResponse,
  RequestImportAdjustStock,
  StockAdjustPagination,
  StockAdjustSearchCriteria
} from '../../pages/manage-stock/models/adjust-stock.model';
import {
  BaseReport,
  StockInformationDto,
  StockInformationListPagination,
  StockInformationSearchCriteria,
  StockMovementPagination,
  StockMovementSearchCriteria,
  StockStorePagination,
  StockStoreSearchCriteria,
  StockWarehousePagination
} from '../models';
import { FileResponse } from '../models/fileResponse';
import { MetricAction, MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { Progress } from '../models/progress.model';
import { metricsReporting } from '../utils/metrics-util';
import { BaseService } from './base.service';

type UploadResultType = Progress | FileResponse | null | any;

@Injectable()
export class StockInformationService extends BaseService {
  public headers: HttpHeaders;
  public envReportService;

  constructor(private readonly http: HttpClient, protected readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.stocks;
    this.envReportService = this.env.services.exportReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: StockInformationSearchCriteria): Observable<StockInformationListPagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STOCK,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria, true);
    return this.http.get<StockInformationListPagination>(this.getUrl(), {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      params
    });
  }

  public searchAdjustByCriteria(criteria: StockAdjustSearchCriteria): Observable<StockAdjustPagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ADJUST_STOCK,
          route: this.envService.adjusts,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getFullUrl(this.envService.adjusts);
    const params = this.getParams(criteria, true);

    return this.http.get<StockAdjustPagination>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  /**
   * @description  save adjust stock
   */
  public submitAdjustStock(data: AdjustStockRequest): Observable<AdjustStockResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.ADJUST_STOCK,
          path: this.envService.adjusts
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ADJUST_STOCK,
          route: this.envService.adjusts,
          action: MetricActionType.SUBMIT
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.ADJUST_STOCK,
          path: this.envService.adjusts,
          action: MetricAction.ADJUST_STOCK_SUBMIT_ITEM,
          type: data.adjustType,
          items: data.items.length
        }
      ]
    };

    const url = this.getFullUrl(this.envService.adjusts);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body'
    });
  }

  public validateAdjustData(data: AdjustStockRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.adjustsValidate);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.ADJUST_STOCK,
          path: this.envService.adjustsValidate
        }
      ]
    };
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body'
    });
  }

  /**
   * @description  get adjust stock by id
   */
  public getAdjustStockByDocNo(docNo: string): Observable<AdjustStockResponse> {
    const url = this.getFullUrl(this.envService.getAdjustStoreByDocNo, {
      docNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getAdjustStockItemCriteria(
    docNo: string,
    criteria: AdjustStockItemCriteria
  ): Observable<AdjustStockItemPagination> {
    const url = this.getFullUrl(this.envService.getAdjustStoreItemsByDocNo, {
      docNo
    });
    const params = this.getParams(criteria, true);
    return this.http.get<AdjustStockItemPagination>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  /**
   * @description  get stock information by warehouse and articleNo
   */
  public warehouseArticleNo(warehouse: string, articleNo: string): Observable<StockInformationDto> {
    const url = this.getFullUrl(this.envService.warehouseArticleNo, {
      articleNo
    });

    const params = this.getParams({
      ...(warehouse === 'STORE' && {
        locationType: warehouse
      }),
      ...(!['WAREHOUSE', 'STORE'].includes(warehouse) && {
        location: warehouse
      })
    });

    return this.http.get<StockInformationDto>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  /**
   * @description  get movement per product warehouse
   */
  public movement(
    warehouse: string,
    articleNo: string,
    criteria: StockMovementSearchCriteria
  ): Observable<StockMovementPagination> {
    const url = this.getFullUrl(this.envService.warehouseMovement, {
      warehouse,
      articleNo
    });

    return this.http.post<StockMovementPagination>(url, criteria, {
      headers: this.headers
    });
  }

  public exportStock<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.export, { exportBy: exportCriteria.exportBy });
    const metrics = metricsReporting([this.envService.url, this.envService.export]);

    delete exportCriteria.exportBy;

    return this.http.post<any>(url, exportCriteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public exportStockCard<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.export, { exportBy: exportCriteria.exportBy });
    const metrics = metricsReporting([this.envService.url, this.envService.export]);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportStockAdjustment<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.export, { exportBy: exportCriteria.exportBy });
    const metrics = metricsReporting([this.envService.url, this.envService.export]);

    delete exportCriteria.exportBy;
    let headers = this.loaderHeaders(0, metrics);
    headers = headers.set('x-host', this.envReportService.headers['x-host']);

    return this.http.post<any>(url, exportCriteria, {
      headers,
      observe: 'body'
    });
  }

  public exportStockByLocation<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.export, { exportBy: 'location' });

    return this.http.post<any>(url, exportCriteria, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  /**
   * @description  save data import adjust stock
   */
  public submitDataImportAdjustsStock(dataAdjustsStock: RequestImportAdjustStock[]): Observable<any> {
    const url = this.getFullUrl(this.envService.importAdjusts);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.ADJUST_STOCK,
          path: this.envService.adjusts,
          action: MetricAction.ADJUST_STOCK_IMPORT_ITEM,
          items: dataAdjustsStock.length
        },
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.ADJUST_STOCK,
          path: `${this.envService.stocks}${this.envService.importAdjusts}`
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ADJUST_STOCK,
          route: `${this.envService.stocks}${this.envService.importAdjusts}`,
          action: MetricAction.IMPORT
        }
      ]
    };

    return this.http.post<any>(url, dataAdjustsStock, {
      observe: 'body',
      headers: this.loaderHeaders(0, metricsReq)
    });
  }

  public validateImportAdjustStockFiles(files: Array<File>): Array<Observable<UploadResultType>> {
    return files.map(file => this.validateImportAdjustStockFile(file));
  }

  public validateImportAdjustStockFile(file: File): Observable<UploadResultType> {
    const url = this.getFullUrl(this.envService.importAdjustsValidate);
    const formData = new FormData();
    formData.append('file', file, file.name);
    this.headers.append('Content-Type', 'multipart/form-data');
    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.loaderHeaders()
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return { ...event.body, status: 'done' };
            default:
              const msg = `Unhandled event: ${HttpEventType[event.type]}`;
              this.logger.info(msg);
              return null;
          }
        }),
        catchError(err => of(err))
      );
  }

  /**
   * @description  get stock by location per product
   */
  public stockByLocation(
    locationType: string,
    articleNo: string,
    criteria: StockStoreSearchCriteria
  ): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STOCK,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.stockByLocationType, {
      locationType: locationType.toLocaleLowerCase(),
      articleNo
    });

    return this.http.get<StockStorePagination>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  /**
   * @description  get stock by store per product
   */
  public stockStore(
    warehouse: string,
    articleNo: string,
    criteria: StockStoreSearchCriteria
  ): Observable<StockStorePagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STOCK_STORE,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.stockStore, {
      warehouse,
      articleNo
    });

    return this.http.get<StockStorePagination>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public getWarehouseArticleNo(articleNo: string, viewParams: any): Observable<StockWarehousePagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STOCK,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };

    const url = this.getFullUrl(this.envService.getWarehouseArticleNo, {
      articleNo
    });
    const params = this.getParams(viewParams, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public forceComplete(data: AdjustStockForceComplete) {
    const url = this.getFullUrl(this.envService.forceCompleteAdjust);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  public getHistoryLogs(payload: AdjustStockRequestNo) {
    const url = this.getFullUrl(this.envService.history, {
      docNo: payload.docNo
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }
}
