import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { ProductOrderItem, ShelfInventoryRequest } from '../models/order-request.model';
import {
  ShelfInventoryCreateRequest,
  ShelfInventoryRequestViewResponse
} from '../models/shelf-inventory-request.model';
import {
  SelectShelfInventoryListResponse,
  ShelfInventoryListResponse,
  ShelfInventoryListSearchCriteria,
  ShelfInventoryNo,
  ShelfInventoryViewResponse
} from '../models/shelf-inventory.model';
import { ShelfNo } from '../models/shelf.model';
import { BaseService } from './base.service';

@Injectable()
export class ShelfInventoryService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.shelfInventory;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: ShelfInventoryListSearchCriteria): Observable<ShelfInventoryListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.SHELF_INVENTORY,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public getHistoryLogs(shelfNo: ShelfInventoryNo): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      shelfNo: shelfNo.shelfNo
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getShelfById(shelfNo: ShelfNo): Observable<ShelfInventoryViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      shelfNo: shelfNo.shelfNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public submitFirstLot(shelf: ShelfInventoryCreateRequest): Observable<ShelfInventoryRequestViewResponse> {
    const url = this.getFullUrl(this.envService.submitFirstLot);

    return this.http.post<ShelfInventoryRequestViewResponse>(url, shelf, { headers: this.loaderHeaders(2000) });
  }

  public activateShelfInventory(shelfNo: ShelfNo, activate: boolean): Observable<any> {
    const url = this.getFullUrl(this.envService.activate, {
      shelfNo: shelfNo.shelfNo
    });
    const params = this.getParams({ activate });

    return this.http.post<ShelfInventoryRequestViewResponse>(url, null, {
      headers: this.loaderHeaders(2000),
      observe: 'body',
      params
    });
  }

  public getShelfInventoryList(
    criteria: ShelfInventoryListSearchCriteria
  ): Observable<SelectShelfInventoryListResponse> {
    const url = this.getFullUrl(this.envService.getShelfList);
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public generateShelfItems(shelfInventoryRequest: ShelfInventoryRequest): Observable<ProductOrderItem[]> {
    const url = this.getFullUrl(this.envService.generateItems);
    const params = this.getParams(shelfInventoryRequest);

    return this.http.get<ProductOrderItem[]>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public exportShelf(selected: Array<string>, status: Array<string>): Observable<any> {
    const url = this.getFullUrl(this.envService.export);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.SHELF_INVENTORY,
          path: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.SHELF_INVENTORY,
          route: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.post<any>(
      url,
      {
        selected,
        status
      },
      {
        headers: this.loaderHeaders(0, metricsReq),
        observe: 'body',
        responseType: 'blob' as 'json'
      }
    );
  }
}
