import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { VatDetail } from '../../../shared/models/vat-details.model';

@Component({
  selector: 'app-vat-details-modal',
  templateUrl: './vat-details-modal.component.html',
  styleUrls: ['./vat-details-modal.component.scss']
})
export class VatDetailsModalComponent {
  public title: string;
  public vatDetails: VatDetail[];
  public vatAmountPrecision: number;

  constructor(public bsModalRef: BsModalRef) {}

  decline(): void {
    this.bsModalRef.hide();
  }
}
