import { MetricActionType, MetricModule, MetricType } from '../models/metrics';

const metricsReporting = (path: string[]) => {
  return {
    metrics: [
      {
        metricType: MetricType.COUNTER,
        module: MetricModule.REPORT,
        path: path && path.length > 0 ? path.join('') : 'no-path',
        action: MetricActionType.EXPORT
      },
      {
        metricType: MetricType.RESPONSE_TIME,
        module: MetricModule.REPORT,
        route: path && path.length > 0 ? path.join('') : 'no-path',
        action: MetricActionType.EXPORT
      }
    ]
  };
};

const metricsReportDataHub = (path: string[], exportType: MetricActionType) => {
  return {
    metrics: [
      {
        metricType: MetricType.COUNTER,
        module: MetricModule.REPORT_DATA_HUB,
        path: path && path.length > 0 ? path.join('') : 'no-path',
        action: exportType
      }
    ]
  };
};

export { metricsReporting, metricsReportDataHub };
