<div class="modal-content admin-form">
  <div class="modal-header">
    <h5 class="modal-title pull-left">Submit Data</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label for="profitReport">As of Month data</label>
        <div class="d-flex">
          <input
            type="text"
            class="form-control mr-3"
            id="profitReport"
            placeholder="Monthly Profit Sharing Report"
            disabled
          />
          <button type="button" class="btn btn-primary" (click)="downloadMonthlyProfitSharing()">
            <em class="icon-download"></em>
            {{ 'REPORTS.DOWNLOAD' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md">
        <label for="uploadProfitSharing">Updated As of Month data</label>
        <div class="d-flex">
          <app-monthly-partner-profit-sharing-file-upload
            id="uploadProfitSharing"
            formControlName="uploadProfitSharing"
            class="form-control"
            [hasError]="form.controls.uploadProfitSharing.errors"
            [allowedTypes]="'sheet'"
            [withMeta]="true"
            [size]="5000000"
            [controlName]="'uploadProfitSharing'"
            [allowedExt]="'xlsx'"
            descriptionTxt=""
            fileSizeErrorTxt="Size up to 5 MB."
            [fileModule]=""
            [runDate]="runDate"
          ></app-monthly-partner-profit-sharing-file-upload>
        </div>
      </div>
    </div>
    <div
      class="form-row"
      *ngIf="
        form.controls.uploadProfitSharing.errors ||
        (form.controls.uploadProfitSharing.value &&
          form.controls.uploadProfitSharing.value[0] &&
          form.controls.uploadProfitSharing.value[0].status)
      "
    >
      <div class="form-group col-md">
        <div
          class="alert-success form-group mb-0"
          *ngIf="
            (form.controls.uploadProfitSharing.value && form.controls.uploadProfitSharing.value[0].status) ===
            monthlyPartnerProfitSharingUploadStatusEnum.COMPLETED
          "
        >
          <div>
            The data has been uploaded. Are you sure you want to submit?
          </div>
        </div>
        <div
          class="alert-danger form-group mb-0"
          *ngIf="
            form.controls.uploadProfitSharing.errors ||
            (form.controls.uploadProfitSharing.value &&
              form.controls.uploadProfitSharing.value[0].status ===
                monthlyPartnerProfitSharingUploadStatusEnum.FAILED)
          "
        >
          <div *ngIf="form.controls.uploadProfitSharing.errors?.fileExt">
            Incorrect format (allow only format file .xlsx).
          </div>
          <div *ngIf="form.controls.uploadProfitSharing.errors?.fileSize">
            Size up to 5 MB.
          </div>
          <div
            *ngIf="
              form.controls.uploadProfitSharing.value &&
              form.controls.uploadProfitSharing.value[0].status === monthlyPartnerProfitSharingUploadStatusEnum.FAILED
            "
          >
            {{ form.controls.uploadProfitSharing.value[0].errorMessage }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="table-responsive max-height-table"
      *ngIf="
        form.controls.uploadProfitSharing.value &&
        form.controls.uploadProfitSharing.value[0] &&
        form.controls.uploadProfitSharing.value[0].validations &&
        form.controls.uploadProfitSharing.value[0].validations.length > 0
      "
    >
      <table class="table table-striped table-bordered table-hover w-100" aria-hidden="true">
        <thead>
          <tr class="error-background text-center">
            <th scope="col">Row</th>
            <th scope="col">Column</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of form.controls.uploadProfitSharing.value[0].validations; index as i">
            <td class="text-center">{{ item.row }}</td>
            <td>{{ item.column }}</td>
            <td>{{ item.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="bsModalRef.hide()" id="btCancel">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="
        form.controls.uploadProfitSharing.errors ||
        !form.controls.uploadProfitSharing.value ||
        form.controls.uploadProfitSharing.value.length === 0 ||
        (form.controls.uploadProfitSharing.value &&
          form.controls.uploadProfitSharing.value[0] &&
          form.controls.uploadProfitSharing.value[0].status === monthlyPartnerProfitSharingUploadStatusEnum.FAILED)
      "
      id="btnSubmit"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </div>
</div>
