import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import {
  ExcludeItemImportResponseModel,
  MemberExcludeItemPagination,
  MemberExcludeItemSearchCriteria,
  MemberExcludeItemViewResponseModel
} from '../models/member-exclude-item.model';
import { BaseService } from './base.service';

@Injectable()
export class MemberExcludeItemService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, protected readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.memberExcludeItem;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getMemberExcludeItemList(
    criteria: MemberExcludeItemSearchCriteria
  ): Observable<MemberExcludeItemPagination> {
    const url = this.getFullUrl(this.envService.getMemberExcludeItemByCriteria);
    const params = this.getParams(criteria, true);

    return this.http.get<MemberExcludeItemPagination>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getMemberExcludeItemView(id): Observable<MemberExcludeItemViewResponseModel> {
    const url = this.getFullUrl(this.envService.getMemberExcludeItemView, { id });

    return this.http.get<MemberExcludeItemViewResponseModel>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public downloadFileTemplate(): Observable<any> {
    const url = this.getFullUrl(this.envService.downloadFileTemplate);
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public importExcludeItems(file): Observable<ExcludeItemImportResponseModel> {
    const url = this.getFullUrl(this.envService.submitImport);
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      observe: 'body',
      headers: this.loaderHeaders()
    });
  }

  public exportExcludeItems(excludeNo: string) {
    const params = this.getParams({ excludeNo });
    const url = this.getFullUrl(this.envService.exportExcludeItems);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  submit(payload: any): Observable<any> {
    const url = this.getFullUrl(this.envService.submit);

    return this.http.post<any>(url, payload, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }

  cancel(payload: any): Observable<any> {
    const url = this.getFullUrl(this.envService.cancel);

    return this.http.post<any>(url, payload, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }
}
