export enum DeliveryOrderStatusEnum {
  DRAFT = 'DRAFT',
  NEW = 'NEW',
  PICKING = 'PICKING',
  PICKED = 'PICKED',
  LOADED = 'LOADED',
  DISPATCHED = 'DISPATCHED',
  DELIVERED = 'DELIVERED',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  AWAITING_CANCELLED = 'AWAITING_CANCELLED'
}

export enum DeliveryOrderModeEnum {
  EDIT_MODE = 'edit',
  VIEW_MODE = 'view'
}

export enum DeliveryDoTypeEnum {
  FIRST_LOT_ORDER = 'FIRST_LOT_ORDER',
  FRESH_LITE = 'FRESH_LITE',
  MEMBER_REWARD = 'MEMBER_REWARD',
  PRE_ORDER = 'PRE_ORDER',
  SPECIAL_REQUEST = 'SPECIAL_REQUEST',
  STOCK_TRANSFER = 'STOCK_TRANSFER',
  STORE_REPLENISHMENT = 'STORE_REPLENISHMENT'
}

export enum WarehouseOperatorEnum {
  TD = 'TD',
  TDSC = 'TDSC'
}

export enum OrderFlowEnum {
  KEEP_STOCK = 'KEEP_STOCK',
  CROSS_DOCK = 'CROSS_DOCK'
}
