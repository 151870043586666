export class TaskResponsePermission {
  private _hasApproverTask: boolean;
  private _hasViewTask: boolean;

  public get hasApproverTask(): boolean {
    return this._hasApproverTask;
  }

  public get hasViewTask(): boolean {
    return this._hasViewTask;
  }

  checkPermission(authGuardService) {
    this._hasApproverTask = authGuardService.checkPermission(['resp_app']);
    this._hasViewTask = authGuardService.checkPermission(['resp_v']);
  }
}
