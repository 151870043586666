import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, exhaustMap, timer } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { StoreNo } from '../../../shared/models';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../../../shared/models/metrics';
import { BaseService } from '../../../shared/services/base.service';
import {
  ExportStoreAssortmentByProductCriteria,
  StoreAssortmentRequestStoreListResponse,
  StoreAssortmentRequestStoreListSearchCriteria
} from '../models/store-assortment-request-store.model';
import {
  MasterAssortmentList,
  MasterAssortmentListSearchCriteria,
  ShelfDetailDownloadRequest,
  StoreAssortmentListResponse,
  StoreAssortmentListSearchCriteria,
  StoreAssortmentViewResponse
} from '../models/store-assortment.model';

@Injectable()
export class StoreAssortmentService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.storeAssortment;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: StoreAssortmentListSearchCriteria): Observable<StoreAssortmentListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STORE_ASSORTMENT,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public getHistoryLogs(storeNo: string): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      storeNo
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getStoreAssortmentById(storeNo: StoreNo): Observable<StoreAssortmentViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      storeNo: storeNo.storeNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public searchAssortmentByCriteria(
    storeNo: StoreNo,
    criteria: MasterAssortmentListSearchCriteria
  ): Observable<MasterAssortmentList> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STORE_ASSORTMENT,
          route: this.envService.getAssortment,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getFullUrl(this.envService.getAssortment, {
      storeNo: storeNo.storeNo
    });
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public searchStoreAssortmentStoreListByCriteria(
    criteria: StoreAssortmentRequestStoreListSearchCriteria
  ): Observable<StoreAssortmentRequestStoreListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STORE_ASSORTMENT,
          route: this.envService.list,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getFullUrl(this.envService.list, {});
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public exportByProduct(criteria: ExportStoreAssortmentByProductCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.exportByProduct);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.STORE_ASSORTMENT,
          path: `${this.envService.url}${this.envService.exportByProduct}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STORE_ASSORTMENT,
          route: `${this.envService.url}${this.envService.exportByProduct}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public downloadShelfDetails(storeCode: string, request: ShelfDetailDownloadRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.submitDownload, {
      storeCode
    });
    const downloadHeader = new HttpHeaders(this.envService.downloadHeaders);

    return this.http
      .post<any>(url, request, {
        headers: downloadHeader,
        observe: 'body'
      })
      .pipe(
        switchMap(token => {
          return timer(3_000, 5_000).pipe(
            exhaustMap(() => this.checkDownloadShelfDetailsIsReady(token.key)),
            first(({ signUrl, status }) => signUrl !== null && status === 'COMPLETED')
          );
        }),
        switchMap(({ signUrl }) => {
          return this.http.get(signUrl, {
            responseType: 'blob' as 'json'
          });
        })
      );
  }

  public checkDownloadShelfDetailsIsReady(uuid: string) {
    const url = this.getFullUrl(this.envService.download, {
      uuid
    });
    const downloadHeader = new HttpHeaders(this.envService.downloadHeaders);

    return this.http.get<any>(url, {
      headers: downloadHeader,
      observe: 'body'
    });
  }
}
