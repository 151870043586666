import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared/shared.module';

import { AddShelfTypeComponent } from './add-shelf-type/add-shelf-type.component';
import { ExportShelfComponent } from './export-shelf/export-shelf.component';
import { ShelfFixAssetComponent } from './shelf-fix-asset/shelf-fix-asset.component';
import { ShelfInfoComponent } from './shelf-info/shelf-info.component';
import { ShelfInformationComponent } from './shelf-information/shelf-information.component';
import { ShelfInventoryComponent } from './shelf-inventory/shelf-inventory.component';

@NgModule({
  declarations: [
    AddShelfTypeComponent,
    ExportShelfComponent,
    ShelfInfoComponent,
    ShelfInformationComponent,
    ShelfInventoryComponent,
    ShelfFixAssetComponent
  ],
  imports: [CommonModule, SharedModule, TranslateModule.forChild()],
  exports: [
    AddShelfTypeComponent,
    ExportShelfComponent,
    ShelfInfoComponent,
    ShelfInformationComponent,
    ShelfInventoryComponent,
    ShelfFixAssetComponent
  ]
})
export class ShelfComponentsModule {}
