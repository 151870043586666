import { Action } from '@ngrx/store';

import {
  MonthlyPartnerProfitSharingErrorResponse,
  MonthlyPartnerProfitSharingListPagination,
  MonthlyPartnerProfitSharingSearchCriteria,
  MonthlyPartnerProfitSharingSubmitRequest,
  MonthlyPartnerProfitSharingSubmitResponse
} from '../../../../shared/models/monthly-partner-profit-sharing.model';

export enum MonthlyPartnerProfitSharingActionTypes {
  MONTHLY_PARTNER_PROFIT_SHARING_LIST_REQUEST = '[Monthly Partner Profit Sharing] Monthly Profit Sharing List request',
  MONTHLY_PARTNER_PROFIT_SHARING_LIST_RESPONSE = '[Monthly Partner Profit Sharing] Monthly Profit Sharing List response',
  MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_REQUEST = '[Monthly Partner Profit Sharing] Monthly Profit Sharing Submit request',
  MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_RESPONSE = '[Monthly Partner Profit Sharing] Monthly Profit Sharing Submit response',
  MONTHLY_PARTNER_PROFIT_SHARING_RESET_RESPONSE = '[Monthly Partner Profit Sharing] Monthly Profit Sharing Reset',
  MONTHLY_PARTNER_PROFIT_SHARING_RESET_UPLOAD = '[Monthly Partner Profit Sharing] Monthly Profit Sharing Reset Upload',
  MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_RESPONSE_ERROR = '[Monthly Partner Profit Sharing] Monthly Profit Sharing Submit response Error'
}

export class MonthlyPartnerProfitSharingListRequestAction implements Action {
  readonly type = MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_LIST_REQUEST;
  constructor(public payload: MonthlyPartnerProfitSharingSearchCriteria) {}
}

export class MonthlyPartnerProfitSharingListResponseAction implements Action {
  readonly type = MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_LIST_RESPONSE;
  constructor(public payload: MonthlyPartnerProfitSharingListPagination) {}
}

export class MonthlyPartnerProfitSharingSubmitRequestAction implements Action {
  readonly type = MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_REQUEST;
  constructor(public payload: MonthlyPartnerProfitSharingSubmitRequest) {}
}

export class MonthlyPartnerProfitSharingSubmitResponseAction implements Action {
  readonly type = MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_RESPONSE;
  constructor(public payload: MonthlyPartnerProfitSharingSubmitResponse) {}
}

export class MonthlyPartnerProfitSharingResetAction implements Action {
  readonly type = MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_RESET_RESPONSE;
  constructor() {}
}

export class MonthlyPartnerProfitSharingResetUploadAction implements Action {
  readonly type = MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_RESET_UPLOAD;
  constructor() {}
}

export class MonthlyPartnerProfitSharingSubmitResponseError implements Action {
  readonly type = MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_RESPONSE_ERROR;
  constructor(public payload: MonthlyPartnerProfitSharingErrorResponse) {}
}

export type MonthlyPartnerProfitSharingActions =
  | MonthlyPartnerProfitSharingListRequestAction
  | MonthlyPartnerProfitSharingListResponseAction
  | MonthlyPartnerProfitSharingSubmitRequestAction
  | MonthlyPartnerProfitSharingSubmitResponseAction
  | MonthlyPartnerProfitSharingResetAction
  | MonthlyPartnerProfitSharingResetUploadAction
  | MonthlyPartnerProfitSharingSubmitResponseError;
