import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import {
  TaskAssignmentRequest,
  TaskRequestContent,
  TaskRequestListSearchCriteria
} from '../../models/task-assignment-request.model';
import { TaskRequestActionType, TaskRequestActions } from '../actions/task-request.actions';
import { BaseState } from '../state/base.state';

export interface TaskRequestState extends EntityState<TaskRequestContent>, BaseState {
  selected: TaskAssignmentRequest;
  criteriaObject: TaskRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  isDeleteSuccess: boolean;
}

export const adapter: EntityAdapter<TaskRequestContent> = createEntityAdapter<TaskRequestContent>();

export const initialTaskRequestResponseState: TaskRequestState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  isDeleteSuccess: null
});

export function taskRequestReducers(
  state = initialTaskRequestResponseState,
  action: TaskRequestActions
): TaskRequestState {
  switch (action.type) {
    case TaskRequestActionType.TASK_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case TaskRequestActionType.TASK_REQUEST_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case TaskRequestActionType.TASK_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };

    case TaskRequestActionType.TASK_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
