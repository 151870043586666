import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { MasterDataEnum } from '../../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { PromotionRequestStatusEnum, SelectedPromotionStoreEnum } from '../../../../shared/enum/promotion.enum';
import { NewMasterData } from '../../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { PromotionRequestPageModes, PromotionRequestResponse } from '../../../../shared/models';
import { ButtonType, ImportExportButton } from '../../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../../shared/models/list-value/list-key-value.model';
import { MasterService } from '../../../../shared/services/master.service';
import { PromotionRequestService } from '../../../../shared/services/promotion-request.service';
import { PromotionService } from '../../../../shared/services/promotion.service';
import { StoreService } from '../../../../shared/services/store.service';
import { selectRequestPromotionData } from '../../../../shared/store/selectors/promotion-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import { PromotionModalUploadComponent } from '../../promotion-modal-upload/promotion-modal-upload.component';
import { SelectByStoreComponent } from '../select-by-store/select-by-store.component';

export const FORM_PARAMS = {
  promotionStores: 'promotionStores',
  selectStore: 'selectStore',
  stores: 'stores'
};

@Component({
  selector: 'app-promotion-store',
  templateUrl: './promotion-store.component.html',
  styleUrls: ['./promotion-store.component.scss']
})
export class PromotionStoreComponent extends OnDestroyMixin implements OnInit {
  private localStore: Observable<any>;
  @Input() saveDraft: boolean;
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() mode: PromotionRequestPageModes;
  @Input() status: PromotionRequestStatusEnum;
  @Input() promotion: PromotionRequestResponse;

  itemPage: number;
  pageSize: number;

  public promotionRequestView$: Observable<PromotionRequestResponse>;
  public headerRow: string[];
  public storeList: any[];

  public isSelectedByStores: boolean;
  public storeTypeList: Array<NewMasterData> | null;
  public regionList: Array<NewMasterData> | null;
  public stateList: Array<NewMasterData> | null;

  public masterDataEnum = MasterDataEnum;
  public buttons: Array<ImportExportButton>;

  constructor(
    public modalRef: BsModalRef,
    private readonly fb: UntypedFormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    protected storeService: StoreService,
    private readonly masterService: MasterService,
    protected promotionRequestService: PromotionRequestService,
    protected promotionService: PromotionService
  ) {
    super();
  }

  ngOnInit() {
    this.headerRow = ['No.', 'Store Code', 'Store Name', 'Store Type', 'Region', 'Province'];

    this.storeList = filterDropdown.selectedPromotionStore;
    this.createForm();
    this.initialData();
    this.initState();
    this.getButton();
  }

  initialData() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT, MasterDataEnum.REGION, MasterDataEnum.STATE])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
        this.regionList = result[MasterDataEnum.REGION];
        this.stateList = result[MasterDataEnum.STATE];
      });

    this.pageSize = 20;
    this.itemPage = 1;
  }

  initState() {
    if (this.canEditView) {
      this.setPromotionStoreValue();
    }
  }

  setPromotionStoreValue() {
    this.promotionRequestView$ = this.localStore.pipe(select(selectRequestPromotionData));
    this.promotionRequestView$.pipe(filter(data => Boolean(data))).subscribe(data => {
      if (data.stores && data.stores.length) {
        data.stores.forEach(store => {
          const itemPatched = { ...store };
          this.formStores.push(this.createFormArray());
          const controlItem = this.formControlStore[this.formControlStore.length - 1];
          controlItem.patchValue(itemPatched);
        });
        this.formStores.controls.sort(this.sortList);
      }

      const casePageView =
        !Boolean(data.selectStore) && !Boolean(data.stores) && this.mode === PromotionRequestPageModes.VIEW;
      const casePageRqView =
        !Boolean(data.selectStore) && !Boolean(data.stores) && this.mode === PromotionRequestPageModes.REQUEST_VIEW;
      const isStatus = ['W', 'A', 'R', 'C', 'D'].includes(data.status);

      if (casePageView || (casePageRqView && isStatus)) {
        this.form.get(FORM_PARAMS.selectStore).setValue(SelectedPromotionStoreEnum.ALL_STORE);
      } else {
        this.form.get(FORM_PARAMS.selectStore).setValue(data.selectStore);
      }
      this.checkIsSelectedByStores();
    });

    if ([PromotionRequestPageModes.REQUEST_VIEW, PromotionRequestPageModes.VIEW].includes(this.mode)) {
      this.form.disable();
    } else {
      this.toggleEditPromotionStore();
    }
  }

  sortList(a: AbstractControl, b: AbstractControl) {
    const aStoreCode = a.value.code.toLowerCase();
    const bStoreCode = b.value.code.toLowerCase();
    if (aStoreCode < bStoreCode) {
      return -1;
    }
    if (aStoreCode > bStoreCode) {
      return 1;
    }
    return 0;
  }

  toggleEditPromotionStore() {
    this.mode = PromotionRequestPageModes.REQUEST_EDIT;
    this.getButton();
    this.form.enable();
    this.checkIsSelectedByStores();
  }

  checkIsSelectedByStores() {
    if (this.form.controls[FORM_PARAMS.selectStore].value === SelectedPromotionStoreEnum.SELECT_BY_STORE) {
      this.isSelectedByStores = true;
    }
  }

  createForm() {
    this.form.addControl(
      FORM_PARAMS.selectStore,
      new UntypedFormControl(
        {
          value: null,
          disabled: false
        },
        { validators: [Validators.required] }
      )
    );
    this.form.addControl(FORM_PARAMS.stores, this.fb.array([]));
  }

  get form(): UntypedFormGroup {
    return this.parentForm.get(FORM_PARAMS.promotionStores) as UntypedFormGroup;
  }

  get formStores(): UntypedFormArray {
    return this.form.get(FORM_PARAMS.stores) as UntypedFormArray;
  }

  get formControlStore(): AbstractControl[] {
    return (this.form.get(FORM_PARAMS.stores) as UntypedFormArray).controls;
  }

  createFormArray() {
    const initialNull = [{ initialValue: { value: null, disabled: false } }];
    return this.fb.group({
      code: initialNull,
      no: initialNull,
      name: initialNull,
      merchant: initialNull,
      warehouse: initialNull,
      merchantType: initialNull,
      region: initialNull,
      state: initialNull
    });
  }

  onChangeStoreList($event) {
    this.isSelectedByStores = $event.value === SelectedPromotionStoreEnum.SELECT_BY_STORE;

    if ($event.value !== SelectedPromotionStoreEnum.SELECT_BY_STORE && this.formStores.length > 0) {
      this.form.get(FORM_PARAMS.selectStore).setValue($event.value);

      const initialState = {
        title: 'Confirm',
        message: `Are you sure you want to change to select all stores? <br/>Your selected store will be deleted.`
      };
      const alertModal = this.modalService.show(ConfirmModalComponent, { initialState, backdrop: 'static' });

      alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
        switch (result) {
          case ModalButtonResponseEnum.OK:
            this.formStores.clear();
            break;
          case ModalButtonResponseEnum.CANCEL:
            this.isSelectedByStores = true;
            this.form.get(FORM_PARAMS.selectStore).setValue(SelectedPromotionStoreEnum.SELECT_BY_STORE);
            break;
          default:
            break;
        }
        alertModal.hide();
      });
    }
  }

  openSelectByStore() {
    const initialState = {
      parentForm: this.form,
      controlName: FORM_PARAMS.stores
    };
    this.modalService.show(SelectByStoreComponent, {
      initialState,
      backdrop: 'static'
    });
  }

  get canEdit(): boolean {
    return [PromotionRequestPageModes.REQUEST_CREATE, PromotionRequestPageModes.REQUEST_EDIT].includes(this.mode);
  }

  get canEditView(): boolean {
    return [
      PromotionRequestPageModes.REQUEST_VIEW,
      PromotionRequestPageModes.REQUEST_EDIT,
      PromotionRequestPageModes.VIEW
    ].includes(this.mode);
  }

  get getFormParams() {
    return FORM_PARAMS;
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.IMPORT,
        name: 'Import',
        hidden: ![PromotionRequestPageModes.REQUEST_CREATE, PromotionRequestPageModes.REQUEST_EDIT].includes(
          this.mode
        )
      },
      {
        type: ButtonType.EXPORT,
        name: 'Export',
        hidden: ![PromotionRequestPageModes.REQUEST_VIEW, PromotionRequestPageModes.VIEW].includes(this.mode)
      }
    ];
  }

  checkExistingItem() {
    if (this.formStores.value.length > 0) {
      this.alertConfirmUploadModal();
    } else {
      this.showPurchaseRequestImportModal();
    }
  }

  checkExistingItemExport() {
    if (!this.promotion && !this.promotion.id) {
      this.alertFailedModal('No item to be exported.');
    } else {
      if (PromotionRequestPageModes.VIEW === this.mode) {
        this.onExportStore();
      } else {
        this.onExportStoreRequest();
      }
    }
  }

  alertConfirmUploadModal(
    initialState = {
      title: 'Confirm',
      message: 'Are you sure you want to import new file? All existing data will be lost.',
      okText: 'OK'
    }
  ) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.showPurchaseRequestImportModal();
        }
      });
  }

  showPurchaseRequestImportModal() {
    this.modalRef = this.modalService.show(PromotionModalUploadComponent, {
      backdrop: 'static',
      keyboard: false
    });

    this.modalRef.content.submitUpload.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.onLoadStoreItems(result.stores);
      this.alertSuccessModal('The data have been imported.');
    });
  }

  onLoadStoreItems(storeItems) {
    if (this.formControlStore.length) {
      this.formStores.clear();
    }
    storeItems.forEach(item => {
      this.formStores.push(this.createFormArray());
      const controlItem = this.formControlStore[this.formControlStore.length - 1];
      controlItem.patchValue(item);
      this.formControlStore.sort(this.sortList);
    });
  }

  alertSuccessModal(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message
      }
    });
  }

  alertFailedModal(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  generateExportedFileName() {
    const promotionRequestNo = this.promotion.requestNo ? this.promotion.requestNo : this.promotion.promotionCode;
    return [PromotionRequestStatusEnum.DRAFT].includes(this.status) && !promotionRequestNo
      ? `${environment.fileName.exportPromotionStoreRequest.prefix} ${this.timeToExport}`
      : `${promotionRequestNo} (${environment.fileName.exportPromotionStoreRequest.prefix}) ${this.timeToExport}`;
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportPromotionStoreRequest.timeFormat);
  }

  onExportStoreRequest() {
    const id = this.promotion.id;
    this.promotionRequestService.onExportStoreRequest(id).subscribe({
      next: response => {
        const blob = new Blob([response]);
        saveAs(blob, `${this.generateExportedFileName()}.xlsx`);
      },
      error: () => {
        this.alertFailedModal('No item to be exported.');
      }
    });
  }

  onExportStore() {
    const promotionCode = this.promotion.promotionCode;
    this.promotionService.onExportStore(promotionCode).subscribe(response => {
      const blob = new Blob([response]);
      saveAs(blob, `${this.generateExportedFileName()}.xlsx`);
    });
  }
}
