import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { ErrorResponse } from '../../models';
import {
  OrderInventoryCreateRequest,
  OrderInventoryRequestViewResponse
} from '../../models/order-request-inventory.model';
import {
  OrderInventoryRequestActionType,
  OrderInventoryRequestActions
} from '../actions/order-inventory-request.actions';
import { BaseState } from '../state/base.state';

export interface OrderInventoryCreateResponseState extends EntityState<OrderInventoryCreateRequest>, BaseState {
  isLoading: boolean;
  isSave?: boolean | null;
  isApproveRejectSuccess?: boolean | null;
  result?: {
    response: OrderInventoryRequestViewResponse | null;
    errorResponse: ErrorResponse | null;
  };
}

export const adapter: EntityAdapter<OrderInventoryCreateRequest> = createEntityAdapter<OrderInventoryCreateRequest>();

export const initialOrderInventoryCreateResponseState: OrderInventoryCreateResponseState = adapter.getInitialState({
  isLoading: false,
  isSave: null,
  isApproveRejectSuccess: null
});

export function OrderInventoryCreateReducers(
  state = initialOrderInventoryCreateResponseState,
  action: OrderInventoryRequestActions
): OrderInventoryCreateResponseState {
  switch (action.type) {
    case OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SAVE_RESPONSE:
    case OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SUBMIT_RESPONSE:
      return {
        ...state,
        isSave: OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SAVE_RESPONSE === action.type,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    case OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SUBMIT_ERROR:
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    case OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_APPROVE_RESPONSE:
      return {
        ...state,
        isApproveRejectSuccess: action.payload.isSuccess
      };
    case OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_RESET:
      return initialOrderInventoryCreateResponseState;
    default: {
      return state;
    }
  }
}
