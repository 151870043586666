import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HistogramMetric, MetricType, Metrics } from '../models/metrics';
import { NotifyError } from '../models/notify-error';
import { NotifyService } from '../services/notify.service';

@Injectable({
  providedIn: 'root'
})
export class MetricInterceptor implements HttpInterceptor {
  metricsBody: Metrics;
  requestTimestamp: number;
  // responseTimestamp: number;
  constructor(private notifyService: NotifyService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has('metrics')) {
      this.metricsBody = JSON.parse(request.headers.get('metrics'));
      const modifiedReq = request.clone({
        headers: request.headers.delete('metrics')
      });
      this.requestTimestamp = new Date().getTime();
      return next.handle(modifiedReq).pipe(
        tap(responseRequest => {
          // const response = val as HttpResponse<any>;
          if (responseRequest instanceof HttpResponse && responseRequest.status === 200) {
            const payload = this.preparePayload(this.metricsBody, request, responseRequest);
            this.notifyService
              .sendMetrics(payload)
              .pipe(catchError(() => of(EMPTY)))
              .subscribe();
          }
        }),
        catchError(err => {
          const error: HttpErrorResponse = err;
          const payload = this.preparePayload(this.metricsBody, request, error);
          this.notifyService
            .sendMetrics(payload)
            .pipe(catchError(() => of(EMPTY)))
            .subscribe();

          return throwError(err);
        })
      );
    }

    if (request.headers.has('x-notify')) {
      const notifyBody = JSON.parse(request.headers.get('x-notify')) as NotifyError;
      const modifiedReq = request.clone({
        headers: request.headers.delete('x-notify')
      });
      this.notifyService.sendNotify(notifyBody.module, notifyBody.message, notifyBody.status, null);

      return next.handle(modifiedReq).pipe(
        tap(responseRequest => {
          if (responseRequest instanceof HttpResponse && responseRequest.status === 200) {
            this.notifyService.sendNotify(notifyBody.module, notifyBody.message, notifyBody.status, null);
          }
        })
      );
    }

    return next.handle(request);
  }

  preparePayload(
    metricsBody: Metrics,
    headerRequest: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse
  ) {
    let responseTime = new Date().getTime() - this.requestTimestamp;

    const metrics = metricsBody.metrics.map(element => {
      if (element.metricType.includes(MetricType.HISTOGRAM)) {
        if (
          response.url.match(/\/supplier-price-configs\/(TD|SUPPLIER)\/import/g) &&
          response.status === 200 &&
          response['body'].length
        ) {
          element = { ...element, items: response['body'].length };
        } else if (
          response.url.match(/\/supplier-price-configs\?importId=.*showSuccess=true/g) &&
          response.status === 200
        ) {
          element = { ...element, items: response['body'].totalElements };
        } else if (response.url.match(/\/z8parameters\/submit/g) && response.status === 200) {
          element = { ...element };
        } else if (!(element as HistogramMetric).items) {
          return null;
        }
      }

      if (element.metricType.includes(MetricType.COUNTER)) {
        element['method'] = headerRequest.method;
        element['statusCode'] = response.status;
        element['responseTime'] = responseTime;
        console.log('Time taken ' + responseTime + 'milli seconds');
      }
      if (element.metricType.includes(MetricType.RESPONSE_TIME)) {
        element['method'] = headerRequest.method;
        element['duration'] = responseTime;
        console.log('Time taken ' + responseTime + 'milli seconds');
      }
      return element;
    });

    responseTime = 0;
    metricsBody = { ...metricsBody, metrics };
    return metricsBody;
  }
}
