import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ExportFileTypeEnum } from '@shared/enum/export-file-type.enum';
import {
  ExportStockByLocationCriteria,
  ExportStockByProductCriteria,
  StoreExportCriteria,
  StoreExportOrderScheduleCriteria
} from '@shared/models';
import { GoodsReceiveExportCriteria } from '@shared/models/goods-receive.model';
import { ExportTypeEnum } from '@shared/models/import-export-button.model';
import { ProfitSharingExportCriteria } from '@shared/models/profit-sharing.model';
import { RewardCatalogExportCriteria } from '@shared/models/reward-catalog.model';
import { StockTransferGRExportCriteria } from '@shared/models/stock-transfer-gr.model';
import { StockTransferExportCriteria } from '@shared/models/stock-transfer.model';
import { DestroyService } from '@shared/services/destroy.service';
import { ExportReportService } from '@shared/services/export-report.service';
import { HoldAssortmentReportService } from '@shared/services/hold-assortment-report.service';
import { InventoryCountTransactionService } from '@shared/services/inventory-count-transaction.service';
import { MemberTransactionRewardsService } from '@shared/services/member-transaction-report.service';
import { OnlineCouponTransactionService } from '@shared/services/online-coupon-transaction.service';
import { PointEarnAndBurnReportService } from '@shared/services/point-earn-and-burn-report.service';
import { PointEarnTransactionReport } from '@shared/services/point-earn-transaction.service';
import { ProfitSharingService } from '@shared/services/profit-sharing.service';
import { PromotionReportService } from '@shared/services/promotion-report.service';
import { PurchaseOrderService } from '@shared/services/purchase-order.service';
import { PurchaseRequestService } from '@shared/services/purchase-request.service';
import { ReceiveOrderService } from '@shared/services/receive-order.service';
import { RefundTransactionConfidentialReport } from '@shared/services/refund-transaction-confidential.service';
import { RefundService } from '@shared/services/refund.service';
import { RewardCatalogService } from '@shared/services/reward-catalog.service';
import { RewardOfferTransactionReportService } from '@shared/services/reward-offer-transaction-report.service';
import { RewardRedeemTransactionReportService } from '@shared/services/reward-redeem-transaction-report.service';
import { SalesTransactionConfidentialReportService } from '@shared/services/sales-transaction-confidential-report.service';
import { SpecialPointReportService } from '@shared/services/special-point-report.service';
import { StockInformationService } from '@shared/services/stock-information.service';
import { StoreExportService } from '@shared/services/store-export.service';
import { StoreLocationReportService } from '@shared/services/store-location-report.service';
import { StoreReportService } from '@shared/services/store-report.service';
import { SupplierCouponReconcileReportService } from '@shared/services/supplier-coupon-reconcile-report.service';
import { TaskAssignmentReportService } from '@shared/services/task-assignment-report.service';
import { VoucherReportService } from '@shared/services/voucher-report.service';
import { Z8HoldRequestService } from '@shared/services/z8-hold-request.service';
import {
  dateTimeToDateOnlyString,
  dateTimeToMonthOnlyString,
  dateToStringCriteria,
  formatDate
} from '@shared/utils/date-util';

import { environment } from '../../../../../environments/environment';
import { ClaimRequestExportCriteria } from '../../../../pages/claim-request/model/claim-request.model';
import { FlashSaleDashboardReportService } from '../../../../pages/flash-sale-dashboard/services/flash-sale-dashboard-report.service';
import { HoldOrderReportService } from '../../../../pages/hold-order/services/hold-order-report.service';
import { ExportAdjustCriteria } from '../../../../pages/manage-stock/models/adjust-stock.model';
import { NewReportService } from '../../../../pages/new-reports/services/new-report.service';
import {
  OnlineCouponExportCriteria,
  OnlineCouponSummaryCriteria
} from '../../../../pages/online-coupon/model/online-coupon.model';
import { HoldAssortmentRequestService } from '../../../../pages/permanent-hold/services/hold-assortment-request.service';
import { ReturnToWarehouseChargeService } from '../../../../pages/return-to-warehouse/services/return-to-warehouse-charge.service';
import { ReturnToWarehouseGRService } from '../../../../pages/return-to-warehouse/services/return-to-warehouse-gr.service';
import { ReturnToWarehouseOrderService } from '../../../../pages/return-to-warehouse/services/return-to-warehouse-order.service';
import { RewardExportCriteria } from '../../../../pages/reward/models/reward.model';
import { RewardService } from '../../../../pages/reward/services/reward.service';
import { StoreAssortmentService } from '../../../../pages/store-assortment/services/store-assortment.service';
import { StoreConsultantExportCriteria } from '../../../../pages/store-consultant/models/store-consultant.model';
import { StoreConsultantService } from '../../../../pages/store-consultant/services/store-consultant.service';
import { ToteReportService } from '../../../../pages/tote-information/services/tote-report.service';
import { DeliveryOrderExportPickingCriteria } from '../../../models/delivery-order.model';
import { OrderExportCriteria } from '../../../models/order.model';
import { HoldAssortmentExportCriteria } from '../../../models/permanent-hold.model';
import { ReceiveOrderExportCriteria } from '../../../models/receive-order.model';
import {
  AwaitingFirstLotExportCriteria,
  BillPaymentReportCriteria,
  DestroyDocumentReportCriteria,
  DestroyTransactionReportCriteria,
  InventoryAdjustmentRequestReportExportCriteria,
  InventoryCountTransactionReportExportCriteria,
  InventoryCountTransactionTempReportExportCriteria,
  InventoryVarianceCriteria,
  MemberRewardTransactionReportCriteria,
  MovingAverageReportExportCriteria,
  OnlineCouponCompensateCriteria,
  OnlineCouponTransactionCriteria,
  OrderProcessingHoldCriteria,
  PointEarnTransactionReportCriteria,
  PreOrderCatalogConfidentialCriteria,
  PreOrderCatalogExportCriteria,
  PreOrderCompensateReportCriteria,
  ReceiveOrderExceedReportExportCriteria,
  RefundExportCriteria,
  SalesSummaryReportExportCriteria,
  SalesTransactionExportCriteria,
  SpecialPointCompensateReportCriteria,
  StockCardExportCriteria,
  StoreLocationByDcReportCriteria,
  SupplierCouponReconcileReportCriteria,
  UserReportCriteria,
  VoucherExportCriteria
} from '../../../models/report.model';
import { ProductAssortmentService } from '../../../services';
import { AwaitingAllocationForGroupOrderService } from '../../../services/awaiting-allocation-for-group-order.service';
import { ClaimReportService } from '../../../services/claim-report.service';
import { DeliveryOrderService } from '../../../services/delivery-order.service';
import { GroupOrderReportService } from '../../../services/group-order-report.service';
import { MovingAverageReportService } from '../../../services/moving-average-report.service';
import { OnlineCouponCompensateService } from '../../../services/online-coupon-compensate.service';
import { OrderService } from '../../../services/order.service';
import { PreOrderReportService } from '../../../services/pre-order-report.service';
import { PreOrderTransactionsReportService } from '../../../services/pre-order-transactions-report.service';
import { PromotionCompensateReportService } from '../../../services/promotion-compensate-report.service';
import { SalesSummaryReportService } from '../../../services/sales-summary-report.service';
import { SalesTransactionReportService } from '../../../services/sales-transaction-report.service';
import { ShelfFixAssetService } from '../../../services/shelf-fix-asset.service';
import { ShelfInventoryService } from '../../../services/shelf-inventory.service';
import { ShipmentService } from '../../../services/shipment.service';
import { SpecialPointCompensateService } from '../../../services/special-point-compensate.service';
import { StoreService } from '../../../services/store.service';
import { UsersService } from '../../../services/users.service';
import { dynamicReportAction } from './dynamic-reports.actions';

@Injectable()
export class DynamicReportsEffects {
  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private storeService: StoreService,
    private holdAssortmentReportService: HoldAssortmentReportService,
    private groupOrderReportService: GroupOrderReportService,
    private memberTransactionRewardsService: MemberTransactionRewardsService,
    private awaitingAllocationForGroupOrderService: AwaitingAllocationForGroupOrderService,
    private storeReportService: StoreReportService,
    private z8HoldRequestService: Z8HoldRequestService,
    private receiveOrderService: ReceiveOrderService,
    private preOrderTransactionsReportService: PreOrderTransactionsReportService,
    private promotionCompensateReportService: PromotionCompensateReportService,
    private promotionReportService: PromotionReportService,
    private salesSummaryReportService: SalesSummaryReportService,
    private salesTransactionService: SalesTransactionReportService,
    private supplierCouponReconcileReportService: SupplierCouponReconcileReportService,
    private inventoryCountTransactionService: InventoryCountTransactionService,
    private voucherReportService: VoucherReportService,
    private destroyService: DestroyService,
    private specialPointCompensateService: SpecialPointCompensateService,
    private specialPointReportService: SpecialPointReportService,
    private taskAssignmentReportService: TaskAssignmentReportService,
    private shipmentService: ShipmentService,
    private holdAssortmentRequestService: HoldAssortmentRequestService,
    private toteReportService: ToteReportService,
    private returnToWarehouseOrderService: ReturnToWarehouseOrderService,
    private returnToWarehouseGRService: ReturnToWarehouseGRService,
    private returnToWarehouseChargeService: ReturnToWarehouseChargeService,
    private exportReportService: ExportReportService,
    private flashSaleDashboardReportService: FlashSaleDashboardReportService,
    private onlineCouponTransactionService: OnlineCouponTransactionService,
    private onlineCouponCompensateService: OnlineCouponCompensateService,
    private rewardRedeemTransactionReportService: RewardRedeemTransactionReportService,
    private rewardOfferTransactionReportService: RewardOfferTransactionReportService,
    private orderService: OrderService,
    private salesTransactionConfidentialReportService: SalesTransactionConfidentialReportService,
    private refundService: RefundService,
    private refundTransactionConfidentialReport: RefundTransactionConfidentialReport,
    private deliveryOrderService: DeliveryOrderService,
    private claimService: ClaimReportService,
    private stockInformationService: StockInformationService,
    private usersService: UsersService,
    private profitSharingService: ProfitSharingService,
    private storeConsultantService: StoreConsultantService,
    private storeExportService: StoreExportService,
    private storeLocationReportService: StoreLocationReportService,
    private productAssortmentService: ProductAssortmentService,
    private storeAssortmentService: StoreAssortmentService,
    private movingAverageReportService: MovingAverageReportService,
    private preorderReportService: PreOrderReportService,
    private shelfFixAssetService: ShelfFixAssetService,
    private shelfInventoryService: ShelfInventoryService,
    private holdOrderReportService: HoldOrderReportService,
    private rewardService: RewardService,
    private pointEarnTransactionReportService: PointEarnTransactionReport,
    private pointEarnAndBurnReportService: PointEarnAndBurnReportService,
    private rewardCatalogService: RewardCatalogService,
    private purchaseRequestService: PurchaseRequestService,
    private purchaseOrderService: PurchaseOrderService,
    private newReportService: NewReportService
  ) {}

  exportMoneyIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.moneyIn),
      tap(action => this.logger.debug('@Effect Export Money In : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.exportReportService.exportMoneyIn(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportToteAdjustment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.toteAdjustment),
      tap(action => this.logger.debug('@Effect Export Tote Adjustment : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitListExceeded(payload?.storeCodes, 100, 'locations');
        if (validateLimitStore) {
          return validateLimitStore;
        }

        return this.toteReportService.toteAdjustmentReport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportHoldAssortment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.holdAssortment),
      tap(action => this.logger.debug('@Effect Export Hold Assortment : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.store);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        const attributes = [];
        if (payload.store) {
          attributes.push({
            id: 'store_code',
            name: 'Store Code',
            type: 'TEXT',
            operator: 'IN',
            values: payload.store.join(',')
          });
        }

        if (payload.storeType && payload.storeType.length !== 0) {
          attributes.push({
            id: 'store_type',
            name: 'Store Type',
            type: 'TEXT',
            operator: 'IN',
            values: payload.storeType.join(',')
          });
        }
        const reportCriteria = new HoldAssortmentExportCriteria({
          fileType: payload.fileType,
          attributes,
          createdDateFrom: payload.createdDateFrom
            ? dateTimeToDateOnlyString(new Date(payload.createdDateFrom))
            : null,
          createdDateTo: payload.createdDateTo ? dateTimeToDateOnlyString(new Date(payload.createdDateTo)) : null
        });
        return this.holdAssortmentReportService.reportExport(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportMemberRewardTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.memberRewardTransactions),
      tap(action =>
        this.logger.debug('@Effect Export Member Reward Transaction : ' + JSON.stringify(action.payload))
      ),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.store);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        const reportCriteria = new MemberRewardTransactionReportCriteria({
          transactionDateFrom: formatDate(payload.memberRewardTransactionDateFrom, 'YYYY-MM-DD'),
          transactionDateTo: formatDate(payload.memberRewardTransactionDateTo, 'YYYY-MM-DD'),
          storeCodes: payload.store,
          fileType: payload.fileType
        });
        return this.memberTransactionRewardsService.exportMemberRewardTransaction(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportMemberRewardTransactionWithPrice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.memberRewardTransactionsWithPrice),
      tap(action =>
        this.logger.debug('@Effect Export Member Reward Transaction With Price : ' + JSON.stringify(action.payload))
      ),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.store);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        const reportCriteria = new MemberRewardTransactionReportCriteria({
          transactionDateFrom: formatDate(payload.memberRewardTransactionDateFrom, 'YYYY-MM-DD'),
          transactionDateTo: formatDate(payload.memberRewardTransactionDateTo, 'YYYY-MM-DD'),
          storeCodes: payload.store,
          fileType: payload.fileType
        });
        return this.memberTransactionRewardsService.exportMemberRewardTransactionWithPrice(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportGroupOrderUnpickedItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.groupOrderUnpickedItem),
      tap(action =>
        this.logger.debug('@Effect Export Group Order Unpicked Item : ' + JSON.stringify(action.payload))
      ),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore((payload?.storeCode as any) as string[]);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        return this.groupOrderReportService.exportUnpickedItemReport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportAwaitingAllocationForGroupOrderReportModalComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.awaitingAllocationForGroupOrder),
      tap(action =>
        this.logger.debug('@Effect Export Awaiting Allocation For GroupOrder : ' + JSON.stringify(action.payload))
      ),
      switchMap(({ payload }) => {
        return this.awaitingAllocationForGroupOrderService.exportAwaitingAllocation(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportBillPayment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.billPayment),
      tap(action => this.logger.debug('@Effect Bill Payment : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.store);
        if (validateLimitStore) return validateLimitStore;

        const reportCriteria = new BillPaymentReportCriteria({
          saleDateFrom: formatDate(payload.saleDateFrom, 'YYYY-MM-DD'),
          saleDateTo: formatDate(payload.saleDateTo, 'YYYY-MM-DD'),
          paymentDateFrom: formatDate(payload.paymentDateFrom, 'YYYY-MM-DD'),
          paymentDateTo: formatDate(payload.paymentDateTo, 'YYYY-MM-DD'),
          store: payload.store && payload.store.length > 0 ? payload.store.toString() : payload.store,
          status: payload.status && payload.status.length > 0 ? payload.status.toString() : null,
          fileType: payload.fileType
        });

        return this.storeReportService.exportBillPayment(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportOrderProcessingHold$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.orderProcessingHold),
      tap(action => this.logger.debug('@Effect Order Processing Hold : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.store as any);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        const orderProcessingHoldCriteria = new OrderProcessingHoldCriteria({
          requestedDateFrom: formatDate(payload.requestedDateFrom, 'YYYY-MM-DD'),
          requestedDateTo: formatDate(payload.requestedDateTo, 'YYYY-MM-DD'),
          store: payload.store && payload.store.length > 0 ? payload.store.toString() : '',
          delistSuggestion:
            payload.delistSuggestion && payload.delistSuggestion.length > 0
              ? (payload.delistSuggestion as string[]).toString()
              : '',
          fileType: payload.fileType
        });
        return this.z8HoldRequestService.reportExport(orderProcessingHoldCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportReceiveOrderExceed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.receiveOrderExceed),
      tap(action => this.logger.debug('@Effect Receive Order Exceed : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const receiveOrderExceedReportExportCriteria = new ReceiveOrderExceedReportExportCriteria({
          receiveOrderDateFrom: dateToStringCriteria(payload.receiveOrderDateFrom, true),
          receiveOrderDateTo: dateToStringCriteria(payload.receiveOrderDateTo, false)
        });
        return this.receiveOrderService.exceedExport(receiveOrderExceedReportExportCriteria).pipe(
          map(response => {
            const { timeFormat, prefix } = environment.fileName.exportReceiveOrderExceed;
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPreOrderCompensate = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.preOrderCompensate),
      tap(action => this.logger.debug('@Effect Pre Order Compensate : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new PreOrderCompensateReportCriteria({
          transactionDateFrom: dateTimeToDateOnlyString(new Date(payload.transactionDateFrom)),
          transactionDateTo: dateTimeToDateOnlyString(new Date(payload.transactionDateTo)),
          storeCode: payload.storeCode,
          supplierCode: payload.supplierCode,
          fileType: payload.fileType
        });
        return this.preOrderTransactionsReportService.exportCompensate(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportOnlineCouponCompensate = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.onlineCouponCompensate),
      tap(action => this.logger.debug('@Effect Online Coupon Compensate : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.storeCode);
        if (validateLimitStore) return validateLimitStore;

        return this.onlineCouponCompensateService.reportExport(payload as OnlineCouponCompensateCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportRedeemTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.redeemTransaction),
      tap(action => this.logger.debug('@Effect Redeem Transaction : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.rewardRedeemTransactionReportService.reportRewardRedeemTransaction(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportRewardTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.rewardTransaction),
      tap(action => this.logger.debug('@Effect Reward Transaction : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.rewardOfferTransactionReportService.reportRewardOfferTransaction(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPromotionCompensate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.promotionCompensate),
      tap(action => this.logger.debug('@Effect Promotion Compensate : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.promotionCompensateReportService.reportExport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportSalesSummary = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.salesSummary),
      tap(action => this.logger.debug('@Effect Sales Summary : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new SalesSummaryReportExportCriteria({
          createdDateFrom: dateTimeToDateOnlyString(new Date(payload.createdDateFrom)),
          createdDateTo: dateTimeToDateOnlyString(new Date(payload.createdDateTo)),
          storeType: payload.storeType
        });
        return this.salesSummaryReportService.reportExport(reportCriteria).pipe(
          map(response => {
            const { timeFormat, prefixReport } = environment.fileName.exportSalesSummary;
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefixReport} ${this.timeToExport(timeFormat)}.xlsx`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportSalesTransaction = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.salesTransaction),
      tap(action => this.logger.debug('@Effect Sales Transaction : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new SalesTransactionExportCriteria({
          transactionDateFrom: dateTimeToDateOnlyString(new Date(payload.transactionDateFrom)),
          transactionDateTo: dateTimeToDateOnlyString(new Date(payload.transactionDateTo)),
          store: payload.store,
          salesType: payload.salesType?.length > 0 ? ((payload.salesType as any) as string[]).join(',') : null,
          fileType: payload.fileType
        });
        return this.salesTransactionService.reportExport(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportSupplierCouponReconcile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.supplierCouponReconcile),
      tap(action => this.logger.debug('@Effect Supplier Coupon Reconcile : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.store);
        if (validateLimitStore) {
          return validateLimitStore;
        }
        const reportCriteria = new SupplierCouponReconcileReportCriteria({
          transactionDateFrom: dateToStringCriteria(payload.transactionDateFrom, true),
          transactionDateTo: dateToStringCriteria(payload.transactionDateTo, false),
          storeNo: payload.store && payload.store.length > 0 ? payload.store.toString() : ''
        });
        return this.supplierCouponReconcileReportService.reportExport(reportCriteria).pipe(
          map(response => {
            const { timeFormat, prefix } = environment.fileName.exportSupplierCouponReconcile;
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportVoucher$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.voucher),
      tap(action => this.logger.debug('@Effect Voucher : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const receiptDateFrom = payload.receiptDateFrom ? new Date(payload.receiptDateFrom) : null;
        const receiptDateTo = payload.receiptDateTo ? new Date(payload.receiptDateTo) : null;

        const voucherExportCriteria = new VoucherExportCriteria({
          receiptDateFrom: dateTimeToDateOnlyString(receiptDateFrom),
          receiptDateTo: dateTimeToDateOnlyString(receiptDateTo),
          fileType: payload.fileType
        });
        return this.voucherReportService.reportVoucher(voucherExportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportInventoryAdjustmentRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.inventoryAdjustmentRequest),
      tap(action => this.logger.debug('@Effect Inventory Adjustment Request : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const exportCriteria = new InventoryAdjustmentRequestReportExportCriteria({
          createdDateFrom: dateToStringCriteria(payload.createdDateFrom, true),
          createdDateTo: dateToStringCriteria(payload.createdDateTo, false),
          storeType:
            payload.storeType && payload.storeType.length > 0 ? payload.storeType.toString() : payload.storeType,
          storeNo: payload.storeNo,
          exportBy: 'count'
        });
        return this.storeService
          .exportAdjustmentRequestStore<InventoryAdjustmentRequestReportExportCriteria>(exportCriteria)
          .pipe(
            map(response => {
              const { timeFormat, prefix } = environment.fileName.exportInventoryAdjustmentRequest;
              return dynamicReportAction.exportReportFileResponse({
                isSuccess: true,
                response,
                fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
              });
            }),
            catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
          );
      })
    );
  });

  exportDestroyDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.destroyDocument),
      tap(action => this.logger.debug('@Effect Destroy Document : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const destroyDocumentReportCriteria = new DestroyDocumentReportCriteria({
          docNo: payload.docNo
        });
        return this.destroyService.exportDestroyDocument(destroyDocumentReportCriteria).pipe(
          map(response => {
            const { timeFormat, prefix } = environment.fileName.exportDestroyDocument;
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.pdf`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportInventoryVariance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.inventoryVariance),
      tap(action => this.logger.debug('@Effect Inventory Variance : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const exportCriteria = new InventoryVarianceCriteria({
          createdDate: dateToStringCriteria(payload.createdDate, true),
          storeNo: payload.storeNo
        });
        return this.storeService.exportInventoryVarianceReport(exportCriteria).pipe(
          map(response => {
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response: response.fileBase64String,
              fileName: `${response.fileName}.pdf`,
              extension: ExportFileTypeEnum.PDF
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportDestroyTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.destroyTransaction),
      tap(action => this.logger.debug('@Effect Destroy Transaction : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload.storeNo);
        if (validateLimitStore) {
          return validateLimitStore;
        }
        const destroyTransactionReportCriteria = new DestroyTransactionReportCriteria({
          createdDateFrom: dateToStringCriteria(payload.createdDateFrom, true),
          createdDateTo: dateToStringCriteria(payload.createdDateTo, false),
          storeType:
            payload.storeType && payload.storeType.length > 0 ? payload.storeType.toString() : payload.storeType,
          storeNo: payload.storeNo && payload.storeNo.length > 0 ? payload.storeNo.toString() : ''
        });
        return this.destroyService.exportDestroyTransaction(destroyTransactionReportCriteria).pipe(
          map(response => {
            const { timeFormat, prefix } = environment.fileName.exportDestroyTransaction;
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportInventoryCountTransactionTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.inventoryCountTransactions),
      tap(action => this.logger.debug('@Effect Inventory Count Transaction : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new InventoryCountTransactionReportExportCriteria({
          createdDateFrom: payload.createdDateFrom
            ? dateTimeToDateOnlyString(new Date(payload.createdDateFrom))
            : null,
          createdDateTo: payload.createdDateTo ? dateTimeToDateOnlyString(new Date(payload.createdDateTo)) : null,
          storeType: payload.storeType && payload.storeType.length > 0 ? payload.storeType.toString() : null,
          storeCodes: payload.storeCodes ? payload.storeCodes.toString().split(',') : null,
          fileType: payload.fileType
        });
        return this.inventoryCountTransactionService.exportInventoryCountTransaction(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportInventoryCountTransactionWithPriceTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.inventoryCountTransactionsWithPrice),
      tap(action =>
        this.logger.debug('@Effect Inventory Count Transaction With Price : ' + JSON.stringify(action.payload))
      ),
      switchMap(({ payload }) => {
        const reportCriteria = new InventoryCountTransactionTempReportExportCriteria({
          createdDateFrom: payload.createdDateFrom
            ? dateTimeToDateOnlyString(new Date(payload.createdDateFrom))
            : null,
          createdDateTo: payload.createdDateTo ? dateTimeToDateOnlyString(new Date(payload.createdDateTo)) : null,
          storeType: payload.storeType && payload.storeType.length > 0 ? payload.storeType.toString() : null,
          storeCodes: payload.storeCodes ? payload.storeCodes.toString().split(',') : null,
          fileType: payload.fileType
        });
        return this.inventoryCountTransactionService.exportInventoryCountTransactionWithPrice(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPreOrderTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.preOrderTransaction),
      tap(action => this.logger.debug('@Effect Pre Order Transaction : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.storeCodes);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        return this.preOrderTransactionsReportService.exportTransactions(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPreOrderTransactionWithPrice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.preOrderTransactionWithPrice),
      tap(action =>
        this.logger.debug('@Effect Pre Order Transaction With Price : ' + JSON.stringify(action.payload))
      ),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.storeCodes);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        return this.preOrderTransactionsReportService.exportTransactionsConfidential(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPromotion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.promotion),
      tap(action => this.logger.debug('@Effect Promotion : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.promotionReportService.exportPromotion(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportShipment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.shipment),
      tap(action => this.logger.debug('@Effect Shipment : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.shipmentService.exportShipment(payload).pipe(
          map(response => {
            const { timeFormat, prefix } = environment.fileName.exportShipment;
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix}${this.timeToExport(timeFormat)}.xlsx`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportSpecialPointCompensate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.specialPointCompensate),
      tap(action => this.logger.debug('@Effect Export Special Point Compensate : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore((payload?.storeNos as any) as string[]);
        if (validateLimitStore) {
          return validateLimitStore;
        }
        const reportCriteria = new SpecialPointCompensateReportCriteria({
          transactionDateFrom: dateTimeToDateOnlyString(new Date(payload.transactionDateFrom)),
          transactionDateTo: dateTimeToDateOnlyString(new Date(payload.transactionDateTo)),
          storeNos: payload.storeNos,
          supplierCode: payload.supplierCode,
          fileType: payload.fileType
        });
        return this.specialPointCompensateService.reportExport(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportSpecialPoint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.specialPoint),
      tap(action => this.logger.debug('@Effect Special Point : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimit = this.validateLimitListExceeded(payload.offerSupplierCode, 100, 'suppliers');

        if (validateLimit) {
          return validateLimit;
        }
        return this.specialPointReportService.specialPointExportReport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportResponse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.response),
      tap(action => this.logger.debug('@Effect Response : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.taskAssignmentReportService.responseExportReport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportSurvey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.survey),
      tap(action => this.logger.debug('@Effect Survey : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.taskAssignmentReportService.surveyExportReport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportReturnToWarehouseOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.returnToWarehouseOrder),
      tap(action => this.logger.debug('@Effect Return to Warehouse order : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.returnToWarehouseOrderService.exportReport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportReturnToWarehouseGR$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.returnToWarehouseGR),
      tap(action => this.logger.debug('@Effect Return to Warehouse GR : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.returnToWarehouseGRService.exportReport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportReturnToWarehouseCharge$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.returnToWarehouseCharge),
      tap(action => this.logger.debug('@Effect Return to Warehouse Charge : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.returnToWarehouseChargeService.exportReport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportFlashSaleOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.flashSaleOrder),
      tap(action => this.logger.debug('@Effect Flash Sale Order : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.storeCodes);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        return this.flashSaleDashboardReportService.exportReport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  OnlineCouponTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.onlineCouponTransaction),
      tap(action =>
        this.logger.debug('@Effect Export Online Coupon Transaction Report : ' + JSON.stringify(action.payload))
      ),
      switchMap(({ payload }) => {
        const onlineCouponTransactionCriteria = new OnlineCouponTransactionCriteria({
          transactionDateFrom: formatDate(payload.transactionDateFrom, 'YYYY-MM-DD'),
          transactionDateTo: formatDate(payload.transactionDateTo, 'YYYY-MM-DD'),
          supplierCode: payload.supplierCode,
          fileType: payload.fileType,
          couponType: payload.couponType
        });

        return this.onlineCouponTransactionService.reportExport(onlineCouponTransactionCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportTaskAssignment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.taskAssignment),
      tap(action => this.logger.debug('@Effect Task : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.exportReportService.exportTaskAssignmentReport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.order),
      tap(action => this.logger.debug('@Effect Order : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateOrderLimitStore(payload?.storeCode);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        const orderDateFrom = dateToStringCriteria(payload.orderDateFrom);
        const orderDateTo = dateToStringCriteria(payload.orderDateTo, false);

        const reportCriteria = new OrderExportCriteria({
          ...(orderDateFrom && { orderDateFrom }),
          ...(orderDateTo && { orderDateTo }),
          orderStatus: this.convertArrayFilter(payload.orderStatus),
          orderType: this.convertArrayFilter(payload.orderType),
          productType: this.convertArrayFilter(payload.productType),
          warehouseCode: this.convertArrayFilter(payload.warehouseCode),
          storeCode: this.convertArrayFilter(payload.storeCode),
          orderFlow: this.convertArrayFilter(payload.orderFlow),
          crossDockSupplier: this.convertArrayFilter(payload.crossDockSupplier),
          groupOrder: false
        });

        const { timeFormat, prefix } = environment.fileName.exportOrder;
        return this.orderService.exportOrder(reportCriteria).pipe(
          map(response => {
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportSalesTransactionConfidential$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.salesTransactionConfidential),
      tap(action => this.logger.debug('@Effect Sales Transaction Confidential : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new SalesTransactionExportCriteria({
          transactionDateFrom: formatDate(payload.transactionDateFrom, 'YYYY-MM-DD'),
          transactionDateTo: formatDate(payload.transactionDateTo, 'YYYY-MM-DD'),
          store: payload.store && payload.store.length > 0 ? payload.store.toString() : null,
          salesType: payload.salesType && payload.salesType.length > 0 ? payload.salesType.toString() : null,
          fileType: payload.fileType
        });

        return this.salesTransactionConfidentialReportService.reportExport(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportHoldOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.holdOrder),
      tap(action => this.logger.debug('@Effect Hold Order : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.storeCodes);
        if (validateLimitStore) {
          return validateLimitStore;
        }
        return this.holdOrderReportService.exportReport(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportRefundSummary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.refundSummary),
      tap(action => this.logger.debug('@Effect Refund Summary : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new RefundExportCriteria({
          transactionDateFrom: formatDate(payload.transactionDateFrom, 'YYYY-MM-DD'),
          transactionDateTo: formatDate(payload.transactionDateTo, 'YYYY-MM-DD'),
          refundTypes: payload.refundTypes && payload.refundTypes.length > 0 ? payload.refundTypes.toString() : null,
          fileType: payload.fileType
        });

        return this.refundService.exportRefund(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportRefundTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.refundTransaction),
      tap(action => this.logger.debug('@Effect Refund Transaction : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new RefundExportCriteria({
          transactionDateFrom: formatDate(payload.transactionDateFrom, 'YYYY-MM-DD'),
          transactionDateTo: formatDate(payload.transactionDateTo, 'YYYY-MM-DD'),
          refundTypes: payload.refundTypes && payload.refundTypes.length > 0 ? payload.refundTypes.toString() : null,
          fileType: payload.fileType
        });

        return this.refundTransactionConfidentialReport.reportExport(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportDeliveryOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.deliveryOrder),
      tap(action => this.logger.debug('@Effect Delivery Order : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const dateFormat = environment.dateFormat;
        const reportCriteria = new DeliveryOrderExportPickingCriteria({
          requestedDeliveryDateFrom: formatDate(payload.requestedDeliveryDateFrom, dateFormat),
          requestedDeliveryDateTo: formatDate(payload.requestedDeliveryDateTo, dateFormat),
          orderDateFrom: dateToStringCriteria(payload.orderDateFrom),
          orderDateTo: dateToStringCriteria(payload.orderDateTo, false),
          pickDateFrom: formatDate(payload.pickDateFrom, dateFormat),
          pickDateTo: formatDate(payload.pickDateTo, dateFormat),
          status: payload.status,
          warehouseCode: payload.warehouseCode,
          orderType: payload.orderType,
          orderFlow: this.convertArrayFilter(payload.orderFlow),
          crossDockSupplier: this.convertArrayFilter(payload.crossDockSupplier)
        });
        const { timeFormat, prefix } = environment.fileName.exportDo;
        return this.deliveryOrderService.exportPicking(reportCriteria).pipe(
          map(response => {
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportReceiveOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.receiveOrder),
      tap(action => this.logger.debug('@Effect Receive Order : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new ReceiveOrderExportCriteria({
          createdDateFrom: dateToStringCriteria(payload.createdDateFrom, true),
          createdDateTo: dateToStringCriteria(payload.createdDateTo, false),
          orderType:
            payload.orderType && payload.orderType.length > 0 ? payload.orderType.toString() : payload.orderType,
          storeNo: payload.storeNo && payload.storeNo.length > 0 ? payload.storeNo.toString() : payload.storeNo,
          warehouseCode: payload?.warehouseCode?.length > 0 ? payload.warehouseCode.toString() : payload.warehouseCode
        });
        const { timeFormat, prefix } = environment.fileName.exportReOrder;
        return this.receiveOrderService.export(reportCriteria).pipe(
          map(response => {
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportClaim$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.claim),
      tap(action => this.logger.debug('@Effect Claim : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new ClaimRequestExportCriteria({
          requestedDateFrom: payload.requestedDateFrom
            ? dateTimeToDateOnlyString(new Date(payload.requestedDateFrom))
            : null,
          requestedDateTo: payload.requestedDateFrom
            ? dateTimeToDateOnlyString(new Date(payload.requestedDateTo))
            : null,
          claimStatus: this.convertArrayFilter(payload.claimStatus),
          warehouseCodes: this.convertArrayFilter(payload.warehouseCodes),
          fileType: payload.fileType
        });
        return this.claimService.exportClaimRequest(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  awaitingFirstLot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.awaitingFirstLot),
      tap(action => this.logger.debug('@Effect Awaiting First Lot : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new AwaitingFirstLotExportCriteria({
          storeOpenDateFrom: dateToStringCriteria(payload.storeOpenDateFrom, true),
          storeOpenDateTo: dateToStringCriteria(payload.storeOpenDateTo, false),
          storeType: this.convertArrayFilter(payload.storeType),
          region: this.convertArrayFilter(payload.region),
          state: this.convertArrayFilter(payload.state)
        });
        const { timeFormat, prefix } = environment.fileName.exportAwaitingFirstLot;
        return this.orderService.exportAwaitingFirstLot(reportCriteria).pipe(
          map(response => {
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportStockByProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.stockByProduct),
      tap(action => this.logger.debug('@Effect Stock By Product : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimit = this.validateLimitListExceeded(payload.locations, 100, 'locations');
        if (validateLimit) {
          return validateLimit;
        }
        const reportCriteria = new ExportStockByProductCriteria({
          ...payload,
          locations: payload.locations ? payload.locations : null,
          exportBy: ExportTypeEnum.PRODUCT
        });

        const { timeFormat, prefixProduct } = environment.fileName.exportStock;
        return this.stockInformationService.exportStock(reportCriteria).pipe(
          map(response => {
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefixProduct} ${this.timeToExport(timeFormat)}.xlsx`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportStockByLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.stockByLocation),
      tap(action => this.logger.debug('@Effect Stock By Location : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new ExportStockByLocationCriteria({
          ...payload,
          location: payload.location ? [payload.location] : null,
          productType: this.convertArrayFilter(payload.productType)
        });

        const { timeFormat, prefixLocation } = environment.fileName.exportStock;
        return this.stockInformationService.exportStockByLocation(reportCriteria).pipe(
          map(response => {
            return dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefixLocation} ${this.timeToExport(timeFormat)}.xlsx`
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportStockAdjustment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.stockAdjustment),
      tap(action => this.logger.debug('@Effect Stock Adjustment : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new ExportAdjustCriteria({
          locationTypes: payload.locationTypes,
          adjustTypes: payload.adjustTypes,
          createdDateFrom: payload.createdDateFrom
            ? dateTimeToDateOnlyString(new Date(payload.createdDateFrom))
            : null,
          createdDateTo: payload.createdDateTo ? dateTimeToDateOnlyString(new Date(payload.createdDateTo)) : null,
          exportBy: 'adjust',
          fileType: payload.fileType
        });

        return this.stockInformationService.exportStockAdjustment(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportStockCard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.stockCard),
      tap(action => this.logger.debug('@Effect Stock Card : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new StockCardExportCriteria({
          ...payload,
          documentDateFrom: dateToStringCriteria(payload.documentDateFrom, true),
          documentDateTo: dateToStringCriteria(payload.documentDateTo, false),
          exportBy: 'card'
        });

        const { timeFormat, prefixCard } = environment.fileName.exportStock;
        return this.stockInformationService.exportStockCard(reportCriteria).pipe(
          map(response =>
            dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefixCard} ${this.timeToExport(timeFormat)}.xlsx`
            })
          ),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportTransferOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.transferOrder),
      tap(action => this.logger.debug('@Effect Transfer Order : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new StockTransferExportCriteria({
          ...payload,
          orderDateFrom: payload.orderDateFrom ? dateTimeToDateOnlyString(new Date(payload.orderDateFrom)) : null,
          orderDateTo: payload.orderDateTo ? dateTimeToDateOnlyString(new Date(payload.orderDateTo)) : null,
          deliveryDateFrom: payload.deliveryDateFrom
            ? dateTimeToDateOnlyString(new Date(payload.deliveryDateFrom))
            : null,
          deliveryDateTo: payload.deliveryDateTo ? dateTimeToDateOnlyString(new Date(payload.deliveryDateTo)) : null
        });

        return this.exportReportService.exportStockTransfer(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportTransferGoodsReceive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.transferGoodsReceive),
      tap(action => this.logger.debug('@Effect Transfer Goods Receive : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new StockTransferGRExportCriteria({
          ...payload,
          createdDateTo: dateTimeToDateOnlyString(new Date(payload.createdDateTo)),
          createdDateFrom: dateTimeToDateOnlyString(new Date(payload.createdDateFrom))
        });

        return this.exportReportService.onExportStockTransferGR(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.user),
      tap(action => this.logger.debug('@Effect User : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new UserReportCriteria({
          ...payload,
          merchantNo: '000001'
        });

        const { timeFormat, prefix } = environment.fileName.exportUserReport;
        return this.usersService.exportUserReport(reportCriteria).pipe(
          map(response =>
            dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            })
          ),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportProfitSharing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.profitSharing),
      tap(action => this.logger.debug('@Effect Profit Sharing : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new ProfitSharingExportCriteria({
          ...payload,
          scheduleFrom: dateTimeToMonthOnlyString(new Date(payload.scheduleFrom), true),
          scheduleTo: dateTimeToMonthOnlyString(new Date(payload.scheduleTo), false)
        });

        return this.profitSharingService.export(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportStoreConsultant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.storeConsultant),
      tap(action => this.logger.debug('@Effect Store Consultant : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new StoreConsultantExportCriteria({
          active: this.convertArrayFilter(payload.active),
          region: this.convertArrayFilter(payload.region),
          state: this.convertArrayFilter(payload.state)
        });

        const { timeFormat, prefix } = environment.fileName.exportStoreConsultant;
        return this.storeConsultantService.exportStoreConsultant(reportCriteria).pipe(
          map(response =>
            dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            })
          ),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.store),
      tap(action => this.logger.debug('@Effect Store : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new StoreExportCriteria({
          ...payload,
          storeType: this.convertArrayFilter(payload.storeType),
          status: this.convertArrayFilter(payload.status)
        });

        return this.storeExportService.exportStore(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportTdAssortment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.tdAssortment),
      tap(action => this.logger.debug('@Effect TD Assortment : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.exportReportService.exportAssortment(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportTdAssortmentConfidential$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.tdAssortmentConfidential),
      tap(action => this.logger.debug('@Effect TD Assortment Confidential : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.exportReportService.exportAssortmentConfidential(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportTdAssortmentLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.assortmentLocation),
      tap(action => this.logger.debug('@Effect Assortment Location : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.exportReportService.exportAssortmentLocation(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportDcAssortment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.dcAssortment),
      tap(action => this.logger.debug('@Effect DC Assortment : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.exportReportService.exportDcAssortment(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportMovingAverage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.movingAverage),
      tap(action => this.logger.debug('@Effect Moving Average : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new MovingAverageReportExportCriteria({
          ...payload,
          createdDateFrom: dateTimeToDateOnlyString(payload.createdDateFrom),
          createdDateTo: dateTimeToDateOnlyString(payload.createdDateTo)
        });

        const { timeFormat, prefix } = environment.fileName.exportMovingAverage;
        return this.movingAverageReportService.reportExport(reportCriteria).pipe(
          map(response =>
            dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            })
          ),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportStoreConfidential$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.storeConfidential),
      tap(action => this.logger.debug('@Effect Store Confidential : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new StoreExportCriteria({
          ...payload,
          storeType: this.convertArrayFilter(payload.storeType),
          status: this.convertArrayFilter(payload.status)
        });

        return this.storeExportService.exportStoreConfidential(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPreOrderCatalog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.preOrderCatalog),
      tap(action => this.logger.debug('@Effect Pre Order Catalog : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new PreOrderCatalogExportCriteria({
          ...payload,
          status: payload.status ? payload.status.join(',') : null,
          effectiveDateFrom: dateTimeToDateOnlyString(payload.exportEffectiveDateFrom),
          effectiveDateTo: dateTimeToDateOnlyString(payload.exportEffectiveDateTo),
          expireDateFrom: dateTimeToDateOnlyString(payload.exportExpireDateFrom),
          expireDateTo: dateTimeToDateOnlyString(payload.exportExpireDateTo),
          validDateFrom: dateTimeToDateOnlyString(payload.exportValidDateFrom),
          validDateTo: dateTimeToDateOnlyString(payload.exportValidDateTo),
          fileType: payload.fileType
        });

        return this.preorderReportService.exportCatalog(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportStoreOrderSchedule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.storeOrderSchedule),
      tap(action => this.logger.debug('@Effect Store Order Schedule : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new StoreExportOrderScheduleCriteria({
          ...payload,
          scheduleType: this.convertArrayFilter(payload.scheduleType),
          warehouseCode: this.convertArrayFilter(payload.warehouseCode),
          status: this.convertArrayFilter(payload.status)
        });

        return this.storeExportService.exportStoreOrderSchedule(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPreOrderCatalogConfidential$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.preOrderCatalogConfidential),
      tap(action => this.logger.debug('@Effect Pre Order Catalog Confidential : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new PreOrderCatalogConfidentialCriteria({
          ...payload,
          status: payload.status && payload.status.length > 0 ? payload.status.join(',') : null
        });

        return this.preorderReportService.reportConfidential(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportStoreLocationByDc$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.storeLocationByDc),
      tap(action => this.logger.debug('@Effect Store Order Schedule : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new StoreLocationByDcReportCriteria({
          ...payload
        });
        return this.storeLocationReportService.exportByDc(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportShelfFixAsset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.shelfFixAsset),
      tap(action => this.logger.debug('@Effect Shelf Fix Asset : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const { timeFormat, prefix } = environment.fileName.exportShelfFixAsset;
        return this.shelfFixAssetService.exportShelf(payload.no, payload.status).pipe(
          map(response =>
            dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            })
          ),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportShelfInventory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.shelfInventory),
      tap(action => this.logger.debug('@Effect Shelf Inventory : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const { timeFormat, prefix } = environment.fileName.exportShelfInventory;
        return this.shelfInventoryService.exportShelf(payload.no, payload.status).pipe(
          map(response =>
            dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            })
          ),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportStoreAssortment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.storeAssortment),
      tap(action => this.logger.debug('@Effect Store Assortment : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateOrderLimitStore(payload?.storeNo);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        return this.exportReportService.exportByStore(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportReward$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.reward),
      tap(action => this.logger.debug('@Effect Reward : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new RewardExportCriteria({
          ...payload,
          validDateFrom: dateToStringCriteria(payload.validDateFrom),
          validDateTo: dateToStringCriteria(payload.validDateTo, false),
          offerFromDateFrom: dateToStringCriteria(payload.offerFromDateFrom),
          offerFromDateTo: dateToStringCriteria(payload.offerFromDateTo, false),
          offerToDateFrom: dateToStringCriteria(payload.offerToDateFrom),
          offerToDateTo: dateToStringCriteria(payload.offerToDateTo, false)
        });

        const { timeFormat, prefix } = environment.fileName.exportReward;
        return this.rewardService.exportReward(reportCriteria).pipe(
          map(response =>
            dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            })
          ),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPointEarnTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.pointEarnTransaction),
      tap(action => this.logger.debug('@Effect Point Earn Transaction : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.storeCodes);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        const reportCriteria = new PointEarnTransactionReportCriteria({
          ...payload,
          transactionDateFrom: dateTimeToDateOnlyString(new Date(payload.transactionDateFrom)),
          transactionDateTo: dateTimeToDateOnlyString(new Date(payload.transactionDateTo)),
          storeCodes: payload.storeCodes ? payload.storeCodes.toString().split(',') : null
        });
        return this.pointEarnTransactionReportService.reportExport(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPointBurnTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.pointBurnTransaction),
      tap(action => this.logger.debug('@Effect Point Burn Transaction : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitStore(payload?.storeCode);
        if (validateLimitStore) {
          return validateLimitStore;
        }

        const rawData = {
          startDate: dateToStringCriteria(payload.transactionDateFrom),
          endDate: dateToStringCriteria(payload.transactionDateTo, false),
          storeCode: payload.storeCode ? payload.storeCode.toString().split(',') : null
        };
        const { timeFormat, prefix } = environment.fileName.exportPointBurnTransactionItem;
        return this.pointEarnAndBurnReportService.exportPointBurnTransactionReport(rawData).pipe(
          map(response =>
            dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            })
          ),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportOnlineCoupon$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.onlineCoupon),
      tap(action => this.logger.debug('@Effect Online Coupon : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new OnlineCouponExportCriteria({
          ...payload,
          offerDateFrom: payload.offerDateFrom ? dateTimeToDateOnlyString(new Date(payload.offerDateFrom)) : null,
          offerDateTo: payload.offerDateTo ? dateTimeToDateOnlyString(new Date(payload.offerDateTo)) : null,
          redeemDateFrom: payload.redeemDateFrom ? dateTimeToDateOnlyString(new Date(payload.redeemDateFrom)) : null,
          redeemDateTo: payload.redeemDateTo ? dateTimeToDateOnlyString(new Date(payload.redeemDateTo)) : null
        });

        return this.exportReportService.exportOnlineCoupon(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportOnlineCouponSummary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.onlineCouponSummary),
      tap(action => this.logger.debug('@Effect Online Coupon Summary: ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new OnlineCouponSummaryCriteria({
          ...payload,
          docNo: payload.docNo ? payload.docNo : null,
          offerDateFrom: payload.offerDateFrom ? dateTimeToDateOnlyString(new Date(payload.offerDateFrom)) : null,
          offerDateTo: payload.offerDateTo ? dateTimeToDateOnlyString(new Date(payload.offerDateTo)) : null,
          redeemDateFrom: payload.redeemDateFrom ? dateTimeToDateOnlyString(new Date(payload.redeemDateFrom)) : null,
          redeemDateTo: payload.redeemDateTo ? dateTimeToDateOnlyString(new Date(payload.redeemDateTo)) : null,
          couponType: payload.couponType,
          status: payload.status,
          fileType: payload.fileType
        });

        return this.exportReportService.exportOnlineCouponSummary(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportMemberRewardCatalog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.memberRewardCatalog),
      tap(action => this.logger.debug('@Effect Member Reward Catalog : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const reportCriteria = new RewardCatalogExportCriteria({
          validDateFrom: dateToStringCriteria(payload.validDateFrom),
          validDateTo: dateToStringCriteria(payload.validDateTo, false),
          expireDateFrom: dateToStringCriteria(payload.expireDateFrom),
          expireDateTo: dateToStringCriteria(payload.expireDateTo, false),
          effectiveDateFrom: dateToStringCriteria(payload.effectiveDateFrom),
          effectiveDateTo: dateToStringCriteria(payload.effectiveDateTo, false),
          status: this.convertArrayFilter(payload.status)
        });

        const { timeFormat, prefix } = environment.fileName.exportRewardCatalogReport;
        return this.rewardCatalogService.exportRewardCatalog(reportCriteria).pipe(
          map(response =>
            dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            })
          ),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPurchaseRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.purchaseRequest),
      tap(action => this.logger.debug('@Effect Purchase Request : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const { timeFormat, prefix } = environment.fileName.exportPr;
        return this.purchaseRequestService.exportPr(payload).pipe(
          map(response =>
            dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            })
          ),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPurchaseOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.purchaseOrder),
      tap(action => this.logger.debug('@Effect Purchase Order : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const { timeFormat, prefix } = environment.fileName.exportPo;
        return this.purchaseOrderService.exportPo(payload).pipe(
          map(response =>
            dynamicReportAction.exportReportFileResponse({
              isSuccess: true,
              response,
              fileName: `${prefix} ${this.timeToExport(timeFormat)}.xlsx`
            })
          ),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportGoodsReceive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.goodsReceive),
      tap(action => this.logger.debug('@Effect Goods Receive : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitLocation = this.validateLimitListExceeded(payload?.locations, 100, 'locations');
        if (validateLimitLocation) {
          return validateLimitLocation;
        }
        const reportCriteria = new GoodsReceiveExportCriteria({
          ...payload,
          createdDateFrom: payload.createdDateFrom
            ? dateTimeToDateOnlyString(new Date(payload.createdDateFrom))
            : null,
          createdDateTo: payload.createdDateTo ? dateTimeToDateOnlyString(new Date(payload.createdDateTo)) : null
        });

        return this.newReportService.exportGoodsReceive(reportCriteria).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportPreOrderCreditRemaining$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.preOrderCreditRemaining),
      tap(action => this.logger.debug('@Effect Pre Order Credit Remaining : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.exportReportService.exportPreOrderCreditRemaining(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportStoreTote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.storeTote),
      tap(action => this.logger.debug('@Effect Store Tote : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitStore = this.validateLimitListExceeded(payload?.storeCodes, 100, 'stores');
        if (validateLimitStore) {
          return validateLimitStore;
        }

        return this.exportReportService.exportStoreTote(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportToteFine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.toteFine),
      tap(action => this.logger.debug('@Effect Tote Fine : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        const validateLimitLocation = this.validateLimitListExceeded(payload?.location, 100, 'locations');
        if (validateLimitLocation) {
          return validateLimitLocation;
        }

        return this.exportReportService.exportToteFine(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportToteInOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.toteInOut),
      tap(action => this.logger.debug('@Effect Tote In Out : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.exportReportService.exportToteInOut(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportToteInTracking$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.toteInTracking),
      tap(action => this.logger.debug('@Effect Tote In Tracking : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.exportReportService.exportToteInTracking(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportCrossDockInvoice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.crossDockInvoice),
      tap(action => this.logger.debug('@Effect Cross Dock Invoice : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.exportReportService.exportCrossDockInvoice(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  exportManualChangeDeliveryStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dynamicReportAction.manualChangeDeliveryStatus),
      tap(action => this.logger.debug('@Effect Manual Change Delivery Status : ' + JSON.stringify(action.payload))),
      switchMap(({ payload }) => {
        return this.exportReportService.exportManualChangeDeliveryStatus(payload).pipe(
          map(response => {
            const message = environment.alert.myReportRequest.replace('{request_no}', response.requestNo);
            return dynamicReportAction.exportReportResponse({
              isSuccess: true,
              response: { message }
            });
          }),
          catchError(error => of(dynamicReportAction.exportReportFileError({ isSuccess: false, error })))
        );
      })
    );
  });

  timeToExport(timeFormat: string): string {
    return moment().format(timeFormat);
  }

  validateLimitStore<T>(stores: T[]) {
    if (stores?.length > 100) {
      return of(
        dynamicReportAction.exportReportError({
          isSuccess: false,
          error: {
            error: {
              message: 'Export limit exceeded (up to 100 stores).'
            }
          }
        })
      );
    }
    return;
  }

  validateOrderLimitStore(stores) {
    if (stores?.length > 100) {
      return of(
        dynamicReportAction.exportReportError({
          isSuccess: false,
          error: {
            error: {
              message: 'Export limit exceeded (up to 100 stores).'
            }
          }
        })
      );
    }
    return;
  }

  validateLimitListExceeded<T>(items: T[], limit: number, limitType: string) {
    if (items && items.length > limit) {
      return of(
        dynamicReportAction.exportReportError({
          isSuccess: false,
          error: {
            error: {
              message: `Export limit exceeded (up to ${limit} ${limitType}).`
            }
          }
        })
      );
    }

    return;
  }

  convertArrayFilter(value) {
    return value && value.length > 0 ? value.toString() : null;
  }
}
