import { HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import * as StackTrace from 'stacktrace-js';

import { BaseService } from './base.service';
import { ErrorConnectorService } from './error-connector.service';

@Injectable()
export class AppGlobalErrorHandler extends BaseService implements ErrorHandler {
  constructor(private errorConnectorService: ErrorConnectorService) {
    super();
    this.envService = this.env.services.purchaseRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async handleError(error: any): Promise<void> {
    console.error('global error : ', error);

    // @ts-ignore
    const isChrome = !!window.chrome;

    const userAgent = {
      language: navigator.language,
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      // @ts-ignore
      connectionDownlink: isChrome ? navigator.connection.downlink : 'N/A',
      // @ts-ignore
      connectionEffectiveType: isChrome ? navigator.connection.effectiveType : 'N/A'
    };

    let stackTrace = await StackTrace.fromError(error);
    stackTrace = stackTrace.slice(0, 2).map(i => i);
    const body = JSON.stringify({ ts: Date.now(), userAgent, stackTrace });
    console.log('body : ', body);

    this.notifyError(body);
  }

  public notifyError(_errorBody: any): void {
    console.log('success send noti error to api', _errorBody);
    // alert(_errorBody);

    const pageContent = document.querySelector('#full-modal-content');
    const errorContent = {
      module: window.location.pathname,
      message: _errorBody,
      status: '0',
      isFullModal: !!pageContent
    };
    this.errorConnectorService.showError(errorContent);
  }
}
