import { Component, HostListener, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Theme } from '@shared/services/theme/theme';

import { environment as env } from '../../../../environments/environment';
import { LogOutAction } from '../../store/actions/auth.actions';
import { selectUserInfoResult } from '../../store/selectors/auth-user-info.selector';
import { AppStates } from '../../store/state/app.states';
import { AuthUserInfoState } from '../../store/state/auth-user-info.state';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public isCollapsed: boolean;
  public userInfoResult$: Observable<AuthUserInfoState>;
  protected readonly env = env;

  constructor(public sidebarService: SidebarService, private readonly store: Store<AppStates>) {}

  ngOnInit() {
    this.isCollapsed = true;
    this.userInfoResult$ = this.store.pipe(select(selectUserInfoResult));

    this.autoToggleMenu();
  }

  @HostListener('window:resize', ['$event'])
  autoToggleMenu() {
    this.sidebarService.sidebarState = window.innerWidth <= 768;
  }

  hideSidebar() {
    this.sidebarService.sidebarState = true;
  }

  toggleSidebar() {
    this.sidebarService.toggle();
  }

  onLogOut() {
    this.store.dispatch(new LogOutAction());
  }

  get sideBarState() {
    return this.sidebarService.sidebarState;
  }

  protected readonly Theme = Theme;
}
