import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import { ImportShelfInventoryBarcode } from '@shared/models/ImportShelfInventoryBarcode';

import { ApproveStatusEnum } from '../enum/approve-status.enum';
import { TDStoreValidatorTypeEnum } from '../enum/merchant-validator-type.enum';
import { NewRequestStatusEnum } from '../enum/request-status.enum';
import { productClassesQuery } from '../gql/classes.gql';
import { productGradingQuery } from '../gql/product-grading.gql';
import { MetricAction, MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import {
  ApproveShelfInventoryRequest,
  RequestId,
  ShelfInventoryCreateRequest,
  ShelfInventoryRequestListResponse,
  ShelfInventoryRequestListSearchCriteria,
  ShelfInventoryRequestViewResponse
} from '../models/shelf-inventory-request.model';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class ShelfInventoryRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(
    private readonly http: HttpClient,
    private readonly masterService: MasterService,
    protected readonly logger: NGXLogger
  ) {
    super();
    this.envService = this.env.services.shelfInventoryRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getShelfValidate(
    type: TDStoreValidatorTypeEnum,
    value: string,
    urlParams: { [key: string]: string } = null
  ): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.validate, { type, value });
    const params = urlParams ? this.getParams(urlParams) : null;

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'response',
      params
    });
  }

  public searchByCriteria(
    criteria: ShelfInventoryRequestListSearchCriteria
  ): Observable<ShelfInventoryRequestListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.SHELF_INVENTORY,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria);

    return this.http.get<ShelfInventoryRequestListResponse>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public saveRequest(shelfRequest: ShelfInventoryCreateRequest): Observable<ShelfInventoryRequestViewResponse> {
    const url = this.getFullUrl(this.envService.save);

    return this.http.put<ShelfInventoryRequestViewResponse>(url, shelfRequest, { headers: this.loaderHeaders(2000) });
  }

  public submitRequest(shelfRequest: ShelfInventoryCreateRequest): Observable<ShelfInventoryRequestViewResponse> {
    const url = this.getFullUrl(this.envService.submit);
    let metricsReq: Metrics;

    if (shelfRequest.status === NewRequestStatusEnum.DRAFT) {
      metricsReq = {
        metrics: [
          {
            metricType: MetricType.COUNTER,
            module: MetricModule.SHELF_INVENTORY,
            path: [this.envService.url, this.envService.submit].join('')
          }
        ]
      };
    }
    return this.http.post<ShelfInventoryRequestViewResponse>(url, shelfRequest, {
      headers: this.loaderHeaders(2000, metricsReq)
    });
  }

  public getShelfInventoryValidate(
    type: TDStoreValidatorTypeEnum,
    value: string,
    urlParams: { [key: string]: string } = null
  ): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.validate, { type, value });
    const params = urlParams ? this.getParams(urlParams) : null;

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'response',
      params
    });
  }

  public getShelfRequestById(requestId: RequestId): Observable<ShelfInventoryRequestViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      requestId: requestId.requestId
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public deleteByRequestId(requestId: RequestId): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.delete, {
      requestId: requestId.requestId
    });

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.SHELF_INVENTORY,
          path: [this.envService.url, 'delete'].join('')
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.SHELF_INVENTORY,
          action: MetricAction.SHELF_INVENTORY_DELETE,
          type: 'shelf_inventory',
          items: 1
        }
      ]
    };

    return this.http.delete<any>(url, { headers: this.loaderHeaders(2000, metricsReq), observe: 'body' });
  }

  public approveRequest(request: ApproveShelfInventoryRequest): Observable<ShelfInventoryRequestViewResponse> {
    const url = this.getFullUrl(this.envService.approve);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.SHELF_INVENTORY,
          path: [this.envService.url, this.envService.approve].join('')
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.SHELF_INVENTORY,
          action: this.getActionStatus(request.status),
          type: 'shelf_inventory',
          items: 1
        }
      ]
    };

    return this.http.put<ShelfInventoryRequestViewResponse>(url, request, {
      headers: this.loaderHeaders(2000, metricsReq),
      observe: 'body'
    });
  }

  public exportInventoryItem(data: any): Observable<any> {
    const url = this.getFullUrl(this.envService.exportFirstLot);
    const params = this.getParams(data);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.SHELF_INVENTORY,
          path: `${this.envService.url}${this.envService.exportFirstLot}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.SHELF_INVENTORY,
          route: `${this.envService.url}${this.envService.exportFirstLot}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      responseType: 'blob' as 'json',
      params
    });
  }

  public getHistoryLogs(requestId: RequestId): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      requestId: requestId.requestId
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getProductGrading(): Observable<ApolloQueryResult<any>> {
    return this.masterService.watchQuery({ query: productGradingQuery });
  }

  public getClasses(): Observable<ApolloQueryResult<any>> {
    return this.masterService.watchQuery({ query: productClassesQuery });
  }

  public exportShelf(selected: Array<string>): Observable<any> {
    const url = this.getFullUrl(this.envService.export);

    return this.http.post<any>(
      url,
      { selected },
      {
        headers: this.loaderHeaders(),
        observe: 'body',
        responseType: 'blob' as 'json'
      }
    );
  }

  public getActionStatus(status: string): MetricAction {
    let result: MetricAction;
    switch (status) {
      case ApproveStatusEnum.APPROVED:
        result = MetricAction.SHELF_INVENTORY_APPROVE;
        break;
      case ApproveStatusEnum.REJECTED:
        result = MetricAction.SHELF_INVENTORY_REJECT;
        break;
      case ApproveStatusEnum.CANCELLED:
        result = MetricAction.SHELF_INVENTORY_CANCEL_REQUEST;
        break;
      default:
        break;
    }
    return result;
  }

  public importFileStore(file: any): Observable<ImportShelfInventoryBarcode> {
    const url = this.getFullUrl(this.envService.importBarcodes);

    const formData = new FormData();
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');
    const metricsReq = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.SHELF_INVENTORY,
          path: url
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.SHELF_INVENTORY,
          route: url,
          action: MetricAction.IMPORT
        }
      ]
    };
    return this.http.post<ImportShelfInventoryBarcode>(url, formData, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body'
    });
  }

  downloadShelfInventoryRequestTemplate() {
    const url = this.getFullUrl(this.envService.downloadImportBarCodeTemplate);
    return this.http.get<any>(url, { headers: this.headers, responseType: 'blob' as 'json' });
  }
}
