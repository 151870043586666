import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-dropdown-multiple-select',
  templateUrl: './dropdown-multiple-select.component.html',
  styleUrls: ['./dropdown-multiple-select.component.scss']
})
export class DropdownMultipleSelectComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() items: any[];
  @Input() invalid: boolean;
  @Input() showSelectAll = true;
  @Input() showClearAll = true;
  @Input() showSearch = true;
  @Input() showTotalItem = true;
  @Input() selectAllFiltered = false;
  @Input() loading = false;

  filteredItems: any[];

  constructor() {
    // intentionally empty
  }

  onSelectAll() {
    if (!this.items) {
      return;
    }
    this.parentForm.get(this.controlName).patchValue([]);

    let selected;
    if (this.selectAllFiltered && this.filteredItems) {
      selected = this.filteredItems.map(item => (item[this.controlName] ? item[this.controlName] : item));
    } else {
      selected = this.items.map(item =>
        item[this.controlName] ? item[this.controlName] : item[this.bindValue] || item
      );
    }

    this.parentForm.get(this.controlName).patchValue(selected);
  }

  onClearAll() {
    const itemDisabledList = this.items.filter(i => i.disabled);
    this.parentForm.get(this.controlName).patchValue([]);
    this.parentForm.get(this.controlName).patchValue(itemDisabledList);
  }

  getItemsLength() {
    return this.items && this.items.length;
  }

  onSearch(event) {
    this.filteredItems = [...event.items];
  }

  onClose() {
    this.filteredItems = null;
  }
}
