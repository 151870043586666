import { DeliveryByTypeEnum } from '../enum/delivery-by-type.enum';
import { OrderingMethodEnum } from '../enum/ordering-method.enum';
import { StoreType } from '../enum/store.enum';
import { BaseReport } from './base-report.model';
import { OrderMethodEnum } from './order-request.model';

export class ReceiveOrderListSearchCriteria {
  searchCriteria?: string;
  deliveryBy?: DeliveryByTypeEnum;
  orderType?: OrderMethodEnum;
  createdDateFrom?: string;
  createdDateTo?: string;
  page: number;
  size: number;
}

export class ReceiveOrderList {
  receiveOrderNo: string;
  storeCodeName: string;
  orderNo: string;
  refNo: string;
  deliveryBy: DeliveryByTypeEnum;
  orderType: OrderingMethodEnum;
  receiver: string;
  createdDate: string;
  crNo: string;
}

export class ReceiveOrderListResponse {
  content: ReceiveOrderList[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class ReceiveOrderViewResponse {
  crNo: string;
  receiveOrderNo: string;
  orderNo: string;
  refNo: string;
  storeCodeName: string;
  storeCode: string;
  storeName: string;
  storeType: StoreType;
  orderType: OrderingMethodEnum;
  deliveryBy: DeliveryByTypeEnum;
  receiver: string;
  createdDate: string;
  items: ReceiveOrderItem[];
}

export class ReceiveOrderItem {
  productName: string;
  articleNo: string;
  deliverQty: number;
  receiveQty: number;
  claimQty: number;
  remainQty?: number;
  qty: number;
  unitFactor: number;
  unit: string;
}

export class ReceiveOrderErrorResponse {
  code: string;
  message: string;
}

export class ReceiveOrderExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  orderType?: string;
  storeNo?: string;
  warehouseCode?: string;

  constructor(init?: Partial<ReceiveOrderExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
