import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// tslint:disable-next-line:no-implicit-dependencies
import { bootloader } from '@angularclass/hmr';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () =>
  platformBrowserDynamic()
    .bootstrapModule(AppModule, { preserveWhitespaces: !environment.production })
    .then(() => {
      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
          for (const registration of registrations) {
            registration.unregister().then(v => v && console.log('unregister is successful'));
          }
        });
      }
    });

bootloader(bootstrap);
