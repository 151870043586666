import env from './.env';
import { environment as defaultEnv } from './base.environment';

export const environment = {
  ...defaultEnv,
  hmr: false,
  metrics: true,
  notifyError: true,
  version: env.version + '-greenapple',
  tier: 'greenapple',
  serverUrl: 'https://api-green.tdshop.io',
  storeResourcesPath: '/store-resources-green',
  imgUrl: 'https://storage.googleapis.com/product-assortments-green',
  cookies: {
    ...defaultEnv.cookies,
    domain: env.forceDomain || 'nest-green.tdshop.io'
  },
  services: {
    ...defaultEnv.services,
    advertisements: {
      ...defaultEnv.services.advertisements,
      bucketName: '/pos-ads-green'
    },
    productAssortment: {
      ...defaultEnv.services.productAssortment,
      bucketName: '/product-assortments-green'
    },
    onlineCouponRequest: {
      ...defaultEnv.services.onlineCouponRequest,
      bucketName: '/coupon-images-green'
    }
  }
};
