import { createSelector } from '@ngrx/store';

import { AppProfitSharingState } from '../profit-sharing.state';
import * as fromMonthlyPartnerProfitSharing from './monthly-partner-profit-sharing-reducers';

const selectMonthlyProfitSharingState = (state: AppProfitSharingState) => state.monthlyProfitSharing;

export const selectAllMonthlyPartnerProfitSharingList = createSelector(
  selectMonthlyProfitSharingState,
  fromMonthlyPartnerProfitSharing.selectAll
);

export const selectMonthlyPartnerProfitSharingList = createSelector(
  selectMonthlyProfitSharingState,
  (state: fromMonthlyPartnerProfitSharing.MonthlyPartnerProfitSharingState) => state
);

export const selectMonthlyPartnerProfitSharingSubmitResult = createSelector(
  selectMonthlyProfitSharingState,
  (state: fromMonthlyPartnerProfitSharing.MonthlyPartnerProfitSharingState) => state.monthlyPartnerProfitSharing
);

export const selectMonthlyPartnerProfitSharingSubmitError = createSelector(
  selectMonthlyProfitSharingState,
  (state: fromMonthlyPartnerProfitSharing.MonthlyPartnerProfitSharingState) =>
    state.submitMonthlyPartnerProfitSharingError
);
