export class ProfitSharingPermission {
  public showButton = true;
  public hasViewProfitSharing = false;
  public hasManageProfitSharing = false;

  checkPermission(authGuardService) {
    this.hasViewProfitSharing = authGuardService.checkPermission(['profitsharing_v']);
    this.hasManageProfitSharing = authGuardService.checkPermission(['profitsharing_m']);
  }
}
