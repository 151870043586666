import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import { FlashSaleOrderReportCriteria } from '@shared/models/report.model';
import { metricsReporting } from '@shared/utils/metrics-util';

import { BaseService } from '../../../shared/services/base.service';

@Injectable()
export class FlashSaleDashboardReportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, protected readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.exportReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  exportReport(criteria: FlashSaleOrderReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.flashSaleOrderExport, null, true);
    const metrics = metricsReporting([this.envService.flashSaleOrderExport]);

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
