<div id="order-details" *ngIf="viewOrder$ | async as order">
  <div class="mt-3 table-responsive">
    <table
      id="datatable"
      class="table table-striped table-bordered table-hover border-none w-100"
      aria-label="datatable"
    >
      <thead>
        <tr>
          <th class="text-center align-top w-5" scope="col">No.</th>
          <th class="text-center align-top w-25" scope="col">Product Name</th>
          <th class="text-center align-top" scope="col">Barcode</th>
          <th class="text-center align-top" scope="col">Product Type</th>
          <th class="text-center align-top w-5" scope="col">Unit</th>
          <th class="text-center align-top" scope="col">Unit Price</th>
          <th class="text-center align-top w-5" scope="col">Order Quantity</th>
          <th class="text-center align-top w-5" scope="col">Assigned Quantity</th>
          <th class="text-center align-top total-amount-bg" scope="col">Amount (THB)</th>
          <th class="text-center align-top" scope="col">Item Status</th>
          <th class="text-center align-top w-10" scope="col">Remark</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of paging.currentPageData; index as i">
          <td class="text-center">{{ paging.getItemNo(i) }}</td>
          <td>
            {{ item.productName }} <span class="d-block">{{ item.articleNo }}</span>
          </td>
          <td class="text-left">{{ item.barcode }}</td>
          <td class="text-left">{{ 'PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + item.productType | translate }}</td>
          <td class="text-center w-5">{{ item.unit }}</td>
          <td class="text-right">{{ item.wholesalePrice | numberFormatDisplay }}</td>
          <td class="text-center">{{ item.qty | numberFormatDisplay: 0 }}</td>
          <td class="text-center">
            {{
              displayByCondition
                | pureFn
                  : item.assignedQty
                  : order.deliveryBy
                  : order.orderType
                  : order.status
                  : 0
                  : numberFormatDisplayPipe.transform
            }}
          </td>
          <td class="text-right total-amount-bg">{{ item.amount | numberFormatDisplay }}</td>
          <td class="text-center">
            {{
              displayByCondition
                | pureFn: getItemStatus(item.itemStatus):order.deliveryBy:order.orderType:order.status:''
            }}
          </td>
          <td class="text-left">{{ item.reason }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-ui-pagination
    #paging
    [currentPage]="currentPage"
    [pageSize]="pageSize"
    [items]="(viewOrder$ | async).items"
    [valueChangeDetector]="viewOrder$ | async"
  >
  </app-ui-pagination>
</div>
