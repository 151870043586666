import { ProfitSharingStatusEnum } from '../enum/profit-sharing.enum';
import { AuditLog } from './audit-log.model';
import { BaseReport } from './base-report.model';
import { ReportFileType } from './report.model';

export class ProfitSharingSearchCriteria {
  lastModifiedDateFrom?: string;
  lastModifiedDateTo?: string;
  status?: ProfitSharingStatusEnum;
  searchCriteria?: string;
  scheduleFrom?: string;
  scheduleTo?: string;
  page: number;
  size: number;
}

export class ProfitSharingListPagination {
  content: ProfitSharingContent[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class ProfitSharingContent {
  id: string;
  docNo: string;
  runDate: string;
  schedule: string;
  scheduleDisplay: string;
  period: string;
  profitStatus: ProfitSharingStatusEnum;
  store: StoreDetails;
  totalAmount: number;
  profitSharingAmount: number;
  lastModifiedDate: string;
  lastModifiedBy: string;
  lastModifiedByName: string;
  lastAllowEditedDate: string;
  remainingBalanceAmount: number;
  auditLogs: AuditLog[];
}

export class StoreDetails {
  no: string;
  code: string;
  name: string;
  storeCodeName: string;
}

export class RefundDetails {
  overCashTransferAmount: number;
  refundAmount: number;
  appealForFineAmount: number;
  appealForStockLossAmount: number;
  otherRefundAmount: number;
  totalAmount: number;
}

export class OtherIncomeDetails {
  marketingSupportAmount: number;
  withHoldingTaxCorpAmount: number;
  otherIncomeAmount: number;
  totalAmount: number;
}

export class LessCashTransferDetails {
  lessCashTransferAmount: number;
  stockLossInstallmentAmount: number;
  billPaymentInstallmentAmount: number;
  negativeProfitSharingAmount: number;
  advancePaymentAmount: number;
  otherLessCashTransferAmount: number;
  totalAmount: number;
}

export class StockLossAdjustmentDetails {
  icLostAmount: number;
  icLostPaidAmount: number;
  icLostNextBillAmount: number;
  totalAmount: number;
}

export class NetPenaltyDetails {
  latePaymentAmount: number;
  toteFineAmount: number;
  otherPenaltyAmount: number;
  totalAmount: number;
}

export class OtherExpenseDetails {
  internetAmount: number;
  assetRepairAmount: number;
  otherExpenseAmount: number;
  totalAmount: number;
}

export class ProfitSharingData {
  id: string;
  docNo: string;
  version: number;
  runDate: string;
  schedule: string;
  scheduleDisplay: string;
  period: string;
  status: string;
  profitStatus: ProfitSharingStatusEnum;
  store: StoreDetails;
  profitSharingAmount: number;
  remainingBalanceAmount: number;
  grossProfitAfterShareAmount: number;
  shareToTDIncVatAmount: number;
  shareToTDExcVatAmount: number;
  shareToTDVatAmount: number;
  grossProfitIncVatAmount: number;
  grossProfitExcVatAmount: number;
  salesIncVatAmount: number;
  salesExcVatAmount: number;
  cogsIncVatAmount: number;
  cogsExcVatAmount: number;
  compensateAmount: number;
  totalOtherExpense: number;
  withHoldingTaxAmount: number;
  accruedExpenseAmount: number;
  lessCashTransferAmount: number;
  storeUseAmount: number;
  stockLossAdjustmentAmount: number;
  destroyAmount: number;
  netPenaltyAmount: number;
  otherExpenseAmount: number;
  totalOtherIncome: number;
  cigaretteVatAdjustmentAmount: number;
  overCashTransferAmount: number;
  incentiveAmount: number;
  otherIncomeAmount: number;
  totalAmount: number;

  refundGrossProfitIncVatAmount: number;
  refundGrossProfitExcVatAmount: number;
  refundIncVatAmount: number;
  refundCogsIncVatAmount: number;
  refundExcVatAmount: number;
  refundCogsExcVatAmount: number;
  refundCompensateAmount: number;
  refundCigaretteVatAdjAmount: number;

  refund: RefundDetails;
  otherIncome: OtherIncomeDetails;
  lessCashTransfer: LessCashTransferDetails;
  stockLossAdjustment: StockLossAdjustmentDetails;
  netPenalty: NetPenaltyDetails;
  otherExpense: OtherExpenseDetails;

  periodDateFrom: string;
  periodDateTo: string;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  lastModifiedByName: string;
  lastAllowEditedDate: string;
  auditLogs: AuditLog[];

  advancePaymentAmount: number;
  templateVersion: number;
}

export class ProfitSharingPrintCriteria {
  docNo: string;
  runDate: string;
}

export class ProfitSharingExportCriteria extends BaseReport {
  scheduleFrom?: string;
  scheduleTo?: string;
  fileType?: ReportFileType;

  constructor(init?: Partial<ProfitSharingExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
