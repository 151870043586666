import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  RoleCreated,
  RoleEdit,
  RoleListResponse,
  RoleSearchCriteria,
  RoleViewResponse,
  UserByRoleContentList,
  UserByRoleCriteria
} from '../models';
import { MetricAction, MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';

@Injectable()
export class RoleService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.role;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteriaRequest(criteria: RoleSearchCriteria): Observable<RoleListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.ROLE,
          route: this.envService.list,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getFullUrl(this.envService.list);

    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  save(roleCreate: RoleCreated): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.ROLE,
          path: window.location.pathname
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.ROLE,
          action: MetricAction.ROLE_CREATE,
          type: 'CREATE',
          items: 1
        }
      ]
    };

    return this.http.post<any>(this.getUrl(), roleCreate, {
      headers: this.loaderHeaders(3000, metricsReq)
    });
  }

  edit(roleEdit: RoleEdit): Observable<any> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.ROLE,
          path: window.location.pathname
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.ROLE,
          action: MetricAction.ROLE_EDIT,
          type: 'EDIT',
          items: 1
        }
      ]
    };

    return this.http.put<any>(this.getUrl(), roleEdit, {
      headers: this.loaderHeaders(3000, metricsReq)
    });
  }

  getRoleById(roleNo: string): Observable<RoleViewResponse> {
    const url = this.getFullUrl(this.envService.get, {
      roleNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  getRoleAll(): Observable<RoleViewResponse[]> {
    return this.http.get<RoleViewResponse[]>(this.getUrl(), {
      headers: this.headers,
      observe: 'body'
    });
  }

  getUserByRole(criteria: UserByRoleCriteria): Observable<UserByRoleContentList> {
    const url = `${this.env.serverUrl}${this.env.services.users.url}${this.env.services.users.searchUserByRole}`;
    const userHeader = new HttpHeaders(this.env.services.users.headers);

    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: userHeader,
      observe: 'body',
      params
    });
  }

  deleteRole(id: string): Observable<any> {
    const url = this.getFullUrl(this.envService.deleteRole, { id });
    return this.http.delete<any>(url, { headers: this.loaderHeaders(3000), observe: 'body' });
  }
}
