import { Action } from '@ngrx/store';

import { ErrorResponse } from '../../models';
import {
  ApproveShelfRequest,
  RequestId,
  ShelfCreateRequest,
  ShelfFixAssetCreateRequest,
  ShelfFixAssetRequestListResponse,
  ShelfFixAssetRequestListSearchCriteria,
  ShelfFixAssetRequestViewResponse,
  ShelfRequestListResponse,
  ShelfRequestListSearchCriteria,
  ShelfRequestViewResponse
} from '../../models/shelf-request.model';

export enum ShelfRequestActionType {
  SHELF_REQUEST_LIST_REQUEST = '[ShelfRequest] Shelf Request List Request',
  SHELF_REQUEST_LIST_RESPONSE = '[ShelfRequest] Shelf Request List Response',
  SHELF_REQUEST_LIST_REQUEST_FIX_ASSET = '[ShelfRequest] Shelf Request List Request fix asset',
  SHELF_REQUEST_LIST_RESPONSE_FIX_ASSET = '[ShelfRequest] Shelf Request List Response fix asset',
  SHELF_CREATE_SAVE_REQUEST = '[ShelfRequest] Create Shelf Save Request',
  SHELF_CREATE_SAVE_RESPONSE = '[ShelfRequest] Create Shelf Save Response',
  SHELF_CREATE_SAVE_ERROR = '[ShelfRequest] Create Shelf Save Error',
  SHELF_CREATE_SAVE_REQUEST_FIX_ASSET = '[ShelfRequest] Create Shelf Save Request fix asset',
  SHELF_CREATE_SAVE_RESPONSE_FIX_ASSET = '[ShelfRequest] Create Shelf Save Response fix asset',
  SHELF_CREATE_SAVE_ERROR_FIX_ASSET = '[ShelfRequest] Create Shelf Save Error fix asset',
  SHELF_CREATE_SUBMIT_REQUEST = '[ShelfRequest] Create Shelf Submit Request',
  SHELF_CREATE_SUBMIT_RESPONSE = '[ShelfRequest] Create Shelf Submit Response',
  SHELF_CREATE_SUBMIT_ERROR = '[ShelfRequest] Create Shelf Submit Error',
  SHELF_CREATE_SUBMIT_REQUEST_FIX_ASSET = '[ShelfRequest] Create Shelf Submit Request fix asset',
  SHELF_CREATE_SUBMIT_RESPONSE_FIX_ASSET = '[ShelfRequest] Create Shelf Submit Response fix asset',
  SHELF_CREATE_SUBMIT_ERROR_FIX_ASSET = '[ShelfRequest] Create Shelf Submit Error fix asset',
  SHELF_REQUEST_GET_BY_ID_REQUEST = '[ShelfRequest] Get Shelf By Id Request',
  SHELF_REQUEST_GET_BY_ID_RESPONSE = '[ShelfRequest] Get Shelf By Id Response',
  SHELF_REQUEST_GET_BY_ID_RESET = '[ShelfRequest] Get Shelf By Id Reset',
  SHELF_REQUEST_GET_BY_ID_REQUEST_FIX_ASSET = '[ShelfRequest] Get Shelf By Id Request fix asset',
  SHELF_REQUEST_GET_BY_ID_RESPONSE_FIX_ASSET = '[ShelfRequest] Get Shelf By Id Response fix asset',
  SHELF_REQUEST_GET_BY_ID_RESET_FIX_ASSET = '[ShelfRequest] Get Shelf By Id Reset fix asset',
  SHELF_REQUEST_LIST_HISTORY_REQUEST = '[ShelfRequest] Shelf Request List History Request',
  SHELF_REQUEST_LIST_HISTORY_RESPONSE = '[ShelfRequest] Shelf Request List History Response',
  SHELF_REQUEST_LIST_HISTORY_REQUEST_FIX_ASSET = '[ShelfRequest] Shelf Request List History Request fix asset',
  SHELF_REQUEST_LIST_HISTORY_RESPONSE_FIX_ASSET = '[ShelfRequest] Shelf Request List History Response fix asset',
  SHELF_REQUEST_DELETE_REQUEST = '[ShelfRequest] Request Delete Shelf Request',
  SHELF_REQUEST_DELETE_RESPONSE = '[ShelfRequest] Request Delete Shelf Response',
  SHELF_REQUEST_DELETE_RESET = '[ShelfRequest] Request Delete Shelf Reset',
  SHELF_REQUEST_DELETE_REQUEST_FIX_ASSET = '[ShelfRequest] Request Delete Shelf Request fix asset',
  SHELF_REQUEST_DELETE_RESPONSE_FIX_ASSET = '[ShelfRequest] Request Delete Shelf Response fix asset',
  SHELF_REQUEST_DELETE_RESET_FIX_ASSET = '[ShelfRequest] Request Delete Shelf Reset fix asset',
  SHELF_REQUEST_APPROVE_REQUEST = '[ShelfRequest] Request Approve Shelf Request',
  SHELF_REQUEST_APPROVE_RESPONSE = '[ShelfRequest] Request Approve Shelf Response',
  SHELF_REQUEST_APPROVE_REQUEST_FIX_ASSET = '[ShelfRequest] Request Approve Shelf Request fix asset',
  SHELF_REQUEST_APPROVE_RESPONSE_FIX_ASSET = '[ShelfRequest] Request Approve Shelf Response fix asset',
  SHELF_TYPE_LIST_REQUEST = '[ShelfRequest] Shelf Type List Request',
  SHELF_TYPE_LIST_RESPONSE = '[ShelfRequest] Shelf Type List Response',
  SHELF_CREATE_RESET = '[ShelfRequest] Create Shelf Reset',
  SHELF_CREATE_RESET_FIX_ASSET = '[ShelfRequest] Create Shelf Reset fix asset'
}

export class ShelfRequestListRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST;

  constructor(public payload: ShelfRequestListSearchCriteria) {}
}

export class ShelfRequestListResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_RESPONSE;

  constructor(public payload: ShelfRequestListResponse) {}
}

export class ShelfCreateSaveRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SAVE_REQUEST;

  constructor(public payload: ShelfCreateRequest) {}
}

export class ShelfCreateSaveResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SAVE_RESPONSE;

  constructor(public payload: ShelfRequestViewResponse) {}
}

export class ShelfCreateSaveErrorAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SAVE_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ShelfCreateSubmitRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SUBMIT_REQUEST;

  constructor(public payload: ShelfCreateRequest) {}
}

export class ShelfCreateSubmitResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SUBMIT_RESPONSE;

  constructor(public payload: ShelfRequestViewResponse) {}
}

export class ShelfCreateSubmitErrorAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SUBMIT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ShelfRequestListHistoryRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_REQUEST;

  constructor(public payload: RequestId) {}
}

export class ShelfRequestListHistoryResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_RESPONSE;

  constructor(public payload: { auditLogs }) {}
}

export class ShelfRequestByIdRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: RequestId) {}
}

export class ShelfRequestByIdResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: ShelfRequestViewResponse) {}
}

export class ShelfRequestByIdResetAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_RESET;
}

export class ShelfDeleteRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_DELETE_REQUEST;

  constructor(public payload: RequestId) {}
}

export class ShelfDeleteResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_DELETE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class ShelfDeleteResetAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_DELETE_RESET;
}

export class ShelfApproveRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_APPROVE_REQUEST;

  constructor(public payload: ApproveShelfRequest) {}
}

export class ShelfApproveResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_APPROVE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class ShelfCreateResetAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_RESET;
}

export class ShelfFixAssetRequestListRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST_FIX_ASSET;

  constructor(public payload: ShelfFixAssetRequestListSearchCriteria) {}
}

export class ShelfFixAssetRequestListResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_RESPONSE_FIX_ASSET;

  constructor(public payload: ShelfFixAssetRequestListResponse) {}
}
export class ShelfFixAssetCreateSaveRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SAVE_REQUEST_FIX_ASSET;

  constructor(public payload: ShelfFixAssetCreateRequest) {}
}

export class ShelfFixAssetCreateSaveResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SAVE_RESPONSE_FIX_ASSET;

  constructor(public payload: ShelfFixAssetRequestViewResponse) {}
}

export class ShelfFixAssetCreateSaveErrorAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SAVE_ERROR_FIX_ASSET;

  constructor(public payload: ErrorResponse) {}
}

export class ShelfFixAssetCreateSubmitRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SUBMIT_REQUEST_FIX_ASSET;

  constructor(public payload: ShelfFixAssetCreateRequest) {}
}

export class ShelfFixAssetCreateSubmitResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SUBMIT_RESPONSE_FIX_ASSET;

  constructor(public payload: ShelfFixAssetRequestViewResponse) {}
}
export class ShelfFixAssetCreateSubmitErrorAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SUBMIT_ERROR_FIX_ASSET;

  constructor(public payload: ErrorResponse) {}
}

export class ShelfFixAssetRequestListHistoryRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_REQUEST_FIX_ASSET;

  constructor(public payload: RequestId) {}
}

export class ShelfFixAssetRequestListHistoryResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_RESPONSE_FIX_ASSET;

  constructor(public payload: { auditLogs }) {}
}

export class ShelfFixAssetRequestByIdRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_REQUEST_FIX_ASSET;

  constructor(public payload: RequestId) {}
}

export class ShelfFixAssetRequestByIdResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_RESPONSE_FIX_ASSET;

  constructor(public payload: ShelfFixAssetRequestViewResponse) {}
}
export class ShelfFixAssetRequestByIdResetAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_RESET_FIX_ASSET;
}

export class ShelfFixAssetDeleteRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_DELETE_REQUEST_FIX_ASSET;

  constructor(public payload: RequestId) {}
}

export class ShelfFixAssetDeleteResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_DELETE_RESPONSE_FIX_ASSET;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class ShelfFixAssetDeleteResetAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_DELETE_RESET_FIX_ASSET;
}

export class ShelfFixAssetApproveRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_APPROVE_REQUEST_FIX_ASSET;

  constructor(public payload: ApproveShelfRequest) {}
}

export class ShelfFixAssetApproveResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_APPROVE_RESPONSE_FIX_ASSET;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class ShelfFixAssetCreateResetAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_RESET_FIX_ASSET;
}

export type ShelfRequestActions =
  | ShelfRequestListRequestAction
  | ShelfRequestListResponseAction
  | ShelfCreateSaveRequestAction
  | ShelfCreateSaveResponseAction
  | ShelfCreateSaveErrorAction
  | ShelfCreateSubmitRequestAction
  | ShelfCreateSubmitResponseAction
  | ShelfCreateSubmitErrorAction
  | ShelfRequestByIdRequestAction
  | ShelfRequestByIdResponseAction
  | ShelfRequestByIdResetAction
  | ShelfRequestListHistoryRequestAction
  | ShelfRequestListHistoryResponseAction
  | ShelfDeleteRequestAction
  | ShelfDeleteResponseAction
  | ShelfDeleteResetAction
  | ShelfApproveRequestAction
  | ShelfApproveResponseAction
  | ShelfCreateResetAction
  | ShelfFixAssetRequestListRequestAction
  | ShelfFixAssetRequestListResponseAction
  | ShelfFixAssetCreateSaveRequestAction
  | ShelfFixAssetCreateSaveResponseAction
  | ShelfFixAssetCreateSaveErrorAction
  | ShelfFixAssetCreateSubmitRequestAction
  | ShelfFixAssetCreateSubmitResponseAction
  | ShelfFixAssetCreateSubmitErrorAction
  | ShelfFixAssetRequestListHistoryRequestAction
  | ShelfFixAssetRequestListHistoryResponseAction
  | ShelfFixAssetRequestByIdRequestAction
  | ShelfFixAssetRequestByIdResponseAction
  | ShelfFixAssetRequestByIdResetAction
  | ShelfFixAssetDeleteRequestAction
  | ShelfFixAssetDeleteResponseAction
  | ShelfFixAssetDeleteResetAction
  | ShelfFixAssetApproveRequestAction
  | ShelfFixAssetApproveResponseAction
  | ShelfFixAssetCreateResetAction;
