import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { SelectStoreDropdownList, StoreDropdownListContent } from '../../models/store-dropdown.model';
import { StoreDropdownAction, StoreDropdownActionTypes } from '../actions/store-dropdown.actions';

export interface StoreDropdownState extends EntityState<StoreDropdownListContent> {
  isLoading: boolean;
  selectStoreDropdownPagingList: SelectStoreDropdownState;
  selectStoreDropdownList: SelectStoreDropdownList[];
}

export interface SelectStoreDropdownState extends EntityState<SelectStoreDropdownList> {
  isLoading: boolean;
  totalElement: number;
}

export const adapter: EntityAdapter<StoreDropdownListContent> = createEntityAdapter<StoreDropdownListContent>();
export const adapterSelectStoreDropdown: EntityAdapter<SelectStoreDropdownList> = createEntityAdapter<
  SelectStoreDropdownList
>();

export const initialSelectStoreDropdownState: SelectStoreDropdownState = adapterSelectStoreDropdown.getInitialState({
  isLoading: false,
  totalElement: 0
});
export const initialStoreDropdownState: StoreDropdownState = adapter.getInitialState({
  isLoading: false,
  selectStoreDropdownPagingList: initialSelectStoreDropdownState,
  selectStoreDropdownList: []
});

export function storeDropdownReducers(
  state = initialStoreDropdownState,
  action: StoreDropdownAction
): StoreDropdownState {
  switch (action.type) {
    case StoreDropdownActionTypes.SELECT_STORE_DROPDOWNS_LIST_RESPONSE:
      return {
        ...state,
        selectStoreDropdownList: action.payload
      };
    case StoreDropdownActionTypes.SELECT_STORE_DROPDOWNS_LIST_REQUEST:
      return {
        ...state,
        selectStoreDropdownPagingList: {
          ...state.selectStoreDropdownPagingList,
          isLoading: true
        }
      };
    case StoreDropdownActionTypes.SELECT_STORE_DROPDOWNS_PAGING_LIST_RESPONSE:
      return {
        ...state,
        selectStoreDropdownPagingList: adapterSelectStoreDropdown.upsertMany(action.payload.masterDtoList, {
          ...state.selectStoreDropdownPagingList,
          isLoading: false,
          totalElement: action.payload.totalElement
        })
      };
    case StoreDropdownActionTypes.REMOVE_ALL_SELECT_STORE_DROPDOWN_LIST:
      return {
        ...state,
        selectStoreDropdownPagingList: adapterSelectStoreDropdown.removeAll({
          ...state.selectStoreDropdownPagingList
        })
      };
    case StoreDropdownActionTypes.RESET_STORE_DROPDOWN_LIST:
      return {
        ...state,
        selectStoreDropdownPagingList: initialSelectStoreDropdownState
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const { selectAll: selectAllStoreDropdownPagingList } = adapterSelectStoreDropdown.getSelectors();
