import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MetricActionType } from '../models/metrics';
import {
  PreOrderCatalogConfidentialCriteria,
  PreOrderCatalogExportCriteria,
  ReportFileType
} from '../models/report.model';
import { metricsReportDataHub } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable()
export class PreOrderReportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.preOrderReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportCatalog(criteria: PreOrderCatalogExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportPreOrderCatalog);
    const exportType =
      criteria.fileType === ReportFileType.CSV ? MetricActionType.EXPORT_CSV : MetricActionType.EXPORT_XLSX;
    const metrics = metricsReportDataHub([this.envService.url, this.envService.export], exportType);

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public reportConfidential(criteria: PreOrderCatalogConfidentialCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.reportConfidential);
    const exportType =
      criteria.fileType === ReportFileType.CSV ? MetricActionType.EXPORT_CSV : MetricActionType.EXPORT_XLSX;
    const metrics = metricsReportDataHub([this.envService.url, this.envService.export], exportType);

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
