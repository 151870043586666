import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import { StoreListSearchCriteria } from '@shared/models';
import { StoreMerchantsPagingList } from '@shared/models/store-merchant.model';
import { StoreMerchantsActionTypes, StoreMerchantsActions } from '@shared/store/actions/store-merchants.actions';
import { StoreMerchantsStates } from '@shared/store/state/store-merchants.state';

export const storeMerchantsModuleReducers: ActionReducerMap<Partial<StoreMerchantsStates>> = {
  storeMerchants: storeMerchantsReducers
};

export interface StoreMerchantsRequestState extends EntityState<StoreMerchantsPagingList> {
  criteriaObject: StoreListSearchCriteria;
  storeMerchantsPagingList: SelectStoreMerchantState;
  storeMerchantsResponseList: StoreMerchantsPagingList[];
}

export interface SelectStoreMerchantState extends EntityState<StoreMerchantsPagingList> {
  isLoading: boolean;
  totalElement: number;
}

export const adapter: EntityAdapter<StoreMerchantsPagingList> = createEntityAdapter<StoreMerchantsPagingList>({
  selectId: (billToBase: StoreMerchantsPagingList) => billToBase.id
});

export const adapterSelectStore: EntityAdapter<StoreMerchantsPagingList> = createEntityAdapter<
  StoreMerchantsPagingList
>({
  selectId: (billToBase: StoreMerchantsPagingList) => billToBase.id
});

export const initialSelectStoreMerchantState: SelectStoreMerchantState = adapterSelectStore.getInitialState({
  isLoading: false,
  totalElement: 0
});

export const initialStoreMerchantResponseState: StoreMerchantsRequestState = adapter.getInitialState({
  criteriaObject: {
    page: 0,
    size: 20
  },
  storeMerchantsPagingList: initialSelectStoreMerchantState,
  storeMerchantsResponseList: []
});

export function storeMerchantsReducers(
  state = initialStoreMerchantResponseState,
  action: StoreMerchantsActions
): StoreMerchantsRequestState {
  switch (action.type) {
    case StoreMerchantsActionTypes.STORE_MERCHANTS_PAGING_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        storeMerchantsPagingList: {
          ...state.storeMerchantsPagingList,
          isLoading: true
        }
      };
    case StoreMerchantsActionTypes.STORE_MERCHANTS_PAGING_LIST_RESPONSE:
      return {
        ...state,
        storeMerchantsPagingList: adapterSelectStore.upsertMany(action.payload.content, {
          ...state.storeMerchantsPagingList,
          isLoading: false,
          totalElement: action.payload.totalElements
        })
      };
    case StoreMerchantsActionTypes.REMOVE_ALL_SELECT_STORE_MERCHANTS_LIST:
      return {
        ...state,
        storeMerchantsPagingList: adapterSelectStore.removeAll({ ...state.storeMerchantsPagingList })
      };
    case StoreMerchantsActionTypes.REMOVE_ALL_SELECT_STORE_MERCHANT_LIST:
      return {
        ...state,
        storeMerchantsPagingList: adapterSelectStore.removeAll({
          ...state.storeMerchantsPagingList
        })
      };
    case StoreMerchantsActionTypes.RESET_STORE_MERCHANTS_LIST:
      return {
        ...state,
        storeMerchantsPagingList: initialSelectStoreMerchantState
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const { selectAll: selectAllStoreMerchantsPagingList } = adapterSelectStore.getSelectors();
