import { createSelector } from '@ngrx/store';

import * as fromShelfListResponseState from '../reducers/shelf.reducers';
import { AppStates } from '../state/app.states';

const selectShelfListState = (state: AppStates) => state.shelf;

export const selectAllShelfList = createSelector(selectShelfListState, fromShelfListResponseState.selectAll);

export const selectShelfList = createSelector(
  selectShelfListState,
  (state: fromShelfListResponseState.ShelfState) => state
);

export const selectShelfListCriteria = createSelector(selectShelfListState, state => {
  return state.criteriaObject;
});

export const selectShelfListHistory = createSelector(
  selectShelfListState,
  (state: fromShelfListResponseState.ShelfState) => {
    return state.auditLogs;
  }
);

export const selectShelfById = createSelector(
  selectShelfListState,
  (state: fromShelfListResponseState.ShelfState) => state.selected
);
