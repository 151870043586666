import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { ShelfFixAssetOrderItem } from '../../models/order-request.model';
import {
  ShelfSelectFixAssetOrderActionTypes,
  ShelfSelectFixAssetOrderActions
} from '../actions/shelf-select-fix-asset-order.actions';

export interface ShelfSelectFixAssetOrderState extends EntityState<ShelfFixAssetOrderItem> {
  isSelectedShelf: boolean;
  isLoading: boolean;
  skipUpdated: boolean;
}

export const adapter: EntityAdapter<ShelfFixAssetOrderItem> = createEntityAdapter<ShelfFixAssetOrderItem>({
  selectId: (shelfSelectFixAssetOrderContent: ShelfFixAssetOrderItem) => shelfSelectFixAssetOrderContent.shelfNo
});

export const initialShelfFixAssetOrderState: ShelfSelectFixAssetOrderState = adapter.getInitialState({
  isSelectedShelf: false,
  isLoading: false,
  skipUpdated: false
});

export function shelfSelectFixAssetOrderReducers(
  state = initialShelfFixAssetOrderState,
  action: ShelfSelectFixAssetOrderActions
): ShelfSelectFixAssetOrderState {
  switch (action.type) {
    case ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_ADD_ITEM:
      return adapter.upsertOne(action.payload, {
        ...state,
        skipUpdated: false
      });
    case ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_UPDATE_ITEM:
      return adapter.updateOne(
        {
          id: action.payload.shelfNo,
          changes: { ...action.payload }
        },
        {
          ...state,
          skipUpdated: false
        }
      );
    case ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_ADD_ALL_ITEM:
      return adapter.setAll(action.payload.itemList, {
        ...state,
        skipUpdated: action.payload.skipUpdated
      });
    case ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_REMOVE_ITEM:
      return adapter.removeOne(action.payload, {
        ...state
      });
    case ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_RESET:
      return adapter.removeAll({
        ...state
      });
    case ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_SELECTED_SHELF:
      return {
        ...state,
        isSelectedShelf: action.payload
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
