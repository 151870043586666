import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { TaskAssignmentService } from '../../services/task-assignment.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import {
  TaskAssignmentActionType,
  TaskAssignmentByIdRequestAction,
  TaskAssignmentByIdResponseAction,
  TaskAssignmentCancelRequest,
  TaskAssignmentListRequestAction,
  TaskAssignmentListResponseAction
} from '../actions/task-assignment.actions';

@Injectable()
export class TaskAssignmentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly taskAssignmentService: TaskAssignmentService,
    private readonly logger: NGXLogger
  ) {}

  searchTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TaskAssignmentListRequestAction>(TaskAssignmentActionType.TASK_ASSIGNMENT_LIST_REQUEST),
      tap(action =>
        this.logger.debug(
          `@Effect ${TaskAssignmentActionType.TASK_ASSIGNMENT_LIST_REQUEST}: ` + this.stringify(action.payload)
        )
      ),
      switchMap(action => {
        return this.taskAssignmentService.searchByCriteria(action.payload).pipe(
          map(response => new TaskAssignmentListResponseAction(response)),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  getTaskById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TaskAssignmentByIdRequestAction>(TaskAssignmentActionType.TASK_ASSIGNMENT_GET_BY_ID_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${TaskAssignmentActionType.TASK_ASSIGNMENT_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.taskAssignmentService.getTaskById(payload.taskNo).pipe(
          map(taskRequest => {
            return new TaskAssignmentByIdResponseAction(taskRequest);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  cancelReward$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TaskAssignmentCancelRequest>(TaskAssignmentActionType.TASK_CANCEL_REQUESTED),
      tap(action =>
        this.logger.debug(
          `@Effect ${TaskAssignmentActionType.TASK_CANCEL_REQUESTED}: ` + this.stringify(action.payload)
        )
      ),
      switchMap(action =>
        this.taskAssignmentService.cancel(action.payload).pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'Task has been cancelled.'
              })
          ),
          catchError(err => {
            return err.error && err.error.code === '00004'
              ? of(new LayoutActionVersionError(true))
              : of(new LayoutActionLoadError(err));
          })
        )
      )
    );
  });

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
