import { Action } from '@ngrx/store';

import { ApprovalFlowResponse } from '../../models/approval-flow.model';

export enum ApprovalFlowActionTypes {
  GET_APPROVAL_WORKFLOW_BY_ID_REQUEST = '[Approval Flow] Get Approval Flow By Id Request',
  GET_APPROVAL_WORKFLOW_BY_ID_RESPONSE = '[Approval Flow] Get approval Flow by Id Response',
  RESET_APPROVAL_WORKFLOW = '[Approval Flow] Reset approval Flow'
}

export class GetApprovalFlowByIdRequest implements Action {
  readonly type = ApprovalFlowActionTypes.GET_APPROVAL_WORKFLOW_BY_ID_REQUEST;

  constructor(public payload: string) {}
}

export class GetApprovalFlowByIdResponse implements Action {
  readonly type = ApprovalFlowActionTypes.GET_APPROVAL_WORKFLOW_BY_ID_RESPONSE;

  constructor(public payload: ApprovalFlowResponse) {}
}

export class ResetApprovalFlow implements Action {
  readonly type = ApprovalFlowActionTypes.RESET_APPROVAL_WORKFLOW;

  constructor() {}
}

export type ApprovalFlowActions = GetApprovalFlowByIdRequest | GetApprovalFlowByIdResponse | ResetApprovalFlow;
