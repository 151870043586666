import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DashboardService } from '../../services/dashboard.service';
import {
  DashboardActionTypes,
  TasksByRoleListRequestAction,
  TasksByRoleListResponseAction
} from '../actions/dashboard.actions';
import { LayoutActionLoadError } from '../actions/layout.action';

@Injectable()
export class DashboardEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly dashboardService: DashboardService,
    private readonly logger: NGXLogger
  ) {}

  searchTasksByRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TasksByRoleListRequestAction>(DashboardActionTypes.TASKS_BY_ROLE_LIST_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${DashboardActionTypes.TASKS_BY_ROLE_LIST_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.dashboardService.tasksByRole(payload).pipe(
          map(reply => {
            return new TasksByRoleListResponseAction(reply);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
