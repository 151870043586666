import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GoodsReceiveExportCriteria } from '../../../shared/models/goods-receive.model';
import { BaseService } from '../../../shared/services/base.service';

@Injectable()
export class NewReportService extends BaseService {
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.exportReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  exportGoodsReceive(criteria: GoodsReceiveExportCriteria): Observable<any> {
    return this.exportReport(this.envService.goodsReceive, criteria);
  }

  exportReport<T>(path: string, exportCriteria: T) {
    const url = this.getFullUrl(path, null, true);
    return this.http.post<any>(url, exportCriteria, {
      headers: this.headers,
      observe: 'body'
    });
  }
}
