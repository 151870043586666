import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import { StockWarehouseContent, StockWarehouseSearchCriteria } from '@shared/models';

import { StockInformationAction, StockInformationActionTypes } from './stock-information.actions';
import { StockInformationStates } from './stock-information.states';

export const stockInformationWarehouseModuleReducers: ActionReducerMap<Partial<StockInformationStates>> = {
  stockInformationWarehouse: stockInformationWarehouseReducers
};

export interface StockInformationWarehouseState extends EntityState<StockWarehouseContent> {
  isLoading: boolean;
  criteriaObject: StockWarehouseSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<StockWarehouseContent> = createEntityAdapter<StockWarehouseContent>();

export const initialStockInformationWarehouseState: StockInformationWarehouseState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function stockInformationWarehouseReducers(
  state = initialStockInformationWarehouseState,
  action: StockInformationAction
): StockInformationWarehouseState {
  switch (action.type) {
    case StockInformationActionTypes.STOCK_INFORMATION_WAREHOUSE_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload.criteria
      };
    case StockInformationActionTypes.STOCK_INFORMATION_WAREHOUSE_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StockInformationActionTypes.STOCK_INFORMATION_WAREHOUSE_RESET:
      return initialStockInformationWarehouseState;
    default: {
      return { ...state };
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
