<div [formGroup]="parentForm" class="store-profile-wrapper">
  <div formArrayName="storeProfile" *ngFor="let tdStore of storeProfile.controls; let i = index; first as firstIndex">
    <div [formGroupName]="i">
      <div class="row">
        <div class="col-md-3 mb-3">
          <label for="storeCode">Store Code<span class="text-danger">*</span></label>
          <input
            id="storeCode"
            name="storeCode"
            type="text"
            class="form-control"
            maxlength="8"
            formControlName="storeCode"
            placeholder="Store Code"
            appAlphanumericOnly
            toUpperCase="true"
            [ngClass]="{
              'is-invalid': isShowDuplicatedError(tdStore.controls.storeCode),
              'bg-h-change': isRequestTypeEdit(type) && listOfChange?.storeCode
            }"
          />
          <div *ngIf="tdStore.controls.storeCode.errors?.duplicated" class="invalid-feedback">
            <div *ngIf="tdStore.controls.storeCode.errors?.duplicated">
              {{ 'ERRORS.DUPLICATED_FIELD' | translate: { value: 'Store Code' } }}
            </div>
          </div>
          <div *ngIf="(saved || submitted) && tdStore.controls.storeCode.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.storeCode.errors.required; else invalidStoreCode">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <ng-template #invalidStoreCode>
              <div *ngIf="tdStore.controls.storeCode.errors.invalidStoreCode">
                {{ 'ERRORS.DUPLICATED_FIELD' | translate: { value: 'Store Code' } }}
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="storeName">Store Name<span class="text-danger">*</span></label>
          <input
            id="storeName"
            name="storeName"
            type="text"
            class="form-control"
            maxlength="100"
            formControlName="storeName"
            placeholder="Store Name"
            [ngClass]="{
              'is-invalid': (saved || submitted) && tdStore.controls.storeName.errors,
              'bg-h-change': isRequestTypeEdit(type) && listOfChange?.storeName
            }"
          />
          <div *ngIf="(saved || submitted) && tdStore.controls.storeName.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.storeName.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="storeTaxNo">Tax No.<span class="text-danger">*</span></label>
          <input
            id="storeTaxNo"
            name="storeTaxNo"
            type="text"
            class="form-control"
            maxlength="13"
            formControlName="taxNo"
            placeholder="Tax No."
            appDigitOnly
            readOnly
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.taxNo.errors,
              'bg-h-change': isRequestTypeEdit(type) && listOfChange?.storeTaxNo
            }"
          />
          <div *ngIf="submitted && tdStore.controls.taxNo.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.taxNo.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="tdStore.controls.taxNo.errors.minlength">
              {{ 'ERRORS.INVALID_FORMAT' | translate: { value: 'Tax No' } }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="storeBranchNo">Branch No.<span class="text-danger">*</span></label>
          <input
            id="storeBranchNo"
            name="storeBranchNo"
            type="text"
            class="form-control"
            maxlength="5"
            formControlName="branchNo"
            placeholder="Branch No."
            appDigitOnly
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.branchNo.errors,
              'bg-h-change': isRequestTypeEdit(type) && listOfChange?.branchNo
            }"
          />
          <div *ngIf="submitted && tdStore.controls.branchNo.errors as controlErrors" class="invalid-feedback">
            <div *ngIf="controlErrors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="controlErrors.pattern">
              {{ 'ERRORS.REQUIRED_5_DIGITS' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 mb-3">
          <label for="email">Branch Name<span class="text-danger">*</span></label>
          <input
            id="branchName"
            data-id="branchName"
            name="branchName"
            type="text"
            class="form-control"
            maxlength="100"
            formControlName="branchName"
            placeholder="Branch Name"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.branchName.errors,
              'bg-h-change': isRequestTypeEdit(type) && listOfChange?.branchName
            }"
          />
          <div *ngIf="submitted && tdStore.controls.branchName.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.branchName.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="tdStore.controls.branchName.errors.email">
              {{ 'ERRORS.INVALID_EMAIL' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="email">Store Email<span class="text-danger">*</span></label>
          <input
            id="email"
            data-id="email"
            name="email"
            type="email"
            class="form-control"
            maxlength="100"
            formControlName="email"
            placeholder="Store Email"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.email.errors,
              'bg-h-change': isRequestTypeEdit(type) && listOfChange?.email
            }"
          />
          <div *ngIf="submitted && tdStore.controls.email.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.email.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="tdStore.controls.email.errors.email">
              {{ 'ERRORS.INVALID_EMAIL' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="openDate">Store Open Date<span class="text-danger">*</span></label>
          <input
            id="openDate"
            name="openDate"
            type="text"
            placeholder="{{ dateFormat }}"
            class="form-control calendar"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': submitted && submitted && tdStore.controls.openDate.errors,
              'bg-h-change': isRequestTypeEdit(type) && listOfChange?.openDate
            }"
            bsDatepicker
            [bsConfig]="bsConfig"
            formControlName="openDate"
            readonly
          />
          <label for="openDate" class="icon-calendar btn-calendar"></label>
          <div *ngIf="submitted && tdStore.controls.openDate.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.openDate.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="terminateDate">Terminate Date</label>
          <input
            id="terminateDate"
            data-id="terminateDate"
            name="terminateDate"
            type="text"
            placeholder="{{ dateFormat }}"
            class="form-control calendar"
            autocomplete="off"
            bsDatepicker
            [bsConfig]="bsConfig"
            formControlName="terminateDate"
            readonly
            [ngClass]="{ 'bg-h-change': isRequestTypeEdit(type) && listOfChange?.terminateDate }"
          />
          <label for="terminateDate" class="icon-calendar btn-calendar"></label>
        </div>
      </div>
    </div>
  </div>
</div>
