<div class="modal-content admin-form">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()" id="closeBtn">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <ng-container *ngIf="isPreOrder">
        <app-pre-order-policy #preOrderPolicy [parentForm]="form" [submitted]="submitted" [mode]="mode" [page]="page">
        </app-pre-order-policy>
      </ng-container>
      <ng-container *ngIf="isOrder">
        <app-order-policy #orderPolicy [parentForm]="form" [submitted]="submitted" [mode]="mode" [page]="page">
        </app-order-policy>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="onCancel()" id="cancelBtn">
      Cancel
    </button>
    <button
      id="okBtnPreOrder"
      data-id="okBtnPreOrder"
      type="button"
      class="btn btn-primary"
      (click)="onSubmit()"
      [isFormInvalid]="form.invalid"
      appDisableButton
    >
      Submit
    </button>
  </div>
</div>
