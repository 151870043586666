import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MetricActionType } from '@shared/models/metrics';
import { PreOrderTransactionReportCriteria, ReportFileType } from '@shared/models/report.model';
import { BaseService } from '@shared/services/base.service';
import { metricsReportDataHub } from '@shared/utils/metrics-util';

import { environment } from '../../../environments/environment';

@Injectable()
export class PreOrderTransactionsReportService extends BaseService {
  public headers: HttpHeaders;
  public env = environment;
  public envService: any;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.preOrderTransactionsReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportTransactions(criteria: PreOrderTransactionReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.transaction);
    const exportType =
      criteria.fileType === ReportFileType.CSV ? MetricActionType.EXPORT_CSV : MetricActionType.EXPORT_XLSX;
    const metrics = metricsReportDataHub([this.envService.url, this.envService.transactionExport], exportType);

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportTransactionsConfidential(criteria: PreOrderTransactionReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.transactionsConfidential);
    const exportType =
      criteria.fileType === ReportFileType.CSV ? MetricActionType.EXPORT_CSV : MetricActionType.EXPORT_XLSX;
    const metrics = metricsReportDataHub([this.envService.url, this.envService.transactionExport], exportType);

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  public exportCompensate(criteria: any): Observable<any> {
    const url = this.getFullUrl(this.envService.compensate);
    const exportType =
      criteria.fileType === ReportFileType.CSV ? MetricActionType.EXPORT_CSV : MetricActionType.EXPORT_XLSX;
    const metrics = metricsReportDataHub([this.envService.url, this.envService.transactionExport], exportType);

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
