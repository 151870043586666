import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Subject } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-ui-pagination',
  templateUrl: './ui-pagination.component.html',
  styleUrls: ['./ui-pagination.component.scss']
})
export class UiPaginationComponent implements OnChanges {
  @Output() currentPageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() pageSizeList: number[];
  @Input() items: Array<any>;
  @Input() valueChangeDetector: Array<any> | null;
  @Input() dataId = 'recordPerPage';

  public environment: { [key: string]: any };

  @Input()
  get currentPage() {
    return this._currentPage;
  }

  set currentPage(value) {
    this._currentPage = value;
    this.currentPageChange.emit(this._currentPage);
  }

  @Input()
  get pageSize() {
    return this._pageSize;
  }

  set pageSize(value) {
    this._pageSize = value;
    this.pageSizeChange.emit(this._pageSize);
  }

  get currentPageData() {
    return this._currentPageData;
  }

  set currentPageData(value) {
    this._currentPageData = value;
    this.currentPageData$.next(value);
  }

  private _currentPageData: Array<any>;
  public currentPageData$ = new Subject<Array<any>>();
  public _currentPage: number;
  public _pageSize: number;

  constructor() {
    this.environment = environment;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items || changes.valueChangeDetector) {
      this.calculatePages(this.currentPage);
    }
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.currentPageData = [...this.items.slice(startItem, endItem)];
  }

  onChangeRowPerPage(value: string): void {
    this.pageSize = Number(value);
    this.resetPage();
  }

  resetPage() {
    const startPage = (Math.ceil(this.currentPage / this.pageSize) - 1) * this.pageSize + 1;
    // Adjust last page if limit is exceeded
    const endPage = Math.min(startPage + this.pageSize - 1, this.calculateTotalPages());
    if (this.currentPage > endPage) {
      this.currentPage = endPage;
    }
    this.calculatePages(this.currentPage);
  }

  calculateTotalPages() {
    const totalItems = this.items ? this.items.length : 0;
    const totalPages = this.pageSize < 1 ? 1 : Math.ceil(totalItems / this.pageSize);
    return Math.max(totalPages || 0, 1);
  }

  calculatePages(currentPage: number) {
    const current = Number(currentPage);
    const startItem = (current - 1) * this.pageSize;
    const endItem = current * this.pageSize;
    this.currentPageData = [...this.items.slice(startItem, endItem)];
  }

  navigateToErrorIndex(index: number) {
    const pageEvent: PageChangedEvent = {
      itemsPerPage: 0,
      page: 0
    };
    const itemsPerPage = this.pageSize;
    const page = Math.ceil((index + 1) / itemsPerPage);

    pageEvent.itemsPerPage = itemsPerPage;
    pageEvent.page = page;
    this.pageChanged(pageEvent);
  }

  getItemNo(index: number) {
    return this.pageSize * (this.currentPage - 1) + index + 1;
  }

  getIndexNo(index: number) {
    return this.pageSize * (this.currentPage - 1) + index;
  }
}
