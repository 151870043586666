import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ProductMasterService } from '../../services';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ProductMasterActionTypes,
  ProductMasterViewLoaded,
  ProductMasterViewRequested
} from '../actions/product-master.actions';

@Injectable()
export class ProductMasterEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly productMasterService: ProductMasterService,
    private readonly logger: NGXLogger
  ) {}

  loadProductMaster$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ProductMasterViewRequested>(ProductMasterActionTypes.ProductMasterViewRequested),
      tap(action => this.logger.debug('@Effect Product Master : ' + JSON.stringify(action.payload))),
      switchMap(action => {
        return this.productMasterService.findProductMasterByArticleNo(action.payload.articleNo).pipe(
          map(productMaster => new ProductMasterViewLoaded({ productMaster })),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });
}
