import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  forwardRef
} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as numeral from 'numeral';

export const Helper = {
  anyChanges: (properties, changes) => {
    for (let _i = 0, _properties = properties; _i < _properties.length; _i++) {
      const property = _properties[_i];
      if (changes[property] !== undefined) {
        return true;
      }
    }
    return false;
  },
  createNumericRegex(hasDecimal, hasSign) {
    let regexString = '^';
    if (hasSign) {
      regexString += '-?';
    }
    regexString += '(?:(?:\\d+';
    if (hasDecimal) {
      regexString += '(\\.\\d*)?';
    }
    regexString += ')|(?:\\.\\d*))?$';
    return new RegExp(regexString);
  }
};

export function createMinValidator(min) {
  return function(control) {
    if (_.isNumber(control.value) && control.value < min) {
      return {
        minError: {
          minValue: min,
          value: control.value
        }
      };
    }
    return null;
  };
}

export function createMaxValidator(max) {
  return function(control) {
    if (_.isNumber(control.value) && control.value > max) {
      return {
        maxError: {
          maxValue: max,
          value: control.value
        }
      };
    }
    return null;
  };
}

@Component({
  selector: 'app-numeric-textbox',
  template: `
    <input
      type="text"
      [class]="classValue"
      [attr.maxlength]="maxlength"
      [attr.placeholder]="placeholder"
      [disabled]="disabled"
      (input)="handleInput()"
      (focus)="handleFocus()"
      (blur)="handleBlur()"
      (keydown)="handleKeyDown($event)"
      #numericInput
    />
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericTextboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumericTextboxComponent),
      multi: true
    }
  ],
  exportAs: 'NumericTextboxComponent'
})
export class NumericTextboxComponent implements OnChanges {
  private readonly renderer2;
  minValidateFn;
  maxValidateFn;
  focused;
  inputValue;
  previousValue;
  numericRegex;
  ngChange;
  ngTouched;

  @ViewChild('numericInput', { static: true }) numericInput: ElementRef;
  @Input() negativeSign: boolean;
  @Input() maxlength: number;
  @Input() min: number;
  @Input() max: number;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() cssClass: string;
  @Input() value: number;
  @Input() placeholder: string;
  @Input() decimals: number;
  @Input() disabled: boolean;
  @Input() format: string;
  @Input() autoCorrect: boolean;
  @Input() rangeValidation: boolean;
  @Input() allowPaste: boolean;
  @Input() allowMinus: boolean;

  @Output() valueChange: EventEmitter<number>;
  @Output() focus: EventEmitter<{}>;
  @Output() blur: EventEmitter<number>;
  @Output() enter: EventEmitter<{}>;
  @Output() escape: EventEmitter<{}>;

  constructor(renderer2: Renderer2) {
    this.negativeSign = true;
    this.renderer2 = renderer2;
    this.min = Number.MIN_SAFE_INTEGER;
    this.max = Number.MAX_SAFE_INTEGER;
    this.minValue = null;
    this.maxValue = null;
    this.cssClass = '';
    this.decimals = 2;
    this.disabled = false;
    this.format = '0,0.00';
    this.autoCorrect = false;
    this.rangeValidation = true;
    this.valueChange = new EventEmitter();
    this.focus = new EventEmitter();
    this.blur = new EventEmitter<number>();
    this.enter = new EventEmitter();
    this.escape = new EventEmitter();
    this.minValidateFn = Validators.nullValidator;
    this.maxValidateFn = Validators.nullValidator;
    this.focused = false;
    this.previousValue = undefined;
    this.ngChange = _value => {};
    this.ngTouched = () => {};
    this.allowPaste = true;
    this.allowMinus = true;

    numeral.options.scalePercentBy100 = false;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (!this.allowPaste) {
      event.preventDefault();
      return;
    }

    const pattern = `^0-9${this.negativeSign ? '\\-' : ''}`;
    const regexp = new RegExp(`[${pattern}.]`, 'g');
    const pastedInput: string = event.clipboardData.getData('text/plain').replace(regexp, ''); // get a digit-only string
    event.preventDefault();
    document.execCommand('insertText', false, pastedInput);
  }

  focusInput(): void {
    this.numericInput.nativeElement.focus();
  }

  blurInput(): void {
    this.numericInput.nativeElement.blur();
  }

  validate(
    control: AbstractControl
  ): {
    [key: string]: any;
  } {
    return this.minValidateFn(control) || this.maxValidateFn(control);
  }

  writeValue(value: number): void {
    const newValue = this.restrictModelValue(value);
    this.value = newValue;
    this.setInputValue();
  }

  registerOnChange(fn: any): void {
    this.ngChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.ngTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.verifySettings();
    if (Helper.anyChanges(['autoCorrect', 'decimals'], changes)) {
      delete this.numericRegex;
    }
    if (Helper.anyChanges(['min', 'max', 'rangeValidation'], changes)) {
      if (_.isNumber(this.min) && this.rangeValidation) {
        this.minValidateFn = createMinValidator(this.min);
      } else {
        this.minValidateFn = Validators.nullValidator;
      }
      if (_.isNumber(this.max) && this.rangeValidation) {
        this.maxValidateFn = createMaxValidator(this.max);
      } else {
        this.maxValidateFn = Validators.nullValidator;
      }
      this.ngChange(this.value);
    }
    if (Helper.anyChanges(['format'], changes)) {
      this.setInputValue();
    }
  }

  handleInput(): void {
    const element = this.numericInput.nativeElement;
    const selectionStart = element.selectionStart;
    const selectionEnd = element.selectionEnd;
    const value = element.value;

    if (!this.isValidInput(value)) {
      element.value = this.inputValue;
      this.setSelection(selectionStart - 1, selectionEnd - 1);
    } else {
      const orginalInputValue = this.parseNumber(value);
      let limitInputValue = this.restrictDecimals(orginalInputValue);
      if (this.autoCorrect) {
        limitInputValue = this.limitValue(limitInputValue);
      }
      if (orginalInputValue !== limitInputValue) {
        this.setInputValue(limitInputValue);
        this.setSelection(selectionStart, selectionEnd);
      } else {
        this.inputValue = value;
      }
      this.updateValue(limitInputValue);
    }
  }

  handleFocus(): void {
    if (!this.focused) {
      this.focused = true;
      this.setInputValue();
      setTimeout(() => {
        return this.setSelection(0, this.inputValue.length);
      });
    }
    this.focus.emit();
  }

  handleBlur(): void {
    if (this.focused) {
      this.focused = false;
      this.ngTouched();
      this.setInputValue();
    }
    this.blur.emit(this.value);
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (!this.disabled) {
      switch (event.key) {
        case 'Enter':
          this.enter.emit();
          break;
        case 'Esc': // IE/Edge specific value
        case 'Escape':
          this.escape.emit();
          break;
        default:
      }
    }

    const element = this.numericInput.nativeElement;
    const selectionStart = element.selectionStart;
    const selectionEnd = element.selectionEnd;
    const isNewValue = selectionEnd - selectionStart === 0;
    const currentValue = Number(`${this.numericInput.nativeElement.value}` + event.key);
    const isInvalidMinValue = this.minValue && isNewValue && currentValue < this.minValue;
    const isInvalidMaxValue = this.maxValue && isNewValue && currentValue > this.maxValue;
    const isInvalidNegativeSign = !this.negativeSign && event.key === '-';

    if (isInvalidMinValue || isInvalidMaxValue || isInvalidNegativeSign) {
      event.preventDefault();
    }
  }

  private verifySettings() {
    if (_.isNumber(this.min) && _.isNumber(this.max) && this.min > this.max) {
      throw new Error('The max value should be bigger than the min value');
    }
    if (_.isNumber(this.decimals) && this.decimals < 0) {
      throw new Error('The decimals value should be bigger than 0');
    }
  }

  private isValidInput(input) {
    let numericRegex = this.numericRegex;
    if (_.isNil(numericRegex)) {
      let hasDecimal = true;
      if (_.isNumber(this.decimals) && this.decimals === 0) {
        hasDecimal = false;
      }
      let hasSign = true;
      if ((_.isNumber(this.min) && this.min >= 0 && this.autoCorrect) || !this.allowMinus) {
        hasSign = false;
      }
      numericRegex = Helper.createNumericRegex(hasDecimal, hasSign);
    }
    return numericRegex.test(input);
  }

  private parseNumber(input) {
    return numeral(input).value();
  }

  private limitValue(value) {
    if (_.isNumber(this.max) && value > this.max) {
      return this.max;
    }
    if (_.isNumber(this.min) && value < this.min) {
      return this.min;
    }
    return value;
  }

  // @ts-ignore
  private isInRange(value) {
    if (_.isNumber(value)) {
      if (_.isNumber(this.min) && value < this.min) {
        return false;
      }
      if (_.isNumber(this.max) && value > this.max) {
        return false;
      }
    }
    return true;
  }

  private restrictModelValue(value) {
    let newValue = this.restrictDecimals(value);
    if (this.autoCorrect && this.limitValue(newValue) !== newValue) {
      newValue = null;
    }
    return newValue;
  }

  private restrictDecimals(value) {
    if (_.isNumber(this.decimals)) {
      const words = String(value).split('.');
      if (words.length === 2) {
        const decimalPart = words[1];
        if (decimalPart.length > this.decimals) {
          value = +`${words[0]}.${decimalPart.substr(0, this.decimals)}`;
        }
      }
    }
    return value;
  }

  private setInputValue(value = this.value) {
    const inputValue = this.formatValue(value);
    this.renderer2.setProperty(this.numericInput.nativeElement, 'value', inputValue);
    this.inputValue = inputValue;
  }

  private updateValue(value) {
    if (this.value !== value) {
      this.previousValue = this.value;
      this.value = value;
      this.ngChange(value);
      this.valueChange.emit(value);
    }
  }

  private formatValue(value) {
    if (!value && value !== 0) {
      return '';
    }

    if (this.focused) {
      return this.formatInputValue(value);
    } else {
      return this.formatNumber(value);
    }
  }

  private formatInputValue(value) {
    return String(value);
  }

  private formatNumber(value) {
    return numeral(value).format(this.format);
  }

  private setSelection(start, end) {
    this.numericInput.nativeElement.setSelectionRange(start, end);
  }

  // @ts-ignore
  private isNumber(input: string | number) {
    return +input || input === '0';
  }

  get classValue() {
    this.cssClass += this.cssClass.search(/form\-control/gi) === -1 ? ' form-control' : '';
    this.cssClass += this.cssClass.search(/w\-100/gi) === -1 ? ' w-100' : '';

    return this.cssClass;
  }
}
