import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { BillToBase } from '../../models/warehouse.model';
import { BillToMasterAction, BillToMasterActionTypes } from '../actions/bill-to-master.actions';

export type BillToMasterState = EntityState<BillToBase>;

export const adapter: EntityAdapter<BillToBase> = createEntityAdapter<BillToBase>({
  selectId: (billToBase: BillToBase) => billToBase.code
});

export const initialBillToMasterState: BillToMasterState = adapter.getInitialState();

export function billToMasterReducers(
  state = initialBillToMasterState,
  action: BillToMasterAction
): BillToMasterState {
  if (action.type === BillToMasterActionTypes.BILL_TO__MASTER_LIST_RESPONSE) {
    return adapter.setAll(action.payload, { ...state });
  } else {
    return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
