import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';

import { environment as env } from '../../../environments/environment';

@Injectable()
export class CookiesUtil {
  private readonly MAXSIZE = env.cookies.maxSizePerCookies;

  constructor(private readonly cookieService: CookieService) {}

  cookieAmount = (str): number => {
    return Math.ceil(str.length / this.MAXSIZE);
  };

  public splitByLength = (str, cookiesName, cookieOptions) => {
    const currentDate = new Date();
    const expiryDate = new Date(currentDate.getTime() + env.cookies.bufferTimePeriod);
    const tokenExpiryDate = new Date(
      moment(expiryDate)
        .add(1, 'week')
        .toDate()
    );
    const amount = this.cookieAmount(str).toString();
    const amountName = this.patternName({ cookiesName, concatName: 'amount' });

    this.cookieService.set(
      amountName,
      amount,
      tokenExpiryDate,
      cookieOptions.path,
      cookieOptions.domain,
      cookieOptions.secure
    );
    return str.match(new RegExp(`.{1,${this.MAXSIZE}}`, 'g'));
  };

  public generateNameByAmount = cookiesName => {
    const amountName = this.patternName({ cookiesName, concatName: 'amount' });

    if (!this.cookieService.check(amountName)) {
      return [cookiesName];
    }

    const strArray = [];
    const amount = +this.cookieService.get(amountName);

    for (let _i = 1; _i <= amount; _i++) {
      strArray.push(`${cookiesName}_${_i}`);
    }
    return strArray;
  };

  public concatCookie = cookiesName => {
    try {
      return Array.isArray(cookiesName)
        ? cookiesName
            .map(value => this.cookieService.get(value))
            .reduce((total, currentValue) => total + currentValue)
        : this.cookieService.get(cookiesName);
    } catch (err) {
      console.error(err);
    }
  };

  private patternName(params: { cookiesName: string; concatName: string }) {
    return `${params.cookiesName}_${params.concatName}`;
  }
}
