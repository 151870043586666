import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { activeWarehouseReducers } from '@shared/store/reducers/active-warehouse.reducers';
import { hideAllModalReducers } from '@shared/store/reducers/hide-modal.reducers';

import { claimRequestReducers } from '../../../pages/claim-request/store/claim-request/claim-request.reducers';
import { roleReducers } from '../../../pages/roles/stores/role/role.reducers';
import { userByRoleReducers } from '../../../pages/roles/stores/role/user-by-role.reducers';
import { shelfFixAssetRequestReducers } from '../../../pages/shelf/shelf-fix-asset/store/shelf-fix-asset-request/shelf-fix-asset-request.reducers';
import { storeReducers } from '../../../pages/store/store/store.reducers';
import { VoucherCreateReducers } from '../../../pages/voucher/store/voucher-create.reducers';
import { VoucherRequestReducers } from '../../../pages/voucher/store/voucher-request.reducers';
import { VoucherReducers } from '../../../pages/voucher/store/voucher.reducers';
import { AppStates } from '../state/app.states';
import { authUserInfoReducers } from './auth-user-info.reducers';
import { authReducers } from './auth.reducers';
import { billToMasterReducers } from './bill-to-master.reducers';
import { classMarkupReducers } from './class-markup.reducers';
import { clientIdReducers } from './client-id.reducers';
import { customer360Reducers } from './customer360.reducers';
import { firstLotOrderReducers } from './first-lot-order.reducers';
import { forceChangePasswordReducers } from './force-change-password.reducer';
import { forgotPasswordReducers } from './forgot-password.reducer';
import { forgotUsernameReducers } from './forgot-username.reducer';
import { freshLiteReducers } from './fresh-lite.reducers';
import { fullModalReducers } from './full-modal.reducers';
import { layoutReducers } from './layout.reducers';
import { masterDataReducers } from './master-data.reducers';
import { menuReducers } from './menu.reducers';
import { MyReportReducers } from './my-report.reducers';
import { myTaskReducers } from './my-task.reducers';
import { orderCreateReducers } from './order-create.reducers';
import { orderFixAssetCreateReducers } from './order-fix-asset-create.reducers';
import { OrderFixAssetRequestReducers } from './order-fix-asset-request.reducers';
import { OrderInventoryCreateReducers } from './order-inventory-create.reducers';
import { OrderInventoryRequestReducers } from './order-inventory-request.reducers';
import { OrderInventorySelectItemOrderReducers } from './order-inventory-select-item-order.reducers';
import { OrderRequestReducers } from './order-request.reducers';
import { OrderSelectItemOrderReducers } from './order-select-item-order.reducers';
import { OrderSelectItemReducers } from './order-select-item.reducers';
import { OrderSelectiveSelectItemOrderReducers } from './order-selective-select-item-order.reducers';
import { OrderSelectiveSelectItemReducers } from './order-selective-select-item.reducers';
import { OrderStoreUseSelectItemOrderReducers } from './order-store-use-select-item-order.reducers';
import { OrderStoreUseSelectItemReducers } from './order-store-use-select-item.reducers';
import { orderReducers } from './order.reducers';
import { productMasterReducers } from './product-master.reducers';
import { promotionCreateReducers } from './promotion-create.reducers';
import { promotionRequestReducers } from './promotion-request.reducers';
import { promotionThemeReducers } from './promotion-theme.reducers';
import { promotionReducers } from './promotion.reducers';
import { requestAssortmentReducers } from './request-assortment.reducers';
import { resetPasswordPinReducers } from './reset-password-pin.reducer';
import { responseAssignmentReducers } from './response-assignment.reducers';
import { settingReducers } from './setting.reducers';
import { shelfCreateReducers } from './shelf-create.reducers';
import { ShelfDetailsReducers } from './shelf-details.reducers';
import { shelfFixAssetDetailsReducers } from './shelf-fix-asset-details.reducers';
import { shelfRequestReducers } from './shelf-request.reducers';
import { shelfSelectFixAssetOrderReducers } from './shelf-select-fix-asset-order.reducers';
import { ShelfSelectFixAssetReducers } from './shelf-select-fix-asset.reducers';
import { shelfSelectInventoryReducers } from './shelf-select-inventory.reducers';
import { shelfReducers } from './shelf.reducers';
import { stackPricingReducers } from './stack-pricing.reducers';
import { stockProductSelectedReducers } from './stock-product-selected.reducers';
import { stockProductReducers } from './stock-product.reducers';
import { storeCreateReducers } from './store-create.reducers';
import { storeDropdownReducers } from './store-dropdown.reducers';
import { storeRequestReducers } from './store-request.reducers';
import { supplierPriceReducers } from './supplier-price.reducers';
import { supplierReducers } from './supplier.reducers';
import { taskAssignmentReducers } from './task-assignment.reducers';
import { taskRequestReducers } from './task-request.reducers';
import { tdAssortmentOrderReducers } from './td-assortment-order.reducers';
import { tdAssortmentReducers } from './td-assortment.reducers';
import { tenantInfoReducers } from './tenant-info.reducers';
import { userCreateReducers } from './user-create.reducers';
import { UsersReducers } from './users.reducers';
import { warehouseMasterReducers } from './warehouse-master.reducers';
import { warehouseReducers } from './warehouse.reducers';

export const appReducers: ActionReducerMap<AppStates, any> = {
  taskAssignment: taskAssignmentReducers,
  auth: authReducers,
  billToMaster: billToMasterReducers,
  classMarkup: classMarkupReducers,
  clientId: clientIdReducers,
  customer360: customer360Reducers,
  firstLotOrder: firstLotOrderReducers,
  forceChangePassword: forceChangePasswordReducers,
  forgotPassword: forgotPasswordReducers,
  forgotUsername: forgotUsernameReducers,
  freshLite: freshLiteReducers,
  fullModal: fullModalReducers,
  layout: layoutReducers,
  masterData: masterDataReducers,
  // masterAssortment: masterAssortmentReducers,
  menus: menuReducers,
  myTask: myTaskReducers,
  myReport: MyReportReducers,
  order: orderReducers,
  orderCreate: orderCreateReducers,
  orderFixAssetCreate: orderFixAssetCreateReducers,
  orderRequest: OrderRequestReducers,
  orderFixAssetRequest: OrderFixAssetRequestReducers,
  orderSelectItem: OrderSelectItemReducers,
  orderSelectItemOrder: OrderSelectItemOrderReducers,
  orderStoreUseSelectItem: OrderStoreUseSelectItemReducers,
  orderStoreUseSelectItemOrder: OrderStoreUseSelectItemOrderReducers,
  orderSelectiveSelectItem: OrderSelectiveSelectItemReducers,
  orderSelectiveSelectItemOrder: OrderSelectiveSelectItemOrderReducers,
  orderInventoryRequest: OrderInventoryRequestReducers,
  orderInventoryCreate: OrderInventoryCreateReducers,
  orderInventorySelectItemOrder: OrderInventorySelectItemOrderReducers,
  // productAssortment: productAssortmentReducers,
  // posAdvertisement: PosAdvertisementReducers,
  productMaster: productMasterReducers,
  promotion: promotionReducers,
  promotionCreate: promotionCreateReducers,
  promotionRequest: promotionRequestReducers,
  promotionTheme: promotionThemeReducers,
  requestAssortment: requestAssortmentReducers,
  resetPasswordPin: resetPasswordPinReducers,
  router: routerReducer,
  setting: settingReducers,
  shelf: shelfReducers,
  shelfCreate: shelfCreateReducers,
  shelfDetails: ShelfDetailsReducers,
  shelfRequest: shelfRequestReducers,
  shelfFixAssetRequest: shelfFixAssetRequestReducers,
  shelfSelectFixAsset: ShelfSelectFixAssetReducers,
  shelfSelectFixAssetOrder: shelfSelectFixAssetOrderReducers,
  shelfFixAssetDetails: shelfFixAssetDetailsReducers,
  shelfSelectInventory: shelfSelectInventoryReducers,
  stackPricing: stackPricingReducers,
  stockProduct: stockProductReducers,
  stockProductSelected: stockProductSelectedReducers,
  storeDropdown: storeDropdownReducers,
  store: storeReducers,
  storeCreate: storeCreateReducers,
  storeRequest: storeRequestReducers,
  supplier: supplierReducers,
  supplierPrice: supplierPriceReducers,
  taskRequest: taskRequestReducers,
  responseAssignment: responseAssignmentReducers,
  tdAssortment: tdAssortmentReducers,
  tdAssortmentOrder: tdAssortmentOrderReducers,
  tenantInfo: tenantInfoReducers,
  userCreate: userCreateReducers,
  userInfo: authUserInfoReducers,
  usersList: UsersReducers,
  userByRoleList: userByRoleReducers,
  voucher: VoucherReducers,
  voucherCreate: VoucherCreateReducers,
  voucherRequest: VoucherRequestReducers,
  warehouse: warehouseReducers,
  activeWarehouse: activeWarehouseReducers,
  warehouseMaster: warehouseMasterReducers,
  roles: roleReducers,
  claimRequest: claimRequestReducers,
  hideAllModal: hideAllModalReducers
};
