import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BehaviorSubject, Observable, Subject, combineLatest, of, zip } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { MasterDataEnum } from '../../../enum/master-data.enum';
import { RequestSectionEnum } from '../../../enum/request-section.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../enum/request-step.enum';
import { TDStorePage } from '../../../enum/td-store-page.enum';
import { MasterService } from '../../../services/master.service';
import { AppStates } from '../../../store/state/app.states';
import { getSelectByPage } from '../../../utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../utils/td-store-workflow-util';

interface StoreConditionOption extends NgOption {
  notAllow: UntypedFormControl;
}

@Component({
  selector: 'app-store-condition',
  templateUrl: './store-condition.component.html',
  styleUrls: ['./store-condition.component.scss']
})
export class StoreConditionComponent extends OnDestroyMixin implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() listOfChange: any;

  @Input() set mode(currentMode: RequestPageModesEnum) {
    this.onChangeMode$.next(currentMode);
  }

  @Input() page: TDStorePage;

  localStore: Observable<any>;
  storeConditionValuesWithFormControl: StoreConditionOption[];

  onLoadedStoreConditionValue$ = new Subject<NgOption[]>();
  onChangeMode$ = new BehaviorSubject<RequestPageModesEnum>(null);
  onChangeStoreConditionFormControl$ = new Subject<void>();
  merchantStoreConditions$: Observable<RequestPageModesEnum[]>;

  storeConditionsFormControl = new UntypedFormControl([]);

  type: RequestTypeEnum = RequestTypeEnum.NEW;
  step: RequestStepEnum = RequestStepEnum.PROFILE;

  constructor(
    private masterService: MasterService,
    private readonly store: Store<AppStates>,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil
  ) {
    super();
  }

  ngOnInit(): void {
    this.initialLocalStore();

    this.addStoreConditionFormControl();

    this.onLoadStoreConditionEffect();
    this.initialStoreConditionValue();

    this.onChangeStoreConditionsEffect();
  }

  initialLocalStore() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    const merchantRequestView$ = this.localStore.pipe(select(getSelectByPage(this.page)));

    const merchantStoreConditions$ = merchantRequestView$.pipe(
      filter(value => value !== null),
      tap(value => {
        this.type = value.type || RequestTypeEnum.EDIT;
        this.step = value.step || RequestStepEnum.EDIT_PROFILE;
      }),
      filter(() =>
        this.tdStoreWorkflowUtil.isShowSection(this.type, this.page, this.step, RequestSectionEnum.STORE_PROFILE)
      ),
      map(result => result.merchantInfo.storeProfile[0].storeConditions || [])
    );

    this.merchantStoreConditions$ = this.onChangeMode$.pipe(
      switchMap(mode => {
        if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(mode)) {
          return merchantStoreConditions$;
        }
        return of([]);
      }),
      untilComponentDestroyed(this)
    );
  }

  toggleEditStoreCondition() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;
  }

  onChangeStoreConditionsEffect() {
    this.onChangeStoreConditionFormControl$.pipe(untilComponentDestroyed(this)).subscribe({
      next: () => {
        const storeConditions = this.storeConditionValuesWithFormControl.reduce((results, condition) => {
          if (condition.notAllow.value === true) {
            results.push(condition.code);
          }
          return results;
        }, []);
        this.storeConditionsFormControl.setValue(storeConditions);
      }
    });
  }

  transformStoreConditions(data: NgOption, formValue: boolean | any): StoreConditionOption {
    return {
      ...data,
      notAllow: new UntypedFormControl(formValue)
    };
  }

  onLoadStoreConditionEffect(): void {
    const onRequestCreateMode$ = this.onChangeMode$.pipe(
      filter(mode => mode === RequestPageModesEnum.REQUEST_CREATE)
    );
    const onInitialStoreConditionCreateMode$ = zip(this.onLoadedStoreConditionValue$, onRequestCreateMode$);

    const onRequestViewMode$ = this.onChangeMode$.pipe(filter(mode => mode === RequestPageModesEnum.REQUEST_VIEW));
    const onInitialStoreConditionViewMode$ = zip(
      this.onLoadedStoreConditionValue$,
      this.merchantStoreConditions$,
      onRequestViewMode$
    );

    const onRequestEditMode$ = this.onChangeMode$.pipe(filter(mode => mode === RequestPageModesEnum.REQUEST_EDIT));
    const onInitialStoreConditionEditMode$ = combineLatest([
      this.onLoadedStoreConditionValue$,
      this.merchantStoreConditions$,
      onRequestEditMode$
    ]);

    onInitialStoreConditionCreateMode$.pipe(untilComponentDestroyed(this)).subscribe({
      next: ([storeConditionValues]) => {
        this.storeConditionValuesWithFormControl = storeConditionValues.map(condition =>
          this.transformStoreConditions(condition, false)
        );
        this.onChangeStoreConditionFormControl$.next();
      }
    });

    onInitialStoreConditionViewMode$
      .pipe(untilComponentDestroyed(this))
      .subscribe(([storeConditionValues, merchantStoreCondition]) => {
        this.storeConditionValuesWithFormControl = storeConditionValues.map(condition =>
          this.transformStoreConditions(condition, {
            value: merchantStoreCondition.includes(condition.code),
            disabled: true
          })
        );
        this.onChangeStoreConditionFormControl$.next();
      });

    onInitialStoreConditionEditMode$
      .pipe(
        map(([storeConditionValues, merchantStoreCondition]) => {
          const editSection = [TDStorePage.MERCHANT_EDIT, TDStorePage.MERCHANT_REQUEST].includes(this.page)
            ? RequestSectionEnum.PROFILE
            : RequestSectionEnum.STORE_PROFILE;

          const canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(
            this.type,
            this.page,
            this.step,
            editSection
          );

          return storeConditionValues.map(condition =>
            this.transformStoreConditions(condition, {
              value: merchantStoreCondition.includes(condition.code),
              disabled: !canEditByWorkflow
            })
          );
        }),
        untilComponentDestroyed(this)
      )
      .subscribe({
        next: storeConditionValues => {
          this.storeConditionValuesWithFormControl = storeConditionValues;
          this.onChangeStoreConditionFormControl$.next();
        }
      });
  }

  addStoreConditionFormControl() {
    this.parentForm.addControl('storeConditions', this.storeConditionsFormControl);
  }

  initialStoreConditionValue() {
    this.masterService
      .getMasterDataByNames([MasterDataEnum.STORE_CONDITIONS])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe({
        next: ({ storeConditions }) => {
          this.onLoadedStoreConditionValue$.next(storeConditions);
        }
      });
  }

  isRequestTypeEdit(requestType: RequestTypeEnum) {
    return requestType === RequestTypeEnum.EDIT;
  }
}
