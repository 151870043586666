import { OrderingMethodEnum } from '../enum/ordering-method.enum';
import { ProductTypeEnum } from '../enum/product-type.enum';
import { RequestSectionEnum } from '../enum/request-section.enum';
import { Sort } from './pagination.model';
import { AssortmentDeliveryMethodEnum, ProductImage, ProductStatusEnum } from './request-assortment.model';

export class ProductAssortmentSearchCriteria {
  searchCriteria?: string;
  lastUpdatedDateFrom?: string;
  lastUpdatedDateTo?: string;
  segment?: string;
  family?: string;
  classCode?: string;
  subClass?: string;
  productType?: string[];
  orderingMethods?: string[];
  deliveryMethods?: string[];
  productStatus?: string;
  page: number;
  size: number;
}

export class ProductAssortmentResponse {
  id: number;
  content: ProductAssortmentList[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface ProductAssortmentList {
  id: string;
  productName: string;
  productDisplayName: string;
  articleNo: string;
  segmentName: string;
  familyName: string;
  className: string;
  subClassName: string;
  lastUpdated: string;
  productStatus: ProductStatusEnum;
  catalogStatus: string;
  useCJProduct: boolean;
  articleType: string;
  productType: ProductTypeEnum;
  productImage: ProductImage;
  orderingMethods: RequestSectionEnum[];
  deliveryMethod: AssortmentDeliveryMethodEnum;
}

export class ExportAssortmentSearchCriteria {
  segment?: string;
  family?: string;
  classCode?: string;
  subClass?: string;
  productType?: ProductTypeEnum[];
  orderingMethods?: OrderingMethodEnum[];
  deliveryMethods?: AssortmentDeliveryMethodEnum[];
  productStatuses?: ProductStatusEnum[];
  fileType: string;
}
