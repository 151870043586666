import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { FreshLiteCategory, FreshLiteUpdateResponse } from '../../models';
import { FreshLiteActionTypes, FreshLiteActions } from '../actions/fresh-lite.actions';

export interface FreshLiteState extends EntityState<FreshLiteCategory> {
  isLoading: boolean;
  saveSuccess: boolean;
  isSuccess: boolean;
  duplicate: FreshLiteUpdateResponse;
}

export const adapter: EntityAdapter<FreshLiteCategory> = createEntityAdapter<FreshLiteCategory>();

export function sortByName(a: FreshLiteCategory, b: FreshLiteCategory): number {
  if (a.code && b.code) {
    return b.code.localeCompare(a.code);
  } else {
    return b.index.localeCompare(a.index);
  }
}

export const initialFreshLiteState: FreshLiteState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  isSuccess: false,
  duplicate: null
});

export function freshLiteReducers(state = initialFreshLiteState, action: FreshLiteActions): FreshLiteState {
  switch (action.type) {
    case FreshLiteActionTypes.FRESH_LITE_LIST_LOADED: {
      return adapter.setAll(action.payload, state);
    }
    case FreshLiteActionTypes.ADD_FRESH_LITE: {
      return adapter.addOne(action.payload, state);
    }
    case FreshLiteActionTypes.UPDATE_FRESH_LITE_RESPONSE: {
      return {
        ...state,
        duplicate: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
