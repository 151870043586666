import { Amount } from '@shared/models/amount.model';
import { BaseReport } from '@shared/models/base-report.model';
import { Sort } from '@shared/models/pagination.model';
import { AuditLog } from '@shared/models/promotion.model';

import { RewardCondition, RewardOffer } from '../enum/reward.enum';

export class RewardValidate {
  details: Details;
  barcodes: string[];
  amounts: string[];
  selectStore: string;
  stores?: any[];
}

export class RewardValidateResponse {
  isError: boolean;
  items: RewardItemValidateResponse[];
}

export class RewardItemValidateResponse {
  barcode: string;
  amount: number;
  errorMessage: string;
}

export class ConditionPurchase {
  condition: RewardCondition;
  offer: RewardOffer;
}

export class RewardRequestResponse {
  requestNo: string;
  rewardNo: string;
  id: null;
  type: string;
  status: string;
  version: number;
  details: Details;
  purchaseAmtCouponItems: PurchaseAmtCouponItem[];
  purchaseAmtGoodsItems: PurchaseAmtGoodsItem[];
  purchaseQtyDetails: PurchaseQtyDetails;
  purchaseQtyItems: OfferItem[];
  supplierCouponDetails: SupplierCouponDetails;
  supplierCouponItems: SupplierCouponItem[];
  selectStore: string;
  stores: RewardStore[];
  unlimitedPerBill: boolean;
  unlimitedOffer: boolean;
  limitOffer: number;
}

export class SupplierCouponDetails {
  couponAmount: Amount;
  minPerBillAmount: Amount;
  applyTo: string;
}

export class SupplierCouponItem {
  barcode: string;
  articleNo: string;
  productName: string;
  unit: string;
  vat?: boolean;
  purchaseQuantity: number;
}

export class Details {
  rewardName: string;
  condition: string;
  offer: string;
  alwaysOffer: boolean;
  offerFromDate: string;
  offerToDate: string;
  limitPerBill: number;
  unlimitedPerBill: boolean;
  redeemFromDate: string;
  redeemToDate: string;
  supplier: RewardSupplier;
  offerPromotion: boolean;
}

export class RewardSupplier {
  supplierCode: string;
  supplierName: string;
  branchNo?: string;
}

export class PurchaseAmtCouponItem {
  purchaseAmount: Amount;
  couponAmount: Amount;
  minPerBillAmount: Amount;
}

export class PurchaseAmtGoodsItem {
  purchaseAmount: Amount;
  offerItem: OfferItem;
  wholesalePriceAmount: Amount;
  offerQty: number;
  vat?: boolean;
  redeemPrice: RedeemPrice;
  totalRedeemPriceIncVat: Amount;
}

export class OfferItem {
  barcode: string;
  articleNo: string;
  productName: string;
  unit: string;
  vat?: boolean;
  unitFactor: string;
}

export class RedeemPrice {
  type: string;
  amount: Amount;
}

export class PurchaseQtyDetails {
  purchaseQty: number;
  couponAmount: Amount;
  minPerBillAmount: Amount;
  offerItem: OfferItem;
  wholesalePriceAmount: Amount;
  totalRedeemPriceIncVat: Amount;
  offerQty: number;
  redeemPriceAmount: Amount;
  free: boolean;
  vat?: boolean;
}

export class RewardRequestSearchCriteria {
  searchCriteria?: string;
  status?: string;
  offerFromDateFrom?: string;
  offerFromDateTo?: string;
  offerToDateFrom?: string;
  offerToDateTo?: string;
  requestedDateFrom?: string;
  requestedDateTo?: string;
  validDateFrom?: string;
  validDateTo?: string;
  page: number;
  size: number;
}

export class RewardRequestContent {
  id: string;
  requestNo: string;
  rewardName: string;
  offerFromDate?: string;
  offerToDate?: string;
  requestedDate?: string;
  requestedByName: string;
  createdDate: string;
  status: string;
  auditLogs: AuditLog[];
}

export class RewardRequestListResponse {
  id: number;
  content: RewardRequestContent[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class RewardStore {
  no: string;
  code: string;
  name: string;
  type: string;
  region: string;
  province: string;
  limitOffer: number;
}

export interface Store {
  no: string;
  code: string;
  name: string;
  type: string;
  region: string;
  regionDisplayName: string;
  province: string;
  provinceDisplayName: string;
  limitOffer: number;
  unlimited: boolean;
  remark?: any;
}

export class RewardSearchCriteria {
  searchCriteria?: string;
  status?: string;
  condition?: string[];
  offer?: string[];
  offerFromDateFrom?: string;
  offerFromDateTo?: string;
  offerToDateFrom?: string;
  offerToDateTo?: string;
  validDateFrom?: string;
  validDateTo?: string;
  page: number;
  size: number;
}

export class RewardListResponse {
  id: number;
  content: RewardContent[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class RewardContent {
  id: string;
  rewardNo: string;
  rewardName: string;
  condition: string;
  noOfStore: string;
  offer: string;
  offerFromDate?: string;
  offerToDate?: string;
  createdDate: string;
  status: string;
  alwaysOffer: boolean;
  auditLogs: AuditLog[];
}

export class RequestRewardWithComment {
  id: string;
  comment: string;
}

export class RewardActiveSummary {
  activeRewardTotal: number;
  alwaysOfferTrueTotal: number;
  selectableOfferTotal: number;
}

export class RewardExportCriteria extends BaseReport {
  condition?: string;
  offer?: string;
  offerFromDateFrom?: string;
  offerFromDateTo?: string;
  offerToDateFrom?: string;
  offerToDateTo?: string;
  status?: string;
  validDateFrom?: string;
  validDateTo?: string;

  constructor(init?: Partial<RewardExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
