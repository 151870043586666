import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { round } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { environment as env } from '../../../../../environments/environment';
import {
  PromotionRequestPageModes,
  PromotionRequestResponse,
  PromotionTransFrom,
  PromotionTypeEnum
} from '../../../../shared/models';
import { selectRequestPromotionData } from '../../../../shared/store/selectors/promotion-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-promotion-condition',
  templateUrl: './promotion-condition.component.html',
  styleUrls: ['./promotion-condition.component.scss']
})
export class PromotionConditionComponent implements OnInit, OnDestroy {
  @Input() saveDraft: boolean;
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() mode: PromotionRequestPageModes;
  promotionRequestView$: Observable<PromotionRequestResponse>;
  forceBuySubscription: Subscription;
  promotionTypeSubscription: Subscription;
  buyCoItemQtyValue: number;

  constructor(protected readonly store: Store<AppStates>, protected fb: UntypedFormBuilder) {
    this.buyCoItemQtyValue = env.buyCoPromotionItem;
  }

  ngOnInit() {
    this.createForm();
    this.initialData();
  }

  get proType() {
    return PromotionTypeEnum;
  }

  get form(): UntypedFormGroup {
    return this.parentForm.get('promotionCondition') as UntypedFormGroup;
  }

  get details(): UntypedFormGroup {
    return this.parentForm.get('promotionDetails') as UntypedFormGroup;
  }

  createForm() {
    const initialHasDisabled = [{ initialValue: { value: null, disabled: true } }];

    const setForm = {
      buyQty: initialHasDisabled,
      totalPrice: [
        {
          initialValue: { value: null, disabled: true },
          validator: { validators: [Validators.required, this.notAllowZeroValidator] }
        }
      ],
      selectedRetailPrice: [
        {
          initialValue: { value: null, disabled: false },
          validator: {
            validators: [Validators.required, this.notAllowZeroValidator],
            updateOn: 'blur'
          }
        }
      ],
      buyCoItemQty: [{ initialValue: { value: this.buyCoItemQtyValue, disabled: true } }],
      averageUnitPrice: initialHasDisabled
    };

    for (const key in setForm) {
      if (setForm[key]) {
        this.form.addControl(key, new UntypedFormControl(setForm[key][0].initialValue, setForm[key][0].validator));
      }
    }
  }

  initialData() {
    this.forceBuySubscription = this.details.controls['forceBuyQty'].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.calculateBuyPromotionItem();
        this.calculatePricePerUnit();
        this.calculateAvgNewPricePerUnit();
      });
    this.promotionTypeSubscription = this.details.controls['promotionType'].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.onPromotionTypChange();
        this.calculateBuyPromotionItem();
      });
    if (this.canEditView) {
      this.setPromotionConditionValue();
    }
  }

  setPromotionConditionValue() {
    this.promotionRequestView$ = this.store.pipe(select(selectRequestPromotionData));
    this.promotionRequestView$.subscribe(value => {
      if (value) {
        this.form.controls['selectedRetailPrice'].setValue(
          PromotionTransFrom.getAmount(value.condition.selectedRetailPrice)
        );
        this.form.controls['buyCoItemQty'].setValue(PromotionTransFrom.getAmount(value.condition.buyQty));
        this.form.controls['totalPrice'].setValue(PromotionTransFrom.getAmount(value.condition.totalPrice));
        this.form.controls['averageUnitPrice'].setValue(
          round(PromotionTransFrom.getAmount(value.condition.averageUnitPrice), 2)
        );
      }
    });
    if (this.mode === PromotionRequestPageModes.REQUEST_VIEW) {
      this.form.disable();
    } else {
      this.toggleEditPromotionCondition();
    }
  }

  onPromotionTypChange() {
    this.form.controls['selectedRetailPrice'].setValue(null);
    this.form.controls['totalPrice'].setValue(null);
    this.form.controls['averageUnitPrice'].setValue(null);
    this.form.controls['selectedRetailPrice'].disable();
    const promotionType = this.details.controls['promotionType'].value;
    switch (promotionType) {
      case PromotionTypeEnum.BUY_TWO_SAVE_MORE:
      case PromotionTypeEnum.SPECIAL_PRICE:
      case PromotionTypeEnum.MORE_SPECIAL_PRICE:
        if (this.canEdit) {
          this.form.controls['totalPrice'].enable();
        }
        this.form.controls['selectedRetailPrice'].disable();
        break;
      default:
        this.form.controls['totalPrice'].disable();
        if (this.canEdit) {
          this.form.controls['selectedRetailPrice'].enable();
        }
        break;
    }
  }

  onBlurRetailPrice() {
    this.calculatePricePerUnit();
    this.calculateAvgNewPricePerUnit();
  }

  onBlurTotalPrice() {
    this.calculateAvgNewPricePerUnit();
  }

  calculatePricePerUnit() {
    let total: number = null;
    if (this.details.controls['forceBuyQty'].value > 0 && this.form.controls['selectedRetailPrice'].value > 0) {
      total = this.details.controls['forceBuyQty'].value * this.form.controls['selectedRetailPrice'].value;
    } else {
      total = null;
    }

    this.form.controls['totalPrice'].setValue(total);
  }

  calculateAvgNewPricePerUnit() {
    const buyQty = this.form.controls['buyQty'].value;
    const totalPrice = this.form.controls['totalPrice'].value;
    let averageUnitPriceValue: number;
    if (buyQty > 0 && totalPrice > 0) {
      averageUnitPriceValue = totalPrice / buyQty;
    }

    this.form.controls['averageUnitPrice'].setValue(round(averageUnitPriceValue, 2));
  }

  toggleEditPromotionCondition() {
    this.form.enable();
    this.form.controls['buyQty'].disable();
    this.form.controls['averageUnitPrice'].disable();
    const promotionType = this.details.controls['promotionType'].value;
    switch (promotionType) {
      case PromotionTypeEnum.BUY_TWO_SAVE_MORE:
        this.form.controls['buyCoItemQty'].disable();
        break;
      case PromotionTypeEnum.SPECIAL_PRICE:
      case PromotionTypeEnum.MORE_SPECIAL_PRICE:
        if (this.canEdit) {
          this.form.controls['totalPrice'].enable();
        }
        this.form.controls['selectedRetailPrice'].disable();
        break;
      default:
        this.form.controls['totalPrice'].disable();
        if (this.canEdit) {
          this.form.controls['selectedRetailPrice'].enable();
        }
        break;
    }
  }

  calculateBuyPromotionItem() {
    const promotionType = this.details.controls['promotionType'].value;
    let buyQtyValue: number = null;
    const forceBuyQty = this.details.controls['forceBuyQty'].value;
    this.form.controls['buyCoItemQty'].setValue(null);
    if (forceBuyQty > 0 && promotionType) {
      switch (promotionType) {
        case PromotionTypeEnum.BUY_GET_ONE_FREE:
          buyQtyValue = 2;
          break;
        case PromotionTypeEnum.BUY_MORE_GET_ONE_FREE:
          buyQtyValue = forceBuyQty + 1;
          break;
        case PromotionTypeEnum.SPECIAL_PRICE:
          buyQtyValue = 1;
          break;
        case PromotionTypeEnum.MORE_SPECIAL_PRICE:
          buyQtyValue = forceBuyQty;
          break;
        case PromotionTypeEnum.BUY_TWO_SAVE_MORE:
          buyQtyValue = 1;
          this.form.controls['buyCoItemQty'].setValue(buyQtyValue);
          break;
        default:
          break;
      }
    }

    this.form.controls['buyQty'].setValue(buyQtyValue);
  }

  isSpecialPrice() {
    const promotionType = this.details.controls['promotionType'].value;
    switch (promotionType) {
      case PromotionTypeEnum.SPECIAL_PRICE:
        return true;
      case PromotionTypeEnum.MORE_SPECIAL_PRICE:
        return true;
      default:
        return false;
    }
  }

  isCoPromotion() {
    const promotionType = this.details.controls['promotionType'].value;
    return promotionType === PromotionTypeEnum.BUY_TWO_SAVE_MORE;
  }

  get notAllowZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value === 0 ? { isZero: true } : null;
      }
      return null;
    };
  }

  get canEdit(): boolean {
    return [PromotionRequestPageModes.REQUEST_CREATE, PromotionRequestPageModes.REQUEST_EDIT].includes(this.mode);
  }

  get canEditView(): boolean {
    return [
      PromotionRequestPageModes.REQUEST_EDIT,
      PromotionRequestPageModes.REQUEST_VIEW,
      PromotionRequestPageModes.VIEW
    ].includes(this.mode);
  }

  ngOnDestroy(): void {
    if (this.forceBuySubscription) {
      this.forceBuySubscription.unsubscribe();
    }
    if (this.promotionTypeSubscription) {
      this.promotionTypeSubscription.unsubscribe();
    }
  }
}
