import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { BaseSearchComponent } from '../../../base/base-search.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { MonthlyPartnerProfitSharingStatusEnum } from '../../../shared/enum/monthly-partner-profit-sharing.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import {
  MonthlyPartnerProfitSharingContent,
  MonthlyPartnerProfitSharingSearchCriteria,
  MonthlyPartnerProfitSharingSubmitResponse
} from '../../../shared/models/monthly-partner-profit-sharing.model';
import { AuthGuardService } from '../../../shared/services';
import { MonthlyPartnerProfitSharingDownloadModalComponent } from '../monthly-partner-profit-sharing-download-modal/monthly-partner-profit-sharing-download-modal.component';
import { ProfitSharingPermission } from '../profit-sharing-permission';
import { MonthlyPartnerProfitSharingState } from '../store/monthly-partner-profit-sharing/monthly-partner-profit-sharing-reducers';
import {
  MonthlyPartnerProfitSharingListRequestAction,
  MonthlyPartnerProfitSharingResetUploadAction,
  MonthlyPartnerProfitSharingSubmitRequestAction
} from '../store/monthly-partner-profit-sharing/monthly-partner-profit-sharing.actions';
import {
  selectAllMonthlyPartnerProfitSharingList,
  selectMonthlyPartnerProfitSharingList,
  selectMonthlyPartnerProfitSharingSubmitError,
  selectMonthlyPartnerProfitSharingSubmitResult
} from '../store/monthly-partner-profit-sharing/monthly-partner-profit-sharing.selectors';
import { AppProfitSharingState } from '../store/profit-sharing.state';

@Component({
  selector: 'app-monthly-profit-sharing-list',
  templateUrl: './monthly-partner-profit-sharing-list.component.html',
  styleUrls: ['./monthly-partner-profit-sharing-list.component.scss']
})
export class MonthlyPartnerProfitSharingListComponent extends BaseSearchComponent<
  MonthlyPartnerProfitSharingSearchCriteria,
  MonthlyPartnerProfitSharingContent,
  MonthlyPartnerProfitSharingState,
  AppProfitSharingState
> {
  monthlyPartnerProfitSharingEnum = MonthlyPartnerProfitSharingStatusEnum;
  monthlyPartnerProfitSharingDownloadModal: BsModalRef;
  private localStore: Observable<any>;

  public permission = new ProfitSharingPermission();

  url: string;

  constructor(
    protected readonly store: Store<AppProfitSharingState>,
    protected readonly modalService: BsModalService,
    protected router: Router,
    protected authGuardService: AuthGuardService
  ) {
    super(store, modalService, selectAllMonthlyPartnerProfitSharingList, selectMonthlyPartnerProfitSharingList);
  }

  createForm() {
    // intentionally empty
  }

  doAfterVersionAlertModal() {
    this.modalService.hide(1);
  }

  doDestroy() {
    this.store.dispatch(new MonthlyPartnerProfitSharingResetUploadAction());
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.permission.checkPermission(this.authGuardService);
    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.search(this.criteriaObject);
    });

    this.localStore
      .pipe(select(selectMonthlyPartnerProfitSharingSubmitResult), filter(Boolean))
      .subscribe((result: MonthlyPartnerProfitSharingSubmitResponse) => {
        if (result.status === 'COMPLETED') {
          this.store.dispatch(new MonthlyPartnerProfitSharingResetUploadAction());
          this.alertSuccessModal('Profit Sharing has been created.');
        }
      });

    this.localStore.pipe(select(selectMonthlyPartnerProfitSharingSubmitError)).subscribe(err => {
      if (err && err.code) {
        let message = '';
        if (err.code === '11007') {
          message = 'Invalid import data.';

          this.modalService.show(AlertModalComponent, {
            initialState: {
              title: 'Failed',
              message: message
            }
          });
        }
      }
    });
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      page: 0,
      size: 20,
      sortOrder: 'desc',
      sortBy: 'lastModifiedDate'
    };
  }

  goToView() {}

  onAdvanceSubmit() {
    // intentionally empty
  }

  prepareSearchCriteriaTags() {
    // intentionally empty
  }

  search(criteriaObj) {
    this.store.dispatch(new MonthlyPartnerProfitSharingListRequestAction(criteriaObj));
  }

  setRouteTab() {
    // intentionally empty
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  getColorStatus(status): string {
    return status.toLowerCase();
  }

  showMonthlyPartnerProfitSharingDownloadModal(monthlyProfitSharing: MonthlyPartnerProfitSharingContent) {
    if (!monthlyProfitSharing) {
      return;
    }
    this.monthlyPartnerProfitSharingDownloadModal = this.modalService.show(
      MonthlyPartnerProfitSharingDownloadModalComponent,
      {
        backdrop: 'static',
        keyboard: false,
        initialState: {
          id: monthlyProfitSharing.id,
          refId: `/${monthlyProfitSharing.directory}/${monthlyProfitSharing.fileName}`,
          runDate: monthlyProfitSharing.runDate
        }
      }
    );

    this.monthlyPartnerProfitSharingDownloadModal.content.submitUpload
      .pipe(untilComponentDestroyed(this))
      .subscribe(submitObject => {
        this.store.dispatch(new MonthlyPartnerProfitSharingSubmitRequestAction(submitObject));
        this.monthlyPartnerProfitSharingDownloadModal.hide();
      });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }
}
