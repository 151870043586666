<div class="select-item-modal full-popup-head">
  <a aria-label="Back" class="clickable" (click)="onBack()">
    <div class="full-popup-close float-left">
      <span aria-label="Close" class="close">
        <em class="icon-prev ml-2"></em>
      </span>
    </div>
    <div class="full-popup-title">{{ data && data.backPageTitle ? data.backPageTitle : '' }}</div>
  </a>
</div>

<div class="admin-form modal-table-wrap">
  <div class="row section-header-item">
    <span>Shelf Details</span>
  </div>
  <div class="d-flex flex-row">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="ml-auto">
      <div class="form-group ml-auto filter-search-box">
        <div class="search-box position-relative">
          <input
            type="text"
            formControlName="searchCriteria"
            class="form-control searchCriteria"
            placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
            required
            maxlength="50"
            (keyup.backspace)="clearLastKeyUpSearchText()"
          />
          <em
            class="icon-close-mini"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
          <button class="btn-search" type="submit">
            <em class="icon-search"></em>
          </button>
        </div>
      </div>
    </form>
  </div>

  <ng-container
    *ngIf="filteredShelfDetail && !filteredShelfDetail.length && !searchForm.controls['searchCriteria'].value"
  >
    <div class="align-middle">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="No item" />
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="filteredShelfDetail && !filteredShelfDetail.length && searchForm.controls['searchCriteria'].value"
  >
    <div class="align-middle">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="filteredShelfDetail && filteredShelfDetail.length > 0">
    <div class="table-responsive table-scroll my-3">
      <table id="itemsTable" class="table table-striped table-bordered w-100" aria-label="itemsTable">
        <thead>
          <tr>
            <th class="text-center align-top w-5" scope="col">No.</th>
            <th class="text-center align-top w-25" scope="col">Shelf Code</th>
            <th class="text-center align-top w-35" scope="col">Shelf Type</th>
            <th class="text-center align-top w-35" scope="col">Shelf Option</th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let item of paging.currentPageData; index as i">
            <ng-container>
              <tr>
                <td class="text-center">{{ getItemNo(i) }}</td>
                <td class="text-left">
                  {{ item.shelfCode }}
                </td>
                <td class="text-left">
                  {{ item.shelfType }}
                </td>
                <td class="text-left">
                  {{ item.shelfOption }}
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-ui-pagination
      #paging
      [(currentPage)]="currentPage"
      [(pageSize)]="pageSize"
      [items]="filteredShelfDetail"
      [valueChangeDetector]="filteredShelfDetail.length"
    >
    </app-ui-pagination>
  </ng-container>
</div>
