import { BaseReport } from '@shared/models/base-report.model';

import { StockTransferStatusEnum } from '../enum/stock-transfer.enum';
import { AuditLog } from './audit-log.model';

export class StockTransferListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  orderDateFrom?: string;
  orderDateTo?: string;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  shipFrom?: string;
  shipTo?: string;
  page: number;
  size: number;
}

export class StockTransferList {
  id: string;
  docNo: string;
  requestNo: string;
  status: StockTransferStatusEnum;
  createdDate: string;
  lastModifiedByName: string;
  deliveryDate: string;
  shipFromCodeName: string;
  shipToCodeName: string;
  orderByName: string;
  auditLogs: AuditLog[];
  items: StockTransferItem[];
}

export class StockTransferListResponse {
  content: StockTransferList[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class StockTransferViewResponse {
  id: string;
  version: string;
  docNo: string;
  requestNo: string;
  deliveryDate: string;
  deliveryNote: string;
  shipFromCode: string;
  shipFromName: string;
  shipFromCodeName: string;
  shipToCode: string;
  shipToName: string;
  shipToCodeName: string;
  orderByName: string;
  status: StockTransferStatusEnum;
  items: StockTransferItem[];
  receiveSummaryItems: StockTransferItem[];
}

export class StockTransferItem {
  itemNo: number;
  articleNo: string;
  barcode: string;
  productName: string;
  unit: string;
  unitFactor: number;
  productType: string;
  quantity: number;
  reservedQty: number;
  returnQty: number;
  deliveredQty: number;
  receivedQty: number;
  remainingQty: number;
}

export class StockTransferCancelCloseRequestData {
  id: string;
  comment: string;
}

export class StockTransferExportCriteria extends BaseReport {
  orderDateFrom?: string;
  orderDateTo?: string;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  shipFrom?: string[];
  shipTo?: string[];
  status?: StockTransferStatusEnum[];
  fileType?: string;

  constructor(init?: Partial<StockTransferExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
