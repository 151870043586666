<div class="full-popup-head">
  <div class="full-popup-title flex"><em class="modal-title-icon icon-td-product"></em>{{ data.title }}</div>
  <button type="button" class="btn btn-header-button" *ngIf="hasCancelPermission()" (click)="cancelShelfRequest()">
    <em class="icon-forbidden"></em>Cancel Request
  </button>
  <button type="button" class="btn btn-header-button" *ngIf="hasDeletePermission()" (click)="deleteShelfRequest()">
    <em class="icon-delete"></em>Delete
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form">
  <div class="info-header-container">
    <div class="info-header-item" *ngIf="(shelfRequestView$ | async)?.requestNo">
      <div class="info-header-label">
        <span>Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (shelfRequestView$ | async)?.requestNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Request Status:</span>
      </div>
      <div class="info-header-value">
        <span
          *ngIf="requestMode.REQUEST_CREATE === data.mode; else getStatus"
          [ngClass]="getColorStatus('DRAFT')"
          class="request-status"
        >
          {{ 'SHELF_REQUEST.STATUS.DRAFT' | translate }}</span
        >
        <ng-template #getStatus>
          <span [ngClass]="getColorStatus((shelfRequestView$ | async)?.status)" class="request-status">
            {{ 'SHELF_REQUEST.STATUS.' + (shelfRequestView$ | async)?.status | translate }}</span
          >
        </ng-template>
      </div>
    </div>
  </div>

  <div [formGroup]="shelfRequestForm" (ngSubmit)="onSubmit()">
    <!-- Shelf Information -->
    <div class="row section-header">
      <span>Shelf Information</span>
    </div>
    <app-shelf-info
      #shelfInfo
      [parentForm]="shelfRequestForm"
      [submitted]="submitted"
      [saved]="saved"
      [mode]="data.mode"
      [page]="page"
    ></app-shelf-info>
  </div>

  <hr class="section-divider" />

  <div class="row section-header">
    <span>Fix Asset</span>
  </div>
  <div class="mb-1">
    <app-shelf-fix-asset
      #fixAsset
      id="fix-asset"
      class="mb-5"
      [mode]="data.mode"
      [submitted]="submitted"
      [parentForm]="shelfRequestForm"
      [page]="page"
    >
    </app-shelf-fix-asset>
  </div>

  <hr class="section-divider" />

  <div class="row section-header">
    <span>Inventory</span>
  </div>
  <div class="mb-1">
    <app-shelf-inventory
      #inventory
      id="inventory"
      class="mb-5"
      [mode]="data.mode"
      [submitted]="submitted"
      [parentForm]="shelfRequestForm"
      [page]="page"
    >
    </app-shelf-inventory>
  </div>

  <!--footer-->
  <ng-container *ngIf="hasAtLeastOnePermission()">
    <ng-container *ngIf="data.mode !== requestMode.REQUEST_VIEW; else otherMode">
      <div class="fixed-row-bottom form-row">
        <div class="float-left" *ngIf="hasSavePermission()">
          <button type="button" id="btn-save" class="btn btn-secondary" (click)="onSave()">
            Save
          </button>
        </div>
        <div class="float-right" *ngIf="hasSubmitPermission()">
          <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
          <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()">
            Submit
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #otherMode>
      <ng-container>
        <div class="fixed-row-bottom form-row">
          <div class="float-left"></div>
          <div class="float-right">
            <span *ngIf="hasEditPermission()" class="mr-2">
              <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
            </span>
            <span *ngIf="hasApprovePermission()">
              <button
                type="button"
                id="btn-reject"
                class="btn btn-special-reject mr-2"
                (click)="doApproveRejectRequest(false)"
              >
                Reject
              </button>
              <button
                type="button"
                id="btn-approve"
                class="btn btn-special-approve"
                (click)="doApproveRejectRequest(true)"
              >
                Approve
              </button>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <!--End footer-->
</div>
