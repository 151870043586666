import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { AppStates } from '../store/state/app.states';

@Directive({
  selector: '[appHasPermission]'
})
// Usage *appHasPermission="['can_write', 'can_read']; op 'OR'"
export class HasPermissionDirective extends OnDestroyMixin implements OnInit {
  private permissions = [];
  private logicalOp = 'OR';
  private isHidden = true;
  private userAuthorities: string[];

  constructor(
    // private readonly element: ElementRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly store: Store<AppStates>
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(state => state.userInfo.authorities)
      .pipe(untilComponentDestroyed(this))
      .subscribe(authorities => {
        this.userAuthorities = authorities;
        this.updateView();
      });
  }

  @Input()
  set appHasPermission(val) {
    this.permissions = val;
  }

  @Input()
  set appHasPermissionOp(permOp) {
    this.logicalOp = permOp;
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;

    if (this.userAuthorities) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.userAuthorities.find(x => x.toUpperCase() === checkPermission.toUpperCase());

        if (permissionFound) {
          hasPermission = true;
          if (this.logicalOp === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
    }
    return hasPermission;
  }
}
