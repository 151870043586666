import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ReturnToWarehouseChargeReportCriteria } from '@shared/models/report.model';
import { BaseService } from '@shared/services/base.service';
import { metricsReporting } from '@shared/utils/metrics-util';

@Injectable()
export class ReturnToWarehouseChargeService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.exportReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  exportReport(criteria: ReturnToWarehouseChargeReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.returnToWarehouseCharge, null, true);
    const metrics = metricsReporting([this.envService.returnToWarehouseGR]);

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
