<div class="full-popup-head">
  <div class="full-popup-title flex"><em class="modal-title-icon icon-td-store"></em>{{ data.title }}</div>
  <button
    type="button"
    class="btn btn-header-button"
    data-id="btnCancelStoreRequest"
    *ngIf="hasCancelPermission()"
    (click)="cancelStoreRequest()"
  >
    <em class="icon-forbidden"></em>Cancel Request
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    data-id="btnDeleteStoreRequest"
    *ngIf="hasDeletePermission()"
    (click)="deleteStoreRequest()"
  >
    <em class="icon-delete"></em>Delete
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="status !== 'D' && hasViewHistoryPermission(status)"
    (click)="showHistory()"
  >
    <em class="icon-history"></em>History
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" data-id="btnCloseModal" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form">
  <div class="info-header-container">
    <div class="info-header-item" *ngIf="(storeView$ | async)?.requestNo">
      <div class="info-header-label">
        <span>Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (storeView$ | async)?.requestNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Request Status:</span>
      </div>
      <div class="info-header-value">
        <span
          *ngIf="storePageMode.REQUEST_CREATE === data.mode; else getStatus"
          [ngClass]="getColorStatus('D')"
          class="request-status"
        >
          {{ 'STORE_REQUEST.STATUS.D' | translate }}</span
        >
        <ng-template #getStatus>
          <span [ngClass]="getColorStatus((storeView$ | async)?.status)" class="request-status">
            {{ 'STORE_REQUEST.STATUS.' + (storeView$ | async)?.status | translate }}</span
          >
        </ng-template>
      </div>
    </div>
  </div>
  <div [formGroup]="storeForm">
    <!-- Store Profile Section -->
    <div class="information-container">
      <div class="row merchant-info">
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Merchant ID</span>
            <span class="info-header-value">{{ (storeView$ | async)?.no | dashDisplay }}</span>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Tax ID/ID card</span>
            <span class="info-header-value">{{ (storeView$ | async)?.taxId | dashDisplay }}</span>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Merchant Name</span>
            <span class="info-header-value">{{ (storeView$ | async)?.merchantName | dashDisplay }}</span>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Merchant Type</span>
            <span class="info-header-value">{{
              'STORE_TYPE.' + (storeView$ | async)?.merchantType | translate
            }}</span>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Contact Name</span>
            <span class="info-header-value">{{ (storeView$ | async)?.contactName | dashDisplay }}</span>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Mobile Phone</span>
            <span class="info-header-value">{{ (storeView$ | async)?.mobilePhone | dashDisplay }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-menu d-flex d-inline-flex">
      <ng-container *ngFor="let tab of storeTabList">
        <a
          class="list-tab-menu px-3 px-md-5 py-2"
          [ngClass]="{ active: isSelectedTab(tab.value) }"
          (click)="onSelectTabs(tab.value)"
        >
          {{ tab.label }}
        </a>
      </ng-container>
    </div>

    <!-- Store Profile -->
    <div class="row section-header justify-content-between">
      <span>Store Profile</span>
    </div>

    <app-store-profile
      #storeProfile
      [parentForm]="storeForm"
      [mode]="data.mode"
      [page]="TDStorePage"
      [saved]="saved"
      [submitted]="submitted"
      [requestId]="data.storeId"
      [listOfChange]="listOfChange?.storeProfile"
    ></app-store-profile>

    <hr class="section-divider" />
    <!-- Store Profile -->

    <!-- Store Contact -->
    <div class="row section-header justify-content-between">
      <span>Store Contact</span>
    </div>
    <app-store-contact
      #storeContact
      [parentForm]="storeForm"
      [submitted]="submitted"
      [mode]="data.mode"
      [page]="page"
      [listOfChange]="listOfChange?.storeProfile"
    ></app-store-contact>
    <hr class="section-divider" />
    <!-- Store Contact -->

    <!-- Store Location -->
    <div class="row section-header justify-content-between">
      <span>Store Location</span>
    </div>
    <app-store-location
      #storeLocation
      [parentForm]="storeForm"
      [submitted]="submitted"
      [page]="page"
      [mode]="data.mode"
      [listOfChange]="listOfChange?.storeProfile"
    ></app-store-location>
    <hr class="section-divider" />
    <!-- Store Location -->

    <!-- Store Condition -->
    <div class="row section-header">
      <span id="">Store Condition</span>
    </div>
    <app-store-condition
      #storeCondition
      [parentForm]="storeForm"
      [submitted]="submitted"
      [mode]="data.mode"
      [page]="TDStorePage"
      [listOfChange]="listOfChange?.storeProfile"
    >
    </app-store-condition>

    <hr class="section-divider" />
    <!-- Store Condition -->

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="row">
          <div class="col-lg-12 pl-0">
            <div class="row mb-3 mr-md-0 section-header">
              <span id="ORDER_POLICY">Order Schedule (for Replenish)</span>
            </div>
            <app-order-policy
              #orderPolicy
              [parentForm]="storeForm"
              [mode]="data.mode"
              [page]="TDStorePage"
              [submitted]="submitted"
            ></app-order-policy>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-lg-12">
            <!-- Order Policy -->

            <div class="row mb-3 mr-md-0 section-header">
              <span id="PRE_ORDER_POLICY">Order Schedule (for Pre Order)</span>
            </div>

            <app-pre-order-policy
              #preOrderPolicy
              [parentForm]="storeForm"
              [mode]="data.mode"
              [page]="TDStorePage"
              [submitted]="submitted"
              [ddlPosition]="'top'"
            ></app-pre-order-policy>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--footer-->
  <ng-container *ngIf="hasAtLeastOnePermission()">
    <ng-container
      *ngIf="[storePageMode.REQUEST_CREATE, storePageMode.REQUEST_EDIT].includes(data.mode); else otherMode"
    >
      <div class="fixed-row-bottom form-row">
        <div class="float-left" *ngIf="hasSavePermission()">
          <button type="button" id="btn-save" class="btn btn-secondary" (click)="onSave()">
            Save
          </button>
        </div>
        <div class="float-right" *ngIf="hasSubmitStorePermission()">
          <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
          <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()">
            Submit
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #otherMode>
      <ng-container>
        <div class="fixed-row-bottom form-row">
          <div class="float-left"></div>
          <div class="float-right">
            <span *ngIf="hasEditStorePermission()" class="mr-2">
              <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
            </span>
            <span *ngIf="hasApproveStorePermission()">
              <button
                type="button"
                id="btn-reject"
                data-id="btn-reject"
                class="btn btn-special-reject mr-2"
                (click)="doApproveRejectRequest(false)"
              >
                Reject
              </button>
              <button
                type="button"
                id="btn-approve"
                data-id="btn-approve"
                class="btn btn-special-approve"
                (click)="doApproveRejectRequest(true)"
              >
                Approve
              </button>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <!--End footer-->
</div>
