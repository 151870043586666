import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MomentModule } from 'ngx-moment';

import { InputFieldComponent } from '@shared/components/dynamic-form/dynamic-components/input-field/input-field.component';
import { LevelSelectFieldComponent } from '@shared/components/dynamic-form/dynamic-components/level-select-field/level-select-field.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { StoreMerchantsEffects } from '@shared/store/effects/store-merchants.effects';
import { storeMerchantsModuleReducers } from '@shared/store/reducers/store-merchants.reducers';

import { ProductService } from '../../services';
import { MerchantService } from '../../services/merchant.service';
import { SupplierService } from '../../services/supplier.service';
import { StoresEffects } from '../../store/effects/stores.effects';
import { storesModuleReducers } from '../../store/reducers/stores.reducers';
import { DisallowSpaceInputDirective } from './disallow-space-input-directive/disallow-space-input.directive';
import { DateFieldComponent } from './dynamic-components/date-field/date-field.component';
import { DropdownPagingMultipleSelectFieldComponent } from './dynamic-components/dropdown-paging-multiple-select-field/dropdown-paging-multiple-select-field.component';
import { DropdownPagingSingleSelectFieldComponent } from './dynamic-components/dropdown-paging-single-select-field/dropdown-paging-single-select-field.component';
import { DropdownSupplierMultipleSelectFieldComponent } from './dynamic-components/dropdown-supplier-multiple-select-field/dropdown-supplier-multiple-select-field.component';
import { InputDownloadFieldComponent } from './dynamic-components/input-download-field/input-download-field.component';
import { InputUploadFieldComponent } from './dynamic-components/input-upload-field/input-upload-field.component';
import { SelectFieldComponent } from './dynamic-components/select-field/select-field.component';
import { SelectTemplateOptionFieldComponent } from './dynamic-components/select-template-option-field/select-template-option-field.component';
import { SingleDateFieldComponent } from './dynamic-components/single-date-field/single-date-field.component';
import { DynamicFieldDirective } from './dynamic-field-directive/dynamic-field.directive';
import { DynamicFormComponent } from './dynamic-form.component';

@NgModule({
  declarations: [
    DynamicFormComponent,
    DynamicFieldDirective,
    DateFieldComponent,
    SelectFieldComponent,
    DropdownPagingMultipleSelectFieldComponent,
    DropdownSupplierMultipleSelectFieldComponent,
    SelectTemplateOptionFieldComponent,
    SingleDateFieldComponent,
    LevelSelectFieldComponent,
    InputFieldComponent,
    InputDownloadFieldComponent,
    InputUploadFieldComponent,
    DropdownPagingSingleSelectFieldComponent,
    DisallowSpaceInputDirective
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    TranslateModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    NgOptionHighlightModule,
    MomentModule.forRoot(),
    PipesModule,
    StoreModule.forFeature('stores', storesModuleReducers.stores),
    StoreModule.forFeature('storeMerchants', storeMerchantsModuleReducers.storeMerchants),
    EffectsModule.forFeature([StoresEffects, StoreMerchantsEffects])
  ],
  exports: [DynamicFieldDirective, DynamicFormComponent],
  providers: [FormGroupDirective, MerchantService, SupplierService, ProductService]
})
export class DynamicFormModule {}
