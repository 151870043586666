import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import {
  MonthlyPartnerProfitSharingContent,
  MonthlyPartnerProfitSharingErrorResponse,
  MonthlyPartnerProfitSharingSearchCriteria,
  MonthlyPartnerProfitSharingSubmitResponse
} from '../../../../shared/models/monthly-partner-profit-sharing.model';
import { AppProfitSharingState } from '../profit-sharing.state';
import {
  MonthlyPartnerProfitSharingActionTypes,
  MonthlyPartnerProfitSharingActions
} from './monthly-partner-profit-sharing.actions';

export const monthlyProfitSharingModuleReducers: ActionReducerMap<Partial<AppProfitSharingState>> = {
  monthlyProfitSharing: monthlyPartnerProfitSharingReducers
};

export interface MonthlyPartnerProfitSharingState extends EntityState<MonthlyPartnerProfitSharingContent> {
  criteriaObject: MonthlyPartnerProfitSharingSearchCriteria;
  isLoading: boolean;
  monthlyPartnerProfitSharing: MonthlyPartnerProfitSharingSubmitResponse;
  totalElements: number;
  totalPages: number;
  submitMonthlyPartnerProfitSharingError: MonthlyPartnerProfitSharingErrorResponse;
}

export const adapter: EntityAdapter<MonthlyPartnerProfitSharingContent> = createEntityAdapter<
  MonthlyPartnerProfitSharingContent
>();

export const initialMonthlyPartnerProfitSharingState: MonthlyPartnerProfitSharingState = adapter.getInitialState({
  isLoading: false,
  monthlyPartnerProfitSharing: null,
  criteriaObject: {
    sortBy: 'lastModifiedDate',
    sortOrder: 'desc',
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  submitMonthlyPartnerProfitSharingError: null
});

export function monthlyPartnerProfitSharingReducers(
  state = initialMonthlyPartnerProfitSharingState,
  action: MonthlyPartnerProfitSharingActions
): MonthlyPartnerProfitSharingState {
  switch (action.type) {
    case MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_RESPONSE:
      return { ...state, monthlyPartnerProfitSharing: action.payload };
    case MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_RESET_RESPONSE:
      return { ...state, ...initialMonthlyPartnerProfitSharingState };
    case MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_RESET_UPLOAD:
      return { ...state, monthlyPartnerProfitSharing: null };
    case MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_RESPONSE_ERROR:
      return {
        ...state,
        submitMonthlyPartnerProfitSharingError: action.payload
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
