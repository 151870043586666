import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { OrderSubTypeEnum } from '../../../../shared/enum/ordering-method.enum';
import { UploadStatusEnum } from '../../../../shared/enum/upload-status.enum';
import { Metrics } from '../../../../shared/models/metrics';
import { OrderFileUploadComponent } from '../order-file-upload/order-file-upload.component';

@Component({
  selector: 'app-order-modal-upload',
  templateUrl: './order-modal-upload.component.html',
  styleUrls: ['./order-modal-upload.component.scss']
})
export class OrderModalUploadComponent implements OnInit, OnDestroy {
  @ViewChild(OrderFileUploadComponent, { static: false })
  fileUpload: OrderFileUploadComponent;

  @Output() submitUpload: EventEmitter<any> = new EventEmitter();

  public uploadUrl: string;
  public uploadHeader: string;
  public uploadStatus = UploadStatusEnum;
  public orderRequestId?: string;
  public isAddSelectiveItem = false;
  public orderSubTypeEnum: OrderSubTypeEnum;
  public titleLabel: string;
  public form: UntypedFormGroup;
  public errors = [];
  public errorMessage: string;
  public metricsReq: Metrics;
  constructor(public bsModalRef: BsModalRef, public fb: UntypedFormBuilder, protected modalService: BsModalService) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  createForm() {
    const url = `${this.uploadUrl}`;
    this.form = this.fb.group({
      storeUseOrderItems: [null],
      uploadUrl: [url]
    });
  }

  onSubmit() {
    if (
      !this.form.controls['storeUseOrderItems'].value &&
      !this.form.controls['storeUseOrderItems'].value[0].length
    ) {
      return;
    }

    this.submitUpload.emit({
      file: this.form.controls['storeUseOrderItems'].value[0]
    });

    this.bsModalRef.hide();
  }
}
