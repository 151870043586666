import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';

import { ProductMasterData, productsMasterQuery } from '../gql/product.gql';
import {
  ApproveRejectAssortmentRequest,
  Assortment,
  MasterData,
  RequestAssortmentResponse,
  RequestAssortmentSearchCriteria
} from '../models';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class RequestAssortmentService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly masterService: MasterService) {
    super();
    this.envService = this.env.services.productAssortment;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getProductsMasterData(
    articleType: string,
    active: boolean = null
  ): Observable<ApolloQueryResult<ProductMasterData>> {
    return this.masterService.watchQuery<ProductMasterData>({
      query: productsMasterQuery,
      fetchPolicy: 'network-only',
      variables: {
        articleType,
        active
      }
    });
  }

  searchByCriteria(criteria: RequestAssortmentSearchCriteria): Observable<RequestAssortmentResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PRODUCT,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    criteria = {
      ...criteria,
      sortBy: 'lastModifiedDate',
      sortOrder: 'desc'
    };

    const params = this.getParams(criteria, true);
    params.append('sortBy', 'RequestedDate');
    return this.http.get<any>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  deleteByRequestId(requestId: string): Observable<any> {
    return this.http.delete<any>(`${this.getUrl()}/${requestId}`, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }

  cancel(data: ApproveRejectAssortmentRequest): Observable<any> {
    return this.http.post<any>(`${this.getUrl()}/cancel`, data, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }

  getByRequestId(data: string): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${data}`, {
      headers: this.headers,
      observe: 'body'
    });
  }

  updateByRequestId(data: Assortment): Observable<any> {
    let params = null;
    if (data.isForceSubmit) {
      params = this.getParams({ forceSubmit: data.isForceSubmit }, true);
    }
    if (params) {
      return this.http.put<any>(`${this.getUrl()}`, data, {
        headers: this.loaderHeaders(3000),
        observe: 'body',
        params
      });
    }
    return this.http.put<any>(`${this.getUrl()}`, data, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }

  create(data: Assortment): Observable<any> {
    return this.http.post<any>(`${this.getUrl()}`, data, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }

  submit(data: Assortment): Observable<any> {
    let params = null;
    if (data.isForceSubmit) {
      params = this.getParams({ forceSubmit: data.isForceSubmit }, true);
    }
    if (params) {
      return this.http.post<any>(`${this.getUrl()}/submit`, data, {
        headers: this.loaderHeaders(3000),
        observe: 'body',
        params
      });
    }
    return this.http.post<any>(`${this.getUrl()}/submit`, data, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }

  approve(data: ApproveRejectAssortmentRequest): Observable<any> {
    return this.http.post<any>(`${this.getUrl()}/approve`, data, {
      headers: this.loaderHeaders(3000)
    });
  }

  reject(data: ApproveRejectAssortmentRequest): Observable<any> {
    return this.http.post<any>(`${this.getUrl()}/reject`, data, {
      headers: this.loaderHeaders(3000)
    });
  }

  copy(requestId: string): Observable<any> {
    return this.http.post<any>(`${this.getUrl()}/${requestId}/copy`, null, {
      headers: this.headers
    });
  }

  getById(data: string, isProduct: boolean): Observable<any> {
    if (isProduct) {
      const url = this.env.services.assortment.productDetail.replace(new RegExp(`{articleNo}`, 'g'), data);
      return this.http.get<any>(`${this.getProductAssortmentUrl()}${url}`, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    }
    return this.http.get<any>(`${this.env.serverUrl}/tdproducts/requests/${data}`, {
      headers: this.headers,
      observe: 'body'
    });
  }

  protected getProductAssortmentUrl() {
    return this.env.serverUrl + this.env.services.assortment.url;
  }

  checkDuplicatedArticleNo(articleNo: string): Observable<any> {
    return this.http.get(`${this.env.serverUrl}/tdproducts/exists/${articleNo}`, {
      headers: this.headers,
      observe: 'body'
    });
  }

  searchFreshLife(): Observable<RequestAssortmentResponse> {
    return this.http.get<any>(`${this.env.serverUrl}/fresh-life-categories`, {
      headers: this.headers,
      observe: 'body'
    });
  }

  checkCJDuplicateBarcode(cjBarcode: { articleNo: string; barcode: string[] }): Observable<any> {
    const params = this.getParams(cjBarcode, true);
    return this.http.get(`${this.env.serverUrl}/cjbarcodes`, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  checkProductRequestExists(articleNo: string): Observable<any> {
    return this.http.get(`${this.getUrl()}/exists/${articleNo}`, {
      headers: this.headers,
      observe: 'body'
    });
  }

  getHistory(id: string): Observable<any> {
    return this.http.get(`${this.getUrl()}/${id}/history`, {
      headers: this.headers,
      observe: 'body'
    });
  }
}

export class Segments extends MasterData {
  families: Families;
}

export class Families extends MasterData {
  classCodes: ClassCodes;
}

export class ClassCodes extends MasterData {
  subClasses: MasterData;
  gradingGroup: MasterData;
}

export class CatalogLv1 extends MasterData {
  catalogLv2: MasterData;
}

export class ProductGrading {
  id?: string;
  code?: string;
  value?: number;
}
