import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { MerchantService } from '@shared/services/merchant.service';
import { StoreService } from '@shared/services/store.service';
import { LayoutActionLoadError } from '@shared/store/actions/layout.action';
import { AppStates } from '@shared/store/state/app.states';

import {
  StoreActionType,
  StoreByIdRequestAction,
  StoreByIdResponseAction,
  StoreListHistoryRequestAction,
  StoreListHistoryResponseAction,
  StoreListRequestAction,
  StoreListResponseAction,
  StoreLockSaleRequestAction,
  StoreLockSaleResponseAction
} from './store.actions';
import { selectStoreById } from './store.selectors';

@Injectable()
export class StoreEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly storeService: StoreService,
    private readonly merchantService: MerchantService,
    private readonly logger: NGXLogger,
    private readonly store: Store<AppStates>
  ) {}

  searchStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreListRequestAction>(StoreActionType.STORE_LIST_REQUEST),
      map(action => {
        this.logger.debug(`@Effect ${StoreActionType.STORE_LIST_REQUEST}: ` + StoreEffects.stringify(action.payload));
        return action.payload;
      }),
      switchMap(payload => {
        return this.merchantService.searchStoreByCriteria(payload).pipe(
          map(response => {
            return new StoreListResponseAction(response);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  storeHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreListHistoryRequestAction>(StoreActionType.STORE_LIST_HISTORY_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${StoreActionType.STORE_LIST_HISTORY_REQUEST}: ` + StoreEffects.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.storeService.getHistoryLogs(payload).pipe(
          map(response => new StoreListHistoryResponseAction({ auditLogs: response.auditLogs })),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  storeLockSale$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreLockSaleRequestAction>(StoreActionType.LOCK_SALE_REQUEST),
      map(action => {
        this.logger.debug(`@Effect ${StoreActionType.LOCK_SALE_REQUEST}: ` + StoreEffects.stringify(action.payload));
        return action.payload;
      }),
      switchMap(payload => {
        return this.storeService.storeLockSale(payload).pipe(
          withLatestFrom(this.store.select(selectStoreById)),
          map(([response, store]) => {
            return new StoreLockSaleResponseAction({
              storeNo: store && store.no ? store.no : null,
              merchantNo: store && store.merchantId ? store.merchantId : null,
              status: response.saleStatus,
              lockSaleSuccess: true
            });
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  getStoreById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<StoreByIdRequestAction>(StoreActionType.STORE_GET_BY_ID_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${StoreActionType.STORE_GET_BY_ID_REQUEST}: ` + StoreEffects.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return combineLatest([
          this.merchantService.getMerchantById(payload.merchant),
          this.storeService.getStoreById(payload.storeNo)
        ]).pipe(
          map(([merchant, store]) => {
            const storeView = {
              id: store.id,
              no: store.no,
              version: store.version,
              status: store.status,
              merchantId: merchant.no,
              taxId: merchant.merchantInfo.merchantProfile.taxId,
              merchantName: merchant.merchantInfo.merchantProfile.merchantName,
              merchantType: merchant.merchantInfo.merchantProfile.merchantType,
              ...(merchant.merchantInfo.ownerProfile && {
                contactName: `${merchant.merchantInfo.ownerProfile.contactFirstName} ${merchant.merchantInfo.ownerProfile.contactLastName}`,
                mobilePhone: `${merchant.merchantInfo.ownerProfile.countryCode} ${merchant.merchantInfo.ownerProfile.mobilePhone}`
              }),
              merchantInfo: {
                storeProfile: [{ ...store.storeProfile }]
              },
              ...(store.orderSchedule && {
                orderSchedule: {
                  orderScheduleList: [{ ...store.orderSchedule }]
                }
              }),
              devices: store.devices,
              saleStatus: store.saleStatus
            };
            return new StoreByIdResponseAction({ storeView });
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  private static stringify(data: any) {
    return JSON.stringify(data);
  }
}
