import { NewRequestStatusEnum } from '../enum/request-status.enum';
import { NewRequestTypeEnum } from '../enum/request-step.enum';
import { AuditLog } from './audit-log.model';
import { Sort } from './pagination.model';
import { NonMerchandiseItem, ShelfInfo } from './shelf-request.model';

export class ShelfFixAssetListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  lastUpdatedDateFrom?: string;
  lastUpdatedDateTo?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class ShelfFixAssetListResponse {
  id: number;
  content: ShelfFixAssetList[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface ShelfFixAssetList {
  id: string;
  shelfNo: string;
  shelfCode: string;
  shelfInfo: ShelfFixAssetInfo;
  lastModifiedDate: string;
  status: NewRequestStatusEnum;
  auditLogs: AuditLog[];
}

export class ShelfFixAssetInfo {
  shelfName: string;
  shelfImage: string;
  changes?: any;
}

export interface ShelfSelectFixAssetList {
  id: string;
  shelfNo: string;
  shelfCode: string;
  shelfName: string;
  shelfImage: string;
  status: NewRequestStatusEnum;
}

export interface ShelfFixAssetNo {
  shelfNo: string;
}

export class ShelfFixAssetViewResponse {
  id: string;
  shelfNo: string;
  version = 0;
  shelfCode: string;
  type?: NewRequestTypeEnum;
  status: NewRequestStatusEnum;
  shelfInfo: ShelfInfo;
  items: NonMerchandiseItem[];
  noOfStore: number;
  createdBy: string;
  createdByName: string;
  createdDate: string;
  lastModifiedDate: string;
}
