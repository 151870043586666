import { SearchCriteria } from '@shared/models';
import { dateToStringCriteria } from '@shared/utils/date-util';

export class CriteriaUtil {
  /**
   *
   * @param criteria - criteriaObject
   * @param listTags - tags  Map
   * @param ignoreProperty - skip Property
   */
  static hasCriteria(
    criteria: any,
    listTags: Map<string, any> = null,
    ignoreProperty: string[] = ['page', 'size']
  ): boolean {
    if (!criteria) {
      return false;
    }

    if (listTags) {
      return !!(listTags.size > 0 || criteria['searchCriteria'] || criteria['status']);
    }

    for (let name in criteria) {
      if (!ignoreProperty.includes(name) && (criteria[name] || typeof criteria[name] === 'boolean')) {
        return true;
      }
    }

    return false;
  }

  /**
   *
   * @param criteria - criteriaObject
   * @param formValue - From Criteria Value
   * @param page - default 0
   */
  static mappingToSearch(criteria: SearchCriteria, formValue: any, page = 0): SearchCriteria {
    if (!criteria || !formValue) {
      return null;
    }

    let search = { ...criteria };
    for (let name in formValue) {
      if (!name) {
        continue;
      }

      if (name.toLocaleLowerCase().indexOf('datefrom') > 0) {
        search[name] = dateToStringCriteria(formValue[name]);
      } else if (name.toLocaleLowerCase().indexOf('dateto') > 0) {
        search[name] = dateToStringCriteria(formValue[name], false);
      } else if (formValue[name] instanceof Array) {
        search[name] = formValue[name].length > 0 ? formValue[name].toString() : null;
      } else {
        search[name] = formValue[name];
      }
    }
    search['page'] = page;

    return search;
  }
}
