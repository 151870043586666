import { Injectable } from '@angular/core';

import { SupplierPriceContent } from '../models/supplier-price.model';

@Injectable()
export class ManualSupplierPriceDataService {
  private supplierPrices: Array<SupplierPriceContent> = [];
  public isManualSupplierPrice: boolean;
  constructor() {
    // intentionally empty
  }

  setData(supplierPriceContent: Array<SupplierPriceContent>) {
    this.supplierPrices = [...supplierPriceContent];
    return this.supplierPrices;
  }

  getManualSupplierPrice() {
    return this.isManualSupplierPrice;
  }

  setManualSupplierPrice(isManual) {
    this.isManualSupplierPrice = isManual;
  }

  getSupplierPricesData() {
    return [...this.supplierPrices];
  }

  deleteData(index: number) {
    this.supplierPrices.splice(index, 1);
    this.supplierPrices = [...this.supplierPrices];
    return this.supplierPrices;
  }

  reIndexData(supplierPriceContents: SupplierPriceContent[]) {
    for (let i = 0; i < supplierPriceContents.length; i++) {
      supplierPriceContents[i] = { ...supplierPriceContents[i], itemNo: i };
    }
    this.supplierPrices = [...supplierPriceContents];
    return this.supplierPrices;
  }

  resetData() {
    this.supplierPrices = [];
    this.isManualSupplierPrice = null;
  }

  updateSupplierByIndex(supplierDetailChange, index) {
    this.supplierPrices[index] = {
      ...this.supplierPrices[index],
      ...supplierDetailChange
    };

    return this.supplierPrices[index];
  }
}
