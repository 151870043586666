import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DaysDisplayPipe } from './days-display.pipe';

@NgModule({
  declarations: [DaysDisplayPipe],
  imports: [CommonModule],
  exports: [DaysDisplayPipe]
})
export class DaysDisplayModule {}
