import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { OrderCustomerDetail } from '../../../../shared/models/order.model';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss']
})
export class StoreInfoComponent {
  public title: string;
  public historyHeader: string;
  public customerDetail: OrderCustomerDetail;

  constructor(public bsModalRef: BsModalRef) {}

  decline(): void {
    this.bsModalRef.hide();
  }
}
