import { Action } from '@ngrx/store';

import { NewRequestStatusEnum } from '@shared/enum/request-status.enum';
import { NewRequestTypeEnum } from '@shared/enum/request-step.enum';
import { ErrorResponse } from '@shared/models';
import {
  ShelfInventoryCreateRequest,
  ShelfInventoryRequestViewResponse
} from '@shared/models/shelf-inventory-request.model';
import {
  ShelfInventoryListResponse,
  ShelfInventoryListSearchCriteria,
  ShelfInventoryNo,
  ShelfInventoryViewResponse
} from '@shared/models/shelf-inventory.model';

export enum ShelfInventoryActionType {
  SHELF_INVENTORY_LIST_REQUEST = '[ShelfInventory] Shelf Inventory List Request',
  SHELF_INVENTORY_LIST_RESPONSE = '[ShelfInventory] Shelf Inventory List Response',
  SHELF_INVENTORY_GET_BY_ID_REQUEST = '[ShelfInventory] Get Shelf Inventory by Id Request',
  SHELF_INVENTORY_GET_BY_ID_RESPONSE = '[ShelfInventory] Get Shelf Inventory by Id Response',
  EDIT_SHELF_INVENTORY = '[ShelfInventory] Edit Shelf Inventory',
  SHELF_INVENTORY_GET_BY_ID_RESET = '[ShelfInventory] Get Shelf Inventory by Id Reset',
  SHELF_INVENTORY_LIST_HISTORY_REQUEST = '[ShelfInventory] List History Request',
  SHELF_INVENTORY_LIST_HISTORY_RESPONSE = '[ShelfInventory] List History Response',
  SUBMIT_SHELF_INVENTORY_FIRST_LOT_REQUEST = '[ShelfInventory] Submit First Lot Request',
  SUBMIT_SHELF_INVENTORY_FIRST_LOT_RESPONSE = '[ShelfInventory] Submit First Lot Response',
  SUBMIT_SHELF_INVENTORY_FIRST_LOT_ERROR = '[ShelfInventory] Submit First Lot Error',
  SHELF_GET_BY_ID_RESET = '[ShelfInventory] Get Shelf Inventory by Id Reset'
}

export class ShelfInventoryListRequestAction implements Action {
  readonly type = ShelfInventoryActionType.SHELF_INVENTORY_LIST_REQUEST;

  constructor(public payload: ShelfInventoryListSearchCriteria) {}
}

export class ShelfInventoryListResponseAction implements Action {
  readonly type = ShelfInventoryActionType.SHELF_INVENTORY_LIST_RESPONSE;

  constructor(public payload: ShelfInventoryListResponse) {}
}

export class ShelfInventoryListHistoryRequestAction implements Action {
  readonly type = ShelfInventoryActionType.SHELF_INVENTORY_LIST_HISTORY_REQUEST;
  constructor(public payload: ShelfInventoryNo) {}
}

export class ShelfInventoryListHistoryResponseAction implements Action {
  readonly type = ShelfInventoryActionType.SHELF_INVENTORY_LIST_HISTORY_RESPONSE;
  constructor(public payload: { auditLogs }) {}
}

export class ShelfInventoryByIdRequestAction implements Action {
  readonly type = ShelfInventoryActionType.SHELF_INVENTORY_GET_BY_ID_REQUEST;

  constructor(public payload: ShelfInventoryNo) {}
}

export class ShelfInventoryByIdResponseAction implements Action {
  readonly type = ShelfInventoryActionType.SHELF_INVENTORY_GET_BY_ID_RESPONSE;

  constructor(public payload: ShelfInventoryViewResponse) {}
}

export class EditShelfInventoryAction implements Action {
  readonly type = ShelfInventoryActionType.EDIT_SHELF_INVENTORY;

  constructor(public payload: { type: NewRequestTypeEnum; status?: NewRequestStatusEnum }) {}
}

export class SubmitShelfInventoryFirstLotRequestAction implements Action {
  readonly type = ShelfInventoryActionType.SUBMIT_SHELF_INVENTORY_FIRST_LOT_REQUEST;

  constructor(public payload: ShelfInventoryCreateRequest) {}
}

export class SubmitShelfInventoryFirstLotResponseAction implements Action {
  readonly type = ShelfInventoryActionType.SUBMIT_SHELF_INVENTORY_FIRST_LOT_RESPONSE;

  constructor(public payload: ShelfInventoryRequestViewResponse) {}
}

export class SubmitShelfInventoryFirstLotErrorAction implements Action {
  readonly type = ShelfInventoryActionType.SUBMIT_SHELF_INVENTORY_FIRST_LOT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResetShelfInventorySelected implements Action {
  readonly type = ShelfInventoryActionType.SHELF_GET_BY_ID_RESET;
}

export type ShelfInventoryActions =
  | ShelfInventoryListRequestAction
  | ShelfInventoryListResponseAction
  | ShelfInventoryListHistoryRequestAction
  | ShelfInventoryListHistoryResponseAction
  | ShelfInventoryByIdRequestAction
  | ShelfInventoryByIdResponseAction
  | EditShelfInventoryAction
  | ResetShelfInventorySelected
  | SubmitShelfInventoryFirstLotRequestAction
  | SubmitShelfInventoryFirstLotResponseAction
  | SubmitShelfInventoryFirstLotErrorAction;
