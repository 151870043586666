<div class="td-card ng-star-inserted px-3 py-6" [formGroup]="getParentForm">
  <div class="d-flex flex-row" style="gap: 20px">
    <div class="p-0 d-flex align-items-center justify-content-center">
      <div class="phone-img ">
        <em class="fa nicon icon-phone-contact rounded"></em>
      </div>
    </div>
    <div class="p-0 flex-item d-flex flex-column cursor-pointer" *ngIf="!isViewMode">
      <div class="row">
        <div class="col-12">
          <label [for]="'contact-name-' + index"
            >Contact Name {{ index + 1 }}<span class="text-danger">*</span></label
          >
          <input
            [id]="'contact-name-' + index"
            [attr.id]="'contact-name-' + index"
            type="text"
            class="form-control"
            maxlength="100"
            placeholder="Contact Name"
            formControlName="contactName"
            [class]="isPhoneError(contactName)"
          />
          <div [ngClass]="isPhoneError(contactName) ? 'mt-1 invalid-feedback' : 'pb-3 mt-1'">
            <ng-template [ngIf]="submitted && contactName.errors?.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="p-0 flex-item d-flex flex-column cursor-pointer" [ngClass]="{ 'hide-contact': isViewMode }">
      <div class="row">
        <div class="col-10 col-md-10">
          <label [for]="'contact-phone-' + index">Phone Number<span class="text-danger">*</span></label>
          <ngx-intl-tel-input
            [cssClass]="'form-control d-grid ' + isPhoneError(phone)"
            [preferredCountries]="['th']"
            [enableAutoCountrySelect]="true"
            [maxLength]="25"
            [id]="'contact-phone-' + index"
            [attr.id]="'contact-phone-' + index"
            formControlName="phone"
            [ngClass]="{ disabled: phone.disabled }"
            [hasChange]="isRequestTypeEdit(type) && listOfChange?.phone"
          ></ngx-intl-tel-input>
          <div [ngClass]="isPhoneError(phone) ? 'mt-1 invalid-feedback' : 'pb-3 mt-1'">
            <ng-template [ngIf]="submitted && isPhoneError(phone)">
              <ng-template [ngIf]="phone.errors?.required" [ngIfElse]="validatephone">
                {{ 'ERRORS.REQUIRED' | translate }}
              </ng-template>
              <ng-template #validatephone>
                {{ 'ERRORS.INVALID_PHONE' | translate }}
              </ng-template>
            </ng-template>
          </div>
        </div>
        <div style="padding-top: 5px;">
          <div class="d-flex flex-row align-items-center h-100">
            <button
              type="button"
              [id]="'delete' + index"
              [attr.id]="'delete' + index"
              class="btn btn-bin-delete ng-star-inserted d-flex flex-column justify-content-center"
              title="Delete"
              (click)="deleteContact()"
              [disabled]="index === 0 && !(phone.value || contactName.value)"
            >
              <em class="icon-delete"></em>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="p-0 flex-item d-flex flex-column cursor-pointer" *ngIf="isViewMode">
      <div class="row">
        <div class="col-5 contact-label">Contact Name {{ index + 1 }}:</div>
        <div class="col-7 ">
          <label [id]="'contact-name-' + index" [attr.id]="'contact-name-' + index">{{ contactName.value }}</label>
        </div>
        <div class="col-12 pt-1 pb-1 pl-2 pr-3">
          <hr class="m-0" />
        </div>
        <div class="col-5 contact-label" [id]="'contact-phone-' + index" [attr.id]="'contact-phone-' + index">
          Phone Number:
        </div>
        <div class="col-7">
          <label>{{ contactPhoneDisplay }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
