<div class="select-item-modal full-popup-head">
  <a aria-label="Back" class="clickable" (click)="onBack()">
    <div class="full-popup-close float-left">
      <span aria-label="Close" class="close">
        <em class="icon-prev ml-2"></em>
      </span>
    </div>
    <div class="full-popup-title">Back to Order Request</div>
  </a>
</div>

<div class="admin-form modal-table-wrap">
  <div class="row section-header-item">
    <span>Select Shelf Fix Asset</span>
  </div>
  <div class="d-flex flex-row">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="ml-auto">
      <div class="form-group ml-auto filter-search-box">
        <div class="search-box position-relative">
          <input
            type="text"
            formControlName="searchCriteria"
            class="form-control searchCriteria"
            placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
            required
            maxlength="50"
            (keyup.backspace)="clearLastKeyUpSearchText()"
          />
          <em
            class="icon-close-mini"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
          <button class="btn-search" type="submit">
            <em class="icon-search"></em>
          </button>
        </div>
      </div>
    </form>
  </div>

  <app-no-search-result [resultList$]="listState$" [hasSearchCriteria]="isSearch"></app-no-search-result>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive table-scroll mt-3 mb-3">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100" aria-label="data-table">
        <thead>
          <tr>
            <th class="text-center w-5" scope="col">No.</th>
            <th class="text-center w-70" scope="col">Shelf Name</th>
            <th class="text-center w-15" scope="col">Quantity</th>
            <th class="text-center w-10" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of resultList$ | async; index as i">
            <td class="text-center">
              {{ (currentPage - 1) * pageSize + i + 1 }}
            </td>
            <td>{{ result.shelfInfo.shelfName | dashDisplay: '(None)' }}</td>
            <td class="text-center p-10px">
              <app-numeric-textbox
                #quantity
                class="form-control td-numeric-textbox-center"
                placeholder="Quantity"
                [format]="'0'"
                maxlength="5"
                value="1"
                [minValue]="1"
                [maxValue]="99999"
                [negativeSign]="false"
                [decimals]="0"
                [allowPaste]="false"
                [ngClass]="{
                  'is-invalid': quantity.value === 0 || quantity.value === null
                }"
              ></app-numeric-textbox>
              <div class="invalid-feedback text-left">
                <div *ngIf="quantity.value === null">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <div *ngIf="quantity.value === 0">
                  <span translate="ERRORS.NOT_ALLOW" [translateParams]="{ value: '0' }"></span>
                </div>
              </div>
            </td>

            <td class="text-center">
              <em
                class="icon-cart md-size mr-1"
                title="Add"
                appTippy
                [tippyOptions]="{ arrow: true }"
                (click)="addItem(result.shelfNo, quantity.value)"
              >
              </em>

              <div class="text-success" *ngIf="getAddedItem(result.shelfNo) > 0">
                <span class="text-success">Added = {{ getAddedItem(result.shelfNo) | numberFormatDisplay: 0 }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <!--footer-->
  <div class="fixed-row-bottom form-row">
    <div class="d-flex flex-row justify-content-end">
      <div class="flex-column mr-4">
        <div class="info-header-label">
          <span>Total Fix Asset</span>
        </div>
        <div class="info-header-value">{{ selectedShelf.length }}</div>
      </div>
      <div class="flex-column">
        <button
          type="button"
          id="btnGenereate"
          class="btn btn-primary"
          [disabled]="selectedShelf.length === 0"
          (click)="onGenerateShelfFixAsset()"
        >
          Generate Shelf Fix Asset
        </button>
      </div>
    </div>
  </div>
  <!--End footer-->
</div>
