import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SignedUrlObject } from '../models';
import { SalesTransactionExportCriteria } from '../models/report.model';
import { metricsReporting } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable()
export class SalesTransactionReportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.salesTransactionReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  reportExport(reportCriteria: SalesTransactionExportCriteria) {
    const url = this.getFullUrl(this.envService.export);
    const metrics = metricsReporting([this.envService.url, this.envService.export]);

    return this.http.post<any>(url, reportCriteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }

  reportExportConfidential(reportCriteria: SalesTransactionExportCriteria) {
    const params = this.getParams(reportCriteria, true);

    const url = this.getFullUrl(this.envService.exportConfidential);
    const metrics = metricsReporting([this.envService.url, this.envService.exportConfidential]);

    // TODO Add change x-host
    let header = new HttpHeaders({
      'x-host': 'store.api.tdshop.io',
      'x-tenant-id': '*:*:*:*'
    });
    header = header.append('metrics', JSON.stringify(metrics));
    header = header.append('x-frontend-spinner-loader', (0).toString());

    return this.http.get<any>(url, {
      headers: header,
      params,
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  reportExportConfidentialSign(reportCriteria: SalesTransactionExportCriteria) {
    const url = this.getFullUrl(this.envService.exportConfidential);
    const metrics = metricsReporting([this.envService.url, this.envService.exportConfidential]);

    return this.http.post<SignedUrlObject>(url, reportCriteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
