import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GroupOrderUnpickedItemReportCriteria } from '../models/report.model';
import { metricsReporting } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class GroupOrderReportService extends BaseService {
  constructor(private http: HttpClient) {
    super();
    this.envService = this.env.services.exportReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  exportUnpickedItemReport(reportCriteria: GroupOrderUnpickedItemReportCriteria) {
    const url = this.getFullUrl(this.envService.groupGroupUnpickedItem, null, true);
    const metrics = metricsReporting([this.envService.groupGroupUnpickedItem]);

    return this.http.post<any>(url, reportCriteria, {
      headers: this.loaderHeaders(0, metrics),
      observe: 'body'
    });
  }
}
