import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ShelfFixAssetRequestService } from '../../services/shelf-fix-asset-request.service';
import { ShelfRequestService } from '../../services/shelf-request.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ShelfApproveRequestAction,
  ShelfApproveResponseAction,
  ShelfCreateSaveErrorAction,
  ShelfCreateSaveRequestAction,
  ShelfCreateSaveResponseAction,
  ShelfCreateSubmitErrorAction,
  ShelfCreateSubmitRequestAction,
  ShelfCreateSubmitResponseAction,
  ShelfDeleteRequestAction,
  ShelfDeleteResponseAction,
  ShelfFixAssetApproveRequestAction,
  ShelfFixAssetApproveResponseAction,
  ShelfFixAssetCreateSaveErrorAction,
  ShelfFixAssetCreateSaveRequestAction,
  ShelfFixAssetCreateSaveResponseAction,
  ShelfFixAssetCreateSubmitErrorAction,
  ShelfFixAssetCreateSubmitRequestAction,
  ShelfFixAssetCreateSubmitResponseAction,
  ShelfFixAssetDeleteRequestAction,
  ShelfFixAssetDeleteResponseAction,
  ShelfFixAssetRequestByIdRequestAction,
  ShelfFixAssetRequestByIdResponseAction,
  ShelfFixAssetRequestListHistoryRequestAction,
  ShelfFixAssetRequestListHistoryResponseAction,
  ShelfFixAssetRequestListRequestAction,
  ShelfFixAssetRequestListResponseAction,
  ShelfRequestActionType,
  ShelfRequestByIdRequestAction,
  ShelfRequestByIdResponseAction,
  ShelfRequestListHistoryRequestAction,
  ShelfRequestListHistoryResponseAction,
  ShelfRequestListRequestAction,
  ShelfRequestListResponseAction
} from '../actions/shelf-request.actions';

@Injectable()
export class ShelfRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly shelfRequestService: ShelfRequestService,
    private readonly shelfFixAssetRequestService: ShelfFixAssetRequestService,
    private readonly logger: NGXLogger
  ) {}

  searchShelfRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfRequestListRequestAction>(ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfRequestService.searchByCriteria(payload).pipe(
          map(response => {
            return new ShelfRequestListResponseAction(response);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  searchShelfFixAssetRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfFixAssetRequestListRequestAction>(ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST_FIX_ASSET),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST_FIX_ASSET}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfFixAssetRequestService.searchByCriteriaFixAsset(payload).pipe(
          map(response => {
            return new ShelfFixAssetRequestListResponseAction(response);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  shelfCreateSave$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfCreateSaveRequestAction>(ShelfRequestActionType.SHELF_CREATE_SAVE_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_CREATE_SAVE_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfRequestService.saveRequest(payload).pipe(
          map(res => new ShelfCreateSaveResponseAction(res)),
          catchError(error => of(new ShelfCreateSaveErrorAction(error)))
        );
      })
    );
  });

  shelfFixAssetCreateSave$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfFixAssetCreateSaveRequestAction>(ShelfRequestActionType.SHELF_CREATE_SAVE_REQUEST_FIX_ASSET),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_CREATE_SAVE_REQUEST_FIX_ASSET}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfFixAssetRequestService.saveRequestFixAsset(payload).pipe(
          map(res => new ShelfFixAssetCreateSaveResponseAction(res)),
          catchError(error => of(new ShelfFixAssetCreateSaveErrorAction(error)))
        );
      })
    );
  });

  shelfCreateSubmit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfCreateSubmitRequestAction>(ShelfRequestActionType.SHELF_CREATE_SUBMIT_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_CREATE_SUBMIT_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfRequestService.submitRequest(payload).pipe(
          map(res => new ShelfCreateSubmitResponseAction(res)),
          catchError(error => of(new ShelfCreateSubmitErrorAction(error.error)))
        );
      })
    );
  });

  shelfFixAssetCreateSubmit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfFixAssetCreateSubmitRequestAction>(ShelfRequestActionType.SHELF_CREATE_SUBMIT_REQUEST_FIX_ASSET),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_CREATE_SUBMIT_REQUEST_FIX_ASSET}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfFixAssetRequestService.submitRequestFixAsset(payload).pipe(
          map(res => new ShelfFixAssetCreateSubmitResponseAction(res)),
          catchError(error => of(new ShelfFixAssetCreateSubmitErrorAction(error.error)))
        );
      })
    );
  });

  getShelfRequestById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfRequestByIdRequestAction>(ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfRequestService.getShelfRequestById(payload).pipe(
          map(res => {
            return new ShelfRequestByIdResponseAction(res);
          }),

          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  getShelfFixAssetRequestById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfFixAssetRequestByIdRequestAction>(ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_REQUEST_FIX_ASSET),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_REQUEST_FIX_ASSET}: ` +
            this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfFixAssetRequestService.getShelfRequestById(payload).pipe(
          map(res => {
            return new ShelfFixAssetRequestByIdResponseAction(res);
          }),

          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  shelfApprove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfApproveRequestAction>(ShelfRequestActionType.SHELF_REQUEST_APPROVE_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_REQUEST_APPROVE_REQUEST}: ` + this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload =>
        this.shelfRequestService.approveRequest(payload).pipe(
          map(() => new ShelfApproveResponseAction({ isSuccess: true })),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  shelfFixAssetApprove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfFixAssetApproveRequestAction>(ShelfRequestActionType.SHELF_REQUEST_APPROVE_REQUEST_FIX_ASSET),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_REQUEST_APPROVE_REQUEST_FIX_ASSET}: ` +
            this.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload =>
        this.shelfFixAssetRequestService.approveRequest(payload).pipe(
          map(() => new ShelfFixAssetApproveResponseAction({ isSuccess: true })),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  shelfRequestHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfRequestListHistoryRequestAction>(ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_REQUEST}: ` + JSON.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfRequestService.getHistoryLogs(payload).pipe(
          map(response => new ShelfRequestListHistoryResponseAction({ auditLogs: response.auditLogs })),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  shelfFixAssetRequestHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfFixAssetRequestListHistoryRequestAction>(
        ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_REQUEST_FIX_ASSET
      ),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_REQUEST_FIX_ASSET}: ` +
            JSON.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload => {
        return this.shelfFixAssetRequestService.getHistoryLogs(payload).pipe(
          map(response => new ShelfFixAssetRequestListHistoryResponseAction({ auditLogs: response.auditLogs })),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  shelfRequestDelete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfDeleteRequestAction>(ShelfRequestActionType.SHELF_REQUEST_DELETE_REQUEST),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_REQUEST_DELETE_REQUEST}: ` + JSON.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload =>
        this.shelfRequestService.deleteByRequestId(payload).pipe(
          map(() => new ShelfDeleteResponseAction({ isSuccess: true })),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  shelfFixAssetRequestDelete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShelfFixAssetDeleteRequestAction>(ShelfRequestActionType.SHELF_REQUEST_DELETE_REQUEST_FIX_ASSET),
      map(action => {
        this.logger.debug(
          `@Effect ${ShelfRequestActionType.SHELF_REQUEST_DELETE_REQUEST_FIX_ASSET}: ` +
            JSON.stringify(action.payload)
        );
        return action.payload;
      }),
      switchMap(payload =>
        this.shelfFixAssetRequestService.deleteByRequestId(payload).pipe(
          map(() => new ShelfFixAssetDeleteResponseAction({ isSuccess: true })),
          catchError(error => of(new LayoutActionLoadError(error)))
        )
      )
    );
  });

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
