import { createSelector } from '@ngrx/store';

import * as fromActiveWarehouseState from '../reducers/active-warehouse.reducers';
import * as fromWarehouseState from '../reducers/warehouse.reducers';
import { AppStates } from '../state/app.states';

const selectWarehouseState = (state: AppStates) => state.warehouse;
const selectActiveWarehouseState = (state: AppStates) => state.activeWarehouse;

export const selectAllWarehouses = createSelector(selectWarehouseState, fromWarehouseState.selectAll);
export const selectAllActiveWarehouses = createSelector(
  selectActiveWarehouseState,
  fromActiveWarehouseState.selectAll
);

export const selectAllWarehouse = (type: string = null) =>
  createSelector(selectAllWarehouses, warehouses => {
    if (!type) {
      return warehouses;
    } else {
      return warehouses.filter(warehouse => warehouse.type === type);
    }
  });

export const selectAllActiveWarehouse = (type: string = null) =>
  createSelector(selectAllActiveWarehouses, warehouses => {
    if (!type) {
      return warehouses;
    } else {
      return warehouses.filter(warehouse => warehouse.type === type);
    }
  });

export const selectWarehouseList = createSelector(
  selectWarehouseState,
  (state: fromWarehouseState.WarehouseState) => state.selectWarehouseList
);

const selectWarehousePagingState = (state: AppStates) => state.warehouse.selectWarehousePagingList;

export const selectWarehousePagingList = createSelector(
  selectWarehousePagingState,
  fromWarehouseState.selectAllWarehousePagingList
);

export const selectWarehousePagingTotalElements = createSelector(
  selectWarehousePagingState,
  (state: fromWarehouseState.SelectWarehouseState) => state.totalElement
);

export const selectWarehousePagingLoading = createSelector(
  selectWarehousePagingState,
  (state: fromWarehouseState.SelectWarehouseState) => state.isLoading
);
