import { Action } from '@ngrx/store';

import {
  StackPricingDeleteRequest,
  StackPricingListResponse,
  StackPricingSearchCriteria,
  StackPricingView
} from '../../models/stack-pricing.model';

export enum StackPricingActionTypes {
  StackPricingSearchRequested = '[Stack Pricing List Page] Stack Pricing Search Requested',
  StackPricingSearchLoaded = '[Stack Pricing List API] Stack Pricing Search Loaded',
  StackPricingViewRequested = '[Stack Pricing View Page] Stack Pricing View Requested',
  StackPricingViewLoaded = '[Stack Pricing View Page] Stack Pricing View Loaded',
  StackPricingViewSubmitRequested = '[Stack Pricing View Page] Stack Pricing Submit Requested',
  StackPricingDeleteRequested = '[Stack Pricing View Page] Stack Pricing Delete Requested',
  StackPricingHistoryRequested = '[Stack Pricing History Page] Stack Pricing History Requested',
  StackPricingHistoryLoaded = '[Stack Pricing History API] Stack Pricing History Loaded'
}

export class StackPricingSearchRequested implements Action {
  readonly type = StackPricingActionTypes.StackPricingSearchRequested;
  constructor(public payload: StackPricingSearchCriteria) {}
}

export class StackPricingSearchLoaded implements Action {
  readonly type = StackPricingActionTypes.StackPricingSearchLoaded;
  constructor(public payload: { stackPricings: StackPricingListResponse }) {}
}

export class StackPricingViewRequested implements Action {
  readonly type = StackPricingActionTypes.StackPricingViewRequested;
  constructor(public payload: { articleNo: string }) {}
}

export class StackPricingViewLoaded implements Action {
  readonly type = StackPricingActionTypes.StackPricingViewLoaded;
  constructor(public payload: { stackPricingView: StackPricingView }) {}
}

export class StackPricingViewSubmit implements Action {
  readonly type = StackPricingActionTypes.StackPricingViewSubmitRequested;
  constructor(public payload: StackPricingView) {}
}

export class StackPricingDeleteRequested implements Action {
  readonly type = StackPricingActionTypes.StackPricingDeleteRequested;
  constructor(public payload: StackPricingDeleteRequest) {}
}

export class StackPricingHistoryRequested implements Action {
  readonly type = StackPricingActionTypes.StackPricingHistoryRequested;
  constructor(public payload: { articleNo: string }) {}
}

export class StackPricingHistoryLoaded implements Action {
  readonly type = StackPricingActionTypes.StackPricingHistoryLoaded;
  constructor(public payload: { auditLogs }) {}
}

export type StackPricingActions =
  | StackPricingSearchRequested
  | StackPricingSearchLoaded
  | StackPricingViewRequested
  | StackPricingViewLoaded
  | StackPricingViewSubmit
  | StackPricingDeleteRequested
  | StackPricingHistoryRequested
  | StackPricingHistoryLoaded;
