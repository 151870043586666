import { Action } from '@ngrx/store';

import { ErrorResponse } from '../../../shared/models';
import {
  VoucherListResponse,
  VoucherListSearchCriteria,
  VoucherRequestWithComment,
  VoucherViewResponse
} from '../models/voucher.model';

export enum VoucherActionTypes {
  VOUCHER_LIST_REQUEST = '[Voucher] List Request',
  VOUCHER_LIST_RESPONSE = '[Voucher] List Response',
  VOUCHER_LIST_ERROR = '[Voucher] List Error',
  VOUCHER_CANCEL = '[Voucher] Voucher Cancel',
  VOUCHER_GET_BY_CODE_REQUEST = '[Voucher] Get Voucher by VoucherCode Request',
  VOUCHER_GET_BY_CODE_RESPONSE = '[Voucher] Get Voucher by VoucherCode Response',
  VOUCHER_GET_BY_CODE_ERROR = '[Voucher] Get Voucher by VoucherCode Error',
  VOUCHER_GET_BY_CODE_RESET = '[Voucher] Get Voucher by VoucherCode Reset'
}

export class VoucherListRequestAction implements Action {
  readonly type = VoucherActionTypes.VOUCHER_LIST_REQUEST;

  constructor(public payload: VoucherListSearchCriteria) {}
}

export class VoucherListResponseAction implements Action {
  readonly type = VoucherActionTypes.VOUCHER_LIST_RESPONSE;

  constructor(public payload: VoucherListResponse) {}
}

export class VoucherListErrorAction implements Action {
  readonly type = VoucherActionTypes.VOUCHER_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class VoucherCancelAction implements Action {
  readonly type = VoucherActionTypes.VOUCHER_CANCEL;

  constructor(public payload: VoucherRequestWithComment) {}
}

export class VoucherByVoucherCodeRequestAction implements Action {
  readonly type = VoucherActionTypes.VOUCHER_GET_BY_CODE_REQUEST;

  constructor(public payload: { voucherCode: string }) {}
}

export class VoucherByVoucherCodeResponseAction implements Action {
  readonly type = VoucherActionTypes.VOUCHER_GET_BY_CODE_RESPONSE;

  constructor(public payload: { VoucherView: VoucherViewResponse }) {}
}

export class VoucherByVoucherCodeErrorAction implements Action {
  readonly type = VoucherActionTypes.VOUCHER_GET_BY_CODE_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResetVoucherByVoucherCodeRequestSelected implements Action {
  readonly type = VoucherActionTypes.VOUCHER_GET_BY_CODE_RESET;
}

export type VoucherAction =
  | VoucherByVoucherCodeRequestAction
  | VoucherByVoucherCodeResponseAction
  | VoucherByVoucherCodeErrorAction
  | ResetVoucherByVoucherCodeRequestSelected
  | VoucherListRequestAction
  | VoucherListResponseAction
  | VoucherListErrorAction;
