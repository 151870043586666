import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-advanced-search-tags',
  templateUrl: './advanced-search-tags.component.html',
  styleUrls: ['./advanced-search-tags.component.scss']
})
export class AdvancedSearchTagsComponent implements OnInit {
  @Input() listTags: Map<string, any>;
  @Output() clearAll: EventEmitter<any> = new EventEmitter();
  @Output() clearByTag: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    // intentionally empty
  }

  ngOnInit() {}

  clearAllTags() {
    this.clearAll.emit();
  }

  clearTag(key: string) {
    this.clearByTag.emit(key);
  }

  originalOrder = (): number => {
    return 0;
  };
}
