import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { SelectWarehouseList, WarehouseListContent } from '../../models/warehouse.model';
import { WarehouseAction, WarehouseActionTypes } from '../actions/warehouse.actions';

export interface ActiveWarehouseState extends EntityState<WarehouseListContent> {
  isLoading: boolean;
  selectWarehousePagingList: SelectActiveWarehouseState;
  selectWarehouseList: SelectWarehouseList[];
}

export interface SelectActiveWarehouseState extends EntityState<SelectWarehouseList> {
  isLoading: boolean;
  totalElement: number;
}

export const adapter: EntityAdapter<WarehouseListContent> = createEntityAdapter<WarehouseListContent>();
export const adapterSelectActiveWarehouse: EntityAdapter<SelectWarehouseList> = createEntityAdapter<
  SelectWarehouseList
>();

export const initialSelectActiveWarehouseState: SelectActiveWarehouseState = adapterSelectActiveWarehouse.getInitialState(
  {
    isLoading: false,
    totalElement: 0
  }
);
export const initialActiveWarehouseState: ActiveWarehouseState = adapter.getInitialState({
  isLoading: false,
  selectWarehousePagingList: initialSelectActiveWarehouseState,
  selectWarehouseList: []
});

export function activeWarehouseReducers(
  state = initialActiveWarehouseState,
  action: WarehouseAction
): ActiveWarehouseState {
  switch (action.type) {
    case WarehouseActionTypes.WAREHOUSE_ACTIVE_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, { ...state });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const { selectAll: selectAllWarehousePagingList } = adapterSelectActiveWarehouse.getSelectors();
