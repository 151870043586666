import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { Observable, forkJoin, of } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';

import { ApproveStatusEnum } from '../../../shared/enum/approve-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { NewRequestStatusEnum } from '../../../shared/enum/request-status.enum';
import {
  NewRequestTypeEnum,
  RequestPageModesEnum,
  RequestProductErrorEnum
} from '../../../shared/enum/request-step.enum';
import { ShelfPages } from '../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ErrorResponse, TaskModuleUrl } from '../../../shared/models';
import { BarcodeListSearchCriteria, BarcodeResponse } from '../../../shared/models/barcode.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  MerchandiseItem,
  NonMerchandiseItem,
  ShelfCreateRequest,
  ShelfRequestListSearchCriteria,
  ShelfRequestViewResponse
} from '../../../shared/models/shelf-request.model';
import { AuthGuardService, BarcodeService } from '../../../shared/services';
import { ShelfRequestService } from '../../../shared/services/shelf-request.service';
import {
  ShelfApproveRequestAction,
  ShelfCreateResetAction,
  ShelfCreateSaveRequestAction,
  ShelfCreateSubmitRequestAction,
  ShelfRequestByIdRequestAction,
  ShelfRequestByIdResetAction,
  ShelfRequestListRequestAction
} from '../../../shared/store/actions/shelf-request.actions';
import { ShelfCreateResponseState } from '../../../shared/store/reducers/shelf-create.reducers';
import { selectShelf, selectShelfApproveRejectStatus } from '../../../shared/store/selectors/shelf-create.selectors';
import {
  selectShelfRequestById,
  selectShelfRequestListCriteria
} from '../../../shared/store/selectors/shelf-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { getFileId } from '../../../shared/utils/get-fileId-util';
import { ShelfFixAssetComponent } from '../shelf-components/shelf-fix-asset/shelf-fix-asset.component';
import { ShelfInfoComponent } from '../shelf-components/shelf-info/shelf-info.component';
import { ShelfInventoryComponent } from '../shelf-components/shelf-inventory/shelf-inventory.component';

@Component({
  selector: 'app-shelf-request',
  templateUrl: './shelf-request.component.html',
  styleUrls: ['./shelf-request.component.scss']
})
export class ShelfRequestComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  constructor(
    private readonly fb: UntypedFormBuilder,
    protected readonly modalService: BsModalService,
    private readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    private readonly shelfRequestService: ShelfRequestService,
    private readonly barcodeService: BarcodeService,
    private readonly authGuardService: AuthGuardService,
    protected readonly logger: NGXLogger
  ) {
    super();
  }

  get requestMode() {
    return RequestPageModesEnum;
  }

  @ViewChild('shelfInfo') shelfInfo: ShelfInfoComponent;
  @ViewChild('fixAsset') fixAsset: ShelfFixAssetComponent;
  @ViewChild('inventory') inventory: ShelfInventoryComponent;
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: RequestPageModesEnum;
    requestId?: string;
    originPage?: string;
  };

  public page: ShelfPages;
  public type: NewRequestTypeEnum;
  public saved: boolean;
  public shelfRequestForm: UntypedFormGroup;
  public shelfRequestView$: Observable<ShelfRequestViewResponse>;
  public status: NewRequestStatusEnum;
  public submitted: boolean;

  private requestNo: string;
  private localStore: Observable<any>;
  private listSearchCriteria: ShelfRequestListSearchCriteria;

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(new ShelfCreateResetAction());
    this.store.dispatch(new ShelfRequestByIdResetAction());

    if (this.data && this.data.originPage !== TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new ShelfRequestListRequestAction(this.listSearchCriteria));
    }
  }

  ngOnInit() {
    this.page = ShelfPages.SHELF_REQUEST;
    this.type = NewRequestTypeEnum.NEW;
    this.status = NewRequestStatusEnum.DRAFT;

    this.shelfRequestForm = this.createForm();
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.shelfRequestView$ = this.localStore.pipe(select(selectShelfRequestById));

    this.shelfRequestView$.pipe(filter(data => Boolean(data))).subscribe(data => {
      this.requestNo = data.requestNo;
      this.type = data.type;
      this.status = data.status;

      if (this.data.mode === RequestPageModesEnum.REQUEST_EDIT) {
        const fixAssetBarcode = data.nonMerchandiseItem.map(item => item.barcode);
        const inventoryBarcode = data.merchandiseItem.map(item => item.barcode);

        this.itemValidator(fixAssetBarcode, inventoryBarcode);
      }
    });

    this.localStore
      .pipe(select(selectShelfRequestListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.localStore
      .pipe(
        select(selectShelf),
        filter(value => Boolean(value && value.result))
      )
      .subscribe((value: ShelfCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          this.alertSuccessModal(this.getSubmitMessage(value.isSave));
        } else {
          this.alertErrorModal(result.errorResponse);
        }
      });

    if (this.data.mode !== RequestPageModesEnum.REQUEST_CREATE) {
      this.store.dispatch(new ShelfRequestByIdRequestAction({ requestId: this.data.requestId }));
    }
  }

  getColorStatus(status) {
    return status && status.toLocaleLowerCase();
  }

  createForm() {
    return this.fb.group({});
  }

  onSave() {
    this.saved = true;
    const form = this.shelfRequestForm.getRawValue();

    if (!form.shelfInfo.shelfName) {
      return;
    }

    this.store.dispatch(new ShelfCreateSaveRequestAction(this.prepareRequestData()));
  }

  onSubmit() {
    this.submitted = true;

    if (this.status !== NewRequestStatusEnum.AWAITING_FIRST_LOT) {
      const fixAssetBarcode = this.shelfRequestForm.getRawValue().fixAssetItem.map(data => data.barcode);
      const inventoryBarcode = this.shelfRequestForm.getRawValue().inventoryItem.map(data => data.barcode);

      this.itemValidator(fixAssetBarcode, inventoryBarcode);
    } else {
      const errorMessageFixAsset = this.getFormGroupErrorMessage(ProductTypeEnum.FIX_ASSET);
      const errorMessageInventory = this.getFormGroupErrorMessage(ProductTypeEnum.INVENTORY);
      const errorMessage = errorMessageFixAsset || errorMessageInventory;

      if (errorMessage) {
        this.showModalError(errorMessage);
      } else if (this.fixAsset.form.invalid) {
        this.validateError(this.fixAsset);
      } else if (this.inventory.form.invalid) {
        this.validateError(this.inventory);
      } else if (this.shelfRequestForm.valid) {
        this.handleConfirm();
      }
    }
  }

  itemValidator(fixAssetBarcode: string[], inventoryBarcode: string[]) {
    const fixAssetList$: Observable<BarcodeResponse[]> = this.barcodeValidator(
      fixAssetBarcode,
      ProductTypeEnum.FIX_ASSET
    );
    const inventoryList$: Observable<BarcodeResponse[]> = this.barcodeValidator(
      inventoryBarcode,
      ProductTypeEnum.INVENTORY
    );

    forkJoin([fixAssetList$, inventoryList$])
      .pipe(
        tap(([fixAssetList, inventoryList]) => {
          this.updateFormGroup(fixAssetList, ProductTypeEnum.FIX_ASSET);
          this.updateFormGroup(inventoryList, ProductTypeEnum.INVENTORY);
        }),
        filter(() => this.submitted)
      )
      .subscribe(() => {
        const errorMessageFixAsset = this.getFormGroupErrorMessage(ProductTypeEnum.FIX_ASSET);
        const errorMessageInventory = this.getFormGroupErrorMessage(ProductTypeEnum.INVENTORY);
        const errorMessage = errorMessageFixAsset || errorMessageInventory;

        if (errorMessage) {
          this.showModalError(errorMessage);
        } else if (this.fixAsset.form.invalid) {
          this.validateError(this.fixAsset);
        } else if (this.inventory.form.invalid) {
          this.validateError(this.inventory);
        } else if (this.shelfRequestForm.valid) {
          this.handleConfirm();
        }
      });
  }

  validateError(form) {
    const invalidIndex = form.formControls.findIndex(item => item.invalid);
    form.paging.navigateToErrorIndex(invalidIndex);

    const error = form.formControls.find(item => !!item.errors);
    if (error) {
      this.showModalError('Please delete invalid data before submit.');
    }
  }

  barcodeValidator(barcodes: string[], productType: ProductTypeEnum) {
    const barcodeList = this.barcodeService.searchBarcodeByCriteria(
      barcodes,
      new BarcodeListSearchCriteria({
        allowRestrictItem: true,
        allowProductType: productType,
        size: barcodes.length
      })
    );

    return barcodes.length ? barcodeList : of([]);
  }

  updateFormGroup(barcode: BarcodeResponse[], productType: ProductTypeEnum.INVENTORY | ProductTypeEnum.FIX_ASSET) {
    if (!barcode.length) {
      return;
    }

    let formGroup;
    let mappingBarcodeResponse;

    if (productType === ProductTypeEnum.INVENTORY) {
      formGroup = this.shelfRequestForm.get('inventoryItem');
      mappingBarcodeResponse = MerchandiseItem.mappingBarcodeResponseToMerchandiseItem;
    } else {
      formGroup = this.shelfRequestForm.get('fixAssetItem');
      mappingBarcodeResponse = NonMerchandiseItem.mappingBarcodeResponseToNonMerchandiseItem;
    }

    barcode.forEach((data, i) => {
      formGroup.controls[i].patchValue({
        ...mappingBarcodeResponse(data),
        ...formGroup.getRawValue()[i]
      });

      if (formGroup.controls[i].get('articleNo') && formGroup.controls[i].get('articleNo').value !== data.articleNo) {
        data.errorMessage = RequestProductErrorEnum.INVALID_BARCODE;
      }

      if (data.errorMessage) {
        this.validateForm(formGroup.controls[i], data.errorMessage);
      }
    });
  }

  validateForm(formItem: UntypedFormGroup, errorMessage: RequestProductErrorEnum) {
    let childItem;

    const newValidator = this.setValidator(errorMessage);

    switch (errorMessage) {
      case RequestProductErrorEnum.INVALID_BARCODE:
      case RequestProductErrorEnum.INACTIVE_BARCODE:
      case RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD:
        childItem = formItem.get('barcode');
        break;
      case RequestProductErrorEnum.RESTRICT_ITEM:
      case RequestProductErrorEnum.STATUS_IS_DELISTED:
      case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
      case RequestProductErrorEnum.NOT_ALLOW_INVENTORY:
      case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
        childItem = formItem.get('productName');
        break;
      default:
        this.logger.error(`ErrorMessage: ${errorMessage} did not supported in barcode and productName.`);
        return;
    }

    childItem.setValidators(newValidator);
    childItem.updateValueAndValidity();

    formItem.setValidators(newValidator);
    formItem.updateValueAndValidity();
  }

  setValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      switch (errorMessage) {
        case RequestProductErrorEnum.INVALID_BARCODE:
          return { invalidBarcode: true };
        case RequestProductErrorEnum.INACTIVE_BARCODE:
          return { isInactive: true };
        case RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD:
          return { duplicated: true };
        case RequestProductErrorEnum.RESTRICT_ITEM:
          return { isRestrictItem: true };
        case RequestProductErrorEnum.STATUS_IS_DELISTED:
          return { isStatusDelisted: true };
        case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
          return { isFixAssetItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_INVENTORY:
          return { isInventoryItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
          return { isStoreUseItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_ZERO:
          return control.value !== null && control.value === 0 ? { isZero: true } : null;
        default:
          return null;
      }
    };
  }

  getFormGroupErrorMessage(productType: ProductTypeEnum.INVENTORY | ProductTypeEnum.FIX_ASSET): string | undefined {
    const itemName = productType === ProductTypeEnum.INVENTORY ? 'inventoryItem' : 'fixAssetItem';
    const formGroup = this.shelfRequestForm.get(itemName) as UntypedFormArray;

    if (!formGroup.controls.length) {
      this.shelfRequestForm.setErrors({ requiredItem: true });
      return 'Please select at least one item before submit.';
    }
  }

  showModalError(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const reqData = this.prepareRequestData();
          this.store.dispatch(new ShelfCreateSubmitRequestAction(reqData));
        }
      });
  }

  prepareRequestData() {
    const formData = this.shelfRequestForm.getRawValue();
    const template = new ShelfCreateRequest();
    template.type = this.type;
    template.status = this.status;

    this.shelfRequestView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe((value: ShelfRequestViewResponse) => {
        template.id = value.id;
        template.version = value.version;
        template.requestNo = value.requestNo;
        template.type = value.type;
        template.status = value.status;
        template.shelfNo = value.shelfNo;
      });

    template.shelfInfo = {
      ...formData.shelfInfo,
      shelfName: formData.shelfInfo.shelfName.trim(),
      shelfImage: getFileId(formData.shelfInfo.shelfImage)
    };

    template.merchandiseItem = formData.inventoryItem;

    template.nonMerchandiseItem = formData.fixAssetItem;

    return template;
  }

  onCancel() {
    if (this.shelfRequestForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onTriggerEdit() {
    this.data.title = 'Edit Shelf Request';
    this.data.mode = RequestPageModesEnum.REQUEST_EDIT;

    if (this.status !== NewRequestStatusEnum.AWAITING_FIRST_LOT) {
      const fixAssetBarcode = this.shelfRequestForm.getRawValue().fixAssetItem.map(data => data.barcode);
      const inventoryBarcode = this.shelfRequestForm.getRawValue().inventoryItem.map(data => data.barcode);

      this.itemValidator(fixAssetBarcode, inventoryBarcode);
    }
  }

  doApproveRejectRequest(isApprove: boolean) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: isApprove ? 'Are you sure you want to "Approve"?' : 'Are you sure you want to "Reject"?',
        label: 'Comment',
        okText: isApprove ? 'Approve' : 'Reject',
        okClass: isApprove ? 'btn btn-special-approve' : 'btn btn-special-reject',
        isRequiredConfirmMessage: !isApprove
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          return this.localStore.pipe(select(selectShelfApproveRejectStatus));
        })
      )
      .subscribe({
        next: isApproveRejectSuccess => {
          if (isApproveRejectSuccess) {
            this.alertApproveRejectModalSuccess(
              {
                result: {
                  response: 'success',
                  errorResponse: null
                }
              },
              isApprove
            );
          }

          this.store.dispatch(
            new ShelfApproveRequestAction({
              requestNo: this.requestNo,
              status: isApprove ? ApproveStatusEnum.APPROVED : ApproveStatusEnum.REJECTED,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        },
        error: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  hasEditPermission(): boolean {
    return (
      (this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
        [NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status) &&
        this.authGuardService.checkPermission(['shelf_m'])) ||
      (this.status === NewRequestStatusEnum.AWAITING_FIRST_LOT &&
        this.authGuardService.checkPermission(['shelf_firstlot_m']))
    );
  }

  hasSubmitPermission(): boolean {
    return (
      (this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
        [NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status) &&
        this.authGuardService.checkPermission(['shelf_m'])) ||
      (this.status === NewRequestStatusEnum.AWAITING_FIRST_LOT &&
        this.authGuardService.checkPermission(['shelf_firstlot_m']))
    );
  }

  hasApprovePermission(): boolean {
    return (
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.status === NewRequestStatusEnum.AWAITING_APPROVAL &&
      this.authGuardService.checkPermission(['shelf_app'])
    );
  }

  hasCancelPermission(): boolean {
    return (
      this.authGuardService.checkPermission(['shelf_m']) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.status === NewRequestStatusEnum.AWAITING_APPROVAL
    );
  }

  hasSavePermission(): boolean {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      this.status === NewRequestStatusEnum.DRAFT &&
      this.authGuardService.checkPermission(['shelf_m'])
    );
  }

  hasDeletePermission(): boolean {
    return this.data.mode === RequestPageModesEnum.REQUEST_VIEW && this.status === NewRequestStatusEnum.DRAFT;
  }

  hasAtLeastOnePermission() {
    return (
      this.status !== NewRequestStatusEnum.APPROVED ||
      this.hasSavePermission() ||
      this.hasEditPermission() ||
      this.hasApprovePermission()
    );
  }

  alertApproveRejectModalSuccess(resp, isApprove) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: isApprove ? 'The request has been approved.' : 'The request has been rejected.'
      },
      backdrop: 'static'
    });

    const modal = alertModal.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK || result === ModalButtonResponseEnum.CANCEL) {
          this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: resp });
        }
        if (modal) {
          modal.unsubscribe();
        }
      });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide();
        if (this.status === NewRequestStatusEnum.DRAFT) {
          this.listSearchCriteria.page = 0;
        }
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  cancelShelfRequest() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${this.requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          return this.shelfRequestService
            .approveRequest({
              requestNo: this.requestNo,
              status: ApproveStatusEnum.CANCELLED,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this));
        })
      )
      .subscribe({
        next: () => {
          this.alertSuccessModal('The request has been cancelled.');
        },
        error: error => {
          this.alertErrorModal(error.error);
        },
        complete: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  deleteShelfRequest() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?',
        okText: 'Yes, delete',
        cancelText: 'Cancel'
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          return this.shelfRequestService
            .deleteByRequestId({ requestId: this.data.requestId })
            .pipe(untilComponentDestroyed(this));
        })
      )
      .subscribe({
        next: () => {
          this.alertSuccessModal('The request has been deleted.');
        },
        error: error => {
          this.alertErrorModal(error.error);
        },
        complete: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  getSubmitMessage(isSave): string | undefined {
    if (isSave) {
      return 'The request has been saved.';
    } else if (this.status === NewRequestStatusEnum.DRAFT) {
      return 'The request has been sent to approver.';
    } else if (this.status === NewRequestStatusEnum.AWAITING_APPROVAL) {
      return 'The request has been updated.';
    } else if (this.status === NewRequestStatusEnum.AWAITING_FIRST_LOT && this.type === NewRequestTypeEnum.NEW) {
      return 'The shelf has been created.';
    } else if (this.status === NewRequestStatusEnum.AWAITING_FIRST_LOT && this.type === NewRequestTypeEnum.EDIT) {
      return 'The shelf has been updated.';
    }
  }
}
