import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreExportCriteria, StoreExportOrderScheduleCriteria } from '../models';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { metricsReporting } from '../utils/metrics-util';
import { BaseService } from './base.service';

@Injectable()
export class StoreExportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.storeExport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportStore(criteria: StoreExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.export);
    let headers = new HttpHeaders(this.envService.headers).set('x-frontend-spinner-loader', '0');

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.STORE,
          path: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.STORE,
          route: `${this.envService.url}${this.envService.export}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    headers = headers.append('metrics', JSON.stringify(metricsReq));

    return this.http.post<any>(url, this.removeNullValueObj(criteria), {
      headers,
      observe: 'body'
    });
  }

  public exportStoreConfidential(criteria: StoreExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportStoreConfidential);
    const metrics = metricsReporting([this.envService.url, this.envService.report]);
    const headers = new HttpHeaders(this.envService.headers).set('x-frontend-spinner-loader', '0');
    headers.append('metrics', JSON.stringify(metrics));

    return this.http.post<any>(url, this.removeNullValueObj(criteria), {
      headers,
      observe: 'body'
    });
  }

  public exportStoreOrderSchedule(criteria: StoreExportOrderScheduleCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportStoreOrderSchedule, null, true);
    const headers = new HttpHeaders(this.envService.headers).set('x-frontend-spinner-loader', '0');

    return this.http.post<any>(url, this.removeNullValueObj(criteria), {
      headers,
      observe: 'body'
    });
  }

  private removeNullValueObj(criteria: any) {
    return JSON.parse(JSON.stringify(criteria, (_k, v) => v ?? undefined));
  }
}
