import { Action } from '@ngrx/store';

import { ClassMarkup, ClassMarkupResponse, ClassMarkupSearchCriteria } from '../../models';

export enum ClassMarkupActionTypes {
  ClassMarkupSearchRequested = '[Class Markup List Page] Class Markup Search Requested',
  ClassMarkupSearchLoaded = '[Class Markup List API] Class Markup Search Loaded',
  ClassMarkupUpdateRequested = '[Class Markup Edit Page] Class Markup Update Requested',
  ClassMarkupUpdateSuccess = '[Class Markup Edit API] Class Markup Update Success',
  ClassMarkupRequested = '[Class Markup] Get Class Markup By Class Code',
  ClassMarkupLoaded = '[Class Markup] Get Class Markup By Class Code Success',
  ClassMarkupReset = '[Class Markup] Class Markup Reset'
}

export class ClassMarkupSearchRequested implements Action {
  readonly type = ClassMarkupActionTypes.ClassMarkupSearchRequested;
  constructor(public payload: ClassMarkupSearchCriteria) {}
}

export class ClassMarkupSearchLoaded implements Action {
  readonly type = ClassMarkupActionTypes.ClassMarkupSearchLoaded;
  constructor(public payload: { classMarkup: ClassMarkupResponse }) {}
}

export class ClassMarkupUpdateRequested implements Action {
  readonly type = ClassMarkupActionTypes.ClassMarkupUpdateRequested;
  constructor(public payload: { classMarkup: ClassMarkup }) {}
}

export class ClassMarkupUpdateSuccess implements Action {
  readonly type = ClassMarkupActionTypes.ClassMarkupUpdateSuccess;
  constructor(
    public payload: {
      id: string;
      classMarkup: Partial<ClassMarkup>;
    }
  ) {}
}

export class ClassMarkupRequested implements Action {
  readonly type = ClassMarkupActionTypes.ClassMarkupRequested;
  constructor(public payload: string) {}
}

export class ClassMarkupLoaded implements Action {
  readonly type = ClassMarkupActionTypes.ClassMarkupLoaded;
  constructor(public payload: { classMarkup: ClassMarkup }) {}
}

export class ClassMarkupReset implements Action {
  readonly type = ClassMarkupActionTypes.ClassMarkupReset;
  constructor() {}
}

export type ClassMarkupActions =
  | ClassMarkupSearchRequested
  | ClassMarkupSearchLoaded
  | ClassMarkupUpdateRequested
  | ClassMarkupUpdateSuccess
  | ClassMarkupRequested
  | ClassMarkupLoaded
  | ClassMarkupReset;
